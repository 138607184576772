import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { FppEspelhoNotaProdutoDetailComp } from './components';

export function FppEspelhoNotaProdutoDetail(): JSX.Element {
    return (
        <ListagemProvider>
            <FppEspelhoNotaProdutoDetailComp />
        </ListagemProvider>
    );
}
