import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface Funcionario {
    idUsuario: number;
    dsUsuario: string;
    idTipoEntrega: number;
}

type FuncionarioAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name' | 'size'>;

export function FuncionarioAutoComplete({
    value,
    error,
    multiple = false,
    disabled = false,
    sx,
    onChange = () => {},
    name,
    size,
}: FuncionarioAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Funcionário"
            objectId="idUsuario"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsUsuario}"
            optionStringTemplate="{dsUsuario}"
            requestOptions={{
                url: '/gestao/usuario/funcionario',
                sort: [{ property: 'dsUsuario', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
                limit: 50,
            }}
            isOptionEqualToValue={(option: Funcionario, value: Funcionario) => option && option.idUsuario === value.idUsuario}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
            size={size}
        />
    );
}
