import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, SxProps, Typography } from '@mui/material';

interface BackBtnProps {
    sx?: SxProps;
    goTo?: boolean | string;
}

/**
 * Estrutura padrão do sistema para página do tipo 1 coluna (oneColumn) (sem aside)
 * Contém header + titulo + conteudo + footer
 */
export function BackBtn({ sx, goTo }: BackBtnProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <Button
            variant="outlined"
            onClick={() => {
                if (typeof goTo === 'string') {
                    navigate(goTo);
                } else {
                    navigate(-1);
                }
            }}
            sx={{ ...sx, flex: 'auto' }}
        >
            Voltar
        </Button>
    );
}
