import React, { Dispatch, SetStateAction } from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { ConfirmModalProps, RowProps } from 'pages/compra/pedido-realizado/types';

type PedidoRealizadoReactiveBtn = {
    rowProps: RowProps;
    isDisabledActions: boolean;
    setIsDisabledActions: Dispatch<SetStateAction<boolean>>;
    setIsOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
    setDataConfirmModal: Dispatch<SetStateAction<ConfirmModalProps | null>>;
};

export function PedidoRealizadoReactiveBtn({
    isDisabledActions,
    setIsDisabledActions,
    setIsOpenConfirmModal,
    setDataConfirmModal,
    rowProps,
}: PedidoRealizadoReactiveBtn): JSX.Element {
    const { mutate, isLoading } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { idPedido } = rowProps;
    const queryClient = useQueryClient();

    const handleAprove = (): void => {
        setIsDisabledActions(true);

        mutate(
            { url: `/compra/pedidocompra/${idPedido}/ativa`, preventSnack: true },
            {
                onSuccess: () => {
                    enqueueSnackbar('Pedido reativado com sucesso', { variant: 'success' });

                    queryClient.invalidateQueries(['getListPedidosRealizados']).finally(() => setIsDisabledActions(false));
                },
                onError: (error: any) => {
                    setIsDisabledActions(false);

                    showError(error, 'Não foi possível reativar o pedido. Tente novamente');
                },
            },
        );
    };

    return (
        <LoadingButton
            color="inherit"
            startIcon={<ReplayIcon />}
            disabled={isDisabledActions}
            loading={isLoading}
            onClick={() => {
                setIsOpenConfirmModal(true);
                setDataConfirmModal({ callback: handleAprove, text: 'Tem certeza que deseja reativar o registro selecionado?' });
            }}
        >
            Reativar
        </LoadingButton>
    );
}
