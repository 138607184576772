import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

interface FullLoadingProps {
    loading?: any;
}

export function FullLoading({ loading }: FullLoadingProps): JSX.Element {
    return (
        <Backdrop sx={{ color: 'common.white', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
