import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';

interface SugestaoCompraExcluirModal {
    openModalExcluir: boolean;
    setOpenModalExcluir: Dispatch<SetStateAction<boolean>>;
}

export function SugestaoCompraExcluirModal({ openModalExcluir, setOpenModalExcluir }: SugestaoCompraExcluirModal): JSX.Element {
    const { autoLoad, setAutoLoad } = useListagemContext();
    const [configPedidoList, setConfigPedidoList] = useState([]);
    const [deleteSugestoes, setDeleteSugestoes] = useState(false);
    const Api = useServiceApiContext();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const data = new Date();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!openModalExcluir) {
            setDeleteSugestoes(false);
            setConfigPedidoList([]);
        }
    }, [openModalExcluir]);

    const handleDeleteSugestoes = (): void => {
        const listIds = configPedidoList.map((config: any) => config.idConfigPedido).toString();
        const removeUrl = `/gestao/sugestaocompra/deletebyconfigpedido?idsConfigPedido=${listIds}`;

        Api.delete(removeUrl)
            .then(() => {
                if (!autoLoad) {
                    setAutoLoad(true);
                }

                queryClient.invalidateQueries(['getListSugestoes']);
                
                enqueueSnackbar('Exclusão das sugestões realizada com sucesso', { variant: 'success' });

                setOpenModalExcluir(false);
            })
            .catch((error) => showError(error, 'Ocorreu um erro ao excluir as sugestões selecionadas'));
    };

    return (
        <ContentModal
            open={openModalExcluir}
            setOpen={setOpenModalExcluir}
            sx={{
                borderRadius: 2,
                width: {
                    xs: '80%',
                    sm: 'auto',
                },
                maxWidth: '80%',
            }}
            closeButton
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Excluir Sugestões das Configurações" />

                <ConfiguracaoPedidoAutoComplete
                    value={configPedidoList}
                    multiple={true}
                    onChange={(e, value) => {
                        if (!value.length) {
                            setDeleteSugestoes(false);
                        }

                        setConfigPedidoList(value);
                    }}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        limit: 9999,
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        filter: [
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                            { property: 'fgAtivo', operator: 'eq', value: true },
                            { property: 'dtInicioVigencia', operator: 'lte', value: data },
                            { property: 'dtFimVigencia', operator: 'gte', value: data },
                        ],
                    }}
                />

                <Typography color="error" fontStyle="italic" variant="body2" sx={{ mt: 3, mb: 2 }}>
                    <strong>ATENÇÃO!</strong> Ao clicar em "Excluir Sugestões das Configurações" todas as sugestões importadas das configurações de pedido selecionadas serão permanentemente excluídas.
                    Essa operação é irreversível.
                </Typography>

                <Grid container justifyContent="space-between">
                    <Grid item>
                        <FormControlLabel label="Estou ciente e confirmo a exclusão" control={<Checkbox />} checked={deleteSugestoes} onChange={(e, checked) => setDeleteSugestoes(checked)} />
                    </Grid>
                </Grid>

                <Stack
                    spacing={2}
                    justifyContent="flex-end"
                    direction="row"
                    sx={{
                        mt: 3,
                    }}
                >
                    <Button variant="outlined" onClick={() => setOpenModalExcluir(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton
                        variant="contained"
                        color="error"
                        startIcon={<DeleteOutlinedIcon />}
                        onClick={handleDeleteSugestoes}
                        loading={false}
                        disabled={configPedidoList.length < 1 || !deleteSugestoes}
                    >
                        Excluir Sugestões das Configurações
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
