import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Alert, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
    acceptedFilesExtension,
    HistoricoStatusProps,
    initialSolicitacaoMensagemPecaValues,
    initialSolicitacaoMensagemValues,
    ItensProps,
    MensagensProps,
    RequestSolicitacaoPecaProps,
    SolicitacaoMensagemPecaProps,
    SolicitacaoPecaProps,
} from '../../types';

import { StatusSolicitacaoPecaAutoComplete } from 'components/autocompletes';
import { AzureUpload } from 'components/azureUpload';
import { ExtraButtonListProps } from 'components/buttons/ExtraButtons';
import { Editor } from 'components/fields';
import { Row } from 'components/form';
import { Centered } from 'components/grid';
import { ConfirmModal } from 'components/modal';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    TradeSolicitacaoPecaDadosGeraisMensagem,
    TradeSolicitacaoPecaHistoricoMensagem,
    TradeSolicitacaoPecaItemsMensagem,
    TradeSolicitacaoPecaMensagensMensagem,
    TradeSolicitacaoPecaMensagemPadraoModal,
} from 'pages/marketing-trade/trade/solicitacoes-pecas/components';
import { TypeMarketing } from 'pages/marketing-trade/types';
import { getErrorMessage } from 'util/error';

export function TradeSolicitacaoPecaMensagemFormComp(): JSX.Element {
    const [historico, setHistorico] = useState<HistoricoStatusProps[]>([]);
    const [data, setData] = useState<SolicitacaoPecaProps>({} as SolicitacaoPecaProps);
    const [itens, setItens] = useState<ItensProps[]>([]);
    const [mensagens, setMensagens] = useState<MensagensProps[]>([]);
    const { validRouterParams } = useValidation();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openMensagemModal, setOpenMensagemModal] = useState(false);
    const { setContent } = useFormContext();
    const { id } = useParams();
    const validId = validRouterParams({ id });
    const extraButtons: ExtraButtonListProps[] = [];
    const form = useRef<{ formik: FormikProps<SolicitacaoMensagemPecaProps> }>(null);
    const contestar = useRef(false);
    const [validType, setValidType] = useState(true);
    const { hasPermission } = usePermissionsContext();

    const url = '/gestao/solicitacaopecawrapper';

    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: `/gestao/solicitacaopeca/${id}`,
    };

    const { isLoading, fetchStatus, error, isError, isSuccess } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<SolicitacaoPecaProps> = RequestListagem(requestOptions).then((res) => res.data.data);
            return request;
        },
        {
            enabled: validId,
            onSuccess(response) {
                const { historicoStatus, itens, mensagens, solicitacaoPecaStatus, tipoMarketing } = response;

                if (tipoMarketing?.idTipoMarketing !== TypeMarketing.TRADE) {
                    setValidType(false);
                }

                setContent({ ...initialSolicitacaoMensagemPecaValues, solicitacaoPecaStatus });

                setHistorico(historicoStatus);
                setData(response);
                setItens(itens);
                setMensagens(mensagens);
            },
        },
    );

    const { mutate } = useFormMutation<RequestSolicitacaoPecaProps>();

    const savePecaMensagem = useCallback(
        (values: SolicitacaoMensagemPecaProps, formik: FormikHelpers<SolicitacaoMensagemPecaProps>, fgEnviaParaEmAndamento = false): void => {
            values.fgContestado = contestar.current;
            values.fgEnviaParaEmAndamento = fgEnviaParaEmAndamento;

            if (id) {
                values.idSolicitacaoPeca = Number(id);
            }

            mutate(
                {
                    url,
                    formData: values,
                },
                {
                    onSuccess: (response) => {
                        const responseData = response?.data?.data;

                        if (responseData) {
                            const { historicoStatus, mensagens } = responseData;

                            setHistorico(historicoStatus);
                            setMensagens(mensagens);

                            setContent({ ...values, mensagem: initialSolicitacaoMensagemValues, fgEnviaParaEmAndamento: false, fgContestado: false });

                            contestar.current = false;
                        }

                        formik.resetForm({ values });
                    },
                    onError: () => {
                        setContent(values);
                        formik.resetForm({ values });
                    },
                },
            );
        },
        [id, mutate, setContent],
    );

    const handleSubmit = useCallback(
        (values: SolicitacaoMensagemPecaProps, formik: FormikHelpers<SolicitacaoMensagemPecaProps>): void => {
            // se aprovado pelo marketing, pode ser enviado diretamente para EM ANDAMENTO (id 2 = aprovado pelo marketing)
            if (values.solicitacaoPecaStatus?.idSolicitacaoPecaStatus === 2) {
                setOpenConfirmModal(true);
            } else {
                savePecaMensagem(values, formik);
            }
        },
        [savePecaMensagem],
    );

    const handleContestar = useCallback(() => {
        const { formik } = form.current || {};

        if (formik) {
            contestar.current = true;

            formik.handleSubmit();
        }
    }, []);

    const validationSchema = useMemo(
        () =>
            Yup.object({
                solicitacaoPecaStatus: Yup.object({
                    idSolicitacaoPecaStatus: Yup.number(),
                    dsStatus: Yup.string(),
                }).when('hasPermission', {
                    is: () => hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR']),
                    then: Yup.object().nullable().required(),
                    otherwise: Yup.object().nullable(),
                }),
                mensagem: Yup.object({
                    dsMensagem: Yup.string().when('!hasPermission', {
                        is: () => !hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR']),
                        then: Yup.string().nullable().required(),
                        otherwise: Yup.string().nullable(),
                    }),
                }),
            }),
        [],
    );

    const [msgError] = getErrorMessage(error);

    // se não for admin e estiver finalizado ou reprovado, podemos contestar
    // 3 = reprovado
    // 5 = finalizado
    const enableContestar = Boolean(
        !hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR']) &&
            data?.solicitacaoPecaStatus?.idSolicitacaoPecaStatus &&
            [3, 5].includes(data.solicitacaoPecaStatus?.idSolicitacaoPecaStatus),
    );

    if (enableContestar) {
        extraButtons.push({
            title: 'Contestar',
            onClick: handleContestar,
            featured: true,
        });
    }

    return (
        <FormPage
            title="Visualizar Solicitação de Peças Trade"
            disableQuery
            keepOriginalTitle
            values={initialSolicitacaoMensagemPecaValues}
            onSubmit={handleSubmit}
            url={url}
            validationSchema={validationSchema}
            fgAtivo={false}
            hideBbar={isError}
            extraButtons={extraButtons}
            disableSaveBtn={enableContestar || !validType}
            ref={form}
            goBackButton
        >
            {(formik: FormikProps<SolicitacaoMensagemPecaProps>) => {
                const mensagemErrors = formik.errors.mensagem as unknown as MensagensProps;

                return (
                    <React.Fragment>
                        <ListagemProvider>
                            <TradeSolicitacaoPecaMensagemPadraoModal open={openMensagemModal} setOpen={setOpenMensagemModal} />
                        </ListagemProvider>

                        {isLoading && fetchStatus === 'fetching' && (
                            <Centered>
                                <CircularProgress sx={{ my: 2 }} />
                            </Centered>
                        )}

                        {isError && Boolean(msgError) && <Alert severity="error">{msgError}</Alert>}

                        {isSuccess && !validType && <Alert severity="warning">Não é possivel editar/visualizar este item no módulo TRADE.</Alert>}

                        {isSuccess && validType && (
                            <React.Fragment>
                                {Boolean(historico.length) && <TradeSolicitacaoPecaHistoricoMensagem historicoStatus={historico} />}

                                {Boolean(Object.keys(data).length) && <TradeSolicitacaoPecaDadosGeraisMensagem data={data} />}

                                {Boolean(itens.length) && <TradeSolicitacaoPecaItemsMensagem itens={itens} />}

                                {Boolean(mensagens.length) && <TradeSolicitacaoPecaMensagensMensagem mensagens={mensagens} />}

                                <Block>
                                    <SubTitle label={'Responder'} />

                                    {hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR']) && (
                                        <Row>
                                            <Grid item sx={{ width: 330 }}>
                                                <StatusSolicitacaoPecaAutoComplete
                                                    value={formik.values.solicitacaoPecaStatus}
                                                    error={formik.errors.solicitacaoPecaStatus}
                                                    idStatus={data.solicitacaoPecaStatus?.idSolicitacaoPecaStatus}
                                                    onChange={(e, value) => formik.setFieldValue('solicitacaoPecaStatus', value)}
                                                />
                                            </Grid>

                                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Button variant="contained" color="primary" onClick={() => setOpenMensagemModal(true)}>
                                                    Mensagem Padrão
                                                </Button>
                                            </Grid>
                                        </Row>
                                    )}

                                    <Row>
                                        <Grid item flex={1}>
                                            <Editor
                                                value={formik.values.mensagem?.dsMensagem ?? ''}
                                                onChange={(value: string) => {
                                                    formik.setFieldValue('mensagem.dsMensagem', value);
                                                }}
                                            />

                                            {mensagemErrors?.dsMensagem && (
                                                <Typography
                                                    color="error"
                                                    sx={{
                                                        px: 2,
                                                        pt: 1,
                                                        fontSize: '.75rem',
                                                    }}
                                                >
                                                    {mensagemErrors.dsMensagem}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Row>

                                    <Divider sx={{ my: 2 }} />

                                    <AzureUpload
                                        files={formik.values.mensagem?.arquivos || []}
                                        onChange={(files) => {
                                            formik.setFieldValue('mensagem.arquivos', files);
                                        }}
                                    />
                                </Block>

                                <ConfirmModal
                                    open={openConfirmModal}
                                    setOpen={setOpenConfirmModal}
                                    text={
                                        <React.Fragment>
                                            Gostaria de atualizar o status da solicitação diretamente para: <strong>EM ANDAMENTO</strong> ?
                                        </React.Fragment>
                                    }
                                    confirm={() => savePecaMensagem(formik.values, formik, true)}
                                    cancel={() => savePecaMensagem(formik.values, formik)}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
