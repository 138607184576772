import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { Filter } from 'components/page';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useFilter } from 'hooks/useFilter';
import { useValidationUsuario } from 'hooks/useValidationUsuario';
import {
    PedidoProdutoTextField,
    PedidoGrupoAutoComplete,
    PedidoMarcaGrupoAutoComplete,
    PedidoConfigPedidoAutoComplete,
    PedidoChangeConfigModal,
    PedidoFuncionarioAutoComplete,
} from 'pages/compra/pedido/components';
import { PedidoTipoEntregaAutoComplete } from 'pages/compra/pedido/components/atoms/filter/PedidoTipoEntregaAutoComplete';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';
import { useSearchParamsFilters } from 'pages/compra/pedido/hooks';

export function PedidoCompraListFuncionarioFilter(): JSX.Element {
    const { hasPermission } = usePermissionsContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { setConfigPedido, setLoja, setTipoEntrega, setFuncionario } = usePedidoCompraContext();
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { isDownLg } = useBreakpoints();
    const { configPedidoParam, funcionarioParam, tipoEntregaParam, lojaParam } = useSearchParamsFilters();
    const { usuario, formattedLoja } = useAplicacaoDataContext();
    const { validateUsuario } = useValidationUsuario();
    const {
        filterConfigPedido,
        filterDsProduto,
        filterFuncionario,
        filterJornada,
        filterLoja,
        filterMarca,
        filterCdProduto,
        filterTipoEntrega,
        setFilterCdProduto,
        setFilterConfigPedido,
        setFilterDsProduto,
        setFilterFuncionario,
        setFilterJornada,
        setFilterLoja,
        setFilterMarca,
        setFilterTipoEntrega,
        setDefaultFilterLoja,
        openChangeConfigModal,
        setOpenChangeConfigModal,
        validateChangeCarrinho,
        validateConfigPedido,
    } = usePedidoFilterContext();

    const hasFuncionarioTipoEntregaAmbos = (funcionario: any): boolean => funcionario && funcionario.idTipoEntrega === -1; // -1 = Selecionar ambos
    const hasUsuarioTipoEntregaAmbos = usuario && usuario.idTipoEntrega === -1; // -1 = Selecionar ambos

    // Exibe o filtro de tipo de entrega caso o funcionário ou o usúário tenham a opção de entrega de ambos em seu cadastro.
    const showFilterTipoEntrega = filterFuncionario.value ? hasFuncionarioTipoEntregaAmbos(filterFuncionario.value) : hasUsuarioTipoEntregaAmbos;

    const colSpanLastRow = showFilterTipoEntrega || hasPermissionFinalizacaoGeral ? 4 : 6;

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterDsProduto, setter: setFilterDsProduto },
        { value: filterCdProduto, setter: setFilterCdProduto },
        { value: filterJornada, setter: setFilterJornada },
        { value: filterTipoEntrega, setter: setFilterTipoEntrega },
        { value: filterFuncionario, setter: setFilterFuncionario },
        { value: filterMarca, setter: setFilterMarca },
    ];

    const validateTipoEntrega = (): boolean => {
        const isValid = !showFilterTipoEntrega || filterTipoEntrega.value;

        if (!isValid) {
            enqueueSnackbar('Tipo de entrega é obrigatório', { variant: 'error' });
        }

        return Boolean(isValid);
    };

    const validateFuncionario = (funcionario: any): boolean => {
        return funcionario || validateUsuario();
    };

    const startFilter = (): void => {
        if (!validateFuncionario(filterFuncionario.value) || !validateTipoEntrega() || !validateConfigPedido() || !validateChangeCarrinho()) {
            return;
        }

        handleFilterSubmit({ filters });

        setLoja(filterLoja.value);
        setConfigPedido(filterConfigPedido.value);
        setFuncionario(filterFuncionario.value);
        setTipoEntrega(filterTipoEntrega.value);
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });

        setDefaultFilterLoja();
    };

    const setInitialStates = (): void => {
        if (lojaParam && configPedidoParam) {
            setLoja(lojaParam);
            setConfigPedido(configPedidoParam);
        }

        if (funcionarioParam) {
            setFuncionario(funcionarioParam);

            if (tipoEntregaParam && hasFuncionarioTipoEntregaAmbos(funcionarioParam)) {
                // Caso tenha um parâmetro de tipo de entrega e o funcionario possua um tipo de entrega de ambos,
                // seleciona o tipo de entrega do parâmetro.

                setTipoEntrega(tipoEntregaParam);
            }
        } else if (tipoEntregaParam && hasUsuarioTipoEntregaAmbos) {
            // Caso não tenha parâmetro de funcionário, valida se o usuário logado tem o tipo de entrega de ambos
            // e seleciona o tipo de entrega do parâmetro
            setTipoEntrega(tipoEntregaParam);
        }
    };

    useEffect(() => {
        if (usuario && formattedLoja) {
            setDefaultFilterLoja();

            if (!validateFuncionario(funcionarioParam)) {
                return;
            }

            setInitialStates();

            setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
        }
    }, [usuario, formattedLoja]);

    return (
        <>
            <PedidoChangeConfigModal open={openChangeConfigModal} setOpen={setOpenChangeConfigModal} filters={filters} />

            <Filter reset={resetFilter} submit={startFilter} fixed={isDownLg} preventOnEnter>
                {hasPermissionFinalizacaoGeral && (
                    <Grid item lg={showFilterTipoEntrega ? 4 : 6}>
                        <PedidoFuncionarioAutoComplete />
                    </Grid>
                )}

                {showFilterTipoEntrega && (
                    <Grid item lg={hasPermissionFinalizacaoGeral ? 4 : 6}>
                        <PedidoTipoEntregaAutoComplete />
                    </Grid>
                )}

                <Grid item lg={hasPermissionFinalizacaoGeral && showFilterTipoEntrega ? 4 : 6}>
                    <PedidoConfigPedidoAutoComplete />
                </Grid>

                <Grid item lg={colSpanLastRow}>
                    <PedidoMarcaGrupoAutoComplete />
                </Grid>

                <Grid item lg={colSpanLastRow}>
                    <PedidoGrupoAutoComplete />
                </Grid>

                <Grid item lg={colSpanLastRow}>
                    <PedidoProdutoTextField />
                </Grid>
            </Filter>
        </>
    );
}
