import React, { memo, ReactNode } from 'react';

import { Box, Skeleton, Typography } from '@mui/material';

import { PedidoProdutoModalHistoricoPeriodo, PedidoProdutoModalInfoTitulo } from 'pages/compra/pedido/components';
import { HistoricoPedidos, ProdutoPedido } from 'pages/compra/pedido/types';

interface PedidoProdutoModalHistoricoGrupoPeriodo {
    titulo: ReactNode;
    historicoPedidos: HistoricoPedidos[];
    isFetching: boolean;
    produto: ProdutoPedido;
}

const SkeletonPeriodo = (): JSX.Element => (
    <Box>
        <Skeleton variant="rounded" height={17} />
        <Skeleton variant="rounded" height={17} sx={{ mt: 0.5, width: '50%' }} />
    </Box>
);

const SkeletonPeriodoGroup = (): JSX.Element => (
    <React.Fragment>
        <SkeletonPeriodo />
        <SkeletonPeriodo />
        <SkeletonPeriodo />
        <SkeletonPeriodo />
    </React.Fragment>
);
export function PedidoProdutoModalHistoricoGrupoPeriodo({ titulo, historicoPedidos, isFetching, produto }: PedidoProdutoModalHistoricoGrupoPeriodo): JSX.Element {
    return (
        <Box>
            <PedidoProdutoModalInfoTitulo bold>{titulo}</PedidoProdutoModalInfoTitulo>

            {historicoPedidos.length === 0 && !isFetching && (
                <Typography
                    variant="body2"
                    sx={{
                        mt: 1,
                        lineHeight: '38px',
                    }}
                >
                    Sem dados disponíveis.
                </Typography>
            )}

            {(historicoPedidos.length > 0 || isFetching) && (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { lg: 'repeat(4, 1fr)', xs: 'repeat(2, 1fr)' },
                        columnGap: 2,
                        rowGap: 1,
                        mt: 1,
                    }}
                >
                    {isFetching && <SkeletonPeriodoGroup />}
                    {!isFetching && historicoPedidos.map((periodo) => <PedidoProdutoModalHistoricoPeriodo key={periodo.dsPeriodo} periodo={periodo} produto={produto} />)}
                </Box>
            )}
        </Box>
    );
}
