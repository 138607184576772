import React from 'react';

import { Chip } from '@mui/material';

import { StateNotaDevolucao } from 'pages/financeiro/nota-devolucao/types';

interface NotaDevolucaoListColumnStatus {
    value: string;
}

export function NotaDevolucaoListColumnStatus({ value }: NotaDevolucaoListColumnStatus): JSX.Element {
    let color: 'error' | 'info' = 'info';
    let title = 'Aguardando faturamento';

    if (value === StateNotaDevolucao.CANCELADA) {
        color = 'error';
        title = 'Cancelado';
    }

    return <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} />;
}
