import React from 'react';

import { Autocomplete, Box } from '@mui/material';

import { CissTextField } from 'components/fields/';
import { MimeTypeProps, useMimeTypes } from 'hooks/useMimeTypes';

interface StatusOptionsProps {
    idTipoArquivo: number;
    dsTipoArquivo: string;
    dsTipoArquivoValue: null | string;
}

interface StatusFieldProps {
    onChange: (e: any, value: any) => void;
    multiple?: boolean;
    label?: string;
    value?: any;
    typeList?: string[];
}

const findOptionByValue = (data: StatusOptionsProps[], value: StatusOptionsProps | string | null): StatusOptionsProps | undefined => {
    if (typeof value === 'object' && value !== null) {
        return value;
    } else {
        return data.find((item) => item.dsTipoArquivoValue === value);
    }
};

const generateDate = ({ mimeList }: { mimeList: MimeTypeProps[] }): StatusOptionsProps[] => {
    const data: StatusOptionsProps[] = [
        {
            idTipoArquivo: 0,
            dsTipoArquivo: 'Todos',
            dsTipoArquivoValue: null,
        },
    ];

    mimeList.forEach((item, index) => {
        const ext = item.extension.slice(1);

        if (!data.some((item) => item.dsTipoArquivoValue === ext)) {
            data.push({
                idTipoArquivo: index + 1,
                dsTipoArquivo: item.title,
                dsTipoArquivoValue: ext,
            });
        }
    });

    return data;
};

export function FileTypeField({ onChange, label = 'Tipo Arquivo', value = null, typeList = [] }: StatusFieldProps): JSX.Element {
    const { findInMimeTable, getMimeTypesTable } = useMimeTypes();
    const mimeList = typeList.length ? findInMimeTable(typeList) : getMimeTypesTable();
    const data = generateDate({ mimeList });

    return (
        <Autocomplete
            value={findOptionByValue(data, value)}
            onChange={onChange}
            options={data}
            isOptionEqualToValue={(option, fieldValue) => option.idTipoArquivo === fieldValue.idTipoArquivo}
            getOptionLabel={(option: StatusOptionsProps) => option.dsTipoArquivo}
            renderInput={(params) => <CissTextField label={label} {...params} />}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.idTipoArquivo}>
                    {option.dsTipoArquivo}
                </Box>
            )}
        />
    );
}
