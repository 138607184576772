import { FormikHelpers } from 'formik';

import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { TLocalFuncionarioForm, TLocalFuncionarioFormRequest } from 'pages/complementar/local-funcionario/types';

type UsePostLocalFuncionarioReturn = {
    handleSubmit(formData: TLocalFuncionarioForm, formik: FormikHelpers<TLocalFuncionarioForm>): void;
    isLoading: boolean;
};

export function usePostLocalFuncionario(): UsePostLocalFuncionarioReturn {
    const { mutate, isLoading } = useFormMutation<TLocalFuncionarioFormRequest>();
    const { setContent } = useFormContext();

    const handleSubmit = (formData: TLocalFuncionarioForm, formik: FormikHelpers<TLocalFuncionarioForm>): void => {
        mutate(
            {
                formik,
                formData,
                url: '/gestao/localfuncionario',
                idField: 'idLocalFuncionario',
            },
            {
                onSuccess: ({ data }) => setContent(data.data),
            },
        );
    };

    return { handleSubmit, isLoading };
}
