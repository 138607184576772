import React from 'react';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { TMenuSystemItem } from 'components/menu/system/MenuSystem';

export function MenuSystemItem({ text, icon, onClick }: TMenuSystemItem): JSX.Element {
    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>{icon}</ListItemIcon>

            <ListItemText disableTypography>{text}</ListItemText>
        </MenuItem>
    );
}
