import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface NotaDebitoOptionsProps {
    idPedidoTipoNotaDebito: number;
    dsPedidoTipoNotaDebito: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function GeraNotaDebitoAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Gerar Nota de Débito"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsPedidoTipoNotaDebito}"
            optionStringTemplate="{dsPedidoTipoNotaDebito}"
            requestOptions={{
                url: '/gestao/pedidotiponotadebito',
                sort: [{ property: 'idPedidoTipoNotaDebito', direction: 'ASC' }],
                columns: 'idPedidoTipoNotaDebito,dsPedidoTipoNotaDebito',
                limit: 50,
            }}
            isOptionEqualToValue={(option: NotaDebitoOptionsProps, value: NotaDebitoOptionsProps) => value && option && option.idPedidoTipoNotaDebito === value.idPedidoTipoNotaDebito}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
