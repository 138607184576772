import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { ProdutoListComp } from 'pages/compra/produto/components';

export function ProdutoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PRODUTO_VISUALIZAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <ListagemProvider>
                <ProdutoListComp />
            </ListagemProvider>
        </FormProvider>
    );
}
