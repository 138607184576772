import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ConfigPedidoTotalizador } from 'components/autocompletes/ConfiguracaoPedidoTotalizadorAutoComplete';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ConfigPedidoProps } from 'pages/compra/pedido/types';

interface ConfiguracaoPedidoCompraSazonalModalProps {
    open: boolean | string;
    setOpen: Dispatch<SetStateAction<boolean | string>>;
}

interface ConfiguracaoPedidoRequestProps {
    data: ConfigPedidoProps;
}

interface ConfiguracaoPedidoTotalizadorRequestProps {
    data: ConfigPedidoTotalizador[];
}

export function ConfiguracaoPedidoCompraQuebrasModal({ open, setOpen }: ConfiguracaoPedidoCompraSazonalModalProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();
    const { id } = useParams();
    const [configPedido, setConfigPedido] = useState<ConfigPedidoProps | null>(null);

    const requestOptionsOrigem = useMemo(
        (): RequestOptionsType => ({
            url: '/gestao/configpedido/totalizador',
            filter: [{ property: 'idConfigPedido', operator: 'eq', value: configPedido?.idConfigPedidoOrigem }],
            sort: [{ property: 'idConfigPedido', direction: 'ASC' }],
        }),
        [configPedido?.idConfigPedidoOrigem],
    );

    const { fetchStatus: fetchStatusOrigem } = useQuery(
        [requestOptionsOrigem],
        (): Promise<ConfiguracaoPedidoTotalizadorRequestProps> => RequestListagem(requestOptionsOrigem).then((res) => res.data),
        {
            enabled: Boolean(configPedido),
            onSuccess: (response) => {
                const [{ configPedido: configPedidoTotalizadora, configPedidoRelacionada, fgSincronizado }] = response.data;
                const { dtInicioVigencia, dtFimVigencia, dsConfigPedido, idConfigPedido } = configPedidoTotalizadora;

                navigate({
                    pathname: '/compra/pedido-totalizador',
                    search: createSearchParams({
                        idConfigPedidoOrigem: JSON.stringify([
                            { configPedido: { dsConfigPedido, idConfigPedido }, configPedidoRelacionada, fgSincronizado },
                        ]),
                        idConfigPedido: JSON.stringify([
                            { idConfigPedido: configPedido?.idConfigPedido, dsConfigPedido: configPedido?.dsConfigPedido },
                        ]),
                        idStatusPedidoTotalizador: JSON.stringify([{ idStatusPedidoTotalizador: 5, dsStatusPedidoTotalizador: 'Refazer Quebras' }]),
                        dhInclusao: JSON.stringify({
                            startDate: new Date(`${dtInicioVigencia}T00:00:00`),
                            endDate: new Date(`${dtFimVigencia}T00:00:00`),
                        }),
                    }).toString(),
                });
            },
        },
    );

    const requestOptions = useMemo(
        (): RequestOptionsType => ({
            url: `gestao/configpedido/${id}`,
        }),
        [id],
    );

    const { refetch, fetchStatus } = useQuery(
        [requestOptions],
        (): Promise<ConfiguracaoPedidoRequestProps> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: false,
            onSuccess: (response) => setConfigPedido(response.data),
        },
    );

    return (
        <ContentModal open={Boolean(open)} setOpen={setOpen} closeButton sx={{ borderRadius: 1, maxWidth: 700 }}>
            <React.Fragment>
                <SubTitle label="Aviso" />

                <Typography align="justify">{open}</Typography>

                <Stack direction="row" justifyContent="flex-end" gap={2} pt={2}>
                    <LoadingButton
                        variant="contained"
                        loading={fetchStatus === 'fetching' || fetchStatusOrigem === 'fetching'}
                        onClick={() => refetch()}
                    >
                        Refazer Quebras
                    </LoadingButton>

                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                </Stack>
            </React.Fragment>
        </ContentModal>
    );
}
