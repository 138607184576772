import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { Alert, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';


import { Centered } from 'components/grid';
import { OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { EspelhoFppResumoList, EspelhoFppNotaList, EspelhoFppTitulosList, EspelhoFppHeader } from 'pages/financeiro/extrato/fpp-espelho/components';

export function EspelhoFppListComp(): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { setAutoLoad } = useListagemContext();
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const nrQuinzena = parseInt(searchParams.get('nrQuinzena') || '');
    const nrMes = searchParams.get('nrMes');
    const nrAno = searchParams.get('nrAno');
    const nrFpp = searchParams.get('nrFpp');
    const validParams = Boolean(cdLoja && typeof nrFpp !== undefined && nrQuinzena && nrMes && nrAno);

    const strDtFiltro = dateFns.format(new Date(`${nrAno}-${nrMes}-${nrQuinzena === 1 ? 1 : 16}`), 'MM/dd/yyyy');

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/fpp/extratodetalhado',
        columns: 'id',
        nested: true,
        filter: [
            { property: 'cdLoja', value: cdLoja, operator: 'eq' },
            { property: 'nrFpp', value: nrFpp, operator: 'eq' },
            { property: 'dtFiltro', value: strDtFiltro, operator: 'eq', logicalOperator: 'AND' },
        ],
    };

    const { data, isError, fetchStatus, isLoading } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<any> = RequestListagem(requestOptions).then((res) => res.data.data);
            return request;
        },
        {
            onSettled: () => setAutoLoad(true), // utilizado para indicar para as grids que não existem dados
        },
    );

    const content = Array.isArray(data) && data.at(0);

    return (
        <OneColumn title="Espelho de FPP" goBackButton>
            {fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress sx={{ my: 2 }} />
                </Centered>
            )}

            {!isLoading && validParams && !content && <Alert severity="warning">Sem dados para exibir.</Alert>}

            {!isLoading && validParams && isError && <Alert severity="error">Falha na recuperação dos dados do Espelho de FPP.</Alert>}

            {!isLoading && validParams && content && (
                <React.Fragment>
                    <EspelhoFppHeader emitente={content.emitente} destinatario={content.destinatario} />

                    <EspelhoFppResumoList list={{ ...content.fpp, cdLoja }} />

                    <EspelhoFppTitulosList />

                    <EspelhoFppNotaList notas={content.notas} cdLoja={cdLoja} nrQuinzena={nrQuinzena} nrMes={nrMes} nrAno={nrAno} />
                </React.Fragment>
            )}
        </OneColumn>
    );
}
