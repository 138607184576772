import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { PedidoCompraTotalizadorListComp } from 'pages/compra/pedido-totalizador/components';

export function PedidoTotalizadorList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_TOTALIZADOR_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <FormProvider>
                <PedidoCompraTotalizadorListComp />
            </FormProvider>
        </ListagemProvider>
    );
}
