import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface CentroPedidoOptionsProps {
    idCentroPedido: number;
    dsCentroPedido: string;
    cdCentroPedido: number;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function CentroPedidoAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Centro de pedido"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsCentroPedido}"
            optionStringTemplate="{dsCentroPedido}"
            requestOptions={{
                url: '/gestao/centropedido',
                columns: 'idCentroPedido,cdCentroPedido,dsCentroPedido',
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
            }}
            isOptionEqualToValue={(option: CentroPedidoOptionsProps, value: CentroPedidoOptionsProps) => value && option && option.idCentroPedido === value.idCentroPedido}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
