import React, { useCallback } from 'react';

import { Box, Divider, Link, Typography } from '@mui/material';
import * as dateFns from 'date-fns';
import { Event } from 'react-big-calendar';

import { CalendarioEventoFinanceiroTitulosProps } from 'pages/calendario/types';
import { brlDate, brlPrice } from 'util/format';

interface CalendarioListEventContentProps {
    event: Event;
    small?: boolean;
}

export const EventContent = (event: Event): JSX.Element => {
    const { resource } = event;
    const { tpCalendarioEvento = null } = resource;

    const LabelValueBox = useCallback(
        ({ item }: { item: { label: string; value: string | number } }) => (
            <Box display="flex" justifyContent="space-between">
                {item.label}
                <strong>{item.value}</strong>
            </Box>
        ),
        [],
    );

    switch (tpCalendarioEvento) {
        case 'EVENTO_FINANCEIRO': {
            const { titulos } = resource;

            return (
                <Box sx={{ maxHeight: 200, overflow: 'auto', overflowX: 'hidden', mr: -1, pr: 1 }}>
                    {titulos.map((item: CalendarioEventoFinanceiroTitulosProps, index: number) => {
                        const { nrTitulo, vlTitulo, dtVencimento } = item;
                        const financeiroList = [
                            { label: 'Número do título', value: nrTitulo },
                            { label: 'Valor', value: `${brlPrice(vlTitulo)}` },
                            { label: 'Data de vencimento', value: `${brlDate(dtVencimento)}` },
                        ];

                        return (
                            <React.Fragment key={nrTitulo + Math.random()}>
                                <Divider sx={{ display: index ? 'auto' : 'none', my: 1 }} />

                                {financeiroList.map((item) => (
                                    <LabelValueBox item={item} key={item.label} />
                                ))}
                            </React.Fragment>
                        );
                    })}
                </Box>
            );
        }
        case 'EVENTO_LIMITE_GRADE':
        case 'EVENTO_PREVISAO_ENTREGA': {
            const { dtLimitePedido, dtColeta, dtEntrega } = resource;
            const logisticaList = [
                { label: 'Data limite', value: dateFns.format(dtLimitePedido, 'dd/MM/yyyy') },
                { label: 'Data de coleta', value: dateFns.format(dtColeta, 'dd/MM/yyyy') },
                { label: 'Previsão de entrega', value: dateFns.format(dtEntrega, 'dd/MM/yyyy') },
            ];

            return (
                <React.Fragment>
                    {logisticaList.map((item) => (
                        <LabelValueBox item={item} key={item.label} />
                    ))}
                </React.Fragment>
            );
        }
        default: {
            return (
                <Box sx={{ maxHeight: '50vh', overflowX: 'hidden', mr: -1, pr: 1 }}>
                    <Typography sx={{ my: -1 }} dangerouslySetInnerHTML={{ __html: resource.dsConteudo }} />
                </Box>
            );
        }
    }
};

export function CalendarioListEventContent({ event, small }: CalendarioListEventContentProps): JSX.Element {
    const { resource } = event;

    if (resource.dsConteudo) {
        const regex = /<img[^>]*>/g;
        const tagsImg = resource.dsConteudo.match(regex);

        if (tagsImg) {
            tagsImg.forEach((tag: string) => {
                let novaTag = tag;

                // Remover o atributo 'style' da tag
                novaTag = novaTag.replace(/style="[^"]*"/g, '');

                // Verificar e modificar o atributo 'width'
                if (!novaTag.includes('width')) {
                    novaTag = novaTag.replace('>', ' width="100%">');
                } else {
                    novaTag = novaTag.replace(/width="[^"]*"/g, 'width="100%"');
                }

                // Verificar e modificar o atributo 'height'
                if (!novaTag.includes('height')) {
                    novaTag = novaTag.replace('>', ' height="auto">');
                } else {
                    novaTag = novaTag.replace(/height="[^"]*"/g, 'height="auto"');
                }

                // Substituir a tag original pela tag modificada na nova string
                resource.dsConteudo = resource.dsConteudo.replace(tag, novaTag);
            });
        }
    }

    return (
        <Box
            sx={{
                p: small ? 0 : 2,
            }}
        >
            {EventContent(event)}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {Boolean(resource.dsLink && resource.dsLinkText) && (
                    <Link
                        href={resource.dsLink}
                        target="_blank"
                        sx={{
                            display: 'inline-block',
                            mt: small ? 1 : 2,
                            mb: small ? 0 : 1,
                        }}
                    >
                        {resource.dsLinkText}
                    </Link>
                )}
                <Box flex={1} />

                {Boolean(resource.dsPedidoLink) && (
                    <Link
                        href={resource.dsPedidoLink}
                        target="_blank"
                        sx={{
                            display: 'inline-block',
                            mt: small ? 1 : 2,
                            mb: small ? 0 : 1,
                        }}
                    >
                        FAZER PEDIDO
                    </Link>
                )}
            </Box>
        </Box>
    );
}
