import React, { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';

import { Event } from 'react-big-calendar';

import { useLocalStorage } from 'hooks/useLocalStorage';

export interface CalendarEventsDisabledProps {
    value: string | null;
    setCategoryEventDisabled: (category: string | number) => void;
    isCategoryEventEnabled: (category: string | number) => boolean;
    calendarEventsList: (events: Event[]) => Event[];
    removeCategoryEventDisabled: (category: string | number) => void;
}

interface FormProviderProps {
    children?: ReactNode;
}

const CalendarEventsDisabledContext = createContext<CalendarEventsDisabledProps>({} as CalendarEventsDisabledProps);

export function CalendarEventsDisabledProvider({ children }: FormProviderProps): JSX.Element {
    const { value, setValue } = useLocalStorage('calendarEventsDisabled');

    const setCategoryEventDisabled = useCallback(
        (category: string | number) => {
            setValue(JSON.stringify([...(value ? JSON.parse(value) : []), category]));
        },
        [setValue, value],
    );

    const removeCategoryEventDisabled = useCallback(
        (category: string | number) => {
            let newValue = [];

            if (value && Array.isArray(JSON.parse(value))) {
                newValue = JSON.parse(value).filter((item: string) => item !== category);
            }

            setValue(JSON.stringify(newValue));
        },
        [setValue, value],
    );

    const isCategoryEventEnabled = useCallback(
        (category: string | number) => {
            if (value && Array.isArray(JSON.parse(value))) {
                return !JSON.parse(value).some((item: string) => item === category);
            }

            return true;
        },
        [value],
    );

    const calendarEventsList = useCallback(
        (events: Event[]) => {
            const newCalendarEventsList: Event[] = [];

            if (Array.isArray(events)) {
                events.forEach((events) => {
                    const { resource } = events;
                    let { tpCalendarioEvento } = resource;

                    if (tpCalendarioEvento === 'EVENTO_PERSONALIZADO') {
                        if (isCategoryEventEnabled(resource.calendarioEventoTag.idCalendarioEventoTag)) {
                            return newCalendarEventsList.push(events);
                        }
                    } else {
                        if (['EVENTO_LIMITE_GRADE', 'EVENTO_PREVISAO_ENTREGA'].includes(tpCalendarioEvento)) {
                            tpCalendarioEvento = 'EVENTO_LOGISTICA';
                        }

                        if (isCategoryEventEnabled(tpCalendarioEvento)) {
                            return newCalendarEventsList.push(events);
                        }
                    }
                });
            }

            return newCalendarEventsList;
        },
        [isCategoryEventEnabled],
    );

    const contextValue = useMemo(
        () => ({
            value,
            setCategoryEventDisabled,
            isCategoryEventEnabled,
            calendarEventsList,
            removeCategoryEventDisabled,
        }),
        [calendarEventsList, isCategoryEventEnabled, removeCategoryEventDisabled, setCategoryEventDisabled, value],
    );

    return <CalendarEventsDisabledContext.Provider value={contextValue}>{children}</CalendarEventsDisabledContext.Provider>;
}

export const useCalendarEventsDisabled = (): CalendarEventsDisabledProps => useContext(CalendarEventsDisabledContext);
