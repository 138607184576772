import React, { ReactNode } from 'react';

import { AuthProvider as KeycloakAuthProvider, AuthProviderProps } from 'react-oidc-context';

import { CissLiveAuthProvider } from 'context/CissLiveAuthContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
interface GenericAuthProvider {
    children?: ReactNode;
}

const onSigninCallback = (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
};

const oicdConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET as string,
    onSigninCallback,
};

export type TokenInfo = {
    dsTimezone: string;
    idEmpresa: number;
    dsEmailEmpresa: string;
    dsNomeEmpresa: string;
    idGrupos: any;
    idUsuario: number;
    tpUsuario: string;
    dsUsuario: string;
    dsEmail: string;
};

export type GenericAuth = {
    isAuthenticated: boolean;
    resetSession: () => void;
    accessToken: string | null | undefined;
    tokenInfo: TokenInfo | null;
    openLoginModal: boolean;
    setOpenLoginModal: (open: boolean) => void;
    logout: () => void;
    setToken: (token: string) => void;
    isFirstTime: boolean;
    setIsFirstTime: (isFirstTime: boolean) => void;
    updatePassword: () => void;
    isUpdatablePassword: boolean;
};

export function GenericAuthProvider({ children }: GenericAuthProvider): JSX.Element {
    const { tipoAuth } = useGetDomainConfig();

    if (tipoAuth === 'oicd') {
        return <KeycloakAuthProvider {...oicdConfig}>{children}</KeycloakAuthProvider>;
    }

    return <CissLiveAuthProvider>{children}</CissLiveAuthProvider>;
}
