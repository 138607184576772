const getSuccessHttp = (statusCode: number): string => {
    switch (statusCode) {
        case 201:
            return 'Registro salvo com sucesso!';
        default:
            return 'Operação realizada com sucesso!';
    }
};

export interface SuccessMessageProps {
    status: number;
    data?: {
        message?: string;
        data?: {
            message?: string;
        };
    };
}

export const getSuccessMessage = (response: SuccessMessageProps): string => {
    const { data, status } = response;

    let { message } = data ?? {};

    if (!message && data?.data) {
        message = data.data.message;
    }

    if (!message) {
        message = getSuccessHttp(status);
    }

    return message;
};
