import { CargoFuncionario } from 'components/autocompletes/CargoFuncionarioAutoComplete';
import { LocalFuncionario } from 'components/autocompletes/LocalFuncionarioAutoComplete';
import { TipoEntrega } from 'components/autocompletes/TipoEntregaAutoComplete';
import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface GrupoEconomicoProps {
    idGrupoEconomico: number;
    dsGrupoEconomico: string;
    grupoEconomico: {
        idGrupoEconomico: number;
        dsGrupoEconomico: string;
    };
}

interface LojaProps {
    idLoja: number;
    cdLoja: string;
    dsNomeFantasia: string;
}

export interface LojaPropsProps {
    loja: LojaProps;
}

export interface UsersOptionsProps {
    idTipoUsuario: number;
    dsTipoUsuario: string;
    tipoUsuarioEnum?: string;
}

interface UsuarioFuncionario {
    fgAtivoCompra: boolean;
    localFuncionario: LocalFuncionario;
    cargo: CargoFuncionario;
    tipoEntrega: TipoEntrega;
}

export interface Cep {
    idCep: number;
    sgEstado: string;
    dsCidade: string;
    dsBairro: string;
    dsEndereco: string;
    nrCep: string;
    cidade: {
        idCidade: number;
        dsCidade: string;
        nrIbge: number;
        sgEstado: string;
        estado: {
            idEstado: number;
            dsEstado: string;
            sgEstado: string;
            sgEstadoName: string;
        };
    };
    estado: {
        idEstado: number;
        dsEstado: string;
        sgEstado: string;
        sgEstadoName: string;
    };
}

interface GrupoUsuarioProps {
    idGrupoUsuario: number;
    dsGrupoUsuario: string;
}
export interface UsuarioProps {
    fgAtivo: boolean;
    idUsuario: number;
    dsLogin: string;
    cdUsuario: string;
    dsUsuario: string;
    loja: LojaProps | null;
    usuarioAcessoLojas: LojaProps[];
    tipoUsuario: UsersOptionsProps | null;
    usuarioGrupoUsuarios: GrupoUsuarioProps[];
    usuarioAcessoGrupoEconomicos: GrupoEconomicoProps[];
    cep?: Cep;
    dsFone?: string;
    dsBairro?: string;
    dsEmail?: string;
    dsCelular?: string;
    dsEndereco?: string;
    nrEndereco?: string;
    dsComplemento?: string;
    usuarioFuncionario?: UsuarioFuncionario;
}

export interface GruposEconomicosProps {
    [x: string]: any;
    idGrupoEconomico: number;
    dsGrupoEconomico: string;
}

export interface RequestGruposEconomicosListProps {
    data: GruposEconomicosProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestUsuarioProps extends RequestListagemProps {
    data: UsuarioProps;
}

export interface GrupoUsuario {
    idGrupoUsuario: number;
    dsGrupoUsuario: string;
}

export const initialUsuarioValues: UsuarioProps = {
    idUsuario: -1,
    cdUsuario: '',
    dsUsuario: '',
    dsLogin: '',
    fgAtivo: true,
    usuarioAcessoLojas: [],
    usuarioAcessoGrupoEconomicos: [],
    usuarioGrupoUsuarios: [],
    tipoUsuario: null,
    loja: null,
};

export interface RequestGrupoUsuario extends RequestListagemProps {
    data: GrupoUsuario[];
}
