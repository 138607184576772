import React, { useCallback, useState } from 'react';

import { Checkbox } from '@mui/material';

import { useFormMutation } from 'mutations/useFormMutation';
import { ConfirmModal } from 'components/modal';

interface DreListLojaColumnProp {
    idStatus: number;
    id: number;
    refresh: () => void;
}

export function DreListLiberarColumn({ idStatus, id, refresh }: DreListLojaColumnProp): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const { mutate } = useFormMutation();
    const isFinalizado = idStatus === 3; // 3 = Finalizado
    const isBloqueado = idStatus === 4; // 4 = Bloqueado
    
    const handleChangeLiberarDre = useCallback((): void => {
        setChecked(true);
        setOpenConfirmModal(true);
    }, []);

    const confirmChangeLiberarDre = useCallback((): void => {
        mutate(
            {
                url: `/dre/dremercadologica/libera?idDreMercadologica=${id}`,
                method: 'PUT',
            },
            {
                onSuccess: () => {
                    refresh();
                    setChecked(false);
                },
                onError: () => setChecked(false),
            },
        );
    }, [mutate, id, refresh]);

    return (
        <React.Fragment>
            <Checkbox checked={checked} onChange={handleChangeLiberarDre} disabled={!(isFinalizado || isBloqueado)} />
            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                confirm={confirmChangeLiberarDre}
                cancel={() => setChecked(false)}
                text="Tem certeza que deseja liberar para preenchimento a estimativa de resultado selecionada?"
            />
        </React.Fragment>
    );
}
