import React, { useCallback } from 'react';

import { FormikErrors } from 'formik';
interface useShowErrorProps {
    focusError: (error: FormikErrors<any>, nameObject?: string) => void;
}

interface ArrayErrorsProps {
    [x: string]: string | ArrayErrorsProps[];
}

export function useFocusErrorFormik(): useShowErrorProps {
    const getElement = useCallback((fieldName: string) => document.getElementsByName(fieldName)[0] || document.getElementById(fieldName), []);

    const focusError = useCallback(
        (error: FormikErrors<any>, nameObject?: string): void => {
            const fieldNames = Object.keys(error);

            if (fieldNames && fieldNames.length) {
                const fieldName = fieldNames.at(0);

                if (fieldName) {
                    let element: HTMLElement | null = null;

                    if (typeof error[fieldName] === 'object') {
                        if (Array.isArray(error[fieldName])) {
                            const arrayErrors = error[fieldName] as ArrayErrorsProps[];
                            const index = arrayErrors.findIndex((item) => item);

                            focusError(arrayErrors[index], `${nameObject ?? ''}${fieldName}[${index}].`);
                        } else {
                            focusError(error[fieldName] as object, `${nameObject ?? ''}${fieldName}.`);
                        }
                    } else {
                        element = getElement(`${nameObject ?? ''}${fieldName}`);
                    }

                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }
            }
        },
        [getElement],
    );

    return { focusError };
}
