import React, { Dispatch, SetStateAction, useRef } from 'react';

import { Box, Button } from '@mui/material';

import { CissTextField } from 'components/fields';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';

type ProdutoImportarEdicaoModal = {
    open: boolean | number;
    setOpen: Dispatch<SetStateAction<boolean | number>>;
    name: string;
    changeFileName: (idFile: number, newDsTitulo: string) => void;
};

export function ProdutoImportarEdicaoModal({ open, setOpen, name, changeFileName }: ProdutoImportarEdicaoModal): JSX.Element {
    const value = useRef<string>('');

    value.current = name;

    const onSave = (): void => {
        changeFileName(Number(open), value.current);
        setOpen(false);
    };

    return (
        <ContentModal open={Boolean(open)} setOpen={setOpen} sx={{ borderRadius: 1 }} closeButton>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <SubTitle label="Editar nome" />

                <CissTextField
                    size="small"
                    value={value.current}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();

                            onSave();
                        }
                    }}
                    onChange={(e) => {
                        value.current = e.target.value;
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <Button variant="contained" onClick={onSave}>
                        Salvar
                    </Button>
                </Box>
            </Box>
        </ContentModal>
    );
}
