import React, { ReactNode, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton, Tooltip, CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';
import { useSnackbar } from 'notistack';

import { ConfirmModal } from 'components/modal';
import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { getSuccessMessage, SuccessMessageProps } from 'util/success';
import { ComunicadoLogsBtn } from 'pages/comunicado/components';

interface EditRemoveBtnProps {
    id: number;
    sx?: SxProps;
    refresh?: () => void;
    removeUrl?: string;
    viewUrl?: string;
    editUrl?: string;
    cloneUrl?: string;
    showEditBtn?: boolean;
    showCloneBtn?: boolean;
    showRemoveBtn?: boolean;
    idClone?: string;
    extraContent?: ReactNode;
}

const CircularProgressAbsolute = (): JSX.Element => (
    <CircularProgress
        size={32}
        sx={{
            position: 'absolute',
        }}
    />
);

export function EditRemoveBtn({
    id,
    sx,
    refresh,
    removeUrl,
    showEditBtn = true,
    showRemoveBtn = true,
    showCloneBtn = true,
    editUrl = 'form/',
    viewUrl,
    cloneUrl,
    idClone,
    extraContent,
}: EditRemoveBtnProps): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openCloneModal, setOpenCloneModal] = useState(false);
    const [isLoadingRemove, setIsLoadingRemove] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const Api = useServiceApiContext();
    const { showError } = useShowError();
    const { mutate, isLoading: isLoadingClone } = useFormMutation();

    const handleRemoveClick = useCallback((): void => {
        setOpenConfirmModal(true);
    }, [setOpenConfirmModal]);

    const callbackExcluir = useCallback((): void => {
        if (removeUrl) {
            setIsLoadingRemove(true);

            Api.delete(removeUrl)
                .then((result: SuccessMessageProps) => {
                    const message = getSuccessMessage(result);

                    enqueueSnackbar(message, { variant: 'success' });

                    if (typeof refresh === 'function') {
                        refresh();
                    }
                })
                .catch((error) => showError(error, 'Ocorreu um erro ao remover o registro, tente novamente.'))
                .finally(() => setIsLoadingRemove(false));
        }
    }, [removeUrl, Api, enqueueSnackbar, refresh, showError]);

    const callbackClone = useCallback(() => {
        if (cloneUrl) {
            mutate(
                { url: cloneUrl },
                {
                    onSuccess: ({ data }: any) => {
                        if (idClone) {
                            navigate(`${editUrl}${[data?.data[idClone]]}`);
                        } else if (typeof refresh === 'function') {
                            refresh();
                        }
                    },
                },
            );
        }
    }, [cloneUrl, editUrl, idClone, refresh]);

    const disabled = isLoadingClone || isLoadingRemove;

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', ...sx }}>
                {showCloneBtn && cloneUrl && id && editUrl && (idClone || typeof refresh === 'function') && (
                    <Tooltip title="Clonar">
                        <IconButton sx={{ p: 0.5 }} onClick={() => setOpenCloneModal(true)} disabled={disabled}>
                            {isLoadingClone && <CircularProgressAbsolute />}

                            <ContentCopyOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {viewUrl && (
                    <Tooltip title="Visualizar">
                        <IconButton sx={{ p: 0.5 }} onClick={() => navigate(`${viewUrl}${id}`)} disabled={disabled}>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {showEditBtn && (
                    <Tooltip title="Editar">
                        <IconButton sx={{ p: 0.5 }} onClick={() => navigate(`${editUrl}${id}`)} disabled={disabled}>
                            <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {showRemoveBtn && removeUrl && (
                    <Tooltip title="Remover">
                        <IconButton sx={{ p: 0.5 }} onClick={handleRemoveClick} disabled={disabled}>
                            {isLoadingRemove && <CircularProgressAbsolute />}
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {extraContent}
            </Box>

            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={callbackExcluir} />

            <ConfirmModal
                open={openCloneModal}
                setOpen={setOpenCloneModal}
                confirm={callbackClone}
                text="Tem certeza que deseja clonar o registro selecionado?"
            />
        </React.Fragment>
    );
}
