import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { TradeTagFormComp, TradeTagListComp } from 'pages/marketing-trade/trade/tag/components';

export function TagListTrade(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['TRADE_PECA_MODERAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <TradeTagListComp />
        </ListagemProvider>
    );
}

export function TagFormTrade(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['TRADE_PECA_MODERAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <TradeTagFormComp />
        </FormProvider>
    );
}
