import React, { useMemo } from 'react';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { initialCategoriaValues, RequestCategoriaModelProps } from '../../types';

import { CissTextField, ToggleFgSomenteLojasRevendedor } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { Upload } from 'components/upload';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';

export function CategoriaComunicadoFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const hasModeratePermission = hasPermission(['COMUNICADO_CATEGORIA_MODERAR']);
    const url = '/gestao/categoriacomunicado/';

    const { mutate } = useFormMutation();

    const handleSubmit = (values: RequestCategoriaModelProps, formik: FormikHelpers<RequestCategoriaModelProps>): void => {
        mutate(
            {
                url,
                idField: 'idCategoriaComunicado',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsCategoriaComunicado: Yup.string().required().min(3),
            }),
        [],
    );

    return (
        <FormPage
            goBackButton
            title="Categorias de Comunicado"
            url={url}
            onSubmit={handleSubmit}
            values={initialCategoriaValues}
            validationSchema={validationSchema}
            extraContent={hasModeratePermission ? <ToggleFgSomenteLojasRevendedor /> : null}
            fgAtivo={hasModeratePermission}
        >
            {(formik: FormikProps<RequestCategoriaModelProps>) => {
                return (
                    <>
                        <Block>
                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Nome da Categoria"
                                        name="dsCategoriaComunicado"
                                        value={formik.values.dsCategoriaComunicado}
                                        error={formik.errors.dsCategoriaComunicado}
                                        onChange={(e) => formik.setFieldValue('dsCategoriaComunicado', e.target.value)}
                                    />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Capa" />

                            <Upload
                                id="dsLinkCapa"
                                listFiles={
                                    formik.values.dsLinkCapa
                                        ? [
                                              {
                                                  fgAtivo: true,
                                                  fgCapa: true,
                                                  fgExcluido: true,
                                                  fgNovo: true,
                                                  arquivo: {
                                                      idArquivo: formik.values.idCategoriaComunicado,
                                                      dsExtensao: '',
                                                      dsArquivo: '',
                                                      dsLink: formik.values.dsLinkCapa,
                                                      dsLinkThumbnail: formik.values.dsLinkCapa,
                                                      dsHash: '',
                                                      dsTitulo: '',
                                                      dsMimeType: '',
                                                  },
                                              },
                                          ]
                                        : []
                                }
                                setListFiles={(list) => {
                                    if (list.at(0)?.fgExcluido) {
                                        formik.setFieldValue('dsLinkCapa', null);
                                    } else {
                                        formik.setFieldValue('dsLinkCapa', list.at(0)?.arquivo.dsLink);
                                    }
                                }}
                                removeAll={false}
                                replaceFile={true}
                                accept={['.jpg', '.png']}
                                error={formik.errors.dsLinkCapa}
                            />
                        </Block>
                    </>
                );
            }}
        </FormPage>
    );
}
