import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface RoyaltiesOptionsProps {
    idConfigPedidoFaixaRoyalties: number;
    peConfigPedidoFaixaRoyalties: number;
}

type RoyaltiesAutoCompleteProps = Pick<CissAutoCompleteProps,  'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function RoyaltiesAutoComplete({ size, value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: RoyaltiesAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Royalties"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{peConfigPedidoFaixaRoyalties}%"
            optionStringTemplate="{peConfigPedidoFaixaRoyalties}%"
            requestOptions={{
                url: '/gestao/configpedidofaixaroyalties',
                sort: [{ property: 'idConfigPedidoFaixaRoyalties', direction: 'ASC' }],
            }}
            isOptionEqualToValue={(option: RoyaltiesOptionsProps, value: RoyaltiesOptionsProps) => option.idConfigPedidoFaixaRoyalties === value.idConfigPedidoFaixaRoyalties}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
            name={name}
        />
    );
}