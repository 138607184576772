import React, { useState } from 'react';

import HistoryIcon from '@mui/icons-material/History';
import { LoadingButton } from '@mui/lab';

import { PedidoLogModal } from 'components/modal/PedidoLogModal';
import { RowProps } from 'pages/compra/pedido-realizado/types';

type PedidoRealizadoLogsBtn = {
    rowProps: RowProps;
    isDisabledActions: boolean;
};

export function PedidoRealizadoLogsBtn({ isDisabledActions, rowProps }: PedidoRealizadoLogsBtn): JSX.Element {
    const [isOpenLogModal, setIsOpenLogModal] = useState<boolean>(false);

    return (
        <React.Fragment>
            <PedidoLogModal open={isOpenLogModal} setOpen={setIsOpenLogModal} idPedido={rowProps.idPedido} />

            <LoadingButton color="inherit" startIcon={<HistoryIcon />} disabled={isDisabledActions} onClick={() => setIsOpenLogModal(true)}>
                Logs
            </LoadingButton>
        </React.Fragment>
    );
}
