import React, { useCallback, useMemo } from 'react';

import { Switch } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { ConfiguracaoPedidoCompraListFilter, ConfiguracaoPedidoCompraSituacaoColumn } from 'pages/compra/configuracao-pedido/components';
import { ConfiguracaoPedidoCompraList, RequestConfiguracaoPedidoCompraList } from 'pages/compra/configuracao-pedido/types';
import { brlDate } from 'util/format';

const generateConfiguracaoPedidoCompraList = (data: ConfiguracaoPedidoCompraList[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idConfigPedido,
            idConfigPedido: item.idConfigPedido,
            dsConfigPedido: item.dsConfigPedido,
            dtInicioVigencia: item.dtInicioVigencia,
            dtFimVigencia: item.dtFimVigencia,
            dsTipoGrade: item.tipoGrade.dsTipoGrade,
            dsTipoPedido: item.tipoPedido.dsTipoPedido,
            fgAtivo: item.fgAtivo,
            fgVisivel: item.fgVisivel,
            idConfigPedidoStatus: item.idConfigPedidoStatus,
        }));
    }

    return [];
};

export function ConfiguracaoPedidoCompraListComp(): JSX.Element {
    const { filter, limit, page, sort, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { mutate } = useFormMutation();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: 'gestao/configpedido',
            sort: sort.length ? sort : [{ property: 'idConfigPedido', direction: 'DESC' }],
            nested: false,
            filter,
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(
        ['getListConfigPedido', requestOptions],
        (): Promise<RequestConfiguracaoPedidoCompraList> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: autoLoad,
        },
    );

    const handleAtivarConfigPedido = useCallback(
        ({ fgAtivo, idConfigPedido }: { fgAtivo: boolean; idConfigPedido: number }): void => {
            mutate({
                url: `/gestao/configpedido/${idConfigPedido}/ativo`,
                method: 'PUT',
                formData: {
                    fgAtivo,
                },
            });
        },
        [mutate],
    );

    const handleVisivelConfigPedido = useCallback(
        ({ fgVisivel, idConfigPedido }: { fgVisivel: boolean; idConfigPedido: number }): void => {
            mutate({
                url: `/gestao/configpedido/${idConfigPedido}/visivel`,
                method: 'PUT',
                formData: {
                    fgVisivel,
                },
            });
        },
        [mutate],
    );

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'idConfigPedido', headerName: 'Código' },
            { field: 'dsConfigPedido', headerName: 'Configuração', type: 'string', minWidth: 120, flex: 3 },
            { field: 'dsTipoPedido', headerName: 'Tipo de Pedido', type: 'string', minWidth: 120, flex: 1 },
            { field: 'dsTipoGrade', headerName: 'Grade', type: 'string', minWidth: 120, flex: 1 },
            {
                field: 'dtInicioVigencia',
                headerName: 'Início de Vigência',
                type: 'string',
                align: 'center',
                headerAlign: 'center',
                valueFormatter: ({ value }) => brlDate(`${value}T00:00:00`),
                minWidth: 120,
                flex: 1,
            },
            {
                field: 'dtFimVigencia',
                headerName: 'Fim de Vigência',
                type: 'string',
                align: 'center',
                headerAlign: 'center',
                valueFormatter: ({ value }) => brlDate(`${value}T00:00:00`),
                minWidth: 120,
                flex: 1,
            },
            {
                field: 'idConfigPedidoStatus',
                headerName: 'Situação',
                headerAlign: 'center',
                minWidth: 140,
                renderCell: ({ row }) => <ConfiguracaoPedidoCompraSituacaoColumn idStatus={row.idConfigPedidoStatus} />,
            },
            {
                field: 'visivel',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Visível',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => (
                    <Switch
                        checked={row.fgVisivel}
                        onChange={(e) => {
                            row.fgVisivel = e.target.checked;
                            handleVisivelConfigPedido({ fgVisivel: e.target.checked, idConfigPedido: row.idConfigPedido });
                        }}
                    />
                ),
            },
            {
                field: 'status',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Status',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => (
                    <Switch
                        checked={row.fgAtivo}
                        onChange={(e) => {
                            row.fgAtivo = e.target.checked;
                            handleAtivarConfigPedido({ fgAtivo: e.target.checked, idConfigPedido: row.idConfigPedido });
                        }}
                    />
                ),
            },
            {
                field: 'editar',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 100,
                renderCell: ({ row }) => (
                    <EditRemoveBtn
                        id={row.idConfigPedido}
                        refresh={refetch}
                        editUrl="/compra/configuracao-pedido/form/"
                        removeUrl={`/gestao/configpedido/${row.idConfigPedido}`}
                        showRemoveBtn={true}
                        cloneUrl={`/gestao/configpedido/${row.idConfigPedido}/clonar`}
                    />
                ),
            },
        ],
        [handleAtivarConfigPedido, handleVisivelConfigPedido, refetch],
    );

    const rows = useMemo(() => generateConfiguracaoPedidoCompraList(data?.data), [data]);

    return (
        <OneColumn title="Configuração de Pedido" createButton goBackButton>
            <ConfiguracaoPedidoCompraListFilter />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
