import React from 'react';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Button, SxProps, Tooltip } from '@mui/material';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { ProdutoCart } from 'pages/compra/pedido/types';

interface PedidoCompraAdicionarButtonProps {
    idProduto: number;
    produtoQtd: number;
    qtMinimaCompra: number;
    produtoDisabled: boolean;
    produtoCart: ProdutoCart | undefined;
    sx?: SxProps;
}

export function PedidoCompraAdicionarButton({
    produtoCart,
    idProduto,
    produtoDisabled,
    produtoQtd,
    sx,
}: PedidoCompraAdicionarButtonProps): JSX.Element {
    const { addProdCart, isSavingCarrinho } = usePedidoCompraContext();

    let produtoInCart = false;
    let qtProdutoIsEqual = false;
    let tooltipMessage = 'Produto não adicionado ao pedido';

    if (produtoCart) {
        const { fgCart, qtProdutoCart, qtProdutoList } = produtoCart;

        produtoInCart = fgCart;
        qtProdutoIsEqual = qtProdutoCart === qtProdutoList;
    }

    if (produtoInCart) {
        if (qtProdutoIsEqual) {
            tooltipMessage = 'Produto já adicionado ao carinho';
        } else {
            tooltipMessage = 'Produto já adicionado ao carinho, porém com quantidade diferente do informado';
        }
    }

    return (
        <Tooltip title={tooltipMessage}>
            <Button
                variant="contained"
                disabled={produtoQtd <= 0 || produtoDisabled || isSavingCarrinho}
                onClick={() => addProdCart(idProduto)}
                color={produtoInCart && qtProdutoIsEqual ? 'success' : 'info'}
                sx={{
                    ...sx,
                    height: 36,
                    minWidth: 36,
                    px: 0,
                }}
            >
                <ShoppingCartOutlinedIcon />
            </Button>
        </Tooltip>
    );
}
