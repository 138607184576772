import React, { useCallback, useEffect, useState } from 'react';
import tinymce, { EditorOptions } from 'tinymce';

import { Typography } from '@mui/material';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { UploadFilDataProps } from 'components/upload/types';
import { BaseEditor } from './BaseEditor';
import { useDebouncedCallback } from 'use-debounce';
import { UploadModal } from 'components/upload/components';
import { useShowError } from 'hooks/useShowError';
import { iconPdf } from 'styles/icons';

interface BlobInfo {
    id: () => string;
    name: () => string;
    filename: () => string;
    blob: () => Blob;
    base64: () => string;
    blobUri: () => string;
    uri: () => string | undefined;
}

type ProgressFn = (percent: number) => void;

interface EditorProps {
    value?: string;
    onInit?: (editor: EditorOptions) => void;
    limit?: number;
    onChange?: (value: string) => void;
    disabled?: boolean;
    onChangeText?: (text: string) => void;
}

export function Editor({ value, onInit, limit, onChange = () => {}, onChangeText = () => {}, disabled }: EditorProps): JSX.Element {
    const [fieldValue, setFieldValue] = useState(value);
    const [length, setLength] = useState(0);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const { showError } = useShowError();

    const { mutate, isLoading: uploadingEditorFile } = useUploadFileMutation();

    const handleInit = useCallback(
        (e: any, editor: EditorOptions): void => {
            setLength(editor.getContent({ format: 'text' }).length);

            if (typeof onInit === 'function') {
                onInit(editor);
            }
        },
        [onInit, setLength],
    );

    const debouncedHandleUpdate = useDebouncedCallback((fieldValue: any, editorText: string) => {
        onChange(fieldValue);
        onChangeText(editorText);
    }, 250);

    const handleEditorChange = useCallback(
        (fieldValue: any, activeEditor: any): void => {
            const editorText = activeEditor.getContent({ format: 'text' });

            setLength(editorText.length);
            setFieldValue(fieldValue);

            debouncedHandleUpdate(fieldValue, editorText);
        },
        [debouncedHandleUpdate],
    );

    const imageUploadHandler = useCallback(
        (blobInfo: BlobInfo, progress: ProgressFn): Promise<string> => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();

                formData.append('file', blobInfo.blob() as File);

                mutate(
                    {
                        formData,
                        progressCallBack: (progressEvent) => progress((progressEvent.loaded / progressEvent.total) * 100),
                    },
                    {
                        onSuccess: (response: UploadFilDataProps) => {
                            const { data } = response;

                            resolve(data.dsLink);
                        },
                        onError: () => {
                            reject(new Error('Ocorreu um erro ao salvar sua imagem.'));
                        },
                    },
                );
            });
        },
        [mutate],
    );

    const { mutate: mutateFile } = useUploadFileMutation({
        onSuccess: (response: UploadFilDataProps) => {
            const { data } = response;

            if (tinymce.activeEditor) {
                tinymce.activeEditor.dom.add(
                    tinymce.activeEditor.getBody(),
                    'div',
                    {
                        class: 'responsive-a4',
                    },
                    `<embed src="${data.dsLink}" type="application/pdf">`,
                );

                setOpenUploadModal(false);

                handleEditorChange(tinymce.activeEditor.getContent(), tinymce.activeEditor);
                setFile(null);
            }
        },
        onError: (error) => showError(error, 'Ocorreu um erro ao enviar seu arquivo, tente novamente.'),
    });

    useEffect(() => {
        if (value !== fieldValue) {
            setFieldValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            mutateFile({ formData });
        }
    }, [file]);

    return (
        <React.Fragment>
            <BaseEditor
                value={fieldValue}
                onInit={handleInit}
                onEditorChange={handleEditorChange}
                disabled={disabled || uploadingEditorFile}
                init={{
                    branding: false,
                    draggable_modal: true,
                    max_height: 2500,
                    min_height: 100,
                    placeholder: 'Digite seu texto...',
                    resize: true,
                    statusbar: false,
                    style_formats_autohide: true,
                    toolbar_sticky: true,
                    content_css: 'writer',
                    allow_unsafe_link_target: true,
                    custom_undo_redo_levels: 10,
                    default_link_target: '_blank', // autolink config
                    help_tabs: ['shortcuts'], // help config
                    language: 'pt_BR',
                    language_url: '/tinymce/langs/pt_BR.js',
                    images_upload_handler: imageUploadHandler,
                    entity_encoding: 'raw',
                    content_style: `
                        .responsive-a4 {
                            width: 595px;
                            height: 842px;
                            position: relative;
                            display: block;
                            margin: 0 auto;
                        }
                        .responsive-a4 iframe,
                        .responsive-a4 object,
                        .responsive-a4 embed {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }`,

                    setup: (editor: any) => {
                        editor.ui.registry.addIcon('icon-pdf', iconPdf);

                        editor.ui.registry.addButton('button-upload-pdf', {
                            icon: 'icon-pdf',
                            onAction: () => setOpenUploadModal(true),
                        });
                    },

                    toolbar:
                        'undo redo | formatselect | blocks | bold italic forecolor | link image code | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | button-upload-pdf | emoticons',

                    plugins: ['advlist', 'lists', 'anchor', 'autolink', 'autoresize', 'code', 'fullscreen', 'help', 'searchreplace', 'table', 'image', 'link', 'emoticons'],

                    mobile: {
                        menubar: true,
                    },
                }}
            />
            {typeof limit === 'number' && limit && (
                <Typography
                    variant="caption"
                    component="div"
                    sx={{
                        mt: 1,
                        color: length > limit ? 'error.main' : '',
                    }}
                >
                    Tamanho: {length} / {limit} caracteres
                </Typography>
            )}

            <UploadModal open={openUploadModal} setOpen={setOpenUploadModal} accept=".pdf" callback={({ file }) => setFile(file)} />
        </React.Fragment>
    );
}
