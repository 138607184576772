import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Checkbox } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { CissNumberField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ConfiguracaoPedidoCompraRemoveBtn } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraRemoveBtn';
import { ProdutosListProps, RequestProdutosProps } from 'pages/compra/configuracao-pedido/types';

interface ConfigPedidoCompraProdutosGridProps {
    valueFilter: string;
    setSelectedRows: Dispatch<SetStateAction<number[]>>;
    inativaProdutos: (idList: number[]) => void;
}

export function ConfiguracaoPedidoCompraProdutosGrid({
    valueFilter,
    setSelectedRows,
    inativaProdutos,
}: ConfigPedidoCompraProdutosGridProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { id } = useParams();
    const { content, setContent } = useFormContext();
    const { produtosList } = content ?? {};
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();

    const filterProdutos = useCallback(
        () =>
            produtosList.filter((item: ProdutosListProps) => {
                const { dsProduto, dsGrupo, cdProduto } = item.produto;

                return (
                    !valueFilter ||
                    dsProduto.toLowerCase().includes(valueFilter.trim().toLowerCase()) ||
                    dsGrupo?.toLowerCase().includes(valueFilter.trim().toLowerCase()) ||
                    cdProduto.toString().includes(valueFilter.trim().toLowerCase())
                );
            }),
        [produtosList, valueFilter],
    );

    const mapProdutos = useCallback(
        (item: ProdutosListProps) => ({
            id: item.produto.idProduto,
            idProduto: item.produto.idProduto,
            cdProduto: item.produto.cdProduto,
            dsProduto: item.produto.dsProduto,
            fgFracionavel: item.produto.fgFracionavel ?? item.produto?.unidadeMedida?.fgFracionavel ?? false,
            dsCategoria: item.produto.dsGrupo,
            dsMarca: item.produto.dsMarca,
            qtMinimaProduto: item.qtMinimaProduto,
            qtMaximaProduto: item.qtMaximaProduto,
            peOferta: item.peOferta,
            fgShowPeOferta: item.fgShowPeOferta,
        }),
        [],
    );

    const generateProdutosList = useCallback((): GridRowsProp => {
        if (Array.isArray(produtosList) && produtosList.length) {
            return filterProdutos().map(mapProdutos);
        }

        return [];
    }, [filterProdutos, mapProdutos, produtosList]);

    const requestOptions: RequestOptionsType = {
        url: `/gestao/configpedidoproduto/configpedido/${id}`,
    };

    const { fetchStatus, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestProdutosProps> = RequestListagem(requestOptions);

            return request;
        },
        {
            enabled: Boolean(id),
            onSuccess: (response) => {
                const { data } = response;

                setContent({ produtosList: data, initialProdutosList: data });
            },
        },
    );

    const handleAlterarFields = useCallback(
        (idProduto: number, value: number | boolean, nameField: string): void => {
            const newProdutosList = produtosList.map((produto: ProdutosListProps) => {
                if (produto.idProduto === idProduto) {
                    return { ...produto, [nameField]: value };
                }

                return produto;
            });

            setContent({ ...content, produtosList: newProdutosList });

            setActiveStepDirty();
        },
        [produtosList],
    );

    const columns: GridColDef[] = [
        { field: 'cdProduto', headerName: 'Código', type: 'number', width: 80 },
        { field: 'dsProduto', headerName: 'Descrição', type: 'string', flex: 1, minWidth: 220 },
        { field: 'dsMarca', headerName: 'Marca', type: 'string', minWidth: 70 },
        { field: 'dsCategoria', headerName: 'Categoria', type: 'string', minWidth: 100 },
        {
            field: 'qtMinimaProduto',
            headerName: 'Quantidade Mínima',
            minWidth: 80,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <CissNumberField
                    size="small"
                    inputType={row.fgFracionavel ? 'decimal' : 'number'}
                    allowNegative={false}
                    value={row.qtMinimaProduto}
                    onChange={(e) => handleAlterarFields(row.idProduto, Number(e.target.value), 'qtMinimaProduto')}
                />
            ),
        },
        {
            field: 'qtMaximaProduto',
            headerName: 'Quantidade Máxima',
            minWidth: 80,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <CissNumberField
                    size="small"
                    inputType={row.fgFracionavel ? 'decimal' : 'number'}
                    allowNegative={false}
                    value={row.qtMaximaProduto}
                    onChange={(e) => handleAlterarFields(row.idProduto, Number(e.target.value), 'qtMaximaProduto')}
                />
            ),
        },
        {
            field: 'peOferta',
            headerName: 'Desconto (%)',
            minWidth: 80,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <CissNumberField
                    size="small"
                    inputType="decimal"
                    maxValue={100}
                    forceMaxValue
                    allowNegative={false}
                    value={row.peOferta}
                    onChange={(e) => handleAlterarFields(row.idProduto, Number(e.target.value), 'peOferta')}
                />
            ),
        },
        {
            field: 'fgShowPeOferta',
            align: 'center',
            headerName: 'Exibir Desconto',
            minWidth: 60,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <Checkbox checked={row.fgShowPeOferta} onChange={(e, checked) => handleAlterarFields(row.idProduto, checked, 'fgShowPeOferta')} />
            ),
        },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 70,
            renderCell: ({ row }) => <ConfiguracaoPedidoCompraRemoveBtn onClick={() => inativaProdutos([row.idProduto])} />,
        },
    ];

    const rows = generateProdutosList();

    return (
        <PanelGrid
            rows={rows}
            rowsCount={rows.length}
            reload={refetch}
            loading={fetchStatus === 'fetching'}
            columns={columns}
            remote={false}
            checkboxSelection
            onClickRow={(selectionModel) => setSelectedRows(selectionModel as number[])}
        />
    );
}
