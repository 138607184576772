import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { CargoFormComp, CargoListComp } from 'pages/complementar/cargo/components';

export function CargoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_CARGO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <FormProvider>
                <CargoFormComp />
            </FormProvider>
        </ListagemProvider>
    );
}

export function CargoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_CARGO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <CargoListComp />
        </ListagemProvider>
    );
}
