import React from 'react';

import { Typography } from '@mui/material';

import { getColor, PedidoTotalizadorSincronizacaoStatus, SincronizacaoStatus } from 'pages/compra/pedido-totalizador/types';


interface StatusIntegracaoTextStatus {
    statusIntegracao: PedidoTotalizadorSincronizacaoStatus;
}

export function StatusIntegracaoTextStatus({ statusIntegracao }: StatusIntegracaoTextStatus): JSX.Element {
    return (
        <Typography
            sx={{
                display: 'inline',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: (theme) => theme.palette[getColor[SincronizacaoStatus[statusIntegracao.idPedidoTotalizadorSincronizacaoStatus] as keyof typeof SincronizacaoStatus]].main,
            }}
        >
            {statusIntegracao.dsPedidoTotalizadorSincronizacaoStatus}
        </Typography>
    );
}
