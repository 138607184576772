import React, { Dispatch, SetStateAction } from 'react';

import { useParams } from 'react-router-dom';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { RequestSubCategoriaArquivoListProps, SubCategoriaProps } from 'pages/arquivo/categoria/types';

const generateCategoriaList = (data: SubCategoriaProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idCategoriaArquivo: item.idCategoriaArquivo,
            dsSubCategoria: item.dsCategoriaArquivo,
            qtArquivos: item.qtArquivos,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function DetalhesSubCategoriaList(): JSX.Element | null {
    const RequestListagem = useRequestListagem();

    const { id } = useParams();
    const { validRouterParams } = useValidation();
    const validId = validRouterParams({ id });

    const requestOptions: RequestOptionsType = {
        url: '/gestao/categoriaarquivo',
        columns: 'idCategoriaArquivo,idCategoriaArquivoPai,dsCategoriaArquivoPai,dsCategoriaArquivo,fgAtivo,qtArquivos',
        filter: [{ property: 'idCategoriaArquivoPai', value: id, operator: 'eq', logicalOperator: 'AND' }],
    };

    const { data, fetchStatus, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestSubCategoriaArquivoListProps> = RequestListagem(requestOptions).then((result) => result.data);
            return request;
        },
        { enabled: validId },
    );

    const columns: GridColDef[] = [
        { field: 'dsSubCategoria', headerName: 'Subcategoria', minWidth: 120, flex: 1 },
        { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean', minWidth: 70 },
        { field: 'qtArquivos', headerName: 'Arquivos', type: 'number', minWidth: 80, width: 100 },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            minWidth: 80,
            renderCell: ({ row }) => (
                <EditRemoveBtn id={row.idCategoriaArquivo} editUrl="/arquivo/categoria/form/" removeUrl={`gestao/categoriaarquivo/${row.idCategoriaArquivo}`} sx={{ ml: 'auto', p: 1.5 }} />
            ),
        },
    ];

    const rows = generateCategoriaList(data?.data);

    if (rows.length) {
        return (
            <Block>
                <SubTitle label="Subcategorias" />

                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
            </Block>
        );
    } else {
        return null;
    }
}
