import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface SituacaoPedidoLoteProps {
    idStatusProcessamento: number;
    dsStatusProcessamento: string;
}

type SituacaoPedidoLoteAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function SituacaoPedidoLoteAutoComplete({ value, error, multiple = true, disabled = false, sx, onChange = () => {} }: SituacaoPedidoLoteAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Situação"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsStatusProcessamento}"
            optionStringTemplate="{dsStatusProcessamento}"
            requestOptions={{
                url: '/compra/statusprocessamento',
                sort: [{ property: 'dsStatusProcessamento', direction: 'ASC' }],
                columns: 'idStatusProcessamento,dsStatusProcessamento',
            }}
            isOptionEqualToValue={(option: SituacaoPedidoLoteProps, value: SituacaoPedidoLoteProps) => option.idStatusProcessamento === value.idStatusProcessamento}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
        />
    );
}
