import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { FilterValuesProps } from 'hooks/useFilter';
import { PreenchimentoExportarRelatorioBtn } from 'pages/financeiro/dre/preenchimento/components/atoms/PreenchimentoExportarRelatorioBtn';

interface DrePainelExportacaoProps {
    disableBtn: boolean;
    filter: FilterValuesProps[];
}

export function PreenchimentoPainelExportacao({ disableBtn, filter }: DrePainelExportacaoProps): JSX.Element {
    return (
        <Stack direction="row" alignItems="center" sx={{ gap: 2 }} flexWrap="wrap">
            <Box sx={{ flex: 1, display: 'inherit', alignItems: 'inherit' }}>
                <TableViewOutlinedIcon color="primary" />

                <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                <ChevronRightOutlinedIcon color="primary" />
            </Box>

            <PreenchimentoExportarRelatorioBtn disabled={disableBtn} label={'Relatório em .xlsx'} sx={{ color: 'green', borderColor: 'green' }} filter={filter} />
        </Stack>
    );
}
