import { useDomainConfigContext } from 'context/DomainConfig';

export function useCustomFavicon(): void {
    const position = document.getElementById('favicon_place');
    const { data } = useDomainConfigContext();
    const faviconString = data?.favicon?.join('');

    if (position && position.outerHTML && faviconString.length) {
        position.outerHTML = faviconString;
    }
}
