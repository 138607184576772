import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function LocalFuncionarioGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterCdLocalFuncionario, setFilterCdLocalFuncionario] = useState<FilterValuesProps>({
        property: 'cdLocalFuncionario',
        operator: 'eq',
        value: null,
    });
    const [filterDsLocalFuncionario, setFilterDsLocalFuncionario] = useState<FilterValuesProps>({
        property: 'dsLocalFuncionario',
        operator: 'like',
        value: null,
    });

    const [filterFgAtivo, setFilterFgAtivo] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        idProperty: 'vlStatus',
        operator: 'eq',
        value: true,
    });

    const filters = [
        { value: filterCdLocalFuncionario, setter: setFilterCdLocalFuncionario },
        { value: filterDsLocalFuncionario, setter: setFilterDsLocalFuncionario },
        { value: filterFgAtivo, setter: setFilterFgAtivo },
    ];

    useEffect(() => setDefaultFilters({ filters, preventQueryParamsEmptyStart: true }), []);

    return (
        <Filter reset={() => handleFilterReset({ filters })} submit={() => handleFilterSubmit({ filters })}>
            <Grid item lg={3}>
                <CissTextField
                    label="Código"
                    value={filterCdLocalFuncionario.value}
                    onChange={(e) => setFilterCdLocalFuncionario((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={6}>
                <CissTextField
                    label="Local de entrega"
                    value={filterDsLocalFuncionario.value}
                    onChange={(e) => setFilterDsLocalFuncionario((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <StatusField
                    value={filterFgAtivo.value}
                    onChange={(e, value) => setFilterFgAtivo((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>
        </Filter>
    );
}
