import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterCentroCusto(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterIdCentroCusto, seFilterIdCentroCusto] = useState<FilterValuesProps>({
        property: 'idCentroCusto',
        operator: 'like',
        value: null,
        idProperty: 'idCentroCusto',
    });
    const [filterDsCentroCusto, setFilterDsCentroCusto] = useState<FilterValuesProps>({
        property: 'dsCentroCusto',
        operator: 'like',
        value: null,
        idProperty: 'dsCentroCusto',
    });
    const [filterCdCentroCusto, setFilterCdCentroCusto] = useState<FilterValuesProps>({
        property: 'cdCentroCusto',
        operator: 'like',
        value: null,
        idProperty: 'cdCentroCusto',
    });

    const [fgAtivo, setFgAtivo] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'fgAtivo',
    });
    const filters = [
        { value: filterIdCentroCusto, setter: seFilterIdCentroCusto },
        { value: filterDsCentroCusto, setter: setFilterDsCentroCusto },
        { value: filterCdCentroCusto, setter: setFilterCdCentroCusto },
        { value: fgAtivo, setter: setFgAtivo },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <CissTextField
                    label="Código"
                    value={filterIdCentroCusto.value}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => seFilterIdCentroCusto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Nome do centro"
                    value={filterDsCentroCusto.value}
                    onChange={(e) => setFilterDsCentroCusto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Código de Integração"
                    value={filterCdCentroCusto.value}
                    onChange={(e) => setFilterCdCentroCusto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
