import React, { useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Radio, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { TipoLojaAutoComplete } from 'components/autocompletes';
import { RoyaltiesAutoComplete } from 'components/autocompletes/RoyaltiesAutoComplete';
import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { StateFieldFormik } from 'components/fields/StateFieldFormik';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { useFocusErrorFormik } from 'hooks/useFocusErrorFormik';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    ConfiguracaoPedidoCompraImportacaoUploadBtn,
    ConfiguracaoPedidoCompraLojasGrid,
    ConfiguracaoPedidoCompraLojasModal,
    ConfiguracaoPedidoHeaderActions,
    ValueChangeListener,
} from 'pages/compra/configuracao-pedido/components';
import {
    CondicoesVisualizacaoProps,
    initialCondicoesVisualizacaoValues,
    LojasProps,
    EstadosProps,
    ConfigPedidoLojasProps,
    ConfigPedidoEstadosProps,
} from 'pages/compra/configuracao-pedido/types';

const getLojasFromConfigPedidoLojas = (configPedidoLojas: ConfigPedidoLojasProps[]): LojasProps[] => {
    return configPedidoLojas.map((configPedidoLoja) => {
        const { loja, peRoyalties, vlPedidoMinimo, tipoControlePedidoMinimo } = configPedidoLoja;

        loja.peRoyalties = peRoyalties;
        loja.vlPedidoMinimo = vlPedidoMinimo;
        loja.tipoControlePedidoMinimo = tipoControlePedidoMinimo;

        return { ...loja, peRoyalties, vlPedidoMinimo, tipoControlePedidoMinimo };
    });
};

export function ConfiguracaoPedidoCompraCondicoesVisualizacao(): JSX.Element {
    const [formData, setFormData] = useState<CondicoesVisualizacaoProps>(initialCondicoesVisualizacaoValues);
    const { mutate, isLoading: mutateIsLoading } = useFormMutation();
    const { setActiveStepSaved, setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { content, setContent } = useFormContext();
    const RequestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { lojasList } = content ?? {};
    const { id } = useParams();
    const { focusError } = useFocusErrorFormik();

    const [estadosList, setEstadosList] = useState<EstadosProps[]>([]);
    const [initialEstadosList, setInitialEstadosList] = useState<EstadosProps[]>([]);
    const [initialLojasList, setInitialLojasList] = useState<LojasProps[]>([]);
    const [valueFilter, setValueFilter] = useState<string>('');
    const [selectedLojas, setSelectedLojas] = useState<LojasProps[]>([]);
    const [openLojasModal, setOpenLojasModal] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    // Cria o objeto usado usado na lista de estados com os dados que serão enviados na request de post.
    const createConfigPedidoEstados = useCallback(
        ({ estado, fgAtivo }: any): ConfigPedidoEstadosProps => {
            return {
                fgAtivo,
                estado,

                configPedido: {
                    idConfigPedido: Number(id),
                },
            };
        },
        [id],
    );

    // Monta a lista com os dados do estado para a request de post.
    const getConfigPedidoEstados = useCallback(
        ({ tpDisponibilizacao }: CondicoesVisualizacaoProps): ConfigPedidoEstadosProps[] => {
            let newEstadosList: ConfigPedidoEstadosProps[] = [];

            if (tpDisponibilizacao === 'estados') {
                newEstadosList = estadosList.map((estado: EstadosProps) => {
                    return createConfigPedidoEstados({ estado, fgAtivo: true });
                });

                initialEstadosList.forEach((estado: EstadosProps) => {
                    const estadoExist = estadosList.find((item: EstadosProps) => item.idEstado === estado.idEstado);

                    if (!estadoExist) {
                        newEstadosList.push(createConfigPedidoEstados({ estado, fgAtivo: false }));
                    }
                });
            } else {
                newEstadosList = initialEstadosList.map((estado: EstadosProps) => {
                    return createConfigPedidoEstados({ estado, fgAtivo: false });
                });
            }

            return newEstadosList;
        },
        [estadosList, initialEstadosList, createConfigPedidoEstados],
    );

    // Cria o objeto usado usado na lista de lojas com os dados que serão enviados na request de post.
    const createConfigPedidoLojas = useCallback(
        ({ loja, fgAtivo }: { loja: LojasProps; fgAtivo: boolean }): ConfigPedidoLojasProps => {
            const { peRoyalties, vlPedidoMinimo, tipoControlePedidoMinimo } = loja;

            return {
                fgAtivo,
                loja,
                peRoyalties,
                vlPedidoMinimo,
                tipoControlePedidoMinimo,

                configPedido: {
                    idConfigPedido: Number(id),
                },
            };
        },
        [id],
    );

    // Monta a lista com os dados da loja para a request de post.
    const getConfigPedidoLojas = useCallback(
        ({ tpDisponibilizacao }: CondicoesVisualizacaoProps): ConfigPedidoLojasProps[] => {
            let newLojasList: ConfigPedidoLojasProps[] = [];

            if (tpDisponibilizacao === 'lojas' && Array.isArray(lojasList)) {
                // Insere todas as novas lojas na lista
                newLojasList = lojasList.map((loja: LojasProps) => {
                    return createConfigPedidoLojas({ loja, fgAtivo: true });
                });

                // Caso exista uma lista base de lojas (advinda do get das lojas), verifica-se se há alguma loja que não está na nova lista, inserindo esta e definindo seu fgAtivo=false, pois será desabilitada.
                initialLojasList.forEach((loja: LojasProps) => {
                    const lojaExist = lojasList.find((item: LojasProps) => item.idLoja === loja.idLoja);

                    if (!lojaExist) {
                        newLojasList.push(createConfigPedidoLojas({ loja, fgAtivo: false }));
                    }
                });
            } else {
                // Caso esteja salvando os estados, deve-se mandar todas as lojas com o fgAtivo=false para as desabilitar.
                newLojasList = initialLojasList.map((loja: LojasProps) => {
                    return createConfigPedidoLojas({ loja, fgAtivo: false });
                });
            }

            return newLojasList;
        },
        [initialLojasList, lojasList, createConfigPedidoLojas],
    );

    const requestOptions: RequestOptionsType = {
        url: `/gestao/configpedidocondicaovisualizacao/configpedido/${id}`,
    };

    const { isFetching } = useQuery<CondicoesVisualizacaoProps>([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: true,
        onSuccess: (response) => {
            const { configPedidoEstados, configPedidoLojas, configPedidoFaixaRoyalties } = response;

            let tpDisponibilizacao = 'lojas';

            if (configPedidoEstados && configPedidoEstados?.length > 0) {
                const newEstadosList = configPedidoEstados.map((item) => item.estado);

                tpDisponibilizacao = 'estados';

                setEstadosList(newEstadosList);
                setInitialEstadosList(newEstadosList);
            } else if (configPedidoLojas && configPedidoLojas.length > 0) {
                const newLojasList = getLojasFromConfigPedidoLojas(configPedidoLojas);

                setContent({ lojasList: newLojasList });
                setInitialLojasList(newLojasList);
            }

            setFormData({
                ...response,
                tpDisponibilizacao,
                fgLojaFaixaRoyalties: Boolean(configPedidoFaixaRoyalties?.idConfigPedidoFaixaRoyalties),
                configPedidoFaixaRoyalties: configPedidoFaixaRoyalties?.idConfigPedidoFaixaRoyalties ? configPedidoFaixaRoyalties : null,
            });
        },
    });

    const getFormData = useCallback(
        (values: CondicoesVisualizacaoProps): CondicoesVisualizacaoProps => {
            return {
                ...values,
                idConfigPedido: Number(id),
                configPedidoLojas: getConfigPedidoLojas(values),
                configPedidoEstados: getConfigPedidoEstados(values),
                configPedidoFaixaRoyalties: values.fgLojaFaixaRoyalties ? values.configPedidoFaixaRoyalties : null,
            };
        },
        [getConfigPedidoEstados, getConfigPedidoLojas, id],
    );

    // Envia os dados para o backend
    const onSubmitForm = (values: CondicoesVisualizacaoProps, formik: FormikHelpers<CondicoesVisualizacaoProps>): void => {
        const { tpDisponibilizacao } = values;

        mutate(
            {
                formik,
                url: '/gestao/configpedidocondicaovisualizacao',
                formData: getFormData(values),
                preventSnack: true,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Condições de Visualização salvo com sucesso', { variant: 'success' });

                    setActiveStepSaved();

                    if (tpDisponibilizacao === 'estados') {
                        setInitialEstadosList(estadosList);
                        setInitialLojasList([]);
                        setContent({ lojasList: [] });
                    } else if (tpDisponibilizacao === 'lojas') {
                        setInitialLojasList(lojasList);
                        setEstadosList([]);
                        setInitialEstadosList([]);
                    }
                },
                onError: (error: any) => showError(error, 'Erro ao salvar Condições de Visualização'),
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                configPedidoFaixaRoyalties: Yup.object()
                    .nullable()
                    .when('fgLojaFaixaRoyalties', {
                        is: true,
                        then: (schema) => schema.required(),
                    }),
            }),
        [],
    );

    const handleAdicionarLojas = useCallback(() => {
        let adicionarLojas = true;

        const newLojas = selectedLojas.filter((loja) => {
            const lojaInList = lojasList && lojasList.find((lojaList: LojasProps) => lojaList.idLoja === loja.idLoja);

            if (lojaInList) {
                if (lojaInList.fgAtivo !== false) {
                    enqueueSnackbar(`A loja ${lojaInList.dsNomeFantasia} já existe na lista de lojas`, { variant: 'error' });

                    adicionarLojas = false;

                    return false;
                }
            }

            return true;
        });

        if (adicionarLojas) {
            const newLojasList = [...(lojasList ? lojasList : []), ...newLojas];

            setOpenLojasModal(false);

            setActiveStepDirty();

            setContent({ ...content, lojasList: newLojasList });
        }
    }, [content, enqueueSnackbar, lojasList, selectedLojas, setActiveStepDirty, setContent]);

    const removeLojas = useCallback(
        (idList: number[]) => {
            const newLojasList = lojasList.filter((loja: LojasProps) => !idList.includes(loja.idLoja));

            setActiveStepDirty();

            setContent({ ...content, lojasList: newLojasList });
        },
        [content, lojasList, setContent, setActiveStepDirty],
    );

    return (
        <Formik initialValues={formData} onSubmit={onSubmitForm} enableReinitialize validationSchema={validationSchema}>
            {(formik: FormikProps<CondicoesVisualizacaoProps>) => {
                focusError(formik.errors);

                return (
                    <Form>
                        <FullLoading loading={mutateIsLoading} />

                        <ConfiguracaoPedidoCompraLojasModal
                            open={openLojasModal}
                            setOpen={setOpenLojasModal}
                            handleAdicionarLojas={handleAdicionarLojas}
                            selectedLojas={selectedLojas}
                            setSelectedLojas={setSelectedLojas}
                        />

                        <Block>
                            <SubTitle label="Segmentação" />

                            <Stack>
                                <Typography>A configuração do pedido será disponibilizado para:</Typography>

                                <FormGroup row sx={{ pt: 1, pb: 2 }}>
                                    <FormControlLabel
                                        label="Lojas"
                                        control={<Radio />}
                                        checked={formik.values.tpDisponibilizacao === 'lojas'}
                                        onChange={() => formik.setFieldValue('tpDisponibilizacao', 'lojas')}
                                        value="lojas"
                                    />

                                    <FormControlLabel
                                        label="Estados"
                                        control={<Radio />}
                                        checked={formik.values.tpDisponibilizacao === 'estados'}
                                        onChange={() => formik.setFieldValue('tpDisponibilizacao', 'estados')}
                                        value="estados"
                                    />
                                </FormGroup>
                            </Stack>

                            <Box>
                                {formik.values.tpDisponibilizacao === 'lojas' && (
                                    <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                                        <Grid item>
                                            <ConfiguracaoPedidoCompraImportacaoUploadBtn
                                                url="/gestao/configpedidoloja/upload"
                                                onSend={() => setActiveStepDirty()}
                                                onSuccessUploadFile={(data)=> {
                                                    setContent({ ...content, lojasList: getLojasFromConfigPedidoLojas(data) });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_LOJAS_CONFIG_PEDIDO" />
                                        </Grid>
                                    </Grid>
                                )}

                                {formik.values.tpDisponibilizacao === 'estados' && (
                                    <Field
                                        component={StateFieldFormik}
                                        onChange={(e: any, value: EstadosProps[]) => {
                                            setEstadosList(value);

                                            setActiveStepDirty();
                                        }}
                                        disabled={false}
                                        value={estadosList}
                                        multiple={true}
                                    />
                                )}
                            </Box>
                        </Block>

                        {formik.values.tpDisponibilizacao === 'lojas' && (
                            <Block>
                                <SubTitle label="Lojas" />

                                <ConfiguracaoPedidoHeaderActions
                                    setValueFilter={setValueFilter}
                                    selectedRows={selectedRows}
                                    searchPlaceholder="Nome da loja..."
                                    onClickAdd={() => setOpenLojasModal(true)}
                                    onClickRemove={() => removeLojas(selectedRows)}
                                />

                                <ListagemProvider>
                                    <ConfiguracaoPedidoCompraLojasGrid
                                        valueFilter={valueFilter}
                                        removeLojas={removeLojas}
                                        setSelectedRows={setSelectedRows}
                                        loading={isFetching}
                                    />
                                </ListagemProvider>
                            </Block>
                        )}

                        <Block>
                            <SubTitle label="Condições" />

                            <TipoLojaAutoComplete
                                name="tipoLojaVisualizar"
                                label="Somente lojas"
                                value={formik.values.tipoLojaVisualizar || null}
                                onChange={(e, value) => formik.setFieldValue('tipoLojaVisualizar', value)}
                                error={formik.errors.tipoLojaVisualizar}
                                sx={{ width: { xs: '100%', md: 170 }, mb: 1 }}
                                size="small"
                            />

                            <FormGroup>
                                <FormControlLabel
                                    label={'Somente lojas com meta atingida'}
                                    name="fgLojaMetaAtingida"
                                    control={<Checkbox />}
                                    checked={formik.values.fgLojaMetaAtingida}
                                    onChange={(e, checked) => formik.setFieldValue('fgLojaMetaAtingida', checked)}
                                />

                                <Grid container gap={1}>
                                    <Grid item>
                                        <FormControlLabel
                                            label={'Somente lojas com % de royalties igual à:'}
                                            name="fgLojaFaixaRoyalties"
                                            control={<Checkbox />}
                                            checked={formik.values.fgLojaFaixaRoyalties}
                                            onChange={(e, checked) => formik.setFieldValue('fgLojaFaixaRoyalties', checked)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md>
                                        <RoyaltiesAutoComplete
                                            name="configPedidoFaixaRoyalties"
                                            value={formik.values.configPedidoFaixaRoyalties}
                                            error={formik.errors.configPedidoFaixaRoyalties}
                                            onChange={(e, value) => formik.setFieldValue('configPedidoFaixaRoyalties', value)}
                                            size="small"
                                            disabled={!formik.values.fgLojaFaixaRoyalties}
                                            sx={{ width: { md: 144, xs: 'auto' } }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Block>

                        <Box
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                zIndex: 3,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2,
                                mx: -2,
                                backgroundColor: (theme) => theme.palette.grey[100],
                            }}
                        >
                            <LoadingButton variant="contained" loading={mutateIsLoading} type="submit" size="large">
                                Salvar
                            </LoadingButton>
                        </Box>

                        <ValueChangeListener />
                    </Form>
                );
            }}
        </Formik>
    );
}
