import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { Marcador } from 'pages/calendario/evento/marcadores/types';

export function EventoMarcadoresListComp(): JSX.Element {
    const RequestListagem = useRequestListagem();

    const url = '/gestao/calendarioeventotag/';
    const RequestOptions: RequestOptionsType = {
        url,
        sort: [{ property: 'idCalendarioEventoTag', direction: 'DESC' }],
    };

    const { data, refetch, fetchStatus } = useQuery([RequestOptions], () => RequestListagem(RequestOptions).then((res) => res.data));

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'dsCalendarioEventoTag', headerName: 'Nome do marcador', type: 'string', flex: 1, minWidth: 120 },
            {
                field: 'dsCorTag',
                headerName: 'Cor',
                type: 'string',
                flex: 1,
                minWidth: 120,
                renderCell: ({ row }) => {
                    return (
                        <React.Fragment>
                            {row.dsCorTag}
                            <div style={{ backgroundColor: row.dsCorTag, width: 25, height: 25, marginLeft: 10, borderRadius: '100%' }} />
                        </React.Fragment>
                    );
                },
            },
            {
                field: 'acoes',
                align: 'center',
                width: 100,
                minWidth: 80,
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: ({ row }) => {
                    return <EditRemoveBtn id={row.idCalendarioEventoTag} refresh={refetch} removeUrl={`${url}${row.idCalendarioEventoTag}`} sx={{ ml: 'auto', p: 1.5 }} />;
                },
            },
        ],
        [],
    );

    const mapToRows = (data: Marcador[]): GridRowsProp => {
        return data.map((marcador, index) => ({
            id: index + 1,
            idCalendarioEventoTag: marcador.idCalendarioEventoTag,
            dsCalendarioEventoTag: marcador.dsCalendarioEventoTag,
            dsCorTag: marcador.dsCorTag,
        }));
    };

    return (
        <OneColumn title="Marcadores" createButton goBackButton>
            <Block>
                <PanelGrid
                    rows={mapToRows(data?.data || [])}
                    rowsCount={data?.total || 0}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={columns}
                    remote={false}
                    visibleRows="all"
                    columnBorder
                    pinnedColumns={{
                        left: ['title'],
                        right: ['acumulado'],
                    }}
                />
            </Block>
        </OneColumn>
    );
}
