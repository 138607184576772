import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { Alert } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikHelpers } from 'formik';

import { ExtraButtonListProps } from 'components/buttons/ExtraButtons';
import { Bbar } from 'components/docked';
import { ToggleFgAtivo } from 'components/fields';
import { FullLoading } from 'components/loading';
import { FormDirtyModal } from 'components/modal/FormDirtyModal';
import { OneColumn } from 'components/page/OneColumn';
import { useFormContext } from 'context/FormContext';
import { useFocusErrorFormik } from 'hooks/useFocusErrorFormik';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { getErrorMessage } from 'util/error';

interface FormPageProps {
    children: any;
    title: string;
    values: any;
    url?: string;
    fgAtivo?: boolean | string;
    disableQuery?: boolean;
    hideBbar?: boolean;
    keepOriginalTitle?: boolean;
    disableSaveBtn?: boolean;
    onSubmit: (values: any, formik: FormikHelpers<any>) => void;
    validationSchema?: any;
    mapContentToInitialValues?: (content: any) => any;
    extraButtons?: ExtraButtonListProps | ExtraButtonListProps[];
    extraTopButtons?: ExtraButtonListProps | ExtraButtonListProps[];
    goBackButton?: boolean | string;
    extraContent?: JSX.Element | null;
    validateOnChange?: boolean;
    validateOnSubmit?: boolean;
    queryWithoutId?: boolean;
}

const generateTitle = (title: string, id?: string): string => {
    const action = id ? 'Editar' : 'Inserir';

    return `${action} ${title}`;
};

export const FormPage = forwardRef(
    (
        {
            children,
            title: pageTitle,
            keepOriginalTitle,
            url,
            values,
            onSubmit,
            fgAtivo,
            validationSchema,
            mapContentToInitialValues,
            disableQuery,
            hideBbar,
            extraButtons,
            disableSaveBtn,
            goBackButton = true,
            extraContent,
            extraTopButtons = [],
            validateOnChange = false,
            validateOnSubmit = false,
            queryWithoutId = false,
        }: FormPageProps,
        ref,
    ) => {
        const { validRouterParams } = useValidation();
        const { id } = useParams();
        const { isLoading, setContent, content } = useFormContext();
        const RequestListagem = useRequestListagem();
        const { focusError } = useFocusErrorFormik();
        const formik = useRef(null);

        let initialValues = values || {};

        const validId = validRouterParams({ id });
        const title = keepOriginalTitle ? pageTitle : generateTitle(pageTitle, id);

        const requestOptions: RequestOptionsType = {
            url: `${url}${queryWithoutId ? '' : id}`,
        };

        const { fetchStatus, data, error, refetch } = useQuery(
            [requestOptions],
            () => {
                const request: Promise<any> = RequestListagem(requestOptions);
                return request;
            },
            {
                enabled: Boolean(!disableQuery && ((id && validId) || queryWithoutId) && url && !content),
                onSuccess: (response) => {
                    const content = response.data.data;

                    setContent(content);
                },
            },
        );

        if (content) {
            initialValues = deepmerge(initialValues, content);
        }

        if (typeof mapContentToInitialValues === 'function' && content) {
            initialValues = mapContentToInitialValues(content);
        }

        const status = data?.status;
        const [msgError] = getErrorMessage(error);
        const showForm = ((id && validId) || !id) && Boolean(!error) && (!data || data.status !== 204);

        useImperativeHandle(ref, () => ({ formik: formik.current }), [formik]);

        // quando navega na mesma rota, trocando somente ID, precisa refazer a request para pegar os dados do novo ID
        useEffect(() => {
            if (validId && content) {
                refetch();
            }
        }, [id, refetch, validId]);

        return (
            <React.Fragment>
                <FullLoading loading={fetchStatus === 'fetching'} />

                <OneColumn title={title} goBackButton={goBackButton} extraButtons={extraTopButtons}>
                    <React.Fragment>
                        {id && !validId && <Alert severity="error">Verifique se a URL informada é valida.</Alert>}

                        {Boolean(error) && <Alert severity="error">{msgError}</Alert>}

                        {status === 204 && <Alert severity="warning">Nenhum registro encontrado com o id {id}</Alert>}

                        {showForm && (
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                enableReinitialize
                                validationSchema={validationSchema}
                                innerRef={formik}
                                validateOnChange={validateOnChange}
                                validateOnSubmit={validateOnSubmit}
                            >
                                {(formik: any) => {
                                    focusError(formik.errors);

                                    return (
                                        <React.Fragment>
                                            {typeof children === 'function' ? children(formik) : children}

                                            <FormDirtyModal dirty={formik.dirty} />

                                            {!hideBbar && (
                                                <Bbar
                                                    isLoading={isLoading}
                                                    extraButtons={extraButtons}
                                                    disablePrimaryBtn={disableSaveBtn}
                                                    primaryBtnHandler={formik.submitForm}
                                                >
                                                    {fgAtivo && <ToggleFgAtivo disabled={fgAtivo === 'disabled'} />}
                                                    {extraContent}
                                                </Bbar>
                                            )}
                                        </React.Fragment>
                                    );
                                }}
                            </Formik>
                        )}
                    </React.Fragment>
                </OneColumn>
            </React.Fragment>
        );
    },
);
