import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { FormFilterTag } from '..';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ComunicadoTag, RequestComunicadoTag } from 'pages/comunicado/tag/types';

const generateTagList = (data: ComunicadoTag[] | undefined): GridRowsProp => {
    const list = data;

    if (Array.isArray(list) && list.length) {
        return list.map((item, index) => ({
            id: index + 1,
            idTagComunicado: item.idTagComunicado,
            dsTagComunicado: item.dsTagComunicado,
        }));
    }
    return [];
};

export function TagListComp(): JSX.Element {
    const { filter, page, limit, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/tagcomunicado',
        filter,
        sort: sort && sort.length > 0 ? sort : [{ property: 'idTagComunicado', direction: 'DESC' }],
        columns: 'idTagComunicado,dsTagComunicado',
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestComunicadoTag> => RequestListagem(requestOptions).then((res: any) => res.data));

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'idTagComunicado', headerName: 'Código', alignItems: 'center', flex: 1, type: 'string' },
            { field: 'dsTagComunicado', headerName: 'Descrição', alignItems: 'center', flex: 4, type: 'string' },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                renderCell: ({ row }) => (
                    <EditRemoveBtn
                        id={row.idTagComunicado}
                        showEditBtn={true}
                        showRemoveBtn={true}
                        editUrl="form/"
                        removeUrl={`gestao/tagcomunicado/${row.idTagComunicado}`}
                        sx={{ ml: 'auto', p: 1.5 }}
                    />
                ),
            },
        ],
        [refetch],
    );

    const rows = generateTagList(data?.data);

    return (
        <OneColumn title="Tags Comunicado" goBackButton createButton>
            <FormFilterTag />
            
            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
            </Block>
        </OneColumn>
    );
}
