import React, { useState, useMemo, useCallback } from 'react';

import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import { Box, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import { CissTextField } from 'components/fields/';
import { FilterValuesProps } from 'hooks/useFilter';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface CategoriaArquivoOptionsProps {
    idCategoriaArquivo: number;
    dsCategoriaArquivo: string;
    subCategoriasArquivo?: CategoriaArquivoOptionsProps[];
    categoriaArquivoPai: {
        idCategoriaArquivo: number;
        dsCategoriaArquivo: string;
    };
}

interface CategoryFieldProps extends AutocompleteProps<CategoriaArquivoOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
    limitViewLevelsByCategoria: string | boolean;
}

interface RequestCategoriaArquivo extends RequestListagemProps {
    data: CategoriaArquivoOptionsProps[];
}

export function CategoriaArquivoFieldFormik({ onChange, multiple = false, limitViewLevelsByCategoria = false, label = 'Categoria Pai', ...PropsFormik }: CategoryFieldProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [field, { error }] = useField(PropsFormik.field.name);
    const [inputValue, setInputValue] = useState('');
    const [queryValue] = useDebounce(inputValue, 500);
    const { tokenInfo } = useGenericAuth();

    const getFiltersRequestOptions = useMemo(() => {
        const filter: FilterValuesProps[] = [{ property: 'dsCategoriaArquivo', value: queryValue, operator: 'like' }];

        if (tokenInfo?.tpUsuario !== 'SUPORTE_CISS') {
            filter.push({ property: 'fgAtivo', value: true, operator: 'eq' });
        }

        return filter;
    }, [tokenInfo?.tpUsuario, queryValue]);

    const requestOptions: RequestOptionsType = {
        url: 'gestao/categoriaarquivo/listagem',
        query: queryValue,
        sort: [{ property: 'dsCategoriaArquivo', direction: 'ASC' }],
        filter: getFiltersRequestOptions,
        limit: 50,
    };

    const { isLoading, fetchStatus, data } = useQuery([requestOptions], (): Promise<RequestCategoriaArquivo> => RequestListagem(requestOptions).then((res) => res.data), {
        onError: () => enqueueSnackbar('Falha ao processar solicitação de categorias', { variant: 'error' }),
    });

    const getDataFiltered = useCallback(
        (limitePais = 1): CategoriaArquivoOptionsProps[] =>
            data?.data ? data.data.filter((categoria: CategoriaArquivoOptionsProps) => categoria.subCategoriasArquivo && categoria.subCategoriasArquivo.length <= limitePais) : [],
        [data],
    );

    const getOptions = useMemo((): CategoriaArquivoOptionsProps[] => {
        const { data: listCategorias } = data || {};

        if (!listCategorias) {
            return [];
        }

        if (!limitViewLevelsByCategoria) {
            return listCategorias;
        }

        // Caso o valor da categoria for string ou number, faz a validação
        if (typeof limitViewLevelsByCategoria !== 'boolean') {
            const categoriaForm = listCategorias.find((categoria: CategoriaArquivoOptionsProps) => categoria.idCategoriaArquivo === Number(limitViewLevelsByCategoria));

            if (categoriaForm) {
                // Encontra categorias filhas da categoria principal
                const listSubCategoriasForm = listCategorias.filter(
                    (categoria: CategoriaArquivoOptionsProps) => categoria?.categoriaArquivoPai?.idCategoriaArquivo === categoriaForm.idCategoriaArquivo,
                );

                // Caso a categoria principal tenha filhos
                if (listSubCategoriasForm.length) {
                    const subCategoriasHasChildren = listSubCategoriasForm.some((subCategoria) =>
                        listCategorias.some((categoria) => categoria?.categoriaArquivoPai?.idCategoriaArquivo === subCategoria.idCategoriaArquivo),
                    );

                    // Caso as categorias filhas tenham filhas, não retorna nada. Senão, retorna categorias sem pai.
                    return subCategoriasHasChildren ? [] : getDataFiltered(0);
                }
            }
        }

        return getDataFiltered();
    }, [data, getDataFiltered, limitViewLevelsByCategoria]);

    const renderOptions = useCallback((props: object, option: CategoriaArquivoOptionsProps) => {
        const { subCategoriasArquivo } = option;
        const categoriasLength = subCategoriasArquivo ? subCategoriasArquivo.length : 0;

        return (
            <Box component="li" {...props} key={option.idCategoriaArquivo}>
                <Stack>
                    {subCategoriasArquivo &&
                        subCategoriasArquivo.map((subCategoria, index) => {
                            return (
                                <Box key={subCategoria.idCategoriaArquivo + subCategoria.dsCategoriaArquivo} sx={{ pl: (index - 1) * 1.5, display: 'flex', alignItems: 'center' }}>
                                    {index > 0 && <SubdirectoryArrowRightOutlinedIcon sx={{ height: 15, width: 15 }} />}
                                    {subCategoria.dsCategoriaArquivo}
                                </Box>
                            );
                        })}

                    <Box sx={{ pl: (categoriasLength - 1) * 1.5 }}>
                        {categoriasLength > 0 && <SubdirectoryArrowRightOutlinedIcon sx={{ height: 15, width: 15 }} />}
                        <strong>{option.dsCategoriaArquivo}</strong>
                    </Box>
                </Stack>
            </Box>
        );
    }, []);

    return (
        <Autocomplete
            {...PropsFormik}
            multiple={multiple}
            onChange={onChange}
            loading={isLoading && fetchStatus === 'fetching'}
            options={getOptions}
            isOptionEqualToValue={(option, value) => option.idCategoriaArquivo === value.idCategoriaArquivo}
            renderInput={(params) => <CissTextField {...params} label={label} error={Boolean(error)} onChange={(e) => setInputValue(e.target.value)} helperText={error} />}
            getOptionLabel={(option: CategoriaArquivoOptionsProps | string) => (typeof option === 'string' ? option : option.dsCategoriaArquivo)}
            onSelect={() => setInputValue('')}
            renderOption={renderOptions}
        />
    );
}
