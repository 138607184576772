import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { PedidoLoteCompraListComp } from 'pages/compra/pedido-lote/components';

export function PedidoLoteCompraList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_LOTE_VISUALIZAR'])) return alertNotPermission();
    
    return (
        <ListagemProvider>
            <PedidoLoteCompraListComp />
        </ListagemProvider>
    );
}