import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { DreFormBlockTitle } from 'pages/financeiro/dre/components/';
import { Block } from 'components/page';

interface DreFormGroupProps {
    label: string;
    id: string;
    children: ReactNode;
}

export function DreFormGroup({ id, label, children }: DreFormGroupProps): JSX.Element {
    return (
        <Block sx={{ p: 0, pb: 1 }} id={id}>
            <DreFormBlockTitle label={label} />

            <Box sx={{ px: 2 }}>{children}</Box>
        </Block>
    );
}
