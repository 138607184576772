import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { ExtratoRoyaltyListComp } from './components';
import { usePermissionsContext } from 'context/PermissionsContext';

export function ExtratoRoyaltyList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_EXTRATO_ROYALTIES_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <ExtratoRoyaltyListComp />
        </ListagemProvider>
    );
}
