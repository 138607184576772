import React from 'react';

import { ConfirmModal } from 'components/modal';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGenericAuth } from 'hooks/useGenericAuth';

interface ConfirmLogoutModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
}

export function ConfirmLogoutModal({ open, setOpen }: ConfirmLogoutModalProps): JSX.Element {
    const { logout } = useGenericAuth();
    const { cleanPermissions } = usePermissionsContext();

    return (
        <ConfirmModal
            open={open}
            setOpen={setOpen}
            text="Tem certeza que quer encerrar a sessão? Você perderá todas informações não salvas."
            confirm={() => {
                cleanPermissions();

                logout();
            }}
        />
    );
}
