import React, { memo, useEffect } from 'react';

import { ConfirmModal } from 'components/modal/ConfirmModal';
import { usePageDefaultNavigationContext } from 'context/PageDefaultNavigationProvider';

interface FormDirtyModalProps {
    dirty: boolean;
}

export const FormDirtyModal = memo(({ dirty }: FormDirtyModalProps): JSX.Element | null => {
    const { setBlockNavigation, showConfirmModal, onCancel, onConfirm } = usePageDefaultNavigationContext();

    useEffect(() => {
        setBlockNavigation(dirty);

        return () => {
            setBlockNavigation(false);
        };
    }, [dirty]);

    return (
        <ConfirmModal
            open={showConfirmModal}
            setOpen={onCancel}
            confirm={() => {
                onConfirm();
            }}
            text="Você tem informações não salvas, deseja ir mesmo assim?"
        />
    );
});
