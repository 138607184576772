import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';

export function EventoListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsCalendarioEvento',
        operator: 'like',
        value: '',
    });

    const filters = [{ value: filterTitulo, setter: setFilterTitulo }];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField label="Título" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>
        </Filter>
    );
}
