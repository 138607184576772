import { RequestListagemProps } from 'hooks/useRequestListagem';

interface ConfigPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
    configPedidoStatus: string;
    idConfigPedidoStatus: number;
}

interface LojaProps {
    idLoja: number;
    dsNomeFantasia: string;
    dsRazaoSocial: string;
    cdLoja: string;
    cepFormatado: string;
    cnpjFormatado: string;
    nrTelefoneFormatado: string;
}

interface SubgrupoProps {
    idSubgrupo: number;
    dsSubgrupo: string;
}
interface ProdutoProps {
    idProduto: number;
    dsProduto: string;
    cdProduto: string;
    lkImagem: string;
    subgrupo: SubgrupoProps;
}

interface StatusPedidoTotalizadorProps {
    idStatusPedidoTotalizador: number;
    dsStatusPedidoTotalizador: string;
}

interface PedidoStatusProps {
    idPedidoStatus: number;
    dsPedidoStatus: string;
}

interface PedidoProps {
    cdPedido: string;
}

export interface PedidoTotalizadorProps {
    idPedido: number;
    dhInclusao: string;
    loja: LojaProps;
    nrPedido: string;
    cdPedido: string;
    configPedido: ConfigPedidoProps;
    vlTotal: number;
    fgAtivo: boolean;
    statusPedidoTotalizador: StatusPedidoTotalizadorProps;
}

export interface PedidoTotalizadorQuebraProps {
    idPedido: number;
    dtInicioQuebraPedido: string;
    loja: LojaProps;
    nrPedido: string;
    cdPedido: string;
    configPedido: ConfigPedidoProps;
    vlTotal: number;
    fgAtivo: boolean;
    pedidoStatus: PedidoStatusProps;
    dsValidacao: string;
    grade: {
        dtEntrega: string;
        dtColeta: string;
        dtLimitePedido: string;
    };
}

export interface PedidoTotalizadorProdutoProps {
    produto: ProdutoProps;
    pedido: PedidoProps;
    vlTotalFaturado: number;
    vlUnitario: number;
    qtProduto: number;
    qtProdutoFaturado: number;
    qtMinimaCompraPedidoProduto: number;
}

export interface RequestPedidoTotalizadorProdutoProps extends RequestListagemProps {
    data: PedidoTotalizadorProdutoProps[];
}

export interface RequestPedidoTotalizadorProps extends RequestListagemProps {
    data: PedidoTotalizadorProps[];
}

export interface RequestPedidoTotalizadorQuebraProps extends RequestListagemProps {
    data: PedidoTotalizadorQuebraProps[];
}

export interface PedidoTotalizadorValdiacaoProps {
    idPedidoTotalizadorValidacao: number;
    dsPedidoTotalizadorValidacao: string;
}

export enum SincronizacaoStatus {
    AGUARDANDO = 1,
    PROCESSANDO = 2,
    FINALIZADO_COM_SUCESSO = 3,
    FINALIZADO_COM_FALHA = 4,
    IGNORADO = 5,
    PACOTE_COM_FALHA = 6,
    GERANDO_PACOTES = 7,
    GERANDO_NOVOS_PACOTES = 8,
}

export const getColor: Record<keyof typeof SincronizacaoStatus, 'error' | 'info' | 'success' | 'warning'> = {
    AGUARDANDO: 'warning',
    PROCESSANDO: 'info',
    FINALIZADO_COM_SUCESSO: 'success',
    FINALIZADO_COM_FALHA: 'error',
    IGNORADO: 'warning',
    PACOTE_COM_FALHA: 'error',
    GERANDO_PACOTES: 'info',
    GERANDO_NOVOS_PACOTES: 'info',
};

export interface PedidoTotalizadorSincronizacaoStatus {
    idPedidoTotalizadorSincronizacaoStatus: SincronizacaoStatus;
    dsPedidoTotalizadorSincronizacaoStatus: string;
}

export interface RequestPedidoIntegracaoStatusProps {
    idPedidoTotalizadorSincronizacaoStatus: number;
    pedidoTotalizadorSincronizacaoStatus: PedidoTotalizadorSincronizacaoStatus;
}
