import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useRequestListagem } from 'hooks/useRequestListagem';

export function useGetWhatsApp(): UseQueryResult<string> {
    const requestListagem = useRequestListagem();

    return useQuery<string>(
        ['getWhatsApp'],
        () =>
            requestListagem({
                url: '/config/configgeral/chave',
                extraParams: [{ dsChave: 'URL_WHATSAPP' }],
            }).then((res) => res.data),
        {
            enabled: true,
        },
    );
}
