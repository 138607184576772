import React, { Dispatch, SetStateAction } from 'react';

import { Chip, Tooltip } from '@mui/material';

interface QuebrasSituacaoColumnProps {
    idStatus: number;
    title: string;
    idConfigPedidoQuebraImportacao: number;
    setOpenModalErros: Dispatch<SetStateAction<number | boolean>>;
}

export function QuebrasSituacaoColumn({ idStatus, title, idConfigPedidoQuebraImportacao, setOpenModalErros }: QuebrasSituacaoColumnProps): JSX.Element {
    let color: 'error' | 'info' | 'success' | 'warning' = 'warning';

    if (idStatus === 2) {
        color = 'info';
    } else if (idStatus === 3) {
        color = 'success';
    } else if (idStatus === 4) {
        return (
            <Tooltip title="Visualizar erros">
                <Chip label={title} variant="outlined" size="small" color="error" sx={{ width: '100%' }} onClick={() => setOpenModalErros(idConfigPedidoQuebraImportacao)} />
            </Tooltip>
        );
    }

    return <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} />;
}
