interface calendarioEventoTag {
    dsCalendarioEventoTag: string;
    dsCorTag: string;
    idCalendarioEventoTag?: number;
}

export interface EventoModelProps {
    idCalendarioEvento: number;
    dsConteudo: string;
    dsCalendarioEvento: string;
    calendarioEventoTag: calendarioEventoTag | null;
    dsCalendarioEventoCategoria: string;
    dsCor: string;
    dhInicio: string;
    dhFim: string;
    dsTextoLink: string;
    dsUrlLink: string;
}
export interface CalendarioModelProps {
    data: EventoModelProps[];
}
interface EventoLojasProps {
    idLoja: number;
    dsNomeFantasia: string;
    dsRazaoSocial: string;
}

interface EventoEstadosProps {
    idEstado: number;
    dsEstado: string;
}
interface EventoCidadesProps {
    idCidade: number;
    dsCidade: string;
}

interface EventoTipoLojasProps {
    idLoja: number;
    dsLoja: string;
}
interface EventoLojasPerfisProps {
    idLoja: number;
    dsLoja: string;
}
export interface RequestCalendarioListModelProps {
    data: CalendarioModelProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestEventoListModelProps {
    data: EventoModelProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestEventoModelProps {
    idCalendarioEvento?: number;
    dsCalendarioEvento?: string;
    dsConteudo?: string;
    tipoEvento?: 'lojas' | 'estados' | 'cidades';
    tipoLoja?: string;
    calendarioEventoTag?: calendarioEventoTag | null;
    calendarioEventoCidades?: EventoCidadesProps[] | null;
    calendarioEventoEstados?: EventoEstadosProps[] | null;
    calendarioEventoLojas?: EventoLojasProps[] | null;
    calendarioEventoTipoLojas?: EventoTipoLojasProps[] | null;
    calendarioEventoLojaPerfis?: EventoLojasPerfisProps[] | null;
    dhInicio?: Date | null;
    dhFim?: Date | null;
    dsTextoLink?: string;
    dsUrlLink?: string;
    dsCor?: string;
}

export const initialEventoFormValues: RequestEventoModelProps = {
    idCalendarioEvento: -1,
    dsCalendarioEvento: '',
    calendarioEventoTag: null,
    calendarioEventoEstados: [],
    calendarioEventoCidades: [],
    calendarioEventoLojas: [],
    calendarioEventoTipoLojas: [],
    calendarioEventoLojaPerfis: [],
    tipoEvento: 'lojas',
    dsConteudo: '',
    dhInicio: null,
    dhFim: null,
    dsTextoLink: '',
    dsUrlLink: '',
    dsCor: '#000000',
};
