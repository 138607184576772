import React, { useCallback, useMemo, useState } from 'react';

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Chip, Typography, Stack, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import { DownloadModeloArquivoBtn } from 'components/buttons';
import { CissTextField } from 'components/fields/';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { UploadButton } from 'components/upload';
import { useRequestListagem, RequestListagemProps, RequestOptionsType } from 'hooks/useRequestListagem';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

interface StoreOptionsProps {
    idLoja: number;
    cdLoja: string;
    dsNomeFantasia: string;
}

interface RequestListagemType extends RequestListagemProps {
    data: StoreOptionsProps[];
}

interface StoreImportAutoCompleteProps extends AutocompleteProps<StoreOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
    requestOptions: RequestOptionsType;
}

export function StoreImportAutoComplete({
    onChange,
    multiple = true,
    label = 'Lojas',
    sx,
    requestOptions = {
        url: '/gestao/loja',
        sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
        columns: 'idLoja,cdLoja,dsNomeFantasia',
        limit: 50,
    },
    ...PropsFormik
}: StoreImportAutoCompleteProps): JSX.Element {
    const [inputValue, setInputValue] = useState<string>('');
    const [queryValue] = useDebounce(inputValue, 500);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [showHiddenTags, setShowHiddenTags] = useState(false);
    const [fileLojas, setFileLojas] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();

    requestOptions.query = queryValue;

    const { isLoading, fetchStatus, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestListagemType> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            onError: () => enqueueSnackbar('Falha ao processar solicitação das Lojas', { variant: 'error' }),
        },
    );

    const setImportedStores = useCallback(
        (lojas: StoreOptionsProps[]) => {
            const currentLojas = PropsFormik.form.values[PropsFormik.field.name];
            const newLojas = lojas.filter((loja) => !currentLojas.some((currentLoja: StoreOptionsProps) => currentLoja.cdLoja === loja.cdLoja));

            PropsFormik.form.setFieldValue(PropsFormik.field.name, [...currentLojas, ...newLojas]);
        },
        [PropsFormik.field.name, PropsFormik.form],
    );

    const { mutate, isLoading: isLoadingLoja } = useUploadFileMutation({
        onSuccess: (response: any) => {
            const { lojas, lojasNaoEncontradas } = response.data;

            enqueueSnackbar('Importação de lojas realizada com sucesso', { variant: 'success' });

            if (lojas.length > 0) {
                setImportedStores(lojas);
            }

            if (lojasNaoEncontradas.length > 0) {
                enqueueSnackbar(`Lojas não encontradas: ${lojasNaoEncontradas.join(', ')}`, { variant: 'warning' });
            }

            setImportModalOpen(false);
            setFileLojas(null);
        },
        onError: () => enqueueSnackbar('Falha ao importar as lojas', { variant: 'error' }),
    });

    const onClickImportarLojas = useCallback(() => {
        const formData = new FormData();

        if (fileLojas) {
            formData.append('file', fileLojas);
        }

        mutate({
            url: '/gestao/loja/arquivo',
            formData,
        });
    }, [fileLojas, mutate]);

    const renderCsvImportModal = useMemo((): JSX.Element => {
        return (
            <ContentModal
                open={importModalOpen}
                setOpen={setImportModalOpen}
                closeButton
                sx={{
                    width: 400,
                    borderRadius: 1,
                }}
            >
                <Stack>
                    <SubTitle label="Importar Lojas" />

                    <UploadButton label="Selecionar Arquivo" accept={['.csv']} onChange={(file) => setFileLojas(file)} />

                    {fileLojas && (
                        <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                            {fileLojas.name}
                        </Typography>
                    )}

                    <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                        *São aceitos somente arquivos em formato .csv
                    </Typography>

                    <Stack direction="row">
                        <DownloadModeloArquivoBtn sx={{ flex: 1 }} dsChave="URL_ARQUIVO_MODELO_LOJA" />
                    </Stack>

                    <Stack
                        spacing={2}
                        justifyContent="flex-end"
                        direction="row"
                        sx={{
                            mt: 3,
                        }}
                    >
                        <Button variant="outlined" onClick={() => setImportModalOpen(false)}>
                            Cancelar
                        </Button>

                        <LoadingButton variant="contained" onClick={onClickImportarLojas} disabled={!fileLojas} loading={isLoadingLoja}>
                            Importar Lojas
                        </LoadingButton>
                    </Stack>
                </Stack>
            </ContentModal>
        );
    }, [fileLojas, importModalOpen, isLoadingLoja, onClickImportarLojas]);

    const renderLimitedTags = (tags: StoreOptionsProps[], getTagProps: any): any => {
        if (showHiddenTags) {
            return (
                <React.Fragment>
                    {tags.map((tag, index) => (
                        <Chip key={tag.cdLoja} label={tag.dsNomeFantasia} {...getTagProps({ index })} />
                    ))}
                    {tags.length > 1 && <Chip label={'▲ Ocultar Lojas'} onClick={() => setShowHiddenTags(!showHiddenTags)} color="primary" />}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {tags.slice(0, 1).map((tag, index) => (
                    <Chip key={`${tag.cdLoja}-${tag.idLoja}`} label={tag.dsNomeFantasia} {...getTagProps({ index })} />
                ))}
                {tags.length > 1 && <Chip label={`+${tags.length - 1}`} onClick={() => setShowHiddenTags(true)} />}
            </React.Fragment>
        );
    };

    return (
        <Box sx={{ ...sx, alignItems: 'center', minWidth: 300 }}>
            <Autocomplete
                {...PropsFormik}
                multiple={multiple}
                onChange={onChange}
                loading={isLoading && fetchStatus === 'fetching'}
                options={data?.data ?? []}
                renderTags={(value, getTagProps) => renderLimitedTags(value, getTagProps)}
                renderInput={(params) => <CissTextField {...params} label={label} />}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                isOptionEqualToValue={(option, value) => option.idLoja === value.idLoja}
                disableCloseOnSelect
                sx={{ flex: 1, mr: 1 }}
                getOptionLabel={(option: StoreOptionsProps | string) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return `${option.cdLoja} - ${option.dsNomeFantasia}`;
                }}
                renderOption={(props, option) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }} key={`${option.cdLoja}-${option.idLoja}`}>
                        <strong>{option.cdLoja}</strong> - {option.dsNomeFantasia}
                    </Box>
                )}
            />

            <IconButton onClick={() => setImportModalOpen(true)} sx={{ width: 50 }} color="primary">
                <UploadFileOutlinedIcon />
            </IconButton>

            {renderCsvImportModal}
        </Box>
    );
}
