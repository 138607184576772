import React from 'react';

import { Box, IconButton, Modal, ModalProps, SxProps } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CSSSelectorObjectOrCssVariables } from '@mui/system';

interface ContentModalProps extends ModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
    sx?: SxProps;
    closeButton?: boolean | SxProps;
    centered?: boolean;
    onClose?: () => void;
    disableBackdropClick?: boolean;
}

const getCenteredSx = (): CSSSelectorObjectOrCssVariables => {
    return { top: '50%', transform: 'translate(-50%, -50%)' };
};

const getFixedSx = (): CSSSelectorObjectOrCssVariables => {
    return { top: '10vh' };
};

export function ContentModal({ open, setOpen, children, sx, closeButton, centered = true, onClose = () => {}, disableBackdropClick = false, ...modalProps }: ContentModalProps): JSX.Element {
    const boxDefaultSx = centered ? getCenteredSx() : getFixedSx();
    const closeSx = typeof closeButton === 'object' && closeButton !== null ? closeButton : {};

    const handleClose = (event?: unknown, reason?: string): void => {
        if (event && reason === 'backdropClick' && disableBackdropClick) {
            return;
        } else if (typeof setOpen === 'function') {
            setOpen(false);
        }

        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose} {...modalProps}>
            <Box
                sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: 'common.white',
                    boxShadow: 24,
                    minWidth: {
                        xs: 'auto',
                        sm: 500,
                    },
                    maxHeight: '80vh',
                    overflow: 'auto',
                    p: 2,
                    ...boxDefaultSx,
                    ...sx,
                }}
            >
                {closeButton && (
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 0, top: 0, ...closeSx }}>
                        <CloseOutlinedIcon color="action" sx={{ fontSize: '1.25rem' }} />
                    </IconButton>
                )}

                {children}
            </Box>
        </Modal>
    );
}
