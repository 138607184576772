import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { BackBtn, ExtraButtons, NewBtn, ToggleListModeBtn } from 'components/buttons';
import { ExtraButtonListProps } from 'components/buttons/ExtraButtons';
import { Block } from 'components/page';
import { PageTitle } from 'components/text';

export interface TbarProps {
    title: string;
    listType?: boolean;
    createButton?: boolean;
    goBackButton?: boolean | string;
    extraButtons?: ExtraButtonListProps | ExtraButtonListProps[];
    extraContent?: JSX.Element | ReactNode;
}

export function Tbar({ title, listType, goBackButton, createButton, extraButtons, extraContent }: TbarProps): JSX.Element {
    return (
        <Block sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', gap: 1 }}>
            {title && <PageTitle label={title} />}

            {(extraContent || extraButtons || goBackButton || createButton || listType) && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 2,
                        alignItems: 'center',
                    }}
                >
                    {extraContent}

                    {extraButtons && <ExtraButtons list={extraButtons} />}

                    {goBackButton && <BackBtn goTo={goBackButton} />}

                    {createButton && <NewBtn />}

                    {listType && <ToggleListModeBtn />}
                </Box>
            )}
        </Block>
    );
}
