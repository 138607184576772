import React, { useEffect, useRef } from 'react';

import { Typography } from '@mui/material';

import { UploadButton } from 'components/upload';

export function ImportacaoUploadBtn({ setFileImportacao, fileImportacao }: any): JSX.Element {
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputFileRef.current && !fileImportacao) {
            inputFileRef.current.value = '';
        }
    }, [fileImportacao]);

    return (
        <React.Fragment>
            <UploadButton
                label="Selecionar Arquivo"
                onChange={(file) => {
                    setFileImportacao(file);
                }}
                accept={['.xlsx', '.csv']}
                inputRef={inputFileRef}
            />

            {fileImportacao && (
                <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                    {fileImportacao.name}
                </Typography>
            )}

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                *São aceitos somente arquivos em formato .csv e .xlsx
            </Typography>
        </React.Fragment>
    );
}
