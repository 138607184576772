import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RevendedorFormComp, RevendedorListComp } from 'pages/revendedor/revendedor/components';

export function RevendedorList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['REVENDEDOR_CADASTRO_REVENDEDOR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <RevendedorListComp />
        </ListagemProvider>
    );
}

export function RevendedorForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['REVENDEDOR_CADASTRO_REVENDEDOR'])) return alertNotPermission();

    return (
        <FormProvider>
            <RevendedorFormComp />
        </FormProvider>
    );
}
