import React from 'react';

import { Box } from '@mui/material';

import { PedidoGradeLimiteText, PedidoGradeEntregaText } from 'pages/compra/pedido/components';
import { useGetDataEntrega } from 'pages/compra/pedido/hooks';

export function PedidoGradeText(): JSX.Element | null {
    const { data } = useGetDataEntrega();

    if (!data) {
        return null;
    }

    const { dtEntrega, dtLimitePedido } = data;

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: { xs: 0.5, lg: 2 } }}>
            <PedidoGradeLimiteText date={`${dtLimitePedido}T23:59:59`} />

            <PedidoGradeEntregaText date={`${dtEntrega}T00:00:00`} />
        </Box>
    );
}
