import React, { Dispatch, SetStateAction } from 'react';

import { alpha, Box } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';

interface ProdutoImagemModalProps {
    open: boolean | string;
    setOpen: Dispatch<SetStateAction<boolean | string>>;
}

export function ProdutoImagemModal({ open, setOpen }: ProdutoImagemModalProps): JSX.Element {
    return (
        <ContentModal sx={{ borderRadius: 1, justifyContent: 'center' }} open={Boolean(open)} setOpen={setOpen} closeButton>
            <Box>
                <SubTitle label="Imagem" />

                <Box
                    sx={{
                        flex: 1,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundImage: `url("${open}")`,
                        border: (theme) => `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
                        minHeight: {
                            sm: 400,
                            xs: 300,
                        },
                        minWidth: {
                            lg: 400,
                            xs: 300,
                        },
                    }}
                />
            </Box>
        </ContentModal>
    );
}