import { IconName, IconPrefix, IconLookup } from '@fortawesome/fontawesome-common-types';
import { faFileAlt, faFileArchive, faFileCode, faFileExcel, faFileImage, faFilePdf, faFile, faFilePowerpoint, faFileVideo, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { useSnackbar } from 'notistack';

import { brlList } from './../util/format';

// fix bug import diretamente de '@fortawesome/fontawesome-svg-core' error
export type MimeTypeIconProp = IconName | [IconPrefix, IconName] | IconLookup;

export interface MimeTypeProps {
    extension: string;
    mimeType: string;
    title: string;
    icon: MimeTypeIconProp;
}

interface MimeTypeParamsProps {
    validateFileExt: (file: any, acceptExtensions: string | string[]) => boolean;
    isMimeType: (value: string) => boolean;
    getTitle: (value: string) => string | undefined;
    getIcon: (value?: string) => MimeTypeIconProp | undefined;
    findInMimeTable: (value: string | string[]) => MimeTypeProps[];
    getMimeTypesTable: () => MimeTypeProps[];
    getFileNameWithoutExtension: (value: string) => string;
}

export function useMimeTypes(): MimeTypeParamsProps {
    const { enqueueSnackbar } = useSnackbar();

    const getMimeTypesTable = (): MimeTypeProps[] => {
        return [
            {
                extension: '.csv',
                mimeType: 'text/csv',
                title: 'Dados (csv)',
                icon: faFileCode,
            },
            {
                extension: '.doc',
                mimeType: 'application/msword',
                title: 'Documento (doc)',
                icon: faFileWord,
            },
            {
                extension: '.docx',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                title: 'Documento (docx)',
                icon: faFileWord,
            },
            {
                extension: '.gif',
                mimeType: 'image/gif',
                title: 'Imagem (gif)',
                icon: faFileImage,
            },
            {
                extension: '.jpeg',
                mimeType: 'image/jpeg',
                title: 'Imagem (jpeg)',
                icon: faFileImage,
            },
            {
                extension: '.jpg',
                mimeType: 'image/jpeg',
                title: 'Imagem (jpg)',
                icon: faFileImage,
            },
            {
                extension: '.json',
                mimeType: 'application/json',
                title: 'Dados (json)',
                icon: faFileCode,
            },
            {
                extension: '.png',
                mimeType: 'image/png',
                title: 'Imagem (png)',
                icon: faFileImage,
            },
            {
                extension: '.pdf',
                mimeType: 'application/pdf',
                title: 'Documento (pdf)',
                icon: faFilePdf,
            },
            {
                extension: '.ppt',
                mimeType: 'application/vnd.ms-powerpoint',
                title: 'Documento (ppt)',
                icon: faFilePowerpoint,
            },
            {
                extension: '.pptx',
                mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                title: 'Documento (pptx)',
                icon: faFilePowerpoint,
            },
            {
                extension: '.rar',
                mimeType: 'application/x-rar-compressed',
                title: 'Arquivo Compactado (rar)',
                icon: faFileArchive,
            },
            {
                extension: '.svg',
                mimeType: 'image/svg+xml',
                title: 'Imagem (svg)',
                icon: faFileImage,
            },
            {
                extension: '.tar',
                mimeType: 'application/x-tar',
                title: 'Arquivo Compactado (tar)',
                icon: faFileArchive,
            },
            {
                extension: '.tif',
                mimeType: 'image/tiff',
                title: 'Imagem (tif)',
                icon: faFileImage,
            },
            {
                extension: '.tiff',
                mimeType: 'image/tiff',
                title: 'Imagem (tiff)',
                icon: faFileImage,
            },
            {
                extension: '.txt',
                mimeType: 'text/plain',
                title: 'Documento (txt)',
                icon: faFileAlt,
            },
            {
                extension: '.xls',
                mimeType: 'application/vnd.ms-excel',
                title: 'Documento (xls)',
                icon: faFileExcel,
            },
            {
                extension: '.xlsx',
                mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                title: 'Documento (xlsx)',
                icon: faFileExcel,
            },
            {
                extension: '.xml',
                mimeType: 'application/xml',
                title: 'Dados (xml)',
                icon: faFileCode,
            },
            {
                extension: '.zip',
                mimeType: 'application/zip',
                title: 'Arquivo Compactado (zip)',
                icon: faFileArchive,
            },
            {
                extension: '.zip',
                mimeType: 'application/zip-compressed',
                title: 'Arquivo Compactado (zip)',
                icon: faFileArchive,
            },
            {
                extension: '.zip',
                mimeType: 'application/x-zip-compressed',
                title: 'Arquivo Compactado (zip)',
                icon: faFileArchive,
            },
            {
                extension: '.zip',
                mimeType: 'application/octet-stream',
                title: 'Arquivo Compactado (zip)',
                icon: faFileArchive,
            },
            {
                extension: '.patch',
                mimeType: 'text/x-diff',
                title: 'Documento (patch)',
                icon: faFileCode,
            },
            {
                extension: '.patch',
                mimeType: 'text/x-patch',
                title: 'Documento (patch)',
                icon: faFileCode,
            },
            {
                extension: '.patch',
                mimeType: 'application/x-patch',
                title: 'Documento (patch)',
                icon: faFileCode,
            },
            {
                extension: '.mp4',
                mimeType: 'video/mp4',
                title: 'Vídeo (mp4)',
                icon: faFileVideo,
            },
            {
                extension: '.flv',
                mimeType: 'video/x-flv',
                title: 'Vídeo (flv)',
                icon: faFileVideo,
            },
            {
                extension: '.mov',
                mimeType: 'video/quicktime',
                title: 'Vídeo (mov)',
                icon: faFileVideo,
            },
            {
                extension: '.avi',
                mimeType: 'video/avi',
                title: 'Vídeo (avi)',
                icon: faFileVideo,
            },
            {
                extension: '.otf',
                mimeType: 'font/otf',
                title: 'Fonte (otf)',
                icon: faFile,
            },
            {
                extension: '.ttf',
                mimeType: 'font/ttf',
                title: 'Fonte (ttf)',
                icon: faFile,
            },
            {
                extension: '.eot',
                mimeType: 'font/eot',
                title: 'Fonte (eot)',
                icon: faFile,
            },
            {
                extension: '.odt',
                mimeType: 'font/odt',
                title: 'Fonte (odt)',
                icon: faFile,
            },
            {
                extension: '.ttc',
                mimeType: 'font/ttc',
                title: 'Fonte (ttc)',
                icon: faFile,
            },
        ];
    };

    const isMimeType = (value: string): boolean => {
        return Boolean(value.match('[w]*/[w]*'));
    };

    const normalizeExtension = (value: string): string => {
        return value.startsWith('.') ? value : `.${value}`;
    };

    const normalizeAcceptExtensionsList = (value: string[]): string[] => {
        return value.map((ext) => normalizeExtension(ext));
    };

    const findInMimeTable = (value: string | string[]): MimeTypeProps[] => {
        const table = getMimeTypesTable();
        const values = Array.isArray(value) ? [...value] : [value];

        return table.filter((item) => {
            const { mimeType, extension } = item;

            let found = false;

            for (let index = 0; index < values.length; index++) {
                const val = values[index];
                const isMime = isMimeType(val);
                const itemVal = isMime ? mimeType : extension;
                const searchVal = isMime ? val : normalizeExtension(val);

                if (itemVal === searchVal) {
                    found = true;
                    break;
                }
            }

            return found;
        });
    };

    const validateFileExt = (file: any, acceptExtensions: string | string[]): boolean => {
        const { type, name } = file;
        const results = findInMimeTable(type);

        // lista de extensões vazia então arquivo é valido (pq não tem com o que comparar)
        let valid = Boolean(!acceptExtensions.length);

        if (typeof acceptExtensions === 'string' && acceptExtensions === '*') {
            valid = true;
        }

        // sem mimetype no arquivo
        if (!type) {
            console.warn(`Arquivo inválido pois não foi possivel ler a extensão do arquivo ${name}`);
        }

        // se é valido (não tem extensões para comparar) não precisa validar... é valido...
        if (valid) {
            return valid;
        }

        results.forEach((result) => {
            const { extension } = result;
            const list = Array.isArray(acceptExtensions) ? normalizeAcceptExtensionsList(acceptExtensions) : [normalizeExtension(acceptExtensions)];

            valid = list.includes(extension);

            return !valid;
        });

        if (!valid && !results.length) {
            console.error(`Arquivo inválido pois extensão do arquivo ${name} não existe na tabela de mimetypes`);
        }

        if (!valid) {
            enqueueSnackbar(`Extensão do arquivo inválida, somente são aceitas as seguintes extensões: ${brlList(acceptExtensions)}`, {
                variant: 'error',
            });
        }

        return valid;
    };

    const getTitle = (value: string): string | undefined => {
        const item = findInMimeTable(value);

        let title = value;

        if (Array.isArray(item) && item.length > 0) {
            title = item[0].title;
        }

        return title;
    };

    const getIcon = (value?: string): MimeTypeIconProp | undefined => {
        if (value) {
            const item = findInMimeTable(value);

            let icon: MimeTypeIconProp = faFile;

            if (Array.isArray(item) && item.length > 0) {
                icon = item[0].icon;
            }

            return icon;
        }

        return;
    };

    const getFileNameWithoutExtension = (fileName: string): string => {
        const imageExtension = fileName.split('.').pop();

        return imageExtension && findInMimeTable(imageExtension) ? fileName.slice(0, fileName.lastIndexOf('.')) : fileName;
    };

    return { validateFileExt, isMimeType, getTitle, findInMimeTable, getIcon, getFileNameWithoutExtension, getMimeTypesTable };
}
