export type StateProdutosNota = 'waiting' | 'loading' | 'loaded';

export enum StateNotaDevolucao {
    CANCELADA = 'CANCELADO',
    AGUARDANDO = 'AGUARDANDO_FATURAMENTO',
}

interface MotivoDevolucaoProps {
    idMotivoDevolucao: number;
}

interface PessoaProps {
    cdPessoa: string;
    dsPessoa: string;
    idPessoa: number;
    nrCpfCnpj: string;
    nrTelefone: string;
}

interface PessoaFornecedorProps {
    dsInscricaoEstadual: string;
    fgAtivo: boolean;
    idPessoaFornecedor: number;
    pessoa: PessoaProps;
}

interface ProdutoProps {
    cdProduto: string;
    dsProduto: string;
}

interface UnidadeMedidaProps {
    sgUnidadeMedida: string;
}

export interface MovimentacaoProdutoSimulacaoDevolucaoDtosProps {
    nrSequencia: number;
    peIcms: number;
    peIcmsSt: number;
    produto: ProdutoProps;
    qtDisponivelDevolucao: number;
    qtNotaProduto: number;
    qtProduto: number | null;
    unidadeMedida: UnidadeMedidaProps;
    vlIcms: number;
    vlIcmsSt: number;
    vlProduto: number;
}

interface LojaProps {
    idLoja: number;
    cdLoja: number;
    dsNomeFantasia: string;
}

export interface NotaDevolucaoFormProps {
    motivoDevolucao: MotivoDevolucaoProps | null;
    pessoaFornecedor: PessoaFornecedorProps | null;
    nrNotaOrigem: number | string;
    movimentacaoProdutoSimulacaoDevolucaoDtos: MovimentacaoProdutoSimulacaoDevolucaoDtosProps[];
    loja: LojaProps | null;
}

export const initialNotaDevolucaoFormValues: NotaDevolucaoFormProps = {
    motivoDevolucao: null,
    pessoaFornecedor: null,
    nrNotaOrigem: '',
    movimentacaoProdutoSimulacaoDevolucaoDtos: [],
    loja: null,
};

export interface RequestProdutosNotaDevolucaoFormProps {
    data: MovimentacaoProdutoSimulacaoDevolucaoDtosProps[];
    total: number;
}

interface LojaProps {
    idLoja: number;
}

export interface ProdutosNotaDevolucaoListProps {
    loja: LojaProps;
    pessoaFornecedor: Pick<PessoaFornecedorProps, 'idPessoaFornecedor'>;
    nrNotaOrigem: number;
    motivoDevolucao: MotivoDevolucaoProps;
    status: string;
    dtMovimentacao: string;
    vlTotalProdutos: number;
    vlTotalNota: number;
    vlTotalNotaSt: number;
    nrOrdemVenda: number;
    nrPedido: number;
}

export interface RequestProdutosNotaDevolucaoListProps {
    data: ProdutosNotaDevolucaoListProps[];
    total: number;
}

export interface ProdutosNotaDevolucaoDetailListProps extends Omit<MovimentacaoProdutoSimulacaoDevolucaoDtosProps, 'nrSequencia' | 'qtDisponivelDevolucao' | 'qtProduto'> {
    vlTotalProduto: number;
}

export interface RequestProdutosNotaDevolucaoDetailListProps {
    data: ProdutosNotaDevolucaoDetailListProps[];
}

export interface RequestPostProdutosNotaDevolucaoFormProps {
    status: number;
    data: {
        data: ProdutosNotaDevolucaoListProps;
    };
}
