import React, { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Alert, Stack, Tooltip } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { RelatorioCreditoItemDetalhamentoCreditos } from '..';

import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DetalhamentoDetailProps, RequestDetalhamentoDetailProps } from 'pages/financeiro/detalhamento-creditos/types';
import { brlDate, brlPrice } from 'util/format';

const generateDetalhamentoRows = (data: DetalhamentoDetailProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => {
            return {
                id: index + 1,
                dsPromocao: item.dsPromocao,
                dtPromocao: item.dtMovimentacao ? `${item.dtMovimentacao}T00:00:00` : null,
                qtPromocao: item.qtPromocao,
                dsProduto: item.dsProduto,
                vlReembolso: item.vlTotalReembolso,
            };
        });
    }

    return [];
};

export function DetalhamentoCreditoDetailComp(): JSX.Element {
    const [searchParams] = useSearchParams();
    const RequestListagem = useRequestListagem();
    const { setAutoLoad } = useListagemContext();
    const cdLoja = searchParams.get('cdLoja');
    const nrAno = searchParams.get('nrAno');
    const nrMes = searchParams.get('nrMes');
    const nrQuinzena = searchParams.get('nrQuinzena');
    const dsPromocao = searchParams.get('dsPromocao');

    const validParams = Boolean(cdLoja && nrAno && nrMes && nrQuinzena && dsPromocao);

    const filter: FilterValuesProps[] = useMemo(
        () => [
            { property: 'cdLoja', value: cdLoja, operator: 'eq', logicalOperator: 'AND' },
            { property: 'nrAno', value: nrAno, operator: 'eq', logicalOperator: 'AND' },
            { property: 'nrMes', value: nrMes, operator: 'eq', logicalOperator: 'AND' },
            { property: 'nrQuinzena', value: nrQuinzena, operator: 'eq', logicalOperator: 'AND' },
            { property: 'dsPromocao', value: dsPromocao, operator: 'eq', logicalOperator: 'AND' },
        ],
        [cdLoja, dsPromocao, nrAno, nrMes, nrQuinzena],
    );

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: 'financeiro/detalhamentocreditodebitoproduto/findByChave',
            columns: 'idDetalhamentoCreditoDebitoProduto',
            nested: true,
            filter,
        }),
        [filter],
    );

    const { data, fetchStatus, isError, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestDetalhamentoDetailProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: validParams,
            onSettled: () => setAutoLoad(true), // utilizado para indicar para as grids que não existem dados
        },
    );

    const columns: GridColDef[] = [
        {
            field: 'dsPromocao',
            headerName: 'Promoção',
            minWidth: 100,
            flex: 1.5,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        { field: 'dtPromocao', headerName: 'Data', minWidth: 90, valueFormatter: ({ value }) => brlDate(value) },
        {
            field: 'dsProduto',
            headerName: 'Nome Produto',
            minWidth: 130,
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        { field: 'qtPromocao', headerName: 'Quantidade', type: 'number', width: 150, minWidth: 130 },
        { field: 'vlReembolso', headerName: 'Valor de Reembolso (total)', type: 'number', width: 180, minWidth: 170, valueFormatter: ({ value }) => brlPrice(value) },
    ];

    const content = data?.data;

    const rows = generateDetalhamentoRows(content);

    return (
        <OneColumn title="Detalhamento de Créditos - Itens" goBackButton>
            <Block>
                {fetchStatus === 'idle' && !validParams && <Alert severity="error">Verifique se a URL informada é valida.</Alert>}

                {fetchStatus === 'idle' && isError && <Alert severity="error">Falha na recuperação dos dados.</Alert>}

                {!isError && validParams && (
                    <React.Fragment>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                            <RelatorioCreditoItemDetalhamentoCreditos disabled={rows.length <= 0} filter={filter} />
                        </Stack>
                        <PanelGrid
                            rows={rows}
                            rowsCount={rows.length}
                            reload={refetch}
                            loading={fetchStatus === 'fetching'}
                            columns={columns}
                            remote={false}
                            summary={['vlReembolso']}
                            customMessageNoRows="Não há detalhamento de crédito a ser exibido"
                        />
                    </React.Fragment>
                )}
            </Block>
        </OneColumn>
    );
}
