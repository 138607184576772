import React from 'react';

import { useSearchParams } from 'react-router-dom';

type UseSearchParamsFilters = {
    lojaParam: any;
    configPedidoParam: any;
    funcionarioParam: any;
    tipoEntregaParam: any;
};

export function useSearchParamsFilters(): UseSearchParamsFilters {
    const [searchParams] = useSearchParams();
    const lojaParamUnparsed = searchParams.get('idLoja');
    const configPedidoParamUnparsed = searchParams.get('idConfigPedido');
    const funcionarioParamUnparsed = searchParams.get('idUsuarioPedido');
    const tipoEntregaParamUnparsed = searchParams.get('idTipoEntrega');

    return {
        lojaParam: lojaParamUnparsed && JSON.parse(lojaParamUnparsed),
        configPedidoParam: configPedidoParamUnparsed && JSON.parse(configPedidoParamUnparsed),
        funcionarioParam: funcionarioParamUnparsed && JSON.parse(funcionarioParamUnparsed),
        tipoEntregaParam: tipoEntregaParamUnparsed && JSON.parse(tipoEntregaParamUnparsed),
    };
}
