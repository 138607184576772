import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { MesFaturamentoAutoComplete, StoreAutoComplete, TipoGradeAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { ContentModal } from 'components/modal';
import { RequestGradeModelProps } from 'pages/logistica/grade/types';

interface GradeModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
    onClose?: () => void;
    grade?: RequestGradeModelProps | null;
    onSubmit: (grade: RequestGradeModelProps, formik: FormikHelpers<RequestGradeModelProps>) => void;
    isLoading?: boolean;
}

const getDateIsoString = (date?: Date | string | null): string => {
    let dateNow = new Date();

    if (date && typeof date === 'string') {
        dateNow = new Date(date);
    } else if (date && date !== 'string') {
        dateNow = date as Date;
    }

    const newDate = new Date(dateNow.valueOf() - dateNow.getTimezoneOffset() * 60000);
    return newDate.toISOString().replace(/\.\d{3}Z$/, '');
};

export function GradeModal({ open, setOpen, grade = null, isLoading = false, onClose = () => {}, onSubmit = () => {} }: GradeModalProps): JSX.Element {
    const validationNewGrade = Yup.object({
        loja: Yup.object().required().nullable(),
        tipoGrade: Yup.object().required().nullable(),
        dtColeta: Yup.string().required().nullable(),
        dtEntrega: Yup.string().required().nullable(),
        dtLimitePedido: Yup.string().required().nullable(),
        dhInicioEntrega: Yup.string().required().nullable(),
        dhFimEntrega: Yup.string().required().nullable(),
        dsObservacao: Yup.string().nullable(),
        mesFaturamento: Yup.string().required().nullable(),
    });

    return (
        <ContentModal closeButton open={open} setOpen={setOpen} sx={{ p: 5, width: 500, borderRadius: 1, maxWidth: '90%' }} onClose={onClose}>
            <Formik
                initialValues={
                    grade || {
                        dtColeta: getDateIsoString(),
                        dtEntrega: getDateIsoString(),
                        dtLimitePedido: getDateIsoString(),
                        dhInicioEntrega: getDateIsoString(),
                        dhFimEntrega: getDateIsoString(),
                        dsObservacao: '',
                    }
                }
                onSubmit={(values, formik) => onSubmit(values, formik)}
                validationSchema={validationNewGrade}
            >
                {(formik: FormikProps<RequestGradeModelProps>) => (
                    <Stack direction="column" gap={2}>
                        <StoreAutoComplete value={formik.values.loja} onChange={(e, value) => formik.setFieldValue('loja', value)} error={formik.errors.loja} />

                        <TipoGradeAutoComplete value={formik.values.tipoGrade} onChange={(e, value) => formik.setFieldValue('tipoGrade', value)} error={formik.errors.tipoGrade} />

                        <MesFaturamentoAutoComplete
                            value={formik.values.mesFaturamento || null}
                            error={formik.errors.mesFaturamento}
                            onChange={(e, value) => formik.setFieldValue('mesFaturamento', value)}
                        />

                        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <CissTextField {...params} label={'Data limite do pedido'} error={formik.errors.dtLimitePedido} />}
                                value={formik.values.dtLimitePedido || null}
                                onChange={(value) => formik.setFieldValue('dtLimitePedido', value)}
                            />

                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <CissTextField {...params} label={'Data de coleta'} error={formik.errors.dtColeta} />}
                                value={formik.values.dtColeta || null}
                                onChange={(value) => formik.setFieldValue('dtColeta', value)}
                            />

                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                renderInput={(params) => <CissTextField {...params} label={'Data de entrega'} error={formik.errors.dtEntrega} />}
                                value={formik.values.dtEntrega || null}
                                onChange={(value) => formik.setFieldValue('dtEntrega', value)}
                            />

                            <TimePicker
                                renderInput={(params) => <CissTextField {...params} label={'Hora de inicio da entrega'} error={formik.errors.dhInicioEntrega} />}
                                value={formik.values.dhInicioEntrega || null}
                                onChange={(value) => formik.setFieldValue('dhInicioEntrega', value)}
                            />

                            <TimePicker
                                renderInput={(params) => <CissTextField {...params} label={'Hora de fim da entrega'} error={formik.errors.dhFimEntrega} />}
                                value={formik.values.dhFimEntrega || null}
                                onChange={(value) => formik.setFieldValue('dhFimEntrega', value)}
                            />
                        </LocalizationProvider>

                        <CissTextField
                            label={'Observação'}
                            value={formik.values.dsObservacao}
                            onChange={(e) => formik.setFieldValue('dsObservacao', e.target.value)}
                            error={formik.errors.dsObservacao}
                        />

                        <LoadingButton variant="contained" size="large" fullWidth onClick={() => formik.submitForm()} loading={isLoading}>
                            {grade ? 'Atualizar' : 'Adicionar'}
                        </LoadingButton>
                    </Stack>
                )}
            </Formik>
        </ContentModal>
    );
}
