export interface categoriaArquivoProps {
    idCategoriaArquivo: number;
    dsCategoriaArquivo: string;
    categoriaArquivoPai?: categoriaArquivoProps;
}

interface arquivoProps {
    dsTitulo: string;
    dsLink: string;
    dsExtensao: string;
}

interface catalogoArquivoTagsProps {
    idCatalogoArquivoTagCatalogoArquivo: number;
    idTagCatalogoArquivo: number;
    dsTagCatalogoArquivo: string;
}

interface ArquivoListProps {
    idCatalogoArquivo: number;
    dhInclusao: string;
    dhAlteracao: string;
    fgAtivo: boolean;
    categoriaArquivo: categoriaArquivoProps;
    arquivo: arquivoProps;
    qtDownload: number;
    catalogoArquivoTagsCatalogoArquivo: catalogoArquivoTagsProps[];
    dsResumo: string;
}

interface ArquivoLojasProps {
    idLoja: number;
    dsNomeFantasia: string;
    dsRazaoSocial: string;
}

interface ArquivoEstadosProps {
    idEstado: number;
    dsEstado: string;
}
interface ArquivoCidadesProps {
    idCidade: number;
    dsCidade: string;
}

interface ArquivoTipoLojasProps {
    idLoja: number;
    dsLoja: string;
}
interface ArquivoLojasPerfisProps {
    idLoja: number;
    dsLoja: string;
}
export interface RequestArquivosProps {
    total: number;
    hasNext: boolean;
    data: {
        data: ArquivoListProps[];
    };
}

interface CategoriaArquivoProps {
    idCategoriaArquivo: number;
    dsCategoriaArquivo: string;
}

interface ArquivoArquivoProps {
    idArquivo: number;
    dsArquivo: string;
    dsExtensao: string;
    dsTitulo: string;
    dsLink: string;
}

interface CatalogoArquivoTags {
    idTagCatalogoArquivo: number;
    dsTagCatalogoArquivo: string;
}

export interface ArquivoProps {
    idCatalogoArquivo: number;
    dsTitulo: string;
    fgAtivo: boolean;
    fgArquivoAtualizado: boolean;
    categoriaArquivo: CategoriaArquivoProps | null;
    arquivo: null | ArquivoArquivoProps;
    catalogoArquivoTagsCatalogoArquivo: null | CatalogoArquivoTags[];
    catalogoArquivoLojas: ArquivoLojasProps[] | null;
    catalogoArquivoTipoLojas: ArquivoTipoLojasProps[] | null;
    catalogoArquivoLojaPerfis: ArquivoLojasPerfisProps[] | null;
    catalogoArquivoCidades: ArquivoCidadesProps[] | null;
    catalogoArquivoEstados: ArquivoEstadosProps[] | null;
    tipoDisponibilizacao: 'lojas' | 'cidades' | 'estados' | null;
    dhInclusao?: string;
    dhAlteracao?: string;
    dsResumo?: string;
    usuarioInclusao?: {
        dsUsuario?: string;
    };
    usuarioAlteracao?: {
        dsUsuario?: string;
    };
}

export const initialArquivoValues: ArquivoProps = {
    idCatalogoArquivo: -1,
    dsTitulo: '',
    fgAtivo: true,
    fgArquivoAtualizado: false,
    categoriaArquivo: null,
    catalogoArquivoTagsCatalogoArquivo: [],
    catalogoArquivoLojas: [],
    catalogoArquivoTipoLojas: [],
    catalogoArquivoLojaPerfis: [],
    catalogoArquivoCidades: [],
    catalogoArquivoEstados: [],
    tipoDisponibilizacao: 'lojas',
    arquivo: null,
    dsResumo: '',
};

export interface MenuGestaoCategoriaArquivoResponse {
    dsCategoriaArquivo: string;
    fgAtivo: boolean;
    idCategoriaArquivo: number;
    qtArquivos: number;
    idsFilhos: number[];
    subCategoriasArquivo: MenuGestaoCategoriaArquivoResponse[];
    categoriaArquivoPai?: {
        idCategoriaArquivo: number;
    };
}
