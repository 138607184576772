import React from 'react';

import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import { Imagem } from 'components/images';
import { useBannerData } from 'hooks/useBannerData';

export function CardBanner(): JSX.Element | null {
    const bannerData = useBannerData();
    const { pathname } = useLocation();

    const path = pathname.split('/').filter((p) => p !== '');

    if (path.length > 1 && path.find((p) => p === 'form' || (!isNaN(parseInt(p)) && typeof parseInt(p) === 'number'))) {
        return null;
    }

    if (!bannerData || !bannerData.length) {
        return null;
    }

    return (
        <Carousel
            navButtonsAlwaysVisible={bannerData.length >= 2}
            indicators={bannerData.length >= 2}
            cycleNavigation={true}
            interval={10000}
            animation="fade"
            autoPlay={true}
            fullHeightHover={true}
            swipe={true}
        >
            {Array.isArray(bannerData) &&
                bannerData?.map(({ idBanner, dsLinkArquivo, dsLinkBanner }: any) => (
                    <Box key={idBanner} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Imagem
                            src={dsLinkArquivo}
                            style={{ objectFit: 'cover', height: 'auto', width: 'auto', cursor: dsLinkBanner ? 'pointer' : 'default' }}
                            onClick={() => {
                                dsLinkBanner && window.open(dsLinkBanner, '_blank');
                            }}
                        />
                    </Box>
                ))}
        </Carousel>
    );
}
