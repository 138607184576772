import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/system';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface RelatorioBaseFaturamentoBtnProps {
    disabled: boolean;
    label: string;
    sx?: SxProps;
}

export function TituloExportarRelatorioBtn({ disabled, label, sx }: RelatorioBaseFaturamentoBtnProps): JSX.Element {
    const { filter } = useListagemContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/titulo/exportar/',
            columns: 'idMovimentacaoFiscal',
            filter,
        }),
        [filter],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClickDownload = useCallback((): void => {
        downloadFile({
            newRequestOptions: requestOptions,
            directDownload: true,
        });
    }, [downloadFile, requestOptions]);

    return (
        <LoadingButton loading={isLoadingFile} variant="outlined" onClick={handleClickDownload} disabled={disabled || isLoadingFile} sx={sx} startIcon={<RiFileExcel2Fill />}>
            {label}
        </LoadingButton>
    );
}
