import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestListagemProdutoCart } from 'pages/compra/pedido/types';

export function useGetCarrinho(): UseQueryResult<RequestListagemProdutoCart> {
    const { loja, funcionario, configPedido, setIdPedido, setInitialTotalizador, setInitialProdutosCart, setProdutos, getProdMinQtd } =
        usePedidoCompraContext();
    const requestListagem = useRequestListagem();
    const idLoja = loja?.idLoja;
    const idConfigPedido = configPedido?.idConfigPedido;
    const idUsuarioPedido = funcionario?.idUsuario;

    const filter: FilterValuesProps[] = [
        { property: 'idLoja', operator: 'eq', value: idLoja },
        { property: 'idConfigPedido', operator: 'eq', value: idConfigPedido },
    ];

    if (idUsuarioPedido) {
        filter.push({ property: 'idUsuarioPedido', operator: 'eq', value: idUsuarioPedido });
    }

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/rascunho',
        sort: [{ property: 'dsProduto', direction: 'ASC' }],
        filter,
    };

    return useQuery<RequestListagemProdutoCart>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(idConfigPedido && idLoja),
        onSuccess: (response) => {
            const { produtos, idPedido } = response;
            const newProdutos = Array.isArray(produtos)
                ? produtos.map((produtoCart) => {
                      const { qtPedido, vlPreco, produto } = produtoCart;
                      const { qtMinimaCompra } = produto;

                      return {
                          ...produtoCart,
                          qtMinimaCompra: getProdMinQtd(qtMinimaCompra ?? 0),
                          fgCart: true,
                          qtProdutoCart: qtPedido ?? 0,
                          vlPrecoCompra: vlPreco ?? 0,
                          qtProdutoList: qtPedido ?? 0,
                      };
                  })
                : [];

            setIdPedido(idPedido || null);

            setInitialTotalizador({
                vlTotal: newProdutos.reduce((acc, cur) => acc + cur.vlPrecoCompra * cur.qtProdutoCart, 0),
                vlPesoLiquidoTotal: newProdutos.reduce((acc, cur) => acc + cur.qtProdutoCart * (cur.produto.vlPesoLiquido ?? 0), 0),
            });

            setInitialProdutosCart(structuredClone(newProdutos));

            setProdutos(newProdutos);
        },
    });
}
