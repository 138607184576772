import React, { useState, MouseEvent, useCallback } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Menu, alpha } from '@mui/material';

import { Logo } from 'components/images';
import { MenuMainRenderer } from 'components/menu/main/components/MenuMainRenderer';

/**
 * Menu de sistema completo
 * Contém botão para abrir menu de sistema (ícone) + menu com opções disponiveis
 * Disponível somente em resoluções acima de 900px (md)
 */
export function MenuMain(): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>): void => setAnchorEl(e.currentTarget as Element), []);
    const handleClose = useCallback((): void => setAnchorEl(null), []);

    return (
        <React.Fragment>
            <Button
                disableRipple
                onClick={handleClick}
                className={open ? 'isOpen' : ''}
                sx={{
                    'mr': 'auto',
                    'height': '100%',
                    '&:hover, &.isOpen': {
                        backgroundColor: (theme) => alpha(theme.palette.common.black, 0.2),
                    },
                }}
            >
                <MenuIcon fontSize="large" sx={{ mr: 2, color: 'common.white' }} />

                <Box sx={{ height: { xs: '80%', sm: '100%' } }}>
                    <Logo />
                </Box>
            </Button>

            <Menu anchorEl={anchorEl as Element} open={open} onClose={handleClose}>
                <MenuMainRenderer onClose={handleClose} />
            </Menu>
        </React.Fragment>
    );
}
