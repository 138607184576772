import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { format, parse } from 'date-fns';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { EditBtn } from 'pages/logistica/grade/components/atoms/EditBtn';
import { FilterGrade } from 'pages/logistica/grade/components/templates/FilterGrade';
import { RequestGradeModelProps, RequestGradeStoreProps } from 'pages/logistica/grade/types';
import { brlDate } from 'util/format';

const generateImportacaoArquivoList = (data: any): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item: RequestGradeModelProps, index) => ({
            id: index + 1,
            idGrade: item.idGrade,
            dsNomeFantasia: item.loja?.dsNomeFantasia,
            cdLoja: item.loja?.cdLoja,
            dtLimitePedido: item.dtLimitePedido ? brlDate(`${item.dtLimitePedido}T00:00:00`) : '',
            mesFaturamento: item.mesFaturamento,
            dtFaturamento: item.dtFaturamento,
            dtColeta: item.dtColeta ? brlDate(`${item.dtColeta}T00:00:00`) : '',
            dtEntrega: item.dtEntrega ? brlDate(`${item.dtEntrega}T00:00:00`) : '',
            dsTipoGrade: item.tipoGrade?.dsTipoGrade,
            dhInicioEntrega: item.dhInicioEntrega ? format(new Date(item.dhInicioEntrega), 'HH:mm:ss') : '',
            dhFimEntrega: item.dhFimEntrega ? format(new Date(item.dhFimEntrega), 'HH:mm:ss') : '',
            dsObservacao: item.dsObservacao,
            fgPedido: item.fgPedido ? 'Sim' : 'Não',
        }));
    }

    return [];
};

export const GradeListComp = (): JSX.Element => {
    const RequestListagem = useRequestListagem();
    const { limit, page, filter, sort, autoLoad } = useListagemContext();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/grade',
            sort: sort.length ? sort : [{ property: 'cdLoja', direction: 'DESC' }],
            limit,
            page,
            filter,
            nested: true,
        }),
        [filter, limit, page, sort],
    );

    const { refetch, data, fetchStatus } = useQuery([requestOptions], (): Promise<RequestGradeStoreProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
    });

    const columns: GridColDef[] = [
        { field: 'dsNomeFantasia', headerName: 'Loja', width: 120, minWidth: 120 },
        { field: 'cdLoja', headerName: 'Código', width: 80, minWidth: 70 },
        { field: 'dtLimitePedido', headerName: 'Data Limite Pedido', width: 130, minWidth: 130 },
        {
            field: 'dtFaturamento',
            headerName: 'Mês Faturamento',
            width: 100,
            minWidth: 100,
            renderCell: ({ row }) => row.mesFaturamento,
        },
        { field: 'dtColeta', headerName: 'Data de Coleta', flex: 1, width: 100, minWidth: 100 },
        { field: 'dtEntrega', headerName: 'Data de Entrega', width: 130, minWidth: 110 },
        { field: 'dsTipoGrade', headerName: 'Tipo de Grade', width: 100, minWidth: 90 },
        { field: 'dhInicioEntrega', headerName: 'Hora Início Entrega', flex: 1, width: 100, minWidth: 100 },
        { field: 'dhFimEntrega', headerName: 'Hora Fim Entrega', flex: 1, width: 100, minWidth: 100 },
        { field: 'fgPedido', headerName: 'Possui pedido', width: 110, headerAlign: 'center', align: 'center' },
        { field: 'dsObservacao', headerName: 'Observação', flex: 1, width: 130, minWidth: 90 },
    ];

    if (hasPermission(['GRADE_GERENCIAR'])) {
        columns.push({
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 100,
            flex: 1,
            minWidth: 90,
            renderCell: ({ row }) => {
                const dtLimite = parse(row.dtLimitePedido, 'dd/MM/yyyy', new Date()).getTime();
                const dateNow = new Date().setHours(0, 0, 0, 0);

                const disableEdit = dtLimite < dateNow;
                const tooltip = disableEdit ? 'A data de limite do pedido é inferior a data atual' : '';

                return (
                    <React.Fragment>
                        <EditBtn onClick={() => navigate(`form/${row.idGrade}`)} disabled={disableEdit} tooltipText={tooltip} />
                        <EditRemoveBtn id={row.idGrade} showEditBtn={false} removeUrl={`/gestao/grade/${row.idGrade}`} sx={{ ml: 2 }} refresh={refetch} />
                    </React.Fragment>
                );
            },
        });
    }

    const rows = generateImportacaoArquivoList(data?.data || []);

    return (
        <OneColumn
            title="Grades"
            createButton={hasPermission(['GRADE_GERENCIAR'])}
            extraButtons={[
                {
                    title: 'Importar Grades',
                    onClick: () => navigate('importacao'),
                    visible: hasPermission(['GRADE_GERENCIAR']),
                },
                {
                    title: 'Excluir Grades',
                    onClick: () => navigate('exclusao'),
                    visible: hasPermission(['GRADE_GERENCIAR']),
                },
            ]}
        >
            <FilterGrade />

            <Block id="files-import-grid">
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
};
