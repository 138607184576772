import React, { useEffect, useState } from 'react';
import { ptBR } from 'date-fns/locale';

import { Grid, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { usePermissionsContext } from 'context/PermissionsContext';
import { DeviceAutoComplete } from 'components/autocompletes/DeviceAutoComplete';
import { ModuleAutoComplete } from 'components/autocompletes/ModuleAutoComplete';

export function FormFilterBanner(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsBanner',
        operator: 'like',
        value: '',
    });

    const [filterDevice, setFilterDevice] = useState<FilterValuesProps>({
        property: 'idTipoDispositivo',
        operator: 'in',
        value: null,
        idProperty: 'idTipoDispositivo',
    });

    const [filterModule, setFilterModule] = useState<FilterValuesProps>({
        property: 'idModulo',
        operator: 'in',
        value: [],
        idProperty: 'idModulo',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dtInicioVigencia',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: true,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterDevice, setter: setFilterDevice },
        { value: filterModule, setter: setFilterModule },
        { value: filterInterval, setter: setFilterInterval },
        { value: filterStatus, setter: setFilterStatus },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField label="Título" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <DeviceAutoComplete label="Dispositivo" value={filterDevice.value} onChange={(e, value) => setFilterDevice((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <ModuleAutoComplete value={filterModule.value} onChange={(e, value) => setFilterModule((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={2}>
                <LocalizationProvider autoComplete="no" adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Inicial', end: 'Data Final' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            {hasPermission(['BANNER_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
}
