import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { ListagemProvider } from 'context/ListagemContext';

interface ProdutoProps {
    idProduto: number;
    cdProduto: string;
    dsProduto: string;
}

interface CalendarioListEventPromocaoGridProps {
    data: ProdutoProps[];
}

export function CalendarioListEventPromocaoGrid({ data }: CalendarioListEventPromocaoGridProps): JSX.Element {
    const columns: GridColDef[] = [
        { field: 'cdProduto', headerName: 'Código', type: 'number', minWidth: 100 },
        { field: 'dsProduto', headerName: 'Descrição', type: 'string', minWidth: 180, flex: 1 },
    ];

    const generateRows = (data: ProdutoProps[]): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: index + 1,
                cdProduto: item.cdProduto,
                dsProduto: item.dsProduto,
            }));
        }

        return [];
    };

    const rows = generateRows(data);

    return (
        <ListagemProvider>
            <PanelGrid rows={rows} rowsCount={rows.length || 0} reload={false} loading={false} columns={columns} remote={false} />
        </ListagemProvider>
    );
}