import React, { ReactNode } from 'react';

import { Container } from '@mui/material';

import { Tbar } from 'components/docked';
import { TbarProps } from 'components/docked/Tbar';
import { useDomainConfigContext } from 'context/DomainConfig';
import { useTitle } from 'hooks/useTitle';

interface OneColumnProps extends TbarProps {
    children: ReactNode;
}

/**
 * Estrutura padrão do sistema para página do tipo 1 coluna (oneColumn) (sem aside)
 * Contém header + titulo + conteudo + footer
 */
export function OneColumn(props: OneColumnProps): JSX.Element {
    const { children, title, ...tbarProps } = props;
    const { data } = useDomainConfigContext();
    const { documentTitle } = data;

    useTitle({ documentTitle, pageTitle: title });

    return (
        <Container
            component="main"
            maxWidth="xl"
            disableGutters
            sx={{
                my: 2,
                px: 2,
            }}
        >
            <Tbar title={title} {...tbarProps} />

            {children}
        </Container>
    );
}
