import React, { useEffect, useState } from 'react';

import {  Grid } from '@mui/material';

import {  EstadoAutoComplete, PercentualNotaMetaSazonalAutoComplete } from 'components/autocompletes';
import { CissNumberField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function PercentualNotaFiscalGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterPercentualMotaMetaSazonal, setFilterPercentualMotaMetaSazonal] = useState<FilterValuesProps>({
        property: 'idPercentualNotaRoyaltiesSazonal',
        operator: 'in',
        value: [],
        idProperty: 'idPercentualNotaRoyaltiesSazonal',
    });

    const [filterEstado, setFilterEstado] = useState<FilterValuesProps>({
        property: 'idEstado',
        operator: 'in',
        value: [],
        idProperty: 'idEstado',
    });
    
    const [filterNotaFiscal, setFilterNotaFiscal] = useState<FilterValuesProps>({
        property: 'peNotaFiscal',
        operator: 'eq',
        value: null,
    });

    const filters = [
        { value: filterPercentualMotaMetaSazonal, setter: setFilterPercentualMotaMetaSazonal },
        { value: filterEstado, setter: setFilterEstado },
        { value: filterNotaFiscal, setter: setFilterNotaFiscal },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={3}>
                <PercentualNotaMetaSazonalAutoComplete
                    multiple={true}
                    value={filterPercentualMotaMetaSazonal.value}
                    onChange={(e, value) => setFilterPercentualMotaMetaSazonal((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item flex={3}>
                <EstadoAutoComplete label="UF Destino" multiple={true} value={filterEstado.value} onChange={(e, value) => setFilterEstado((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={3}>
                <CissNumberField
                    label="Nota Fiscal %"
                    inputType="decimal"
                    preventEmptyField={false}
                    maxValue={100}
                    forceMaxValue={true}
                    value={filterNotaFiscal.value}
                    onChange={(e) => setFilterNotaFiscal((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
};
