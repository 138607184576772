import React from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { Box, Button, Divider, Typography, Tooltip } from '@mui/material';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { PedidoCompraAmmount } from 'pages/compra/pedido/components';
import { ProdutoCart } from 'pages/compra/pedido/types';
import { brlPrice } from 'util/format';

interface PedidoCompraCartProductProps {
    produtoCart: ProdutoCart;
}

export function PedidoCompraCartProduct({ produtoCart }: PedidoCompraCartProductProps): JSX.Element {
    const { removeProdCart, modifyProdCartQtd, getProdCartQtd, isProdutoDisabled, getProdMinQtd, isSavingCarrinho } = usePedidoCompraContext();
    const { produto, qtMinimaCompra } = produtoCart;
    const produtoDisabled = isProdutoDisabled(produtoCart);
    const opacity = produtoDisabled ? 0.6 : 1;
    const isAboveTenMultiplied = getProdCartQtd(produto.idProduto) >= getProdMinQtd(qtMinimaCompra) * 10;

    return (
        <React.Fragment>
            <Tooltip followCursor={true} title={produtoDisabled ? 'Produto indisponível para compra' : null}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 1,
                        }}
                    >
                        <Box
                            sx={{
                                width: 50,
                                height: 50,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundImage: `url("${produto.lkImagem}")`,
                                borderRadius: 1,
                                opacity,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {!produto.lkImagem && <InsertPhotoOutlinedIcon fontSize="large" sx={{ color: (theme) => theme.palette.grey[400] }} />}
                        </Box>

                        <Typography
                            variant="body2"
                            sx={{
                                flex: 1,
                                opacity,
                            }}
                        >
                            {produto.dsProduto}
                        </Typography>

                        <Button
                            onClick={() => removeProdCart(produto.idProduto)}
                            disabled={isSavingCarrinho}
                            color="error"
                            variant="outlined"
                            sx={{
                                minWidth: 'auto',
                                p: 0.75,
                            }}
                        >
                            <DeleteOutlinedIcon />
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 2,
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <PedidoCompraAmmount
                                getQtd={getProdCartQtd}
                                modifyQtd={modifyProdCartQtd}
                                produtoPedido={produtoCart}
                                sx={{ width: 110, height: 26 }}
                            />

                            {isAboveTenMultiplied && (
                                <Tooltip title="Quantidade é igual ou superior ao múltiplo de 10 vezes a quantidade mínima do produto">
                                    <InfoOutlinedIcon color="warning" />
                                </Tooltip>
                            )}
                        </Box>

                        <Typography variant="body2" sx={{ opacity }}>
                            Total: <strong>{brlPrice(produtoCart.vlPrecoCompra * produtoCart.qtProdutoCart)}</strong>
                        </Typography>
                    </Box>
                </Box>
            </Tooltip>

            <Divider sx={{ my: 2 }} />
        </React.Fragment>
    );
}
