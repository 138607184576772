import React from 'react';

import { useNavigate } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, Tooltip, Box } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { TradeSolicitaoPecasListCardIndicator, TradeSolicitacaoPecasListFilter, TradeSolicitacaoPecasListStatusIndicatorTitle } from '..';
import { SolicitacaoPecasProps, RequestSolicitacaoPecasModelProps, PecasItensProps } from '../../types';

import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { brlDate, brlList } from 'util/format';

const getPeriodoIndicador = (): string => {
    const firstDay = dateFns.startOfMonth(new Date());
    const todayFormatted = dateFns.format(new Date(), 'dd/MM');

    if (dateFns.isAfter(new Date(), firstDay)) {
        return `Periodo: ${dateFns.format(firstDay, 'dd/MM')} até ${todayFormatted}`;
    }

    return `Periodo: ${todayFormatted}`;
};

const generateCampanhasList = (data: SolicitacaoPecasProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => {
            const listTipoPeca = (item.itens || []).map((TipoPeca: PecasItensProps) => TipoPeca.dsTipoPeca);
            const dsTipoPeca = listTipoPeca.length ? listTipoPeca : ['--'];

            return {
                id: index + 1,
                idSolicitacaoPeca: item.idSolicitacaoPeca,
                dsNomeFantasia: item.loja.dsRazaoSocial,
                dsUsuario: item.usuarioSolicitante.dsUsuario,
                dsStatus: item.solicitacaoPecaStatus.dsStatus,
                dhInclusao: item.dhInclusao,
                fgAtivo: item.fgAtivo,
                idSolicitacaoPecaStatus: item.idSolicitacaoPecaStatus,
                dsTipoPeca,
            };
        });
    }

    return [];
};

export function TradeSolicitacaoPecasListComp(): JSX.Element {
    const navigate = useNavigate();
    const { filter, sort, page, autoLoad, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/solicitacaopeca/lista',
        filter,
        page,
        sort: sort.length ? sort : [{ property: 'idSolicitacaoPeca', direction: 'DESC' }],
        columns: 'idSolicitacaoPeca,dsNomeFantasia,dsUsuario,dsStatus,dhInclusao,idSolicitacaoPecaStatus',
        nested: true,
        limit,
    };

    const { fetchStatus, refetch, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestSolicitacaoPecasModelProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const columns: GridColDef[] = [
        { field: 'idSolicitacaoPeca', headerName: 'Id', minWidth: 50 },
        { field: 'dhInclusao', headerName: 'Data Emissão', minWidth: 90, valueFormatter: ({ value }) => brlDate(value) },
        { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 150 },
        { field: 'dsUsuario', headerName: 'Solicitante', flex: 1, minWidth: 150 },
        {
            field: 'dsTipoPeca',
            headerName: 'Peça',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: params.value.join('<br/>') as string }} />}>
                    <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{brlList(params.value)}</Box>
                </Tooltip>
            ),
        },
        {
            field: 'dsStatus',
            headerName: 'Status',
            filterable: false,
            flex: 1,
            minWidth: 220,
            renderCell: (params) => <TradeSolicitacaoPecasListStatusIndicatorTitle rowData={params.row} />,
        },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 50,
            renderCell: (params) => (
                <IconButton sx={{ p: 0 }} onClick={() => navigate(`/trade/solicitacoes-pecas/form/${params.row.idSolicitacaoPeca}`)} title="Visualizar solicitação">
                    <VisibilityIcon />
                </IconButton>
            ),
        },
    ];

    const rows = generateCampanhasList(data?.data);

    return (
        <OneColumn title="Solicitações de Peças" createButton={hasPermission(['TRADE_SOLICITACAO_PECA_SOLICITAR'])}>
            <Block>
                <SubTitle
                    label={
                        <React.Fragment>
                            {`Indicadores e Métricas. ${getPeriodoIndicador()}  `}

                            <Tooltip
                                title={
                                    'Dados do período atual(primeiro dia do mês até o dia atual). Ex: 01/01 até 15/01. Comparado com os dados do período anterior, considerando o mesmo número de dias do período atual. Ex: 01/12 até 15/12.'
                                }
                            >
                                <InfoOutlinedIcon
                                    color="success"
                                    sx={{
                                        ml: 2,
                                        transform: 'translateY(3px)',
                                    }}
                                />
                            </Tooltip>
                        </React.Fragment>
                    }
                />

                {hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR']) && <TradeSolicitaoPecasListCardIndicator />}
            </Block>

            <TradeSolicitacaoPecasListFilter />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
