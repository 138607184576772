import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';

import { CustomSnackbarProvider } from 'components/snackbar';
import { AplicacaoDataProvider } from 'context/AplicacaoDataContext';
import { DomainConfigProvider } from 'context/DomainConfig';
import { GenericAuthProvider } from 'context/GenericAuthContext';
import { PermissionsProvider } from 'context/PermissionsContext';
import { ServiceApiProvider } from 'context/ServiceApiContext';
import { LoginModal } from 'pages/login/components';
import { Navigator } from 'routes';
import { CustomTheme } from 'styles/customTheme';

if (typeof process.env.REACT_APP_MUI_LICENCE === 'string') {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE);
}

Yup.setLocale(ptForm);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: false,
        },
    },
});

const fontesCss = document.getElementById('fontes_edicao_pecas') as HTMLLinkElement;
if (fontesCss) {
    fontesCss.href = `/proxy/fonts.css?hash=${Math.ceil(Math.random() * 100000)}`;
}

const container = document.getElementById('app');
const root = createRoot(container as HTMLElement);

root.render(
    <React.StrictMode>
        <DomainConfigProvider>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <CustomTheme>
                        <GenericAuthProvider>
                            <CustomSnackbarProvider>
                                <ServiceApiProvider>
                                    <AplicacaoDataProvider>
                                        <PermissionsProvider>
                                            <LoginModal />
                                            <Navigator />
                                        </PermissionsProvider>
                                    </AplicacaoDataProvider>
                                </ServiceApiProvider>
                            </CustomSnackbarProvider>
                        </GenericAuthProvider>
                    </CustomTheme>
                </QueryClientProvider>
            </BrowserRouter>
        </DomainConfigProvider>
    </React.StrictMode>,
);
