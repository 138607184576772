import React from 'react';

import {  } from '@mui/lab';
import { Alert, Box, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Centered } from 'components/grid';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface PedidoLoteCompraErrosModal {
    idPedidoImportacaoLoja?: number;
    open: boolean;
    setOpen: (action: boolean) => void;
}

interface PedidoImportacaoErrosProps {
    idPedidoImportacaoValidacao: number;
    dsPedidoImportacaoValidacao: string;
    dhInclusao: string;
}

export function PedidoLoteCompraErrosModal({ open, setOpen, idPedidoImportacaoLoja }: PedidoLoteCompraErrosModal): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: `/compra/pedidoimportacaovalidacao/pedidoimportacaoloja?idPedidoImportacaoLoja=${idPedidoImportacaoLoja}`,
    };

    const { isLoading, fetchStatus, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<PedidoImportacaoErrosProps[]> = RequestListagem(requestOptions).then((res) => res.data);

            return request;
        },
        {
            enabled: Boolean(idPedidoImportacaoLoja),
        },
    );

    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 2, width: { xs: '80%', sm: 'auto' } }} closeButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Erros" />
                {isLoading && fetchStatus === 'fetching' && (
                    <Centered>
                        <CircularProgress sx={{ my: 2 }} />
                    </Centered>
                )}
                <Stack spacing={2}>
                    {data &&
                        data.map((item): JSX.Element => {
                            return (
                                <Alert key={item.idPedidoImportacaoValidacao} variant="outlined" severity="error">
                                    {item.dsPedidoImportacaoValidacao}
                                </Alert>
                            );
                        })}
                </Stack>
            </Box>
        </ContentModal>
    );
}
