export interface RequestDepositosProps {
    idDeposito: number | null;
    dsDeposito: string;
    cdDeposito: string;
    fgAtivo: boolean;
}

export const initialDepositosValues: RequestDepositosProps = {
    idDeposito: null,
    dsDeposito: '',
    cdDeposito: '',
    fgAtivo: true,
};
