import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissNumberField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function RevendedorGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        idProperty: 'cdLoja',
        property: 'cdSap',
        operator: 'eq',
        value: null,
        required: 'Loja é obrigatória',
    });

    const [filterDocumento, setFilterDocumento] = useState<FilterValuesProps>({
        property: 'documento',
        operator: 'eq',
        value: null,
    });

    const filters = [
        { value: filterDocumento, setter: setFilterDocumento },
        { value: filterLoja, setter: setFilterLoja },
    ];

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={() => handleFilterReset({ filters })} submit={() => handleFilterSubmit({ filters })}>
            <Grid item flex={4}>
                <StoreAutoComplete
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissNumberField
                    label="Documento"
                    preventEmptyField={false}
                    value={filterDocumento.value}
                    onChange={(e) => setFilterDocumento((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
