import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { PromocaoRequest } from '../types';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useGetPromocao(): UseQueryResult<PromocaoRequest> {
    const requestListagem = useRequestListagem();
    const { page } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        url: '/integracao-revendedor/promocoes',
        sort: [{ property: 'codigo', direction: 'ASC' }],
        limit: 10,
        nested: true,
        page,
    };

    return useQuery<PromocaoRequest>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data));
}
