import React, { Dispatch, SetStateAction } from 'react';

import { ConfirmModal } from 'components/modal';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { FilterValuesProps, ValueSetterFiltersProps, useFilter } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

type PedidoChangeConfigModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    filters: ValueSetterFiltersProps[];
};

export function PedidoChangeConfigModal({ open, setOpen, filters }: PedidoChangeConfigModal): JSX.Element {
    const { filterLoja, setFilterLoja, setFilterConfigPedido, filterConfigPedido } = usePedidoFilterContext();
    const { setConfigPedido, setLoja, configPedido, loja } = usePedidoCompraContext();
    const { handleFilterSubmit } = useFilter();

    return (
        <ConfirmModal
            open={open}
            setOpen={setOpen}
            text="Ao trocar de configuração você perderá todas as alterações não salvas no pedido. Deseja continuar?"
            confirm={() => {
                handleFilterSubmit({ filters });

                setLoja(filterLoja.value);
                setConfigPedido(filterConfigPedido.value);
            }}
            cancel={() => {
                setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value: configPedido }));
                setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: loja }));
            }}
        />
    );
}
