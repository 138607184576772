import React, { useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestPedidoTotalizadorQuebraProps } from 'pages/compra/pedido-totalizador/types';
import { brlDate } from 'util/format';

const getRows = (data: RequestPedidoTotalizadorQuebraProps | undefined): GridRowsProp => {
    if (Array.isArray(data?.data) && data?.data.length) {
        return data?.data?.map((item) => ({
            id: item.idPedido,
            idPedido: item.idPedido,
            dtQuebrdtInicioQuebraPedidoaPedido: item.dtInicioQuebraPedido && brlDate(`${item.dtInicioQuebraPedido}T00:00:00`),
            idLoja: item.loja.idLoja,
            cdLoja: item.loja.cdLoja,
            dsNomeFantasia: item.loja.dsNomeFantasia,
            dsRazaoSocial: item.loja.dsRazaoSocial,
            cdPedido: item.cdPedido ?? null,
            nrPedido: item.nrPedido ?? null,
            idConfigPedido: item.configPedido.idConfigPedido,
            dsConfigPedido: item.configPedido.dsConfigPedido,
            vlTotal: item.vlTotal,
            fgAtivo: item.fgAtivo,
            idPedidoStatus: item.pedidoStatus.idPedidoStatus,
            dsPedidoStatus: item.pedidoStatus.dsPedidoStatus,
            dsValidacao: item.dsValidacao,
        }));
    }

    return [];
};

const getColumns: GridColDef[] = [
    { field: 'dtInicioQuebraPedido', headerName: 'Data da Quebra', minWidth: 110, align: 'center', headerAlign: 'center' },
    { field: 'dsConfigPedido', headerName: 'Configuração de Pedido', flex: 1, minWidth: 260 },
    { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 170 },
    { field: 'dsValidacao', headerName: 'Falha', flex: 4, minWidth: 130 },
    { field: 'nrPedido', headerName: 'Código do Pedido', width: 100, minWidth: 80 },
    { field: 'cdPedido', headerName: 'Código de Integração', width: 100, minWidth: 80 },
];

export function StatusIntegracaoGrid(): JSX.Element {
    const { limit, page, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const [searchParams] = useSearchParams();
    const configPedidoOrigemList = searchParams.get('configPedidoOrigemList');

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/compra/pedidocompra/quebras/configpedidoorigem',
            sort: sort.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
            filter: [
                { property: 'idConfigPedidoOrigem', value: configPedidoOrigemList && JSON.parse(configPedidoOrigemList), operator: 'in' },
                { property: 'idPedidoStatus', value: 13, operator: 'eq' },
            ],
            limit,
            page,
        }),
        [configPedidoOrigemList, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(['getPedidos', requestOptions], (): Promise<RequestPedidoTotalizadorQuebraProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(configPedidoOrigemList),
    });

    return (
        <Block>
            <SubTitle label="Pedidos com Falha" />

            <PanelGrid rows={getRows(data)} reload={refetch} loading={fetchStatus === 'fetching'} columns={getColumns} rowsCount={data?.total ?? 0} />
        </Block>
    );
}
