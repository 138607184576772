import React, { useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Alert, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Responsive } from 'components/content';
import { Centered } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { PermissionKey, usePermissionsContext } from 'context/PermissionsContext';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface BIType {
    idConfigBi: number;
    dsConfigBi: string;
    dsUrl: string;
    dsUrlMobile: string;
    vlAltura?: number;
}

interface RequestBIType extends RequestListagemProps {
    data: BIType[];
}

export function BiPageComp(): JSX.Element | null {
    const { hasPermission, alertNotPermission, isLoadingPermissions } = usePermissionsContext();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const RequestListagem = useRequestListagem();
    const { biName = 'INICIO' } = useParams();

    const requestOptions: RequestOptionsType = {
        url: '/config/configbi',
        columns: 'idConfigBi,dsConfigBi,dsUrl,dsUrlMobile,vlAltura,cdPainelBi,idTipoPainelBi',
    };

    const { isSuccess, fetchStatus, data } = useQuery([requestOptions], () => {
        const request: Promise<RequestBIType> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    const iframesBi = useMemo(() => data?.data || [], [data?.data]);
    const itemPosition = iframesBi?.map((e) => e.dsConfigBi).indexOf(biName.toLocaleLowerCase()) || 0;
    const itemPermission = iframesBi.length > 0 && itemPosition > 0 ? iframesBi[itemPosition].dsConfigBi.toUpperCase() : biName;
    const permissionKeyStr = `BI_${itemPermission}_VISUALIZAR` as PermissionKey;

    useEffect(() => {
        if (itemPermission === 'INICIO' && !isLoadingPermissions && !hasPermission([permissionKeyStr]) && hasPermission(['COMUNICADO_VISUALIZAR'])) {
            navigate('/comunicado');
        }
    }, [itemPermission, isLoadingPermissions, permissionKeyStr]);

    const getUrl = useCallback((): string => {
        const { dsUrlMobile, dsUrl } = iframesBi[itemPosition];

        return isMobile && dsUrlMobile ? dsUrlMobile : dsUrl;
    }, [iframesBi, isMobile, itemPosition]);

    if (itemPermission && !hasPermission([permissionKeyStr])) {
        if (itemPermission === 'INICIO') {
            return null;
        }

        return alertNotPermission();
    }

    return (
        <React.Fragment>
            {fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress />
                </Centered>
            )}

            <OneColumn title={'Indicadores'}>
                {isSuccess && iframesBi.length > 0 ? (
                    <Block key={iframesBi[itemPosition].idConfigBi}>
                        <Responsive height={iframesBi[itemPosition].vlAltura}>
                            <iframe
                                title={iframesBi[itemPosition].dsConfigBi}
                                src={getUrl()}
                                id={`iframe_bi_${iframesBi[itemPosition].dsUrl}`}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </Responsive>
                    </Block>
                ) : (
                    <Alert severity="warning">Painel BI não configurado.</Alert>
                )}
            </OneColumn>
        </React.Fragment>
    );
}
