// iconUtils.ts
import React from 'react';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import BookOutlined from '@mui/icons-material/BookOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MmsOutlinedIcon from '@mui/icons-material/MmsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';

type IconDictionaryProps = { [key: string]: JSX.Element };

const ICONS: IconDictionaryProps = {
    comunicacao: <CampaignOutlinedIcon />,
    financeiro: <MonetizationOnOutlinedIcon />,
    pedido: <RequestQuoteOutlinedIcon />,
    marketing: <MmsOutlinedIcon />,
    calendario: <CalendarMonthOutlinedIcon />,
    arquivo: <AttachFileOutlinedIcon />,
    promocao: <LocalOfferOutlinedIcon />,
    bi: <BarChartOutlinedIcon />,
    chamado: <SupportAgentOutlinedIcon />,
    usuario: <PeopleAltOutlinedIcon />,
    trade: <DisplaySettingsOutlinedIcon />,
    revendedor: <AddShoppingCartIcon />,
    banner: <ViewCarouselOutlinedIcon />,
    logistica: <LocalShippingOutlinedIcon />,
    complementar: <SettingsOutlinedIcon />,
    cadastro: <BookOutlined />,
    estatistica: <EqualizerOutlinedIcon />,
};

export const getIcons = (key: string): JSX.Element | null => ICONS[key] || null;
