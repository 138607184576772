import React from 'react';

import { OneColumn } from 'components/page';
import { PedidoRealizadoRelatorio } from 'pages/compra/pedido-realizado/components';
import { PedidoRealizadoGrid } from 'pages/compra/pedido-realizado/components/templates/PedidoRealizadoGrid';
import { PedidoRealizadoListFilter } from 'pages/compra/pedido-realizado/components/templates/PedidoRealizadoListFilter';

export function PedidoRealizadoListComp(): JSX.Element {
    return (
        <OneColumn title="Consulta de Pedidos" goBackButton createButton={false}>
            <PedidoRealizadoListFilter />

            <PedidoRealizadoRelatorio />

            <PedidoRealizadoGrid />
        </OneColumn>
    );
}
