import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { MarketingTagFormComp, MarketingTagListComp } from 'pages/marketing-trade/marketing/tag/components';

export function TagListMarketing(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['MARKETING_PECA_MODERAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <MarketingTagListComp />
        </ListagemProvider>
    );
}

export function TagFormMarketing(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['MARKETING_PECA_MODERAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <MarketingTagFormComp />
        </FormProvider>
    );
}
