import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { NotaDevolucaoFormComp, NotaDevolucaoListComp } from 'pages/financeiro/nota-devolucao/components';
import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function NotaDevolucaoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['FINANCEIRO_DEVOLUCAO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <NotaDevolucaoListComp />
        </ListagemProvider>
    );
}

export function NotaDevolucaoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DEVOLUCAO_SIMULAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <ListagemProvider>
                <NotaDevolucaoFormComp />
            </ListagemProvider>
        </FormProvider>
    );
}
