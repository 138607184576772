import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

export interface GradeOptionsProps {
    idTipoGrade: number;
    dsTipoGrade: string;
}

type GradeAutoCompleteProps = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function GradeAutoComplete({ value, error, label = 'Grade', multiple = true, disabled = false, sx, onChange = () => {}, name }: GradeAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsTipoGrade}"
            optionStringTemplate="{dsTipoGrade}"
            requestOptions={{
                url: '/gestao/tipograde',
                sort: [{ property: 'idTipoGrade', direction: 'ASC' }],
            }}
            isOptionEqualToValue={(option: GradeOptionsProps, value: GradeOptionsProps) => option.idTipoGrade === value.idTipoGrade}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
