import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import { CissTextField } from 'components/fields';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface TipoGrupoUsuario {
    idTipoGrupoUsuario: number;
    dsTipoGrupoUsuario: string;
}

interface FieldOptionsProps {
    idGrupoUsuario: number;
    dsGrupoUsuario: string;
    tipoGrupoUsuario: TipoGrupoUsuario;
}

interface RequestListagemType extends RequestListagemProps {
    data: FieldOptionsProps[];
}

interface StoreFieldProps extends AutocompleteProps<FieldOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
}

export function GrupoUsuarioField({ multiple = true, label = 'Grupo de Usuários', ...props }: StoreFieldProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [query, setQuery] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [{ value }, { touched, error }] = useField(props.field.name);
    const RequestListagem = useRequestListagem();
    const [queryValue] = useDebounce(query, 500);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/grupousuario',
        query: queryValue,
        sort: [{ property: 'idGrupoUsuario', direction: 'ASC' }],
        limit: 50,
    };

    const { isLoading, fetchStatus, data } = useQuery(
        [requestOptions],
        (): Promise<RequestListagemType> => {
            return RequestListagem(requestOptions).then((res) => res.data);
        },
        {
            keepPreviousData: false,
            enabled: open,
            onError: () => {
                enqueueSnackbar('Falha ao processar solicitação de grupos de usuários', { variant: 'error' });
            },
        },
    );

    useEffect(() => {
        if (open) {
            setQuery(inputValue);
        }
    }, [inputValue, open]);

    return (
        <Autocomplete
            {...props}
            multiple={multiple}
            loading={isLoading && fetchStatus === 'fetching'}
            options={data?.data ?? []}
            isOptionEqualToValue={(option: any, fieldValue: any) => option.idGrupoUsuario === fieldValue.idGrupoUsuario}
            getOptionLabel={(option: any) => `${option.idGrupoUsuario} - ${option.dsGrupoUsuario}`}
            renderInput={(params) => <CissTextField label={label} error={touched && Boolean(error)} helperText={error} {...params} />}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            renderOption={(props, option: any) => (
                <Box component="li" {...props} key={option.idGrupoUsuario}>
                    {option.idGrupoUsuario} - {option.dsGrupoUsuario}
                </Box>
            )}
        />
    );
}
