import React, { useState } from 'react';

import { Block, OneColumn } from 'components/page';
import { SugestaoCompraListFilter, SugestaoCompraImportarModal, SugestaoCompraGrid,  SugestaoCompraExcluirModal} from 'pages/compra/sugestao/components';

export function SugestaoCompraListComp(): JSX.Element {
    const [openModalImportar, setOpenModalImportar] = useState(false);
    const [openModalExcluir, setOpenModalExcluir] = useState(false);

    return (
        <OneColumn
            title="Sugestão de Compra"
            extraButtons={[
                {
                    title: 'Importar',
                    onClick: () => setOpenModalImportar(true),
                },
                {
                    title: 'Excluir',
                    onClick: () => setOpenModalExcluir(true),
                },
            ]}
        >
            <SugestaoCompraExcluirModal openModalExcluir={openModalExcluir} setOpenModalExcluir={setOpenModalExcluir} />

            <SugestaoCompraImportarModal openModalImportar={openModalImportar} setOpenModalImportar={setOpenModalImportar} />

            <SugestaoCompraListFilter />

            <SugestaoCompraGrid />
        </OneColumn>
    );
}
