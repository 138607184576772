import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

interface ConfirmModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
    confirm?: () => void;
    cancel?: () => void;
    text?: JSX.Element | string;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    showCancel?: boolean;
}

export function ConfirmModal({ open, setOpen, confirm, cancel, text, title, confirmText, cancelText, showCancel = true }: ConfirmModalProps): JSX.Element {
    const close = (): void => {
        if (typeof setOpen === 'function') {
            setOpen(false);
        }
    };

    const handleCancel = (): void => {
        if (typeof cancel === 'function') {
            cancel();
        }

        close();
    };

    const handleConfirm = (): void => {
        if (typeof confirm === 'function') {
            confirm();
        }

        close();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{title ?? 'Confirmação'}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ whiteSpace: 'pre-line' }}>{text ?? 'Tem certeza que deseja remover o registro selecionado?'}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {showCancel && <Button onClick={handleCancel}>{cancelText ?? 'Não'}</Button>}
                <Button onClick={handleConfirm} disabled={!open}>
                    {confirmText ?? 'Sim'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
