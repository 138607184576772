import React from 'react';

import { Grid, Box, Paper } from '@mui/material';

import { Fit, Centered } from 'components/grid';
import { Logo } from 'components/images';
import { LoginAtivacaoForm } from 'pages/login-ativacao/components';
import { useDomainConfigContext } from 'context/DomainConfig';

export function LoginAtivacaoPageComp(): JSX.Element {
    const { data } = useDomainConfigContext();
    const { mainGradient } = data;

    return (
        <Fit
            sx={{
                backgroundImage: mainGradient,
                flexDirection: { xs: 'column', md: 'row' },
            }}
        >
            <Grid
                item
                component="header"
                xs={12}
                md
                sx={{
                    height: { xs: 150, md: 'auto' },
                    mb: { xs: 4, md: 0 },
                }}
            >
                <Centered>
                    <Box sx={{ p: 2, maxWidth: '95%' }}>
                        <Logo />
                    </Box>
                </Centered>
            </Grid>
            <Grid item component="main" xs={12} md="auto">
                <Centered>
                    <Box
                        component={Paper}
                        elevation={6}
                        sx={{
                            padding: { xs: 2, md: 5 },
                            maxWidth: 400, // definido tamanho máximo lateral login (400px)
                            marginRight: { xs: '', md: '4vw', lg: '10vw', xl: '15vw' },
                        }}
                    >
                        <LoginAtivacaoForm />
                    </Box>
                </Centered>
            </Grid>
        </Fit>
    );
}
