import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface PercentualNotaMetaSazonalOptionsProps {
    idPercentualNotaRoyaltiesSazonal: number;
    dsPercentualNotaRoyaltiesSazonal: string;
}

type PercentualNotaMetaSazonalAutoCompleteProps = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'requestOptions'>;

export function PercentualNotaMetaSazonalAutoComplete({
    value,
    error,
    label = 'Tipo de Percentual',
    multiple = false,
    disabled = false,
    sx,
    onChange = () => {},
    requestOptions = {
        url: '/gestao/percentualnotalroyaltiessazonal',
        sort: [{ property: 'dsPercentualNotaRoyaltiesSazonal', direction: 'ASC' }],
    },
}: PercentualNotaMetaSazonalAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsPercentualNotaRoyaltiesSazonal}"
            optionStringTemplate="{dsPercentualNotaRoyaltiesSazonal}"
            requestOptions={requestOptions}
            isOptionEqualToValue={(option: PercentualNotaMetaSazonalOptionsProps, value: PercentualNotaMetaSazonalOptionsProps) =>
                option.idPercentualNotaRoyaltiesSazonal === value.idPercentualNotaRoyaltiesSazonal
            }
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            queryKey="getPercentualNotaMetaSazonal"
        />
    );
}
