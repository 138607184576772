import React, { useId } from 'react';

import { Grid, Typography } from '@mui/material';

interface DashedGridListProps {
    label: string;
    value?: string | number;
    featured?: boolean;
}

interface DashedGridProps {
    list: DashedGridListProps[];
}

export function DashedGrid({ list }: DashedGridProps): JSX.Element {
    const id = useId();

    return (
        <Grid
            container
            sx={{
                'border': '1px dashed',
                'flexDirection': 'column',
                'flex': 1,
                'p': 1,
                'mt': 1,
                '& .MuiGrid-item': {
                    display: 'flex',
                    justifyContent: 'flex-end',
                },
            }}
        >
            {list.map(({ label, value, featured }) => {
                const sx = { mt: featured ? 2 : 0 };
                return (
                    <Grid item key={label + id} sx={sx} columns={12}>
                        <Grid item sx={{ height: 21 }} xs={6}>
                            <Typography noWrap>{label}</Typography>
                        </Grid>

                        <Grid item sx={{ height: 21 }} xs={6}>
                            <Typography noWrap>{featured ? <strong>{value}</strong> : value}</Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}
