import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

export interface MarcaOptionsProps {
    idMarca: number;
    dsMarca: string;
}

type MarcaAutoCompleteProps = Pick<
    CissAutoCompleteProps,
    | 'name'
    | 'label'
    | 'value'
    | 'error'
    | 'multiple'
    | 'disabled'
    | 'sx'
    | 'onChange'
    | 'size'
    | 'requestOptions'
    | 'selectedStringTemplate'
    | 'optionStringTemplate'
>;

export function MarcaAutoComplete({
    sx,
    size,
    name,
    value,
    error,
    label = 'Marca',
    selectedStringTemplate = '{dsMarca}',
    optionStringTemplate = '{dsMarca}',
    multiple = false,
    disabled = false,
    onChange = () => {},
    requestOptions = {
        url: '/gestao/marca',
        limit: 100,
        sort: [{ property: 'idMarca', direction: 'ASC' }],
    },
}: MarcaAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            size={size}
            name={name}
            error={error}
            value={value}
            label={label}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            requestOptions={requestOptions}
            optionStringTemplate={optionStringTemplate}
            selectedStringTemplate={selectedStringTemplate}
            isOptionEqualToValue={(option: MarcaOptionsProps, value: MarcaOptionsProps) => option.idMarca === value.idMarca}
        />
    );
}
