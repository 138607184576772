import React from 'react';

import { Box, Typography } from '@mui/material';

import { HistoricoPedidos, ProdutoPedido } from 'pages/compra/pedido/types';
import { brlDecimal } from 'util/format';

interface PedidoProdutoModalHistoricoPeriodo {
    periodo: HistoricoPedidos;
    produto: ProdutoPedido;
}

export function PedidoProdutoModalHistoricoPeriodo({ periodo, produto }: PedidoProdutoModalHistoricoPeriodo): JSX.Element {
    const { dsPeriodo, qtProduto } = periodo;

    return (
        <Box sx={{ minHeight: 38 }}>
            <Typography sx={{ fontSize: '.8rem' }}>{dsPeriodo}</Typography>

            <Typography
                sx={{
                    lineHeight: 1,
                    fontSize: '1.2rem',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    mt: 0.5,

                    span: {
                        fontSize: '0.8rem',
                        display: 'block',
                        fontWeight: 300,
                    },
                }}
            >
                {brlDecimal(qtProduto || 0, 0, 3)}

                <span>
                    {produto?.produto.unidadeMedida?.dsUnidadeMedida?.toLowerCase()}
                    {qtProduto > 1 && produto?.produto.unidadeMedida?.dsUnidadeMedida && 's'}
                </span>
            </Typography>
        </Box>
    );
}
