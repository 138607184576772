import React, { useEffect, useRef } from 'react';

import { Typography } from '@mui/material';

import { UploadButton } from 'components/upload';

export function QuebrasImportarUploadBtn({ setFileQuebras, fileQuebras }: any): JSX.Element {
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputFileRef.current && !fileQuebras) {
            inputFileRef.current.value = '';
        }
    }, [fileQuebras]);

    return (
        <React.Fragment>
            <UploadButton
                label="Selecionar Arquivo"
                onChange={(file) => {
                    setFileQuebras(file);
                }}
                accept={['.xlsx', '.csv']}
                inputRef={inputFileRef}
            />

            {fileQuebras && (
                <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                    {fileQuebras.name}
                </Typography>
            )}

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                *São aceitos somente arquivos em formato .csv e .xlsx
            </Typography>
        </React.Fragment>
    );
}
