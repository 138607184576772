import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { UploadFileReturn, useAzureBlob } from './useAzureBlob';

import { AzureUploadFileArquivoProps, AzureUploadFileProps } from 'components/azureUpload/types';

export type UploadFile = {
    file: File | null;
    dsTitulo: string;
};

type HandleUploadFile = UploadFile & {
    multiple?: boolean;
    signal?: AbortSignal;
};

type HandleMultipleUploadFile = {
    files: UploadFile[];
    signal?: AbortSignal;
};

type AzureUploadProps = {
    isLoading: boolean;
    handleMultipleUploadFile: (values: HandleMultipleUploadFile) => Promise<AzureUploadFileProps[]>;
    handleUploadFile: (values: HandleUploadFile) => Promise<AzureUploadFileProps>;
};

const createFile = ({ value, dsTitulo }: { value: UploadFileReturn; dsTitulo: string }): AzureUploadFileProps => {
    const { file, uploadUrl } = value;
    const hash = uploadUrl.split('/').pop() || '';
    const extensao = file.name.split('.').pop() || '';

    const arquivo: AzureUploadFileArquivoProps = {
        dsExtensao: extensao,
        dsArquivo: file.name,
        dsLink: uploadUrl,
        dsLinkThumbnail: ['png', 'jpg', 'jpeg'].includes(extensao) ? uploadUrl : undefined,
        dsHash: hash,
        dsTitulo: dsTitulo,
        dsMimeType: file.type,
    };

    return {
        fgAtivo: true,
        fgCapa: false,
        fgExcluido: false,
        fgNovo: true,
        arquivo,
    };
};

export function useAzureUpload(): AzureUploadProps {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { uploadFile } = useAzureBlob();

    const handleUploadFile = ({ file, dsTitulo, multiple, signal }: HandleUploadFile): Promise<AzureUploadFileProps> => {
        if (!multiple) {
            setIsLoading(true);
        }

        if (!file) {
            return Promise.reject();
        }

        return uploadFile(file, signal)
            .then((value) => (value ? Promise.resolve(createFile({ value, dsTitulo })) : Promise.reject()))
            .catch(() => {
                if (!signal?.aborted) {
                    enqueueSnackbar('Falha ao realizar o upload do arquivo. Tente novamente', { variant: 'error' });
                }

                return Promise.reject();
            })
            .finally(() => {
                if (!multiple) {
                    setIsLoading(false);
                }
            });
    };

    const handleMultipleUploadFile = ({ files, signal }: HandleMultipleUploadFile): Promise<AzureUploadFileProps[]> => {
        setIsLoading(true);

        const promises = files.map((file) => handleUploadFile({ file: file.file, dsTitulo: file.dsTitulo, multiple: true, signal }));

        return Promise.allSettled(promises).then((values) => {
            setIsLoading(false);

            if (signal?.aborted) {
                return Promise.reject();
            }

            const fulfilledList: AzureUploadFileProps[] = [];

            values.forEach((item) => (item.status === 'fulfilled' ? fulfilledList.push(item.value) : null));

            return Promise.resolve(fulfilledList);
        });
    };

    return { handleMultipleUploadFile, handleUploadFile, isLoading };
}
