import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface SituacaoPedidoRealizadoOptionsProps {
    idPedidoStatus: number;
    dsPedidoStatus: string;
}

type SituacaoPedidoRealizadoAutoCompleteProps = Pick<
    CissAutoCompleteProps,
    'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'size' | 'requestOptions'
>;

export function SituacaoPedidoRealizadoAutoComplete({
    value,
    error,
    size,
    multiple = false,
    onChange = () => {},
    requestOptions = {
        url: '/compra/pedidostatus',
        sort: [{ property: 'dsPedidoStatus', direction: 'ASC' }],
        filter: [{ property: 'fgVisivel', operator: 'eq', value: true, logicalOperator: 'AND' }],
    },
}: SituacaoPedidoRealizadoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Situação do Pedido"
            value={value}
            multiple={multiple}
            error={error}
            size={size}
            requestOptions={requestOptions}
            selectedStringTemplate="{dsPedidoStatus}"
            optionStringTemplate="{dsPedidoStatus}"
            isOptionEqualToValue={(option: SituacaoPedidoRealizadoOptionsProps, value: SituacaoPedidoRealizadoOptionsProps) =>
                value && option && option.idPedidoStatus === value.idPedidoStatus
            }
            onChange={onChange}
        />
    );
}
