import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { TituloStatusAutoComplete } from 'pages/financeiro/titulo/components/atoms/TituloStatusAutoComplete';

export function TituloFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'eq',
        value: null,
        idProperty: 'cdLoja',
        required: 'Loja é obrigatória',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'dsStatus',
        operator: 'eq',
        value: null,
        idProperty: 'tpStatus',
        required: 'Situação é obrigatório',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dtVencimento',
        operator: 'bt',
        startDate: null,
        endDate: null,
        required: 'Intervalo de vencimento é obrigatório',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterInterval, setter: setFilterInterval },
    ];

    const resetFilter = (): void => handleFilterReset({ filters });

    const startFilter = (): void => handleFilterSubmit({ filters });

    useEffect(() => setDefaultFilters({ filters, preventQueryParamsEmptyStart: true }), []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={4}>
                <StoreAutoComplete
                    size="small"
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item lg={3}>
                <TituloStatusAutoComplete
                    label="Situacão"
                    size="small"
                    value={filterStatus.value}
                    onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item lg={5}>
                <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}
                    localeText={{ start: 'Vencimento Início', end: 'Vencimento Fim' }}
                >
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" size="small" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" size="small" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>
        </Filter>
    );
}
