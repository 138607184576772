import React, { ReactNode, useState } from 'react';

import { alpha, Box, Checkbox, Typography } from '@mui/material';
import * as dateFns from 'date-fns';

import { useCalendarEventsDisabled } from 'context/CalendarEventsDisabledContext';

interface CalendarioListEventHeaderProps {
    title?: ReactNode | string;
    titleLarge?: string;
    color: string;
    startDate?: Date;
    small?: boolean;
    category?: string | number;
}

export function CalendarioListEventHeader({ title = '', titleLarge, color, startDate, small, category }: CalendarioListEventHeaderProps): JSX.Element {
    const { isCategoryEventEnabled, setCategoryEventDisabled, removeCategoryEventDisabled } = useCalendarEventsDisabled();
    const [checked, setChecked] = useState<boolean>(category ? isCategoryEventEnabled(category) : true);

    return (
        <Box
            sx={{
                backgroundColor: small ? 'transparent' : alpha(color, 0.2),
                p: small ? 0 : 2,
                flex: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant={small ? 'body1' : 'h6'} color="common.black">
                    <strong>{title}</strong>
                </Typography>

                <Typography variant={small ? 'body1' : 'h6'} color="common.black">
                    {startDate && <strong style={{ alignSelf: 'flex-end' }}>{dateFns.format(startDate, 'dd/MM/yyyy')}</strong>}
                </Typography>

                {category && (
                    <Checkbox
                        sx={{ p: 0.5 }}
                        checked={checked}
                        onChange={(e, checked) => {
                            if (checked) {
                                removeCategoryEventDisabled(category);
                            } else {
                                setCategoryEventDisabled(category);
                            }

                            setChecked(checked);
                        }}
                    />
                )}
            </Box>

            {!small && <Typography>{titleLarge}</Typography>}
        </Box>
    );
}
