import React from 'react';

import { DepositosFormComp, DepositosListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function DepositoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_DEPOSITO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <DepositosListComp />
        </ListagemProvider>
    );
}

export function DepositosForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_DEPOSITO_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <DepositosFormComp />
        </FormProvider>
    );
}
