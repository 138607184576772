import React, { useCallback, useMemo } from 'react';

import { Box, Alert, CircularProgress } from '@mui/material';
import { FetchStatus } from '@tanstack/react-query';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList, FixedSizeGrid, ListChildComponentProps, GridChildComponentProps } from 'react-window';

import { Centered } from 'components/grid';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { useListMode } from 'hooks/useListMode';
import { PedidoCompraGridProduct, PedidoCompraListProduct } from 'pages/compra/pedido/components';
import { RequestListagemProdutoPedidos } from 'pages/compra/pedido/types';

interface PedidoCompraListContainerProps {
    data?: RequestListagemProdutoPedidos;
    fetchStatus: FetchStatus;
}

export function PedidoCompraListContainer({ data, fetchStatus }: PedidoCompraListContainerProps): JSX.Element {
    const { produtosRequest } = usePedidoCompraContext();
    const { isDownMd, isDownSm } = useBreakpoints();
    const { mode } = useListMode();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    const isGridMode = useMemo(() => mode === 'grid' || isDownMd, [isDownMd, mode]);
    const numColumns = useMemo((): number => (isDownMd ? (isDownSm ? 1 : 2) : 3), [isDownMd, isDownSm]);
    const numRows = useMemo((): number => Math.ceil(produtosRequest.length / numColumns), [numColumns, produtosRequest.length]);

    const alturaCardList = 108;
    const alturaCardGrid = isTipoPedidoFuncionario ? 416 : 476;

    // 64/162 = altura bbar
    // 16 = padding top
    // 64 = altura paginacao
    const alturaComponente = useMemo(() => window.innerHeight - (isDownMd ? 162 : 64) - 16, [isDownMd]);

    const getHeight = useMemo(() => {
        // Se não possui paginação
        if (data && data.total && data.total <= 200) {
            // Se for modo de grid e a altura das linhas dos produtos seja menor que a altura sem paginação
            if (isGridMode && numRows * alturaCardGrid < alturaComponente) {
                return numRows * alturaCardGrid;
            }

            // Se for modo de lista e a a altura das linhas dos produtos seja menor que a altura sem paginação
            else if (!isGridMode && produtosRequest.length * alturaCardList < alturaComponente) {
                return produtosRequest.length * alturaCardList;
            }
        }

        return alturaComponente;
    }, [data, alturaComponente, isGridMode, numRows, alturaCardGrid, produtosRequest.length]);

    const RowComponent = useCallback(
        ({ index, style }: ListChildComponentProps): JSX.Element | null => {
            const produtoPedido = produtosRequest.at(index);

            return produtoPedido ? <PedidoCompraListProduct key={produtoPedido?.produto.idProduto} produtoPedido={produtoPedido} sx={style} /> : null;
        },
        [produtosRequest],
    );

    const GridComponent = useCallback(
        ({ columnIndex, rowIndex, style }: GridChildComponentProps): JSX.Element | null => {
            const produtoPedido = produtosRequest.at(rowIndex * numColumns + columnIndex);

            return produtoPedido ? (
                <PedidoCompraGridProduct
                    key={produtoPedido.produto.idProduto}
                    produtoPedido={produtoPedido}
                    columnIndex={columnIndex}
                    numColumns={numColumns}
                    sx={style}
                />
            ) : null;
        },
        [numColumns, produtosRequest],
    );

    if (fetchStatus === 'fetching') {
        return (
            <Box sx={{ width: '100%', height: alturaComponente }}>
                <Centered sx={{ alignItems: 'center' }}>
                    <CircularProgress />
                </Centered>
            </Box>
        );
    }

    return produtosRequest.length ? (
        <Box sx={{ width: '100%', height: getHeight }}>
            <AutoSizer>
                {({ width, height }: Size) =>
                    isGridMode ? (
                        <FixedSizeGrid
                            columnCount={numColumns}
                            rowCount={numRows}
                            width={width}
                            height={height}
                            columnWidth={width / numColumns}
                            rowHeight={alturaCardGrid}
                            style={{ borderRadius: 4, overflowX: 'hidden' }}
                        >
                            {GridComponent}
                        </FixedSizeGrid>
                    ) : (
                        <FixedSizeList width={width} height={height} itemCount={produtosRequest.length} itemSize={108} style={{ borderRadius: 4 }}>
                            {RowComponent}
                        </FixedSizeList>
                    )
                }
            </AutoSizer>
        </Box>
    ) : (
        <Alert severity="info">Não foram localizados produtos. Tente novamente</Alert>
    );
}
