import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterOperacoes(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterIdOperacao, setFilterIdOperacao] = useState<FilterValuesProps>({
        property: 'idOperacao',
        operator: 'like',
        value: null,
        idProperty: 'idOperacao',
    });
    const [filterDsOperacao, setFilterDsOperacao] = useState<FilterValuesProps>({
        property: 'dsOperacao',
        operator: 'like',
        value: null,
        idProperty: 'dsOperacao',
    });
    const [filterCdOperacao, setFilterCdOperacao] = useState<FilterValuesProps>({
        property: 'cdOperacao',
        operator: 'like',
        value: null,
        idProperty: 'cdOperacao',
    });

    const [fgAtivo, setFgAtivo] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: true,
        idProperty: 'fgAtivo',
    });
    const filters = [
        { value: filterIdOperacao, setter: setFilterIdOperacao },
        { value: filterDsOperacao, setter: setFilterDsOperacao },
        { value: filterCdOperacao, setter: setFilterCdOperacao },
        { value: fgAtivo, setter: setFgAtivo },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <CissTextField
                    label="Código"
                    value={filterIdOperacao.value}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => setFilterIdOperacao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField label="Nome da Operação" value={filterDsOperacao.value} onChange={(e) => setFilterDsOperacao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={4}>
                <CissTextField label="Código de Integração" value={filterCdOperacao.value} onChange={(e) => setFilterCdOperacao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>
        </Filter>
    );
}
