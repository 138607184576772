import React, { useEffect, useState, useMemo } from 'react';

import { Grid } from '@mui/material';

import { GrupoAutoComplete, ProdutoAutoComplete, SecaoAutoComplete, SubgrupoAutoComplete } from 'components/autocompletes';
import { ProdutoProps } from 'components/autocompletes/ProdutoAutoComplete';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export interface SecaoProps {
    idSecao: number;
    cdSecao: number;
    dsSecao: string;
}

export interface GrupoProps {
    idGrupo: number;
    dsGrupo: string;
    dsSecao: string;
}

export function TabelaPrecoListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterSegmento, setFilterSegmento] = useState<FilterValuesProps>({
        property: 'dsSecao',
        operator: 'in',
        value: [],
        idProperty: 'dsSecao',
    });

    const [filterJornada, setFilterJornada] = useState<FilterValuesProps>({
        property: 'dsGrupo',
        operator: 'in',
        value: [],
        idProperty: 'dsGrupo',
    });

    const [filterCategoria, setFilterCategoria] = useState<FilterValuesProps>({
        property: 'dsSubgrupo',
        operator: 'in',
        value: [],
        idProperty: 'dsSubgrupo',
    });

    const [filterProduto, setFilterProduto] = useState<FilterValuesProps>({
        property: 'idProduto',
        operator: 'in',
        value: [],
        idProperty: 'idProduto',
    });

    const filters = [
        { value: filterSegmento, setter: setFilterSegmento },
        { value: filterCategoria, setter: setFilterCategoria },
        { value: filterJornada, setter: setFilterJornada },
        { value: filterProduto, setter: setFilterProduto },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    const getFiltersCategoria = useMemo((): FilterValuesProps[] | undefined => {
        if (filterJornada.value.length > 0) {
            return [{ property: 'idGrupo', value: filterJornada.value.map((grupo: GrupoProps) => grupo.idGrupo), operator: 'in' }];
        } else if (filterSegmento.value.length > 0) {
            return [{ property: 'idSecao', value: filterSegmento.value.map((segmento: SecaoProps) => segmento.idSecao), operator: 'in' }];
        } else {
            return undefined;
        }
    }, [filterJornada.value, filterSegmento.value]);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <SecaoAutoComplete
                    multiple={true}
                    label="Segmento"
                    value={filterSegmento.value}
                    onChange={(e, value: SecaoProps[]) => {
                        if ((value.length < filterSegmento.value.length && value.length !== 0) || filterSegmento.value.length === 0) {
                            setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                            setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                        }

                        setFilterSegmento((oldState: FilterValuesProps) => ({ ...oldState, value: value.map((item) => ({ idSecao: item.idSecao, dsSecao: item.dsSecao })) }));
                    }}
                />
            </Grid>

            <Grid item flex={1}>
                <GrupoAutoComplete
                    multiple={true}
                    label="Jornada"
                    value={filterJornada.value}
                    onChange={(e, value) => {
                        if ((value.length < filterJornada.value.length && value.length !== 0) || filterJornada.value.length === 0) {
                            setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                        }

                        setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value }));
                    }}
                    requestOptions={{
                        url: '/gestao/grupo',
                        sort: [{ property: 'dsGrupo', direction: 'ASC' }],
                        filter: filterSegmento.value.length > 0 ? [{ property: 'idSecao', value: filterSegmento.value.map((segmento: SecaoProps) => segmento.idSecao), operator: 'in' }] : undefined,
                        columns: 'idGrupo,dsGrupo,dsSecao',
                        limit: 100,
                    }}
                />
            </Grid>

            <Grid item flex={1}>
                <SubgrupoAutoComplete
                    multiple={true}
                    label="Categoria"
                    value={filterCategoria.value}
                    onChange={(e, value) => setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: '/gestao/subgrupo',
                        sort: [{ property: 'dsSubgrupo', direction: 'ASC' }],
                        filter: getFiltersCategoria,
                        columns: 'idSubgrupo,dsSubgrupo,dsGrupo,dsSecao',
                        limit: 100,
                    }}
                />
            </Grid>

            <Grid item flex={1}>
                <ProdutoAutoComplete
                    multiple={true}
                    value={filterProduto.value}
                    onChange={(e, value: ProdutoProps[]) =>
                        setFilterProduto((oldState: FilterValuesProps) => ({
                            ...oldState,
                            value: value.map((item) => ({ idProduto: item.idProduto, dsProduto: item.dsProduto, cdProduto: item.cdProduto })),
                        }))
                    }
                />
            </Grid>
        </Filter>
    );
}
