import React, { Dispatch, SetStateAction } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { useListagemContext } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

type TImportLocalFuncionario = {
    setOpen: Dispatch<SetStateAction<boolean>>;
};

type TReturnImportLocalFuncionario = {
    handleSubmit: (fileImportacao: File) => void;
    isLoading: boolean;
};

export function useImportLocalFuncionario({ setOpen }: TImportLocalFuncionario): TReturnImportLocalFuncionario {
    const { autoLoad, setAutoLoad } = useListagemContext();
    const { showError } = useShowError();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: () => {
            if (!autoLoad) {
                setAutoLoad(true);
            }

            queryClient.invalidateQueries(['getLocalFuncionario']);

            enqueueSnackbar('Arquivo importado com sucesso!', { variant: 'success' });

            setOpen(false);
        },
        onError: (error: any) => showError(error, 'Erro ao salvar arquivo'),
    });

    const handleSubmit = (fileImportacao: File): void => {
        const formData = new FormData();

        formData.append('file', fileImportacao);

        mutate({
            url: '/gestao/localfuncionario/upload',
            formData,
        });
    };

    return { handleSubmit, isLoading };
}
