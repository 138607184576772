import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

export function ProdutoRelatorioBtn(): JSX.Element {
    const { content } = useFormContext();
    const { filter } = useListagemContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/compra/produto/estoque/exportar',
            sort: [{ property: 'dsProduto', direction: 'ASC' }],
            filter,
        }),
        [filter],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({ requestOptions });

    const handleClickDownload = useCallback((): void => {
        downloadFile({
            newRequestOptions: requestOptions,
            directDownload: true,
        });
    }, [downloadFile, requestOptions]);

    return (
        <LoadingButton
            loading={isLoadingFile}
            variant="outlined"
            onClick={handleClickDownload}
            disabled={!content || isLoadingFile}
            sx={{ color: 'green', borderColor: 'green', flex: { xs: 'auto', md: 'none' } }}
            startIcon={<RiFileExcel2Fill />}
        >
            Relatório em .xlsx
        </LoadingButton>
    );
}
