import React from 'react';
import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface DeviceProps {
    idTipoDispositivo: number;
    dsTipoDispositivo: string;
}

type DeviceAutoComplete = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function DeviceAutoComplete({ value, error, label = 'Dispositivo', multiple = false, sx, onChange }: DeviceAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idTipoDispositivo} - {dsTipoDispositivo}"
            optionStringTemplate="<strong>{idTipoDispositivo}</strong> - {dsTipoDispositivo}"
            requestOptions={{
                url: '/gestao/tipodispositivo',
                sort: [{ property: 'dsTipoDispositivo', direction: 'ASC' }],
                columns: 'idTipoDispositivo,dsTipoDispositivo',
                limit: 50,
            }}
            isOptionEqualToValue={(option: DeviceProps, value: DeviceProps) => option.idTipoDispositivo === value.idTipoDispositivo}
            onChange={onChange}
            sx={sx}
        />
    );
}
