import React from 'react';

import { Box } from '@mui/material';

import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { DetalhamentoCreditosImportacaoUploadBtn, DetalhamentoCreditosImportacaoModeloBtn } from 'pages/financeiro/detalhamento-creditos/importar/components';

export function DetalhamentoCreditosImportacaoItem(): JSX.Element {
    return (
        <Block>
            <SubTitle label="Importar Arquivo - Detalhamento por Item" />

            <Box sx={{ mb: 2 }}>
                <DetalhamentoCreditosImportacaoModeloBtn dsChave="URL_ARQUIVO_MODELO_DETALHAMENTO_CREDITO" />
            </Box>

            <DetalhamentoCreditosImportacaoUploadBtn url="/financeiro/detalhamentocreditodebitoimportacao/upload" />
        </Block>
    );
}
