import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useDebounce } from 'use-debounce';

import { Box } from '@mui/material';

import { useRequestListagem, RequestListagemProps, RequestOptionsType } from 'hooks/useRequestListagem';
import { CissTextField } from 'components/fields/';

interface StateOptionsProps {
    idEstado: number;
    dsEstado: string;
    sgEstado: string;
}

interface RequestListagemType extends RequestListagemProps {
    data: StateOptionsProps[];
}

interface StateFieldProps extends AutocompleteProps<StateOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
}

export function StateFieldFormik({ onChange, multiple = true, label = 'Estados', ...PropsFormik }: StateFieldProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [query, setQuery] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [queryValue] = useDebounce(query, 500);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/estado',
        query: queryValue,
        sort: [{ property: 'sgEstado', direction: 'ASC' }],
        columns: 'idEstado,dsEstado,sgEstado',
        limit: 50,
    };

    const { isLoading, fetchStatus, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestListagemType> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            keepPreviousData: false,
            enabled: open,
            onError: () => {
                enqueueSnackbar('Falha ao processar solicitação dos Estados', { variant: 'error' });
            },
        },
    );

    useEffect(() => {
        if (open) {
            setQuery(inputValue);
        }
    }, [inputValue, open]);

    return (
        <Autocomplete
            {...PropsFormik}
            multiple={multiple}
            onChange={onChange}
            loading={isLoading && fetchStatus === 'fetching'}
            options={data?.data ?? []}
            isOptionEqualToValue={(option, value) => option.idEstado === value.idEstado}
            getOptionLabel={(option: StateOptionsProps | string) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.dsEstado;
            }}
            renderInput={(params) => <CissTextField {...params} label={label} />}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.idEstado}>
                    {option.sgEstado} - {option.dsEstado}
                </Box>
            )}
        />
    );
}
