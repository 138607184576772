import React from 'react';

import { OneColumn } from 'components/page';
import { MensagemPadraoGrid, MensagemPadraoGridFilter } from 'pages/marketing-trade/trade/solicitacoes-pecas/mensagem-padrao/components';

export function MensagemPadraoListComp(): JSX.Element {
    
    return (
        <OneColumn title="Mensagem Padrão" createButton goBackButton>
            <MensagemPadraoGridFilter />

            <MensagemPadraoGrid />
        </OneColumn>
    );
}
