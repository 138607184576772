import React, { useState } from 'react';

import { IconButton, Paper, Stack, Typography, Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';

import { ConfirmModal } from 'components/modal';
import { UploadFileProps, UploadPreviewProps } from '../types';

/**
 * Componente de Upload de Imagens,
 */
export function UploadPreview({ listFiles, setListFiles, removeFile, updateCover, disabled }: UploadPreviewProps): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleConfirmExcluir = (): void => {
        setOpenConfirmModal(true);
    };

    const handleUpdateCover = (file: UploadFileProps): void => {
        if (!file.fgCapa && typeof updateCover === 'function') {
            updateCover(file.arquivo.dsHash);
        }
    };

    const callbackExcluir = (file: UploadFileProps): void => {
        if (typeof removeFile === 'function') {
            removeFile(file.arquivo.dsHash);
        } else if (typeof setListFiles === 'function') {
            setListFiles(
                listFiles.map((item) => {
                    if (item.arquivo.dsHash === file.arquivo.dsHash) {
                        item.fgAtivo = false;
                        item.fgExcluido = true;
                    }

                    return item;
                }),
            );
        }
    };

    return (
        <React.Fragment>
            {Boolean(listFiles.length) && (
                <Stack spacing={2} sx={{ mt: 2 }}>
                    {listFiles.map((file) => (
                        <React.Fragment key={`${file.arquivo.idArquivo ?? Math.random()}_${file.arquivo.dsHash || file.arquivo.dsLink}`}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    p: 1.5,
                                }}
                            >
                                {file.arquivo.dsLinkThumbnail && (
                                    <Box
                                        sx={{
                                            minWidth: 80,
                                            height: 60,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundImage: `url("${file.arquivo.dsLinkThumbnail}")`,
                                            borderRadius: 1,
                                            mr: 2,
                                        }}
                                    />
                                )}

                                <Box
                                    sx={{
                                        flex: 1,
                                        mr: 2,
                                    }}
                                >
                                    <Typography variant="h6" sx={{ color: 'common.black' }}>
                                        {file.arquivo.dsTitulo}
                                    </Typography>

                                    <Typography>{file.arquivo.dsArquivo}</Typography>
                                </Box>

                                {updateCover && (
                                    <IconButton sx={{ width: 60 }} onClick={() => handleUpdateCover(file)} color="primary">
                                        {file.fgCapa && <RadioButtonCheckedOutlinedIcon />}
                                        {!file.fgCapa && <RadioButtonUncheckedOutlinedIcon />}
                                    </IconButton>
                                )}

                                {(removeFile || setListFiles) && (
                                    <IconButton sx={{ width: 60 }} onClick={handleConfirmExcluir} color="primary" disabled={Boolean(disabled)}>
                                        <DeleteOutlineIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </Paper>

                            {(removeFile || setListFiles) && (
                                <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={() => callbackExcluir(file)} text="Tem certeza que deseja excluir o arquivo?" />
                            )}
                        </React.Fragment>
                    ))}
                </Stack>
            )}
        </React.Fragment>
    );
}
