import { useMediaQuery, useTheme } from '@mui/material';

interface useBreakpoints {
    isDownXl: boolean;
    isDownLg: boolean;
    isDownMd: boolean;
    isDownSm: boolean;
    isDownXs: boolean;
    isUpXl: boolean;
    isUpLg: boolean;
    isUpMd: boolean;
    isUpSm: boolean;
    isUpXs: boolean;
}

export function useBreakpoints(): useBreakpoints {
    const { breakpoints } = useTheme();
    const { down, up } = breakpoints;

    return {
        isDownXl: useMediaQuery(down('xl')),
        isDownLg: useMediaQuery(down('lg')),
        isDownMd: useMediaQuery(down('md')),
        isDownSm: useMediaQuery(down('sm')),
        isDownXs: useMediaQuery(down('xs')),
        isUpXl: useMediaQuery(up('xl')),
        isUpLg: useMediaQuery(up('lg')),
        isUpMd: useMediaQuery(up('md')),
        isUpSm: useMediaQuery(up('sm')),
        isUpXs: useMediaQuery(up('xs')),
    };
}
