import React, { forwardRef, useEffect, useState, useMemo } from 'react';

import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { SituacaoConfiguracaoPedidoAutoComplete, ProdutoAutoComplete, GradeAutoComplete, TipoPedidoAutoComplete } from 'components/autocompletes';
import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { ProdutoProps } from 'components/autocompletes/ProdutoAutoComplete';
import { GradeOptionsProps } from 'components/autocompletes/GradeAutoComplete';

export const ConfiguracaoPedidoCompraListFilter = forwardRef((props, ref) => {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const getInicioVigencia = useMemo((): Date => new Date(new Date().getFullYear(), 0, 1), []);
    const getFimVigencia = useMemo((): Date => new Date(2099, 11, 31), []);

    const [filterProduto, setFilterProduto] = useState<FilterValuesProps>({
        property: 'idProduto',
        operator: 'in',
        value: [],
        idProperty: 'idProduto',
    });

    const [filterGrade, setFilterGrade] = useState<FilterValuesProps>({
        property: 'idTipoGrade',
        operator: 'in',
        value: [],
        idProperty: 'idTipoGrade',
    });

    const [filterSitucao, setFilterSitucao] = useState<FilterValuesProps>({
        property: 'idConfigPedidoStatus',
        operator: 'in',
        value: [],
        idProperty: 'idConfigPedidoStatus',
    });

    const [filterDsConfiguracao, setFilterDsConfiguracao] = useState<FilterValuesProps>({
        property: 'dsConfigPedido',
        operator: 'like',
        value: '',
        logicalOperator: 'OR',
    });

    const [filterIdConfiguracao, setFilterIdConfiguracao] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        operator: 'eq',
        value: '',
        logicalOperator: 'OR',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'vlStatus',
    });

    const [filterInicioVigencia, setFilterInicioVigencia] = useState<FilterValuesProps>({
        property: 'dtInicioVigencia',
        operator: 'gte',
        value: getInicioVigencia,
        required: 'Início de Vigência é obrigatório',
    });

    const [filterFimVigencia, setFilterFimVigencia] = useState<FilterValuesProps>({
        property: 'dtFimVigencia',
        operator: 'lte',
        value: getFimVigencia,
        required: 'Fim de Vigência é obrigatório',
    });

    const [filterTipoPedido, setFilterTipoPedido] = useState<FilterValuesProps>({
        property: 'idTipoPedido',
        operator: 'in',
        value: [],
        idProperty: 'idTipoPedido',
    });

    const filters = [
        { value: filterDsConfiguracao, setter: setFilterDsConfiguracao },
        { value: filterIdConfiguracao, setter: setFilterIdConfiguracao },
        { value: filterProduto, setter: setFilterProduto },
        { value: filterGrade, setter: setFilterGrade },
        { value: filterTipoPedido, setter: setFilterTipoPedido },
        { value: filterSitucao, setter: setFilterSitucao },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterInicioVigencia, setter: setFilterInicioVigencia },
        { value: filterFimVigencia, setter: setFilterFimVigencia },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={3}>
                <CissTextField
                    label="Configuração"
                    placeholder="Nome ou código da configuração..."
                    value={filterDsConfiguracao.value}
                    onChange={(e) => {
                        setFilterDsConfiguracao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }));
                        setFilterIdConfiguracao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }));
                    }}
                />
            </Grid>

            <Grid item lg={3}>
                <ProdutoAutoComplete
                    label="Produtos"
                    value={filterProduto.value}
                    onChange={(e, value: ProdutoProps[]) =>
                        setFilterProduto((oldState: FilterValuesProps) => ({
                            ...oldState,
                            value: value.map((item) => ({ idProduto: item.idProduto, dsProduto: item.dsProduto, cdProduto: item.cdProduto })),
                        }))
                    }
                />
            </Grid>

            <Grid item lg={3}>
                <GradeAutoComplete
                    label="Grade"
                    multiple={true}
                    value={filterGrade.value}
                    onChange={(e, value: GradeOptionsProps[]) =>
                        setFilterGrade((oldState: FilterValuesProps) => ({ ...oldState, value: value.map((item) => ({ idTipoGrade: item.idTipoGrade, dsTipoGrade: item.dsTipoGrade })) }))
                    }
                />
            </Grid>

            <Grid item lg={3}>
                <TipoPedidoAutoComplete multiple={true} value={filterTipoPedido.value} onChange={(e, value) => setFilterTipoPedido((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item lg={3}>
                <SituacaoConfiguracaoPedidoAutoComplete
                    multiple={true}
                    value={filterSitucao.value}
                    onChange={(e, value) => setFilterSitucao((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item lg={3}>
                <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item lg={3}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Início de Vigência"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <CissTextField {...params} />}
                        value={filterInicioVigencia.value}
                        onChange={(value) => setFilterInicioVigencia((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item lg={3}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Fim de Vigência"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => <CissTextField {...params} />}
                        value={filterFimVigencia.value}
                        onChange={(value) => setFilterFimVigencia((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    />
                </LocalizationProvider>
            </Grid>
        </Filter>
    );
});
