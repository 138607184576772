import React, { useCallback, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { UploadButton } from 'components/upload';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { useShowError } from 'hooks/useShowError';

interface DetalhamentoCreditosImportacaoUploadBtnProps {
    url: string;
}

export function DetalhamentoCreditosImportacaoUploadBtn({ url }: DetalhamentoCreditosImportacaoUploadBtnProps): JSX.Element {
    const [file, setFile] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const queryClient = useQueryClient();
    const inputFileRef = useRef<HTMLInputElement>(null);

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: () => {
            enqueueSnackbar('Arquivo de importação enviado com sucesso.', { variant: 'success' });

            // refaço a request da grid
            queryClient.refetchQueries(['importacao-file-list'], { type: 'active' });

            // rolamos a tela até a grid
            document.querySelector('#files-import-grid')?.scrollIntoView({
                behavior: 'smooth',
            });

            // reset inputFile
            if (inputFileRef.current) {
                inputFileRef.current.value = '';
                setFile(null);
            }
        },
        onError: (error) => showError(error, 'Erro ao enviar arquivo.'),
    });

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (file) {
            formData.append('file', file);
            mutate({ formData, url });
        }
    }, [file, mutate, url]);

    const handlerFileChange = (selectedFile: File): void => {
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    return (
        <React.Fragment>
            <UploadButton label="Selecionar Arquivo" disabled={isLoading} onChange={(file) => file && handlerFileChange(file)} accept=".csv" inputRef={inputFileRef} />

            <LoadingButton variant="contained" loading={isLoading} disabled={!file} onClick={handleSubmit} sx={{ ml: 2, maxWidth: 150 }}>
                Enviar
            </LoadingButton>

            {file && (
                <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                    {file.name}
                </Typography>
            )}

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                *São aceitos somente arquivos em formato .csv
            </Typography>
        </React.Fragment>
    );
}
