import React from 'react';

import { Autocomplete, Box } from '@mui/material';

import { CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';
import { CissTextField } from 'components/fields/';

interface StatusOptionsProps {
    idStatus: number;
    dsStatus: string;
    vlStatus: boolean | null;
}

type StatusFieldProps = Pick<
    CissAutoCompleteProps,
    'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'size' | 'requestOptions' | 'label'
> & {
    value: StatusOptionsProps | boolean | null;
    both?: boolean;
};

const activeStatus: StatusOptionsProps = {
    idStatus: 1,
    dsStatus: 'Ativos',
    vlStatus: true,
};

const inativeStatus: StatusOptionsProps = {
    idStatus: 2,
    dsStatus: 'Inativos',
    vlStatus: false,
};

const bothStatus: StatusOptionsProps = {
    idStatus: 3,
    dsStatus: 'Ambos',
    vlStatus: null,
};

const data: StatusOptionsProps[] = [activeStatus, inativeStatus];
const dataWithBoth: StatusOptionsProps[] = [activeStatus, inativeStatus, bothStatus];

const findOptionByValue = (value: StatusOptionsProps | boolean | null): StatusOptionsProps => {
    switch (value) {
        case null:
            return bothStatus;
        case true:
            return activeStatus;
        case false:
            return inativeStatus;
        default:
            return value;
    }
};

export function StatusField({ onChange, size, value, label = 'Status', both = true }: StatusFieldProps): JSX.Element | null {
    const selectedValue = findOptionByValue(value);
    const options = both ? dataWithBoth : data;

    return (
        <Autocomplete
            value={selectedValue}
            onChange={onChange}
            options={options}
            size={size}
            disableClearable={!both}
            isOptionEqualToValue={(option, fieldValue) => option.idStatus === fieldValue.idStatus}
            getOptionLabel={(option: StatusOptionsProps) => option.dsStatus}
            renderInput={(params) => <CissTextField label={label} {...params} />}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.idStatus}>
                    {option.dsStatus}
                </Box>
            )}
        />
    );
}
