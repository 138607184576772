import React from 'react';

import { Chip } from '@mui/material';

interface ConfiguracaoPedidoCompraSituacaoColumnProps {
    idStatus: number;
}

export function ConfiguracaoPedidoCompraSituacaoColumn({ idStatus }: ConfiguracaoPedidoCompraSituacaoColumnProps): JSX.Element {
    let color: 'error' | 'success';
    let title;

    switch (idStatus) {
        // 2  = Completo
        case 2:
            color = 'success';
            title = 'Completo';
            break;
        // 1 = Completo
        case 1:
        default:
            color = 'error';
            title = 'Incompleto';
    }

    return <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} />;
}
