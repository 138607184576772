import React, { useEffect, useState } from 'react';

import { FormControlLabel, Switch } from '@mui/material';
import { useFormikContext } from 'formik';

export function ToggleFgSomenteLojasRevendedor(): JSX.Element {
    const { values, setFieldValue } = useFormikContext();
    const { fgSomenteLojasRevendedor } = values as { fgSomenteLojasRevendedor: boolean };
    const [switchChecked, setSwitchChecked] = useState<boolean>(true);

    const handleChangeFgSomenteLojasRevendedor = (status: boolean): void => {
        setSwitchChecked(status);
        setFieldValue('fgSomenteLojasRevendedor', status);
    };

    useEffect(() => {
        if (fgSomenteLojasRevendedor !== switchChecked) {
            handleChangeFgSomenteLojasRevendedor(Boolean(fgSomenteLojasRevendedor));
        }
    }, [fgSomenteLojasRevendedor]);

    return (
        <FormControlLabel
            control={<Switch checked={switchChecked} onChange={(e, checked) => handleChangeFgSomenteLojasRevendedor(checked)} />}
            label="Listar somente para lojas com revendedores"
        />
    );
}
