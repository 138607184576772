import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Box, Typography } from '@mui/material';

import { Block } from 'components/page';
import { useTituloContext } from 'context/TituloContext';
import { TituloExportarRelatorioBtn } from 'pages/financeiro/titulo/components/atoms/TituloExportarRelatorioBtn';

export function TituloRelatorio(): JSX.Element {
    const { hasTitulos } = useTituloContext();

    return (
        <Block>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, alignItems: 'center' }}>
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TableViewOutlinedIcon color="primary" />

                    <Typography>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                    <ChevronRightOutlinedIcon color="primary" />
                </Box>

                <TituloExportarRelatorioBtn
                    disabled={!hasTitulos}
                    label={'Relatório em .xlsx'}
                    sx={{ color: 'green', borderColor: 'green', width: { xs: '100%', sm: 'auto' } }}
                />
            </Box>
        </Block>
    );
}
