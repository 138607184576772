import React from 'react';

import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { ConfiguracaoGeralFormComp } from 'pages/revendedor/configuracao-geral/components';

export function ConfiguracaoGeralForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['REVENDEDOR_CADASTRO_CONFIGURACOES'])) return alertNotPermission();

    return (
        <FormProvider>
            <ConfiguracaoGeralFormComp />
        </FormProvider>
    );
}
