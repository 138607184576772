import { FilterValuesProps } from './useFilter';

import { useServiceApiContext } from 'context/ServiceApiContext';

export interface RequestListagemProps {
    hasNext: boolean;
    total: number;
}

export interface SortProps {
    property: string;
    direction: 'ASC' | 'DESC';
}

export interface ExtraParamsProps {
    [key: string]: boolean | number | string | undefined;
}

export interface RequestOptionsType {
    url: string;
    sort?: SortProps[];
    filter?: FilterValuesProps[];
    columns?: string;
    query?: string;
    page?: number;
    limit?: number;
    nested?: boolean;
    requestConfig?: any; // TODO analisar para aplicar tipagem correta de configs (AxiosRequestConfig)
    extraParams?: ExtraParamsProps[];
}

export interface RequestParamsProps {
    page?: number;
    limit?: number;
    filter?: string;
    sort?: string;
    columns?: string;
    query?: string;
    nested?: boolean;
    requestConfig?: any; // TODO analisar para aplicar tipagem correta de configs (AxiosRequestConfig)
}

/**
 * Request de get genérica
 */
export const useRequestListagem = (): ((params: RequestOptionsType) => Promise<any>) => {
    const Api = useServiceApiContext();

    const RequestListagem = ({ url, filter, sort, columns, page, limit, query, nested, extraParams, requestConfig = {} }: RequestOptionsType): Promise<any> => {
        const params: RequestParamsProps = {};

        if (typeof page === 'number') {
            params.page = page;
        }

        if (typeof limit === 'number') {
            params.limit = limit;
        }

        if (Array.isArray(filter) && filter.length) {
            params.filter = JSON.stringify(filter);
        }

        if (Array.isArray(sort) && sort.length) {
            params.sort = JSON.stringify(sort);
        }

        if (typeof columns === 'string' && columns.length) {
            params.columns = columns;
        }

        if (typeof query === 'string' && query.length) {
            params.query = query;
        }

        if (typeof nested === 'boolean') {
            params.nested = nested;
        }

        if (Array.isArray(extraParams) && extraParams.length) {
            extraParams.forEach((param) => {
                const [key, value] = Object.entries(param).flat();

                params[key as keyof typeof params] = value;
            });
        }

        return Api.get(url, { params, ...requestConfig });
    };

    return RequestListagem;
};
