import React, { useMemo } from 'react';

import { Typography } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { HeaderProdutosTooltip, HeaderSituacaoTooltip, SugestaoCompraSituacaoColumn } from 'pages/compra/sugestao/components';
import { RequestGerenciarProdutosList, SugestaoCompraProps } from 'pages/compra/sugestao/types';
import { brlDateTime } from 'util/format';

const getRows = (data: SugestaoCompraProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idConfigPedido: item.sugestaoCompra.configPedido.idConfigPedido,
            dsConfigPedido: item.sugestaoCompra.configPedido.dsConfigPedido,
            idLoja: item.sugestaoCompra.loja.idLoja,
            cdLoja: item.sugestaoCompra.loja.cdLoja,
            dsNomeFantasia: item.sugestaoCompra.loja.dsNomeFantasia,
            qtProdutosSugestao: item.qtProdutosSugestao,
            qtProdutosConfig: item.qtProdutosConfig,
            sugestaoCompraStatus: item.sugestaoCompra.sugestaoCompraStatus,
            dsValidacao: item.sugestaoCompra.dsValidacao,
            dhAlteracao: item.dhAlteracao && brlDateTime(`${item.dhAlteracao}`),
        }));
    }

    return [];
};

export function SugestaoCompraGrid(): JSX.Element {
    const { filter, limit, page, autoLoad, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/sugestaocompraitem',
            sort: sort?.length ? sort : [{ property: 'dhAlteracao', direction: 'DESC' }],
            nested: true,
            filter,
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(['getListSugestoes', requestOptions], (): Promise<RequestGerenciarProdutosList> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
    });

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'dhAlteracao',
                headerName: 'Data da Importação',
                type: 'string',
                align: 'center',
                headerAlign: 'center',
                minWidth: 130,
            },
            {
                field: 'dsConfigPedido',
                align: 'left',
                headerAlign: 'left',
                headerName: 'Configuração',
                hideable: false,
                disableColumnMenu: true,
                minWidth: 140,
                flex: 1,
                renderCell: ({ row }) => (
                    <Typography>
                        <strong>{row.idConfigPedido}</strong> - {row.dsConfigPedido}
                    </Typography>
                ),
            },
            {
                field: 'dsNomeFantasia',
                align: 'left',
                headerAlign: 'left',
                headerName: 'Loja',
                hideable: false,
                disableColumnMenu: true,
                minWidth: 140,
                flex: 1,
                renderCell: ({ row }) => {
                    if (row.sugestaoCompraStatus !== 'NAO_IMPORTADA') {
                        return (
                            <Typography>
                                <strong>{row.cdLoja}</strong> - {row.dsNomeFantasia}
                            </Typography>
                        );
                    }
                },
            },
            {
                field: 'produtos',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Produtos',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 100,
                renderHeader: () => <HeaderProdutosTooltip />,
                renderCell: ({ row }) => {
                    if (row.sugestaoCompraStatus !== 'NAO_IMPORTADA') {
                        return (
                            <Typography
                                color={() => {
                                    const { qtProdutosSugestao, qtProdutosConfig } = row;
                                    if (qtProdutosSugestao === qtProdutosConfig) {
                                        return '#4caf50';
                                    } else if (qtProdutosSugestao > 0) {
                                        return '#ff9800';
                                    } else {
                                        return '#ef5350';
                                    }
                                }}
                            >
                                <strong>
                                    {row.qtProdutosSugestao}/{row.qtProdutosConfig}
                                </strong>
                            </Typography>
                        );
                    }
                },
            },
            {
                field: 'sugestaoCompraStatus',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Situacao',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 150,
                renderHeader: () => <HeaderSituacaoTooltip />,
                renderCell: ({ row }) => <SugestaoCompraSituacaoColumn sugestaoCompraStatus={row.sugestaoCompraStatus} dsValidacao={row.dsValidacao} />,
            },
            {
                field: 'excluir',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Excluir',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => {
                    if (!['NAO_IMPORTADA', 'IMPORTANDO'].includes(row.sugestaoCompraStatus)) {
                        return (
                            <EditRemoveBtn
                                id={row.idConfigPedido}
                                refresh={refetch}
                                showEditBtn={false}
                                removeUrl={`/gestao/sugestaocompra/deletebyconfigpedidoandloja?idConfigPedido=${row.idConfigPedido}&idLoja=${row.idLoja}`}
                            />
                        );
                    }
                },
            },
        ],
        [],
    );

    return (
        <Block>
            <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
        </Block>
    );
}
