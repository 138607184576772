import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

type TipoPessoa = {
    tpPessoa: string;
    dsTpPessoa: string;
};

const pessoaFisica: TipoPessoa = {
    tpPessoa: 'PF',
    dsTpPessoa: 'Pessoa física',
};

const pessoaJuridica: TipoPessoa = {
    tpPessoa: 'PJ',
    dsTpPessoa: 'Pessoa jurídica',
};

const TipoPessoaOptions: TipoPessoa[] = [pessoaFisica, pessoaJuridica];

const getSelection = (value: string): TipoPessoa | null => {
    switch (value) {
        case 'PF':
            return pessoaFisica;
        case 'PJ':
            return pessoaJuridica;
        default:
            return null;
    }
};

type TipoPessoaAutoComplete = Pick<CissAutoCompleteProps, 'size' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label' | 'name'> & {
    value: string;
};

export function TipoPessoaAutoComplete({
    sx,
    size,
    value,
    error,
    name,
    multiple = false,
    disabled = false,
    onChange = () => {},
}: TipoPessoaAutoComplete): JSX.Element {
    const selection = getSelection(value);

    return (
        <CissAutoComplete
            disableClearable
            sx={sx}
            size={size}
            name={name}
            value={selection}
            error={error}
            onChange={onChange}
            disabled={disabled}
            multiple={multiple}
            options={TipoPessoaOptions}
            label="Tipo de pessoa"
            optionStringTemplate="{dsTpPessoa}"
            selectedStringTemplate="{dsTpPessoa}"
            isOptionEqualToValue={(option: TipoPessoa, value: TipoPessoa) => option.tpPessoa === value.tpPessoa}
        />
    );
}
