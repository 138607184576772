import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { LoadingButton } from '@mui/lab';
import { SwipeableDrawer, Fab, Button, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { Formik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { Row } from 'components/form';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useFieldKeyPress } from 'hooks/useFieldKeyPress';

export interface FilterProps {
    children?: ReactNode;
    showTitle?: boolean;
    closeOnSubmit?: boolean;
    fixed?: boolean;
    preventOnEnter?: boolean;
    loadingSubmit?: boolean;
    reset: () => void;
    submit: () => void;
}

const Title = (): JSX.Element => <SubTitle label="Filtros" />;

const FilterChildren = ({ children, reset, submit, preventOnEnter, loadingSubmit }: FilterProps): JSX.Element => {
    const pressEnterInsideField = useFieldKeyPress('Enter');

    const debouncedSubmit = useDebouncedCallback(() => {
        submit();
    }, 250);

    if (pressEnterInsideField && !preventOnEnter) {
        debouncedSubmit();
    }

    return (
        <React.Fragment>
            <Row
                sx={{
                    '& .MuiGrid-item:empty': {
                        display: 'none',
                    },
                }}
            >
                {children}
            </Row>

            <Box sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'flex-end' }}>
                <Button type="reset" sx={{ mr: 2 }} onClick={reset}>
                    Redefinir
                </Button>

                <LoadingButton type="submit" variant="contained" onClick={submit} loading={loadingSubmit}>
                    Pesquisar
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export function Filter({ children, reset, submit, fixed = true, showTitle = true, closeOnSubmit = true, preventOnEnter = false, loadingSubmit = false }: FilterProps): JSX.Element {
    const { isDownLg: isMobile } = useBreakpoints();
    const style = fixed ? { position: 'sticky', top: 0, zIndex: 3 } : {};

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [heightFilter, setHeightFilter] = useState<number>(0);

    // Define a partir de quantos pixels que o filtro será montado.
    // Caso esteja mostrando parte ou todo o header, scrolla ao topo e define o espaço como 60.
    // Caso não esteja mostrando, define o espaço como 0.
    useEffect(() => {
        if (openDrawer && isMobile) {
            const header = document.getElementsByTagName('header')[0];
            const { bottom } = header.getBoundingClientRect();

            let value = 0;

            if (heightFilter !== bottom) {
                if (bottom <= 60 && bottom >= 0) {
                    value = 60;

                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }

                setHeightFilter(value);
            }
        }
    }, [heightFilter, isMobile, openDrawer]);

    const onSubmit = useCallback(() => {
        submit();

        if (isMobile && closeOnSubmit) {
            setOpenDrawer(false);
        }
    }, [closeOnSubmit, isMobile, submit]);

    return (
        <Formik initialValues={[]} onSubmit={() => {}}>
            <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                {!isMobile && (
                    <Block sx={style} id="filter-bar">
                        {showTitle && <Title />}

                        <FilterChildren reset={reset} submit={onSubmit} preventOnEnter={preventOnEnter} loadingSubmit={loadingSubmit}>
                            {children}
                        </FilterChildren>
                    </Block>
                )}

                {isMobile && (
                    <React.Fragment>
                        <Fab color="primary" variant="extended" onClick={() => setOpenDrawer(true)} sx={{ ...style, top: 16, gap: 1 }}>
                            <FilterAltOutlinedIcon />
                            Filtros
                        </Fab>

                        <SwipeableDrawer
                            open={openDrawer}
                            onOpen={() => setOpenDrawer(true)}
                            onClose={() => setOpenDrawer(false)}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            PaperProps={{
                                sx: {
                                    width: 'clamp(325px, 70vw, 450px)',
                                    p: 2,
                                    top: heightFilter,
                                    height: `calc(100% - ${heightFilter}px)`,
                                },
                            }}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
                        >
                            {showTitle && <Title />}

                            <FilterChildren reset={reset} submit={onSubmit} preventOnEnter={preventOnEnter} loadingSubmit={loadingSubmit}>
                                {children}
                            </FilterChildren>
                        </SwipeableDrawer>
                    </React.Fragment>
                )}
            </LocalizationProvider>
        </Formik>
    );
}
