import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { ImportacaoUploadBtn } from 'pages/compra/pedido/components';

interface PercentualNotaFiscalImportarModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export function PercentualNotaFiscalImportarModal({ open, setOpen }: PercentualNotaFiscalImportarModalProps): JSX.Element {
    const [fileImportacao, setFileImportacao] = useState<File | null>(null);
    const { setAutoLoad, autoLoad } = useListagemContext();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useUploadFileMutation();

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (fileImportacao) {
            formData.append('file', fileImportacao);
        }

        mutate(
            {
                url: '/gestao/percentualnotalroyaltiessazonal/upload',
                formData,
            },
            {
                onSuccess: () => {
                    if (!autoLoad) {
                        setAutoLoad(true);
                    }

                    enqueueSnackbar('Arquivo importado com sucesso', { variant: 'success' });

                    setFileImportacao(null);

                    queryClient.invalidateQueries(['getPercentualNotaFiscalList']);
                    queryClient.invalidateQueries(['getPercentualNotaMetaSazonal']);

                    setOpen(false);
                },
                onError: (error: any) => showError(error, 'Não foi possível importar o arquivo. Tente novamente'),
            },
        );
    }, [autoLoad, enqueueSnackbar, fileImportacao, mutate, queryClient, setAutoLoad, setOpen, showError]);

    useEffect(() => {
        if (!open) {
            setFileImportacao(null);
        }
    }, [open]);

    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 2 }} closeButton>
            <Box sx={{ p: 1 }}>
                <SubTitle label="Importação de Percentual de Nota Fiscal" sx={{ width: '75%' }} />

                <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                    <Grid item>
                        <ImportacaoUploadBtn setFileImportacao={setFileImportacao} fileImportacao={fileImportacao} />
                    </Grid>
                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_PERCENTUAL_NOTA_ROYALTIES_SAZONAL" />
                    </Grid>
                </Grid>

                <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={handleSubmit} loading={isLoading} disabled={!fileImportacao || isLoading}>
                        Importar
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
