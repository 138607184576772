import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { initialTagValues, ArquivoTag } from 'pages/arquivo/tag/types';

export function TagFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const url = '/gestao/tagcatalogoarquivo/';
    const { id } = useParams();
    const { mutate } = useFormMutation();

    const handleSubmit = (values: ArquivoTag, formik: FormikHelpers<ArquivoTag>): void => {
        mutate(
            {
                url,
                idField: 'idTagCatalogoArquivo',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsTagCatalogoArquivo: Yup.string().required(),
            }),
        [],
    );

    return (
        <FormPage title="Tags Arquivo" values={initialTagValues} onSubmit={handleSubmit} url={url} validationSchema={validationSchema} goBackButton>
            {(formik: FormikProps<ArquivoTag>) => {
                return (
                    <Block>
                        <Row>
                            {id && (
                                <Grid item flex={1}>
                                    <CissTextField label="Código" name="idTagCatalogoArquivo" value={id} disabled={true} />
                                </Grid>
                            )}

                            <Grid item flex={4}>
                                <CissTextField
                                    label="Tag"
                                    name="dsTagCatalogoArquivo"
                                    value={formik.values.dsTagCatalogoArquivo}
                                    error={formik.errors.dsTagCatalogoArquivo}
                                    onChange={(e) => formik.setFieldValue('dsTagCatalogoArquivo', e.target.value)}
                                />
                            </Grid>
                        </Row>
                    </Block>
                );
            }}
        </FormPage>
    );
}
