import React from 'react';

import EastIcon from '@mui/icons-material/East';import { Box } from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { FormFilterCategoria } from 'pages/arquivo/categoria/components/templates/FormFilterCategoria';
import { RequestCategoriaArquivoListModelProps, CategoriaModelProps } from 'pages/arquivo/categoria/types';

const getCategoriaTitle = ({ row }: GridRowModel): JSX.Element => {
    const { subCategoriasArquivo, dsCategoriaArquivo } = row;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {subCategoriasArquivo &&
                subCategoriasArquivo.map((subCategoria: CategoriaModelProps, index: number) => {
                    return (
                        <React.Fragment key={subCategoriasArquivo.idCategoriaArquivo + subCategoriasArquivo.dsCategoriaArquivo}>
                            {index > 0 && <EastIcon sx={{ height: 15, width: 15, mx: 0.5 }} />}

                            {subCategoria.dsCategoriaArquivo}
                        </React.Fragment>
                    );
                })}

            {subCategoriasArquivo && subCategoriasArquivo.length > 0 && <EastIcon sx={{ height: 15, width: 15, mx: 0.5 }} />}

            <strong>{dsCategoriaArquivo}</strong>
        </Box>
    );
};

const generateCampanhasList = (data: CategoriaModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idCategoriaArquivo: item.idCategoriaArquivo,
            dsCategoriaArquivo: item.dsCategoriaArquivo,
            categoriaArquivoPai: item.categoriaArquivoPai,
            subCategoriasArquivo: item.subCategoriasArquivo,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function CategoriaListComp(): JSX.Element {
    const { filter, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/categoriaarquivo/listagem',
        filter,
        sort: sort.length ? sort : [{ property: 'idCategoriaArquivo', direcstion: 'DESC' }],
        columns: 'idCategoriaArquivo,categoriaArquivoPai,dsCategoriaArquivo,fgAtivo',
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], () => {
        const request: Promise<RequestCategoriaArquivoListModelProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    const columns: GridColDef[] = [
        { field: 'idCategoriaArquivo', headerName: 'ID', minWidth: 50 },
        { field: 'dsCategoriaArquivo', headerName: 'Categoria', minWidth: 120, flex: 1, renderCell: getCategoriaTitle },
        { field: 'fgAtivo', headerName: 'Status', type: 'boolean', minWidth: 70 },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 90,
            renderCell: ({ row }) => (
                <EditRemoveBtn
                    id={row.idCategoriaArquivo}
                    refresh={refetch}
                    editUrl="/arquivo/categoria/form/"
                    removeUrl={`gestao/categoriaarquivo/${row.idCategoriaArquivo}`}
                    sx={{ ml: 'auto', p: 1.5 }}
                />
            ),
        },
    ];

    const rows = generateCampanhasList(data?.data);

    return (
        <OneColumn title="Listagem Categorias de Arquivo" createButton goBackButton="/arquivo">
            <FormFilterCategoria />

            <Block>
                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
            </Block>
        </OneColumn>
    );
}
