import React, { useRef, useState } from 'react';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { ConfirmModal } from 'components/modal';
import { Revendedor } from 'pages/revendedor/revendedor/types';

export function RevendedorNewFilesPaper(): JSX.Element {
    const { values, setFieldValue } = useFormikContext<Revendedor>();
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

    const deleteFile = useRef<File | null>(null);

    const deleteNewFile = (file: File | null): void => {
        const newFiles = values.newFiles?.filter((item: File) => item.name !== file?.name) || [];

        setFieldValue('newFiles', newFiles);
    };

    return (
        <>
            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                text={
                    <>
                        Tem certeza que deseja remover o arquivo <b>{deleteFile.current?.name}</b>?
                    </>
                }
                confirm={() => deleteNewFile(deleteFile.current)}
            />

            {values.newFiles?.map((file: File) => (
                <Paper key={Math.random()} sx={{ display: 'flex', alignItems: 'center', p: 1.5, gap: 1, height: 54 }}>
                    <ArticleOutlinedIcon />

                    <Typography sx={{ mr: 'auto' }}>{file.name}</Typography>

                    <Tooltip title="Excluir">
                        <IconButton
                            sx={{ p: 0.5 }}
                            onClick={() => {
                                deleteFile.current = file;

                                setOpenConfirmModal(true);
                            }}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Paper>
            ))}
        </>
    );
}
