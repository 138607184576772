import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

import { Promocao } from 'pages/revendedor/promocao/types';

type PromocaoAutoComplete = Pick<
    CissAutoCompleteProps,
    'name' | 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'size' | 'requestOptions' | 'label'
>;

export function PromocaoAutoComplete({
    sx,
    size,
    name,
    value,
    error,
    label,
    multiple = false,
    disabled = false,
    onChange = () => {},
}: PromocaoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            size={size}
            name={name}
            label={label}
            error={error}
            value={value}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            objectId="idPromocoes"
            optionStringTemplate="{nome}"
            selectedStringTemplate="{nome}"
            isOptionEqualToValue={(option: Promocao, value: Promocao) => option.idPromocoes === value.idPromocoes}
            requestOptions={{
                url: '/integracao-revendedor/promocoes',
                sort: [{ property: 'idMarca', direction: 'ASC' }],
                nested: true,
            }}
        />
    );
}
