import React from 'react';

import LockResetIcon from '@mui/icons-material/LockReset';

import { MenuSystemItem } from 'components/menu/system/components/MenuSystemItem';
import { useGenericAuth } from 'hooks/useGenericAuth';

export function MenuSystemItemChangePassword(): JSX.Element {
    const { updatePassword } = useGenericAuth();

    return <MenuSystemItem text="Trocar Senha" icon={<LockResetIcon fontSize="small" />} onClick={updatePassword} />;
}
