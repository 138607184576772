import React from 'react';

import * as Yup from 'yup';

import { FullLoading } from 'components/loading';
import { FormPage } from 'components/page';
import {
    RevendedorGeneralBlock,
    RevendedorAutoCompletesBlock,
    RevendedorFilesBlock,
    RevendedorAtivoToggle,
    RevendedorAprovadoToggle,
} from 'pages/revendedor/revendedor/components';
import { usePostRevendedor } from 'pages/revendedor/revendedor/hooks';
import { Revendedor } from 'pages/revendedor/revendedor/types';

const formatCdLojaLojas = (formData: Revendedor): Revendedor => {
    formData.listLojas = formData.listLojas.map((loja) => ({
        ...loja,
        idLoja: loja.idLoja,
        dsNomeFantasia: loja.nome,
        cdLoja: loja.cdSap,
    }));

    return formData;
};

const formatDtNascimento = (formData: Revendedor): Revendedor => {
    if (formData.dtNascimento && !formData.dtNascimento?.endsWith('T00:00:00')) {
        formData.dtNascimento = `${formData.dtNascimento}T00:00:00`;
    }

    return formData;
};

const mapContentToInitialValues = (data: Revendedor): Revendedor => {
    formatDtNascimento(data);

    formatCdLojaLojas(data);

    return data;
};

export function RevendedorFormComp(): JSX.Element {
    const { handleSubmit, isLoading } = usePostRevendedor();

    const validationSchema = Yup.object({
        nome: Yup.string().required().nullable(),
        tipo: Yup.string().required().nullable(),
        email: Yup.string().required().nullable(),
        telefone: Yup.string().required().nullable(),
        cep: Yup.string().min(8, 'O campo deve ter exatamente 8 números.').nullable(),
        listLojas: Yup.array().min(1, 'Selecione pelo menos uma loja.').required().nullable(),
        municipio: Yup.string().when('cep', {
            is: (value: string) => value?.length >= 8,
            then: (schema) => schema.required().nullable(),
        }),
        estado: Yup.string().when('cep', {
            is: (value: string) => value?.length >= 8,
            then: (schema) => schema.required().nullable(),
        }),
        documento: Yup.string()
            .required()
            .nullable()
            .when('tipo', {
                is: 'PF',
                then: Yup.string().length(11, 'O campo deve ter exatamente 11 números.'),
                otherwise: Yup.string().length(14, 'O campo deve ter exatamente 14 números.'),
            }),
    });

    return (
        <FormPage
            goBackButton
            title="Revendedor"
            url="/integracao-revendedor/cadastrorevendedor/documento/"
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            mapContentToInitialValues={mapContentToInitialValues}
            values={{ listMarcas: [], listLojas: [], promocoes: [], files: [], newFiles: [] }}
            extraContent={
                <>
                    <RevendedorAtivoToggle />
                    <RevendedorAprovadoToggle />
                </>
            }
        >
            {() => (
                <>
                    <FullLoading loading={isLoading} />

                    <RevendedorGeneralBlock />

                    <RevendedorAutoCompletesBlock />

                    <RevendedorFilesBlock />
                </>
            )}
        </FormPage>
    );
}
