import React from 'react';

import { Link } from 'react-router-dom';

import HomeOutlined from '@mui/icons-material/HomeOutlined';
import { Box, Typography, IconButton } from '@mui/material';

import { MenuSystem, MenuMain, MenuMainMobile } from 'components/menu';
import { MenuTaskBar } from 'components/menu/taskbar/MenuTaskBar';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGenericAuth } from 'hooks/useGenericAuth';

/**
 * Cabeçalho padrão do sistema, geralmente fixo no topo da página
 * Contém botão home + menu principal (button e logo do cliente) + taskbar com ícones de ações + dados do cliente (nome e empresa) + menu do sistema
 */
export function Header(): JSX.Element {
    const { tokenInfo } = useGenericAuth();
    const { isUpMd, isUpSm } = useBreakpoints();

    return (
        <Box
            component="header"
            sx={{ backgroundColor: 'secondary.main', height: 60, zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', p: 1 }}
        >
            <IconButton component={Link} to="/">
                <HomeOutlined fontSize="large" sx={{ color: 'common.white' }} />
            </IconButton>

            {isUpMd && (
                <>
                    <MenuMain />

                    <MenuTaskBar />
                </>
            )}

            {!isUpMd && <MenuMainMobile />}

            {isUpSm && (
                <Box sx={{ color: 'common.white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="button" component="div" color="inherit">
                        {tokenInfo?.dsUsuario}
                    </Typography>

                    <Typography variant="caption" component="div" color="inherit">
                        {tokenInfo?.dsNomeEmpresa}
                    </Typography>
                </Box>
            )}

            <MenuSystem />
        </Box>
    );
}
