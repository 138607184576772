import React, { Dispatch, SetStateAction } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ContentModal } from 'components/modal';

type PedidoRealizadoTrackModal = {
    url: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function PedidoRealizadoTrackModal({ open, setOpen, url }: PedidoRealizadoTrackModal): JSX.Element {
    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            sx={{ borderRadius: 1, width: '90dvw', maxHeight: '90dvh', height: '90dvh', display: 'flex', flexDirection: 'column', pt: 1 }}
            closeButton
        >
            <>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Rastreio
                </Typography>

                <Box component="iframe" src={url} sx={{ flex: 1, border: (theme) => `1px solid ${theme.palette.grey[400]}`, borderRadius: 1 }} />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button size="small" variant="outlined" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                </Box>
            </>
        </ContentModal>
    );
}
