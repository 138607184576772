import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { PromocaoFormComp, PromocaoListComp } from 'pages/revendedor/promocao/components';

export function PromocaoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['REVENDEDOR_CADASTRO_PROMOCAO'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PromocaoListComp />
        </ListagemProvider>
    );
}

export function PromocaoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['REVENDEDOR_CADASTRO_PROMOCAO'])) return alertNotPermission();

    return (
        <FormProvider>
            <PromocaoFormComp />
        </FormProvider>
    );
}
