import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import QuestionMark from '@mui/icons-material/QuestionMark';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SchoolIcon from '@mui/icons-material/School';
import { Box, CircularProgress, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { HelpDeskBtn } from 'components/helpdesk';
import { MenutaskBarButton } from 'components/menu/taskbar/components';
import { AcessoRapidoResponseData } from 'components/menu/taskbar/types';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

function getAcessoRapidoIcon(icon: string): JSX.Element {
    switch (icon) {
        case 'school_icon':
            return <SchoolIcon />;
        case 'fact_check_icon':
            return <FactCheckIcon />;
        case 'record_voice_over_icon':
            return <RecordVoiceOverIcon />;
        case 'email_icon':
            return <EmailIcon />;
        case 'local_shipping_icon':
            return <LocalShippingOutlinedIcon />;
    }

    return <QuestionMark />;
}

export function MenuTaskBar(): JSX.Element {
    const requestListagem = useRequestListagem();
    const { fgExibeHelpDesk } = useGetDomainConfig();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/acessorapido',
        sort: [{ property: 'nrOrdem', direction: 'ASC' }],
    };

    const { data, isFetching } = useQuery<AcessoRapidoResponseData>(['getAcessoRapido'], () =>
        requestListagem(requestOptions).then((res) => res.data),
    );

    const buttons = data?.data?.filter((acessoRapido) => acessoRapido.fgAtivo === true) || [];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'common.white',
                gap: 1,
                mr: 2,
            }}
        >
            <>
                {isFetching && <CircularProgress size="1.5rem" sx={{ mr: 1 }} />}

                {!isFetching && (
                    <>
                        {buttons?.map((acessoRapido, index) => (
                            <MenutaskBarButton
                                key={index}
                                title={acessoRapido.dsAcessoRapido}
                                icon={getAcessoRapidoIcon(acessoRapido.dsIcone)}
                                onClick={() => {
                                    window.open(acessoRapido.lkAcessoRapido, '_blank')?.focus();
                                }}
                            />
                        ))}
                    </>
                )}

                {fgExibeHelpDesk && (
                    <>
                        <HelpDeskBtn />

                        <Divider orientation="vertical" variant="middle" flexItem sx={{ backgroundColor: 'common.white' }} />
                    </>
                )}
            </>
        </Box>
    );
}
