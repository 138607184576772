import React from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Block } from 'components/page';
import { brlPrice } from 'util/format';
import { SubTitle } from 'components/text';
import { PanelGrid } from 'components/grid';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { TinyButton } from 'components/buttons';
import { usePermissionsContext } from 'context/PermissionsContext';

const generateDetalheCreditoList = (data: any[] | undefined): any => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: `_debitocredito${index}`,
            nrDocumento: item.nrDocumento,
            dsTipoDocumento: item.dsTipoDocumento,
            vlCredito: item.dsTipoDocumento === 'Crédito' ? item.vlDocumento : 0,
            vlDebito: item.dsTipoDocumento === 'Débito' ? item.vlDocumento : 0,
            dsObservacao: item.dsObservacao,
            detalhes: item,
        }));
    }

    return [];
};

export function EspelhoRoyaltyDetalhamentoList({ list }: any): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { hasPermission } = usePermissionsContext();

    const columns: GridColDef[] = [
        { field: 'nrDocumento', headerName: 'ND', minWidth: 120 },
        { field: 'vlDebito', headerName: 'Débito', type: 'number', align: 'right', minWidth: 150, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'vlCredito', headerName: 'Crédito', type: 'number', align: 'right', minWidth: 150, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'dsObservacao', headerName: 'Observação', minWidth: 120, flex: 1 },
    ];

    if (hasPermission(['FINANCEIRO_CREDITO_DEBITO_DETALHAR'])) {
        columns.push({
            field: 'detalhes',
            headerName: 'Detalhes',
            sortable: false,
            filterable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (item: GridRenderCellParams<any>) => {
                const handleClick = (): void => {
                    const nrMes = searchParams.get('nrMes') ?? '';
                    const nrQuinzena = searchParams.get('nrQuinzena') ?? '';
                    const nrAno = searchParams.get('nrAno') ?? '';
                    const cdLoja = searchParams.get('cdLoja') ?? '';
                    const dsPromocao = item.value.dsObservacao;

                    navigate({
                        pathname: '/financeiro/detalhamento-creditos/detalhes',
                        search: `?${createSearchParams({
                            nrMes,
                            nrQuinzena,
                            nrAno,
                            cdLoja,
                            dsPromocao,
                        })}`,
                    });
                };

                return <TinyButton onClick={handleClick}>Detalhes</TinyButton>;
            },
        });
    }

    const rows = generateDetalheCreditoList(list);

    return (
        <Block>
            <SubTitle label="Detalhamento de Créditos e Débitos" />

            <PanelGrid rows={rows} rowsCount={rows.length} reload={false} loading={false} columns={columns} remote={false} summary={['vlDebito', 'vlCredito']} />
        </Block>
    );
}
