import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import { deepmerge } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import useBlobFile from './useBlobFile';
import { useShowError } from './useShowError';

interface UseDownloadFileProps {
    requestOptions: RequestOptionsType;
}

interface RequestDownloadFileProps {
    data: ArrayBuffer;
    status: number;
    request: XMLHttpRequest;
}

interface DownloadFileProps {
    newRequestOptions?: RequestOptionsType;
    directDownload?: boolean;
}

interface UseDownloadFile {
    isLoadingFile: boolean;
    downloadFile: (params?: DownloadFileProps) => void;
}

const updateRequestConfigType = (requestOptions: RequestOptionsType): RequestOptionsType => {
    const options = deepmerge(requestOptions, {});

    if (!('requestConfig' in options)) {
        options.requestConfig = {};
    }

    // força tipo arraybuffer no responseType
    options.requestConfig.responseType = 'arraybuffer';

    return options;
};

export function useDownloadFile({ requestOptions: options }: UseDownloadFileProps): UseDownloadFile {
    const { enqueueSnackbar } = useSnackbar();
    const { requestToFile } = useBlobFile();
    const RequestListagem = useRequestListagem();
    const { showError } = useShowError();
    const [directDownload, setDirectDownload] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [requestOptions, setRequestOptions] = useState<RequestOptionsType>(updateRequestConfigType(options));

    const { fetchStatus } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestDownloadFileProps> = RequestListagem(requestOptions);
            return request;
        },
        {
            enabled,
            onSuccess: (response) => {
                const { status: responseStatus } = response;

                if (responseStatus === 200) {
                    requestToFile(response, directDownload);
                } else {
                    const message = responseStatus === 204 ? 'Não existem dados suficientes para gerar seu arquivo.' : 'Não foi possível localizar o arquivo';

                    enqueueSnackbar(message, { variant: 'warning' });
                }
            },
            onSettled: () => setEnabled(false),
            onError: (error) => showError(error, 'Ocorreu um erro ao fazer o download do arquivo, tente novamente.'),
        },
    );

    const downloadFile = useCallback((params?: DownloadFileProps): void => {
        const { newRequestOptions } = params || {};

        setDirectDownload(Boolean(params?.directDownload));

        if (newRequestOptions) {
            setRequestOptions(updateRequestConfigType(newRequestOptions));
        }

        setEnabled(true);
    }, []);

    return {
        isLoadingFile: fetchStatus === 'fetching',
        downloadFile,
    };
}
