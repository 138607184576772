import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';


import { SituacaoPedidoLoteAutoComplete, ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function QuebrasGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const data = useMemo(() => new Date(), []);

    const [filterConfigPedido, setFilterConfigPedido] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        operator: 'in',
        value: [],
        idProperty: 'idConfigPedido',
    });

    const [filterSituacao, setFilterSituacao] = useState<FilterValuesProps>({
        property: 'idStatusProcessamento',
        operator: 'in',
        value: [],
        idProperty: 'idStatusProcessamento',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhInclusao',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const filters = [
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterSituacao, setter: setFilterSituacao },
        { value: filterInterval, setter: setFilterInterval },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <ConfiguracaoPedidoAutoComplete
                    value={filterConfigPedido.value}
                    multiple={true}
                    onChange={(e, value) => setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        filter: [
                            { property: 'fgAtivo', value: true, operator: 'eq' },
                            { property: 'dtInicioVigencia', operator: 'lte', value: data },
                            { property: 'dtFimVigencia', operator: 'gte', value: data },
                            { property: 'idTipoPedido', operator: 'eq', value: 2 },
                        ],
                        limit: 100,
                    }}
                />
            </Grid>

            <Grid item flex={4}>
                <SituacaoPedidoLoteAutoComplete value={filterSituacao.value} onChange={(e, value) => setFilterSituacao((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={4}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Início', end: 'Data Fim' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>
        </Filter>
    );
}
