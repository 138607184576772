import { FormikHelpers } from 'formik';

import { ConfiguracaoGeral } from '../types';

import { useFormMutation } from 'mutations/useFormMutation';

type UsePostConfiguracaoGeralReturn = {
    handleSubmit: (formData: ConfiguracaoGeral, formik: FormikHelpers<ConfiguracaoGeral>) => void;
    isLoading: boolean;
};

export function usePostConfiguracaoGeral(): UsePostConfiguracaoGeralReturn {
    const { mutate, isLoading } = useFormMutation();

    const handleSubmit = (formData: ConfiguracaoGeral, formik: FormikHelpers<ConfiguracaoGeral>): void => {
        mutate({
            formData,
            formik,
            preventRedirect: true,
            url: '/integracao-revendedor/descontos',
        });
    };

    return { handleSubmit, isLoading };
}
