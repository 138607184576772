import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

export type TipoQuantidadeMaximaProduto = {
    idTipoControleQuantidadeMaximaProduto: number;
    dsTipoControleQuantidadeMaximaProduto: string;
};

type TipoQuantidadeMaximaProdutoAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function TipoQuantidadeMaximaProdutoAutoComplete({
    sx,
    name,
    value,
    error,
    multiple = false,
    disabled = false,
    onChange = () => {},
}: TipoQuantidadeMaximaProdutoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Quantidade Máxima"
            size="small"
            optionStringTemplate="{dsTipoControleQuantidadeMaximaProduto}"
            selectedStringTemplate="{dsTipoControleQuantidadeMaximaProduto}"
            sx={sx}
            name={name}
            value={value}
            error={error}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            isOptionEqualToValue={(option: TipoQuantidadeMaximaProduto, value: TipoQuantidadeMaximaProduto) =>
                option.idTipoControleQuantidadeMaximaProduto === value.idTipoControleQuantidadeMaximaProduto
            }
            requestOptions={{
                url: '/gestao/tipocontrolequantidademaximaproduto',
                sort: [{ property: 'idTipoControleQuantidadeMaximaProduto', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', operator: 'eq', value: true }],
                limit: 50,
            }}
        />
    );
}
