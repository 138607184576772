import React, { useState, useMemo, useEffect } from 'react';

import { Alert, Box, Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { PedidoCompraCartProduct, PedidoCarrinhoStack } from 'pages/compra/pedido/components';

interface PedidoCompraCartModalProps {
    setOpen: (action: boolean) => void;
    open: boolean;
    isFetching: boolean;
}

export function PedidoCompraCartModal({ open, setOpen, isFetching }: PedidoCompraCartModalProps): JSX.Element {
    const { totalizador, isEmptyCart, produtoCartList } = usePedidoCompraContext();
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        if (!open) {
            setSearch('');
        }
    }, [open]);

    const produtoCartListFiltered = useMemo(() => {
        const filteredList = produtoCartList.filter((produtoCart) => {
            const { dsProduto } = produtoCart.produto;
            const includeSearch = dsProduto.toLowerCase().includes(search.toLowerCase());
            return includeSearch;
        });

        return filteredList.map((produtoCart) => <PedidoCompraCartProduct key={produtoCart.produto.idProduto} produtoCart={produtoCart} />);
    }, [produtoCartList, search]);

    const erroSemProdutos = useMemo(
        () => (!produtoCartListFiltered.length ? <Alert severity="info">Sem produtos correspondentes</Alert> : null),
        [produtoCartListFiltered.length],
    );

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton
            sx={{
                display: 'flex',
                borderRadius: 2,
                minWidth: {
                    md: '60%',
                    sm: '70%',
                    xs: '80%',
                },
                p: 0,
            }}
        >
            <Grid container direction="column" gap={1}>
                {totalizador && (
                    <Grid item sx={{ mx: 3, mt: 3 }}>
                        <PedidoCarrinhoStack isFetching={isFetching} />
                    </Grid>
                )}

                <Grid item>
                    <SubTitle label="Detalhes do Pedido" sx={{ width: 'auto', mx: 3, mt: 2 }} />

                    {isEmptyCart && (
                        <Alert severity="info" sx={{ mx: 2, mb: 2 }}>
                            Sem produtos no pedido atual
                        </Alert>
                    )}

                    {!isEmptyCart && (
                        <>
                            <Box display="flex">
                                <CissTextField
                                    label="Pesquisar"
                                    placeholder="Nome do produto"
                                    onChange={(e) => setSearch(e.target.value)}
                                    size="small"
                                    sx={{ mx: 3 }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    '> hr:last-of-type': {
                                        display: 'none',
                                    },
                                    'px': 3,
                                    'mb': 3,
                                    'mt': 2,
                                    'maxHeight': 250,
                                    'overflow': 'scroll',
                                }}
                            >
                                {produtoCartListFiltered}
                                {erroSemProdutos}
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </ContentModal>
    );
}
