import i18n from 'json/i18n.json';

export const translateKey = (str: string): string => {
    let value = str;

    if (str in i18n) {
        value = i18n[str as keyof typeof i18n];
    }

    return value;
};
