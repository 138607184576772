import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { GrupoEconomicoModelProps, RequestGrupoEconomicoListModelProps } from '../../types';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { GrupoEconomicoListFilter } from 'pages/grupo-economico/components/templates/GrupoEconomicoListFilter';

const generateGrupoEconomicoList = (data: GrupoEconomicoModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idGrupoEconomico: item.idGrupoEconomico,
            dsGrupoEconomico: item.dsGrupoEconomico,
            cdGrupoEconomico: item.cdGrupoEconomico,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function GrupoEconomicoListComp(): JSX.Element {
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/grupoeconomico',
        sort: sort.length ? sort : [{ property: 'idGrupoEconomico', direction: 'DESC' }],
        columns: 'idGrupoEconomico,dsGrupoEconomico,cdGrupoEconomico,fgAtivo',
        limit,
        page,
        filter,
    };

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestGrupoEconomicoListModelProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const columns: GridColDef[] = [
        { field: 'idGrupoEconomico', headerName: 'ID', width: 80, minWidth: 50 },
        { field: 'dsGrupoEconomico', headerName: 'Título', flex: 2, minWidth: 80 },
        { field: 'cdGrupoEconomico', headerName: 'Código Grupo Econômico', flex: 1, minWidth: 170 },
        { field: 'fgAtivo', headerName: 'Ativo', width: 120, type: 'boolean', minWidth: 80 },
    ];

    if (hasPermission(['GRUPO_ECONOMICO_ALTERAR'])) {
        columns.push({
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 80,
            renderCell: ({ row }) => <EditRemoveBtn id={row.idGrupoEconomico} refresh={refetch} removeUrl={`/gestao/grupoeconomico/${row.idGrupoEconomico}`} sx={{ ml: 'auto', p: 1.5 }} />,
        });
    }

    const rows = generateGrupoEconomicoList(data?.data);

    return (
        <OneColumn title="Grupos Econômicos" goBackButton createButton={hasPermission(['GRUPO_ECONOMICO_CADASTRAR'])}>
            <GrupoEconomicoListFilter />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
