import React, { ReactNode } from 'react';

import { SimplePaletteColorOptions, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { useDomainConfigContext } from 'context/DomainConfig';
import { useCustomFavicon } from 'styles/customFavicon';
import { updateDefaultTheme } from 'styles/defaultTheme';

interface CustomThemeProps {
    children?: ReactNode;
}

/**
 * Cria o themeProvider aplicando o tema personalizado + baseline
 * @param children
 * @returns base do sistema com tema + baseline aplicado
 */
export function CustomTheme({ children }: CustomThemeProps): JSX.Element {
    const { data } = useDomainConfigContext();
    const customTheme = updateDefaultTheme(data.theme || {});
    const secondaryColor = (data.theme.palette?.secondary as SimplePaletteColorOptions).main;

    if (secondaryColor) {
        document.body.style.backgroundColor = secondaryColor;
    }

    useCustomFavicon();

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}
