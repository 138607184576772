import { RequestListagemProps } from 'hooks/useRequestListagem';
import { MensagemPadrao } from 'pages/marketing-trade/trade/solicitacoes-pecas/types';

export interface RequestMensagemPadrao extends RequestListagemProps {
    data: MensagemPadrao[];
}

export const initialValues: MensagemPadrao = {
    dsMensagemPadrao: '',
    dsTitulo: '',
};
