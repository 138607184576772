import React from 'react';
import { Box, Typography } from '@mui/material';

import { brlDate } from 'util/format';

import { translateKey } from 'util/i18n';
import { Block } from 'components/page';

export function NotaProdutoDetalhamento({ notaProdutoData }: any): JSX.Element {
    const translateKeyPrefix = 'royalty_espelho_nota_produto_';

    const firstListFromDetalhamento = [
        {
            label: translateKey(`${translateKeyPrefix}idDocumento`).toUpperCase(),
            value: notaProdutoData.idDocumento,
        },
        {
            label: translateKey(`${translateKeyPrefix}nrDocumento`).toUpperCase(),
            value: notaProdutoData.nrDocumento,
        },
        {
            label: translateKey(`${translateKeyPrefix}dsTipoDocumento`).toUpperCase(),
            value: notaProdutoData.dsTipoDocumento,
        },
    ];

    const secondaryListFromDetalhamento = [
        {
            label: translateKey(`${translateKeyPrefix}dtDocumento`).toUpperCase(),
            value: brlDate(notaProdutoData.dtDocumento),
        },
        {
            label: translateKey('dtEmissao').toUpperCase(),
            value: brlDate(notaProdutoData.dtEmissao),
        },
    ];

    return (
        <Block sx={{ display: 'flex' }}>
            <Box sx={{ mr: 8 }}>
                {firstListFromDetalhamento.map((item) => (
                    <Typography key={item.label}>
                        {item.label}: {item.value}
                    </Typography>
                ))}
            </Box>

            <Box>
                {secondaryListFromDetalhamento.map((item) => (
                    <Typography key={item.label}>
                        {item.label}: {item.value}
                    </Typography>
                ))}
            </Box>
        </Block>
    );
}
