import React, { MouseEvent, useCallback } from 'react';

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { useListMode } from 'hooks/useListMode';

interface ToggleListModeBtnProps {
    sx?: SxProps;
}

export function ToggleListModeBtn({ sx }: ToggleListModeBtnProps): JSX.Element {
    const { mode, updateMode } = useListMode();

    const handleChange = useCallback(
        (event: MouseEvent<HTMLElement>, type: string | null): void => {
            if (type) {
                updateMode(type);
            }
        },
        [updateMode],
    );

    return (
        <ToggleButtonGroup size="small" color="primary" exclusive value={mode} onChange={handleChange} sx={sx}>
            <ToggleButton value="list">
                <ViewListOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="grid">
                <GridViewOutlinedIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
