import React, { useEffect, useState } from 'react';

import { Checkbox, Grid } from '@mui/material';
import { Field } from 'formik';

import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';


export function FormFilterPermissoes(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterIdGrupo, setfilterIdGrupo] = useState<FilterValuesProps>({
        property: 'idGrupoUsuario',
        operator: 'eq',
        value: null,
        idProperty: 'idGrupoUsuario',
    });

    const [dsGrupoUsuario, setdsGrupoUsuario] = useState<FilterValuesProps>({
        property: 'dsGrupoUsuario',
        operator: 'like',
        value: null,
        idProperty: 'dsGrupoUsuario',
    });

    const [dsObservacao, setdsObservacao] = useState<FilterValuesProps>({
        property: 'dsObservacao',
        operator: 'like',
        value: null,
        idProperty: 'dsObservacao',
    });

    const filters = [
        { value: filterIdGrupo, setter: setfilterIdGrupo },
        { value: dsGrupoUsuario, setter: setdsGrupoUsuario },
        { value: dsObservacao, setter: setdsObservacao },
    ];

    const [fgPadraoFranqueado, setfgPadraoFranqueado] = useState<FilterValuesProps>({
        property: 'fgPadraoFranqueado',
        operator: 'eq',
        value: true,
        idProperty: 'fgPadraoFranqueado',
    });

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={3}>
                <CissTextField label="Código Grupo Usuário" value={filterIdGrupo.value} onChange={(e) => setfilterIdGrupo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={3}>
                <CissTextField label="Grupo Usuário" value={dsGrupoUsuario.value} onChange={(e) => setdsGrupoUsuario((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={3}>
                <CissTextField label="Observação" value={dsObservacao.value} onChange={(e) => setdsObservacao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>
        </Filter>
    );
}
