import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TinyButton } from 'components/buttons';
import { LoadingButton } from '@mui/lab';

interface DreListLojaColumnProp {
    id: number;
    idStatus: number;
    vlReceita: string;
    isLoading: boolean;
}

export function DreListPreencherColumn({ id, idStatus, vlReceita, isLoading }: DreListLojaColumnProp): JSX.Element {
    const navigate = useNavigate();

    // 1 = A preencher
    // 2 = Em preenchimento
    let enabled = [1, 2].includes(idStatus);

    // se a preencher, somente disponivel quando existir receita bruta
    if (idStatus === 1) {
        enabled = Boolean(vlReceita);
    }

    const handleClickPreencherDre = useCallback((): void => {
        navigate({
            pathname: `form/${id}`,
        });
    }, [id]);

    return (
        <LoadingButton variant="contained" size="small" fullWidth={false} sx={{ px: 1, py: 0, minWidth: 22 }} disabled={!enabled} onClick={handleClickPreencherDre} loading={isLoading}>
            Preencher
        </LoadingButton>
    );
}
