import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import StyleIcon from '@mui/icons-material/Style';
import { Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { Container } from '@mui/system';

import { Block } from 'components/page';
import { Field } from 'pages/marketing-trade/types';

interface fontStyles {
    dsEstiloFonte: string;
    dsFonte: string;
    dsCor: string;
    nrTamanho: number;
}

interface AtributoCardProps {
    field: Field;
    checked: boolean;
    onDelete: () => void;
    onCheck: (checked: boolean) => void;
    onEdit: () => void;
    onSaveStyle?: (styles: fontStyles) => void;
}

export function MarketingTradeAtributoCard({ field, checked, onDelete = () => {}, onCheck = () => {}, onSaveStyle = () => {}, onEdit = () => {} }: AtributoCardProps): JSX.Element {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: field.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleSaveStyle = (): void => {
        onSaveStyle({
            dsCor: field.font.color,
            dsEstiloFonte: '',
            dsFonte: field.font.family,
            nrTamanho: field.font.size,
        });
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Block sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
                <Container sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <MenuIcon sx={{ opacity: 0.2 }} />

                    <Checkbox checked={checked} onChange={(e, check) => onCheck(check)} sx={{ ml: 3 }} />

                    <Typography variant="body1" sx={{ ml: 3, fontFamily: field.font.family }}>
                        {field.name}
                    </Typography>
                </Container>

                <Container sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Tooltip title={'Salvar predefinição de estilo'}>
                        <IconButton onClick={() => handleSaveStyle()}>
                            <StyleIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={'Alterar atributo'}>
                        <IconButton onClick={() => onEdit()}>
                            <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={'Excluir atributo'}>
                        <IconButton onClick={() => onDelete()}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Container>
            </Block>
        </div>
    );
}
