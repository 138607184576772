import React from 'react';

import { CissTextField } from 'components/fields';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoProdutoTextField(): JSX.Element {
    const { filterDsProduto, setFilterCdProduto, setFilterDsProduto } = usePedidoFilterContext();

    return (
        <CissTextField
            label="Produto"
            size="small"
            placeholder="Nome ou código do produto..."
            value={filterDsProduto.value}
            onChange={(e) => {
                setFilterDsProduto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }));
                setFilterCdProduto((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }));
            }}
        />
    );
}
