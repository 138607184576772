import React from 'react';

import { EventoFormComp, EventoListComp } from './components';

import { ListagemProvider } from 'context/ListagemContext';
import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function EventoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['CALENDARIO_EVENTO_PERSONALIZADO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <EventoListComp />
        </ListagemProvider>
    );
}

export function EventoForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();
    if (!hasFormPermission('CALENDARIO_EVENTO_PERSONALIZADO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <EventoFormComp />
        </FormProvider>
    );
}
