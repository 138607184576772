import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface MarketingTagsProps {
    idTag: number;
    dsTag: string;
}

export interface CategoriaModelProps {
    idCategoriaArquivo: number;
    dsCategoriaArquivo: string;
    categoriaArquivoPai: any;
    subCategoriasArquivo: CategoriaModelProps[] | null;
    fgAtivo: boolean;
}

export interface SubCategoriaProps {
    idCategoriaArquivo: number;
    dsCategoriaArquivo: string;
    qtArquivos: any;
    fgAtivo: boolean;
}
export interface RequestSubCategoriaArquivoListProps {
    data: SubCategoriaProps[];
    total: number;
    hasNext: boolean;
}

export interface DetalhesArquivoProps {
    idCatalogoArquivo: number;
    dsTitulo: string;
    dsExtensao: string;
    fgAtivo: boolean;
}

export interface RequestDetalhesArquivoProps {
    data: DetalhesArquivoProps[];
    total: number;
    hasNext: boolean;
}

export interface DetalhesCategoriaModelProps {
    dsSubCategoria: string;
    fgStatus: any;
    arquivos: any;
}

export interface RequestCategoriaArquivoListModelProps {
    data: CategoriaModelProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestCampanhaMarketingStoreProps extends Partial<RequestListagemProps> {
    status: number;
    data: {
        data: CategoriaModelProps;
    };
}

export const initialCategoriaValues: Partial<CategoriaModelProps> = {
    idCategoriaArquivo: -1,
    dsCategoriaArquivo: '',
    categoriaArquivoPai: null,
    fgAtivo: true,
};
