import React from 'react';

import { TextField, Typography } from '@mui/material';

import { brlDecimal } from 'util/format';

interface PedidoCompraValueProps {
    value: number;
    align?: 'right' | 'left';
    size?: 'small' | 'normal';
}

export function PedidoCompraValue({ value, align = 'left', size = 'normal' }: PedidoCompraValueProps): JSX.Element {
    const price = brlDecimal(value);
    const infoPrice = /([\d.]{1,}),([\d]{2,})/g.exec(price) || [null, '0', '00'];
    const defaultSize = size === 'normal';

    const calculateSizeByPrice = (sizeFont: number, percent: number[]): string => {
        const calculate = (index: number): number => sizeFont - price.length * percent[index];
        const validate = (length: number): boolean => price.length >= length;
        const fontSize = validate(20) ? calculate(4) : validate(16) ? calculate(3) : validate(12) ? calculate(2) : validate(10) ? calculate(1) : validate(9) ? calculate(0) : sizeFont;
        return fontSize.toString().concat('rem');
    };

    return (
        <Typography
            sx={{
                display: 'flex',
                lineHeight: 1,
                justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
            }}
        >
            <span
                style={{
                    fontSize: defaultSize ? calculateSizeByPrice(1.2, [0.01, 0.02, 0.02, 0.01, 0.01]) : calculateSizeByPrice(0.9, [0.01, 0.02, 0.02, 0.01, 0.01]),
                    paddingBottom: defaultSize ? '3px' : '2px',
                    alignSelf: 'flex-end',
                    marginRight: '4px',
                }}
            >
                R$
            </span>
            <span
                style={{
                    fontSize: defaultSize ? calculateSizeByPrice(2.4, [0.05, 0.08, 0.09, 0.09, 0.08]) : calculateSizeByPrice(1.6, [0.02, 0.03, 0.04, 0.05, 0.05]),
                }}
            >
                {infoPrice[1]}
            </span>
            <span
                style={{
                    fontSize: defaultSize ? calculateSizeByPrice(0.9, [0.01, 0.01, 0.02, 0.02, 0.02]) : calculateSizeByPrice(0.8, [0.01, 0.01, 0.02, 0.02, 0.02]),
                    paddingTop: defaultSize ? '3px' : '2px',
                }}
            >
                ,{infoPrice[2]}
            </span>
        </Typography>
    );
}
