import { FilterValuesProps, ValueSetterFiltersProps } from 'hooks/useFilter';
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

export type ListagemContextData = {
    url: string;
    setUrl: (value: string) => void;
    filter: FilterValuesProps[];
    setFilter: (value: any) => void;
    sort: any;
    setSort: (value: any) => void;
    columns: any;
    setColumns: (value: any) => void;
    page: number;
    setPage: (value: number) => void;
    limit: number;
    setLimit: (value: number) => void;
    autoLoad: boolean;
    setAutoLoad: (value: boolean) => void;
    nested: boolean;
    setNested: (value: boolean) => void;
    fullFilterData: ValueSetterFiltersProps[];
    setFullFilterData: Dispatch<SetStateAction<ValueSetterFiltersProps[]>>;
    routeState: any;
    setRouteState: (value: any) => void;
};

interface ListagemProviderProps {
    children?: ReactNode;
}

const ListagemContext = createContext<ListagemContextData>({} as ListagemContextData);

interface sortApiGridProps {
    property: string;
    direction: string;
}

/**
 * Contexto para uso das listagens
 */
export function ListagemProvider({ children }: ListagemProviderProps): JSX.Element {
    const [url, setUrl] = useState('');
    const [filter, setFilter] = useState<FilterValuesProps[]>([]);
    const [fullFilterData, setFullFilterData] = useState<ValueSetterFiltersProps[]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [sort, setSortInternal] = useState<sortApiGridProps[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(12);
    const [autoLoad, setAutoLoad] = useState(false);
    const [nested, setNested] = useState(false);
    const [routeState, setRouteState] = useState<any>();

    // conversor de sort pois vindo de uma grid o contrato é diferente do esperado pelo backend
    const setSort = (sorters: any): void => {
        const newSort: sortApiGridProps[] = [];

        if (Array.isArray(sorters)) {
            sorters.forEach((item) => {
                newSort.push({
                    property: item.property ?? item.field,
                    direction: (item.direction ?? item.sort).toUpperCase(),
                });
            });
        }

        setSortInternal(newSort);
    };

    const contextValue = useMemo(
        () => ({
            url,
            setUrl,
            filter,
            setFilter,
            columns,
            setColumns,
            sort,
            setSort,
            page,
            setPage,
            limit,
            setLimit,
            autoLoad,
            setAutoLoad,
            nested,
            setNested,
            fullFilterData,
            setFullFilterData,
            routeState,
            setRouteState,
        }),
        [url, filter, columns, sort, page, limit, autoLoad, nested, fullFilterData],
    );

    return <ListagemContext.Provider value={contextValue}>{children}</ListagemContext.Provider>;
}

export const useListagemContext = (): ListagemContextData => useContext(ListagemContext);
