import { enqueueSnackbar } from 'notistack';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';

type ReturnUseValidationUsuario = {
    validateUsuario: (showError?: boolean) => boolean;
};

export function useValidationUsuario(): ReturnUseValidationUsuario {
    const { usuario } = useAplicacaoDataContext();

    const getFormattedMessage = (messages: string[]): string => {
        return messages.length > 1 ? `${messages.slice(0, -1).join(', ')} e ${messages.slice(-1)}` : messages[0];
    };

    const validateUsuario = (showError = true): boolean => {
        let message = '';

        if (usuario) {
            const { cdUsuario, idLocalFuncionario, idTipoEntrega, cep } = usuario;
            const messages: string[] = [];

            if (!cdUsuario) {
                messages.push('código do usuário');
            }

            if (!idLocalFuncionario) {
                messages.push('local do funcionário');
            }

            if (!idTipoEntrega) {
                messages.push('tipo de entrega');
            }

            if (!cep?.idCep) {
                messages.push('endereço');
            }

            if (messages.length) {
                const formattedMessage = getFormattedMessage(messages);

                message = `Cadastro do usuário incompleto, é necessário informar o ${formattedMessage} para efetuar pedidos`;
            }
        } else {
            message = 'Falha ao validar o cadastro do usuário';
        }

        if (showError && message) {
            enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
        }

        return !message;
    };

    return { validateUsuario };
}
