import React from 'react';

import { useParams } from 'react-router-dom';

import { GrupoEconomicoFormComp, GrupoEconomicoListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function GrupoEconomicoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['GRUPO_ECONOMICO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <GrupoEconomicoListComp />
        </ListagemProvider>
    );
}

export function GrupoEconomicoForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('GRUPO_ECONOMICO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <GrupoEconomicoFormComp />
        </FormProvider>
    );
}
