import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { PercentualNotaMetaSazonalAutoComplete } from 'components/autocompletes';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { getSuccessMessage, SuccessMessageProps } from 'util/success';

interface PercentualNotaFiscalExcluirModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

interface PercentualNotaMetaSazonalProps {
    idPercentualNotaRoyaltiesSazonal: number;
    dsPercentualNotaRoyaltiesSazonal: string;
}

export function PercentualNotaFiscalExcluirModal({ open, setOpen }: PercentualNotaFiscalExcluirModalProps): JSX.Element {
    const [percentualNotaMetaSazonal, setPercentualNotaMetaSazonal] = useState<PercentualNotaMetaSazonalProps | null>();
    const [confirmExclusao, setConfirmExclusao] = useState<boolean>(false);
    const { setAutoLoad, autoLoad } = useListagemContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const Api = useServiceApiContext();
    const queryClient = useQueryClient();

    const handleSubmit = useCallback(() => {
        if (percentualNotaMetaSazonal) {
            setIsLoading(true);

            Api.delete(`/gestao/percentualnotalroyaltiessazonal/${percentualNotaMetaSazonal.idPercentualNotaRoyaltiesSazonal}`)
                .then((result: SuccessMessageProps) => {
                    if (!autoLoad) {
                        setAutoLoad(true);
                    }

                    const message = getSuccessMessage(result);

                    enqueueSnackbar(message, { variant: 'success' });

                    setConfirmExclusao(false);
                    setPercentualNotaMetaSazonal(null);

                    queryClient.invalidateQueries(['getPercentualNotaFiscalList']);
                    queryClient.invalidateQueries(['getPercentualNotaMetaSazonal']);

                    setOpen(false);
                })
                .catch((error) => showError(error, 'Não foi possível excluir os percentuais de nota fiscal do tipo de percentual. Tente Novamente'))
                .finally(() => setIsLoading(false));
        }
    }, [Api, autoLoad, enqueueSnackbar, percentualNotaMetaSazonal, queryClient, setAutoLoad, setOpen, showError]);

    useEffect(() => {
        if (!open) {
            setConfirmExclusao(false);
            setPercentualNotaMetaSazonal(null);
        }
    }, [open]);

    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 2 }} closeButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Excluir Percentual de Nota Fiscal" sx={{ width: '75%' }} />

                <PercentualNotaMetaSazonalAutoComplete value={percentualNotaMetaSazonal} onChange={(e, value) => setPercentualNotaMetaSazonal(value)} />

                <Typography color="error" fontStyle="italic" variant="body2" sx={{ mt: 3, mb: 2 }}>
                    <strong>ATENÇÃO!</strong> Ao clicar em "Excluir Percentual de Nota Fiscal" todos os percentuais importadas dos tipos de percentual selecionadas serão permanentemente excluídas.
                    Essa operação é irreversível.
                </Typography>

                <FormControlLabel label="Estou ciente e confirmo a exclusão" control={<Checkbox />} checked={confirmExclusao} onChange={(e, checked) => setConfirmExclusao(checked)} />

                <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton
                        variant="contained"
                        color="error"
                        startIcon={<DeleteOutlinedIcon />}
                        onClick={handleSubmit}
                        disabled={!percentualNotaMetaSazonal || !confirmExclusao}
                        loading={isLoading}
                    >
                        Excluir Percentual de Nota Fiscal
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
