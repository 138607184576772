import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Alert, CircularProgress } from '@mui/material';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { OneColumn } from 'components/page';

import { RequestRoyaltyNotaProdutoDetalhamentoModelProps } from 'pages/financeiro/extrato/royalty-espelho-nota-produto/types';
import { NotaProdutoDetalhamento, NotaProdutoGrid } from 'pages/financeiro/extrato/royalty-espelho-nota-produto/components';
import { Centered } from 'components/grid';

export function NotaProdutoDetailComp(): JSX.Element {
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const dtFiltro = searchParams.get('dtFiltro');
    const nrNota = searchParams.get('nrNota');
    const validParams = Boolean(cdLoja && dtFiltro && nrNota);
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/royaltyextrato/nota/detalhamento',
        columns: 'idDocumento',
        nested: true,
        filter: [
            { property: 'dtFiltro', value: dtFiltro, operator: 'eq' },
            { property: 'cdLoja', value: cdLoja, operator: 'eq' },
            { property: 'nrNota', value: nrNota, operator: 'eq' },
        ],
    };

    const { data, fetchStatus, isLoading } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestRoyaltyNotaProdutoDetalhamentoModelProps> = RequestListagem(requestOptions).then((result) => result.data.data[0]);
            return request;
        },
        {
            enabled: validParams,
        },
    );

    const requestConfigOptions: RequestOptionsType = {
        url: '/config/configgeral/chave',
        extraParams: [{ dsChave: 'VL_PRECO_BASE_FATURAMENTO' }],
    };

    const { data: configVlBaseFaturamento } = useQuery([requestConfigOptions], () => {
        const request: Promise<string> = RequestListagem(requestConfigOptions).then((result) => result.data);
        return request;
    });

    return (
        <OneColumn title="Consulta de Royalties - Detalhamento de nota" goBackButton>
            {isLoading && fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress sx={{ my: 2 }} />
                </Centered>
            )}

            {!validParams && <Alert severity="error">Verifique se a URL informada é valida.</Alert>}

            {!isLoading && validParams && !data && <Alert severity="error">Falha na recuperação dos dados do detalhamento das notas de Royalties.</Alert>}

            {!isLoading && validParams && data && (
                <React.Fragment>
                    <NotaProdutoDetalhamento notaProdutoData={data} />

                    {data.royaltyNotaProdutos && configVlBaseFaturamento && <NotaProdutoGrid royaltyNotaProdutos={data.royaltyNotaProdutos} configVlBaseFaturamento={configVlBaseFaturamento} />}
                </React.Fragment>
            )}
        </OneColumn>
    );
}
