import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { QuebrasImportarUploadBtn } from 'pages/logistica/quebras/components';

interface ConfiguracaoPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
}

interface QuebrasImportarModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export function QuebrasImportarModal({ open, setOpen }: QuebrasImportarModalProps): JSX.Element {
    const [fileQuebras, setFileQuebras] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const queryClient = useQueryClient();

    const [configPedidoList, setConfigPedidoList] = useState<ConfiguracaoPedidoProps[]>([]);

    const listIdConfigPedido = useMemo(() => configPedidoList.map((config) => config.idConfigPedido).toString(), [configPedidoList]);

    const { mutate: mutateFile, isLoading: isLoadingFile } = useUploadFileMutation({
        onSuccess: () => {
            enqueueSnackbar('Importação de quebras realizada com sucesso', { variant: 'success' });

            setFileQuebras(null);
            setConfigPedidoList([]);

            queryClient.invalidateQueries(['getListagemQuebras']);
        },
        onError: (error: any) => showError(error, 'Erro ao importar arquivo de quebras'),
    });

    const callbackSubmitFile = useCallback(() => {
        const formData = new FormData();

        if (fileQuebras) {
            formData.append('file', fileQuebras);
            formData.append('ids', listIdConfigPedido);
        }

        mutateFile({
            url: '/gestao/configpedidoquebraimportacao/upload',
            formData,
        });
    }, [fileQuebras, listIdConfigPedido, mutateFile]);

    useEffect(() => {
        if (!open) {
            setFileQuebras(null);
            setConfigPedidoList([]);
        }
    }, [open]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            sx={{
                borderRadius: 2,
                width: {
                    xs: '80%',
                    sm: 700,
                },
                maxWidth: '80%',
            }}
            closeButton
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Importar Novo Arquivo de Quebras" />

                <ConfiguracaoPedidoAutoComplete
                    multiple={true}
                    value={configPedidoList}
                    onChange={(e, value) => setConfigPedidoList(value)}
                    sx={{ mb: 2 }}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        filter: [
                            { property: 'fgAtivo', value: true, operator: 'eq' },
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                        ],
                        limit: 100,
                    }}
                />

                <QuebrasImportarUploadBtn setFileQuebras={setFileQuebras} fileQuebras={fileQuebras} />

                <Stack
                    spacing={2}
                    justifyContent="flex-end"
                    direction="row"
                    sx={{
                        mt: 3,
                    }}
                >
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={callbackSubmitFile} loading={isLoadingFile} disabled={configPedidoList.length === 0 || !fileQuebras}>
                        Importar Quebras
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
