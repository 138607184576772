import React from 'react';

import { OneColumn } from 'components/page';
import { GrupoEmailsGrid } from 'pages/complementar/grupo-emails/components';

export function GrupoEmailsListComp(): JSX.Element {
    return (
        <OneColumn title="Grupos de E-mails" goBackButton>
            <GrupoEmailsGrid />
        </OneColumn>
    );
}
