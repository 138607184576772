import React, { ReactNode, forwardRef, useCallback, useEffect, useState } from 'react';

import { LinearProgress, alpha } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { DataGridPro, GridCallbackDetails, GridColDef, GridColumnGroupingModel, GridPinnedColumns, GridRowId, GridRowParams, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { Centered } from 'components/grid';
import { CustomFooter } from 'components/grid/components/CustomFooter';
import { CustomNoRowsOverlay } from 'components/grid/components/CustomNoRowsOverlay';
import { useListagemContext } from 'context/ListagemContext';
import { uniqueArray } from 'util/format';

interface PanelGridProps {
    rows: GridRowsProp;
    rowsCount: number;
    columns: GridColDef[];
    summary?: string[];
    summaryPrefix?: string | boolean;
    loading: boolean;
    reload: false | (() => void);
    remote?: boolean;
    visibleRows?: number | 'all';
    columnGrouping?: GridColumnGroupingModel;
    onClickRow?: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
    pinnedColumns?: GridPinnedColumns;
    columnBorder?: boolean;
    rowContent?: (params: GridRowParams<any>) => JSX.Element;
    rowContentHeight?: number | 'auto';
    onRowContentExpandedChange?: (ids: GridRowId[], details: GridCallbackDetails<any>) => void;
    hideFooter?: boolean;
    customMessageNoRows?: string;
    rowThreshold?: number | string;
    getDetailPanelHeight?: (params: GridRowParams) => number | 'auto';
    getDetailPanelContent?: (params: GridRowParams) => ReactNode;
    isTreeData?: boolean;
    rowCellHeight?: 'auto';
    checkboxSelection?: boolean;
    selectionModel?: GridSelectionModel;
    keepNonExistentRowsSelected?: boolean;
    autoHeight?: boolean;
    disableSelectionOnClick?: boolean;
}

const rowsPerPageOptions = [12, 24, 36, 48];

export const PanelGrid = forwardRef<GridApiPro, PanelGridProps>(
    (
        {
            rows,
            rowsCount,
            columns,
            loading,
            reload,
            summary = [],
            summaryPrefix,
            remote = true,
            visibleRows,
            columnGrouping,
            onClickRow,
            pinnedColumns,
            columnBorder,
            rowContent,
            rowContentHeight = 500, // default do MUI = 500
            onRowContentExpandedChange,
            hideFooter,
            customMessageNoRows,
            isTreeData = false,
            getDetailPanelHeight,
            getDetailPanelContent,
            rowCellHeight,
            checkboxSelection = false,
            selectionModel,
            keepNonExistentRowsSelected = false,
            autoHeight = true,
            disableSelectionOnClick = true,
        },
        ref,
    ): JSX.Element => {
        const { limit, autoLoad, setPage, page, setSort, setLimit } = useListagemContext();
        const [gridRows, setGridRows] = useState(rows);
        const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
        const [gridRowsCount, setGridRowsCount] = useState(rowsCount);

        const experimentalFeatures = { columnGrouping: false };
        const mode = remote ? 'server' : 'client';
        const hideFooterSelectedRowCount = typeof visibleRows !== 'undefined';

        let pageSize = limit;

        if (typeof visibleRows === 'string') {
            pageSize = rowsCount;
        } else if (typeof visibleRows === 'number') {
            pageSize = visibleRows;
        }

        if (columnGrouping) {
            experimentalFeatures.columnGrouping = true;
        }

        if (!rowsPerPageOptions.includes(pageSize)) {
            rowsPerPageOptions.push(pageSize);
        }

        const handlePageChange = useCallback(
            (newPage: number): void => {
                const page = newPage + 1;
                setPage(page);
            },
            [setPage],
        );

        const handleDetailPanelExpandedRowIdsChange = useCallback(
            (newIds: GridRowId[], details: GridCallbackDetails<any>) => {
                if (newIds.length > 1) {
                    const lastId = newIds[newIds.length - 1];
                    setExpandedRowIds([lastId]);
                } else {
                    setExpandedRowIds(newIds);
                }

                if (typeof onRowContentExpandedChange === 'function') {
                    onRowContentExpandedChange(newIds, details);
                }
            },
            [onRowContentExpandedChange],
        );

        useEffect(() => {
            if (!loading) {
                setGridRows(rows);
                setGridRowsCount(rowsCount);
            }
        }, [loading, rows, rowsCount]);

        const CustomMessageNoRows = (): JSX.Element => <Centered>{customMessageNoRows}</Centered>;

        return (
            <DataGridPro
                apiRef={ref as React.MutableRefObject<GridApiPro>}
                rows={gridRows}
                rowCount={gridRowsCount}
                columns={columns}
                autoHeight={autoHeight}
                loading={loading}
                paginationMode={mode}
                pageSize={pageSize}
                pagination
                page={page - 1}
                onPageChange={handlePageChange}
                rowsPerPageOptions={hideFooterSelectedRowCount ? [pageSize] : uniqueArray<number>(rowsPerPageOptions.sort((a, b) => a - b))}
                hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
                onSelectionModelChange={onClickRow}
                selectionModel={selectionModel}
                experimentalFeatures={experimentalFeatures}
                columnGroupingModel={columnGrouping}
                pinnedColumns={pinnedColumns}
                disableColumnFilter
                sortingMode={mode}
                onSortModelChange={(sortModel) => {
                    setSort(sortModel);
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: customMessageNoRows ? CustomMessageNoRows : CustomNoRowsOverlay,
                    Footer: CustomFooter,
                }}
                componentsProps={{
                    footer: {
                        refresh: reload,
                        summary,
                        summaryPrefix,
                        hideFooter,
                        ref,
                    },
                    noRowsOverlay: {
                        started: autoLoad,
                    },
                }}
                getRowHeight={() => (rowCellHeight ? 'auto' : null)}
                getRowClassName={(params) => {
                    const { featured, fgAtivo } = params.row;

                    if (featured) {
                        return 'mui-personalized-row-featured';
                    }

                    if (typeof fgAtivo === 'boolean' && fgAtivo === false) {
                        return 'mui-personalized-row-disabled';
                    }

                    return '';
                }}
                sx={deepmerge(
                    {
                        'backgroundColor': 'common.white',
                        '& .mui-personalized-row-featured': {
                            'backgroundColor': (theme) => alpha(theme.palette.primary.main, 0.25),

                            '&:hover': {
                                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.45),
                            },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: 'clip',
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        },
                        '& .mui-personalized-row-disabled': {
                            'opacity': 0.3,

                            '&:hover': {
                                opacity: 1,
                            },
                        },
                    },
                    columnBorder && {
                        '& .MuiDataGrid-cell': {
                            borderRight: '1px solid #efefef',
                        },
                    },
                )}
                getDetailPanelContent={isTreeData ? getDetailPanelContent : rowContent}
                getDetailPanelHeight={isTreeData ? getDetailPanelHeight : () => rowContentHeight}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                detailPanelExpandedRowIds={expandedRowIds}
                checkboxSelection={checkboxSelection}
                disableSelectionOnClick={disableSelectionOnClick}
                keepNonExistentRowsSelected={keepNonExistentRowsSelected}
            />
        );
    },
);
