import React from 'react';
import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface ModuleOptionsProps {
    idModulo: number;
    dsModulo: string;
}

type ModuleAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function ModuleAutoComplete({ value, error, multiple = true, disabled = false, sx, onChange = () => {} }: ModuleAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Modulos"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idModulo} - {dsModulo}"
            optionStringTemplate="<strong>{idModulo}</strong> - {dsModulo}"
            requestOptions={{
                url: '/gestao/modulo',
                sort: [{ property: 'dsModulo', direction: 'ASC' }],
                columns: 'idModulo,dsModulo',
                limit: 50,
            }}
            isOptionEqualToValue={(option: ModuleOptionsProps, value: ModuleOptionsProps) => option.idModulo === value.idModulo}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
        />
    );
}
