import React from 'react';

import { OneColumn } from 'components/page';
import { ListagemProvider } from 'context/ListagemContext';
import { DetalhamentoCreditosImportacaoGrid, DetalhamentoCreditosImportacaoItem } from 'pages/financeiro/detalhamento-creditos/importar/components';

export function DetalhamentoCreditosImportacaoComp(): JSX.Element {
    return (
        <OneColumn title="Detalhamento de Créditos - Importar Arquivos" goBackButton>
            <DetalhamentoCreditosImportacaoItem />

            <ListagemProvider>
                <DetalhamentoCreditosImportacaoGrid />
            </ListagemProvider>
        </OneColumn>
    );
}
