import React from 'react';

import { CircularProgress, Typography } from '@mui/material';

import { Centered } from 'components/grid';
import { Block } from 'components/page';
import { useGetUsuario } from 'pages/compra/pedido/hooks';
import { brlPrice } from 'util/format';

export function PedidoCompraUsuarioInfo(): JSX.Element | null {
    const { data, isFetching } = useGetUsuario();

    if (isFetching) {
        return (
            <Block sx={{ width: '100%', height: { xs: 168, lg: 118 } }}>
                <Centered sx={{ alignItems: 'center' }}>
                    <CircularProgress />
                </Centered>
            </Block>
        );
    }

    if (!data) {
        return null;
    }

    const getDiasPrevisaoEntregaText = (): string => {
        const dias = data.nrDiasPrevisaoEntrega;

        if (dias) {
            const diasUteis = dias > 1 ? 'dias úteis' : 'dia útil';

            return `${dias} ${diasUteis} (após o faturamento)`;
        }

        return '--';
    };

    return (
        <Block sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, gap: 0.5 }}>
            <Typography
                sx={{
                    gridColumn: { sm: 'span 2' },
                    fontSize: '24px',
                    fontWeight: 'bold',
                    span: { color: (theme) => theme.palette.secondary.main },
                }}
            >
                Saldo atual: <span>{brlPrice(data.vlSaldoAtual)}</span>
            </Typography>

            <Typography>
                <strong>Previsão de entrega do pedido: </strong>
                {getDiasPrevisaoEntregaText()}
            </Typography>

            <Typography>
                <strong>Endereço de entrega: </strong>
                {data.dsEndereco || '--'}
            </Typography>

            <Typography>
                <strong>Frete: </strong>
                {data.dsFrete || '--'}
            </Typography>

            <Typography>
                <strong>Valor mínimo do pedido: </strong>
                {brlPrice(data.vlMinimo)}
            </Typography>
        </Block>
    );
}
