import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { RevendedorActionColumn } from 'pages/revendedor/revendedor/components';
import { useGetRevendedor } from 'pages/revendedor/revendedor/hooks/useGetRevendedor';
import { Revendedor } from 'pages/revendedor/revendedor/types';
import { brlPhone } from 'util/format';

const getRows = (data: Revendedor[] | undefined): GridRowsProp => {
    const list = data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => ({
            id: item.idCadastroRevendedor,
            documento: item.documento,
            nome: item.nome,
            email: item.email,
            files: item.files,
            telefone: item.telefone && brlPhone(item.telefone),
        }));
    }
    return [];
};

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Código', flex: 1, sortable: false },
    { field: 'nome', headerName: 'Nome', flex: 1, sortable: false },
    { field: 'email', headerName: 'E-mail', flex: 1, sortable: false },
    { field: 'telefone', headerName: 'Telefone', flex: 1, sortable: false },
    {
        field: 'acoes',
        align: 'center',
        headerAlign: 'center',
        headerName: 'Ações',
        sortable: false,
        renderCell: ({ row }) => <RevendedorActionColumn row={row} />,
    },
];

export function RevendedorGrid(): JSX.Element {
    const { data, refetch, isFetching } = useGetRevendedor();

    return (
        <Block>
            <PanelGrid
                rows={getRows(data?.data)}
                rowsCount={data?.total ?? 0}
                reload={refetch}
                loading={isFetching}
                columns={columns}
                visibleRows={10}
            />
        </Block>
    );
}
