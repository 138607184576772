import React, { SyntheticEvent } from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Alert, alpha, CircularProgress } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { Centered } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DreMenuProps } from 'pages/financeiro/dre/types';
import { getErrorMessage } from 'util/error';

export function DreFormAsideMenu(): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();

    const requestOptions: RequestOptionsType = {
        url: '/dre/tipodremercadologicagrupoexibicao/agrupamento',
        sort: [{ property: 'nrSequencia', direction: 'ASC' }],
    };

    const { isLoading, fetchStatus, isSuccess, data, isError, error } = useQuery([requestOptions], () => {
        const request: Promise<DreMenuProps[]> = RequestListagem(requestOptions).then((result) => result.data.data);
        return request;
    });

    const handleNodeSelect = (value: string): void => {
        const element = document.getElementById(value);

        if (element) {
            const headerOffset = 8;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        } else {
            enqueueSnackbar('Não existem valores para a opção escolhida.', { variant: 'warning' });
        }
    };

    const [errorRequest] = getErrorMessage(error);

    return (
        <Block
            sx={{
                position: 'sticky',
                top: 0,
            }}
        >
            <SubTitle label="Agrupamentos" />

            {isLoading && fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress />
                </Centered>
            )}

            {isError && errorRequest && <Alert severity="error">{errorRequest}</Alert>}

            {isSuccess && (
                <TreeView
                    defaultCollapseIcon={<ExpandMoreOutlinedIcon />}
                    defaultExpandIcon={<ChevronRightOutlinedIcon />}
                    onNodeSelect={(event: SyntheticEvent<Element, Event>, value: string) => handleNodeSelect(value)}
                    sx={{
                        '.MuiTreeItem-content': {
                            'p': 0.5,

                            '.MuiTreeItem-iconContainer svg': {
                                fontSize: '1.6rem',
                            },

                            '.MuiTreeItem-label': {
                                fontSize: '1.05rem',
                            },

                            '&:hover': {
                                backgroundColor: 'grey.100',
                            },

                            '&.Mui-selected': {
                                backgroundColor: 'grey.300',
                            },
                        },

                        '& > li': {
                            '& > .MuiTreeItem-content': {
                                '& > .MuiTreeItem-label, & > .MuiTreeItem-iconContainer svg': {
                                    fontWeight: 700,
                                    color: 'primary.main',
                                },

                                '&:hover': {
                                    backgroundColor: (theme: any) => alpha(theme.palette.primary.main, 0.05),
                                },

                                '&.Mui-selected': {
                                    backgroundColor: (theme: any) => alpha(theme.palette.primary.main, 0.15),
                                },
                            },
                        },
                    }}
                >
                    {data.map((menu) => {
                        return (
                            <TreeItem
                                key={menu.idDreMercadologicaTipoGrupoExibicao}
                                nodeId={menu.idDreMercadologicaTipoGrupoExibicao.toString()}
                                label={menu.dsDreMercadologicaTipoGrupoExibicao}
                            >
                                {menu.gruposExibicao.map((submenu) => {
                                    return (
                                        <TreeItem
                                            key={submenu.idDreMercadologicaGrupoExibicao}
                                            nodeId={`${menu.idDreMercadologicaTipoGrupoExibicao}_${submenu.idDreMercadologicaGrupoExibicao}`}
                                            label={submenu.dsDreMercadologicaGrupoExibicao}
                                        />
                                    );
                                })}
                            </TreeItem>
                        );
                    })}
                </TreeView>
            )}
        </Block>
    );
}
