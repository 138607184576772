import React from 'react';

import * as Yup from 'yup';

import { FullLoading } from 'components/loading';
import { FormPage } from 'components/page';
import { BandaPrecoGeneralBlock } from 'pages/complementar/banda-preco/components/templates/BandaPrecoGeneralBlock';
import { usePostBandaPreco } from 'pages/complementar/banda-preco/hooks';
import { BandaPreco } from 'pages/complementar/banda-preco/types';

export function BandaPrecoFormComp(): JSX.Element {
    const { handleSubmit, isLoading } = usePostBandaPreco();

    const validationSchema = Yup.object({
        dreMercadologicaGrupoExibicao: Yup.object().required().nullable(),
        peBandaPreco: Yup.number().required().nullable(),
        dtInicioVigencia: Yup.string().required().nullable(),
    });

    const mapContentToInitialValues = (data: BandaPreco): BandaPreco => {
        if (data.dtInicioVigencia && !data.dtInicioVigencia?.endsWith('T00:00:00')) {
            data.dtInicioVigencia = `${data.dtInicioVigencia}T00:00:00`;
        }

        return data;
    };

    return (
        <FormPage
            fgAtivo
            goBackButton
            title="Banda de Preço"
            url="/dre/dremercadologicagrupoexibicaobandapreco/"
            values={{}}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            mapContentToInitialValues={mapContentToInitialValues}
        >
            {() => {
                return (
                    <>
                        <FullLoading loading={isLoading} />

                        <BandaPrecoGeneralBlock />
                    </>
                );
            }}
        </FormPage>
    );
}
