import { styled } from '@mui/system';

/**
 * Container para conteúdos vindos de um editor (rich text ou wysiwyg)
 */
export const RichContent = styled('article')(({ theme }) => ({
    'fontSize': '1.071em',
    'position': 'relative',

    '> p:first-of-type, > h1:first-of-type, > h2:first-of-type, > h3:first-of-type, > h4:first-of-type, > h5:first-of-type, > h6:first-of-type': {
        marginTop: 0,
    },

    '> p:last-of-type, > h1:last-of-type, > h2:last-of-type, > h3:last-of-type, > h4:last-of-type, > h5:last-of-type, > h6:last-of-type': {
        marginBottom: 0,
    },

    'img': {
        maxWidth: '100%',
        height: 'auto',
    },

    '.responsive-a4': {
        'width': '100%',
        'height': '0',
        'paddingBottom': '70%',
        'position': 'relative',
        'margin': '8px auto',

        'iframe, object, embed': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    },

    'iframe, object, embed': {
        display: 'block',
        margin: '0 auto',
        maxWidth: '100%',
        maxHeight: '100%',
    },

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1),
    },

    [theme.breakpoints.down('md')]: {
        '& img': {
            display: 'block',
            margin: '15px auto',
        },
    },
}));
