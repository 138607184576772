import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface CentroCustoOptionsProps {
    idCentroCusto: number;
    dsCentroCusto: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function CentroCustoAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Centro de custo"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsCentroCusto}"
            optionStringTemplate="{dsCentroCusto}"
            requestOptions={{
                url: '/gestao/centrocusto',
                sort: [{ property: 'idCentroCusto', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
                limit: 50,
            }}
            isOptionEqualToValue={(option: CentroCustoOptionsProps, value: CentroCustoOptionsProps) => option && option.idCentroCusto === value.idCentroCusto}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
