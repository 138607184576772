import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { LojaProps } from '../types';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { Totalizador } from 'pages/compra/pedido/types';

export function useGetTotalizador(): UseQueryResult<Totalizador> {
    const requestListagem = useRequestListagem();
    const { formattedLoja } = useAplicacaoDataContext();
    const { hasPermission } = usePermissionsContext();
    const { content } = useFormContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const hasLojas = Boolean(Array.isArray(content?.lojas));
    const { fullFilterData, autoLoad } = useListagemContext();
    const filterFuncionario = fullFilterData?.find((filter) => filter.value.property === 'idUsuarioPedido');

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/rascunho/totalizador',
        sort: [{ property: 'vlTotal', direction: 'ASC' }],
        filter: [],
    };

    if (content?.lojas?.length) {
        requestOptions.filter?.push({ property: 'idLoja', operator: 'in', value: content.lojas.map((loja: LojaProps) => loja.idLoja) });
    }

    if (isTipoPedidoFuncionario) {
        if (formattedLoja) {
            requestOptions.filter?.push({ property: 'idLoja', operator: 'in', value: [formattedLoja.idLoja] });
        }

        if (filterFuncionario?.value?.value) {
            requestOptions.filter?.push({ property: 'idUsuarioPedido', operator: 'eq', value: filterFuncionario.value.value.idUsuario });
        }
    }

    return useQuery<Totalizador>(['getTotalizadorRascunhos', requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        cacheTime: 0,
        enabled: hasLojas || (isTipoPedidoFuncionario && !hasPermissionFinalizacaoGeral) || autoLoad,
    });
}
