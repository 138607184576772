import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface CondicoesOptionsProps {
    idCondicaoPagamento: number;
    dsCondicaoPagamento: string;
    cdCondicaoPagamento: string;
}

type CondicaoPagamentoAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'size' | 'label' | 'name'>;

export function CondicaoPagamentoAutoComplete({
    value,
    error,
    multiple = false,
    disabled = false,
    sx,
    onChange = () => {},
    size = 'medium',
    label = 'Condição de Pagamento',
    name
}: CondicaoPagamentoAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{cdCondicaoPagamento} - {dsCondicaoPagamento}"
            optionStringTemplate="<strong>{cdCondicaoPagamento}</strong> - {dsCondicaoPagamento}"
            requestOptions={{
                url: '/gestao/condicaopagamento',
                sort: [{ property: 'idCondicaoPagamento', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
                columns: 'idCondicaoPagamento,dsCondicaoPagamento,cdCondicaoPagamento',
                limit: 50,
            }}
            isOptionEqualToValue={(option: CondicoesOptionsProps, value: CondicoesOptionsProps) => value && option && option.idCondicaoPagamento === value.idCondicaoPagamento}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
            name={name}
        />
    );
}
