import React, { useId } from 'react';

import { Grid, Typography } from '@mui/material';

import { useValidation } from 'hooks/useValidation';

export interface LabeledGridListProps {
    label: string;
    value?: string | number;
}

interface LabeledGridProps {
    list: LabeledGridListProps[];
    showEmpty?: boolean;
    fixed?: boolean;
}

export function LabeledGrid({ list, showEmpty, fixed }: LabeledGridProps): JSX.Element {
    const id = useId();
    const { validValue } = useValidation();

    return (
        <Grid
            container
            spacing={2}
            sx={{
                'justifyContent': 'space-between',
                '& .MuiTypography-root': {
                    whiteSpace: 'nowrap',
                },
                '& .MuiTypography-caption': {
                    display: 'block',
                    mb: 0.5,
                },
            }}
        >
            {list.map(({ label, value }: LabeledGridListProps) => {
                const valid = showEmpty || validValue(value);
                const sx = fixed ? { flex: 1 } : {};
                const width = label === 'Endereço' ? 350 : label === 'Emitente' || label === 'Destinatário' ? 250 : label === 'UF' ? 50 : 160;

                let content = null;

                if (valid) {
                    content = (
                        <Grid item key={label + id} sx={sx} width={width}>
                            <Typography variant="caption">{label}</Typography>
                            <Typography noWrap>{value}</Typography>
                        </Grid>
                    );
                }

                return content;
            })}
        </Grid>
    );
}
