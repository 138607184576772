import React, { useState } from 'react';

import { OneColumn } from 'components/page';
import { LocalFuncionarioGridFilter, LocalFuncionarioGrid, LocalFuncionarioImportModal } from 'pages/complementar/local-funcionario/components';

export function LocalFuncionarioListComp(): JSX.Element {
    const [openImportModal, setOpenImportModal] = useState<boolean>(false);
    return (
        <OneColumn
            title="Locais de Funcionário"
            createButton
            extraButtons={[
                {
                    title: 'Importar',
                    onClick: () => setOpenImportModal(true),
                },
            ]}
        >
            <LocalFuncionarioImportModal open={openImportModal} setOpen={setOpenImportModal} />

            <LocalFuncionarioGridFilter />

            <LocalFuncionarioGrid />
        </OneColumn>
    );
}
