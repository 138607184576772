import React from 'react';


import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHighOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import DragHandleIcon from '@mui/icons-material/DragHandleOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAltOutlined';
import { Box, Paper, Typography, Grid, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Centered } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestCardIndicadorProps, SolicitacaoStatusColor } from 'pages/marketing-trade/trade/solicitacoes-pecas/types';
import { TypeMarketing } from 'pages/marketing-trade/types';
import { brlPercentual, camelCase } from 'util/format';

/**
 * Componente de card para uso nas listagens
 */

const GetIcon = (icon: string): JSX.Element => {
    switch (icon) {
        case 'SOLICITACOES':
            return <ArticleIcon fontSize="small" />;
        case 'REPROVADAS':
            return <CancelIcon fontSize="small" />;
        case 'AGUARDANDO_APROVACAO':
            return <AccessTimeIcon fontSize="small" />;
        case 'EM_ANDAMENTO':
            return <AutoFixHighIcon fontSize="small" />;
        case 'TEMPO_MEDIO':
            return <AccessTimeIcon fontSize="small" />;
        default:
            return <TaskAltIcon fontSize="small" />;
    }
};

const GetPositionArrow = (valorIndicador: number): JSX.Element => {
    if (valorIndicador >= 1) {
        return <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />;
    } else if (valorIndicador <= -1) {
        return <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />;
    }

    return <DragHandleIcon fontSize="small" sx={{ ml: 1 }} />;
};

export function TradeSolicitaoPecasListCardIndicator(): JSX.Element | null {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/solicitacaopeca/indicador',
        sort: [{ property: 'dsIndicador', direction: 'DESC' }],
        columns: 'dsIndicador,qtIndicador,qtAnterior,tpIndicador',
        extraParams: [
            {
                idTipoMarketing: TypeMarketing.TRADE,
            },
        ],
    };

    const { isLoading, data } = useQuery([requestOptions], () => {
        const request: Promise<RequestCardIndicadorProps> = RequestListagem(requestOptions);
        return request;
    });

    return (
        <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }} gap={2} display="flex">
            {isLoading && (
                <Centered>
                    <CircularProgress />
                </Centered>
            )}

            {!isLoading &&
                data?.data?.map((indicador, index) => {
                    const keyEnum = `STATUS_${indicador.idIndicador}` as keyof typeof SolicitacaoStatusColor;
                    const color = SolicitacaoStatusColor[keyEnum];

                    return (
                        <Grid item key={index} flex={1}>
                            <Paper
                                variant="outlined"
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 1,
                                        backgroundColor: color,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {GetIcon(indicador.tpIndicador)}
                                </Box>
                                <Box
                                    sx={{
                                        p: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ mr: 0.5 }}>
                                            <strong>{indicador.qtIndicador}</strong>
                                        </Typography>

                                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                                            {indicador.tpIndicador === 'TEMPO_MEDIO' && 'Dias'} {camelCase(indicador.dsIndicador)}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex' }}>
                                        {brlPercentual(Math.abs(indicador.peIndicador) ?? 0)}
                                        {GetPositionArrow(indicador.peIndicador)}
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    );
                })}
        </Grid>
    );
}
