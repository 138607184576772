import React from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { getIn, useFormikContext } from 'formik';

import { CissNumberField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block } from 'components/page';
import { GrupoExibicaoAutoComplete } from 'pages/complementar/banda-preco/components';
import { BandaPreco } from 'pages/complementar/banda-preco/types';

export function BandaPrecoGeneralBlock(): JSX.Element {
    const { id } = useParams();
    const { errors, setFieldValue, values } = useFormikContext<BandaPreco>();

    return (
        <Block>
            <Row>
                <Grid item flex={1}>
                    <GrupoExibicaoAutoComplete
                        name="dreMercadologicaGrupoExibicao"
                        disabled={Boolean(id)}
                        value={values.dreMercadologicaGrupoExibicao}
                        error={getIn(errors, 'dreMercadologicaGrupoExibicao')}
                        onChange={(e, value) => setFieldValue('dreMercadologicaGrupoExibicao', value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissNumberField
                        label="Banda de preço (%)"
                        name="peBandaPreco"
                        inputType="decimal"
                        maxValue={100}
                        value={values.peBandaPreco}
                        error={errors.peBandaPreco}
                        onChange={(e) => setFieldValue('peBandaPreco', e.target.value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Data de vigência"
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <CissTextField {...params} name="dtInicioVigencia" error={errors.dtInicioVigencia} />}
                            value={values.dtInicioVigencia || null}
                            onChange={(value) => setFieldValue('dtInicioVigencia', value)}
                        />
                    </LocalizationProvider>
                </Grid>
            </Row>
        </Block>
    );
}
