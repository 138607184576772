import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ExtraButtons } from 'components/buttons';
import { ExtraButtonListProps } from 'components/buttons/ExtraButtons';

export interface BbarProps {
    children?: ReactNode;
    isLoading?: boolean;
    extraButtons?: ExtraButtonListProps | ExtraButtonListProps[];
    disablePrimaryBtn?: boolean;
    primaryBtnText?: string;
    primaryBtnHandler?: () => void;
}

export function Bbar({ children, isLoading, extraButtons, disablePrimaryBtn, primaryBtnText = 'Salvar', primaryBtnHandler = () => {} }: BbarProps): JSX.Element {
    return (
        <Box
            sx={{
                position: 'sticky',
                bottom: 0,
                zIndex: 3,
                display: 'flex',
                py: 2,
                px: 4,
                mx: -2,
                backgroundColor: (theme) => theme.palette.grey[100],
            }}
        >
            {children}

            <Box flex={1} />

            {extraButtons && <ExtraButtons list={extraButtons} />}

            <LoadingButton variant="contained" loading={isLoading} disabled={disablePrimaryBtn || isLoading} onClick={() => primaryBtnHandler()} size="large">
                {primaryBtnText}
            </LoadingButton>
        </Box>
    );
}
