import React from 'react';

import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { getIn, useFormikContext } from 'formik';

import { CissNumberField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block } from 'components/page';
import { CepField } from 'pages/cadastro/usuario/components';
import { TLocalFuncionarioForm } from 'pages/complementar/local-funcionario/types';

export function LocalFuncionarioGeneralBlock(): JSX.Element {
    const { errors, values, setFieldValue } = useFormikContext<TLocalFuncionarioForm>();

    return (
        <Block>
            <Row>
                <Grid item flex={1}>
                    <CissNumberField
                        label="Código"
                        name="cdLocalFuncionario"
                        value={values.cdLocalFuncionario}
                        error={errors.cdLocalFuncionario}
                        preventEmptyField={false}
                        onChange={(e) => setFieldValue('cdLocalFuncionario', e.target.value || null)}
                    />
                </Grid>

                <Grid item flex={3}>
                    <CissTextField
                        label="Descrição"
                        name="dsLocalFuncionario"
                        value={values.dsLocalFuncionario}
                        error={errors.dsLocalFuncionario}
                        onChange={(e) => setFieldValue('dsLocalFuncionario', e.target.value || null)}
                    />
                </Grid>
            </Row>

            <Row>
                <Grid item>
                    <FormControlLabel
                        label="Retira"
                        name="fgRetirada"
                        control={<Checkbox />}
                        checked={Boolean(values.fgRetirada)}
                        onChange={(e, value) => {
                            setFieldValue('fgRetirada', value);

                            let dsTransportadora = '';

                            if (value) {
                                setFieldValue('fgFreteGratis', false);
                                setFieldValue('vlFrete', 0);

                                dsTransportadora = 'Retirada';
                            }

                            setFieldValue('dsTransportadora', dsTransportadora);
                        }}
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        label="Frete grátis"
                        name="fgFreteGratis"
                        control={<Checkbox />}
                        disabled={values.fgRetirada}
                        checked={Boolean(values.fgFreteGratis)}
                        onChange={(e, value) => {
                            setFieldValue('fgFreteGratis', value);

                            if (value) {
                                setFieldValue('vlFrete', 0);
                            }
                        }}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissNumberField
                        label="Valor do frete (R$)"
                        name="vlFrete"
                        inputType="decimal"
                        value={values.vlFrete}
                        error={errors.vlFrete}
                        preventEmptyField={false}
                        disabled={values.fgFreteGratis || values.fgRetirada}
                        onChange={(e) => setFieldValue('vlFrete', Number(e.target.value))}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissNumberField
                        label="Previsão de entrega em dias úteis"
                        name="nrDiasPrevisaoEntrega"
                        value={values.nrDiasPrevisaoEntrega}
                        error={errors.nrDiasPrevisaoEntrega}
                        preventEmptyField={false}
                        onChange={(e) => setFieldValue('nrDiasPrevisaoEntrega', e.target.value || null)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="Transportadora"
                        name="dsTransportadora"
                        value={values.dsTransportadora}
                        error={errors.dsTransportadora}
                        onChange={(e) => setFieldValue('dsTransportadora', e.target.value)}
                    />
                </Grid>
            </Row>

            <Row>
                <Grid item flex={1}>
                    <CepField value={values.cep?.nrCep || ''} error={getIn(errors, 'cep.nrCep')} />
                </Grid>

                <Grid item flex={2}>
                    <CissTextField
                        label="Endereço"
                        name="dsEndereco"
                        value={values.dsEndereco}
                        error={errors.dsEndereco}
                        disabled={!values.cep?.cidade?.dsCidade}
                        onChange={(e) => setFieldValue('dsEndereco', e.target.value || null)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissNumberField
                        label="Número"
                        name="nrEndereco"
                        value={values.nrEndereco}
                        error={errors.nrEndereco}
                        disabled={!values.cep?.cidade?.dsCidade}
                        preventEmptyField={false}
                        onChange={(e) => setFieldValue('nrEndereco', e.target.value || null)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="Complemento"
                        name="dsComplemento"
                        value={values.dsComplemento}
                        error={errors.dsComplemento}
                        disabled={!values.cep?.cidade?.dsCidade}
                        onChange={(e) => setFieldValue('dsComplemento', e.target.value || null)}
                    />
                </Grid>
            </Row>

            <Row>
                <Grid item flex={1}>
                    <CissTextField
                        label="Bairro"
                        name="dsBairro"
                        value={values.dsBairro}
                        error={errors.dsBairro}
                        disabled={!values.cep?.cidade?.dsCidade}
                        onChange={(e) => setFieldValue('dsBairro', e.target.value || null)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        disabled
                        label="Estado"
                        name="dsEstado"
                        value={values.cep?.estado?.dsEstado || ''}
                        error={getIn(errors, 'cep.estado')}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        disabled
                        label="Cidade"
                        name="dsCidade"
                        value={values.cep?.cidade?.dsCidade || ''}
                        error={getIn(errors, 'cep.cidade')}
                    />
                </Grid>
            </Row>
        </Block>
    );
}
