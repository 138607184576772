import React from 'react';

import { Chip } from '@mui/material';

interface DreListLojaColumnProp {
    idStatus: number;
    title: string;
}

export function DreListStatusColumn({ idStatus, title }: DreListLojaColumnProp): JSX.Element {
    let color: 'default' | 'error' | 'info' | 'success' | 'warning' = 'default';

    switch (idStatus) {
        // 2 = Em preenchimento
        case 2:
            color = 'info';
            break;
        // 3 = Finalizado
        case 3:
            color = 'success';
            break;
        // 4 = Bloqueado
        case 4:
            color = 'error';
            break;
        // 1 = A preencher
        case 1:
        default:
            color = 'warning';
            break;
    }

    return <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} />;
}
