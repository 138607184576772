import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useGetLocalFuncionario } from 'pages/complementar/local-funcionario/hooks';
import { TLocalFuncionarioList } from 'pages/complementar/local-funcionario/types';
import { brlCep, brlPrice } from 'util/format';

const getRows = (list: TLocalFuncionarioList[] | undefined): GridRowsProp => {
    if (Array.isArray(list) && list.length) {
        return list.map((item) => ({
            id: item.idLocalFuncionario,
            cdLocalFuncionario: item.cdLocalFuncionario,
            dsLocalFuncionario: item.dsLocalFuncionario,
            fgFreteGratis: item.fgFreteGratis,
            nrCep: item.nrCep,
            dsEndereco: item.dsEndereco,
            vlFrete: item.vlFrete,
            nrDiasPrevisaoEntrega: item.nrDiasPrevisaoEntrega,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

const columns: GridColDef[] = [
    { field: 'cdLocalFuncionario', headerName: 'Código', minWidth: 80 },
    { field: 'dsLocalFuncionario', headerName: 'Descrição', flex: 1, minWidth: 120 },
    { field: 'fgFreteGratis', headerName: 'Frete grátis', type: 'boolean', minWidth: 80 },
    { field: 'nrCep', headerName: 'CEP', minWidth: 80, valueFormatter: ({ value }) => (value ? brlCep(value) : '--') },
    { field: 'dsEndereco', headerName: 'Endereço', minWidth: 120, flex: 1, valueFormatter: ({ value }) => value || '--' },
    { field: 'vlFrete', headerName: 'Valor do frete', minWidth: 120, valueFormatter: ({ value }) => (value ? brlPrice(value) : '--') },
    {
        field: 'nrDiasPrevisaoEntrega',
        headerName: 'Previsão de entrega',
        minWidth: 120,
        valueFormatter: ({ value }) => (value ? `${value} dia${value > 1 ? 's' : ''}` : '--'),
    },
    { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean', minWidth: 80 },
    {
        field: 'acoes',
        align: 'center',
        headerAlign: 'center',
        headerName: 'Ações',
        filterable: false,
        sortable: false,
        hideable: false,
        disableColumnMenu: true,
        minWidth: 70,
        renderCell: ({ row }) => <EditRemoveBtn id={row.id} showEditBtn={true} />,
    },
];

export function LocalFuncionarioGrid(): JSX.Element {
    const { isFetching, data, refetch } = useGetLocalFuncionario();

    return (
        <Block>
            <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={isFetching} columns={columns} />
        </Block>
    );
}
