import React from 'react';

import { Typography } from '@mui/material';

const getTimeToDate = (date: string) => {
    const dateNow = new Date();
    const destinyDate = new Date(date);
    const millisecondsDiference = destinyDate.getTime() - dateNow.getTime();

    const daysToDate = Math.floor(millisecondsDiference / (1000 * 60 * 60 * 24));
    const hoursToDate = Math.floor((millisecondsDiference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesToDate = Math.floor((millisecondsDiference % (1000 * 60 * 60)) / (1000 * 60));

    return { daysToDate, hoursToDate, minutesToDate };
};

const getTimeToDateText = (date: string) => {
    const { daysToDate, hoursToDate, minutesToDate } = getTimeToDate(date);
    const daysToDateText = daysToDate ? `${daysToDate} dia(s), ` : '';
    const hoursToDateText = hoursToDate ? `${hoursToDate} hora(s) e ` : '';
    const minutesToDateText = minutesToDate ? `${minutesToDate} minuto(s)` : '';

    return { daysToDateText, hoursToDateText, minutesToDateText };
};

const hasMinutesToDate = (date: string) => {
    const { minutesToDate } = getTimeToDate(date);

    return minutesToDate > 0;
};

export function PedidoGradeLimiteText({ date }: { date: string }) {
    const { daysToDateText, hoursToDateText, minutesToDateText } = getTimeToDateText(date);

    if (!hasMinutesToDate(date)) {
        return null;
    }

    return (
        <Typography sx={{ display: 'inline' }}>
            Faltam{' '}
            <Typography sx={{ display: 'inline', color: (theme) => theme.palette.secondary.main, fontWeight: 500, textAlign: 'center' }}>
                {daysToDateText}
                {hoursToDateText}
                {minutesToDateText}
            </Typography>{' '}
            para a data limite de grade
        </Typography>
    );
}
