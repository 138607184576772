import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Stack } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';

import { CissTextField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { GruposEconomicosProps, RequestGruposEconomicosListProps } from 'pages/cadastro/usuario/types';

interface UsuariosListModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    gruposEconomicos: GruposEconomicosProps[];
}

export function GrupoEconomicoListGridModal({ open, setOpen, gruposEconomicos }: UsuariosListModalProps): JSX.Element | null {
    const RequestListagem = useRequestListagem();
    const { setFieldValue } = useFormikContext();

    const [query, setQuery] = useState<string>('');
    const [gruposEconomicosModal, setGruposEconomicosModal] = useState<GruposEconomicosProps[]>([]);

    const requestOptions = useMemo(
        (): RequestOptionsType => ({
            url: '/gestao/grupoeconomico',
            query,
            sort: [{ property: 'idGrupoEconomico', direction: 'ASC' }],
            columns: 'idGrupoEconomico,dsGrupoEconomico,',
            limit: 50,
        }),
        [query],
    );

    const { isLoading, fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestGruposEconomicosListProps> => RequestListagem(requestOptions).then((result) => result.data));

    const columns = useMemo(
        (): GridColDef[] => [
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: '',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 60,
                renderCell: ({ row }) => {
                    return (
                        <Checkbox
                            checked={!!gruposEconomicosModal.find((user) => user.idGrupoEconomico === row.idGrupoEconomico)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const user = gruposEconomicosModal.find((users) => users.idGrupoEconomico === row.idGrupoEconomico);

                                    if (!user) {
                                        const { dsGrupoEconomico, idGrupoEconomico } = row;
                                        setGruposEconomicosModal((oldState) => [...oldState, { dsGrupoEconomico, idGrupoEconomico }]);
                                    }
                                } else {
                                    const user = gruposEconomicosModal.find((users) => users.idGrupoEconomico === row.idGrupoEconomico);

                                    if (user) {
                                        setGruposEconomicosModal((oldState) => oldState.filter((state) => state.idGrupoEconomico !== user.idGrupoEconomico));
                                    }
                                }
                            }}
                        />
                    );
                },
            },
            { field: 'idGrupoEconomico', headerName: 'Código', type: 'number', minWidth: 80 },
            { field: 'dsGrupoEconomico', headerName: 'Nome Usuário', flex: 1, minWidth: 100 },
        ],
        [gruposEconomicosModal],
    );

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(data?.data) && data?.data.length) {
            return data.data.map((item, index) => ({
                id: index + 1,
                idGrupoEconomico: item.idGrupoEconomico,
                dsGrupoEconomico: item.dsGrupoEconomico,
            }));
        }

        return [];
    }, [data]);

    const handleConfirmModal = useCallback((): void => {
        // Valida se todos os grupos econômicos selecionados anteriormente estão na nova seleção de grupos.
        const hasChangedGrupoEconomicos = gruposEconomicos.some(
            (grupoEconomico) => !gruposEconomicosModal.some((grupoEconomicoModal) => grupoEconomicoModal.idGrupoEconomico === grupoEconomico.idGrupoEconomico),
        );

        setFieldValue('usuarioAcessoGrupoEconomicos', gruposEconomicosModal);

        if (hasChangedGrupoEconomicos) {
            setFieldValue('loja', null);
            setFieldValue('usuarioAcessoLojas', []);
        }

        setOpen(false);
    }, [gruposEconomicosModal]);

    useEffect(() => {
        if (open) {
            setGruposEconomicosModal(gruposEconomicos);
        }
    }, [open]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton
            centered={false}
            sx={{
                maxWidth: 960,
                width: '90vw',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
            }}
        >
            <React.Fragment>
                <SubTitle label="Adicionar Grupos Econômicos" />

                <CissTextField label="Pesquisar" placeholder="Digite um Grupo Econômico..." size="small" sx={{ mb: 2, width: '50%' }} deboundTime={500} onChange={(e) => setQuery(e.target.value)} />

                <Box
                    sx={{
                        height: 'calc(80vh - 215px)',
                        overflow: 'scroll',
                    }}
                >
                    <PanelGrid rows={rows} rowsCount={rows.length ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} visibleRows="all" remote={false} />
                </Box>

                <Stack
                    spacing={2}
                    justifyContent="flex-end"
                    direction="row"
                    sx={{
                        mt: 2,
                    }}
                >
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={handleConfirmModal} loading={isLoading} disabled={isLoading}>
                        Atualizar
                    </LoadingButton>
                </Stack>
            </React.Fragment>
        </ContentModal>
    );
}
