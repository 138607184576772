import React from 'react';

import { Chip, Tooltip } from '@mui/material';

interface DreListLojaColumnProp {
    idStatus: number;
    title: string;
    idPedidoImportacaoLoja: number;
    setOpenModalErros: (action: boolean) => void;
    setIdPedidoImportacaoLoja: (id: number) => void;
}

export function PedidoLoteCompraSituacaoColumn({ idStatus, title, idPedidoImportacaoLoja, setOpenModalErros, setIdPedidoImportacaoLoja }: DreListLojaColumnProp): JSX.Element {
    let color: 'default' | 'error' | 'info' | 'success' | 'warning' = 'default';

    if (idStatus === 4) {
        return (
            <Tooltip title="Visualizar erros">
                <Chip
                    label={title}
                    variant="outlined"
                    size="small"
                    color="error"
                    sx={{ width: '100%' }}
                    onClick={() => {
                        setOpenModalErros(true);
                        setIdPedidoImportacaoLoja(idPedidoImportacaoLoja);
                    }}
                />
            </Tooltip>
        );
    }

    switch (idStatus) {
        // 2 = Em preenchimento
        case 2:
            color = 'info';
            break;
        // 3 = Finalizado
        case 3:
            color = 'success';
            break;
        // 1 = A preencher
        case 1:
        case 5:
        default:
            color = 'warning';
            break;
    }

    return <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} />;
}
