import React, { useCallback, useEffect, useRef, useState } from 'react';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { LoadingButton } from '@mui/lab';
import { Typography, Box, Stack, Button } from '@mui/material';

import { UploadModalProps } from '../types';

import { CissTextField } from 'components/fields/';
import { Imagem } from 'components/images';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { UploadButton } from 'components/upload/';
import { useMimeTypes } from 'hooks/useMimeTypes';

/**
 * Componente de Upload de Imagens,
 */
export function UploadModal({ callback, setOpen, open, loading, accept }: UploadModalProps): JSX.Element {
    const [file, setFile] = useState<null | File>(null);
    const [dsTitulo, setDsTitulo] = useState('');
    const [errorTitulo, setErrorTitulo] = useState('');
    const [errorFile, setErrorFile] = useState<JSX.Element | null>(null);
    const [preview, setPreview] = useState<JSX.Element | null>(null);
    const { getFileNameWithoutExtension } = useMimeTypes();

    const inputDsTitulo = useRef<any>(null);

    const resetErrors = (): void => {
        setErrorTitulo('');
        setErrorFile(null);
    };

    const handleSubmit = useCallback(() => {
        resetErrors();

        if (file && dsTitulo) {
            callback({ file, dsTitulo });
            setDsTitulo('');
        }

        if (!file) {
            setErrorFile(
                <Typography color="error" sx={{ fontSize: '0.8rem', ml: 1.5, mt: 0.5 }}>
                    Selecione um arquivo para continuar.
                </Typography>,
            );
        }

        if (!dsTitulo) {
            setErrorTitulo('Campo obrigatório');

            if (inputDsTitulo.current) {
                inputDsTitulo.current.focus();
            }
        }
    }, [file, dsTitulo, callback]);

    const getImagePreview = (selectedFile: any): JSX.Element => {
        const type = selectedFile.type.split('/')[0];

        let previewImg = null;

        if (type === 'image') {
            previewImg = (
                <Box sx={{ mt: 2 }}>
                    <Imagem src={URL.createObjectURL(selectedFile)} style={{ maxHeight: 250, margin: '0 auto' }} />
                </Box>
            );
        } else {
            previewImg = (
                <Box sx={{ mt: 2, backgroundColor: 'grey.200', p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.7rem' }}>
                    <InsertDriveFileOutlinedIcon sx={{ display: 'block', mb: 0.5 }} />
                    {selectedFile.name.split('.').at(-1)}
                </Box>
            );
        }

        return previewImg;
    };


    const handlerFileChange = (selectedFile: any): void => {
        const imagePreview = selectedFile ? getImagePreview(selectedFile) : null;
        const imageName =  selectedFile?.name;
        const newDsTitulo = imageName ? getFileNameWithoutExtension(imageName) : '';

        resetErrors();

        setDsTitulo(newDsTitulo);

        setFile(selectedFile);

        setPreview(imagePreview);
    };

    useEffect(() => {
        setPreview(null);
        resetErrors();
        setDsTitulo('');
        setFile(null);
    }, [open]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton
            sx={{
                width: 400,
                borderRadius: 1,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <SubTitle label="Adicionar Arquivo" />

                <CissTextField
                    ref={inputDsTitulo}
                    disabled={loading}
                    onChange={(e) => setDsTitulo(e.target.value)}
                    value={dsTitulo}
                    error={Boolean(errorTitulo)}
                    helperText={errorTitulo}
                    label={'Nome do arquivo'}
                    sx={{
                        mt: 0,
                        mb: 2,
                    }}
                />

                <UploadButton disabled={loading} onChange={handlerFileChange} accept={accept} />

                {errorFile}
                {preview}

                <Stack
                    spacing={2}
                    justifyContent="flex-end"
                    direction="row"
                    sx={{
                        mt: 3,
                    }}
                >
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" loading={loading} disabled={loading} onClick={handleSubmit}>
                        Adicionar Arquivo
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
