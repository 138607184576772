import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Typography, Grid, List, ListItem, Link } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useDomainConfigContext } from 'context/DomainConfig';

const SocialIcon = (value?: string): JSX.Element | null => {
    switch (value) {
        case 'youtube':
            return <YouTubeIcon sx={{ mr: 1 }} />;
        case 'facebook':
            return <FacebookIcon sx={{ mr: 1 }} />;
        case 'instagram':
            return <InstagramIcon sx={{ mr: 1 }} />;
        default:
            return null;
    }
};

export function MenuFooter(): JSX.Element {
    const { data } = useDomainConfigContext();
    const footerBlocks = data.footer || [];

    return (
        <Grid container>
            <React.Fragment>
                {footerBlocks.map(({ dsTitle, links }) => (
                    <List
                        dense
                        disablePadding
                        key={`footer_block_${dsTitle}`}
                        sx={{
                            flex: 1,
                            display: { xs: 'none', md: 'block' },
                            maxWidth: { md: 240 },
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        <Typography
                            sx={{
                                px: 2,
                                color: 'common.white',
                                mb: { xs: 2, md: 0 },
                            }}
                            variant="h6"
                            component="li"
                        >
                            {dsTitle.toUpperCase()}
                        </Typography>

                        {links.map(({ dsTitle, dsLink, dsIcon }) => {
                            const icon = SocialIcon(dsIcon);

                            return (
                                <ListItem key={`footer_link_${dsTitle}`} sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
                                    {dsLink.startsWith('http') ? (
                                        <Link href={dsLink} target="_blank" color="inherit" sx={{ display: 'flex' }}>
                                            {icon} {dsTitle}
                                        </Link>
                                    ) : (
                                        <RouterLink to={dsLink}>
                                            {icon} {dsTitle}
                                        </RouterLink>
                                    )}
                                </ListItem>
                            );
                        })}
                    </List>
                ))}
            </React.Fragment>
        </Grid>
    );
}
