import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

import { CissMaskField } from 'components/fields/';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface EstadoProps {
    idEstado: number;
    dsEstado: string;
    sgEstado: string;
    sgEstadoName: string;
}

interface CidadeProps {
    idCidade: number;
    dsCidade: string;
    estado: EstadoProps;
    nrIbge: number;
    sgEstado: string;
}

export interface CepProps {
    cidade: CidadeProps;
    dsBairro: string;
    dsCidade: string;
    dsEndereco: string;
    estado: EstadoProps;
    idCep: number;
    nrCep: string;
    dsEstado: string;
}

interface RequestListagemType extends RequestListagemProps {
    data: CepProps;
}

interface CepFieldProps {
    updateFields?: boolean;
    onLoad?: (cep: CepProps) => void;
    onChange?: (value: string) => void;
    value?: string;
    error?: string;
    name?: string;
    disabled?: boolean;
}

export function CepField({
    error,
    disabled,
    value = '',
    name = 'cep',
    updateFields = true,
    onLoad = () => {},
    onChange = () => {},
}: CepFieldProps): JSX.Element {
    const { setFieldValue } = useFormikContext();
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [cep, setCep] = useState<string>(value);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/cep/findByNrCep',
        extraParams: [
            {
                nrCep: cep,
            },
        ],
    };

    const cleanFields = (value: string | null): void => {
        if (updateFields && (!value || value.length < 8)) {
            setFieldValue('dsBairro', null);
            setFieldValue('dsEndereco', null);
            setFieldValue('dsComplemento', null);
            setFieldValue('nrEndereco', null);
            setFieldValue('cep', { nrCep: value });
        }
    };

    const { isFetching } = useQuery<RequestListagemType>([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: cep.length === 8,
        onSuccess: ({ data }) => {
            if (!Object.keys(data).length) {
                enqueueSnackbar('CEP informado é inválido', { variant: 'warning' });

                cleanFields(cep);
            } else if (updateFields) {
                const { dsBairro, dsEndereco } = data;

                setFieldValue('dsComplemento', null);
                setFieldValue('nrEndereco', null);
                setFieldValue('dsBairro', dsBairro);
                setFieldValue('dsEndereco', dsEndereco);
                setFieldValue('cep', data);
            }

            onLoad(data);
        },
        onError: () => enqueueSnackbar('Falha ao processar solicitação do CEP', { variant: 'error' }),
    });

    return (
        <CissMaskField
            format="cep"
            label="CEP"
            disabled={isFetching || disabled}
            value={value}
            name={name}
            error={error}
            onChange={(e) => {
                const newValue = e.target.value;

                if (value !== newValue) {
                    setCep(newValue);

                    cleanFields(newValue);

                    onChange(newValue);
                }
            }}
        />
    );
}
