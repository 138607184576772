import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Typography } from '@mui/material';

import { UploadButton } from 'components/upload';

interface ImportFileBtn {
    file: File | null;
    setFile: Dispatch<SetStateAction<File | null>>;
    accept?: string | string[];
}

export function ImportFileBtn({ setFile, file, accept = '*' }: ImportFileBtn): JSX.Element {
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputFileRef.current && !file) {
            inputFileRef.current.value = '';
        }
    }, [file]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start', width: { xs: '100%', md: 'auto' } }}>
            <Box sx={{ display: 'flex', minWidth: { xs: '100%', md: 'auto' }, flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                <UploadButton label="Selecionar arquivo" accept={accept} inputRef={inputFileRef} onChange={(file) => setFile(file)} />

                {file && (
                    <Button variant="outlined" color="error" startIcon={<DeleteOutlineIcon />} onClick={() => setFile(null)}>
                        Limpar seleção
                    </Button>
                )}
            </Box>

            {file && <Typography variant="caption">{file.name}</Typography>}

            {Array.isArray(accept) && (
                <Typography variant="caption" component="p">
                    *São aceitos somente arquivos em formato {accept.join(', ')}
                </Typography>
            )}
        </Box>
    );
}
