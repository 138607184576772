import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { Totalizador } from 'pages/compra/pedido/types';

export function useGetTotalizador(): UseQueryResult<Totalizador> {
    const { loja, setTotalizador } = usePedidoCompraContext();
    const requestListagem = useRequestListagem();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const idLoja = loja?.idLoja;

    const requestOptionsTotalizadorRascunhos: RequestOptionsType = {
        url: '/compra/pedidocompra/rascunho/totalizador',
        sort: [{ property: 'vlTotal', direction: 'ASC' }],
        filter: [{ property: 'idLoja', operator: 'in', value: [idLoja] }],
    };

    return useQuery<Totalizador>(
        ['getTotalizadorRascunhos', requestOptionsTotalizadorRascunhos],
        () => requestListagem(requestOptionsTotalizadorRascunhos).then((res) => res.data),
        {
            enabled: Boolean(loja) && isTipoPedidoPadrao,
            onSuccess: (response) => setTotalizador(response),
        },
    );
}
