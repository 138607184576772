import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { NotaProdutoDetailComp } from './components';

export function NotaProdutoDetail(): JSX.Element {
    return (
        <ListagemProvider>
            <NotaProdutoDetailComp />
        </ListagemProvider>
    );
}
