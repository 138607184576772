import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function GrupoEconomicoListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsGrupoEconomico',
        operator: 'like',
        value: '',
    });

    const [filterCodigo, setFilterCodigo] = useState<FilterValuesProps>({
        property: 'cdGrupoEconomico',
        operator: 'eq',
        value: '',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: hasPermission(['GRUPO_ECONOMICO_MODERAR']) ? null : true,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterCodigo, setter: setFilterCodigo },
        { value: filterStatus, setter: setFilterStatus },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField label="Título" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="Código Grupo Econômico" value={filterCodigo.value} onChange={(e) => setFilterCodigo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            {hasPermission(['GRUPO_ECONOMICO_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
}
