import React from 'react';

import { DetalhamentoCreditosImportacaoComp } from 'pages/financeiro/detalhamento-creditos/importar/components';
import { usePermissionsContext } from 'context/PermissionsContext';

export function DetalhamentoCreditosImportacao(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DETALHAMENTO_CREDITO_VISUALIZAR'])) return alertNotPermission();

    return <DetalhamentoCreditosImportacaoComp />;
}
