import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { LocalFuncionarioListComp, LocalFuncionarioFormComp } from 'pages/complementar/local-funcionario/components';

export function LocalFuncionarioList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_LOCAL_FUNCIONARIO'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <LocalFuncionarioListComp />
        </ListagemProvider>
    );
}

export function LocalFuncionarioForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_LOCAL_FUNCIONARIO'])) return alertNotPermission();

    return (
        <FormProvider>
            <LocalFuncionarioFormComp />
        </FormProvider>
    );
}
