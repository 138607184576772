import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { OneColumn } from 'components/page';
import { PedidoIframes } from 'pages/pedido/components/templates/PedidoIframes';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { Centered } from 'components/grid';
import { Alert, CircularProgress } from '@mui/material';

export function PedidoGradeComp(): JSX.Element {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/embeded/consultagradeurl',
    };

    const { isLoading, data, isError } = useQuery(
        [requestOptions.url],
        (): Promise<string> => {
            return RequestListagem(requestOptions).then((res) => res.data);
        },
        {
            onError: (error: { data?: string }) => {
                if (error && error.data) {
                    setErrorMessage(error.data);
                }
            },
        },
    );

    return (
        <OneColumn title="Pedidos de Compra">
            {isLoading && (
                <Centered>
                    <CircularProgress />
                </Centered>
            )}

            {isError && errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            {data && <PedidoIframes title="Pedido" src={data} />}
        </OneColumn>
    );
}
