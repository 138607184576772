import React from 'react';

import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { CissNumberField } from 'components/fields';
import { Row } from 'components/form';
import { Block } from 'components/page';
import { ConfiguracaoGeral } from 'pages/revendedor/configuracao-geral/types';

export function ConfiguracaoGeralGeneralBlock(): JSX.Element {
    const { values, errors, setFieldValue } = useFormikContext<ConfiguracaoGeral>();

    return (
        <Block>
            <Row>
                <Grid item flex={1}>
                    <CissNumberField
                        label="Valor mínimo do desconto"
                        name="vlMinimoDesconto"
                        inputType="decimal"
                        preventEmptyField={false}
                        value={values.vlMinimoDesconto}
                        error={errors.vlMinimoDesconto}
                        onChange={(e) => setFieldValue('vlMinimoDesconto', e.target.value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissNumberField
                        label="Percentual de desconto"
                        name="peDesconto"
                        inputType="decimal"
                        forceMaxValue
                        maxValue={100}
                        preventEmptyField={false}
                        value={values.peDesconto}
                        error={errors.peDesconto}
                        onChange={(e) => setFieldValue('peDesconto', e.target.value)}
                    />
                </Grid>
            </Row>
        </Block>
    );
}
