import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Alert, CircularProgress } from '@mui/material';

import { OneColumn } from 'components/page';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

import { RequestFppNotaProdutoDetalhamentoModelProps } from 'pages/financeiro/extrato/fpp-espelho-nota-produto/types';
import { FppEspelhoNotaProdutoDetalhamento, FppEspelhoNotaProdutoGrid, FppEspelhoNotaProdutoResumoFinanceiro } from 'pages/financeiro/extrato/fpp-espelho-nota-produto/components';
import { Centered } from 'components/grid';

export function FppEspelhoNotaProdutoDetailComp(): JSX.Element {
    const [searchParams] = useSearchParams();
    const RequestListagem = useRequestListagem();

    const cdLoja = searchParams.get('cdLoja');
    const nrNota = searchParams.get('nrNota');
    const dtFiltro = searchParams.get('dtFiltro');
    const validParams = Boolean(cdLoja && nrNota && dtFiltro);

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/fpp/notadetalhada',
        columns: 'fpp',
        nested: true,
        filter: [
            { property: 'cdLoja', value: cdLoja, operator: 'eq' },
            { property: 'nrNota', value: nrNota, operator: 'eq' },
            { property: 'dtFiltro', value: dtFiltro, operator: 'eq' },
        ],
    };

    const { data, fetchStatus, isError, isLoading } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestFppNotaProdutoDetalhamentoModelProps> = RequestListagem(requestOptions).then((result) => result.data.data[0]);
            return request;
        },
        { enabled: validParams },
    );

    const requestConfigOptions: RequestOptionsType = {
        url: '/config/configgeral/chave',
        extraParams: [{ dsChave: 'VL_PRECO_BASE_FATURAMENTO' }],
    };

    const { data: configVlBaseFaturamento } = useQuery([requestConfigOptions], () => {
        const request: Promise<string> = RequestListagem(requestConfigOptions).then((result) => result.data);
        return request;
    });

    const detalhamentoListData = { ...data?.nota, ...data?.pedido };

    return (
        <OneColumn title="Consulta de FPP - Detalhamento de nota" goBackButton>
            {isLoading && fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress sx={{ my: 2 }} />
                </Centered>
            )}

            {!isLoading && !validParams && <Alert severity="error">Verifique se a URL informada é valida.</Alert>}

            {!isLoading && isError && <Alert severity="error">Falha na recuperação dos dados.</Alert>}

            {data && (
                <React.Fragment>
                    {detalhamentoListData && <FppEspelhoNotaProdutoDetalhamento detalhamento={detalhamentoListData} />}

                    {data.produtos && configVlBaseFaturamento && <FppEspelhoNotaProdutoGrid produtos={data.produtos} configVlBaseFaturamento={configVlBaseFaturamento} />}

                    {data.nota && data.titulo && <FppEspelhoNotaProdutoResumoFinanceiro nota={data.nota} titulo={data.titulo} />}
                </React.Fragment>
            )}
        </OneColumn>
    );
}
