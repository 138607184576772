import React, { useState } from 'react';

import { OneColumn } from 'components/page';
import { PedidoCompraTotalizadorGrid, PedidoCompraTotalizadorGridFilter, PedidoCompraTotalizadorQuebras } from 'pages/compra/pedido-totalizador/components';
import { PedidoCompraTotalizadorQuebrasExportarModal } from 'pages/compra/pedido-totalizador/components/templates/PedidoCompraTotalizadorQuebrasExportarModal';

export function PedidoCompraTotalizadorListComp(): JSX.Element {
    const [openModalExportar, setOpenModalExportar] = useState(false);

    return (
        <React.Fragment>
            <PedidoCompraTotalizadorQuebrasExportarModal open={openModalExportar} setOpen={setOpenModalExportar} />

            <OneColumn
                title="Pedidos Totalizadores"
                extraButtons={[
                    {
                        title: 'Exportar',
                        onClick: () => setOpenModalExportar(true),
                    },
                ]}
                goBackButton
                createButton={false}
            >
                <PedidoCompraTotalizadorGridFilter />

                <PedidoCompraTotalizadorQuebras />

                <PedidoCompraTotalizadorGrid />
            </OneColumn>
        </React.Fragment>
    );
}
