import React from 'react';

import { useParams } from 'react-router-dom';

import { FormProvider } from 'context/FormContext';
import { LikesProvider } from 'context/LikeContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import {
    MarketingTradeCampanhaListComp,
    MarketingTradeCampanhaForm,
    MarketingTradeDetailComp,
    MarketingTradeEdicaoPecasComp,
    MarketingTradeFormComp,
    MarketingTradeListComp,
} from 'pages/marketing-trade/components';

export function MarketingDetail(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['MARKETING_PECA_VISUALIZAR'])) return alertNotPermission();

    return (
        <LikesProvider>
            <MarketingTradeDetailComp isMarketing />
        </LikesProvider>
    );
}

export function MarketingList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['MARKETING_PECA_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <MarketingTradeListComp isMarketing />
        </ListagemProvider>
    );
}

export function MarketingForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('MARKETING_PECA', id)) return alertNotPermission();

    return (
        <FormProvider>
            <MarketingTradeFormComp isMarketing />
        </FormProvider>
    );
}

export function MarketingEdicaoPecas(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['MARKETING_PECA_ALTERAR']) && !hasPermission(['MARKETING_PECA_CADASTRAR'])) return alertNotPermission();

    return <MarketingTradeEdicaoPecasComp isMarketing />;
}

export function MarketingCampanhaList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['MARKETING_CAMPANHA_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <MarketingTradeCampanhaListComp isMarketing />
        </ListagemProvider>
    );
}

export function MarketingCampanhaForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('MARKETING_CAMPANHA', id)) return alertNotPermission();

    return (
        <FormProvider>
            <MarketingTradeCampanhaForm isMarketing />
        </FormProvider>
    );
}
