import React from 'react';

import { RecebiveisComp } from './components';

import { usePermissionsContext } from 'context/PermissionsContext';

export function RecebiveisPage(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['FINANCEIRO_RECEBIVEIS_VISUALIZAR'])) return alertNotPermission();

    return <RecebiveisComp />;
}
