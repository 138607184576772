import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';

import { brlPrice } from 'util/format';
import { OneColumn, Block } from 'components/page';
import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { ExtratoFPPProps, RequestExtratoFPPListModelProps } from 'pages/financeiro/extrato/fpp/types';
import { PanelGridEspelhoFPP, FormFilterExtratoFPP, RelatorioFaturamentoFppBtn } from '..';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

const generateExtratoFPPList = (data: ExtratoFPPProps[] | undefined): GridRowsProp => {
    const rows: any = [];

    if (Array.isArray(data) && data.length) {
        data.forEach((item, index) => {
            const { fpp, notas } = item;
            const { nrMes, nrAno, nrQuinzena, marca, loja } = notas[0];

            rows.push({
                id: index + 1,
                nrMes: nrMes,
                nrAno: nrAno,
                nrQuinzena: nrQuinzena,
                dsTipoNota: fpp.tipoNota,
                dsMarca: marca.dsMarca,
                cdLoja: loja.cdLoja,
                vlFpp: 0,
                sumFpp: fpp.vlFpp, // coluna usada no sumario, somente soma os totais e não individual por nota
                nrFpp: fpp.nrFpp,
                featured: true,
            });

            notas.forEach((nota, indexNota) => {
                rows.push({
                    id: `row_${index}_${indexNota + 1}`,
                    nrMes: nota.nrMes,
                    nrAno: nota.nrAno,
                    nrQuinzena: nota.nrQuinzena,
                    dsTipoNota: nota.tipoNota,
                    dsMarca: nota.marca.dsMarca,
                    cdLoja: nota.loja.cdLoja,
                    vlFpp: nota.vlFpp,
                    nrFpp: fpp.nrFpp,
                    sumFpp: 0, // coluna usada no sumario, somente soma os totais e não individual por nota
                    nrNotaFiscal: nota.nrNotaFiscal,
                });
            });
        });
    }

    return rows;
};

export function ExtratoFppListComp(): JSX.Element {
    const { filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/fpp/extrato',
        columns: 'idExtratoFPP',
        nested: true,
        filter,
    };

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestExtratoFPPListModelProps> = RequestListagem(requestOptions).then((res: any) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const columns: GridColDef[] = [
        { field: 'nrMes', headerName: 'Mês', width: 100, type: 'number' },
        { field: 'nrAno', headerName: 'Ano', width: 100, type: 'number' },
        { field: 'nrQuinzena', headerName: 'Quinzena', width: 120, type: 'number' },
        { field: 'dsTipoNota', headerName: 'Tipo de ND', width: 150 },
        { field: 'dsMarca', headerName: 'Marca', flex: 1, minWidth: 100 },
        { field: 'nrNotaFiscal', headerName: 'NF relacionada', flex: 1, minWidth: 130, type: 'number' },
        { field: 'cdLoja', headerName: 'Loja', flex: 1, minWidth: 100 },
        {
            field: 'vlFpp',
            headerName: 'Valor FPP',
            width: 130,
            type: 'number',
            renderCell: ({ row }) => {
                if (row.vlFpp) {
                    return brlPrice(row.vlFpp);
                }

                return '';
            },
        },
        {
            field: 'sumFpp',
            headerName: 'Total FPP',
            width: 130,
            type: 'number',
            renderCell: ({ row }) => {
                if (row.sumFpp) {
                    return brlPrice(row.sumFpp);
                }

                return '';
            },
        },
        { field: 'nrFpp', headerName: 'Doc. contábil FPP', flex: 1, minWidth: 100 },
        {
            field: 'dsEspelho',
            align: 'center',
            headerAlign: 'center',
            headerName: '',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            renderCell: (params) => <PanelGridEspelhoFPP rowData={params.row} />,
        },
    ];

    const rows = generateExtratoFPPList(data?.data);

    return (
        <OneColumn title="FPP - Extrato e Relatório">
            <FormFilterExtratoFPP />

            <Block>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                    <RelatorioFaturamentoFppBtn disabled={rows.length <= 0} />
                </Stack>

                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} summary={['sumFpp']} visibleRows="all" />
            </Block>
        </OneColumn>
    );
}
