import React, { ReactNode } from 'react';

import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface NotaProdutoGridTooltipProps {
    children: ReactNode;
    tooltip: string;
}

export function NotaProdutoGridTooltip({ children, tooltip }: NotaProdutoGridTooltipProps): JSX.Element {
    return (
        <Tooltip title={tooltip} placement="top">
            <Box
                sx={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25em',
                    fontWeight: 500,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                }}
            >
                {children}*
            </Box>
        </Tooltip>
    );
}
