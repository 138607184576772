import React, { Dispatch, SetStateAction } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useGetCarrinho, usePostClonePedido } from 'pages/compra/pedido-realizado/hooks';
import { ConfirmModalProps, RowProps } from 'pages/compra/pedido-realizado/types';

type PedidoRealizadoCloneBtn = {
    rowProps: RowProps;
    isDisabledActions: boolean;
    setIsDisabledActions: Dispatch<SetStateAction<boolean>>;
    setIsOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
    setDataConfirmModal: Dispatch<SetStateAction<ConfirmModalProps | null>>;
};

export function PedidoRealizadoCloneBtn({
    isDisabledActions,
    setIsDisabledActions,
    setIsOpenConfirmModal,
    setDataConfirmModal,
    rowProps,
}: PedidoRealizadoCloneBtn): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const { idLoja, idConfigPedido } = rowProps;
    const { refetch: refetchCart, isFetching: isFetchingCart } = useGetCarrinho({ idConfigPedido, idLoja });
    const { handleClone, isLoadingClonar } = usePostClonePedido({ rowData: rowProps, setIsDisabledActions });

    const openCloneConfirmation = (): void => {
        setIsDisabledActions(true);

        refetchCart({ throwOnError: true })
            .then((res) => {
                setIsOpenConfirmModal(true);

                let text;

                if (res?.data?.produtos && res.data.produtos.length > 0) {
                    text = 'Já existe um rascunho para o pedido selecionado. Deseja clonar o pedido e substituir o rascunho atual?';
                } else {
                    text = 'Não existe um rascunho para o pedido selecionado. Deseja clonar o pedido?';
                }

                setDataConfirmModal({ callback: handleClone, text });
            })
            .catch(() => enqueueSnackbar('Não foi possível verificar o rascunho do pedido. Tente novamente', { variant: 'error' }))
            .finally(() => setIsDisabledActions(false));
    };

    return (
        <LoadingButton
            color="inherit"
            onClick={openCloneConfirmation}
            disabled={isDisabledActions}
            loading={isFetchingCart || isLoadingClonar}
            startIcon={<ContentCopyIcon />}
        >
            Clonar
        </LoadingButton>
    );
}
