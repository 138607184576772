import React, { ReactNode, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { Paper, Skeleton, Stack, Typography, Box } from '@mui/material';

import { EditRemoveBtn, LikeBtn, ViewBtn } from 'components/buttons';
import { InactiveBox } from 'components/content';
import { Tag, TagGroup } from 'components/text';
import { removeHtmlTags } from 'util/format';

export interface ListBoxItemProps {
    id: number;
    label: string;
    type?: string;
    date?: string;
    tags?: string[];
    cover?: string;
    content?: string;
    likes?: number;
    liked?: boolean;
    views?: number;
    active?: boolean;
    refresh?: () => void;
    removeUrl?: string;
    cloneUrl?: string;
    showEditBtn?: boolean;
    showCloneBtn?: boolean;
    showRemoveBtn?: boolean;
    showView?: boolean;
    showLike?: boolean;
    DontShowDetail?: boolean;
    idClone?: string;
    extraContent?: ReactNode;
}

export function ListBoxSkeleton(): JSX.Element {
    return (
        <Box sx={{ display: 'flex', p: 2 }}>
            <Skeleton variant="rectangular" width={135} height={90} />
            <Box sx={{ ml: 3, width: '50%' }}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Box>
        </Box>
    );
}

export function ListBoxItem({
    id,
    active,
    label,
    content,
    type,
    date,
    tags,
    cover,
    likes = 0,
    liked = false,
    views = 0,
    refresh,
    removeUrl,
    cloneUrl,
    showEditBtn = false,
    showCloneBtn = false,
    showRemoveBtn = false,
    showView = true,
    showLike = true,
    DontShowDetail = false,
    idClone,
    extraContent,
}: ListBoxItemProps): JSX.Element {
    const navigate = useNavigate();

    const handleDetail = useCallback((): void => {
        cover && DontShowDetail === false ? navigate(id.toString()) : navigate(`form/${id}`);
    }, [navigate, id]);

    return (
        <InactiveBox active={active}>
            <Paper>
                <Box
                    sx={{
                        display: {
                            sm: 'flex',
                        },
                        flexWrap: 'wrap',
                        p: 1.5,
                    }}
                >
                    {cover && (
                        <Box
                            onClick={handleDetail}
                            title={label}
                            className="box-img-preview"
                            sx={{
                                cursor: 'pointer',
                                minWidth: 135,
                                minHeight: {
                                    xs: 130,
                                    md: 90,
                                },
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundImage: `url("${cover}")`,
                                borderRadius: 1,
                                mr: {
                                    xs: 0,
                                    sm: 2,
                                },
                                mb: {
                                    xs: 2,
                                    sm: 0,
                                },
                            }}
                        />
                    )}

                    <Box
                        sx={{
                            flex: 1,
                            display: {
                                md: 'flex',
                            },
                            flexDirection: {
                                md: 'row',
                            },
                        }}
                    >
                        <Stack
                            title={label}
                            onClick={handleDetail}
                            spacing={0.25}
                            sx={{
                                cursor: 'pointer',
                                flex: 1,
                                mr: {
                                    md: 2,
                                },
                            }}
                        >
                            <Typography variant="h6" sx={{ color: 'common.black' }}>
                                {label.toUpperCase()}
                            </Typography>

                            {type && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'primary.main',
                                        pt: {
                                            xs: 1.4,
                                            md: 0,
                                        },
                                    }}
                                >
                                    {type.toUpperCase()}
                                </Typography>
                            )}

                            {date && (
                                <Typography variant="body2" sx={{ color: 'primary.main' }}>
                                    {date}
                                </Typography>
                            )}

                            {Array.isArray(tags) && tags.length > 0 && (
                                <TagGroup max={3}>
                                    {tags.map((tag) => (
                                        <Tag label={tag} key={Math.random()} />
                                    ))}
                                </TagGroup>
                            )}

                            {content && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'primary.main',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        pt: {
                                            xs: 1.4,
                                            md: 0,
                                        },
                                    }}
                                >
                                    {removeHtmlTags(content)}
                                </Typography>
                            )}
                        </Stack>

                        <Box
                            sx={{
                                alignSelf: 'flex-end',
                            }}
                        >
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    mt: {
                                        xs: 1.5,
                                        md: 0,
                                    },
                                }}
                            >
                                {showLike && <LikeBtn likes={likes} liked={liked} />}
                                {showView && <ViewBtn views={views} />}
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {(showEditBtn || showRemoveBtn || showCloneBtn || extraContent) && (
                    <EditRemoveBtn
                        id={id}
                        refresh={refresh}
                        sx={{ ml: 'auto', p: 1.5 }}
                        showEditBtn={showEditBtn}
                        showCloneBtn={showCloneBtn}
                        showRemoveBtn={showRemoveBtn}
                        cloneUrl={cloneUrl}
                        idClone={idClone}
                        removeUrl={removeUrl}
                        extraContent={extraContent}
                    />
                )}
            </Paper>
        </InactiveBox>
    );
}
