import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { EventoModelProps, RequestEventoListModelProps } from '../../types';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { EventoListFilter } from 'pages/calendario/evento/components/templates/EventoListFilter';

const generateEventoList = (data: EventoModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idCalendarioEvento: item.idCalendarioEvento,
            dsCalendarioEvento: item.dsCalendarioEvento,
            dsCalendarioEventoCategoria: item.dsCalendarioEventoCategoria,
            dhInicio: item.dhInicio,
            dhFim: item.dhFim,
            dsTextoLink: item.dsTextoLink,
            dsUrlLink: item.dsUrlLink,
        }));
    }

    return [];
};

export function EventoListComp(): JSX.Element {
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/calendarioevento',
            sort: sort.length ? sort : [{ property: 'idCalendarioEvento', direction: 'DESC' }],
            columns: 'idCalendarioEvento,dsConteudo,idCalendarioEventoCategoria,dsCalendarioEvento,dhInicio,dhFim,dsTextoLink,dsUrlLink',
            limit,
            page,
            filter,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestEventoListModelProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const columns: GridColDef[] = useMemo(() => {
        const cols: GridColDef[] = [
            { field: 'idCalendarioEvento', headerName: 'Id Evento', minWidth: 70 },
            { field: 'tpCalendarioEvento', headerName: 'Categoria Evento', flex: 1, minWidth: 110 },
            { field: 'dsCalendarioEvento', headerName: 'Título Evento', flex: 2, minWidth: 90 },
            { field: 'dhInicio', headerName: 'Data Início', flex: 1, minWidth: 90, valueFormatter: ({ value }) => dateFns.format(new Date(value), 'dd/MM/yyyy') },
            { field: 'dhFim', headerName: 'Data Fim', flex: 1, minWidth: 90, valueFormatter: ({ value }) => dateFns.format(new Date(value), 'dd/MM/yyyy') },
        ];

        if (hasPermission(['CALENDARIO_EVENTO_PERSONALIZADO_GERENCIAR'])) {
            cols.push({
                field: 'acoes',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => (
                    <EditRemoveBtn
                        id={row.idCalendarioEvento}
                        refresh={refetch}
                        removeUrl={`gestao/calendarioevento/${row.idCalendarioEvento}`}
                        showEditBtn={true}
                        sx={{ p: 1.5 }}
                    />
                ),
            });
        }

        return cols;
    }, [hasPermission, refetch]);

    const rows = generateEventoList(data?.data);

    return (
        <OneColumn title="Listagem de Eventos " createButton goBackButton="/calendario">
            <EventoListFilter />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
