import React from 'react';

import { TabelaPrecoListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';

export function TabelaPrecoList(): JSX.Element {
    return (
        <ListagemProvider>
            <FormProvider>
                <TabelaPrecoListComp />
            </FormProvider>
        </ListagemProvider>
    );
}
