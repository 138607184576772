import React from 'react';
import * as dateFns from 'date-fns';

import { Chip } from '@mui/material';

interface TituloListStatusColumnProp {
    status: string;
}

export function TituloListStatusColumn({ status }: TituloListStatusColumnProp): JSX.Element {
    let color: 'default' | 'error' | 'info' | 'success' | 'warning' = 'default';
    let title = '';

    switch (status) {
        case 'PAGO':
            color = 'success';
            title = 'Liquidado';
            break;
        case 'VENCIDO':
            color = 'error';
            title = 'Vencido';
            break;
        default:
            color = 'info';
            title = 'À Vencer';
            break;
    }

    return <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} />;
}
