import { createSearchParams, useNavigate } from 'react-router-dom';

import { GridRowModel } from '@mui/x-data-grid-pro';

import { useGetDomainConfig } from './useGetDomainConfig';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePermissionsContext } from 'context/PermissionsContext';

type ReturnUseNavigateToPedido = {
    navigateToPedido: (data: GridRowModel) => void;
};

export function useNavigateToPedido(): ReturnUseNavigateToPedido {
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { usuario } = useAplicacaoDataContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    const navigateToPedido = (rowData: GridRowModel): void => {
        const { dsNomeFantasia, cdLoja, idLoja, tipoEntrega, usuarioPedido, configPedido } = rowData;
        const {
            idConfigPedido,
            dsConfigPedido,
            vlPedidoMinimoLoja,
            configPedidoRegraValidacao,
            tpControlePedidoMinimoLoja,
            idTipoControleEstoquePedido,
        } = configPedido;
        const hasUsuarioTipoEntregaAmbos = usuarioPedido.idTipoEntrega === -1; // Seleciona ambos tipo de entrega
        const isUsuarioPedidoLogged = usuarioPedido.idUsuario === usuario?.idUsuario;
        const includeParamUsuario = isTipoPedidoFuncionario && hasPermissionFinalizacaoGeral && !isUsuarioPedidoLogged;
        const includeParamTipoEntrega = isTipoPedidoFuncionario && tipoEntrega && hasUsuarioTipoEntregaAmbos;

        navigate({
            pathname: '/compra/pedido',
            search: createSearchParams({
                idLoja: JSON.stringify({
                    idLoja,
                    cdLoja,
                    dsNomeFantasia,
                }),
                idConfigPedido: JSON.stringify({
                    idConfigPedido,
                    dsConfigPedido,
                    vlPedidoMinimoLoja,
                    configPedidoRegraValidacao,
                    tpControlePedidoMinimoLoja,
                    idTipoControleEstoquePedido,
                }),
                ...(includeParamUsuario && { idUsuarioPedido: JSON.stringify(usuarioPedido) }),
                ...(includeParamTipoEntrega && { idTipoEntrega: JSON.stringify(tipoEntrega) }),
            }).toString(),
        });
    };

    return { navigateToPedido };
}
