import React from 'react';

import { TradeSolicitacoesPecaFormComp, TradeSolicitacaoPecasListComp, TradeSolicitacaoPecaMensagemFormComp } from './components';

import { ListagemProvider } from 'context/ListagemContext';
import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function TradeSolicitacoesPecasList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['TRADE_SOLICITACAO_PECA_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <TradeSolicitacaoPecasListComp />
        </ListagemProvider>
    );
}

export function TradeSolicitacoesPecasForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['TRADE_SOLICITACAO_PECA_SOLICITAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <TradeSolicitacoesPecaFormComp />
        </FormProvider>
    );
}
export function TradeSolicitacaoPecaMensagemForm(): JSX.Element {
    return (
        <FormProvider>
            <TradeSolicitacaoPecaMensagemFormComp />
        </FormProvider>
    );
}
