import React, { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import { MensagemPadraoAutoComplete } from 'components/autocompletes';
import { ContentModal } from 'components/modal';
import { MensagemPadrao } from 'pages/marketing-trade/trade/solicitacoes-pecas/types';

interface TradeSolicitacaoPecaMensagemPadraoModal {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export function TradeSolicitacaoPecaMensagemPadraoModal({ open, setOpen }: TradeSolicitacaoPecaMensagemPadraoModal): JSX.Element {
    const { setFieldValue } = useFormikContext();
    const navigate = useNavigate();
    const [mensagemPadrao, setMensagemPadrao] = useState<MensagemPadrao>();

    const onClickSelecionar = (): void => {
        setFieldValue('mensagem.dsMensagem', mensagemPadrao?.dsMensagemPadrao);
        setOpen(false);
    };

    return (
        <ContentModal open={open} setOpen={setOpen} closeButton sx={{ borderRadius: 1, p: 2.5 }}>
            <Stack mt={2}>
                <MensagemPadraoAutoComplete value={mensagemPadrao} onChange={(e, value) => setMensagemPadrao(value)} />

                <Stack direction="row" justifyContent="flex-end" mt={2} gap={2}>
                    <Button variant="contained" color="primary" onClick={() => navigate('/trade/solicitacoes-pecas/mensagem-padrao')}>
                        Nova Mensagem
                    </Button>

                    <Button sx={{ ml: 'auto' }} variant="outlined" color="primary" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>

                    <Button variant="contained" color="primary" disabled={!mensagemPadrao} onClick={onClickSelecionar}>
                        Selecionar
                    </Button>
                </Stack>
            </Stack>
        </ContentModal>
    );
}
