import { useSnackbar } from 'notistack';

import { getErrorMessage } from 'util/error';

interface useShowErrorProps {
    showError: (error: unknown, fallback?: string) => void;
}

export function useShowError(): useShowErrorProps {
    const { enqueueSnackbar } = useSnackbar();

    const showError = (error: unknown, fallback?: string): void => {
        const messages = getErrorMessage(error);

        if (!messages.length && fallback) {
            messages.push(fallback);
        }

        messages.forEach((message) => {
            if (message) {
                enqueueSnackbar(message, { variant: 'error' });
            }
        });
    };

    return { showError };
}
