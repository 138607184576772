import React, { ReactNode } from 'react';

import { Typography } from '@mui/material';

interface PedidoProdutoModalInfoDescricao {
    children: ReactNode;
}

export function PedidoProdutoModalInfoDescricao({ children }: PedidoProdutoModalInfoDescricao): JSX.Element {
    return (
        <Typography
            sx={{
                lineHeight: 1,
                fontSize: '1.6rem',
                display: 'flex',
                gap: 0.3,

                span: {
                    fontSize: '0.8rem',
                    paddingTop: '2px',
                },
            }}
        >
            {children}
        </Typography>
    );
}
