import React, { useState } from 'react';

import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

import { MenuSystemItem } from 'components/menu/system/components/MenuSystemItem';
import { ChangeEmpresaLogadaModal } from 'components/modal';
import { ListagemProvider } from 'context/ListagemContext';

export function MenuSystemItemChangeStore(): JSX.Element {
    const [openEmpresaModal, setOpenEmpresaModal] = useState(false);

    return (
        <>
            <MenuSystemItem text="Trocar Empresa" icon={<CachedOutlinedIcon fontSize="small" />} onClick={() => setOpenEmpresaModal(true)} />

            <ListagemProvider>
                <ChangeEmpresaLogadaModal open={openEmpresaModal} setOpen={setOpenEmpresaModal} />
            </ListagemProvider>
        </>
    );
}
