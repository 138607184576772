import React, { Dispatch, SetStateAction, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, CircularProgress, Grid, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { CardBoxItem, CardBoxSkeleton, ListBoxItem, ListBoxItemProps, ListBoxSkeleton } from 'components/content';
import { Centered } from 'components/grid';
import { MenuTreeViewFilter } from 'components/menu/menutreeviewfilter';
import { MenuTreeViewItem } from 'components/menu/menutreeviewfilter/types';
import { OneColumn, Block } from 'components/page';
import { ListPagination } from 'components/pagination';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { FilterValuesProps } from 'hooks/useFilter';
import { useListMode } from 'hooks/useListMode';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { MarketingTradeListFilter } from 'pages/marketing-trade/components';
import { GestaoCampanhaMarketingAninhadaResponse, MarketingTag, TradeTag, MarketingTradePage, RequestCampanhaMarketingStoreProps, getTypeMarketing } from 'pages/marketing-trade/types';
import { getErrorMessage } from 'util/error';

const generateMarketingList = (data: RequestCampanhaMarketingStoreProps | undefined, isMarketing: boolean): ListBoxItemProps[] => {
    const list = data?.data?.data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => {
            let tags = [];

            if (isMarketing) {
                tags = Array.isArray(item.pecaMarketingTagsPecaMarketing) ? item.pecaMarketingTagsPecaMarketing.map((pecaMarketingTag: MarketingTag) => pecaMarketingTag.dsTagPecaMarketing) : [];
            } else {
                tags = Array.isArray(item.pecaTradeTagsPecaTrade) ? item.pecaTradeTagsPecaTrade.map((pecaMarketingTag: TradeTag) => pecaMarketingTag.dsTagPecaTrade) : [];
            }

            return {
                id: item.idPecaMarketing,
                label: item.dsPecaMarketing,
                active: item.fgAtivo,
                date: item.dhInclusao && dateFns.format(new Date(item.dhInclusao), 'dd/MM/yyyy'),
                tags,
                cover: item.dsLinkCapa,
                content: item?.dsConteudo,
                likes: item.qtCurtida,
                liked: item.fgUsuarioCurtiu,
                views: item.qtVisualizacao,
            };
        });
    }

    return [];
};

const mapContentToTreeView = (item: GestaoCampanhaMarketingAninhadaResponse): MenuTreeViewItem<GestaoCampanhaMarketingAninhadaResponse> => ({
    nodeId: item.idCampanhaMarketing.toString(),
    idsFilhos: item.idsFilhos.map((i) => i.toString()),
    label: item.dsCampanhaMarketing,
    rightLabel: item.qtPecas.toString(),
    submenus: item.subCampanhasMarketing.map((sub) => mapContentToTreeView(sub)),
});

export function MarketingTradeListComp({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const { page, setPage, filter, limit, autoLoad } = useListagemContext();
    const filterEl = useRef<{ setFilterCampanha: Dispatch<SetStateAction<FilterValuesProps>>; filterCampanha: FilterValuesProps } | null>(null);
    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();
    const { mode } = useListMode();
    const { hasPermission } = usePermissionsContext();
    const { isDownMd: isMobile } = useBreakpoints();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/pecamarketing/lista',
        filter,
        sort: [{ property: 'dhInclusao', direction: 'DESC' }],
        page,
        limit,
    };

    const { isLoading, data, fetchStatus, isSuccess, isError, error, refetch } = useQuery([requestOptions], (): Promise<RequestCampanhaMarketingStoreProps> => RequestListagem(requestOptions), {
        enabled: autoLoad,
    });

    const marketingList = generateMarketingList(data, isMarketing);
    const [requestError] = getErrorMessage(error);

    return (
        <OneColumn
            title={`Peças de ${isMarketing ? 'Marketing' : 'Trade'}`}
            createButton={hasPermission([isMarketing ? 'MARKETING_PECA_CADASTRAR' : 'TRADE_PECA_CADASTRAR'])}
            listType={!isMobile}
            extraButtons={[
                {
                    title: 'Gerenciar Campanhas',
                    onClick: () => navigate('campanha'),
                    visible: hasPermission([isMarketing ? 'MARKETING_CAMPANHA_GERENCIAR' : 'TRADE_CAMPANHA_GERENCIAR']),
                },
            ]}
        >
            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <MenuTreeViewFilter<GestaoCampanhaMarketingAninhadaResponse>
                        title="Campanhas"
                        url="/gestao/campanhamarketing/aninhada"
                        setFilter={filterEl?.current?.setFilterCampanha}
                        filter={filterEl.current?.filterCampanha}
                        mapContentToTreeView={mapContentToTreeView}
                        extraParams={[
                            {
                                fgAtivo: true,
                            },
                            {
                                idTipoMarketing: getTypeMarketing(isMarketing),
                            },
                        ]}
                    />
                </Grid>

                <Grid item md={9}>
                    <MarketingTradeListFilter ref={filterEl} isMarketing={isMarketing} />

                    {isError && requestError && <Alert severity="error">{requestError}</Alert>}

                    {!marketingList.length && isSuccess && <Alert severity="warning">Sem registros para exibir.</Alert>}

                    <Block filled={false} sx={{ p: 0 }}>
                        {(mode === 'grid' || isMobile) && (
                            <Grid container spacing={2}>
                                {isLoading &&
                                    fetchStatus === 'fetching' &&
                                    Array.from(new Array(limit)).map(() => (
                                        <Grid item xs={12} sm={6} md={4} key={Math.random()}>
                                            <CardBoxSkeleton />
                                        </Grid>
                                    ))}

                                {Array.isArray(marketingList) &&
                                    marketingList.map((pecaMarketing) => (
                                        <Grid item xs={12} sm={6} md={4} key={pecaMarketing.id}>
                                            <CardBoxItem
                                                showEditBtn={hasPermission([isMarketing ? 'MARKETING_PECA_ALTERAR' : 'TRADE_PECA_ALTERAR'])}
                                                showCloneBtn={hasPermission([isMarketing ? 'MARKETING_PECA_ALTERAR' : 'TRADE_PECA_ALTERAR'])}
                                                showRemoveBtn={hasPermission([isMarketing ? 'MARKETING_PECA_EXCLUIR' : 'TRADE_PECA_EXCLUIR'])}
                                                idClone="idPecaMarketing"
                                                refresh={refetch}
                                                removeUrl={`gestao/pecamarketing/${pecaMarketing.id}`}
                                                cloneUrl={`/gestao/pecamarketing/${pecaMarketing.id}/clonar`}
                                                key={pecaMarketing.id}
                                                {...pecaMarketing}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        )}

                        {mode === 'list' && !isMobile && (
                            <Stack spacing={2}>
                                {isLoading && fetchStatus === 'fetching' && Array.from(new Array(limit)).map(() => <ListBoxSkeleton key={Math.random()} />)}

                                {Array.isArray(marketingList) &&
                                    marketingList.map((pecaMarketing) => (
                                        <ListBoxItem
                                            showEditBtn={hasPermission([isMarketing ? 'MARKETING_PECA_ALTERAR' : 'TRADE_PECA_ALTERAR'])}
                                            showCloneBtn={hasPermission([isMarketing ? 'MARKETING_PECA_ALTERAR' : 'TRADE_PECA_ALTERAR'])}
                                            showRemoveBtn={hasPermission([isMarketing ? 'MARKETING_PECA_EXCLUIR' : 'TRADE_PECA_EXCLUIR'])}
                                            idClone="idPecaMarketing"
                                            refresh={refetch}
                                            removeUrl={`gestao/pecamarketing/${pecaMarketing.id}`}
                                            cloneUrl={`/gestao/pecamarketing/${pecaMarketing.id}/clonar`}
                                            key={pecaMarketing.id}
                                            {...pecaMarketing}
                                        />
                                    ))}
                            </Stack>
                        )}
                    </Block>

                    <ListPagination data={data} list={marketingList} limit={limit} page={page} setPage={setPage}  />
                </Grid>
            </Grid>
        </OneColumn>
    );
}
