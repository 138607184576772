import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { TipoQuantidadeMaximaProdutoAutoComplete } from 'components/autocompletes';
import { CissNumberField } from 'components/fields';
import { Row } from 'components/form';
import { Centered } from 'components/grid';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFocusErrorFormik } from 'hooks/useFocusErrorFormik';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { ValueChangeListener } from 'pages/compra/configuracao-pedido/components';
import { RegrasValidacaoProps, RequestRegrasValidacaoProps, initialRegrasValidacaoValues } from 'pages/compra/configuracao-pedido/types';

export function ConfiguracaoPedidoCompraRegrasValidacao(): JSX.Element {
    const { isActiveStepSaved, setActiveStepSaved } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { mutate, isLoading: mutateLoading } = useFormMutation();
    const RequestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const { focusError } = useFocusErrorFormik();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const [formData, setFormData] = useState<RegrasValidacaoProps>(initialRegrasValidacaoValues);

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: `/gestao/configpedidoregravalidacao/configpedido/${id}`,
        }),
        [id],
    );

    const { isLoading: getLoading, fetchStatus } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestRegrasValidacaoProps> = RequestListagem(requestOptions);

            return request;
        },
        {
            enabled: Boolean(id),
            onSuccess: (response) => {
                const { data } = response;

                if (data) {
                    if (typeof data.peOferta !== 'number') {
                        data.peOferta = 0;
                        data.fgUtilizaDescontoCadastroLoja = true;
                    }

                    if (typeof data.vlBaseFreteGratis === 'number') {
                        data.fgUtilizaVlBaseFreteGratis = true;
                    }

                    setFormData(data);
                }
            },
        },
    );

    const onSubmitForm = (values: RegrasValidacaoProps, formik: FormikHelpers<RegrasValidacaoProps>): void => {
        values.idConfigPedido = Number(id);
        values.peOferta = values.fgUtilizaDescontoCadastroLoja ? null : values.peOferta ?? 0;
        values.vlBaseFreteGratis = values.fgUtilizaVlBaseFreteGratis ? values.vlBaseFreteGratis : null;

        mutate(
            {
                url: '/gestao/configpedidoregravalidacao',
                idField: isActiveStepSaved() ? 'idConfigPedidoRegraValidacao' : '',
                formData: values,
                formik,
                preventRedirect: true,
                preventSnack: true,
            },
            {
                onSuccess: ({ data }: any) => {
                    enqueueSnackbar('Regras de Validação salvo com sucesso', { variant: 'success' });

                    if (!isActiveStepSaved()) {
                        const { idConfigPedidoRegraValidacao } = data.data;

                        setFormData({ ...values, idConfigPedidoRegraValidacao });
                    }

                    setActiveStepSaved();
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                peOferta: Yup.number().max(100),
                qtPedidosVisualizar: Yup.number().max(2147483647),
            }),
        [],
    );

    return (
        <Formik initialValues={formData} onSubmit={onSubmitForm} validationSchema={validationSchema} enableReinitialize>
            {(formik: FormikProps<RegrasValidacaoProps>) => {
                focusError(formik.errors);

                return (
                    <Form>
                        {getLoading && fetchStatus === 'fetching' && (
                            <Centered>
                                <CircularProgress sx={{ my: 2 }} />
                            </Centered>
                        )}

                        <FullLoading loading={mutateLoading} />

                        <Block>
                            <SubTitle label="Regras" />

                            <Row>
                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Percentual de oferta"
                                        name="peOferta"
                                        size="small"
                                        inputType="decimal"
                                        maxValue={100}
                                        disabled={formik.values.fgUtilizaDescontoCadastroLoja}
                                        value={formik.values.peOferta || 0}
                                        onChange={(e) => formik.setFieldValue('peOferta', Number(e.target.value))}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Quantidade de pedidos à visualizar"
                                        name="qtPedidosVisualizar"
                                        size="small"
                                        maxValue={2147483647}
                                        value={formik.values.qtPedidosVisualizar}
                                        onChange={(e) => formik.setFieldValue('qtPedidosVisualizar', Number(e.target.value))}
                                    />
                                </Grid>

                                {isTipoPedidoFuncionario && (
                                    <Grid item flex={1}>
                                        <CissNumberField
                                            label="Valor base para frete grátis"
                                            name="vlBaseFreteGratis"
                                            inputType="decimal"
                                            size="small"
                                            value={formik.values.vlBaseFreteGratis}
                                            disabled={!formik.values.fgUtilizaVlBaseFreteGratis}
                                            onChange={(e) => formik.setFieldValue('vlBaseFreteGratis', Number(e.target.value))}
                                        />
                                    </Grid>
                                )}
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Habilitar Regras" />

                            <FormGroup>
                                {isTipoPedidoFuncionario && (
                                    <FormControlLabel
                                        label={'Utilizar valor base para frete grátis'}
                                        name="fgUtilizaVlBaseFreteGratis"
                                        control={<Checkbox />}
                                        checked={formik.values.fgUtilizaVlBaseFreteGratis}
                                        onChange={(e, checked) => {
                                            formik.setFieldValue('fgUtilizaVlBaseFreteGratis', checked);
                                            formik.setFieldValue('vlBaseFreteGratis', 0);
                                        }}
                                    />
                                )}

                                <FormControlLabel
                                    label={'Utilizar desconto do cadastro de loja'}
                                    name="fgUtilizaDescontoCadastroLoja"
                                    control={<Checkbox />}
                                    checked={formik.values.fgUtilizaDescontoCadastroLoja}
                                    onChange={(e, checked) => {
                                        formik.setFieldValue('fgUtilizaDescontoCadastroLoja', checked);
                                        formik.setFieldValue('peOferta', 0);
                                    }}
                                />

                                <FormControlLabel
                                    label={'Validar quantidade mínima e quantidade do múltiplo do produto'}
                                    name="fgValidaQuantidadeMinimaMultiploProduto"
                                    control={<Checkbox />}
                                    checked={formik.values.fgValidaQuantidadeMinimaMultiploProduto}
                                    onChange={(e, checked) => formik.setFieldValue('fgValidaQuantidadeMinimaMultiploProduto', checked)}
                                />

                                <FormControlLabel
                                    label={'Exibir sugestão por produto'}
                                    name="fgExibeSugestaoProduto"
                                    control={<Checkbox />}
                                    checked={formik.values.fgExibeSugestaoProduto}
                                    onChange={(e, checked) => formik.setFieldValue('fgExibeSugestaoProduto', checked)}
                                />

                                <FormControlLabel
                                    label={'Requer aprovação do pedido'}
                                    name="fgRequerAprovacaoPedido"
                                    control={<Checkbox />}
                                    checked={formik.values.fgRequerAprovacaoPedido}
                                    onChange={(e, checked) => formik.setFieldValue('fgRequerAprovacaoPedido', checked)}
                                />

                                {isTipoPedidoPadrao && (
                                    <FormControlLabel
                                        label={'Validar inadimplência'}
                                        name="fgValidaInadimplencia"
                                        control={<Checkbox />}
                                        checked={formik.values.fgValidaInadimplencia}
                                        onChange={(e, checked) => formik.setFieldValue('fgValidaInadimplencia', checked)}
                                    />
                                )}
                            </FormGroup>

                            <TipoQuantidadeMaximaProdutoAutoComplete
                                sx={{ width: { md: '50%' }, paddingTop: 1 }}
                                name="tipoControleQuantidadeMaximaProduto"
                                error={formik.errors.tipoControleQuantidadeMaximaProduto}
                                value={formik.values.tipoControleQuantidadeMaximaProduto}
                                onChange={(e, value) => formik.setFieldValue('tipoControleQuantidadeMaximaProduto', value)}
                            />
                        </Block>

                        <Box
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                zIndex: 3,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2,
                                mx: -2,
                                backgroundColor: (theme) => theme.palette.grey[100],
                            }}
                        >
                            <LoadingButton variant="contained" loading={mutateLoading} disabled={mutateLoading} type="submit" size="large">
                                Salvar
                            </LoadingButton>
                        </Box>

                        <ValueChangeListener />
                    </Form>
                );
            }}
        </Formik>
    );
}
