export interface MotivoDevolucaoProps {
    idMotivoDevolucao: number;
    dsMotivoDevolucao: string;
    fgAtivo: boolean;
    cdMotivo: string;
}

export interface RequestMotivoDevolucaoListProps {
    total: number;
    data: MotivoDevolucaoProps[];
}

export interface RequestMotivoDevolucaoFormProps {
    data: {
        data: MotivoDevolucaoProps;
    };
}

export const initialMotivoDevolucaoFormValues: Omit<MotivoDevolucaoProps, 'cdMotivo'> = {
    idMotivoDevolucao: -1,
    dsMotivoDevolucao: '',
    fgAtivo: true,
};
