import React, { ReactNode } from 'react';

import { Paper, PaperProps } from '@mui/material';

interface BlockProps extends PaperProps {
    children?: ReactNode;
    filled?: boolean;
}

interface defaultBlockSxProps {
    [key: string]: number | string | boolean;
}

const getBlockSxDefaultConfig = (filled: boolean): defaultBlockSxProps => {
    const blockSx: defaultBlockSxProps = {
        my: 2,
        p: 2,
    };

    if (!filled) {
        blockSx.backgroundColor = 'transparent';
    }

    return blockSx;
};

const getBlockOptionsDefaultConfig = (filled: boolean): PaperProps => {
    let options: PaperProps = {
        variant: 'elevation',
        elevation: 2,
    };

    if (!filled) {
        options = {
            elevation: 0,
        };
    }

    return options;
};

/**
 * Bloco padrão para utilização dentro de páginas
 * @param props
 * @returns
 */
export function Block({ sx, children, filled = true, ...PaperProps }: BlockProps): JSX.Element {
    const defaultOptions = getBlockOptionsDefaultConfig(filled);
    const defaultSx = getBlockSxDefaultConfig(filled);

    return (
        <Paper {...defaultOptions} sx={{ ...defaultSx, ...sx }} {...PaperProps}>
            {children}
        </Paper>
    );
}
