import React from 'react';

import { Tooltip, Typography } from '@mui/material';

interface DreListLojaColumnProp {
    title: string;
}

export function DreListLojaColumn({ title }: DreListLojaColumnProp): JSX.Element {
    return (
        <Tooltip title={title}>
            <Typography sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</Typography>
        </Tooltip>
    );
}
