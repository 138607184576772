import React, { useCallback, useMemo, useRef } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { GradeAutoComplete, StoreAutoComplete, MesFaturamentoAutoComplete } from 'components/autocompletes';
import { CissTextField, Editor } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { RequestGradeModelProps } from 'pages/logistica/grade/types';


const getDateIsoString = (date?: Date | string | null): string => {
    let dateNow = new Date();

    if (date && typeof date === 'string') {
        dateNow = new Date(date);
    } else if (date && date !== 'string') {
        dateNow = date as Date;
    }

    return dateNow.toISOString().replace(/\.\d{3}Z$/, '');
};

export function GradeFormComp(): JSX.Element {
    const urlSave = useRef('/gestao/grade/save');
    const urlUpdate = useRef('/gestao/grade/');
    const [searchParams] = useSearchParams();
    const idLoja = searchParams.get('idLoja');
    const idTipoGrade = searchParams.get('idTipoGrade');

    const { mutate } = useFormMutation();
    const { setContent, setLoading } = useFormContext();
    const { id } = useParams();

    const validationNewGrade = useMemo(
        () =>
            Yup.object({
                loja: Yup.object().nullable().required(),
                tipoGrade: Yup.object().required().nullable(),
                mesFaturamento: Yup.string().required().nullable(),
                dtColeta: Yup.string().required().nullable(),
                dtEntrega: Yup.string().required().nullable(),
                dtLimitePedido: Yup.string().required().nullable(),
                dhInicioEntrega: Yup.string().required().nullable(),
                dhFimEntrega: Yup.string().required().nullable(),
                dsObservacao: Yup.string().nullable(),
            }),
        [],
    );

    const initialValues: RequestGradeModelProps = useMemo(
        () => ({
            dtColeta: getDateIsoString(),
            dtEntrega: getDateIsoString(),
            dtLimitePedido: getDateIsoString(),
            dsObservacao: '',
            loja: idLoja ? JSON.parse(idLoja) : null,
            tipoGrade: idTipoGrade ? JSON.parse(idTipoGrade) : null,
        }),
        [idLoja, idTipoGrade],
    );

    const onSubmitGrade = (values: RequestGradeModelProps, formik: FormikHelpers<RequestGradeModelProps>): void => {
        setLoading(true);

        if (values.dhInicioEntrega && typeof values.dhInicioEntrega !== 'string') {
            const dhInicioEntrega = new Date(values.dhInicioEntrega);

            values.dhInicioEntrega = format(dhInicioEntrega, "yyyy-MM-dd'T'HH:mm:ss");
        }

        if (values.dhFimEntrega && typeof values.dhFimEntrega !== 'string') {
            const dhFimEntrega = new Date(values.dhFimEntrega);

            values.dhFimEntrega = format(dhFimEntrega, "yyyy-MM-dd'T'HH:mm:ss");
        }

        mutate(
            {
                url: id ? `${urlUpdate.current}${id}` : urlSave.current,
                method: id ? 'PUT' : 'POST',
                formData: id ? values : [values],
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response?.data || {};

                    setContent(data);
                },
                onSettled: () => {
                    setLoading(false);
                },
            },
        );
    };

    const mapContent = useCallback((content: RequestGradeModelProps) => {
        return {
            ...content,
            dtLimitePedido: getDateIsoString(content.dtLimitePedido),
            dtFaturamento: getDateIsoString(content.dtFaturamento),
            dtEntrega: getDateIsoString(content.dtEntrega),
            dtColeta: getDateIsoString(content.dtColeta),
        };
    }, []);

    return (
        <FormPage title={'Grade'} values={initialValues} onSubmit={onSubmitGrade} validationSchema={validationNewGrade} url="/gestao/grade/" mapContentToInitialValues={mapContent}>
            {(formik: FormikProps<RequestGradeModelProps>) => (
                <React.Fragment>
                    <Block>
                        <Row>
                            <Grid item flex={1}>
                                <StoreAutoComplete
                                    name="loja"
                                    flagInstitucional
                                    multiple={false}
                                    defaultStore={!id && !idLoja}
                                    value={formik.values.loja || null}
                                    error={formik.errors.loja}
                                    onChange={(e, value) => {
                                        formik.setFieldValue('loja', value);
                                    }}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <GradeAutoComplete
                                    name="tipoGrade"
                                    multiple={false}
                                    value={formik.values.tipoGrade || null}
                                    error={formik.errors.tipoGrade}
                                    onChange={(e, value) => {
                                        formik.setFieldValue('tipoGrade', value);
                                    }}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <MesFaturamentoAutoComplete
                                    value={formik.values.mesFaturamento || null}
                                    error={formik.errors.mesFaturamento}
                                    onChange={(e, value) => formik.setFieldValue('mesFaturamento', value)}
                                />
                            </Grid>
                        </Row>

                        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                            <Row>
                                <Grid item flex={1}>
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <CissTextField name="dtLimitePedido" {...params} label={'Data limite do pedido'} error={formik.errors.dtLimitePedido} />}
                                        value={formik.values.dtLimitePedido}
                                        onChange={(value) => {
                                            formik.setFieldValue('dtLimitePedido', value);
                                        }}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <CissTextField name="dtColeta" {...params} label={'Data de coleta'} error={formik.errors.dtColeta} />}
                                        value={formik.values.dtColeta}
                                        onChange={(value) => {
                                            formik.setFieldValue('dtColeta', value);
                                        }}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <CissTextField name="dtEntrega" {...params} label={'Data de entrega'} error={formik.errors.dtEntrega} />}
                                        value={formik.values.dtEntrega}
                                        onChange={(value) => {
                                            formik.setFieldValue('dtEntrega', value);
                                        }}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <TimePicker
                                        renderInput={(params) => <CissTextField {...params} name="dhInicioEntrega" label={'Hora de início da entrega'} error={formik.errors.dhInicioEntrega} />}
                                        value={formik.values.dhInicioEntrega || null}
                                        onChange={(value) => formik.setFieldValue('dhInicioEntrega', value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <TimePicker
                                        renderInput={(params) => <CissTextField {...params} name="dhFimEntrega" label={'Hora de fim da entrega'} error={formik.errors.dhFimEntrega} />}
                                        value={formik.values.dhFimEntrega || null}
                                        onChange={(value) => formik.setFieldValue('dhFimEntrega', value)}
                                    />
                                </Grid>
                            </Row>
                        </LocalizationProvider>
                    </Block>

                    <Block>
                        <SubTitle label={'Observação'} />

                        <Grid item xs={12}>
                            <Editor value={formik.values.dsObservacao} onChangeText={(text: string) => formik.setFieldValue('dsObservacao', text)} />
                        </Grid>
                    </Block>
                </React.Fragment>
            )}
        </FormPage>
    );
}
