import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Grid, FormControlLabel, Radio, Typography, Checkbox, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ptBR } from 'date-fns/locale';
import { Field, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CategoryFieldFormik } from '..';

import { StoreImportAutoComplete } from 'components/autocompletes';
import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { Editor, CissTextField } from 'components/fields';
import { CityFieldFormik } from 'components/fields/CityFieldFormik';
import { StateFieldFormik } from 'components/fields/StateFieldFormik';
import { StoreProfileFieldFormik } from 'components/fields/StoreProfileFieldFormik';
import { StoreTypeFieldFormik } from 'components/fields/StoreTypeFieldFormik';
import { Row } from 'components/form';
import { FormPage, Block } from 'components/page';
import { SubTitle } from 'components/text';
import { Upload } from 'components/upload';
import { UploadFileProps } from 'components/upload/types';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { ComunicadoTagFieldFormik } from 'pages/comunicado/components/atoms/ComunicadoTagFieldFormik';
import { initialComunicadosValues, RequestComunicadosModelProps } from 'pages/comunicado/types';

export function ComunicadoFormComp(): JSX.Element {
    // const contentLimit = 12000; // TODO desabilitado até ajuste tratativa drag/ctr+v imagens no editor
    const [comunicateTo, setComunicateTo] = useState<'lojas' | 'estados' | 'cidades'>('lojas');
    const [fileCapa, setFileCapa] = useState<UploadFileProps[]>([]);
    const [fileNormal, setFileNormal] = useState<UploadFileProps[]>([]);
    const { setLoading, setContent, content } = useFormContext();

    const url = '/gestao/comunicado/';

    const { mutate: mutateComunicado, isLoading } = useFormMutation();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();

    const uploadAcceptExtensions = ['.pdf', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.xls', '.xlsx', '.tiff'];

    const handleSubmit = (values: RequestComunicadosModelProps, formik: FormikHelpers<RequestComunicadosModelProps>): void => {
        // TODO desabilitado até ajuste tratativa drag/ctr+v imagens no editor
        // const errors = [];

        // reseta arquivos
        values.comunicadoArquivos = [];

        // TODO desabilitado até ajuste tratativa drag/ctr+v imagens no editor
        // conteúdo (dsConteudo)
        // > validamos se conteído possui limite e é valido
        // if (contentLimit && contentLimit < values.dsConteudo.length) {
        //     errors.push(`O campo Descrição excedeu o limite de caracteres (${contentLimit}), verifique e tente novamente.`);
        // }

        // disponibilidade comunicado (para lojas ou estados)
        // se disponibilizado para lojas não podemos ter estados e vice versa
        if (comunicateTo === 'lojas') {
            values.comunicadoEstados = [];
        } else {
            values.comunicadoLojas = [];
        }

        // pop-up
        // > se desativado, removemos configs de confirmação e data inicio/fim
        if (!values.fgExibePopup) {
            values.fgSolicitaConfirmacao = false;
            values.dhInicioPopup = undefined;
            values.dhFinalPopup = undefined;
        }

        // arquivos do tipo capa, são inseridos no inicio da lista
        // varemos os arquivos da capa, pois só podemos ter 1 ativo e capa, itens excluidos deixa de ser capa
        if (fileCapa.length) {
            fileCapa.forEach((capa) => {
                capa.fgCapa = !capa.fgExcluido;
            });

            values.comunicadoArquivos?.unshift(...fileCapa);
        }

        // arquivos normais (não capa) são inseridos no decorrer da lista
        if (fileNormal.length) {
            fileNormal.forEach((file) => {
                file.fgCapa = false;
            });

            values.comunicadoArquivos?.push(...fileNormal);
        }

        // TODO desabilitado até ajuste tratativa drag/ctr+v imagens no editor
        // if (errors.length) {
        //     errors.forEach((customError) => {
        //         enqueueSnackbar(customError, { variant: 'warning' });
        //     });
        // } else {
        mutateComunicado(
            {
                url,
                idField: 'idComunicado',
                formData: values,
                formik,
                preventRedirect: !hasPermission(['COMUNICADO_ALTERAR']),
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (!hasPermission(['COMUNICADO_ALTERAR'])) {
                        navigate(-1);
                    }

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
        // }
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsComunicado: Yup.string().required(),
                categoriaComunicado: Yup.object().required().nullable(),
                dhInicioPopup: Yup.string()
                    .when('fgExibePopup', {
                        is: true,
                        then: (schema) => schema.required().nullable(),
                    })
                    .nullable(),
                dhFinalPopup: Yup.string()
                    .when('fgExibePopup', {
                        is: true,
                        then: (schema) => schema.required().nullable(),
                    })
                    .nullable(),
            }),
        [],
    );

    useEffect(() => {
        setLoading(isLoading);

        if (content?.comunicadoEstados?.length) {
            setComunicateTo('estados');
        }

        // se temos arquivos, separados eles em 2 listas:
        // 1 - capa
        // 2 - normal (não capa)
        // no submit pegamos essas listas e aplicamos nos valores do form
        if (content?.comunicadoArquivos?.length) {
            const cover = content.comunicadoArquivos.find((item: any) => item.fgCapa);
            const normal = content.comunicadoArquivos.filter((item: any) => !item.fgCapa);

            if (cover) {
                setFileCapa([cover]);
            }

            if (normal) {
                setFileNormal(normal);
            }
        }
    }, [content, isLoading, setLoading]);

    return (
        <FormPage
            title="Comunicado"
            values={initialComunicadosValues}
            onSubmit={handleSubmit}
            url={url}
            fgAtivo={hasPermission(['COMUNICADO_MODERAR'])}
            validationSchema={validationSchema}
            extraTopButtons={[
                {
                    title: 'Gerenciar Tags',
                    onClick: () => navigate('/comunicado/tag'),
                    visible: hasPermission(['COMUNICADO_MODERAR']),
                },
            ]}
        >
            {(formik: FormikProps<RequestComunicadosModelProps>) => {
                return (
                    <React.Fragment>
                        <Block>
                            <Row>
                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Título"
                                        name="dsComunicado"
                                        value={formik.values.dsComunicado}
                                        onChange={(e) => formik.setFieldValue('dsComunicado', e.target.value)}
                                        error={formik.errors.dsComunicado}
                                    />
                                </Grid>

                                <Grid item flex={0.95}>
                                    <Field component={CategoryFieldFormik} multiple={false} name="categoriaComunicado" />
                                </Grid>
                                <Grid item flex={1}>
                                    <Field component={ComunicadoTagFieldFormik} name="comunicadoTagsComunicado" />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <Row sx={{ flexDirection: 'column' }}>
                                        <Grid item>
                                            <Typography>
                                                <strong>Enviar comunicado para loja?</strong>
                                            </Typography>

                                            <Field as={FormControlLabel} type="checkbox" name="fgEnviaEmail" control={<Checkbox />} label="Enviar por email" />
                                        </Grid>

                                        <Grid item>
                                            <Field component={StoreTypeFieldFormik} name="comunicadoTipoLojas" />
                                        </Grid>
                                        <Grid item>
                                            <Field component={StoreProfileFieldFormik} name="comunicadoLojaPerfis" />
                                        </Grid>
                                    </Row>
                                </Grid>

                                <Grid item flex={1}>
                                    <Row sx={{ flexDirection: 'column' }}>
                                        <Grid item>
                                            <Typography>
                                                <strong>Comunicado será disponibilizado para:</strong>
                                            </Typography>

                                            <Grid container>
                                                <Grid item>
                                                    <FormControlLabel
                                                        label="Lojas"
                                                        control={<Radio />}
                                                        checked={comunicateTo === 'lojas'}
                                                        onChange={(event: any): void => {
                                                            setComunicateTo(event.target.value);
                                                        }}
                                                        value="lojas"
                                                        name="comunicateTo"
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <FormControlLabel
                                                        label="Estados"
                                                        control={<Radio />}
                                                        checked={comunicateTo === 'estados'}
                                                        onChange={(event: any): void => {
                                                            setComunicateTo(event.target.value);
                                                        }}
                                                        value="estados"
                                                        name="comunicateTo"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        label="Cidades"
                                                        control={<Radio />}
                                                        checked={comunicateTo === 'cidades'}
                                                        onChange={(event: any): void => {
                                                            setComunicateTo(event.target.value);
                                                        }}
                                                        value="cidades"
                                                        name="comunicateTo"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Field
                                                component={StoreImportAutoComplete}
                                                name="comunicadoLojas"
                                                sx={{ display: comunicateTo === 'lojas' ? 'flex' : 'none' }}
                                                multiple={true}
                                                requestOptions={{
                                                    url: '/gestao/loja',
                                                    sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                                                    filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
                                                    columns: 'idLoja,cdLoja,dsNomeFantasia,dsRazaoSocial',
                                                    limit: 50,
                                                }}
                                            />
                                            <Field component={StateFieldFormik} name="comunicadoEstados" sx={{ display: comunicateTo === 'estados' ? 'block' : 'none' }} />
                                            <Field component={CityFieldFormik} name="comunicadoCidades" sx={{ display: comunicateTo === 'cidades' ? 'block' : 'none' }} />
                                        </Grid>
                                    </Row>
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <Stack direction="column">
                                        <Typography>
                                            <strong>Pop-up</strong>
                                        </Typography>

                                        <Field as={FormControlLabel} type="checkbox" name="fgExibePopup" control={<Checkbox />} label="Exibir pop-up" />

                                        {formik.values.fgExibePopup && (
                                            <Field as={FormControlLabel} type="checkbox" name="fgSolicitaConfirmacao" control={<Checkbox />} label="Solicitar confirmação no pop-up" />
                                        )}
                                    </Stack>
                                </Grid>

                                <Grid item flex={3}>
                                    {formik.values.fgExibePopup && (
                                        <React.Fragment>
                                            <Typography sx={{ mb: 1.5 }}>Período de exibição</Typography>

                                            <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Início', end: 'Fim' }}>
                                                <DateRangePicker
                                                    value={[formik.values.dhInicioPopup ?? null, formik.values.dhFinalPopup ?? null]}
                                                    onChange={(newValue) => {
                                                        const [start, end] = newValue;

                                                        formik.setFieldValue('dhInicioPopup', start);
                                                        formik.setFieldValue('dhFinalPopup', end);
                                                    }}
                                                    renderInput={(startProps, endProps) => (
                                                        <React.Fragment>
                                                            <CissTextField name="dhInicioPopup" {...startProps} error={formik.errors.dhInicioPopup} autoComplete="off" />
                                                            <Box sx={{ mx: 2 }}> até </Box>
                                                            <CissTextField name="dhFinalPopup" {...endProps} error={formik.errors.dhFinalPopup} autoComplete="off" />
                                                        </React.Fragment>
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Descrição" />

                            <Grid item xs={12}>
                                <Editor
                                    // TODO desabilitado até ajuste tratativa drag/ctr+v imagens no editor
                                    // limit={contentLimit}
                                    value={formik.values.dsConteudo}
                                    onChange={(value: string) => {
                                        formik.setFieldValue('dsConteudo', value);
                                    }}
                                />
                            </Grid>
                        </Block>

                        <Block>
                            <SubTitle label="Capa" />

                            <Upload
                                accept={uploadAcceptExtensions}
                                listFiles={fileCapa}
                                setListFiles={(list) => {
                                    setFileCapa(list);
                                }}
                            />
                        </Block>

                        <Block>
                            <SubTitle label="Anexos" />

                            <Upload
                                fileLimit={10}
                                accept={uploadAcceptExtensions}
                                listFiles={fileNormal}
                                setListFiles={(list) => {
                                    setFileNormal(list);
                                }}
                            />
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
