import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface ConfiguracaoPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
}

interface PedidoLoteCompraExportarModal {
    open: boolean;
    setOpen: (action: boolean) => void;
}

export function PedidoCompraTotalizadorQuebrasExportarModal({ open, setOpen }: PedidoLoteCompraExportarModal): JSX.Element {
    const [configPedido, setConfigPedido] = useState<ConfiguracaoPedidoProps | null>();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/compra/pedidocompra/itens/exportar',
            filter: [{ property: 'idConfigPedidoOrigem', value: configPedido?.idConfigPedido, operator: 'eq' }],
            sort: [{ property: 'idPedido', direction: 'DESC' }],
        }),
        [configPedido?.idConfigPedido],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClickDownload = useCallback((): void => {
        downloadFile({
            newRequestOptions: requestOptions,
            directDownload: true,
        });
    }, [downloadFile, requestOptions]);

    useEffect(() => {
        if (!open) {
            setConfigPedido(null);
        }
    }, [open]);

    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 2 }} closeButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Exportação de pedidos" />

                <ConfiguracaoPedidoAutoComplete
                    value={configPedido}
                    onChange={(e, value) => setConfigPedido(value)}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        filter: [
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                            { property: 'fgAtivo', operator: 'eq', value: true },
                            { property: 'idTipoPedido', operator: 'eq', value: 4 },
                        ],
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        limit: 9999,
                    }}
                    sx={{ mb: 2 }}
                />
                <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton
                        loading={isLoadingFile}
                        variant="outlined"
                        onClick={handleClickDownload}
                        disabled={isLoadingFile || !configPedido}
                        sx={{ color: 'green', borderColor: 'green' }}
                        startIcon={<RiFileExcel2Fill />}
                    >
                        Exportar
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
