import React from 'react';

import { CategoriaFormComp, CategoriaListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';

export function CategoriaList(): JSX.Element {
    return (
        <ListagemProvider>
            <CategoriaListComp />
        </ListagemProvider>
    );
}

export function CategoriaForm(): JSX.Element {
    return (
        <FormProvider>
            <CategoriaFormComp />
        </FormProvider>
    );
}
