import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

type ConfigPedido = {
    idConfigPedido: number;
    dsConfigPedido: string;
    dtInicioVigencia: string;
    dtFimVigencia: string;
};

export type ConfigPedidoTotalizador = {
    configPedido: ConfigPedido;
    configPedidoRelacionada: ConfigPedido[];
    fgSincronizado: boolean;
};

type ConfiguracaoPedidoTotalizadorAutoComplete = Pick<
    CissAutoCompleteProps,
    'requestOptions' | 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label' | 'getOptionDisabled'
>;

export function ConfiguracaoPedidoTotalizadorAutoComplete({
    size,
    value,
    error,
    multiple = true,
    disabled = false,
    sx,
    onChange = () => {},
    requestOptions = {
        url: '/gestao/configpedido/totalizador',
        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
    },
    label = 'Configuração de Pedido Totalizador',
    getOptionDisabled,
}: ConfiguracaoPedidoTotalizadorAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{configPedido.idConfigPedido} - {configPedido.dsConfigPedido}"
            optionStringTemplate="<strong>{configPedido.idConfigPedido}</strong> - {configPedido.dsConfigPedido}"
            requestOptions={requestOptions}
            isOptionEqualToValue={(option: ConfigPedidoTotalizador, value: ConfigPedidoTotalizador) =>
                option.configPedido.idConfigPedido === value.configPedido.idConfigPedido
            }
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
            getOptionDisabled={getOptionDisabled}
        />
    );
}
