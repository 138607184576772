import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { GridCellParams, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';

import { CissNumberField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { MovimentacaoProdutoSimulacaoDevolucaoDtosProps, NotaDevolucaoFormProps, RequestProdutosNotaDevolucaoFormProps, StateProdutosNota } from 'pages/financeiro/nota-devolucao/types';
import { brlDecimal, brlPrice } from 'util/format';

const generateRows = (data: MovimentacaoProdutoSimulacaoDevolucaoDtosProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.produto.cdProduto,
            nrCodigo: item.produto.cdProduto,
            dsProduto: item.produto.dsProduto,
            sgUnidadeMedida: item.unidadeMedida.sgUnidadeMedida,
            vlProduto: item.vlProduto,
            qtFaturado: item.qtNotaProduto,
            qtDevolucao: item.qtProduto ?? 0,
            vlTotal: item.vlProduto * (item.qtProduto ?? 0),
            qtEstoque: item.qtDisponivelDevolucao,
        }));
    }

    return [];
};

interface NotaDevolucaoFormProdutosGridProps {
    stateProdutosNota: string;
    setStateProdutosNota: Dispatch<SetStateAction<StateProdutosNota>>;
    produtosNota: MovimentacaoProdutoSimulacaoDevolucaoDtosProps[];
    setProdutosNota: Dispatch<SetStateAction<MovimentacaoProdutoSimulacaoDevolucaoDtosProps[]>>;
}

export function NotaDevolucaoFormProdutosGrid({ stateProdutosNota, setStateProdutosNota, produtosNota, setProdutosNota }: NotaDevolucaoFormProdutosGridProps): JSX.Element {
    const { setAutoLoad, filter, setFilter } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { values, errors } = useFormikContext<NotaDevolucaoFormProps>();

    const url = '/fiscal/movimentacaosimulacaodevolucao/produtovenda';

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url,
            sort: [{ property: 'nrSequencia', direction: 'DESC' }],
            filter,
        }),
        [filter],
    );

    const formikInfo = useMemo(
        () => ({
            cdLoja: values.loja?.cdLoja,
            nrNota: values.nrNotaOrigem,
            cdPessoa: values.pessoaFornecedor?.pessoa.cdPessoa,
        }),
        [values.loja?.cdLoja, values.nrNotaOrigem, values.pessoaFornecedor?.pessoa.cdPessoa],
    );

    const qtProdutoNullToZero = useCallback((data: MovimentacaoProdutoSimulacaoDevolucaoDtosProps[]): MovimentacaoProdutoSimulacaoDevolucaoDtosProps[] => {
        return data.map((produto) => {
            const { qtProduto, qtDisponivelDevolucao } = produto;

            return {
                ...produto,
                qtProduto: qtDisponivelDevolucao < 0 || !qtProduto ? 0 : qtProduto,
            };
        });
    }, []);

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestProdutosNotaDevolucaoFormProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: stateProdutosNota === 'loading',
            onSuccess: (result) => {
                const { data } = result;
                const produtos = qtProdutoNullToZero(data);

                setStateProdutosNota('loaded');
                setProdutosNota(produtos);
            },
        },
    );

    // setamos o autoload da listagem (grid) para true para mudar a mensagem de sem registros
    useEffect(() => {
        setAutoLoad(true);
    }, []);

    useEffect(() => {
        if (formikInfo.cdLoja && formikInfo.nrNota && formikInfo.cdPessoa) {
            setFilter([
                { property: 'cdLoja', operator: 'eq', value: formikInfo.cdLoja },
                { property: 'nrNota', operator: 'eq', value: formikInfo.nrNota },
                { property: 'cdPessoa', operator: 'eq', value: formikInfo.cdPessoa },
            ]);

            setProdutosNota([]);
        }
    }, [formikInfo.cdLoja, formikInfo.cdPessoa, formikInfo.nrNota, setFilter, setProdutosNota]);

    const updateQtdProdutoNF = useCallback(
        (value: number, params: GridCellParams<any, any, any>): void => {
            const { id, vlProduto } = params.row;

            setProdutosNota((prevRows) =>
                prevRows.map((row) => {
                    const { produto } = row;
                    const { cdProduto } = produto;

                    if (cdProduto === id) {
                        return {
                            ...row,
                            qtProduto: value,
                        };
                    }

                    return row;
                }),
            );
            params.row.vlTotal = vlProduto * value;
        },
        [setProdutosNota],
    );

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'nrCodigo', headerName: 'Código', width: 110 },
            { field: 'dsProduto', headerName: 'Produto', minWidth: 130, flex: 1 },
            { field: 'sgUnidadeMedida', headerName: 'Unidade', width: 80 },
            { field: 'vlProduto', headerName: 'Preço', type: 'number', width: 120, valueFormatter: ({ value }) => brlPrice(value) },
            { field: 'qtFaturado', headerName: 'Qtde. Faturada', type: 'number', width: 120, valueFormatter: ({ value }) => brlDecimal(value) },
            {
                field: 'qtDevolucao',
                headerName: 'Qtd. Devolução',
                type: 'number',
                width: 120,
                renderCell: (params) => {
                    const { row } = params;
                    const { qtDevolucao, qtEstoque, sgUnidadeMedida } = row;
                    const inputType = sgUnidadeMedida.toLowerCase() === 'kg' ? 'decimal' : 'number';
                    const decimalPrecision = sgUnidadeMedida.toLowerCase() === 'kg' ? 3 : 0;

                    return (
                        <CissNumberField
                            value={qtDevolucao}
                            size="small"
                            onChange={(e) => updateQtdProdutoNF(Number(e.target.value), params)}
                            inputType={inputType}
                            allowNegative={false}
                            maxValue={qtEstoque < 0 ? 0 : qtEstoque}
                            decimalPrecision={decimalPrecision}
                            forceMaxValue
                        />
                    );
                },
            },
            { field: 'vlTotal', headerName: 'Total (R$)', type: 'number', width: 130, valueFormatter: ({ value }) => brlPrice(value) },
            { field: 'qtEstoque', headerName: 'Qtd. Estoque', type: 'number', width: 130, valueFormatter: ({ value }) => brlDecimal(value) },
        ],
        [updateQtdProdutoNF],
    );

    const rows = useMemo(() => generateRows(data?.data), [data]);

    const handleClickProcurarProdutos = useCallback((): void => {
        setStateProdutosNota('loading');
    }, [setStateProdutosNota]);

    return (
        <Box>
            <Box id="movimentacaoProdutoSimulacaoDevolucaoDtos">
                {errors.movimentacaoProdutoSimulacaoDevolucaoDtos && !Array.isArray(errors.movimentacaoProdutoSimulacaoDevolucaoDtos) && (
                    <Typography color="error" fontSize="0.85rem" lineHeight={1.5} my={1}>
                        {errors.movimentacaoProdutoSimulacaoDevolucaoDtos as string}
                    </Typography>
                )}
            </Box>

            {stateProdutosNota === 'waiting' && (
                <Button variant="contained" onClick={handleClickProcurarProdutos} disabled={!formikInfo.cdLoja || !formikInfo.nrNota || !formikInfo.cdPessoa}>
                    Procurar produtos da Nota
                </Button>
            )}

            {stateProdutosNota !== 'waiting' && (
                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} visibleRows="all" />
            )}
        </Box>
    );
}
