import { useState } from 'react';

import { ConfirmModal } from 'components/modal';
import { useGetGrade } from 'hooks/useGetGrade';
import { brlDate } from 'util/format';
import { useQueryClient } from '@tanstack/react-query';

const isDateCurrentDate = (date: string) => {
    const currentDate = new Date();
    const dateToCompare = new Date(date);

    currentDate.setHours(0, 0, 0, 0);
    dateToCompare.setHours(0, 0, 0, 0);

    return currentDate.getTime() === dateToCompare.getTime();
};

export function GradeModal() {
    const [openModal, setOpenModal] = useState<boolean>(true);
    const { data } = useGetGrade();
    const queryClient = useQueryClient();

    if (!data) return null;

    const dtLimitePedido = `${data.dtLimitePedido}T00:00:00`;
    const dayText = isDateCurrentDate(dtLimitePedido) ? 'hoje' : 'amanhã';

    return (
        <ConfirmModal
            open={openModal}
            setOpen={setOpenModal}
            showCancel={false}
            title="Grade"
            confirmText="Fechar"
            confirm={() => queryClient.removeQueries(['getGrade'])} // caso fique algo em cache, remove os dados da query, evitando a modal abrir erroneamente
            text={
                <>
                    Sua data limite para digitação de <b>{data.tipoGrade.dsTipoGrade.toUpperCase()}</b> vence {dayText}, dia{' '}
                    <b>{brlDate(dtLimitePedido)}</b>
                </>
            }
        />
    );
}
