import React, { Dispatch, SetStateAction } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography, Box, Button, Tooltip, FormControlLabel, Checkbox } from '@mui/material';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { useFormMutation } from 'mutations/useFormMutation';
import { ComunicadoFecharBtn } from 'pages/inicio/components';
import { ComunicadoIndex } from 'pages/inicio/types';

type ComunicadoFooter = {
    comunicado: ComunicadoIndex;
    listComunicadosLength: number;
    liEstouCienteDisabled: boolean;
    liEstouCiente: boolean;
    setLiEstouCiente: Dispatch<SetStateAction<boolean>>;
    setOpenModalComunicados: Dispatch<SetStateAction<boolean>>;
    changeComunicado: (index: number) => void;
    readComunicados: number[];
    setReadComunicados: Dispatch<SetStateAction<number[]>>;
};

export function ComunicadoFooter({
    comunicado,
    liEstouCienteDisabled,
    liEstouCiente,
    setLiEstouCiente,
    setOpenModalComunicados,
    changeComunicado,
    listComunicadosLength,
    readComunicados,
    setReadComunicados,
}: ComunicadoFooter): JSX.Element {
    const { isDownMd } = useBreakpoints();
    const { mutate, isLoading: isLoadingMutate } = useFormMutation();
    const { index, idComunicado } = comunicado;
    const hasReadComunicado = readComunicados.includes(idComunicado);
    const validateLiEstouCiente = comunicado.fgSolicitaConfirmacao && !liEstouCiente && !hasReadComunicado;

    const handleVisualizacao = (): void => {
        mutate(
            { url: `/gestao/comunicadovisualizacao/add?idComunicado=${idComunicado}`, preventSnack: true },
            {
                onSettled: () => changeComunicado(index + 1),
            },
        );
    };

    const handleLiEstouCiente = (): void => {
        mutate(
            { url: `/gestao/comunicadoleitura/add?idComunicado=${idComunicado}`, preventSnack: true },
            {
                onSettled: () => changeComunicado(index + 1),
            },
        );
    };

    const handleProximo = (): void => {
        // Caso já tiver lido apenas avança o comunicado
        if (hasReadComunicado) {
            return changeComunicado(index + 1);
        }

        // Caso não tenha sido lido, marca como já lido e realiza a request necessária
        setReadComunicados((oldReadComunicados) => {
            oldReadComunicados.push(comunicado.idComunicado);

            return oldReadComunicados;
        });

        return comunicado.fgSolicitaConfirmacao ? handleLiEstouCiente() : handleVisualizacao();
    };

    const handleFechar = (): void => {
        // Caso não tenha lido ainda o comunicado, faz a request e fecha o modal
        if (!hasReadComunicado) {
            handleVisualizacao();
        }

        setOpenModalComunicados(false);
    };

    return (
        <Box sx={{ px: 2, py: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 64 }}>
            {!isDownMd && (
                <Typography>
                    <strong>{index + 1}</strong> de <strong>{listComunicadosLength}</strong> comunicado(s)
                </Typography>
            )}

            <Box
                sx={{
                    display: { xs: 'grid', md: 'flex' },
                    flex: { xs: 1, md: 'none' },
                    gridTemplateColumns: '1fr 1fr',
                    gap: { xs: 1, md: 2 },
                    alignItems: 'center',
                }}
            >
                <Box>
                    {comunicado.fgSolicitaConfirmacao && (
                        <Tooltip
                            title={liEstouCienteDisabled && !hasReadComunicado ? 'É necessário ler todo o comunicado para habilitar a flag' : ''}
                        >
                            <span>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    checked={liEstouCiente || hasReadComunicado}
                                    disabled={hasReadComunicado || liEstouCienteDisabled}
                                    onChange={(e, checked) => setLiEstouCiente(checked)}
                                    label="Li e estou ciente"
                                    sx={{ m: 0 }}
                                />
                            </span>
                        </Tooltip>
                    )}
                </Box>

                {isDownMd && (
                    <ComunicadoFecharBtn
                        validateLiEstouCiente={validateLiEstouCiente}
                        comunicado={comunicado}
                        index={index}
                        handleFechar={handleFechar}
                        isLoadingMutate={isLoadingMutate}
                        liEstouCiente={liEstouCiente}
                        listComunicadosLength={listComunicadosLength}
                    />
                )}

                <Button
                    variant="outlined"
                    startIcon={<NavigateNextIcon sx={{ transform: 'rotate(180deg)' }} />}
                    onClick={() => changeComunicado(index - 1)}
                    disabled={index === 0}
                >
                    Voltar
                </Button>

                <Tooltip title={validateLiEstouCiente ? 'É necessário clicar em "Li e Estou Ciente" para habilitar o botão' : ''}>
                    <span>
                        <Button
                            disabled={isLoadingMutate || validateLiEstouCiente || index === listComunicadosLength - 1}
                            variant="outlined"
                            onClick={handleProximo}
                            endIcon={<NavigateNextIcon />}
                            sx={{ width: '100%' }}
                        >
                            Próximo
                        </Button>
                    </span>
                </Tooltip>

                {!isDownMd && (
                    <ComunicadoFecharBtn
                        validateLiEstouCiente={validateLiEstouCiente}
                        comunicado={comunicado}
                        index={index}
                        handleFechar={handleFechar}
                        isLoadingMutate={isLoadingMutate}
                        liEstouCiente={liEstouCiente}
                        listComunicadosLength={listComunicadosLength}
                    />
                )}
            </Box>
        </Box>
    );
}
