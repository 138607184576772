import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface TagPecaMarketing {
    idTagPecaMarketing: number;
    dsTagPecaMarketing: string;
}

type TagPecaMarketingAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function TagPecaMarketingAutoComplete({ value, error, multiple = true, disabled = false, sx, onChange = () => {}, name }: TagPecaMarketingAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Tags"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idTagPecaMarketing} - {dsTagPecaMarketing}"
            optionStringTemplate="<strong>{idTagPecaMarketing}</strong> - {dsTagPecaMarketing}"
            requestOptions={{
                url: '/gestao/tagpecamarketing',
                sort: [{ property: 'idTagPecaMarketing', direction: 'ASC' }],
                columns: 'idTagPecaMarketing,dsTagPecaMarketing',
                limit: 50,
            }}
            isOptionEqualToValue={(option: TagPecaMarketing, value: TagPecaMarketing) => option.idTagPecaMarketing === value.idTagPecaMarketing}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
