import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface GrupoProps {
    idGrupo: number;
    dsGrupo: string;
    dsSecao: string;
    secao: {
        dsSecao: string;
    };
}

type GrupoAutoComplete = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'requestOptions' | 'optionStringTemplate' | 'size' | 'disabled'>;

export function GrupoAutoComplete({
    value,
    error,
    label = 'Grupo',
    multiple = false,
    sx,
    onChange,
    disabled, 
    requestOptions = {
        url: '/gestao/grupo',
        sort: [{ property: 'dsGrupo', direction: 'ASC' }],
        columns: 'idGrupo,dsGrupo,dsSecao',
        limit: 100,
    },
    optionStringTemplate = '<strong>{dsGrupo}</strong> <br/> Segmento {dsSecao}',
    size,
}: GrupoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            value={value}
            onChange={onChange}
            sx={sx}
            label={label}
            multiple={multiple}
            error={error}
            size={size}
            disabled={disabled}
            objectId='idGrupo'
            selectedStringTemplate="{dsGrupo}"
            optionStringTemplate={optionStringTemplate}
            isOptionEqualToValue={(option: GrupoProps, value: GrupoProps) => option.idGrupo === value.idGrupo}
            requestOptions={requestOptions}
        />
    );
}
