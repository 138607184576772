import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PedidoRealizadoModelProps, RequestPedidoRealizadoListModelProps, RowProps } from '../../types';

import { PanelGrid, StatusPedidoColumn } from 'components/grid';
import { Block } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider, useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoRealizadoActionMenu, PedidoRealizadoProdutosGrid } from 'pages/compra/pedido-realizado/components';
import { brlDate, brlPrice } from 'util/format';

const getRows = (data: PedidoRealizadoModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idPedido,
            idPedido: item.idPedido,
            dhInclusao: new Date(item.dhInclusao).toLocaleDateString('pt-BR'),
            cdLoja: item.loja.cdLoja,
            idLoja: item.loja.idLoja,
            dsNomeFantasia: item.loja.dsNomeFantasia,
            cdPedido: item.cdPedido ?? null,
            cdPedidoExterno: item.cdPedidoExterno,
            idConfigPedido: item.idConfigPedido,
            nrPedido: item.nrPedido ?? null,
            tipoPedido: item.tipoPedido,
            tipoGrade: item.configPedido.tipoGrade,
            configPedido: item.configPedido,
            dsConfigPedido: item.configPedido.dsConfigPedido,
            idTipoControleEstoquePedido: item.configPedido.idTipoControleEstoquePedido,
            configPedidoStatus: item.configPedido.configPedidoStatus,
            idPedidoStatus: item.pedidoStatus.idPedidoStatus,
            dsPedidoStatus: item.pedidoStatus.dsPedidoStatus,
            dhConfirmacaoPedido: item.dhConfirmacaoPedido ? new Date(item.dhConfirmacaoPedido).toLocaleDateString('pt-BR') : null,
            pedidoStatusEnum: item.pedidoStatus.pedidoStatusEnum,
            vlTotal: item.vlTotal ? item.vlTotal : 0,
            vlFrete: item.vlFrete ? item.vlFrete : 0,
            fgAtivo: item.fgAtivo,
            dsValidacao: item.dsValidacao,
            dtEntrega: item.grade.dtEntrega ? brlDate(`${item.grade.dtEntrega}T00:00:00`) : null,
            dtColeta: item.grade.dtColeta ? brlDate(`${item.grade.dtColeta}T00:00:00`) : null,
            dtLimitePedido: item.grade.dtLimitePedido ? brlDate(`${item.grade.dtLimitePedido}T00:00:00`) : null,
            tipoEntrega: item.tipoEntrega,
            usuarioPedido: item.usuarioPedido,
            dsUsuarioPedido: item.usuarioPedido?.dsUsuario,
            idUsuarioPedido: item.usuarioPedido?.idUsuario,
            dsUrlTrakingPedido: item.dsUrlTrakingPedido,
        }));
    }

    return [];
};

export function PedidoRealizadoGrid(): JSX.Element {
    const { hasPermission } = usePermissionsContext();
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { setContent } = useFormContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/historico',
        sort: sort.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
        page,
        limit,
        filter,
    };

    const { fetchStatus, data, refetch } = useQuery(
        ['getListPedidosRealizados', requestOptions],
        (): Promise<RequestPedidoRealizadoListModelProps> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: autoLoad,
            onSuccess: (data) => setContent(data.total),
        },
    );

    const columns = useMemo((): GridColDef[] => {
        const baseColumns: GridColDef[] = [
            { field: 'dhInclusao', headerName: 'Data', width: 90, minWidth: 90 },
            { field: 'dsConfigPedido', headerName: 'Configuração de Pedido', flex: 2, minWidth: 120 },
            { field: 'nrPedido', headerName: 'Código do Pedido', width: 100, minWidth: 90 },
            {
                field: 'cdPedido',
                headerName: 'Código de Integração',
                width: 100,
                minWidth: 90,
                renderCell: ({ row }) => (row.cdPedidoExterno ? `${row.cdPedido}/${row.cdPedidoExterno}` : row.cdPedido),
            },
            { field: 'dhConfirmacaoPedido', headerName: 'Data Pedido Confirmado', minWidth: 90, width: 90 },
            {
                field: 'vlTotal',
                headerName: 'Valor do Pedido',
                type: 'number',
                align: 'right',
                width: 120,
                minWidth: 110,
                valueFormatter: ({ value }) => brlPrice(value),
            },
            {
                field: 'dsPedidoStatus',
                headerName: 'Situação do Pedido',
                width: 150,
                minWidth: 150,
                flex: 1,
                filterable: false,
                sortable: false,
                renderCell: ({ row }) => (
                    <StatusPedidoColumn idStatus={row.idPedidoStatus} dsStatus={row.dsPedidoStatus} dsValidacao={row.dsValidacao} />
                ),
            },
            {
                field: 'Ações',
                align: 'center',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 90,
                minWidth: 90,
                renderCell: ({ row }) => {
                    const filters: RowProps = {
                        dsNomeFantasia: row.dsNomeFantasia,
                        cdLoja: row.cdLoja,
                        idLoja: row.idLoja,
                        idConfigPedido: row.idConfigPedido,
                        tipoGrade: row.tipoGrade,
                        idPedido: row.idPedido,
                        pedidoStatusEnum: row.pedidoStatusEnum,
                        tipoEntrega: row.tipoEntrega,
                        nrPedido: row.nrPedido,
                        idUsuarioPedido: row.idUsuarioPedido,
                        dsUsuarioPedido: row.dsUsuarioPedido,
                        usuarioPedido: row.usuarioPedido,
                        dsUrlTrakingPedido: row.dsUrlTrakingPedido,
                        fgAtivo: row.fgAtivo,
                        configPedido: row.configPedido,
                    };

                    return <PedidoRealizadoActionMenu rowProps={filters} />;
                },
            },
        ];

        if (isTipoPedidoPadrao) {
            const gradeColumns: GridColDef[] = [
                { field: 'dtLimitePedido', headerName: 'Data Limite do Pedido', minWidth: 90, width: 90 },
                { field: 'dtColeta', headerName: 'Data da Coleta', minWidth: 90, width: 90 },
                { field: 'dtEntrega', headerName: 'Data da Entrega', minWidth: 90, width: 90 },
            ];

            baseColumns.splice(1, 0, ...gradeColumns);

            baseColumns.splice(3, 0, { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 130 });
        } else {
            if (hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL'])) {
                baseColumns.splice(2, 0, {
                    field: 'dsUsuarioPedido',
                    headerName: 'Funcionário',
                    minWidth: 120,
                });
            }

            baseColumns.splice(6, 0, {
                field: 'vlFrete',
                headerName: 'Frete',
                type: 'number',
                align: 'right',
                width: 80,
                minWidth: 80,
                valueFormatter: ({ value }) => brlPrice(value),
            });
        }

        return baseColumns;
    }, [isTipoPedidoPadrao]);

    return (
        <Block>
            <PanelGrid
                rows={getRows(data?.data)}
                rowsCount={data?.total ?? 0}
                reload={refetch}
                loading={fetchStatus === 'fetching'}
                columns={columns}
                rowThreshold={0}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={({ row }) => (
                    <ListagemProvider>
                        <PedidoRealizadoProdutosGrid row={row} />
                    </ListagemProvider>
                )}
                isTreeData
                summaryPrefix={false}
                summary={['vlTotal']}
            />
        </Block>
    );
}
