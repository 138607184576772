import { Box } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Imagem } from 'components/images';
import { CarouselProps } from 'react-material-ui-carousel/dist/components/types';

export interface CardCarouselItemType {
    id?: number;
    url?: string;
}

export interface CardCarouselType extends CarouselProps {
    cardList: CardCarouselItemType[];
}

export function CardCarousel({ cardList, ...props }: CardCarouselType): JSX.Element {
    return (
        <Carousel navButtonsAlwaysVisible={true} cycleNavigation={false} interval={8000} animation="slide" {...props}>
            {cardList.map(({ id, url }) => (
                <Box key={id} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Imagem src={url} style={{ height: 400 }} />
                </Box>
            ))}
        </Carousel>
    );
}
