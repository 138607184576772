import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface MetaOptionsProps {
    idMeta: number;
    dsMeta: string;
    vlMeta: boolean;
}


const MetaOptions: MetaOptionsProps[] = [
    {
        idMeta: 1,
        dsMeta: 'Sim',
        vlMeta: true,
    },
    {
        idMeta: 2,
        dsMeta: 'Não',
        vlMeta: false,
    },
];

type MetaAutoCompleteProps = Pick<CissAutoCompleteProps, 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label'>;

export function MetaAutoComplete({ size, value, error, multiple = false, disabled = false, sx, onChange = () => {}, label = 'Meta' }: MetaAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            options={MetaOptions}
            selectedStringTemplate="{dsMeta}"
            optionStringTemplate="{dsMeta}"
            isOptionEqualToValue={(option: MetaOptionsProps, value: MetaOptionsProps) => option.idMeta === value.idMeta}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}