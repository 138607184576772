import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { Box } from '@mui/material';

import { PeriodoHistoricoAutoComplete } from 'components/autocompletes';
import { PeriodoHistorico, PeriodoHistoricoOptions } from 'components/autocompletes/PeriodoHistoricoAutoComplete';
import { ContentModal } from 'components/modal';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { PedidoProdutoModalHistorico, PedidoProdutoModalTitulo, PedidoProdutoModalInfo } from 'pages/compra/pedido/components';
import { ProdutoPedido } from 'pages/compra/pedido/types';

interface PedidoCompraProdutoModalProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    produto: ProdutoPedido;
}

export function PedidoCompraProdutoModal({ open, setOpen, produto }: PedidoCompraProdutoModalProps): JSX.Element {
    const storagePeriodoHistorico = localStorage.getItem('periodoHistoricoProdutoModal');
    const [periodoHistorico, setPeriodoHistorico] = useState<PeriodoHistorico>(
        (storagePeriodoHistorico && JSON.parse(storagePeriodoHistorico)) || PeriodoHistoricoOptions[0],
    );
    const { isDownMd } = useBreakpoints();

    useEffect(() => {
        if (open && storagePeriodoHistorico) {
            setPeriodoHistorico(JSON.parse(storagePeriodoHistorico));
        } else if (!open && periodoHistorico) {
            localStorage.setItem('periodoHistoricoProdutoModal', JSON.stringify(periodoHistorico));
        }
    }, [open]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton
            sx={{
                minWidth: { md: 960, xs: '80vw' },
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                borderRadius: 2,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2, md: 3 } }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box
                        sx={{
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundImage: `url("${produto.produto.lkImagem}")`,
                            minHeight: 270,
                            minWidth: 270,
                            borderRadius: 1,
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                        }}
                    >
                        {!produto.produto.lkImagem && <InsertPhotoOutlinedIcon sx={{ fontSize: 70, color: (theme) => theme.palette.grey[400] }} />}
                    </Box>

                    {!isDownMd && (
                        <PeriodoHistoricoAutoComplete
                            disableClearable
                            size="small"
                            value={periodoHistorico}
                            onChange={(e, value) => setPeriodoHistorico(value)}
                        />
                    )}
                </Box>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <PedidoProdutoModalTitulo produto={produto} />

                    <PedidoProdutoModalInfo produto={produto} />

                    {isDownMd && (
                        <PeriodoHistoricoAutoComplete
                            size="small"
                            value={periodoHistorico}
                            onChange={(e, value) => setPeriodoHistorico(value)}
                            sx={{ my: 1 }}
                        />
                    )}

                    <PedidoProdutoModalHistorico produto={produto} open={open} periodoHistorico={periodoHistorico?.vlPeriodoHistorico} />
                </Box>
            </Box>
        </ContentModal>
    );
}
