import { ConfigPedidoProps } from '../types';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { brlDecimal, brlPrice } from 'util/format';

export function usePedidoMinimo() {
    const { vlPedido, vlPesoLiquido, configPedido } = usePedidoCompraContext();
    const { vlPedidoMinimoLoja, tpControlePedidoMinimoLoja } = configPedido as ConfigPedidoProps;
    const hasPedidoMinimo = typeof vlPedidoMinimoLoja === 'number';

    let vlParaPedidoMinimo = 0;
    let dsPedidoMinimo = '';

    if (tpControlePedidoMinimoLoja && hasPedidoMinimo) {
        if (tpControlePedidoMinimoLoja === 'PESO_TOTAL') {
            vlParaPedidoMinimo = vlPedidoMinimoLoja - vlPesoLiquido;
        } else {
            vlParaPedidoMinimo = vlPedidoMinimoLoja - vlPedido;
        }

        if (vlParaPedidoMinimo < 0) {
            vlParaPedidoMinimo = 0;
        }

        if (tpControlePedidoMinimoLoja === 'PESO_TOTAL') {
            dsPedidoMinimo = `${brlDecimal(vlParaPedidoMinimo, 0, 3)} Kg`;
        } else if (tpControlePedidoMinimoLoja === 'VALOR_TOTAL') {
            dsPedidoMinimo = brlPrice(vlParaPedidoMinimo);
        }
    }

    return { dsPedidoMinimo };
}
