import React, { ChangeEvent, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { Person } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Typography } from '@mui/material';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { Password } from 'components/fields/';
import { PasswordValidationGroup } from 'components/text';
import { AuthLoginErrorMessages } from 'constants/AuthLoginErrorMessages';
import { useDomainConfigContext } from 'context/DomainConfig';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useFormMutation } from 'mutations/useFormMutation';
import { initialValidationsPassword, ValidationsPassword, validatePassword } from 'util/validatePassword';

interface CredentialsProps {
    dsLogin: string;
    dsSenha: string;
    dsNovaSenha: string;
    dsConfirmacaoSenha: string;
    dsHostacesso?: string;
}

interface LoginResponseSuccessProps {
    data: {
        access_token: string;
    };
}

interface LoginResponseErrorProps {
    data: {
        expiredAt: string;
        message: string;
        name: string;
        error: string;
    };
}

export function LoginForm(): JSX.Element {
    const [isPasswordExpired, setIsPasswordExpired] = useState<boolean>(false);
    const urlLogin = useRef('/auth2/token');
    const urlPasswordUpdate = useRef('/authmanager/usuario/redefinirsenha');

    const { setToken } = useGenericAuth();
    const { mutate, isLoading, error, reset } = useFormMutation<LoginResponseSuccessProps, LoginResponseErrorProps>();
    const { subdomain } = useDomainConfigContext();
    const { enqueueSnackbar } = useSnackbar();
    const [validationsPassword, setValidationsPassword] = useState<ValidationsPassword>(initialValidationsPassword);

    const onSubmitLogin = (values: CredentialsProps, formik: FormikHelpers<CredentialsProps>): void => {
        mutate(
            {
                url: urlLogin.current,
                formData: values,
                preventSnack: true,
            },
            {
                onSuccess: (result) => {
                    const { data } = result;

                    setToken(data.access_token);
                },
                onError: (res) => {
                    if (res.data.expiredAt) {
                        setIsPasswordExpired(true);
                        formik.resetForm({ values });
                        reset();
                    } else {
                        formik.resetForm({ values });
                    }
                },
            },
        );
    };

    const onSubmitPasswordUpdate = (values: CredentialsProps, formik: FormikHelpers<CredentialsProps>): void => {
        if (!Object.values(validationsPassword).every((value) => value)) {
            return;
        }

        values.dsHostacesso = subdomain;

        mutate(
            {
                url: urlPasswordUpdate.current,
                formData: values,
                preventSnack: true,
            },
            {
                onSuccess: () => {
                    formik.resetForm({
                        values: {
                            dsLogin: values.dsLogin,
                            dsSenha: '',
                            dsNovaSenha: '',
                            dsConfirmacaoSenha: '',
                        },
                    });

                    enqueueSnackbar('Senha atualizada com sucesso', { variant: 'success' });

                    setIsPasswordExpired(false);
                },
                onError: () => {
                    formik.resetForm({ values });
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsNovaSenha: Yup.string(),
                dsConfirmacaoSenha: Yup.string().test('passwords-match', 'As senhas precisam ser iguais', function (value) {
                    return this.parent.dsNovaSenha === value;
                }),
            }),
        [],
    );

    if (isPasswordExpired) {
        return (
            <Formik
                initialValues={{
                    dsLogin: '',
                    dsSenha: '',
                    dsNovaSenha: '',
                    dsConfirmacaoSenha: '',
                }}
                onSubmit={onSubmitPasswordUpdate}
                validationSchema={validationSchema}
            >
                {(formik: FormikProps<CredentialsProps>) => (
                    <Form>
                        <Alert severity="warning">Sua senha de login expirou e precisa ser redefinida</Alert>

                        <Typography sx={{ mt: 2 }}>Conectado com usuário:</Typography>
                        <Typography fontWeight={'bold'}>{formik.values.dsLogin}</Typography>

                        <Field name="dsSenha" component={Password} />

                        <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                            Nova senha
                        </Typography>

                        <Field
                            name="dsNovaSenha"
                            label="Nova senha"
                            margin="dense"
                            component={Password}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const inputValue = e.target.value;

                                setValidationsPassword(validatePassword(inputValue));

                                formik.setFieldValue('dsNovaSenha', inputValue);

                                return inputValue;
                            }}
                        />

                        <PasswordValidationGroup validationsPassword={validationsPassword} />

                        <Field name="dsConfirmacaoSenha" margin="dense" label="Confirmar senha" component={Password} />

                        <LoadingButton loading={isLoading} type="submit" fullWidth variant="contained" size="large" sx={{ mt: 2 }}>
                            Alterar senha
                        </LoadingButton>

                        {error && (
                            <Alert sx={{ mt: 2, px: 1 }} severity="error">
                                {error?.data?.message || AuthLoginErrorMessages[error?.data?.error] || 'Não foi possível atualizar a senha'}
                            </Alert>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }

    return (
        <Formik
            initialValues={{
                dsLogin: '',
                dsSenha: '',
                dsNovaSenha: '',
                dsConfirmacaoSenha: '',
            }}
            onSubmit={onSubmitLogin}
        >
            <Form>
                <Field
                    name="dsLogin"
                    component={TextField}
                    required
                    fullWidth
                    id="email"
                    label="Usuário"
                    autoComplete="email"
                    InputProps={{
                        endAdornment: <Person />,
                    }}
                />

                <Field name="dsSenha" component={Password} />

                <Link to={'/login-recuperacao-senha'}>Esqueceu sua senha?</Link>

                <LoadingButton loading={isLoading} type="submit" fullWidth variant="contained" size="large" sx={{ mt: 2 }}>
                    Entrar
                </LoadingButton>

                {error && (
                    <Alert sx={{ mt: 2, px: 1 }} severity="error">
                        {error?.data?.message || AuthLoginErrorMessages[error?.data?.error] || 'Não foi possível efetuar o login'}
                    </Alert>
                )}
            </Form>
        </Formik>
    );
}
