import React from 'react';

import { SolicitacaoPecaProps } from '../../types';

import { TabuledGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';

interface SolicitacaoPecaDadosGeraisMensagemProps {
    data?: SolicitacaoPecaProps;
}

export function TradeSolicitacaoPecaDadosGeraisMensagem({ data }: SolicitacaoPecaDadosGeraisMensagemProps): JSX.Element {
    return (
        <Block>
            <SubTitle label="Dados Gerais" />

            <TabuledGrid
                showEmpty={false}
                list={[
                    {
                        label: <strong>Loja:</strong>,
                        value: `${data?.loja?.idLoja} - ${data?.loja?.dsRazaoSocial}`,
                    },
                    {
                        label: <strong>Solicitante:</strong>,
                        value: data?.usuarioSolicitante.dsUsuario,
                    },
                    {
                        label: <strong>Email Solicitante:</strong>,
                        value: data?.usuarioSolicitante.dsEmail,
                    },
                    {
                        label: <strong>Categoria:</strong>,
                        value: data?.categoriaPeca?.dsCategoriaPeca,
                    },
                ]}
            />
        </Block>
    );
}
