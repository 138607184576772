import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ImportFileBtn } from 'components/buttons';
import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

interface CargoImportarModal {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export function CargoImportarModal({ open, setOpen }: CargoImportarModal): JSX.Element {
    const { autoLoad, setAutoLoad } = useListagemContext();
    const [file, setFile] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: () => {
            if (!autoLoad) {
                setAutoLoad(true);
            }

            queryClient.invalidateQueries(['getListCargo']);

            enqueueSnackbar('Importação de cargos e créditos realizada com sucesso', { variant: 'success' });

            setOpen(false);
        },
        onError: (error: any) => showError(error, 'Falha ao importar o arquivo'),
    });

    const handleSubmit = (): void => {
        const formData = new FormData();

        if (file) {
            formData.append('cargo', file);
        }

        mutate({
            url: '/gestao/cargo/upload',
            formData,
        });
    };

    useEffect(() => {
        if (!open) {
            setFile(null);
        }
    }, [open]);

    return (
        <ContentModal
            closeButton
            open={open}
            setOpen={setOpen}
            sx={{
                borderRadius: 2,
                maxWidth: '80%',
                width: {
                    xs: '80%',
                    sm: 'auto',
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <SubTitle label="Importar Cargos e Créditos" />

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                    <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_CARGO" sx={{ width: { xs: '100%', md: 'auto' } }} />

                    <ImportFileBtn file={file} setFile={setFile} accept={['.xlsx', '.csv']} />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'flex-end',
                        mt: 2,
                        gap: 2,
                    }}
                >
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={handleSubmit} loading={isLoading} disabled={!file}>
                        Importar
                    </LoadingButton>
                </Box>
            </Box>
        </ContentModal>
    );
}
