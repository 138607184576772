import React from 'react';
import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface CategoriaPecaProps {
    idCategoriaPeca: number;
    dsCategoriaPeca: string;
}

type CategoriaPecaAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function CategoriaPecaAutoComplete({ value, error, multiple = false, sx, onChange, name }: CategoriaPecaAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Categoria"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idCategoriaPeca} - {dsCategoriaPeca}"
            optionStringTemplate="<strong>{idCategoriaPeca}</strong> - {dsCategoriaPeca}"
            requestOptions={{
                url: '/gestao/categoriapeca',
                sort: [{ property: 'dsCategoriaPeca', direction: 'ASC' }],
                columns: 'idCategoriaPeca,dsCategoriaPeca',
                limit: 50,
            }}
            isOptionEqualToValue={(option: CategoriaPecaProps, value: CategoriaPecaProps) => value && option.idCategoriaPeca === value.idCategoriaPeca}
            onChange={onChange}
            sx={sx}
            name={name}
        />
    );
}
