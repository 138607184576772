import React from 'react';

import { TipoEntregaAutoComplete } from 'components/autocompletes';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoTipoEntregaAutoComplete(): JSX.Element {
    const { filterTipoEntrega, setFilterTipoEntrega } = usePedidoFilterContext();

    return (
        <TipoEntregaAutoComplete
            size="small"
            value={filterTipoEntrega.value}
            onChange={(e, value) => setFilterTipoEntrega((oldState: FilterValuesProps) => ({ ...oldState, value }))}
            requestOptions={{
                url: '/gestao/tipoentrega/pedido',
                sort: [{ property: 'dsTipoEntrega', direction: 'ASC' }],
            }}
        />
    );
}
