import React, { Dispatch, SetStateAction, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { ConfirmModal } from 'components/modal';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { DrePageProps, RequestDrePageProps } from 'pages/financeiro/dre/types';

type DreLoadF360Btn = {
    setSalvando: Dispatch<SetStateAction<boolean>>;
    formData: DrePageProps;
    setFormData: Dispatch<SetStateAction<DrePageProps>>;
    setFormRequestStatus: Dispatch<SetStateAction<number | undefined>>;
};

export function DreLoadF360Btn({ formData, setFormData, setFormRequestStatus, setSalvando }: DreLoadF360Btn): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const { mutate } = useFormMutation<RequestDrePageProps>();
    const { showError } = useShowError();
    const { id } = useParams();
    const showF360Btn = Boolean(
        formData.loja?.lojaConfiguracoes?.some((config) => config?.configuracaoPropriedade.dsChave === 'cdLojaF360' && config?.dsValor),
    );

    const loadF360 = (): void => {
        setSalvando(true);

        mutate(
            { url: `/dre/dremercadologica/atualizadespesaexterna?idDreMercadologica=${id}`, preventSnack: true },
            {
                onSuccess: (response) => {
                    const { data, status } = response;

                    enqueueSnackbar('Desespesas do F360 carregadas com sucesso!', { variant: 'success' });

                    setFormData(data?.data);
                    setFormRequestStatus(status);
                },
                onError: (error: any) => showError(error, 'Falha ao carregar as desespesas do F360'),
                onSettled: () => setSalvando(false),
            },
        );
    };

    return (
        <>
            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                confirm={loadF360}
                text="Ao carregar as despesas do F360 todos os dados das seções Despesas, Despesas Extras e Impostos serão substituídos. Tem certeza que deseja continuar?"
            />

            {showF360Btn && (
                <Button variant="outlined" onClick={() => setOpenConfirmModal(true)}>
                    Carregar Despesas F360
                </Button>
            )}
        </>
    );
}
