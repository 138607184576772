import React, { useCallback, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, FormControlLabel, Checkbox, Typography, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ptBR } from 'date-fns/locale';
import { Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { OptionalArraySchema } from 'yup/lib/array';

import { DeviceFieldFormik, ModuleFieldFormik } from '..';

import { CissTextField } from 'components/fields/';
import { Row } from 'components/form';
import { FormPage, Block } from 'components/page';
import { SubTitle } from 'components/text';
import { Upload } from 'components/upload';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { RequestBannerModelProps, initialBannerValues } from 'pages/banner/types';

export function BannerFormComp(): JSX.Element {
    const { setLoading, setContent, content } = useFormContext();

    const url = '/gestao/banner/';

    const { mutate: mutateBanner, isLoading } = useFormMutation();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();
    const uploadAcceptExtensions = ['.jpg', '.jpeg', '.png', '.tiff'];

    const formatPayloadBannerModulos = (bannerModulos: any): any => {
        return bannerModulos?.map((modulo: any) => ({ modulo }));
    };

    const getMinDate = (): any => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    };

    const handleSubmit = (values: RequestBannerModelProps, formik: FormikHelpers<RequestBannerModelProps>): void => {
        const modifyBannerModulos = formatPayloadBannerModulos(values?.bannerModulos);

        values.bannerModulos = modifyBannerModulos;

        mutateBanner(
            {
                url,
                idField: 'idBanner',
                formData: values,
                formik,
                preventRedirect: !hasPermission(['BANNER_ALTERAR']),
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (!hasPermission(['BANNER_ALTERAR'])) {
                        navigate(-1);
                    }

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const imagePropsYupValidation = (): OptionalArraySchema<any> => {
        return Yup.array(
            Yup.object().shape({
                arquivo: Yup.object({
                    dsArquivo: Yup.string(),
                    dsExtensao: Yup.string(),
                    dsHash: Yup.string(),
                    dsLink: Yup.string(),
                    dsLinkThumbnail: Yup.string(),
                    dsTitulo: Yup.string(),
                    mimeType: Yup.string(),
                }),
                fgAtivo: Yup.boolean(),
                fgCapa: Yup.boolean(),
                fgExcluido: Yup.boolean(),
                fgNovo: Yup.boolean(),
            }),
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsBanner: Yup.string().required().max(32),
                dsLink: Yup.string(),
                nrOrdem: Yup.string().required(),
                tipoDispositivo: Yup.object({
                    idTipoDispositivo: Yup.number(),
                    dsTipoDispositivo: Yup.string(),
                })
                    .nullable()
                    .required(),
                dtInicioVigencia: Yup.date()
                    .when('fgPermanente', {
                        is: false,
                        then: (schema) => schema.required(),
                    })
                    .min(getMinDate(), 'Data de início de vigência deve ser maior ou igual a data atual.')
                    .nullable(),
                dtFimVigencia: Yup.date()
                    .when('fgPermanente', {
                        is: false,
                        then: (schema) => schema.required(),
                    })
                    .typeError('A data não é válida.')
                    .nullable(),
                bannerArquivos: imagePropsYupValidation().required().min(1).nullable(),
            }),
        [],
    );

    const mapGetAvailableBannerModules = (getBannerModulos: any): any => {
        return getBannerModulos?.map((modulo: any) => {
            if (modulo?.modulo) {
                return { idModulo: modulo?.idModulo, dsModulo: modulo?.modulo?.dsModulo };
            }

            return;
        });
    };

    useEffect(() => {
        setLoading(isLoading);

        if (content && content?.bannerModulos.length) {
            content.bannerModulos = mapGetAvailableBannerModules(content?.bannerModulos);
        }
    }, [content, isLoading, setLoading]);

    const mapContentToInitialValues = useCallback((contentData: RequestBannerModelProps) => {
        if (content && contentData && contentData?.bannerModulos?.length) {
            contentData.bannerModulos = content?.bannerModulos;
        }

        return contentData;
    }, []);

    return (
        <FormPage
            title="Banner"
            values={initialBannerValues}
            onSubmit={handleSubmit}
            url={url}
            fgAtivo={true}
            validationSchema={validationSchema}
            goBackButton="/banner"
            mapContentToInitialValues={mapContentToInitialValues}
        >
            {(formik: any) => {
                return (
                    <React.Fragment>
                        <Block>
                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Descrição curta"
                                        name="dsBanner"
                                        value={formik.values.dsBanner}
                                        onChange={(e) => formik.setFieldValue('dsBanner', e.target.value)}
                                        error={formik.errors.dsBanner}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Link no Banner"
                                        name="dsLinkBanner"
                                        value={formik.values.dsLinkBanner}
                                        onChange={(e) => formik.setFieldValue('dsLinkBanner', e.target.value)}
                                        error={formik.errors.dsLink}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Ordem do Banner"
                                        type="number"
                                        name="nrOrdem"
                                        value={formik.values.nrOrdem}
                                        onChange={(e) => formik.setFieldValue('nrOrdem', e.target.value)}
                                        error={formik.errors.nrOrdem}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <Field as={FormControlLabel} type="checkbox" name="fgPermanente" control={<Checkbox />} label="Banner Permanente" />
                                </Grid>
                            </Row>

                            {!formik.values.fgPermanente && (
                                <Row>
                                    <Grid item flex={1}>
                                        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Início Vigência', end: 'Final Vigência' }}>
                                            <DateRangePicker
                                                value={[formik.values.dtInicioVigencia, formik.values.dtFimVigencia]}
                                                onChange={(newValue) => {
                                                    const [start, end] = newValue;

                                                    formik.setFieldValue('dtInicioVigencia', start);
                                                    formik.setFieldValue('dtFimVigencia', end);
                                                }}
                                                renderInput={(startProps, endProps) => (
                                                    <React.Fragment>
                                                        <CissTextField name="dtInicioVigencia" {...startProps} error={formik.errors.dtInicioVigencia} autoComplete="off" />
                                                        <Box sx={{ mx: 2 }}> até </Box>
                                                        <CissTextField name="dtFimVigencia" {...endProps} error={formik.errors.dtFimVigencia} autoComplete="off" />
                                                    </React.Fragment>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item flex={1} />
                                </Row>
                            )}
                            <Row>
                                <Grid item flex={1}>
                                    <Field component={DeviceFieldFormik} multiple={false} name="tipoDispositivo" />
                                </Grid>

                                <Grid item flex={1}>
                                    <Field component={ModuleFieldFormik} multiple={true} name="bannerModulos" />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle
                                label={
                                    <React.Fragment>
                                        Banner
                                        <Tooltip title="Para inserir o banner, ele precisa atender a resolução 2560x466.">
                                            <InfoOutlinedIcon
                                                color="success"
                                                sx={{
                                                    ml: 2,
                                                    transform: 'translateY(3px)',
                                                }}
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            />

                            <Upload
                                id="bannerArquivos"
                                listFiles={formik.values.bannerArquivos}
                                setListFiles={(list: any) => formik.setFieldValue('bannerArquivos', list)}
                                fileLimit={1}
                                accept={uploadAcceptExtensions}
                                removeAll={false}
                                isBannerForm={true}
                            />

                            {formik.errors.bannerArquivos && (
                                <Typography
                                    sx={{
                                        color: (theme) => theme.palette.error.light,
                                    }}
                                >
                                    Insira ao menos um <strong>Arquivo Banner</strong> para criar um Banner.
                                </Typography>
                            )}
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
