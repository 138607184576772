import * as dateFns from 'date-fns';
import pLimit from 'p-limit';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { DreListProps } from 'pages/financeiro/dre/types';

interface DreListUpdateReturn {
    dreUpdate: (dreProps: DreListProps) => Promise<DreListProps>;
}

export const useDreListUpdate = (): DreListUpdateReturn => {
    const Api = useServiceApiContext();

    const limit = pLimit(6); // Limita para 6 solicitações concorrentes

    const dreUpdate = async (dreProps: DreListProps): Promise<DreListProps> => {
        const dtInicio = dateFns.parseISO(dreProps?.dtInicio);
        const idAno = dtInicio.getUTCFullYear();
        const idMes = dtInicio.getUTCMonth() + 1;
        const { idLoja } = dreProps.loja;

        const url = '/dre/dremercadologica/calcula/';
        const { data } = await limit(() => Api.post(url, {}, { params: { idLoja, idAno, idMes } }));

        return { ...data.data, isLoading: false };
    };

    return { dreUpdate };
};
