import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import * as dateFns from 'date-fns';

import { TinyButton } from 'components/buttons';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface EspelhoFppBoletoBtnProps {
    params: GridRenderCellParams<any, any, any>;
}

export function EspelhoFppBoletoBtn({ params }: EspelhoFppBoletoBtnProps): JSX.Element {
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const [fgImpresso, setFgImpresso] = useState<boolean>(params.row.fgImpresso);

    const { tpStatus, nrTitulo, nrParcela, hasBoleto, dtVencimento: vencimento } = params.row;
    const dtVencimento = dateFns.format(new Date(vencimento), 'MM/dd/yyyy');

    useEffect(() => setFgImpresso(params.row.fgImpresso), [params.row.fgImpresso]);

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/titulo/boleto',
            columns: 'idBoleto',
            nested: true,
            filter: [
                { property: 'cdLoja', operator: 'eq', value: cdLoja, logicalOperator: 'AND' },
                { property: 'dsStatus', operator: 'eq', value: 'VC', logicalOperator: 'AND' },
                { property: 'nrTitulo', operator: 'eq', value: nrTitulo, logicalOperator: 'AND' },
                { property: 'nrParcela', operator: 'eq', value: nrParcela, logicalOperator: 'AND' },
                { property: 'dtVencimento', operator: 'bt', value: [dtVencimento, dtVencimento], logicalOperator: 'AND' },
            ],
        }),
        [cdLoja, dtVencimento, nrParcela, nrTitulo],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClick = useCallback((): void => {
        downloadFile();
    }, [downloadFile]);

    const color = fgImpresso ? 'error' : 'success';
    const text = fgImpresso ? 'Impresso' : 'Imprimir';

    return (
        <TinyButton variant="outlined" color={color} onClick={handleClick} disabled={!hasBoleto || tpStatus === 'PAGO' || isLoadingFile}>
            {text}
        </TinyButton>
    );
}
