import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TipoGradeOptionsProps {
    idTipoGrade: number;
    dsTipoGrade: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function TipoGradeAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Tipo de grade"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsTipoGrade}"
            optionStringTemplate="{dsTipoGrade}"
            requestOptions={{
                url: '/gestao/tipograde',
                sort: [{ property: 'idTipoGrade', direction: 'ASC' }],
                columns: 'idTipoGrade,dsTipoGrade',
                limit: 50,
            }}
            isOptionEqualToValue={(option: TipoGradeOptionsProps, value: TipoGradeOptionsProps) => value && option && option.idTipoGrade === value.idTipoGrade}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
