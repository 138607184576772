import React, { Dispatch, SetStateAction } from 'react';

import { Alert, Stack } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';

interface ErroPedidoModalProps {
    open: string | boolean;
    setOpen: Dispatch<SetStateAction<string | boolean>>;
}

export function ErroPedidoModal({ open, setOpen }: ErroPedidoModalProps): JSX.Element {
    return (
        <ContentModal open={Boolean(open)} setOpen={setOpen} sx={{ borderRadius: 2, width: { xs: '80%', sm: 'auto' } }} closeButton>
            <Stack>
                <SubTitle label="Erro" />

                <Alert variant="outlined" severity="error">
                    {open}
                </Alert>
            </Stack>
        </ContentModal>
    );
}
