import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Typography } from '@mui/material';
import { GridColDef,  GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { QuebrasSituacaoColumn } from 'pages/logistica/quebras/components';
import { QuebrasProps, RequestQuebrasProps } from 'pages/logistica/quebras/types';
import { brlDateTime } from 'util/format';

interface QuebrasGridProps {
    setOpenModalErros: Dispatch<SetStateAction<number | boolean>>;
}

export function QuebrasGrid({ setOpenModalErros }: QuebrasGridProps): JSX.Element {
    const { filter, limit, page, autoLoad, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const getPedidoLoteCompraGridList = useCallback((data: QuebrasProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: index + 1,
                idConfigPedidoQuebraImportacao: item.idConfigPedidoQuebraImportacao,
                dsStatusProcessamento: item.statusProcessamento.dsStatusProcessamento,
                idStatusProcessamento: item.statusProcessamento.idStatusProcessamento,
                dsConfigPedido: item.configPedido.dsConfigPedido,
                idConfigPedido: item.configPedido.idConfigPedido,
                dhInclusao: item.dhInclusao,
            }));
        }

        return [];
    }, []);

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/configpedidoquebraimportacao',
            sort: sort.length ? sort : [{ property: 'dhInclusao', direction: 'DESC' }],
            nested: true,
            filter,
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(['getListagemQuebras', requestOptions], () => RequestListagem(requestOptions).then((res) => res.data) as Promise<RequestQuebrasProps>, {
        enabled: autoLoad,
    });

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'dhInclusao',
                headerName: 'Data da Importação',
                type: 'string',
                align: 'center',
                headerAlign: 'center',
                valueFormatter: ({ value }) => (value ? brlDateTime(`${value}`) : ''),
                minWidth: 130,
                flex: 1,
            },
            {
                field: 'idConfigPedido',
                align: 'left',
                headerAlign: 'left',
                headerName: 'Configuração',
                minWidth: 140,
                flex: 4,
                renderCell: ({ row }) => (
                    <Typography>
                        <strong>{row.idConfigPedido}</strong> - {row.dsConfigPedido}
                    </Typography>
                ),
            },
            {
                field: 'dsStatusProcessamento',
                headerName: 'Situação',
                headerAlign: 'center',
                minWidth: 170,
                flex: 2,
                renderCell: ({ row }) => (
                    <QuebrasSituacaoColumn
                        idStatus={row.idStatusProcessamento}
                        title={row.dsStatusProcessamento}
                        setOpenModalErros={setOpenModalErros}
                        idConfigPedidoQuebraImportacao={row.idConfigPedidoQuebraImportacao}
                    />
                ),
            },
        ],
        [setOpenModalErros],
    );

    const rows = getPedidoLoteCompraGridList(data?.data);

    return (
        <Block>
            <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
        </Block>
    );
}
