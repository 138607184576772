import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { MotivoDevolucaoFormComp, MotivoDevolucaoListComp } from './components';
import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function MotivoDevolucaoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['FINANCEIRO_DEVOLUCAO_MOTIVO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <MotivoDevolucaoListComp />
        </ListagemProvider>
    );
}

export function MotivoDevolucaoForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('FINANCEIRO_DEVOLUCAO_MOTIVO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <MotivoDevolucaoFormComp />
        </FormProvider>
    );
}
