import React, { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';

import { Centered } from 'components/grid';
import { NotaDevolucaoListActionDelete, NotaDevolucaoListActionPrint } from 'pages/financeiro/nota-devolucao/components';
import { usePermissionsContext } from 'context/PermissionsContext';

interface NotaDevolucaoListColumnActionsProps {
    row: GridRowModel<any>;
    reload: () => void;
    showPrintBtn?: boolean;
    showRemoveBtn?: boolean;
}

export function NotaDevolucaoListColumnActions({ row, reload, showPrintBtn = true, showRemoveBtn = true }: NotaDevolucaoListColumnActionsProps): JSX.Element {
    const [loading, setLoading] = useState(false);

    return (
        <Box>
            <Centered hidden={!loading}>
                <CircularProgress size={16} />
            </Centered>

            <NotaDevolucaoListActionPrint row={row} hidden={loading || !showPrintBtn} />

            <NotaDevolucaoListActionDelete row={row} reload={reload} setLoading={setLoading} hidden={loading || !showRemoveBtn} />
        </Box>
    );
}
