import React, { useCallback } from 'react';

import { TypographyProps, IconButton, Box, Typography, BoxProps } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { LikesContextData } from 'context/LikeContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { useShowError } from 'hooks/useShowError';

interface LikeLikedProps {
    qtLikes: number;
    fgLiked: boolean;
}

export interface LikeBtnProps extends TypographyProps {
    likes?: number;
    liked?: boolean;
    small?: boolean;
    labelPrefix?: string;
    likeUrl?: string;
    context?: LikesContextData;
}

const validateLike = ({ likeUrl, context }: LikeBtnProps): boolean => {
    return Boolean(context && typeof likeUrl === 'string' && likeUrl.length);
};

const getLikeLikedVal = ({ context, likes, liked }: LikeBtnProps): LikeLikedProps => {
    const { stateLike } = context || {};
    let qtLikes = likes;
    let fgLiked = liked;

    if (stateLike) {
        qtLikes = stateLike.likes;
        fgLiked = stateLike.liked;
    }

    return {
        qtLikes: qtLikes ?? 0,
        fgLiked: fgLiked ?? false,
    };
};

const getLabelDefeaultProps = (small: boolean): BoxProps => {
    let labelProps = {};

    if (small) {
        labelProps = {
            sx: {
                fontSize: '1rem',
                color: 'grey.500',
            },
        };
    }

    return labelProps;
};

const LikeBtnContent = ({ sx, likes, liked, small = false, labelPrefix = '' }: LikeBtnProps): JSX.Element => {
    const labelProps = getLabelDefeaultProps(small);

    return (
        <Typography variant="h6" component="span" sx={{ mr: 3, p: 0, ...sx }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {liked ? <FavoriteIcon sx={{ mr: 1 }} /> : <FavoriteBorderIcon sx={{ mr: 1 }} />}
                <Box {...labelProps} component="span">
                    {likes} {labelPrefix}
                </Box>
            </Box>
        </Typography>
    );
};

export function LikeBtn({ likes, liked, likeUrl, context, small, labelPrefix, sx }: LikeBtnProps): JSX.Element {
    const { mutate } = useFormMutation();
    const { qtLikes, fgLiked } = getLikeLikedVal({ context, likes, liked });
    const validLike = validateLike({ likeUrl, context });
    const { showError } = useShowError();

    const handleIconClick = useCallback((): void => {
        if (likeUrl && context) {
            mutate(
                { url: likeUrl, preventSnack: true },
                {
                    onSuccess: () => context.dispachLike({ type: fgLiked ? 'unlike' : 'like' }),
                    onError: (error: unknown) => showError(error, `Ocorreu um erro ao aplicar seu ${fgLiked ? 'dislike' : 'like'}, tente novamente.`),
                },
            );
        }
    }, [likeUrl, context, mutate, fgLiked, showError]);

    return (
        <React.Fragment>
            {validLike && (
                <IconButton onClick={handleIconClick} sx={{ p: 0 }}>
                    <LikeBtnContent sx={sx} likes={qtLikes} liked={fgLiked} small={small} labelPrefix={labelPrefix} />
                </IconButton>
            )}

            {!validLike && <LikeBtnContent sx={sx} likes={qtLikes} liked={fgLiked} small={small} labelPrefix={labelPrefix} />}
        </React.Fragment>
    );
}
