import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { AzureUploadFileProps } from 'components/azureUpload/types';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { UploadButton } from 'components/upload';
import { ListagemProvider, useListagemContext } from 'context/ListagemContext';
import { UploadFile, useAzureUpload } from 'hooks/useAzureUpload';
import { useMimeTypes } from 'hooks/useMimeTypes';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { ProdutoImportarGrid } from 'pages/compra/produto/components/templates/ProdutoImportarGrid';
import { AzureUploadFileModal } from 'pages/compra/produto/types';

type ProdutoImportarModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const getFilesFormatted = (value: File[]): UploadFile[] => value.map((file) => ({ file: file, dsTitulo: file.name }));

export function ProdutoImportarModal({ open, setOpen }: ProdutoImportarModal): JSX.Element {
    const { autoLoad } = useListagemContext();
    const [files, setFiles] = useState<AzureUploadFileModal[]>([]);
    const accept = ['.jpg', '.jpeg', '.png', '.tiff'];
    const { handleMultipleUploadFile, isLoading: isLoadingAzure } = useAzureUpload();
    const { getFileNameWithoutExtension } = useMimeTypes();
    const { mutate, isLoading: isLoadingMutate } = useFormMutation<{ data: string[] }>();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const lastIdImage = useRef<number>(0);
    const abortController = useMemo(() => new AbortController(), [open]);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!open) {
            lastIdImage.current = 0;

            setFiles([]);
        }
    }, [open]);

    const handleImportar = (): void => {
        mutate(
            { url: '/gestao/produtoimagem/imagens', formData: files.map((item) => item.arquivo), preventSnack: true },
            {
                onSuccess: ({ data }) => {
                    if (autoLoad) {
                        queryClient.invalidateQueries(['listProdutos']);
                    }

                    if (Array.isArray(data) && data.length) {
                        enqueueSnackbar('As imagens listadas não puderam ser importadas. Verifique o código do produto delas e tente novamente', {
                            variant: 'warning',
                        });

                        setFiles((oldFiles) => oldFiles.filter((file) => data.includes(file.arquivo.dsTitulo)));
                    } else {
                        enqueueSnackbar('Imagens importadas com sucesso!', { variant: 'success' });

                        setOpen(false);
                    }
                },
                onError: (error: any) => showError(error),
            },
        );
    };

    const setNewFiles = (newFiles: AzureUploadFileProps[]): void => {
        const formattedNewFiles: AzureUploadFileModal[] = newFiles.map((file, index) => ({
            arquivo: { ...file.arquivo, dsTitulo: getFileNameWithoutExtension(file.arquivo.dsTitulo) },
            id: lastIdImage.current + 1 + index,
        }));

        lastIdImage.current += newFiles.length;

        setFiles((oldFiles) => [...oldFiles, ...formattedNewFiles]);
    };

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            onClose={() => abortController.abort()}
            closeButton
            sx={{
                borderRadius: 1,
                padding: 2,
                minWidth: {
                    xs: '85%',
                    sm: 600,
                },
            }}
        >
            <React.Fragment>
                <SubTitle label="Importar Imagens" />

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start', mb: 2 }}>
                    <Box sx={{ display: 'flex', minWidth: { xs: '100%', sm: 'auto' }, flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
                        <UploadButton
                            label="Selecionar arquivo"
                            accept={accept}
                            disabled={isLoadingAzure}
                            multiple
                            onChangeMultiple={(newFiles) =>
                                handleMultipleUploadFile({ files: getFilesFormatted(newFiles), signal: abortController.signal }).then(setNewFiles)
                            }
                        />

                        {Boolean(files?.length) && (
                            <Button
                                variant="outlined"
                                color="error"
                                disabled={isLoadingAzure}
                                startIcon={<DeleteOutlineIcon />}
                                onClick={() => {
                                    setFiles([]);

                                    lastIdImage.current = 0;
                                }}
                            >
                                Limpar seleção
                            </Button>
                        )}
                    </Box>

                    {Array.isArray(accept) && (
                        <Typography variant="caption" component="p">
                            *São aceitos somente arquivos em formato {accept.join(', ')}
                        </Typography>
                    )}
                </Box>

                <ListagemProvider>
                    <ProdutoImportarGrid files={files} setFiles={setFiles} loading={isLoadingAzure} />
                </ListagemProvider>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            abortController.abort();

                            setOpen(false);
                        }}
                    >
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" loading={isLoadingMutate} onClick={handleImportar} disabled={!files?.length || isLoadingAzure}>
                        Importar
                    </LoadingButton>
                </Box>
            </React.Fragment>
        </ContentModal>
    );
}
