import React from 'react';

import { OneColumn } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { TituloFilter, TituloAlert, TituloGrid, TituloRelatorio } from 'pages/financeiro/titulo/components/';

export function TituloListComp(): JSX.Element {
    const { hasPermission } = usePermissionsContext();

    return (
        <OneColumn title="Consulta de Títulos" extraContent={<TituloAlert />}>
            <TituloFilter />

            {hasPermission(['FINANCEIRO_TITULO_EXPORTAR']) && <TituloRelatorio />}

            <TituloGrid />
        </OneColumn>
    );
}
