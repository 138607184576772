import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { decodeToken } from 'react-jwt';

import { GenericAuth, TokenInfo } from 'context/GenericAuthContext';
import { useLocalStorage } from 'hooks/useLocalStorage';

interface CissLiveAuthProvider {
    children?: ReactNode;
}

interface AuthContextEmpresaProps {
    idEmpresa: number;
    dsRazaoSocial: string;
    dsNomeFantasia: string;
    dsEmail: string;
    portalTimezone: {
        dsPortalTimezone: string;
    };
}

interface AuthContextPessoaProps {
    idPessoa: number;
    dsPessoa: string;
    fgAtivo: boolean;
    dsEmail: string;
    tipoUsuario: 'SUPORTE_CISS' | 'MASTER' | 'FRANQUEADO';
    grupos: number[];
}
interface AuthContextDecodedTokenProps {
    id_cliente: number;
    id_usuario: number;
    ds_hostacesso: string;
    cliente_fg_ativo: boolean;
    id_tipo_usuario: number;
    id_empresa: number;
    iat: number;
    ext: number;
    info: {
        empresaLogada: AuthContextEmpresaProps;
        pessoa: AuthContextPessoaProps;
    };
}

const getDecodedTokenInfo = (decodedToken: AuthContextDecodedTokenProps | null): TokenInfo | null => {
    if (decodedToken !== null && typeof decodedToken === 'object') {
        const { idEmpresa, dsEmail: dsEmailEmpresa, dsNomeFantasia: dsNomeEmpresa, portalTimezone } = decodedToken.info.empresaLogada;
        const { dsPortalTimezone: dsTimezone } = portalTimezone;
        const { grupos: idGrupos, idPessoa: idUsuario, tipoUsuario: tpUsuario, dsPessoa: dsUsuario, dsEmail } = decodedToken.info.pessoa;

        return { dsTimezone, idEmpresa, dsEmailEmpresa, dsNomeEmpresa, idGrupos, idUsuario, tpUsuario, dsUsuario, dsEmail };
    }

    return null;
};

export const AuthContext = createContext<GenericAuth>({} as GenericAuth);

export function CissLiveAuthProvider({ children }: CissLiveAuthProvider): JSX.Element {
    const { value: accessToken, removeValue: removeToken, setValue: setToken } = useLocalStorage('AuthToken');
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const initialPathname = useRef('');
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const isLoggingOut = useRef<boolean>(false);

    useEffect(() => {
        if (['/login-ativacao', '/login-recuperacao-senha'].includes(pathname)) {
            return removeToken();
        }

        if (accessToken) {
            //Caso tenha token válido e está com o modal aberto, o fecha
            if (openLoginModal) {
                setOpenLoginModal(false);
            }

            // Caso acabou de fazer o login pela tela de login
            if (pathname === '/login') {
                // Caso tenha uma rota alternativa à do login, redireciona até ela com os filtros
                if (initialPathname.current) {
                    navigate(initialPathname.current.replace('?url-redirect=', ''));

                    initialPathname.current = '';
                }
                // Senão, vai até a tela inical
                else {
                    navigate('/');
                }
            }
        } else {
            // Caso está acessando o portal pela primeira vez com o token expirado
            if (isFirstTime) {
                // E estiver nessas rotas, vai à tela de login
                if (['/login', '/'].includes(pathname)) {
                    navigate('/login');
                }
                // Senão, vai à tela de login com a rota personalizada
                else {
                    navigate(`/login?url-redirect=${pathname}${search}`);

                    initialPathname.current = pathname + search;
                }
            }
            // Caso está fazendo o logout, apenas troca a referência pra falso e nã faz nada
            else if (isLoggingOut.current) {
                isLoggingOut.current = false;
            }
            // Senão, abre o modal de login
            else {
                setOpenLoginModal(true);
            }
        }
    }, [accessToken]);

    // Remove o token e limpa os caches
    const resetSession = (): void => {
        removeToken();

        queryClient.clear();
    };

    // Realiza o remeção do token e vai à tela de login
    const logout = (): void => {
        // Caso tem o token, remove ele e define que está fazendo o logout
        if (accessToken) {
            resetSession();

            isLoggingOut.current = true;
        }

        navigate('/login');

        if (openLoginModal) {
            setOpenLoginModal(false);
        }
    };

    const updatePassword = (): void => {
        throw new Error('O método updatePassword não é suportado neste tipo de auth');
    };

    const contextValue = useMemo(() => {
        const decodedToken = accessToken ? decodeToken<AuthContextDecodedTokenProps>(accessToken) : null;
        const tokenInfo = getDecodedTokenInfo(decodedToken);

        return {
            isAuthenticated: !!accessToken,
            logout,
            resetSession,
            tokenInfo,
            accessToken,
            setToken,
            openLoginModal,
            setOpenLoginModal,
            isFirstTime,
            setIsFirstTime,
            updatePassword,
            isUpdatablePassword: false,
        };
    }, [accessToken, openLoginModal, isFirstTime]);

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export const useGenericAuthContext = (): GenericAuth => useContext(AuthContext);
