import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Box, Typography } from '@mui/material';

import { ExportReportBtn } from 'components/buttons';
import { Block } from 'components/page';

export function TabelaPrecoRelatorio(): JSX.Element {
    return (
        <Block>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alinItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TableViewOutlinedIcon color="primary" />

                    <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIO REFERENTE AOS FILTROS INFORMADOS</Typography>

                    <ChevronRightOutlinedIcon color="primary" />
                </Box>

                <ExportReportBtn dsChave="ID_RELATORIO_TABELA_PRECO" />
            </Box>
        </Block>
    );
}
