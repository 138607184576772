import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissNumberField, CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function NotaDevolucaoListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dtFiltro',
        operator: 'bt',
        startDate: null,
        endDate: null,
        required: 'Intervalo de emissão é obrigatório',
    });

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdCliente', // ao alterar propriedade lembrar de alterar leitura dela no método getDsLoja tela listagem nota devolução
        operator: 'eq',
        value: null,
        idProperty: 'cdLoja',
        required: 'Loja é obrigatória',
    });

    const [filterCodigo, setFilterCodigo] = useState<FilterValuesProps>({
        property: 'nrOrdemVenda',
        operator: 'eq',
        value: '',
    });

    const filters = useMemo(
        () => [
            { value: filterInterval, setter: setFilterInterval },
            { value: filterLoja, setter: setFilterLoja },
            { value: filterCodigo, setter: setFilterCodigo },
        ],
        [filterCodigo, filterInterval, filterLoja],
    );

    const resetFilter = useCallback((): void => {
        handleFilterReset({ filters });
    }, [filters, handleFilterReset]);

    const startFilter = useCallback((): void => {
        handleFilterSubmit({ filters });
    }, [filters, handleFilterSubmit]);

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <StoreAutoComplete value={filterLoja.value} onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={2}>
                <DateRangePicker
                    value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                    onChange={([startDate, endDate]) => {
                        setFilterInterval((oldState: FilterValuesProps) => ({
                            ...oldState,
                            startDate,
                            endDate,
                        }));
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <CissTextField {...startProps} label="Início Emissão" autoComplete="off" />
                            <Box sx={{ mx: 2 }}> até </Box>
                            <CissTextField {...endProps} label="Final Emissão" autoComplete="off" />
                        </React.Fragment>
                    )}
                />
            </Grid>

            <Grid item flex={1}>
                <CissNumberField
                    label="Código"
                    value={filterCodigo.value}
                    onChange={(e) => setFilterCodigo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                    preventEmptyField={false}
                />
            </Grid>
        </Filter>
    );
}
