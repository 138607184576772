import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface TradeTag {
    idTagPecaTrade?: number;
    dsTagPecaTrade: string;
}
export const initialTagValues: TradeTag = {
    dsTagPecaTrade: '',
};

export interface RequestTradeTag extends RequestListagemProps {
    data: TradeTag[];
}
