import React from 'react';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { CissTextField } from 'components/fields/';

interface DatePickerMonthYearProps extends Omit<DatePickerProps<Date, Date>, 'views' | 'label' | 'renderInput'> {
    label?: string;
}

/**
 * Componente data Mes/Ano com props pré-definidas
 */
export function DatePickerMonthYear({ label = 'Mês/Ano', ...props }: DatePickerMonthYearProps): JSX.Element {
    return <DatePicker views={['year', 'month']} label={label} inputFormat="MM/yyyy" renderInput={(params) => <CissTextField {...params} autoComplete="off" />} {...props} />;
}
