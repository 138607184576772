import { useEffect, useState } from 'react';

type KeyProp = string | number;

interface handlerFn {
    key: KeyProp;
}

export function useFieldKeyPress(targetKey: KeyProp): boolean {
    const [keyPressed, setKeyPressed] = useState(false);

    function downHandler({ key }: handlerFn): void {
        const inputFocus = document?.activeElement?.tagName.toLowerCase();

        if (key === targetKey && inputFocus === 'input') {
            setKeyPressed(true);
        }
    }

    const upHandler = ({ key }: handlerFn): void => {
        const inputFocus = document?.activeElement?.tagName.toLowerCase();

        if (key === targetKey && inputFocus === 'input') {
            setKeyPressed(false);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    return keyPressed;
}
