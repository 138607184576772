import React, { useMemo } from 'react';

import { Box, Button, Link } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

type RequestConfigProps = string;

interface DetalhamentoCreditosImportacaoUploadBtnProps {
    dsChave: 'URL_ARQUIVO_MODELO_DETALHAMENTO_CREDITO';
}

export function DetalhamentoCreditosImportacaoModeloBtn({ dsChave }: DetalhamentoCreditosImportacaoUploadBtnProps): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/config/configgeral/chave',
            extraParams: [{ dsChave }],
        }),
        [dsChave],
    );

    const { data } = useQuery([requestOptions], () => {
        const request: Promise<RequestConfigProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    return (
        <Box hidden={Boolean(!data)}>
            <Button href={data} component={Link} target="_blank" sx={{ width: '290px' }} variant="outlined" color="success" startIcon={<RiFileExcel2Fill />}>
                Download do arquivo modelo
            </Button>
        </Box>
    );
}
