import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

interface ResponsiveProps {
    children?: ReactNode;
    type?: 'wide' | 'square' | 'fixed';
    height?: number;
    paddingBottom?: string;
}

export function Responsive({ children, type = 'wide', height: userHeight, paddingBottom: userPadding }: ResponsiveProps): JSX.Element {
    let paddingBottom = userPadding ?? '0';
    let height = userHeight ?? 0;

    if (type === 'wide') {
        paddingBottom = '56.25%';
    } else if (type === 'fixed' && userPadding) {
        paddingBottom = userPadding;
    } else if (type === 'fixed' && userHeight) {
        height = userHeight;
    } else if (type === 'square') {
        paddingBottom = '100%';
    }

    return (
        <Box
            sx={{
                'position': 'relative',
                'paddingBottom': paddingBottom,
                'width': '100%',
                'height': `${height}px`,
                'overflow': 'hidden',

                'iframe, object, embed': {
                    border: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            {children}
        </Box>
    );
}
