import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { EstiloFonteResponse } from 'pages/predefinicoes-estilo/types';

export function PredefinicoesEstiloListComp(): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/estilofonte',
        sort: [{ property: 'dsEstiloFonte', direction: 'ASC' }],
    };

    const { data, fetchStatus, refetch } = useQuery([requestOptions], (): Promise<EstiloFonteResponse> => RequestListagem(requestOptions).then((res) => res.data));

    const makeRows = (): GridRowsProp => {
        if (!data) {
            return [];
        }

        return data.data.map((estiloFonte, index) => ({
            id: index,
            idEstiloFonte: estiloFonte.idEstiloFonte,
            dsEstiloFonte: estiloFonte.dsEstiloFonte,
            dsFonte: estiloFonte.dsFonte,
            nrTamanho: estiloFonte.nrTamanho,
            dsCor: estiloFonte.dsCor,
        }));
    };

    const columns: GridColDef[] = [
        { field: 'idEstiloFonte', headerName: 'Cód.', type: 'string', width: 60 },
        { field: 'dsEstiloFonte', headerName: 'Nome', type: 'string', flex: 1 },
        { field: 'dsFonte', headerName: 'Nome da fonte', type: 'string', flex: 1 },
        { field: 'nrTamanho', headerName: 'Tamanho da fonte', type: 'string', width: 150 },
        {
            field: 'dsCor',
            headerName: 'Cor da fonte',
            type: 'string',
            flex: 1,
            renderCell: ({ row }) => (
                <React.Fragment>
                    {row.dsCor}
                    <div style={{ backgroundColor: row.dsCor, width: 25, height: 25, marginLeft: 10, borderRadius: '100%' }} />
                </React.Fragment>
            ),
        },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            minWidth: 70,
            renderCell: ({ row }) => <EditRemoveBtn id={row.idEstiloFonte} removeUrl={`/gestao/estilofonte/${row.idEstiloFonte}`} refresh={refetch} />,
        },
    ];

    const rows = makeRows();

    return (
        <OneColumn title="Predefinições de estilo" goBackButton>
            <Block>
                <PanelGrid rows={rows} rowsCount={rows.length} columns={columns} loading={fetchStatus === 'fetching'} reload={refetch} />
            </Block>
        </OneColumn>
    );
}
