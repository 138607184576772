import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface EstatisticasExportarRelatorioBtnProps {
    disabled: boolean;
}

export function EstatisticasExportarRelatorioBtn({ disabled }: EstatisticasExportarRelatorioBtnProps): JSX.Element {
    const { filter } = useListagemContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/documento/exportar',
            sort: [{ property: 'idDocumento', direction: 'DESC' }],
            filter,
        }),
        [filter],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClickDownload = useCallback((): void => {
        downloadFile({
            newRequestOptions: requestOptions,
            directDownload: true,
        });
    }, [downloadFile, requestOptions]);

    return (
        <LoadingButton
            loading={isLoadingFile}
            variant="outlined"
            onClick={handleClickDownload}
            disabled={disabled || isLoadingFile}
            sx={{ color: 'green', borderColor: 'green' }}
            startIcon={<RiFileExcel2Fill />}
        >
            Relatório em .xlsx
        </LoadingButton>
    );
}
