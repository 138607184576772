import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { Image, Layer, Stage } from 'react-konva';
import useImage from 'use-image';

import { Centered } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { MarketingTradeExportTypeModal, MarketingTradeKonvaText } from 'pages/marketing-trade/components';
import { Atributo, Field, PainelPecasMarketingTradeProps } from 'pages/marketing-trade/types';

const mapAtributosToFields = (atributos: Atributo[] = []): Field[] => {
    return atributos.sort((atrA, atrB) => (atrA.nrSequencia > atrB.nrSequencia ? 1 : -1)).map((atributo): Field => JSON.parse(atributo.dsJsonAtributoEdicao));
};

export const MarketingTradePainelPecas = ({ arquivoDownload, formatoImagem }: PainelPecasMarketingTradeProps): JSX.Element => {
    const [image, status] = useImage(arquivoDownload.arquivo.dsLink, 'anonymous');
    const [fields, setFields] = useState<Field[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const stageRef = useRef<any>();
    const canvasWidth = 800;

    const valueToNormalize = useMemo(() => {
        let value = 1;

        if (status === 'loaded') {
            if ((image?.width || 0) > canvasWidth) {
                value = Math.ceil((image?.width || 0) / canvasWidth);
            }
        }
        
        return value;
    }, [image?.width, status]);

    const normalize = useCallback((value: number | string) => Number(value) / valueToNormalize, [valueToNormalize]);

    useEffect(() => {
        setFields(mapAtributosToFields(arquivoDownload.atributos));
    }, [arquivoDownload.atributos]);

    return (
        <Block>
            <MarketingTradeExportTypeModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                valueStage={stageRef?.current}
                valueToNormalize={valueToNormalize}
                formatoImagem={formatoImagem}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <SubTitle label="Pré-visualização" />
                    {status === 'loaded' ? (
                        <React.Fragment>
                            <Stage width={normalize(image?.width || 0)} height={normalize(image?.height || 0)} style={{ display: 'flex', justifyContent: 'center' }} ref={stageRef}>
                                <Layer>
                                    <Image image={image} scale={{ x: normalize(1), y: normalize(1) }} />
                                    {fields.map((field) => (
                                        <MarketingTradeKonvaText
                                            key={field.id}
                                            text={field.value}
                                            font={field.font}
                                            width={normalize(image?.width || 0)}
                                            x={normalize(field.x)}
                                            y={normalize(field.y)}
                                            rotation={field.rotation}
                                            draggable={false}
                                            isSelected={false}
                                        />
                                    ))}
                                </Layer>
                            </Stage>
                            <IconButton sx={{ width: 60 }} onClick={() => setIsModalVisible(true)}>
                                <CloudDownloadIcon
                                    fontSize="large"
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                />
                            </IconButton>
                        </React.Fragment>
                    ) : (
                        <Centered>
                            <CircularProgress sx={{ my: 2 }} size="1.5rem" />
                        </Centered>
                    )}
                </Grid>

                <Grid item xs={12} lg={4} sx={{ minWidth: 305 }}>
                    <SubTitle label="Atributos" />
                    <Grid item direction={'column'} display={'flex'} container={true} sx={{ mt: 3 }}>
                        {fields.map((field) => (
                            <TextField
                                key={field.id}
                                placeholder={field.name}
                                label={field.name}
                                style={{ marginBottom: 15 }}
                                value={field.value}
                                variant="outlined"
                                inputProps={{ maxLength: field.valueLimit || null }}
                                onChange={(e: any) => {
                                    field.value = e.target.value;

                                    setFields(
                                        fields.map((f) => {
                                            return f.id === field.id ? field : f;
                                        }),
                                    );
                                }}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Block>
    );
};
