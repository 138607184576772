import React, { useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton, Tooltip } from '@mui/material';

import { RevendedorFilesModal } from 'pages/revendedor/revendedor/components';
import { RevendedorFile } from 'pages/revendedor/revendedor/types';

type RevendedorFileButton = {
    files: RevendedorFile[];
};

export function RevendedorFileButton({ files }: RevendedorFileButton): JSX.Element {
    const [openFilesModal, setOpenFilesModal] = useState(false);

    return (
        <>
            <RevendedorFilesModal files={files} open={openFilesModal} setOpen={setOpenFilesModal} />

            <Tooltip title="Anexos">
                <span>
                    <IconButton sx={{ p: 0.5 }} onClick={() => setOpenFilesModal(true)} disabled={!files.length}>
                        <AttachFileIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
}
