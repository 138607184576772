import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

interface CustomSnackbarProviderProps {
    children?: ReactNode;
}

export function CustomSnackbarProvider({ children }: CustomSnackbarProviderProps): JSX.Element {
    return (
        <SnackbarProvider
            dense
            maxSnack={5}
            disableWindowBlurListener
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {children}
        </SnackbarProvider>
    );
}
