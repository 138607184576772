import React, { useCallback, useEffect, useRef, FocusEvent, MouseEvent } from 'react';

import { Box, Button, SxProps } from '@mui/material';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { ProdutoPedido, ProdutoCart } from 'pages/compra/pedido/types';

interface PedidoCompraAmmountProps {
    modifyQtd: (produto: any, type: 'remove' | 'add' | 'fixed', value?: number) => void; // TODO analisar pq produto não pode receber ProdutoPedidos | ProdutosCart
    getQtd: (id: number) => number;
    produtoPedido: ProdutoPedido | ProdutoCart;
    sx?: SxProps;
}

export function PedidoCompraAmmount({ modifyQtd, getQtd, produtoPedido, sx }: PedidoCompraAmmountProps): JSX.Element {
    const { isProdutoDisabled, getProdEstqQtd, configPedidoControlaEstoque, getProdMinQtd, getProdMaxQtd, getMaxQtdValidated, isSavingCarrinho } =
        usePedidoCompraContext();
    const { produto, qtMinimaCompra } = produtoPedido;
    const val = getQtd(produto.idProduto);
    const produtoDisabled = isProdutoDisabled(produtoPedido);
    const inputRef = useRef<HTMLInputElement>(null);
    const prodMaxEstqQtd = getProdEstqQtd(produtoPedido);
    const prodMinQtd = getProdMinQtd(qtMinimaCompra);
    const prodMaxQtd = getProdMaxQtd(produtoPedido);

    const findNearestQtd = useCallback((value: number): number => Math.ceil(value / prodMinQtd) * prodMinQtd, [prodMinQtd]);

    const handleBlurQtdField = (event: FocusEvent<HTMLInputElement>): void => {
        const value = Number(event.target.value);
        const nearestQtd = findNearestQtd(value);

        let newValue = nearestQtd;

        // Caso for maior que 0, valida se não extrapola estoque ou a quantidade máxima e exibe modal
        if (newValue > 0) {
            newValue = getMaxQtdValidated(nearestQtd, produtoPedido);
        } else {
            newValue = 0;
        }

        modifyQtd(produtoPedido, 'fixed', newValue);

        if (inputRef?.current) {
            if (value !== newValue) {
                inputRef.current.value = newValue.toString();
            } else if (inputRef.current.value.length === 0) {
                inputRef.current.value = '0';
            }
        }
    };

    const handleClickQtdField = useCallback((event: MouseEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;

        target.select();
    }, []);

    useEffect(() => {
        if (inputRef?.current) {
            const actualValue = Number(inputRef.current.value);

            if (actualValue !== val) {
                inputRef.current.value = val.toString();
            }
        }
    }, [val]);

    return (
        <Box
            sx={{
                display: 'flex',
                height: 36,
                ...sx,
            }}
        >
            <Button
                variant="outlined"
                disabled={produtoDisabled || val === 0 || isSavingCarrinho}
                onClick={() => modifyQtd(produtoPedido, 'remove')}
                sx={{
                    p: 0,
                    minWidth: 32,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRight: 0,
                }}
            >
                -
            </Button>

            <input
                ref={inputRef}
                type="number"
                disabled={produtoDisabled || isSavingCarrinho}
                defaultValue={getQtd(produto.idProduto)}
                onBlur={handleBlurQtdField}
                onClick={handleClickQtdField}
                style={{
                    textAlign: 'center',
                    width: '100%',
                }}
            />

            <Button
                variant="outlined"
                disabled={
                    produtoDisabled ||
                    (val >= prodMaxEstqQtd && configPedidoControlaEstoque) ||
                    !!(prodMaxQtd && val >= prodMaxQtd) ||
                    isSavingCarrinho
                }
                onClick={() => modifyQtd(produtoPedido, 'add')}
                sx={{
                    p: 0,
                    minWidth: 32,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: 0,
                }}
            >
                +
            </Button>
        </Box>
    );
}
