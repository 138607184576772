import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { alpha, Box, Button, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ConfirmModal, ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { Upload } from 'components/upload';
import { UploadFileProps } from 'components/upload/types';
import { useFormMutation } from 'mutations/useFormMutation';
import { ProdutoAlterarImagemProps } from 'pages/compra/produto/types';

interface ProdutoAlterarImagemModalProps {
    open: boolean | ProdutoAlterarImagemProps;
    setOpen: Dispatch<SetStateAction<boolean | ProdutoAlterarImagemProps>>;
}

export function ProdutoAlterarImagemModal({ open, setOpen }: ProdutoAlterarImagemModalProps): JSX.Element {
    const [fileImagem, setFileImagem] = useState<UploadFileProps[]>([]);
    const [openRemoverModal, setOpenRemoverModal] = useState<boolean>(false);
    const { mutate, isLoading } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!open) {
            setFileImagem([]);
        }
    }, [open]);

    const onClickBtn = useCallback(
        (removerImagem = false): void => {
            if (typeof open !== 'boolean') {
                mutate(
                    {
                        url: '/gestao/produtoimagem',
                        preventSnack: true,
                        formData: {
                            fgPrincipal: true,

                            produto: {
                                idProduto: open.idProduto,
                            },

                            imagem: {
                                lkImagem: removerImagem ? '' : fileImagem[0].arquivo.dsLink,
                                tipoImagem: 'IMAGEM',
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            setOpen(false);

                            enqueueSnackbar(`Imagem ${removerImagem ? 'removida' : 'alterada'} com sucesso.`, { variant: 'success' });

                            queryClient.invalidateQueries(['listProdutos']);
                        },
                    },
                );
            }
        },
        [enqueueSnackbar, fileImagem, mutate, open, queryClient, setOpen],
    );

    return (
        <React.Fragment>
            <ConfirmModal open={openRemoverModal} setOpen={setOpenRemoverModal} text="Tem certeza que deseja remover a imagem atual?" confirm={() => onClickBtn(true)} />

            <ContentModal sx={{ borderRadius: 1 }} open={Boolean(open)} setOpen={setOpen} closeButton>
                <Box>
                    <SubTitle label="Alterar Imagem" />

                    {typeof open !== 'boolean' && open.lkImagem && (
                        <Box
                            sx={{
                                flex: 1,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundImage: `url("${open.lkImagem}")`,
                                border: (theme) => `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
                                minHeight: {
                                    sm: 300,
                                    xs: 200,
                                },
                                minWidth: {
                                    lg: 300,
                                    xs: 200,
                                },
                                mb: 2,
                            }}
                        />
                    )}

                    <Upload accept={['.jpg', '.jpeg', '.png', '.tiff']} listFiles={fileImagem} setListFiles={(list) => setFileImagem(list)} />

                    <Stack
                        spacing={2}
                        justifyContent="flex-end"
                        direction="row"
                        sx={{
                            mt: 2,
                        }}
                    >
                        <Button variant="outlined" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>

                        <Button
                            variant="contained"
                            startIcon={<DeleteOutlineIcon />}
                            color="error"
                            onClick={() => setOpenRemoverModal(true)}
                            disabled={isLoading || (typeof open !== 'boolean' && !open.lkImagem)}
                        >
                            Remover Imagem Atual
                        </Button>

                        <Button variant="contained" color="success" onClick={() => onClickBtn()} disabled={isLoading || fileImagem.filter((item) => item.fgAtivo).length < 1}>
                            Alterar Imagem
                        </Button>
                    </Stack>
                </Box>
            </ContentModal>
        </React.Fragment>
    );
}
