import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ColumnTabelaProps, RequestTabelaPrecoProduto, TabelaPrecoProduto } from 'pages/compra/tabela-preco/types';
import { brlPrice } from 'util/format';

const getRows = ({ data, columnsTabela }: { data?: TabelaPrecoProduto[]; columnsTabela?: ColumnTabelaProps[] }): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => {
            const obj: { [key: string]: string | number | boolean } = {
                id: index + 1,
                cdProduto: item.cdProduto,
                dsProduto: item.dsProduto,
                sgUnidadeMedida: item.unidadeMedida.sgUnidadeMedida,
                qtMinimaCompra: item.qtMinimaCompra,
                fgAtivo: item.fgAtivo,
            };

            if (columnsTabela) {
                columnsTabela.forEach((column: ColumnTabelaProps) => {
                    const tabelaPreco = item.listTabelaPreco?.find((item) => item.tabelaPreco.idTabelaPreco === column.idTabelaPreco);

                    if (tabelaPreco) {
                        obj[column.dsField] = tabelaPreco?.vlPrecoBase;
                    }
                });
            }

            return obj;
        });
    }

    return [];
};

const getColumnsTabelasFormatted = (columnsTabela?: ColumnTabelaProps[]): GridColDef[] => {
    if (columnsTabela) {
        return columnsTabela.map(
            (column: ColumnTabelaProps): GridColDef => ({
                field: column.dsField,
                headerName: column.dsHeader,
                minWidth: 90,
                flex: 1,
                sortable: false,
                valueFormatter: ({ value }) => (value ? brlPrice(value) : ''),
            }),
        );
    }

    return [];
};

export function TabelaPrecoGrid(): JSX.Element {
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const { setContent } = useFormContext();
    const RequestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/produtowrapper/tabelapreco',
        sort: sort.length ? sort : [{ property: 'dsProduto', direction: 'asc' }],
        limit,
        page,
        filter,
    };

    const {
        isFetching: isFetchingTabela,
        data,
        refetch,
    } = useQuery<RequestTabelaPrecoProduto>([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
        onSuccess: (data) => setContent(data?.total),
    });

    const requestOptionsColumnsTabelas: RequestOptionsType = {
        url: '/gestao/configtabelaprecoexibicao/all',
    };

    const { data: columnsTabela, isFetching: isFetchingColunas } = useQuery<ColumnTabelaProps[]>(
        [requestOptionsColumnsTabelas],
        () => RequestListagem(requestOptionsColumnsTabelas).then((res) => res.data),
        { onError: () => enqueueSnackbar('Não foi possível carregar as colunas das tabelas de preços', { variant: 'error' }) },
    );

    const columns = useMemo((): GridColDef[] => {
        const baseColumns: GridColDef[] = [
            { field: 'cdProduto', headerName: 'Código', minWidth: 60 },
            { field: 'dsProduto', headerName: 'Nome do Produto', flex: 2, minWidth: 130 },
            { field: 'sgUnidadeMedida', headerName: 'Unidade', width: 100, minWidth: 70 },
            { field: 'qtMinimaCompra', headerName: 'Quantidade Mínima', minWidth: 130 },
            { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean', minWidth: 50 },
        ];

        const columnsTabelaFormatted = getColumnsTabelasFormatted(columnsTabela);

        if (columnsTabelaFormatted && columnsTabelaFormatted.length > 0) {
            baseColumns.splice(3, 0, ...columnsTabelaFormatted);
        }

        return baseColumns;
    }, [columnsTabela]);

    return (
        <Block>
            <PanelGrid rows={getRows({ data: data?.data, columnsTabela })} reload={refetch} loading={isFetchingTabela || isFetchingColunas} columns={columns} rowsCount={data?.total ?? 0} />
        </Block>
    );
}
