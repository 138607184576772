import React, { useState } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DadosEntregaListFilter, DadosEntregaImportarModal } from 'pages/complementar/dados-entrega/components';
import { ConfigEstadoRequestProps } from 'pages/complementar/dados-entrega/types';
import { brlPrice } from 'util/format';

interface ResponseData {
    data: ConfigEstadoRequestProps[];
    hasNext: boolean;
    total: number;
}

const getRows = (rows: ConfigEstadoRequestProps[] | undefined): GridRowsProp => (!rows ? [] : rows.map((row) => ({ ...row, id: row.idConfigEstado })));

export function DadosEntregaListComp(): JSX.Element {
    const { sort, page, filter, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const [openImportarModal, setOpenImportarModal] = useState<boolean>(false);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/configestado',
        columns: 'idConfigEstado,vlFrete,nrDiasPrevisaoEntrega,dsEstado,dsTransportadora',
        sort: sort?.length ? sort : [{ property: 'idConfigEstado', direction: 'DESC' }],
        page,
        filter,
        limit,
    };

    const { data, refetch, fetchStatus } = useQuery(['getListFretePrazos', requestOptions], (): Promise<ResponseData> => RequestListagem(requestOptions).then((res) => res.data));

    const columns: GridColDef[] = [
        { field: 'dsEstado', headerName: 'Estado', flex: 1, minWidth: 100 },
        { field: 'vlFrete', headerName: 'Valor Frete', flex: 1, width: 100, minWidth: 110, valueFormatter: ({ value }) => brlPrice(value) },
        {
            field: 'nrDiasPrevisaoEntrega',
            headerName: 'Previsão entrega',
            flex: 1,
            minWidth: 100,
            valueFormatter: ({ value }) => `${value} dias`,
        },
        { field: 'dsTransportadora', headerName: 'Transportadora', flex: 1, width: 100, minWidth: 110 },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 80,
            minWidth: 60,
            renderCell: ({ row }) => <EditRemoveBtn id={row.idConfigEstado} showEditBtn={true} refresh={refetch} />,
        },
    ];

    return (
        <React.Fragment>
            <DadosEntregaImportarModal open={openImportarModal} setOpen={setOpenImportarModal} />

            <OneColumn
                title="Fretes e Prazos"
                extraButtons={[
                    {
                        title: 'Importar',
                        onClick: () => setOpenImportarModal(true),
                    },
                ]}
            >
                <DadosEntregaListFilter />

                <Block>
                    <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
                </Block>
            </OneColumn>
        </React.Fragment>
    );
}
