import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';

export type SetFilterValuesProps = Dispatch<SetStateAction<FilterValuesProps>>;

export type PedidoFilterData = {
    filterDsProduto: FilterValuesProps;
    setFilterDsProduto: SetFilterValuesProps;
    filterCdProduto: FilterValuesProps;
    setFilterCdProduto: SetFilterValuesProps;
    filterLoja: FilterValuesProps;
    setFilterLoja: SetFilterValuesProps;
    filterJornada: FilterValuesProps;
    setFilterJornada: SetFilterValuesProps;
    filterConfigPedido: FilterValuesProps;
    setFilterConfigPedido: SetFilterValuesProps;
    filterTipoEntrega: FilterValuesProps;
    setFilterTipoEntrega: SetFilterValuesProps;
    filterFuncionario: FilterValuesProps;
    setFilterFuncionario: SetFilterValuesProps;
    filterMarca: FilterValuesProps;
    setFilterMarca: SetFilterValuesProps;
    setDefaultFilterLoja: () => void;
    validateLoja: () => boolean;
    validateConfigPedido: () => boolean;
    validateChangeCarrinho: () => boolean;
    openChangeConfigModal: boolean;
    setOpenChangeConfigModal: Dispatch<SetStateAction<boolean>>;
};

type PedidoFilterProvider = {
    children?: ReactNode;
};

const PedidoFilterContext = createContext<PedidoFilterData>({} as PedidoFilterData);

export function PedidoFilterProvider({ children }: PedidoFilterProvider): JSX.Element {
    const { formattedLoja } = useAplicacaoDataContext();
    const { carrinhoChanged, configPedido } = usePedidoCompraContext();
    const { tipoPedido } = useGetDomainConfig();

    const [filterDsProduto, setFilterDsProduto] = useState<FilterValuesProps>({
        property: 'dsProduto',
        operator: 'like',
        logicalOperator: 'OR',
        value: '',
    });

    const [filterCdProduto, setFilterCdProduto] = useState<FilterValuesProps>({
        property: 'cdProduto',
        operator: 'like',
        logicalOperator: 'OR',
        value: '',
    });

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        idProperty: 'idLoja',
        operator: 'eq',
        value: null,
    });

    const [filterJornada, setFilterJornada] = useState<FilterValuesProps>({
        property: 'idGrupo',
        idProperty: 'idGrupo',
        operator: 'eq',
        value: null,
    });

    const [filterConfigPedido, setFilterConfigPedido] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        idProperty: 'idConfigPedido',
        operator: 'eq',
        value: null,
    });

    const [filterTipoEntrega, setFilterTipoEntrega] = useState<FilterValuesProps>({
        property: 'idTipoEntrega',
        idProperty: 'idTipoEntrega',
        operator: 'eq',
        value: null,
    });

    const [filterFuncionario, setFilterFuncionario] = useState<FilterValuesProps>({
        property: 'idUsuarioPedido',
        idProperty: 'idUsuario',
        operator: 'eq',
        value: null,
    });

    const [filterMarca, setFilterMarca] = useState<FilterValuesProps>({
        property: 'idMarca',
        idProperty: 'idMarca',
        operator: 'eq',
        value: null,
    });

    const [openChangeConfigModal, setOpenChangeConfigModal] = useState<boolean>(false);

    const setDefaultFilterLoja = useCallback((): void => setFilterLoja((oldValue) => ({ ...oldValue, value: formattedLoja })), [formattedLoja]);

    const validateLoja = useCallback((): boolean => {
        const isValid = filterLoja.value;

        if (!isValid) {
            enqueueSnackbar('Loja é obrigatória', { variant: 'error' });
        }

        return isValid;
    }, [filterLoja.value]);

    const validateConfigPedido = useCallback((): boolean => {
        const isValid = filterConfigPedido.value;

        if (!isValid) {
            enqueueSnackbar(`${tipoPedido === 'funcionario' ? 'Campanha de produtos' : 'Configuração de Pedido'} é obrigatória`, {
                variant: 'error',
            });
        }

        return isValid;
    }, [filterConfigPedido.value]);

    const validateChangeCarrinho = useCallback((): boolean => {
        const idConfigPedido = filterConfigPedido?.value?.idConfigPedido;

        let isValid = true;

        if (carrinhoChanged && configPedido && configPedido.idConfigPedido !== idConfigPedido) {
            setOpenChangeConfigModal(true);

            isValid = false;
        }

        return isValid;
    }, [carrinhoChanged, configPedido, filterConfigPedido?.value?.idConfigPedido]);

    const contextValue = useMemo(
        () => ({
            filterDsProduto,
            setFilterDsProduto,
            filterCdProduto,
            setFilterCdProduto,
            filterLoja,
            setFilterLoja,
            filterJornada,
            setFilterJornada,
            filterConfigPedido,
            setFilterConfigPedido,
            filterTipoEntrega,
            setFilterTipoEntrega,
            filterFuncionario,
            setFilterFuncionario,
            filterMarca,
            setFilterMarca,
            setDefaultFilterLoja,
            validateLoja,
            validateConfigPedido,
            validateChangeCarrinho,
            openChangeConfigModal,
            setOpenChangeConfigModal,
        }),
        [
            filterCdProduto,
            filterConfigPedido,
            filterDsProduto,
            filterFuncionario,
            filterJornada,
            filterLoja,
            filterMarca,
            filterTipoEntrega,
            openChangeConfigModal,
            setDefaultFilterLoja,
            validateChangeCarrinho,
            validateConfigPedido,
            validateLoja,
        ],
    );

    return <PedidoFilterContext.Provider value={contextValue}>{children}</PedidoFilterContext.Provider>;
}

export const usePedidoFilterContext = (): PedidoFilterData => useContext(PedidoFilterContext);
