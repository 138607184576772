import React from 'react';

import { ReportBtn } from 'components/buttons';
import { OneColumn } from 'components/page';
import { PromocaoGrid } from 'pages/revendedor/promocao/components';

export function PromocaoListComp(): JSX.Element {
    return (
        <OneColumn
            title="Promoções"
            createButton
            extraContent={
                <ReportBtn
                    requestOptions={{
                        url: '/integracao-revendedor/promocoes/exportar',
                        sort: [{ property: 'codigo', direction: 'ASC' }],
                    }}
                />
            }
        >
            <PromocaoGrid />
        </OneColumn>
    );
}
