import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';

import { ContentModal } from 'components/modal';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { useFormMutation } from 'mutations/useFormMutation';
import { CarrinhoCallbackModal, CarrinhoFalhaEstoqueText } from 'pages/compra/carrinho/components';
import { CallbackInfo } from 'pages/compra/carrinho/types';

type CarrinhoFinalizarModal = {
    open: boolean;
    setOpen: (action: boolean) => void;
    row: GridRowModel;
};

export type ProdutosEstoque = {
    cdProduto: string;
    dsProduto: string;
    sgUnidadeMedida: string;
    qtEstoqueDisponivel: number;
};

const getErrorMessage = (error: any): string | JSX.Element => {
    const { data } = error;
    const { message, errors } = data;

    // Valida se possui erro de estoque.
    // Retorna mensagem com as informações de estoque
    if (Array.isArray(errors)) {
        const firstError = errors.at(0);

        if (firstError && firstError['qtEstoqueDisponivel'] !== undefined) {
            return <CarrinhoFalhaEstoqueText produtosEstoque={errors} />;
        }
    }

    return message || 'Não foi possível finalizar o pedido. Tente novamente.';
};

export function CarrinhoFinalizarModal({ open, setOpen, row }: CarrinhoFinalizarModal): JSX.Element {
    const [isOpenCallbackModal, setIsOpenCallbackModal] = useState<boolean>(false);
    const [callbackInfo, setCallbackInfo] = useState<CallbackInfo | null>(null);
    const { mutate, isLoading } = useFormMutation();
    const { content, setContent } = useFormContext();
    const { usuario } = useAplicacaoDataContext();
    const { hasPermission } = usePermissionsContext();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const handleConfirm = (): void => {
        const { idPedido, configPedido, loja, usuarioPedido } = row;
        const isUsuarioPedidoLogged = usuarioPedido.idUsuario === usuario?.idUsuario;
        const showConfirm = isTipoPedidoPadrao || isUsuarioPedidoLogged || (!isUsuarioPedidoLogged && hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL']));

        setContent({ ...content, finalizarRow: idPedido });

        mutate(
            { url: `/compra/pedidocompra/rascunho/${idPedido}/finalizar`, preventSnack: true },
            {
                onSuccess: ({ data }: any) => {
                    setCallbackInfo({ configPedido, loja, usuarioPedido, cdPedidoInterno: data.data.nrPedido, showConfirm });
                },
                onError: (error: any) => {
                    setCallbackInfo({
                        title: 'Falha ao finalizar pedido',
                        message: getErrorMessage(error),
                    });
                },
                onSettled: () => {
                    setOpen(false);
                    setIsOpenCallbackModal(true);
                },
            },
        );
    };

    return (
        <React.Fragment>
            <CarrinhoCallbackModal open={isOpenCallbackModal} setOpen={setIsOpenCallbackModal} callbackInfo={callbackInfo} />

            <ContentModal open={open} setOpen={setOpen} closeButton sx={{ borderRadius: 1, minWidth: { xs: 320, md: 600 } }}>
                <Stack gap={2}>
                    <Typography variant="h6">Finalizar pedido</Typography>

                    <Typography>Tem certeza que deseja finalizar o pedido?</Typography>

                    <Stack spacing={2} justifyContent="flex-end" direction="row">
                        <Button variant="outlined" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>

                        <LoadingButton variant="contained" onClick={handleConfirm} loading={isLoading}>
                            Finalizar pedido
                        </LoadingButton>
                    </Stack>
                </Stack>
            </ContentModal>
        </React.Fragment>
    );
}
