import React, { useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, Paper, Typography } from '@mui/material';

import { AzureUploadPreviewProps } from 'components/azureUpload/types';
import { ConfirmModal } from 'components/modal';

export function AzureUploadPreview({ file, onDelete, sx }: AzureUploadPreviewProps): JSX.Element {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    return (
        <Paper sx={{ ...sx, display: 'flex', p: 1.5 }}>
            <ConfirmModal open={modalVisible} setOpen={setModalVisible} text={'Tem certeza que deseja remover o arquivo?'} confirm={() => onDelete()} />

            {file.arquivo.dsLinkThumbnail && (
                <Box
                    sx={{
                        minWidth: 80,
                        height: 60,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundImage: `url("${file.arquivo.dsLinkThumbnail}")`,
                        borderRadius: 1,
                        mr: 2,
                    }}
                />
            )}

            <Box
                sx={{
                    flex: 1,
                    mr: 2,
                }}
            >
                <Typography variant="h6" sx={{ color: 'common.black' }}>
                    {file.arquivo.dsTitulo}
                </Typography>

                <Typography>{file.arquivo.dsArquivo}</Typography>
            </Box>

            <IconButton sx={{ width: 60 }} onClick={() => setModalVisible(true)} color="primary">
                <DeleteOutlineIcon fontSize="large" />
            </IconButton>
        </Paper>
    );
}
