import React, { Dispatch, SetStateAction } from 'react';

import { Box, Button } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RevendedorFilesPaper } from 'pages/revendedor/revendedor/components';
import { RevendedorFile } from 'pages/revendedor/revendedor/types';

type RevendedorFilesModal = {
    files: RevendedorFile[];
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function RevendedorFilesModal({ files, open, setOpen }: RevendedorFilesModal): JSX.Element {
    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 1, minWidth: { md: 800 } }} closeButton>
            <Box>
                <SubTitle label="Anexos" />

                <Box sx={{ maxHeight: 400, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 1.5, mb: 2, p: 0.5 }}>
                    <RevendedorFilesPaper files={files} />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: { xs: 'stretch', md: 'flex-end' } }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                </Box>
            </Box>
        </ContentModal>
    );
}
