import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { UploadFilDataProps } from 'components/upload/types';
import { useServiceApiContext } from 'context/ServiceApiContext';

export type UploadFileRequest = {
    progressCallBack?: (progressEvent: ProgressEvent) => void;
    url?: string;
    formData: FormData;
};

export type UploadFileResponseError = {
    error: string;
};

type MutationOptionsProps = UseMutationOptions<UploadFilDataProps, UploadFileResponseError, UploadFileRequest>;
type MutationResponseProps = UseMutationResult<UploadFilDataProps, UploadFileResponseError, UploadFileRequest>;

export const useUploadFileMutation = (options?: MutationOptionsProps, isBannerForm?: boolean, timeoutRequest?: number): MutationResponseProps => {
    const Api = useServiceApiContext();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation<UploadFilDataProps, UploadFileResponseError, UploadFileRequest>((data: UploadFileRequest): Promise<any> => {
        const { progressCallBack, url, formData } = data;

        if (!isBannerForm) {
            const request = Api.post(url ?? '/fileupload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    if (typeof progressCallBack === 'function') {
                        progressCallBack(progressEvent);
                    }
                },
                timeout: timeoutRequest,
            });
            return Promise.resolve(request);
        }

        // Aqui ocorre a validacao da resolucao
        const file = formData.get('file') as File;
        const image = new Image();
        image.src = URL.createObjectURL(file);
        return new Promise((resolve, reject) => {
            image.onload = () => {
                if (image.width !== 2560 || image.height !== 466) {
                    reject({ error: 'Resolução para o Banner é de 2560x466.' });
                } else {
                    const request = Api.post(url ?? '/fileupload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent: ProgressEvent) => {
                            if (typeof progressCallBack === 'function') {
                                progressCallBack(progressEvent);
                            }
                        },
                    });
                    resolve(request);
                }
            };
        }).catch((error) => {
            enqueueSnackbar(error.error, { variant: 'error' });
            // throw error;
        });
    }, options);
};
