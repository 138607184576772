import React, { ReactNode } from 'react';

import { alpha, Typography } from '@mui/material';

interface PedidoProdutoModalInfoTitulo {
    children: ReactNode;
    bold?: boolean;
}

export function PedidoProdutoModalInfoTitulo({ children, bold }: PedidoProdutoModalInfoTitulo): JSX.Element {
    return (
        <Typography
            sx={{
                fontSize: '.8rem',
                lineHeight: '1.3em',
                fontWeight: bold ? 600 : undefined,
                pb: 1,
                mb: 0.5,
                borderBottom: (theme) => `1px solid ${alpha(theme.palette.common.black, 0.12)}`,

                span: {
                    fontSize: '1rem',
                    display: 'block',
                },
            }}
        >
            {children}
        </Typography>
    );
}
