import React, { useState, useEffect } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, SwipeableDrawer, alpha } from '@mui/material';

import menuData from './utils/menuData.json';

import { Logo } from 'components/images';
import { MenuMainMobileNestedAcordion } from 'components/menu/main/components/MenuMainMobileNestedAccordion';
import { MenuItemProps } from 'components/menu/main/types';

/**
 * Menu de sistema completo para celular
 * Contém botão para abrir menu de sistema (ícone) + menu com opções disponiveis
 * Disponível somente em resoluções abaixo de 900px (md)
 * @returns <Button + Menu>
 */
export function MenuMainMobile(): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [open]);

    return (
        <React.Fragment>
            <Button
                disableRipple
                onClick={() => setOpen((oldOpen) => !oldOpen)}
                className={open ? 'isOpen' : ''}
                sx={{
                    'mr': 'auto',
                    'height': '100%',
                    '&.isOpen': {
                        backgroundColor: (theme) => alpha(theme.palette.common.black, 0.2),
                    },
                }}
            >
                <MenuIcon fontSize="large" sx={{ mr: 2, color: 'common.white' }} />

                <Box sx={{ height: { xs: '80%', sm: '100%' } }}>
                    <Logo />
                </Box>
            </Button>

            <SwipeableDrawer
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                ModalProps={{ keepMounted: true }}
                PaperProps={{
                    sx: {
                        top: 60,
                        height: 'calc(100% - 60px)',
                    },
                }}
            >
                {(menuData as MenuItemProps[]).map((item) => (
                    <MenuMainMobileNestedAcordion key={item.route} item={item} setOpen={setOpen} open={open} />
                ))}
            </SwipeableDrawer>
        </React.Fragment>
    );
}
