import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { PanelGrid } from 'components/grid';
import { DetalhesArquivoProps, RequestDetalhesArquivoProps } from 'pages/arquivo/categoria/types';
import { useMimeTypes } from 'hooks/useMimeTypes';
import { useParams } from 'react-router-dom';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { useQuery } from '@tanstack/react-query';
import { usePermissionsContext } from 'context/PermissionsContext';
import { EditRemoveBtn } from 'components/buttons';

export function DetalhesArquivoList(): JSX.Element | null {
    const { getTitle } = useMimeTypes();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const { id } = useParams();
    const { validRouterParams } = useValidation();
    const validId = validRouterParams({ id });

    const requestOptions: RequestOptionsType = {
        url: '/gestao/catalogoarquivo',
        columns: 'idCatalogoArquivo,idCategoriaArquivo,idArquivo,dsArquivo,dsExtensao,dsTitulo,dsLink,qtDownload,fgAtivo',
        filter: [{ property: 'idCategoriaArquivo', value: id, operator: 'eq', logicalOperator: 'AND' }],
    };

    const { data, fetchStatus, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestDetalhesArquivoProps> = RequestListagem(requestOptions).then((result) => result.data);
            return request;
        },
        { enabled: validId },
    );

    const generateCategoriaList = (data: DetalhesArquivoProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: index + 1,
                idCatalogoArquivo: item.idCatalogoArquivo,
                dsArquivoName: item.dsTitulo,
                fgAtivo: item.fgAtivo,
                dsMimeTypeTitle: getTitle(item.dsExtensao),
            }));
        }

        return [];
    };

    const columns: GridColDef[] = [
        { field: 'dsArquivoName', headerName: 'Nome Arquivo', minWidth: 120, flex: 1 },
        { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean',minWidth: 60, width: 120 },
        { field: 'dsMimeTypeTitle', headerName: 'Tipo', minWidth: 70,width: 135 },
    ];

    if (hasPermission(['ARQUIVO_ALTERAR']) || hasPermission(['ARQUIVO_EXCLUIR'])) {
        columns.push({
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            minWidth: 80,
            renderCell: ({ row }) => (
                <EditRemoveBtn
                    id={row.idCatalogoArquivo}
                    showEditBtn={hasPermission(['ARQUIVO_ALTERAR'])}
                    showRemoveBtn={hasPermission(['ARQUIVO_EXCLUIR'])}
                    editUrl="/arquivo/form/"
                    removeUrl={`gestao/catalogoarquivo/${row.idCatalogoArquivo}`}
                    sx={{ ml: 'auto', p: 1.5 }}
                />
            ),
        });
    }

    const rows = generateCategoriaList(data?.data);

    if (rows.length) {
        return (
            <Block>
                <SubTitle label="Arquivos" />

                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
            </Block>
        );
    } else {
        return null;
    }
}
