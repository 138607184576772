import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterDepositos(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterIdDeposito, setFilterIdDeposito] = useState<FilterValuesProps>({
        property: 'idDeposito',
        operator: 'like',
        value: null,
        idProperty: 'idDeposito',
    });
    const [filterDsDeposito, setFilterDsDeposito] = useState<FilterValuesProps>({
        property: 'dsDeposito',
        operator: 'like',
        value: null,
        idProperty: 'dsDeposito',
    });
    const [filterCdDeposito, setFilterCdDeposito] = useState<FilterValuesProps>({
        property: 'cdDeposito',
        operator: 'like',
        value: null,
        idProperty: 'cdDeposito',
    });
    const [fgAtivo, setFgAtivo] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: true,
        idProperty: 'fgAtivo',
    });

    const filters = [
        { value: filterIdDeposito, setter: setFilterIdDeposito },
        { value: filterDsDeposito, setter: setFilterDsDeposito },
        { value: filterCdDeposito, setter: setFilterCdDeposito },
        { value: fgAtivo, setter: setFgAtivo },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <CissTextField
                    label="Código"
                    value={filterIdDeposito.value}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => setFilterIdDeposito((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Nome do depósito"
                    value={filterDsDeposito.value}
                    onChange={(e) => setFilterDsDeposito((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Código de Integração"
                    value={filterCdDeposito.value}
                    onChange={(e) => setFilterCdDeposito((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
