import React, { useEffect, useRef, useState } from 'react';

import FontFaceObserver from 'fontfaceobserver';
import { Text, Transformer } from 'react-konva';

import { KonvaTextProps } from 'pages/marketing-trade/types';

export function MarketingTradeKonvaText({ text, width, font, x, y, rotation, isSelected, draggable, onDblClick, onDragEnd, onTransformEnd }: KonvaTextProps): JSX.Element {
    const [fontLoaded, setFontLoaded] = useState<boolean>(false);
    const textRef = useRef<any>();
    const transformerRef = useRef<any>();

    if (!fontLoaded) {
        const fontFamily = new FontFaceObserver(font.family, {
            weight: font.bold ? 700 : 400,
            style: font.italic ? 'italic' : '',
        });
        fontFamily
            .load()
            .then(() => {
                setFontLoaded(true);
            })
            .catch(() => {});
    }

    const getFontStyle = (): string => {
        if (font.italic && font.bold) {
            return 'italic bold';
        } else if (font.bold) {
            return 'bold';
        } else if (font.italic) {
            return 'italic';
        } else {
            return '';
        }
    };

    useEffect(() => {
        if (isSelected) {
            transformerRef.current.nodes([textRef.current]);
            transformerRef.current.getLayer().batchDraw();
        } else {
            transformerRef.current.nodes([]);
        }
    }, [isSelected]);

    useEffect(() => {
        if (fontLoaded) {
            setFontLoaded(false);
        }
    }, [font.family, font.bold, font.italic]);

    return (
        <React.Fragment>
            <Text
                ref={textRef}
                text={text}
                width={width}
                fontSize={font.size}
                fill={font.color}
                align={font.align}
                fontFamily={fontLoaded ? font.family : 'Arial'}
                fontStyle={getFontStyle()}
                offsetX={width / 2}
                offsetY={font.size / 2}
                x={x + width / 2}
                y={y + font.size / 2}
                rotation={rotation}
                draggable={draggable}
                onDblClick={onDblClick}
                onDragEnd={onDragEnd}
                onTransformEnd={onTransformEnd}
            />
            <Transformer ref={transformerRef} resizeEnabled={false} flipEnabled={false} anchorCornerRadius={5} borderEnabled={false} rotationSnaps={[0, 90, 180, 270]} />
        </React.Fragment>
    );
}
