import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FullLoading } from 'components/loading';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

type ArquivoKeys = 'ARQUIVO_VISUALIZAR' | 'ARQUIVO_CADASTRAR' | 'ARQUIVO_ALTERAR' | 'ARQUIVO_EXCLUIR' | 'ARQUIVO_MODERAR';
type ArquivoCategoriasKeys = 'ARQUIVO_CATEGORIA_GERENCIAR' | 'ARQUIVO_CATEGORIA_MODERAR';

type ComunicadoKeys =
    | 'COMUNICADO_VISUALIZAR'
    | 'COMUNICADO_CADASTRAR'
    | 'COMUNICADO_ALTERAR'
    | 'COMUNICADO_MODERAR'
    | 'COMUNICADO_EXCLUIR'
    | 'COMUNICADO_LOG_VISUALIZAR';
type ComunicadoCategoriasKeys = 'COMUNICADO_CATEGORIA_GERENCIAR' | 'COMUNICADO_CATEGORIA_MODERAR';

type CompraPedidoKeys = 'COMPRA_PEDIDO_VISUALIZAR' | 'COMPRA_PEDIDO_FINALIZAR' | 'COMPRA_PEDIDO_ACESSO_GERAL' | 'COMPRA_PEDIDO_FINALIZACAO_GERAL';
type CompraPedidoConfiguracaoKeys = 'COMPRA_PEDIDO_CONFIGURACAO_VISUALIZAR';
type CompraProdutoKeys = 'COMPRA_PRODUTO_VISUALIZAR' | 'COMPRA_PRODUTO_ALTERAR';
type CompraSugestaoKeys = 'COMPRA_SUGESTAO_VISUALIZAR';
type CompraPedidoLoteKeys = 'COMPRA_PEDIDO_LOTE_VISUALIZAR';
type CompraPedidoInstitucional = 'COMPRA_PEDIDO_ACESSA_LOJA_INSTITUCIONAL';
type CompraPedidoTotalizadorKeys = 'COMPRA_PEDIDO_TOTALIZADOR_VISUALIZAR';
type CompraPercentualNotaFiscalKeys = 'COMPRA_PERCENTUAL_NOTA_FISCAL_VISUALIZAR';

type CompraPedidoListagemKeys =
    | 'COMPRA_PEDIDO_LISTAGEM_VISUALIZAR'
    | 'COMPRA_PEDIDO_LISTAGEM_GRADE'
    | 'COMPRA_PEDIDO_LISTAGEM_SINCRONIZAR'
    | 'COMPRA_PEDIDO_LISTAGEM_CLONAR'
    | 'COMPRA_PEDIDO_LISTAGEM_EXCLUIR'
    | 'COMPRA_PEDIDO_LISTAGEM_APROVAR'
    | 'COMPRA_PEDIDO_LISTAGEM_EXCLUIR_TOTALIZADOR'
    | 'COMPRA_PEDIDO_ACESSAR_LOGS'
    | 'COMPRA_PEDIDO_COPIAR_DADOS_PEDIDOS'
    | 'COMPRA_PEDIDO_REATIVAR_PEDIDO_EXCLUIDO';

type BannerKeys = 'BANNER_VISUALIZAR' | 'BANNER_CADASTRAR' | 'BANNER_ALTERAR' | 'BANNER_MODERAR' | 'BANNER_EXCLUIR';

type MarketingPecasKeys =
    | 'MARKETING_PECA_VISUALIZAR'
    | 'MARKETING_PECA_CADASTRAR'
    | 'MARKETING_PECA_ALTERAR'
    | 'MARKETING_PECA_EXCLUIR'
    | 'MARKETING_PECA_MODERAR';
type MarketingCampanhasKeys = 'MARKETING_CAMPANHA_GERENCIAR' | 'MARKETING_CAMPANHA_MODERAR';

type TradePecasKeys = 'TRADE_PECA_VISUALIZAR' | 'TRADE_PECA_CADASTRAR' | 'TRADE_PECA_ALTERAR' | 'TRADE_PECA_EXCLUIR' | 'TRADE_PECA_MODERAR';
type TradeSolicitacoesKeys = 'TRADE_SOLICITACAO_PECA_VISUALIZAR' | 'TRADE_SOLICITACAO_PECA_SOLICITAR' | 'TRADE_SOLICITACAO_PECA_MODERAR';
type TradeCampanhasKeys = 'TRADE_CAMPANHA_GERENCIAR' | 'TRADE_CAMPANHA_MODERAR';

type CalendarioKeys = 'CALENDARIO_VISUALIZAR';
type CalendarioEventosKeys = 'CALENDARIO_EVENTO_PERSONALIZADO_GERENCIAR' | 'CALENDARIO_EVENTO_MARCADOR_GERENCIAR';

type UsuariosKeys = 'USUARIO_VISUALIZAR' | 'USUARIO_CADASTRAR' | 'USUARIO_ALTERAR' | 'USUARIO_MODERAR' | 'USUARIO_EXCLUIR';
type UsuariosGruposKey = 'USUARIO_GRUPO_RELACIONAR';

type GrupoEconomicoKeys =
    | 'GRUPO_ECONOMICO_VISUALIZAR'
    | 'GRUPO_ECONOMICO_CADASTRAR'
    | 'GRUPO_ECONOMICO_ALTERAR'
    | 'GRUPO_ECONOMICO_MODERAR'
    | 'GRUPO_ECONOMICO_EXCLUIR';

type GruposPermissoesKeys =
    | 'GRUPO_PERMISSAO_VISUALIZAR'
    | 'GRUPO_PERMISSAO_CADASTRAR'
    | 'GRUPO_PERMISSAO_ALTERAR'
    | 'GRUPO_PERMISSAO_MODERAR'
    | 'GRUPO_PERMISSAO_EXCLUIR';

type FinanceiroRoyaltiesExtratoKeys = 'FINANCEIRO_EXTRATO_ROYALTIES_VISUALIZAR';
type FinanceiroRoyaltiesEspelhoKeys = 'FINANCEIRO_ESPELHO_ROYALTIES_VISUALIZAR';
type FinanceiroFppExtratoKeys = 'FINANCEIRO_EXTRATO_FPP_VISUALIZAR';
type FinanceiroFppEspelhoKeys = 'FINANCEIRO_ESPELHO_FPP_VISUALIZAR';
type FinanceiroTitulosKeys = 'FINANCEIRO_TITULO_VISUALIZAR' | 'FINANCEIRO_TITULO_BOLETO_GERAR' | 'FINANCEIRO_TITULO_EXPORTAR';

type FinanceiroCreditoDebitoKeys = 'FINANCEIRO_CREDITO_DEBITO_DETALHAR';
type FinanceiroCreditoKeys =
    | 'FINANCEIRO_DETALHAMENTO_CREDITO_VISUALIZAR'
    | 'FINANCEIRO_DETALHAMENTO_CREDITO_IMPORTAR'
    | 'FINANCEIRO_DETALHAMENTO_CREDITO_EXCLUIR';
type FinanceiroNotasKeys = 'FINANCEIRO_NOTA_FISCAL_VISUALIZAR' | 'FINANCEIRO_NOTA_FISCAL_DETALHAR' | 'FINANCEIRO_NOTA_FISCAL_EXPORTAR';

type FinanceiroDevolucaoKeys = 'FINANCEIRO_DEVOLUCAO_VISUALIZAR' | 'FINANCEIRO_DEVOLUCAO_SIMULAR' | 'FINANCEIRO_DEVOLUCAO_EXCLUIR';
type FinanceiroDevolucaoMotivoKeys = 'FINANCEIRO_DEVOLUCAO_MOTIVO_GERENCIAR';

type FinanceiroDreKeys =
    | 'FINANCEIRO_DRE_VISUALIZAR'
    | 'FINANCEIRO_DRE_PREENCHER'
    | 'FINANCEIRO_DRE_ENVIAR'
    | 'FINANCEIRO_DRE_CONTESTAR'
    | 'FINANCEIRO_DRE_ANALITICA_VISUALIZAR'
    | 'FINANCEIRO_DRE_SINTETICA_VISUALIZAR'
    | 'FINANCEIRO_DRE_MODERAR'
    | 'FINANCEIRO_DRE_RELATORIO_EXPORTAR';

type FinanceiroDrePreenchimentoKeys = 'FINANCEIRO_DRE_PREENCHIMENTO_GERENCIAR';

type FinanceiroRecebiveisKeys = 'FINANCEIRO_RECEBIVEIS_VISUALIZAR';

type BiPainelKeys = 'BI_VENDA_VISUALIZAR' | 'BI_ESTOQUE_VISUALIZAR' | 'BI_DIGITAL_VISUALIZAR' | 'BI_SELLIN_VISUALIZAR';

type LojaKeys = 'LOJA_GERENCIAR' | 'LOJA_MODERAR' | 'LOJA_VISUALIZAR' | 'LOJA_CADASTRAR' | 'LOJA_ALTERAR' | 'LOJA_EXCLUIR';

type TagKeys = 'TAG_GERENCIAR';

type EstatisticaKeys = 'ESTATISTICA_VISUALIZAR';
type GradeKeys = 'GRADE_VISUALIZAR' | 'GRADE_GERENCIAR';

type PedidoKeys = 'PEDIDO_CADASTRAR' | 'PEDIDO_VISUALIZAR';

type ComplementarCargo = 'COMPLEMENTAR_CARGO_GERENCIAR';
type ComplementarFrete = 'COMPLEMENTAR_FRETE_GERENCIAR';

type ComplementarGrupoEmails = 'COMPLEMENTAR_GRUPO_EMAIL_GERENCIAR';
type ComplementarCentroDistribuicao = 'COMPLEMENTAR_CENTRO_DISTRIBUICAO_GERENCIAR';
type ComplementarCentroCusto = 'COMPLEMENTAR_CENTRO_CUSTO_GERENCIAR';
type ComplementarCentroPedido = 'COMPLEMENTAR_CENTRO_PEDIDO_GERENCIAR';
type ComplementarCondicaoPagamento = 'COMPLEMENTAR_CONDICAO_PAGAMENTO_GERENCIAR';
type ComplementarDeposito = 'COMPLEMENTAR_DEPOSITO_GERENCIAR';
type ComplementarOperacao = 'COMPLEMENTAR_OPERACAO_GERENCIAR';
type ComplementarBandaPreco = 'COMPLEMENTAR_BANDA_PRECO_GERENCIAR';
type ComplementarLocalFuncionario = 'COMPLEMENTAR_LOCAL_FUNCIONARIO';

type QuebrasKeys = 'QUEBRAS_VISUALIZAR' | 'QUEBRAS_IMPORTAR';

type RevendedorKeys = 'REVENDEDOR_CADASTRO_REVENDEDOR';
type RevendedorPromocaoKeys = 'REVENDEDOR_CADASTRO_PROMOCAO';
type RevendedorConfiguracaoGeralKeys = 'REVENDEDOR_CADASTRO_CONFIGURACOES';

export type PermissionKey =
    | ComplementarOperacao
    | ComplementarDeposito
    | ComplementarCondicaoPagamento
    | ComplementarCentroPedido
    | ComplementarCentroCusto
    | ComplementarCentroDistribuicao
    | ComplementarGrupoEmails
    | ComplementarCargo
    | ComplementarFrete
    | ComplementarBandaPreco
    | ComplementarLocalFuncionario
    | PedidoKeys
    | CompraSugestaoKeys
    | CompraPedidoTotalizadorKeys
    | CompraPedidoLoteKeys
    | CompraProdutoKeys
    | CompraPedidoKeys
    | CompraPedidoConfiguracaoKeys
    | CompraPedidoListagemKeys
    | CompraPedidoInstitucional
    | CompraPercentualNotaFiscalKeys
    | ComunicadoKeys
    | ComunicadoCategoriasKeys
    | MarketingPecasKeys
    | MarketingCampanhasKeys
    | TradePecasKeys
    | TradeCampanhasKeys
    | TradeSolicitacoesKeys
    | ArquivoKeys
    | ArquivoCategoriasKeys
    | CalendarioKeys
    | CalendarioEventosKeys
    | UsuariosKeys
    | UsuariosGruposKey
    | FinanceiroRoyaltiesExtratoKeys
    | FinanceiroRoyaltiesEspelhoKeys
    | FinanceiroFppExtratoKeys
    | FinanceiroFppEspelhoKeys
    | FinanceiroNotasKeys
    | FinanceiroDevolucaoKeys
    | FinanceiroDevolucaoMotivoKeys
    | FinanceiroTitulosKeys
    | FinanceiroCreditoKeys
    | FinanceiroCreditoDebitoKeys
    | FinanceiroDreKeys
    | FinanceiroRecebiveisKeys
    | GrupoEconomicoKeys
    | GruposPermissoesKeys
    | BiPainelKeys
    | BannerKeys
    | FinanceiroDrePreenchimentoKeys
    | LojaKeys
    | LojaKeys
    | FinanceiroDrePreenchimentoKeys
    | TagKeys
    | EstatisticaKeys
    | GradeKeys
    | QuebrasKeys
    | RevendedorKeys
    | RevendedorPromocaoKeys
    | RevendedorConfiguracaoGeralKeys;

interface PermissionsContextData {
    hasPermission: (permissionsKeys: PermissionKey[]) => boolean;
    hasAnyPermission: (permissionsKeys: PermissionKey[]) => boolean;
    hasFormPermission: (module: string, paramId: string | undefined) => boolean;
    alertNotPermission: () => JSX.Element;
    isLoadingPermissions: boolean;
    cleanPermissions: () => void;
}

interface PermissionsProviderProps {
    children?: ReactNode;
}

interface PermissionRequest {
    idPermissao: number;
    dsChavePermissao: string;
    dsPermissao: string;
}

const PermissionsContext = createContext<PermissionsContextData>({} as PermissionsContextData);

export function PermissionsProvider({ children }: PermissionsProviderProps): JSX.Element {
    const [permissions, setPermissions] = useState<PermissionRequest[]>([]);
    const RequestListagem = useRequestListagem();
    const { isAuthenticated } = useGenericAuth();

    const requestOptions: RequestOptionsType = {
        url: '/security/permissao/grupousuario',
    };

    const {
        isLoading: isLoadingPermissions,
        isFetching,
        fetchStatus,
    } = useQuery([requestOptions], () => RequestListagem(requestOptions), {
        onSuccess: (data) => {
            setPermissions(data?.data?.data || []);
        },
        enabled: isAuthenticated,
    });

    const hasPermission = useCallback(
        (permissionsKeys: PermissionKey[]): boolean => {
            const permissionsMatches = permissions.filter((p) => !!permissionsKeys.find((pk) => pk === p.dsChavePermissao));

            return permissionsMatches.length === permissionsKeys.length;
        },
        [permissions],
    );

    const hasFormPermission = useCallback(
        (module: string, paramId: string | undefined) => {
            const permissionsMatches = permissions.filter((p) => {
                return p.dsChavePermissao.includes(module);
            });

            const permissionsArr = permissionsMatches.map((p) => p.dsChavePermissao);

            if (paramId && !permissionsArr.find((p) => p.includes('ALTERAR')) && !permissionsArr.find((p) => p.includes('GERENCIAR'))) return false;

            return Boolean(
                permissionsArr.find(
                    (p) =>
                        p.includes('GERENCIAR') ||
                        p.includes('ALTERAR') ||
                        p.includes('CADASTRAR') ||
                        p.includes('MODERAR') ||
                        p.includes('SOLICITAR'),
                ),
            );
        },
        [permissions],
    );

    const hasAnyPermission = useCallback(
        (permissionsKeys: PermissionKey[]): boolean => {
            const permissionsMatches = permissions.filter((p) => !!permissionsKeys.find((pk) => pk === p.dsChavePermissao));

            return permissionsMatches.length > 0;
        },
        [permissions],
    );

    const alertNotPermission = useCallback(() => {
        if (isLoadingPermissions || isFetching || fetchStatus === 'fetching') {
            return <FullLoading loading={true} />;
        }

        return (
            <Alert sx={{ m: 2 }} severity="error">
                Você não possui permissão
            </Alert>
        );
    }, [isLoadingPermissions, isFetching, fetchStatus]);

    const cleanPermissions = useCallback(() => setPermissions([]), []);

    const contextValue = useMemo(
        () => ({
            hasPermission,
            hasFormPermission,
            hasAnyPermission,
            alertNotPermission,
            isLoadingPermissions: isLoadingPermissions || permissions.length === 0,
            cleanPermissions,
        }),
        [alertNotPermission, hasFormPermission, hasAnyPermission, hasPermission, isLoadingPermissions, permissions.length, cleanPermissions],
    );

    return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>;
}

export const usePermissionsContext = (): PermissionsContextData => useContext(PermissionsContext);
