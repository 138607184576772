import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterCondicaoPagamento(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterIdCondicaoPagamento, setFilterIdCondicaoPagamento] = useState<FilterValuesProps>({
        property: 'idCondicaoPagamento',
        operator: 'like',
        value: null,
        idProperty: 'idCondicaoPagamento',
    });
    const [filterDsCondicaoPagamento, setFilterDsCondicaoPagamento] = useState<FilterValuesProps>({
        property: 'dsCondicaoPagamento',
        operator: 'like',
        value: null,
        idProperty: 'dsCondicaoPagamento',
    });
    const [filterCdCondicaoPagmento, setFilterCdCondicaoPagmento] = useState<FilterValuesProps>({
        property: 'cdCondicaoPagamento',
        operator: 'like',
        value: null,
        idProperty: 'cdCondicaoPagamento',
    });
    const [fgAtivo, setFgAtivo] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'fgAtivo',
    });

    const filters = [
        { value: filterIdCondicaoPagamento, setter: setFilterIdCondicaoPagamento },
        { value: filterDsCondicaoPagamento, setter: setFilterDsCondicaoPagamento },
        { value: filterCdCondicaoPagmento, setter: setFilterCdCondicaoPagmento },
        { value: fgAtivo, setter: setFgAtivo },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <CissTextField
                    label="Código"
                    value={filterIdCondicaoPagamento.value}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => setFilterIdCondicaoPagamento((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Nome da condição de pagamento"
                    value={filterDsCondicaoPagamento.value}
                    onChange={(e) => setFilterDsCondicaoPagamento((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Código de Integração"
                    value={filterCdCondicaoPagmento.value}
                    onChange={(e) => setFilterCdCondicaoPagmento((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
