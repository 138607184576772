import React from 'react';

import { Box, Typography } from '@mui/material';

import { ProdutoPedido } from 'pages/compra/pedido/types';

interface PedidoProdutoModalTitulo {
    produto: ProdutoPedido;
}

export function PedidoProdutoModalTitulo({ produto }: PedidoProdutoModalTitulo): JSX.Element {
    return (
        <Box>
            <Typography variant="h4">{produto.produto.dsProduto} </Typography>

            <Box sx={{ display: 'flex', gap: 0.5 }}>
                <Typography variant="body2" sx={{ fontWeight: 300 }}>
                    Código:
                </Typography>

                <Typography variant="body2" sx={{ fontWeight: 300 }}>
                    {produto.produto.cdProduto}
                </Typography>
            </Box>
        </Box>
    );
}
