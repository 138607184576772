import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useDreListUpdate } from 'hooks/useDreListUpdate';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import {
    DreListFilter,
    DreListLiberarColumn,
    DreListLojaColumn,
    DreListPreencherColumn,
    DreListStatusColumn,
    RelatorioDreAnaliticoBtn,
    RelatorioDreSinteticoBtn,
} from 'pages/financeiro/dre/components';
import { DreConsultaGridTooltip } from 'pages/financeiro/dre/components/atoms/DreConsultaGridTooltip';
import { DreListProps, RequestDreListProps } from 'pages/financeiro/dre/types';
import { brlPrice, camelCase } from 'util/format';

enum Apuracao {
    RECEITA_BRUTA = 'apuracao_receita_bruta',
    RECEITA_LIQUIDA = 'apuracao_receita_liquida',
    LUCRO_LIQUIDO = 'apuracao_lucro_liquido',
    CMV = 'apuracao_cmv',
    GASTOS = 'apuracao_gastos',
}

export function DreListComp(): JSX.Element {
    const filterEl = useRef<null | { filterNrAno: FilterValuesProps; filterLoja: FilterValuesProps }>(null);
    const GridApiRef = useRef<GridApiPro>(null);
    const navigate = useNavigate();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();
    const { dreUpdate } = useDreListUpdate();
    const { filter, autoLoad } = useListagemContext();

    const isMultipleLojas = filterEl?.current?.filterLoja?.value.length > 1;

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: isMultipleLojas ? '/dre/dremercadologica/consulta' : '/dre/dremercadologica',
            nested: true,
            columns: 'idDreMercadologica',
            filter,
        }),
        [filter, isMultipleLojas],
    );

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestDreListProps> = RequestListagem(requestOptions).then((res) => res.data);

            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const getDsLoja = (): string => {
        const [loja] = filterEl?.current?.filterLoja?.value || {};

        return loja ? `${loja.cdLoja} - ${loja.dsNomeFantasia}` : '';
    };

    const findVlItem = (data: DreListProps, variavel: string): string => {
        const item = data?.dreMercadologicaApuracoes.find((apuracao) => apuracao.dreMercadologicaConfigApuracao.dsNomeVariavel === variavel);

        return item ? brlPrice(item.vlTotal) : '--';
    };

    const getStrStatus = (item: DreListProps): string => {
        const statusInfo = item.dreStatus.dreStatusEnum.split('_');
        const status = camelCase(statusInfo.length > 1 ? statusInfo[1] : statusInfo[0]);

        if (statusInfo.length > 1) {
            return `${camelCase(statusInfo[0], 0)} ${status}`;
        }

        return status;
    };

    const getColumnTooltip = useCallback(
        (variavel: string): string => {
            let tooltip = '';

            data?.data.forEach((dreProp) => {
                const apuracao = dreProp.dreMercadologicaApuracoes?.find(
                    (apuracao) => apuracao.dreMercadologicaConfigApuracao.dsNomeVariavel === variavel,
                );
                tooltip = apuracao?.dreMercadologicaConfigApuracao?.dsFormula || '';
            });

            return tooltip;
        },
        [data?.data],
    );

    const generateRows = useCallback((data: DreListProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: item.idDreMercadologica || index,
                dsLoja: getDsLoja(),
                dsMes: camelCase(item.dsMesExtenso),
                vlReceitaBruta: '- -',
                vlReceitaLiquida: '- -',
                vlLucroLiquido: '- -',
                vlCmv: '- -',
                vlGastos: '- -',
                idStatus: item.dreStatus.idDreStatus,
                dsStatus: getStrStatus(item),
                loja: item.loja,
                dtInicio: item.dtInicio,
                isLoading: true,
            }));
        }

        return [];
    }, []);

    const generateRowsMultipleLojas = useCallback((data: DreListProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: item.idDreMercadologica || index,
                dsMes: camelCase(item.dsMesExtenso),
                vlReceitaBruta: findVlItem(item, Apuracao.RECEITA_BRUTA),
                vlReceitaLiquida: findVlItem(item, Apuracao.RECEITA_LIQUIDA),
                vlLucroLiquido: findVlItem(item, Apuracao.LUCRO_LIQUIDO),
                vlCmv: findVlItem(item, Apuracao.CMV),
                vlGastos: findVlItem(item, Apuracao.GASTOS),
            }));
        }

        return [];
    }, []);

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'dsMes', headerName: 'Mês', minWidth: 80 },
            {
                field: 'vlReceitaBruta',
                headerName: 'Receita Bruta',
                type: 'number',
                width: 125,
                minWidth: 110,
                flex: 1,
                renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.RECEITA_BRUTA)}>Receita Bruta</DreConsultaGridTooltip>,
            },
            {
                field: 'vlReceitaLiquida',
                headerName: 'Receita Líquida',
                type: 'number',
                width: 125,
                minWidth: 110,
                flex: 1,
                renderHeader: () => (
                    <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.RECEITA_LIQUIDA)}>Receita Líquida</DreConsultaGridTooltip>
                ),
            },
            {
                field: 'vlLucroLiquido',
                headerName: 'Lucro',
                type: 'number',
                width: 125,
                minWidth: 80,
                flex: 1,
                renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.LUCRO_LIQUIDO)}>Lucro</DreConsultaGridTooltip>,
            },
            {
                field: 'vlCmv',
                headerName: 'CMV',
                type: 'number',
                width: 125,
                minWidth: 80,
                flex: 1,
                renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.CMV)}>CMV</DreConsultaGridTooltip>,
            },
            {
                field: 'vlGastos',
                headerName: 'Despesas',
                type: 'number',
                width: 125,
                minWidth: 90,
                flex: 1,
                renderHeader: () => <DreConsultaGridTooltip tooltip={getColumnTooltip(Apuracao.GASTOS)}>Despesas</DreConsultaGridTooltip>,
            },
        ],
        [getColumnTooltip],
    );

    if (!isMultipleLojas) {
        columns.unshift({
            field: 'loja',
            headerName: 'Loja',
            minWidth: 150,
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: ({ row }) => <DreListLojaColumn title={row.dsLoja} />,
        });

        columns.push({
            field: 'dsStatus',
            headerName: 'Status',
            headerAlign: 'center',
            minWidth: 140,
            renderCell: ({ row }) => <DreListStatusColumn idStatus={row.idStatus} title={row.dsStatus} />,
        });

        if (hasPermission(['FINANCEIRO_DRE_PREENCHER'])) {
            columns.push({
                field: 'preencher',
                align: 'center',
                headerName: 'Preencher Estimativa de Resultado',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 110,
                renderCell: ({ row }) => {
                    return <DreListPreencherColumn vlReceita={row.vlReceitaBruta} id={row.id} idStatus={row.idStatus} isLoading={row.isLoading} />;
                },
            });
        }

        if (hasPermission(['FINANCEIRO_DRE_MODERAR'])) {
            columns.push({
                field: 'liberar',
                align: 'center',
                headerName: 'Liberar',
                headerAlign: 'center',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 75,
                minWidth: 60,
                renderCell: ({ row }) => <DreListLiberarColumn idStatus={row.idStatus} id={row.id} refresh={refetch} />,
            });
        }
    }

    const rows = useMemo(
        () => (isMultipleLojas ? generateRowsMultipleLojas(data?.data) : generateRows(data?.data)),
        [isMultipleLojas, generateRowsMultipleLojas, data?.data, generateRows],
    );

    useEffect(() => {
        if (data?.data && !isMultipleLojas) {
            const dreArr = data.data.map((dre) => ({ ...dre, isLoading: true }));

            dreArr.forEach((dreProps) => {
                dreUpdate(dreProps).then((newDreProps) => {
                    const vlReceitaBruta = findVlItem(newDreProps, Apuracao.RECEITA_BRUTA);
                    const vlReceitaLiquida = findVlItem(newDreProps, Apuracao.RECEITA_LIQUIDA);
                    const vlLucroLiquido = findVlItem(newDreProps, Apuracao.LUCRO_LIQUIDO);
                    const vlCmv = findVlItem(newDreProps, Apuracao.CMV);
                    const vlGastos = findVlItem(newDreProps, Apuracao.GASTOS);

                    GridApiRef.current?.updateRows([
                        {
                            id: newDreProps.idDreMercadologica,
                            isLoading: newDreProps.isLoading,
                            idStatus: newDreProps.dreStatus.idDreStatus,
                            vlReceitaBruta,
                            vlReceitaLiquida,
                            vlLucroLiquido,
                            vlCmv,
                            vlGastos,
                        },
                    ]);
                });
            });
        }
    }, [rows]);

    return (
        <OneColumn
            title="Estimativa de Resultado"
            extraButtons={[
                {
                    title: 'Gerenciar preenchimentos de estimativa de resultado',
                    onClick: () => navigate('preenchimento'),
                    visible: hasPermission(['FINANCEIRO_DRE_PREENCHIMENTO_GERENCIAR']),
                },
            ]}
        >
            <DreListFilter ref={filterEl} />

            <Block>
                <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
                    <Box sx={{ display: 'inherit', alignItems: 'inherit' }}>
                        <Typography sx={{ mr: 1 }}>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                        <ChevronRightOutlinedIcon color="primary" />
                    </Box>

                    {hasPermission(['FINANCEIRO_DRE_SINTETICA_VISUALIZAR']) && (
                        <RelatorioDreSinteticoBtn disabled={rows.length <= 0} filterLoja={filterEl?.current?.filterLoja} />
                    )}

                    {hasPermission(['FINANCEIRO_DRE_ANALITICA_VISUALIZAR']) && (
                        <RelatorioDreAnaliticoBtn disabled={rows.length <= 0} filterLoja={filterEl?.current?.filterLoja} />
                    )}
                </Stack>
            </Block>

            <Block>
                <PanelGrid
                    ref={GridApiRef}
                    rows={rows}
                    rowsCount={rows.length}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={columns}
                    remote={false}
                    visibleRows="all"
                />
            </Block>
        </OneColumn>
    );
}
