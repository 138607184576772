import React from 'react';

import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { TicketModelProps } from './types';

import { MenutaskBarButton } from 'components/menu/taskbar/components';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

const openDeskManager = (data: TicketModelProps): void => {
    const form: HTMLFormElement = document.createElement('form');

    form.setAttribute('action', data.dsUrlHelpDesk);
    form.setAttribute('method', 'post');
    form.setAttribute('target', '_blank');

    const prefixo: HTMLInputElement = document.createElement('input');
    prefixo.setAttribute('name', 'desk_prefixo');
    prefixo.setAttribute('value', data.dsDataHelpDesk.desk_prefixo);

    const senha: HTMLInputElement = document.createElement('input');
    senha.setAttribute('name', 'desk_senha');
    senha.setAttribute('value', data.dsDataHelpDesk.desk_senha);

    const email: HTMLInputElement = document.createElement('input');
    email.setAttribute('name', 'desk_login');
    email.setAttribute('value', data.dsDataHelpDesk.desk_login);

    document.body.appendChild(form);

    form.appendChild(prefixo);
    form.appendChild(senha);
    form.appendChild(email);

    form.submit();

    form.removeChild(prefixo);
    form.removeChild(senha);
    form.removeChild(email);

    document.body.removeChild(form);
};

export const HelpDeskBtn = (): JSX.Element => {
    const RequestListagem = useRequestListagem();
    const { tokenInfo } = useGenericAuth();
    const { enqueueSnackbar } = useSnackbar();

    const requestOptions: RequestOptionsType = {
        url: '/config/configgeral/helpdesk',
        columns: 'dsUrlHelpDesk,dsDataHelpDesk,desk_prefixo,desk_senha,desk_login',
    };

    const { data } = useQuery([requestOptions], () => {
        const request: Promise<TicketModelProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    const handleClick = (): void => {
        if (data && data.dsTipoHelpDesk === 'desk.ms') {
            if (!tokenInfo?.dsEmailEmpresa) {
                enqueueSnackbar('E-mail da loja não configurado', { variant: 'error' });
                return;
            }

            data.dsDataHelpDesk.desk_login = tokenInfo.dsEmailEmpresa;
            openDeskManager(data);
        }
    };

    return <MenutaskBarButton title="Abrir Chamado" notifications={0} onClick={handleClick} icon={<SupportAgentOutlinedIcon />} />;
};
