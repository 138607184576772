import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';

type PasswordValidationProps = {
    text: string;
    validated: boolean;
};

export function PasswordValidation({ text, validated }: PasswordValidationProps): JSX.Element {
    return (
        <Typography variant="body2" sx={{ color: validated ? 'success.main' : 'error.main', display: 'flex', alignItems: 'center', gap: 1 }}>
            {validated ? <CheckCircleIcon fontSize="small" /> : <CancelIcon fontSize="small" />} {text}
        </Typography>
    );
}
