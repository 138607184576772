import React, { useState } from 'react';

import HistoryIcon from '@mui/icons-material/History';
import { IconButton, Tooltip } from '@mui/material';

import { ListagemProvider } from 'context/ListagemContext';
import { ComunicadoLogsModal } from 'pages/comunicado/components/templates/ComunicadoLogsModal';

type ComunicadoLogsBtn = {
    idComunicado: number;
};

export function ComunicadoLogsBtn({ idComunicado }: ComunicadoLogsBtn): JSX.Element {
    const [isOpenLogsModal, setIsOpenLogsModal] = useState<boolean>(false);

    return (
        <React.Fragment>
            <ListagemProvider>
                <ComunicadoLogsModal isOpen={isOpenLogsModal} setIsOpen={setIsOpenLogsModal} idComunicado={idComunicado} />
            </ListagemProvider>

            <Tooltip title="Logs de Envio de Emails">
                <IconButton sx={{ p: 0.5 }} onClick={() => setIsOpenLogsModal(true)}>
                    <HistoryIcon />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}
