import React, { Dispatch, SetStateAction } from 'react';

import { Chip, Tooltip } from '@mui/material';

interface PedidoCompraTotalizadorSituacaoColumnProps {
    idStatus: number;
    title: string;
    idPedido: number;
    setOpenErrosModal: Dispatch<SetStateAction<number | boolean>>;
}

export function PedidoCompraTotalizadorSituacaoColumn({ idStatus, title, idPedido, setOpenErrosModal }: PedidoCompraTotalizadorSituacaoColumnProps): JSX.Element {
    let color: 'error' | 'info' | 'success' | 'warning';
    let msgTooltip = null;

    switch (idStatus) {
        case 2:
            color = 'info';
            break;
        case 3:
            color = 'success';
            break;
        case 4:
            msgTooltip = 'Visualizar Erros';
            color = 'error';
            break;
        case 5:
            msgTooltip = 'Devido a alterações nas quebras da configuração do pedido, é necessário refazer as quebras do pedido para poder integrar-lo';
            color = 'warning';
            break;
        default:
            color = 'warning';
    }

    return (
        <Tooltip title={msgTooltip || ''}>
            <Chip label={title} variant="outlined" size="small" color={color} sx={{ width: '100%' }} onClick={idStatus === 4 ? () => setOpenErrosModal(idPedido) : undefined} />
        </Tooltip>
    );
}
