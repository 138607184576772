import React, { useCallback, useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { UploadButton } from 'components/upload';
import { useFormContext } from 'context/FormContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

interface GradeImportacaoUploadBtnProps {
    url: string;
    contentListName: string;
}

export function GradeImportacaoUploadBtn({ url, contentListName }: GradeImportacaoUploadBtnProps): JSX.Element {
    const [file, setFile] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { content, setContent } = useFormContext();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: (response: any) => {
            const { data } = response;

            enqueueSnackbar('Arquivo de importação enviado com sucesso.', { variant: 'success' });

            setContent({ ...content, [contentListName]: data });

            if (inputFileRef.current) {
                inputFileRef.current.value = '';
                setFile(null);
            }
        },
        onError: (error) => showError(error, 'Erro ao enviar arquivo.'),
    });

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (file) {
            formData.append('file', file);
            mutate({ formData, url });
        }
    }, [file, mutate, url]);

    const handleDeleteFile = useCallback(() => {
        // enviar uma solicitação para remover o arquivo
        // atualizar a lista de arquivos
        setContent({ ...content, [contentListName]: undefined });
    }, [content, contentListName, setContent]);

    const handlerFileChange = (selectedFile: File): void => {
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    return (
        <React.Fragment>
            <Grid container sx={{ gap: 2, alignItems: 'flex-start' }}>
                <Grid item>
                    <UploadButton label="Selecionar Arquivo" disabled={isLoading} onChange={(file) => file && handlerFileChange(file)} accept=".csv" inputRef={inputFileRef} />

                    {file && (
                        <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                            {file.name}
                        </Typography>
                    )}
                </Grid>

                <LoadingButton variant="contained" loading={isLoading} disabled={!file} onClick={handleSubmit} sx={{ maxWidth: 150 }}>
                    Enviar
                </LoadingButton>

                <LoadingButton variant="contained" disabled={!content?.[contentListName]} onClick={handleDeleteFile} sx={{ maxWidth: 150 }}>
                    Limpar
                </LoadingButton>
            </Grid>

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                *São aceitos somente arquivos em formato .csv
            </Typography>
        </React.Fragment>
    );
}
