export interface Cep {
    idCep: number;
    cidade: {
        idCidade: number;
        dsCidade: string;
        nrIbge: number;
        estado: {
            idEstado: number;
            dsEstado: string;
            sgEstado: string;
            sgEstadoName: string;
        };
        sgEstado: string;
    };
    nrCep: string;
    estado: {
        idEstado: number;
        dsEstado: string;
        sgEstado: string;
        sgEstadoName: string;
    };
    sgEstado: string;
    dsCidade: string;
    dsBairro: string;
    dsEndereco: string;
}

interface TipoLoja {
    idTipoLoja: number;
}

export interface LojaBandaPreco {
    bandaPreco: {
        idBandaPreco: number;
        dsBandaPreco?: string;
    };
    idBandaPreco: number;
    dsBandaPreco: string;
    vlPercentual: number;
    fgAtivo: boolean;
}

export interface LojaConfiguracao {
    idConfiguracaoPropriedade: number;
    dsConfiguracao: string;
    dsChave: string;
    idLojaConfiguracao?: number;
    dsValor?: string;
    configuracaoPropriedade: {
        idConfiguracaoPropriedade: number;
        dsConfiguracao: string;
        dsChave: string;
    };
}

interface GrupoEconomicoLoja {
    fgAtivo: boolean;
    idGrupoEconomicoLoja: number;
    idGrupoEconomico: number;
    dsGrupoEconomico: string;
}

interface FaixaFaturamento {
    idFaixaFaturamento: number;
    dsFaixaFaturamento: string;
}

interface LojaPerfil {
    idLojaPerfil: number;
    dsLojaPerfil: string;
}

export interface LojaCondicaoPagamento {
    idCondicaoPagamento: number;
    dsCondicaoPagamento: string;
    cdCondicaoPagamento: string;
}
export interface LojaRequest {
    dsRazaoSocial: string;
    dsNomeFantasia: string;
    dsCnpj: string;
    cdLoja: string;
    dsEmail: string;
    dsFone: string;
    dsObservacao: string;
    idCep?: number;
    dsEndereco: string;
    nrEndereco?: number;
    dsBairro: string;
    dsEstado: string;
    dsCidade: string;
    dsComplemento: string;
    dsInscricaoEstadual: string;
    fgAtivo: true;
    tipoLoja: TipoLoja | null;
    cep: Cep | null;
    dtInauguracao: string;
    portalTimezone: {
        idPortalTimezone: number;
    };
    grupoEconomicoLoja: GrupoEconomicoLoja | null;
    faixaFaturamento: FaixaFaturamento | null;
    lojaBandaPrecos?: LojaBandaPreco[];
    lojaConfiguracoes?: LojaConfiguracao[];
    lojaPerfil: LojaPerfil | null;
    cdPontoComercial: string;
    lojaCondicaoPagamento: LojaCondicaoPagamento | null;
}

export interface Loja {
    idLoja: string;
    cdLoja: string;
    dsRazaoSocial: string;
    dsNomeFantasia: string;
    dsCnpj: string;
    dsGrupoEconomico: string;
    sgEstado: string;
    dsCidade: string;
    dsTipoLoja: string;
    fgAtivo: string;
}

export const initialValuesFormik: LojaRequest = {
    fgAtivo: true,
    dsRazaoSocial: '',
    dsNomeFantasia: '',
    dsCnpj: '',
    cdLoja: '',
    dsEmail: '',
    dsFone: '',
    dsEndereco: '',
    nrEndereco: 0,
    dsBairro: '',
    dsEstado: '',
    dsInscricaoEstadual: '',
    dsCidade: '',
    dsComplemento: '',
    dsObservacao: '',
    cdPontoComercial: '',
    dtInauguracao: new Date().toISOString(),
    lojaBandaPrecos: [],
    lojaConfiguracoes: [],
    portalTimezone: {
        idPortalTimezone: 1,
    },
    cep: null,
    tipoLoja: null,
    lojaPerfil: null,
    faixaFaturamento: null,
    grupoEconomicoLoja: null,
    lojaCondicaoPagamento: null,
};
