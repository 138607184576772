import React, { useEffect, useState } from 'react';
import { ptBR } from 'date-fns/locale';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { Filter } from 'components/page';
import { CissTextField } from 'components/fields/';
import { useListagemContext } from 'context/ListagemContext';

export function DetalhamentoCreditosImportacaFilter(): JSX.Element {
    const { setRouteState } = useListagemContext();
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsArquivo',
        operator: 'like',
        value: '',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhInclusao',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const filters = [
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterInterval, setter: setFilterInterval },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });

        // ao aplicar um filtro, rola tela para a grid
        setRouteState({
            scroll: {
                element: '#files-import-grid',
            },
        });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter} showTitle={false}>
            <Grid item flex={1}>
                <CissTextField label="Arquivo" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={2}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Início', end: 'Data Fim' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>
        </Filter>
    );
}
