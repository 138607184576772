import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, CircularProgress, Divider, Grid, alpha } from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';
import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { View, Event } from 'react-big-calendar';

import { DefaultAccordion } from 'components/accordion';
import { StoreAutoComplete } from 'components/autocompletes';
import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { DatePickerMonthYear } from 'components/fields';
import { Centered } from 'components/grid';
import { Block, OneColumn } from 'components/page/';
import { useCalendarEventsDisabled } from 'context/CalendarEventsDisabledContext';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CalendarioListAccordionContent, CalendarioListEventHeader, CalendarioListCalendar } from 'pages/calendario/components';
import { CalendarioListPromocaoModal } from 'pages/calendario/components/templates/CalendarioListPromocaoModal';
import { CalendarioEventoPersonalizadoRequestProps, CalendarioEventoFinanceiroRequestProps, CalendarioEventoGradeRequestProps, CalendarioEventoPromocaoRequestProps } from 'pages/calendario/types';

const changeEventTitle = (title: string): string => {
    switch (title) {
        case 'Venda':
            return 'Título Produtos';
        case 'Nota de serviço':
            return 'Título Royalties';
        case 'Nota de débito FPP':
            return 'Título FPP';
        default:
            return title;
    }
};
/**
 * Página listagem Calendário
 */
export function CalendarioListComp(): JSX.Element {
    const navigate = useNavigate();
    const RequestListagem = useRequestListagem();
    const { limit, page, sort, filter } = useListagemContext();
    const { hasPermission } = usePermissionsContext();
    const { enqueueSnackbar } = useSnackbar();
    const { calendarEventsList } = useCalendarEventsDisabled();

    const [view, setView] = useState<View>('month');
    const [date, setDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [store, setStore] = useState<StoreOptionsProps | any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [eventosPersonalizados, setEventosPersonalizados] = useState<Event[]>([]);
    const [eventosFinanceiros, setEventosFinanceiros] = useState<Event[]>([]);
    const [eventosGrades, setEventosGrades] = useState<Event[]>([]);
    const [eventosPromocoes, setEventosPromocoes] = useState<Event[]>([]);
    const [corEventosFinanceiros, setCorEventosFinanceiros] = useState<string>('#FFFFFF');
    const [corEventosGradesLimite, setCorEventosGradesLimite] = useState<string>('#FFFFFF');
    const [corEventosPromocoes, setCorEventosPromocoes] = useState<string>('#FFFFFF');
    const [promocao, setPromocao] = useState<Event | null>(null);
    const [openModalPromocao, setOpenModalPromocao] = useState<boolean>(false);

    const personalizadosRequestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/calendarioeventopersonalizado',
            sort: sort.length ? sort : [{ property: 'dsCalendarioEvento', direction: 'DESC' }],
            filter: [
                ...filter,
                { property: 'cdLoja', value: store?.cdLoja, operator: 'eq' },
                { property: 'dtFiltro', value: dateFns.format(date, 'MM/dd/yyy'), operator: 'eq' },
                { property: 'tipoCalendario', value: view, operator: 'eq' },
            ],
            nested: true,
            limit,
            page,
        }),
        [filter, store, date, limit, page, sort, view],
    );

    const personalizadoQuery = useQuery(
        [personalizadosRequestOptions.url],
        (): Promise<CalendarioEventoPersonalizadoRequestProps> => RequestListagem(personalizadosRequestOptions).then((res) => res.data),
        {
            enabled: false,
            onSuccess: (data) => {
                const newEventosPersonalizados = data.data.flatMap((item) =>
                    item.calendarioEvento.map((event) => {
                        return {
                            title: item.calendarioEventoTag.dsCalendarioEventoTag,
                            start: dateFns.parseISO(event.dhInicio) || date,
                            end: dateFns.parseISO(event.dhFim) || date,
                            resource: {
                                idCalendarioEvento: event.idCalendarioEvento,
                                tpCalendarioEvento: 'EVENTO_PERSONALIZADO',
                                calendarioEventoTag: item.calendarioEventoTag,
                                titleEvent: event.dsCalendarioEvento,
                                dsLink: event.dsUrlLink,
                                dsLinkText: event.dsTextoLink,
                                dsConteudo: event.dsConteudo,
                                dsCor: event.calendarioEventoTag.dsCorTag || '#4D8B31',
                            },
                        };
                    }),
                );

                setEventosPersonalizados(newEventosPersonalizados);
            },
            onError: () => {
                enqueueSnackbar('Não foi possível carregar os dados dos eventos personalizados', { variant: 'error' });

                setEventosPersonalizados([]);
            },
        },
    );

    const financeirosRequestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/calendarioeventotitulo/financeiro',
            sort: sort.length ? sort : [{ property: 'dsCalendarioEvento', direction: 'DESC' }],
            filter: [
                ...filter,
                { property: 'cdLoja', value: store?.cdLoja, operator: 'eq' },
                { property: 'dtFiltro', value: dateFns.format(date, 'MM/dd/yyy'), operator: 'eq' },
                { property: 'tipoCalendario', value: view, operator: 'eq' },
            ],
            nested: true,
            limit,
            page,
        }),
        [filter, store, date, limit, page, sort, view],
    );

    const financeirosQuery = useQuery([financeirosRequestOptions.url], (): Promise<CalendarioEventoFinanceiroRequestProps> => RequestListagem(financeirosRequestOptions).then((res) => res.data), {
        enabled: false,
        onSuccess: (data) => {
            const newEventosFinanceiros = data.data.eventos.map(({ origem, titulos }) => {
                const { dsOrigem, dtVencimento } = origem;
                const dtVencimentoParse = dateFns.parseISO(dtVencimento);
                const title = changeEventTitle(dsOrigem);

                return {
                    title,
                    start: dtVencimentoParse,
                    end: dtVencimentoParse,
                    resource: {
                        tpCalendarioEvento: 'EVENTO_FINANCEIRO',
                        titulos,
                        calendarioEventoTag: {
                            dsCalendarioEventoTag: title,
                            dsCorTag: data.data.dsCor,
                            idCalendarioEventoTag: Math.ceil(Math.random() * 100000),
                        },
                    },
                };
            });

            if (newEventosFinanceiros.length) {
                setCorEventosFinanceiros(newEventosFinanceiros[0].resource.calendarioEventoTag.dsCorTag);
            }

            setEventosFinanceiros(newEventosFinanceiros);
        },
        onError: () => {
            enqueueSnackbar('Não foi possível carregar os dados financeiros', { variant: 'error' });

            setEventosFinanceiros([]);
        },
    });

    const gradesRequestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/calendarioeventograde',
            sort: sort.length ? sort : [{ property: 'idGrade', direction: 'DESC' }],
            filter: [
                ...filter,
                { property: 'cdLoja', value: store?.cdLoja, operator: 'eq' },
                { property: 'dtFiltro', value: dateFns.format(date, 'MM/dd/yyy'), operator: 'eq' },
                { property: 'tipoCalendario', value: view, operator: 'eq' },
            ],
            nested: true,
            limit,
            page,
        }),
        [filter, store, date, limit, page, sort, view],
    );

    const gradesQuery = useQuery([gradesRequestOptions.url], (): Promise<CalendarioEventoGradeRequestProps> => RequestListagem(gradesRequestOptions).then((res) => res.data), {
        enabled: false,
        onSuccess: (data) => {
            const newEventosGrades = data.data.map(({ grade, eventoCategoria, dsCor }) => {
                const { dtLimitePedido, dtColeta, dtEntrega, tipoGrade } = grade;
                const { dsTipoGrade } = tipoGrade;
                const dtStartParse = eventoCategoria === 'EVENTO_LIMITE_GRADE' ? dateFns.parseISO(dtLimitePedido) : dateFns.parseISO(dtEntrega);

                return {
                    title: eventoCategoria === 'EVENTO_LIMITE_GRADE' ? `Grade ${dsTipoGrade} - Limite Pedido` : `Grade ${dsTipoGrade} - Previsão Entrega`,
                    start: dtStartParse,
                    end: dtStartParse,
                    resource: {
                        tpCalendarioEvento: eventoCategoria,
                        dtColeta: dateFns.parseISO(dtColeta),
                        dtEntrega: dateFns.parseISO(dtEntrega),
                        dtLimitePedido: dateFns.parseISO(dtLimitePedido),
                        dsCor,
                    },
                };
            });

            if (newEventosGrades.length) {
                setCorEventosGradesLimite(newEventosGrades[0].resource.dsCor);
            }

            setEventosGrades(newEventosGrades);
        },
        onError: () => {
            enqueueSnackbar('Não foi possível carregar os dados das grades', { variant: 'error' });

            setEventosGrades([]);
        },
    });

    const promocoesRequestOptions: RequestOptionsType = useMemo(() => {
        return {
            url: '/gestao/calendarioeventopromocao',
            sort: sort.length ? sort : [{ property: 'idPromocao', direction: 'DESC' }],
            filter: [
                ...filter,
                { property: 'cdLoja', value: store?.cdLoja, operator: 'eq' },
                { property: 'dtFiltro', value: dateFns.format(date, 'MM/dd/yyy'), operator: 'eq' },
                { property: 'tipoCalendario', value: view, operator: 'eq' },
            ],
            nested: false,
            limit,
            page,
        };
    }, [filter, store, date, limit, page, sort, view]);

    const promocoesQuery = useQuery([promocoesRequestOptions.url], (): Promise<CalendarioEventoPromocaoRequestProps> => RequestListagem(promocoesRequestOptions).then((res) => res.data), {
        enabled: false,
        onSuccess: (data) => {
            const newEventosPromocoes = data.data.map(({ promocao, eventoCategoria, dsCor }) => {
                const { idPromocao, dsPromocao, dhInicioPromocao, dhFimPromocao } = promocao;
                const dtStartParse = dateFns.parseISO(dhInicioPromocao);
                const dtEndParse = dateFns.parseISO(dhFimPromocao);

                return {
                    title: dsPromocao,
                    start: dtStartParse,
                    end: dtEndParse,
                    resource: {
                        tpCalendarioEvento: eventoCategoria,
                        idPromocao,
                        dsCor,
                    },
                };
            });

            if (newEventosPromocoes.length) {
                setCorEventosPromocoes(newEventosPromocoes[0].resource.dsCor);
            }

            setEventosPromocoes(newEventosPromocoes);
        },
        onError: () => {
            enqueueSnackbar('Não foi possível carregar os dados das promoções', { variant: 'error' });

            setEventosPromocoes([]);
        },
    });

    useEffect(() => {
        if (store) {
            setLoading(true);

            const proms = [personalizadoQuery.refetch(), financeirosQuery.refetch(), gradesQuery.refetch(), promocoesQuery.refetch()];

            Promise.all(proms).finally(() => setLoading(false));
        }
    }, [view, date, store]);

    const groupedEvents = groupBy(eventosPersonalizados, (item) => item.title);

    return (
        <React.Fragment>
            <CalendarioListPromocaoModal open={openModalPromocao} setOpen={setOpenModalPromocao} promocao={promocao} store={store} />

            <OneColumn
                title="Calendário"
                extraButtons={[
                    {
                        title: 'Gerenciar Eventos',
                        onClick: () => navigate('evento'),
                        visible: hasPermission(['CALENDARIO_EVENTO_PERSONALIZADO_GERENCIAR']),
                    },
                ]}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        flexDirection: {
                            xs: 'column-reverse',
                            md: 'row',
                        },
                    }}
                >
                    <Grid item xs={12} md={8}>
                        <Block>
                            {store && date ? (
                                <CalendarioListCalendar
                                    view={view}
                                    setView={setView}
                                    date={date}
                                    setDate={setDate}
                                    store={store}
                                    loading={loading}
                                    events={calendarEventsList([...eventosPersonalizados, ...eventosFinanceiros, ...eventosGrades, ...eventosPromocoes])}
                                />
                            ) : (
                                <Alert severity="warning">
                                    Selecione uma <strong>Loja</strong> para continuar
                                </Alert>
                            )}
                        </Block>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Block
                            sx={{
                                height: 'calc(100% - 32px)',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    display: view === 'month' ? 'none' : 'block',
                                }}
                                onClick={() => (view !== 'month' ? setView('month') : setView('day'))}
                            >
                                Voltar para visualização do {view === 'month' ? 'Mês' : 'Dia'}
                            </Button>

                            <Divider sx={{ my: 2, display: view === 'month' ? 'none' : 'block' }} />

                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <StoreAutoComplete value={store} onChange={(e, value) => setStore(value)} sx={{ flex: 2 }} />

                                <Box sx={{ display: 'flex', flex: 1, ml: 2 }}>
                                    <DatePickerMonthYear
                                        minDate={new Date(new Date().getFullYear() - 10, new Date().getMonth(), 1)}
                                        maxDate={new Date(new Date().getFullYear(), 11, 1)}
                                        value={date}
                                        onChange={() => false}
                                        onMonthChange={(value) => {
                                            const newDate = new Date(value as Date);
                                            const date = new Date(newDate.getFullYear(), newDate.getMonth(), 1);

                                            if (date) {
                                                setDate(date);
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>

                            {loading ? (
                                <Centered>
                                    <CircularProgress />
                                </Centered>
                            ) : store && date ? (
                                Boolean(eventosPersonalizados.length) || Boolean(eventosFinanceiros.length) || Boolean(eventosGrades.length) || Boolean(eventosPromocoes.length) ? (
                                    <React.Fragment>
                                        <Divider sx={{ my: 2 }} />

                                        {Object.entries(groupedEvents).map(([title, events]) => (
                                            <DefaultAccordion
                                                key={title}
                                                title={
                                                    <CalendarioListEventHeader
                                                        title={title}
                                                        color={events[0].resource.calendarioEventoTag.dsCorTag}
                                                        small
                                                        category={events[0].resource.calendarioEventoTag.idCalendarioEventoTag}
                                                    />
                                                }
                                                sx={{ backgroundColor: alpha(events[0].resource.calendarioEventoTag.dsCorTag, 0.2) }}
                                            >
                                                {events.map((item) => (
                                                    <CalendarioListAccordionContent event={item} key={item.resource.idCalendarioEvento} />
                                                ))}
                                            </DefaultAccordion>
                                        ))}

                                        {eventosGrades.length > 0 && (
                                            <DefaultAccordion
                                                key="logistica"
                                                title={<CalendarioListEventHeader title="Logística" color={corEventosGradesLimite} small category="EVENTO_LOGISTICA" />}
                                                sx={{ backgroundColor: alpha(corEventosGradesLimite, 0.2) }}
                                            >
                                                {eventosGrades.map((item) => {
                                                    const { title, start, resource } = item;
                                                    const { dsCor } = resource;

                                                    return (
                                                        <DefaultAccordion
                                                            key={Math.random()}
                                                            title={<CalendarioListEventHeader title={title} color={dsCor} startDate={start} small />}
                                                            sx={{
                                                                'backgroundColor': alpha(dsCor, 0.2),
                                                                '.MuiCollapse-root': {
                                                                    backgroundColor: (theme) => alpha(theme.palette.common.black, 0.1),
                                                                },
                                                            }}
                                                        >
                                                            <CalendarioListAccordionContent event={item} key={Math.random()} />
                                                        </DefaultAccordion>
                                                    );
                                                })}
                                            </DefaultAccordion>
                                        )}

                                        {eventosFinanceiros.length > 0 && (
                                            <DefaultAccordion
                                                key="financeiro"
                                                title={<CalendarioListEventHeader title="Financeiro" color={corEventosFinanceiros} small category="EVENTO_FINANCEIRO" />}
                                                sx={{ backgroundColor: alpha(corEventosFinanceiros, 0.2) }}
                                            >
                                                {eventosFinanceiros.map((item) => {
                                                    const { title, start } = item;

                                                    return (
                                                        <DefaultAccordion
                                                            key={Math.random()}
                                                            title={<CalendarioListEventHeader title={title} color={corEventosFinanceiros} startDate={start} small />}
                                                            sx={{
                                                                'backgroundColor': alpha(corEventosFinanceiros, 0.2),
                                                                '.MuiCollapse-root': {
                                                                    backgroundColor: (theme) => alpha(theme.palette.common.black, 0.1),
                                                                },
                                                            }}
                                                        >
                                                            <CalendarioListAccordionContent event={item} key={Math.random()} />
                                                        </DefaultAccordion>
                                                    );
                                                })}
                                            </DefaultAccordion>
                                        )}

                                        {eventosPromocoes.length > 0 && (
                                            <DefaultAccordion
                                                key="promocoes"
                                                title={<CalendarioListEventHeader title="Promoções" color={corEventosPromocoes} small category="EVENTO_PROMOCAO" />}
                                                sx={{ backgroundColor: alpha(corEventosPromocoes, 0.2) }}
                                            >
                                                <Stack spacing={1} sx={{ maxHeight: 350, overflow: 'auto', overflowX: 'hidden', mr: -1, pr: 1 }}>
                                                    {eventosPromocoes.map((item) => {
                                                        const { title } = item;

                                                        return (
                                                            <Button
                                                                key={Math.random()}
                                                                sx={{
                                                                    display: 'flex',
                                                                    color: (theme) => alpha(theme.palette.common.black, 1),
                                                                    backgroundColor: alpha(corEventosPromocoes, 0.2),
                                                                    justifyContent: 'flex-start',
                                                                    textAlign: 'start',
                                                                }}
                                                                onClick={() => {
                                                                    setPromocao(item);
                                                                    setOpenModalPromocao(true);
                                                                }}
                                                            >
                                                                {title}
                                                            </Button>
                                                        );
                                                    })}
                                                </Stack>
                                            </DefaultAccordion>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Divider sx={{ my: 2 }} />

                                        <Alert severity="info">Não existem eventos disponíveis</Alert>
                                    </React.Fragment>
                                )
                            ) : null}
                        </Block>
                    </Grid>
                </Grid>
            </OneColumn>
        </React.Fragment>
    );
}
