import React from 'react';

import { useParams } from 'react-router-dom';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { LojaFormComp, LojaListComp } from 'pages/cadastro/loja/components';

export function LojaForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('LOJA', id)) return alertNotPermission();

    return (
        <ListagemProvider>
            <FormProvider>
                <LojaFormComp />
            </FormProvider>
        </ListagemProvider>
    );
}

export function LojaList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['LOJA_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <LojaListComp />
        </ListagemProvider>
    );
}
