import React from 'react';
import { EventoMarcadoresListComp } from 'pages/calendario/evento/marcadores/components/pages/MarcadoresList';
import { usePermissionsContext } from 'context/PermissionsContext';
import { ListagemProvider } from 'context/ListagemContext';
import { EventoMarcadoresFormComp } from 'pages/calendario/evento/marcadores/components/pages/MarcadoresForm';
import { FormProvider } from 'context/FormContext';

export function EventoMarcadoresList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['CALENDARIO_EVENTO_MARCADOR_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <EventoMarcadoresListComp />
        </ListagemProvider>
    );
}

export function EventoMarcadoresForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['CALENDARIO_EVENTO_MARCADOR_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <EventoMarcadoresFormComp />
        </FormProvider>
    );
}
