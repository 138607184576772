import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface SecaoProps {
    idSecao: number;
    cdSecao: number;
    dsSecao: string;
}

type SecaoAutoCompleteProps = Pick<CissAutoCompleteProps, 'requestOptions' | 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function SecaoAutoComplete({
    value,
    error,
    label = 'Seção',
    multiple = false,
    sx,
    onChange,
    requestOptions = {
        url: '/gestao/secao',
        sort: [{ property: 'dsSecao', direction: 'ASC' }],
        columns: 'idSecao,dsSecao,cdSecao',
        limit: 100,
    },
}: SecaoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsSecao}"
            optionStringTemplate="{dsSecao}"
            requestOptions={requestOptions}
            isOptionEqualToValue={(option: SecaoProps, value: SecaoProps) => option.idSecao === value.idSecao}
            onChange={onChange}
            sx={sx}
        />
    );
}
