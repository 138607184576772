import React, { useEffect, useState } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, IconButton, InputAdornment, Stack } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';

import { CissTextField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestUsuariosListProps, UsuariosProps } from 'pages/cadastro/permissoes/types';

interface FormUsuarioProps {
    search: string;
}

interface UsuariosListModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
    addUsuario: (users: UsuariosProps[]) => void;
    currentUsers: UsuariosProps[];
}

const generateUsuariosList = (data: UsuariosProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idUsuario: item.idUsuario,
            dsUsuario: item.dsUsuario,
        }));
    }

    return [];
};

export function UsuariosListGridModal({ open, setOpen, addUsuario, currentUsers }: UsuariosListModalProps): JSX.Element | null {
    const RequestListagem = useRequestListagem();

    const [query, setQuery] = useState('');

    const [usuariosModal, setUsuariosModal] = useState<UsuariosProps[]>([]);
    const [selected, setSelected] = useState<null | number>(null);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/usuario',
        query,
        sort: [{ property: 'dsUsuario', direction: 'ASC' }],
        columns: 'idUsuario,dsUsuario',
        limit: 50,
    };

    const { isLoading, fetchStatus, data, refetch } = useQuery([requestOptions], () => {
        const request: Promise<RequestUsuariosListProps> = RequestListagem(requestOptions).then((result) => result.data);
        return request;
    });

    const columns: GridColDef[] = [
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: '',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 60,
            renderCell: ({ row }) => {
                return (
                    <Checkbox
                        checked={!!usuariosModal.find((user) => user.idUsuario === row.idUsuario)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                const user = usuariosModal.find((users) => users.idUsuario === row.idUsuario);

                                if (!user) {
                                    const { dsUsuario, idUsuario } = row;
                                    setUsuariosModal((oldState) => [...oldState, { dsUsuario, idUsuario }]);
                                }
                            } else {
                                const user = usuariosModal.find((users) => users.idUsuario === row.idUsuario);

                                if (user) {
                                    setUsuariosModal((oldState) => oldState.filter((state) => state.idUsuario !== user.idUsuario));
                                }
                            }
                        }}
                    />
                );
            },
        },
        { field: 'idUsuario', headerName: 'Código', type: 'number', width: 80, minWidth: 70 },
        { field: 'dsUsuario', headerName: 'Nome Usuário', flex: 1, minWidth: 100 },
    ];

    const rows = generateUsuariosList(data?.data);

    const handleConfirmModal = (): void => {
        addUsuario(usuariosModal);
        setOpen(false);
    };

    useEffect(() => {
        setUsuariosModal(currentUsers);
    }, [currentUsers]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton
            centered={false}
            sx={{
                maxWidth: 960,
                width: '90vw',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
            }}
        >
            <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
                {(formik: FormikProps<FormUsuarioProps>) => (
                    <React.Fragment>
                        <SubTitle label="Adicionar Usuários" />

                        <CissTextField
                            label="Pesquisar"
                            placeholder="Digite um usuário..."
                            size="small"
                            sx={{ mb: 2, width: '50%' }}
                            deboundTime={500}
                            onChange={(e) => setQuery(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setQuery(formik.values.search)} edge="end">
                                            <SearchOutlinedIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Box
                            sx={{
                                height: 'calc(80vh - 215px)',
                                overflow: 'scroll',
                            }}
                        >
                            <PanelGrid
                                rows={rows}
                                rowsCount={rows.length ?? 0}
                                reload={refetch}
                                loading={fetchStatus === 'fetching'}
                                columns={columns}
                                visibleRows="all"
                                onClickRow={([idUsuario]) => setSelected(idUsuario as number)}
                                remote={false}
                            />
                        </Box>

                        <Stack
                            spacing={2}
                            justifyContent="flex-end"
                            direction="row"
                            sx={{
                                mt: 2,
                            }}
                        >
                            <Button variant="outlined" onClick={() => setOpen(false)}>
                                Cancelar
                            </Button>

                            <LoadingButton variant="contained" onClick={handleConfirmModal} loading={isLoading} disabled={isLoading || !selected}>
                                Atualizar
                            </LoadingButton>
                        </Stack>
                    </React.Fragment>
                )}
            </Formik>
        </ContentModal>
    );
}
