import React, { useId } from 'react';

import { Box, Grid, Typography } from '@mui/material';

interface DashedGridTotalizerListProps {
    label: string;
    value?: string | number;
    featured?: boolean;
}

interface DashedGridTotalizerProps {
    list: DashedGridTotalizerListProps[];
    title: string;
}

export function DashedGridTotalizer({ list, title }: DashedGridTotalizerProps): JSX.Element {
    const id = useId();

    return (
        <Grid
            item
            container
            sx={{
                'border': '1px dashed',
                'flexDirection': 'column',
                'flex': 1,
                'minWidth': 280,
                'p': 1,

                '& .MuiGrid-item': {
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            }}
        >
            <Grid item key={id} sx={{ mg: 4 }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: 'common.black',
                        mb: 1.5,
                    }}
                >
                    <strong>{title}</strong>
                </Typography>
            </Grid>

            {list.map(({ label, value, featured }) => {
                if (featured) {
                    return (
                        <Grid item sx={{ mt: 'auto' }} key={label + id}>
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography>{label}</Typography>
                                <Typography>
                                    <strong>{value}</strong>
                                </Typography>
                            </Box>
                        </Grid>
                    );
                }

                return (
                    <Grid item key={label + id}>
                        <Typography>{label}</Typography>
                        <Typography>{value}</Typography>
                    </Grid>
                );
            })}
        </Grid>
    );
}
