import { GridRowModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { SuccessMessageProps, getSuccessMessage } from 'util/success';

type UseDeletePedido = {
    rowData: GridRowModel;
};

type ReturnUseDeletePedido = {
    handleDelete: () => void;
};

export function useDeletePedido({ rowData }: UseDeletePedido): ReturnUseDeletePedido {
    const { idPedido } = rowData;
    const { showError } = useShowError();
    const api = useServiceApiContext();
    const queryClient = useQueryClient();

    const handleDelete = (): void => {
        api.delete(`/compra/pedidocompra/rascunho/${idPedido}`)
            .then((result: SuccessMessageProps) => {
                const message = getSuccessMessage(result);

                enqueueSnackbar(message, { variant: 'success' });

                queryClient.invalidateQueries(['getListRascunhos']);
                queryClient.invalidateQueries(['getTotalizadorRascunhos']);
            })
            .catch((error) => showError(error, 'Não foi possível remover o pedido. Tente novamente'));
    };

    return { handleDelete };
}
