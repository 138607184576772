import React, { Dispatch, SetStateAction } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { ContentModal } from 'components/modal';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { CallbackInfo } from 'pages/compra/carrinho/types';

type CarrinhoCallbackModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    callbackInfo: CallbackInfo | null;
};

export function CarrinhoCallbackModal({ open, setOpen, callbackInfo }: CarrinhoCallbackModal): JSX.Element {
    const navigate = useNavigate();
    const { loja, configPedido, cdPedidoInterno, message, title, confirm, usuarioPedido, confirmCallback, showConfirm } = callbackInfo || {};
    const queryClient = useQueryClient();
    const { content, setContent } = useFormContext();
    const { hasPermission } = usePermissionsContext();

    const handleConfirm = (): void => {
        navigate({
            pathname: '/compra/pedido-realizado',
            search: createSearchParams({
                idLoja: JSON.stringify(loja),
                ...(configPedido && { idConfigPedido: JSON.stringify(configPedido) }),
                ...(cdPedidoInterno && { cdPedidoInterno: JSON.stringify(cdPedidoInterno) }),
                ...(usuarioPedido && hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL']) && { idUsuarioPedido: JSON.stringify(usuarioPedido) }),
            }).toString(),
        });
    };

    const handleClose = (): void => {
        setOpen(false);

        queryClient.invalidateQueries(['getTotalizadorRascunhos']);
        queryClient.invalidateQueries(['getListRascunhos']).finally(() => setContent({ ...content, finalizarRow: false }));
    };

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            sx={{ borderRadius: 1, minWidth: { xs: 320, md: 400 } }}
            disableBackdropClick
            disableEscapeKeyDown
        >
            <Stack gap={2}>
                <Typography variant="h6"> {title || 'Pedido finalizado com sucesso!'}</Typography>

                {message && <Typography>{message}</Typography>}

                <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button variant="outlined" onClick={handleClose}>
                        Ok
                    </Button>

                    {showConfirm && (
                        <Button variant="contained" onClick={confirmCallback || handleConfirm}>
                            {confirm || 'Acompanhar Pedido'}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </ContentModal>
    );
}
