import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { FilterValuesProps, useFilter, ValueSetterFiltersProps } from 'hooks/useFilter';

export function CargoListFilter(): JSX.Element {
    const { handleFilterReset, handleFilterSubmit, setDefaultFilters } = useFilter();

    const [filterRazao, setFilterRazao] = useState<FilterValuesProps>({
        property: 'dsCargo',
        operator: 'like',
        value: '',
    });

    const [filterCod, setFilterCod] = useState<FilterValuesProps>({
        property: 'cdCargo',
        operator: 'like',
        value: null,
    });

    const filters: ValueSetterFiltersProps[] = useMemo(() => {
        return [
            { value: filterCod, setter: setFilterCod },
            { value: filterRazao, setter: setFilterRazao },
        ];
    }, [filterCod, filterRazao]);

    const resetFilter = useCallback(() => {
        handleFilterReset({ filters });
    }, [filters, handleFilterReset]);

    const startFilter = useCallback(() => {
        handleFilterSubmit({ filters });
    }, [filters, handleFilterSubmit]);

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField
                    label="Descrição do Cargo"
                    value={filterRazao.value}
                    onChange={(e) =>
                        setFilterRazao((oldState) => {
                            return { ...oldState, value: e.target.value };
                        })
                    }
                />
            </Grid>
            <Grid item flex={1}>
                <CissTextField
                    label="Código"
                    value={filterCod.value}
                    onChange={(e) =>
                        setFilterCod((oldState) => {
                            return { ...oldState, value: e.target.value };
                        })
                    }
                />
            </Grid>
        </Filter>
    );
}
