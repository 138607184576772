import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import TaskAltIcon from '@mui/icons-material/TaskAltOutlined';
import { Box, Paper, Typography } from '@mui/material';
import { isNumber } from 'lodash';

import { getColor, Icon, StatusIntegracaoCardIndicatorProps } from 'pages/compra/pedido-totalizador/status-integracao/types';
import { brlDecimal } from 'util/format';

const getIcon: Record<Icon, JSX.Element> = {
    clock: <AccessTimeIcon />,
    success: <TaskAltIcon />,
    cancel: <CancelIcon />,
    article: <ArticleIcon />,
    flag: <FlagOutlinedIcon />,
    box: <Inventory2OutlinedIcon />
};

export function StatusIntegracaoCardIndicator({ color, icon, label, value, peValue }: StatusIntegracaoCardIndicatorProps): JSX.Element {
    return (
        <Paper variant="outlined" sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <Box
                sx={{
                    p: 1,
                    backgroundColor: getColor[color],
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {getIcon[icon]}
            </Box>

            <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography>{label}</Typography>
                <Typography>
                    {value}
                    {isNumber(peValue) ? ` ~ ${brlDecimal(peValue)}%` : ''}
                </Typography>
            </Box>
        </Paper>
    );
}
