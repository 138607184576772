import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Tooltip } from '@mui/material';

import { ComunicadoIndex } from 'pages/inicio/types';

type ComunicadoFecharBtn = {
    comunicado: ComunicadoIndex;
    liEstouCiente: boolean;
    listComunicadosLength: number;
    handleFechar: () => void;
    isLoadingMutate: boolean;
    index: number;
    validateLiEstouCiente: boolean;
};

export function ComunicadoFecharBtn({
    isLoadingMutate,
    comunicado,
    index,
    listComunicadosLength,
    liEstouCiente,
    handleFechar,
    validateLiEstouCiente,
}: ComunicadoFecharBtn): JSX.Element {
    return (
        <Tooltip
            title={
                validateLiEstouCiente && index === listComunicadosLength - 1
                    ? 'É necessário clicar em "Li e Estou Ciente" para habilitar o botão'
                    : ''
            }
        >
            <span>
                <Button
                    disabled={
                        isLoadingMutate ||
                        (comunicado.fgSolicitaConfirmacao &&
                            (index < listComunicadosLength - 1 || (index === listComunicadosLength - 1 && !liEstouCiente)))
                    }
                    variant="contained"
                    onClick={handleFechar}
                    sx={{ width: '100%' }}
                    endIcon={<CloseIcon />}
                >
                    Fechar
                </Button>
            </span>
        </Tooltip>
    );
}
