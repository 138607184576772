import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { Field, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { FornecedorAutoComplete, FornecedorOptionsProps } from 'components/autocompletes/FornecedorAutoComplete';
import { StoreAutoComplete, StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { CissNumberField } from 'components/fields/';
import { Row } from 'components/form';
import { FormPage, Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { MotivoFieldFormik, NotaDevolucaoFormProdutosGrid } from 'pages/financeiro/nota-devolucao/components';
import {
    initialNotaDevolucaoFormValues,
    MovimentacaoProdutoSimulacaoDevolucaoDtosProps,
    NotaDevolucaoFormProps,
    RequestPostProdutosNotaDevolucaoFormProps,
    StateProdutosNota,
} from 'pages/financeiro/nota-devolucao/types';

export function NotaDevolucaoFormComp(): JSX.Element {
    const [stateProdutosNota, setStateProdutosNota] = useState<StateProdutosNota>('waiting');
    const [produtosNota, setProdutosNota] = useState<MovimentacaoProdutoSimulacaoDevolucaoDtosProps[]>([]);
    const { mutate } = useFormMutation<RequestPostProdutosNotaDevolucaoFormProps>();
    const navigate = useNavigate();
    const { setLoading } = useFormContext();
    const { id } = useParams();

    const url = useMemo(() => '/fiscal/movimentacaosimulacaodevolucao/simulacaodevolucao', []);

    const handleSubmit = useCallback(
        (values: NotaDevolucaoFormProps, formik: FormikHelpers<NotaDevolucaoFormProps>): void => {
            setLoading(true);

            values.movimentacaoProdutoSimulacaoDevolucaoDtos = produtosNota;

            mutate(
                {
                    url,
                    formik,
                    idField: 'idCatalogoArquivo',
                    formData: values,
                    preventRedirect: true,
                },
                {
                    onSuccess: (response) => {
                        const { data } = response.data;
                        const { nrOrdemVenda } = data;
                        const today = new Date().toISOString();
                        const dtFiltro = {
                            startDate: today,
                            endDate: today,
                        };

                        // após salvar, enviamos para a tela de listagem de notas, com o filtro especifico pela data de hoje + empresa selecionada + ordem de venda
                        navigate({
                            pathname: '/financeiro/nota-devolucao',
                            search: `?${createSearchParams({
                                dtFiltro: JSON.stringify(dtFiltro),
                                cdCliente: JSON.stringify(values.loja),
                                nrOrdemVenda: nrOrdemVenda.toString(),
                            })}`,
                        });
                    },
                    onError: () => {
                        formik.setSubmitting(false);

                        setLoading(false);
                    },
                },
            );
        },
        [mutate, navigate, produtosNota, setLoading, url],
    );

    const validationSchemaForm = useMemo(
        (): any =>
            Yup.object({
                loja: Yup.object({ idLoja: Yup.number() }).required().nullable(),
                pessoaFornecedor: Yup.object({ idPessoaFornecedor: Yup.number() }).required().nullable(),
                nrNotaOrigem: Yup.number().required().positive(),
                motivoDevolucao: Yup.object({ idMotivoDevolucao: Yup.number() }).required().nullable(),
            }),
        [],
    );

    return (
        <FormPage
            title="Simular Nota Fiscal de Devolução"
            values={initialNotaDevolucaoFormValues}
            onSubmit={handleSubmit}
            url={url}
            keepOriginalTitle
            fgAtivo={false}
            disableSaveBtn={!produtosNota.length}
            validationSchema={validationSchemaForm}
        >
            {(formik: FormikProps<NotaDevolucaoFormProps>) => {
                return (
                    <React.Fragment>
                        <Block>
                            <SubTitle label="Loja" />

                            <Row>
                                <Grid item flex={1 / 3}>
                                    <StoreAutoComplete
                                        name="loja"
                                        defaultStore={!id}
                                        value={formik.values.loja}
                                        error={formik.errors.loja}
                                        onChange={(e: SyntheticEvent<Element, Event>, value: StoreOptionsProps) => {
                                            formik.setFieldValue('loja', value);
                                            if (stateProdutosNota !== 'waiting') {
                                                setStateProdutosNota('waiting');
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item flex={1 / 3}>
                                    <FornecedorAutoComplete
                                        name="pessoaFornecedor"
                                        value={formik.values.pessoaFornecedor}
                                        error={formik.errors.pessoaFornecedor}
                                        onChange={(e: SyntheticEvent<Element, Event>, value: FornecedorOptionsProps) => {
                                            formik.setFieldValue('pessoaFornecedor', value);
                                            if (stateProdutosNota !== 'waiting') {
                                                setStateProdutosNota('waiting');
                                            }
                                        }}
                                    />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Observações/Informações complemementares" />

                            <Row>
                                <Grid item flex={1 / 3}>
                                    <CissNumberField
                                        name="nrNotaOrigem"
                                        label="Nota fiscal de origem"
                                        value={formik.values.nrNotaOrigem}
                                        preventEmptyField={false}
                                        error={formik.errors.nrNotaOrigem}
                                        onChange={(e) => {
                                            formik.setFieldValue('nrNotaOrigem', e.target.value);

                                            if (stateProdutosNota !== 'waiting') {
                                                setStateProdutosNota('waiting');
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item flex={1 / 3}>
                                    <Field component={MotivoFieldFormik} multiple={false} name="motivoDevolucao" />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Itens da Nota Fiscal" />

                            <NotaDevolucaoFormProdutosGrid
                                stateProdutosNota={stateProdutosNota}
                                setStateProdutosNota={setStateProdutosNota}
                                produtosNota={produtosNota}
                                setProdutosNota={setProdutosNota}
                            />
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
