import React, { useEffect, useState } from 'react';

import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';

import { useGenericAuth } from 'hooks/useGenericAuth';
import { Revendedor } from 'pages/revendedor/revendedor/types';

export function RevendedorAprovadoToggle(): JSX.Element | null {
    const { values, setFieldValue } = useFormikContext<Revendedor>();
    const [switchChecked, setSwitchChecked] = useState<boolean>(true);
    const { tokenInfo } = useGenericAuth();
    const hasFiles = values?.files?.length > 0;
    const hasPermission = tokenInfo?.tpUsuario && ['MASTER', 'SUPORTE_CISS'].includes(tokenInfo?.tpUsuario);
    const disableToggle = !hasFiles && !hasPermission;

    const handleChangeAprovado = (status: boolean): void => {
        setSwitchChecked(status);
        setFieldValue('aprovado', status);
    };

    useEffect(() => {
        if (values.aprovado !== switchChecked) {
            handleChangeAprovado(Boolean(values.aprovado));
        }
    }, [values.aprovado]);

    if (!hasPermission && values?.tipo === 'PJ') return null;

    return (
        <Tooltip
            title={disableToggle ? 'É necessário ter um anexo salvo para poder aprovar' : ''}
            componentsProps={{
                tooltip: {
                    sx: {
                        maxWidth: 330,
                    },
                },
            }}
        >
            <FormControlLabel
                control={<Switch checked={switchChecked} disabled={disableToggle} onChange={(e, checked) => handleChangeAprovado(checked)} />}
                label="Aprovado"
            />
        </Tooltip>
    );
}
