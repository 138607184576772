import React from 'react';

import { Autocomplete, Box } from '@mui/material';

import { CissTextField } from 'components/fields/';

type StatusImagem = {
    idStatus: number;
    dsStatus: string;
    vlStatus: boolean | null;
};

type StatusImagemAutoComplete = {
    onChange: (e: any, value: StatusImagem | null) => void;
    multiple?: boolean;
    label?: string;
    value: boolean | null;
};

const activeStatus: StatusImagem = {
    idStatus: 1,
    dsStatus: 'Com imagem',
    vlStatus: true,
};

const inativeStatus: StatusImagem = {
    idStatus: 2,
    dsStatus: 'Sem imagem',
    vlStatus: false,
};

const bothStatus: StatusImagem = {
    idStatus: 3,
    dsStatus: 'Ambos',
    vlStatus: null,
};

const data: StatusImagem[] = [activeStatus, inativeStatus, bothStatus];

const findOptionByValue = (value: StatusImagem | boolean | null): StatusImagem => {
    switch (value) {
        case null:
            return bothStatus;
        case true:
            return activeStatus;
        case false:
            return inativeStatus;
        default:
            return value;
    }
};

export function StatusImagemAutoComplete({ onChange, label = 'Imagens', value }: StatusImagemAutoComplete): JSX.Element | null {
    const selectedValue = findOptionByValue(value);

    return (
        <Autocomplete
            value={selectedValue}
            onChange={onChange}
            options={data}
            isOptionEqualToValue={(option, fieldValue) => option.idStatus === fieldValue.idStatus}
            getOptionLabel={(option: StatusImagem) => option.dsStatus}
            renderInput={(params) => <CissTextField label={label} {...params} />}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.idStatus}>
                    {option.dsStatus}
                </Box>
            )}
        />
    );
}
