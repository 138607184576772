import React, { cloneElement, useRef, useState } from 'react';

import { Button, Box, Typography, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CallbackUploadModalProps, UploadFilDataProps, UploadFileArquivoProps, UploadFileProps, UploadProps } from './types';

import { ConfirmModal } from 'components/modal';
import { UploadPreviewCover, UploadModal, UploadPreview } from 'components/upload/components';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { brlList } from 'util/format';

export function Upload({
    listFiles,
    setListFiles,
    fileLimit = 1,
    accept = '*',
    removeAll = true,
    uploadPreview = 'default',
    replaceFile,
    buttonText = 'Selecionar arquivo',
    removeAllText = 'Remover todos arquivos',
    error,
    disabled,
    timeoutRequest,
    isBannerForm = false,
    id,
}: UploadProps): JSX.Element {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false);
    const [openConfirmReplaceModal, setOpenConfirmReplaceModal] = useState(false);
    const fileTitle = useRef<string>('');

    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();

    const activeFiles = listFiles.filter((file) => {
        const { fgAtivo } = file;

        return typeof fgAtivo === 'boolean' ? fgAtivo : true;
    });

    let componentUploadPreview = null;

    if (typeof uploadPreview === 'string') {
        if (uploadPreview === 'cover') {
            componentUploadPreview = <UploadPreviewCover listFiles={activeFiles} setListFiles={setListFiles} disabled={disabled} />;
        } else {
            componentUploadPreview = <UploadPreview listFiles={activeFiles} setListFiles={setListFiles} disabled={disabled} />;
        }
    } else if (typeof uploadPreview === 'object') {
        componentUploadPreview = cloneElement(uploadPreview, { listFiles: activeFiles, setListFiles: setListFiles });
    }

    const handleConfirmExcluir = (): void => {
        setOpenConfirmRemoveModal(true);
    };

    const callbackExcluir = (): void => {
        setListFiles(
            listFiles.map((item: UploadFileProps) => {
                return {
                    ...item,
                    fgAtivo: false,
                    fgExcluido: true,
                };
            }),
        );
    };

    const handleUploadSuccess = (arquivo: UploadFileArquivoProps): void => {
        const newFile = {
            fgAtivo: true,
            fgCapa: !listFiles.length,
            fgExcluido: false,
            fgNovo: true,
            arquivo,
        };

        if (replaceFile) {
            setListFiles([newFile]);
        } else {
            setListFiles([...listFiles, newFile]);
        }
    };

    const { mutate, isLoading } = useUploadFileMutation(
        {
            onSuccess: (response: UploadFilDataProps) => {
                const { data } = response;

                handleUploadSuccess({ ...data, dsTitulo: fileTitle.current });
                setOpenUploadModal(false);

                enqueueSnackbar('Arquivo enviado com sucesso.', { variant: 'success' });
            },

            onError: (error) => (!isBannerForm ? showError(error, 'Ocorreu um erro ao enviar seu arquivo, tente novamente.') : null),
        },
        isBannerForm,
        timeoutRequest,
    );

    const handleModalConfirm = ({ dsTitulo, file }: CallbackUploadModalProps): void => {
        fileTitle.current = dsTitulo;

        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            mutate({ formData });
        }
    };

    const handleSelecionarArquivo = (): void => {
        if (replaceFile && activeFiles.length) {
            setOpenConfirmReplaceModal(true);
        } else {
            setOpenUploadModal(true);
        }
    };

    return (
        <React.Fragment>
            <Grid container gap={2}>
                <Box>
                    <Button
                        id={id}
                        variant="contained"
                        onClick={handleSelecionarArquivo}
                        disabled={Boolean(disabled) || (replaceFile ? false : activeFiles.length >= fileLimit)}
                    >
                        <Typography>{buttonText}</Typography>
                    </Button>

                    {error && (
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.error.light,
                                fontSize: 11,
                                mt: 1,
                                ml: 1,
                            }}
                        >
                            {error}
                        </Typography>
                    )}
                </Box>

                {removeAll && Boolean(activeFiles.length) && (
                    <Button variant="outlined" onClick={handleConfirmExcluir} disabled={Boolean(disabled)} sx={{ maxHeight: 33 }}>
                        <Typography>{removeAllText}</Typography>
                    </Button>
                )}
            </Grid>

            <Typography
                variant="caption"
                sx={{
                    display: 'block',
                    mt: 1,
                }}
            >
                <strong>EXTENSÕES ACEITAS:</strong> {brlList(accept)}
            </Typography>

            {componentUploadPreview}

            <UploadModal open={openUploadModal} setOpen={setOpenUploadModal} callback={handleModalConfirm} loading={isLoading} accept={accept} />

            {removeAll && (
                <ConfirmModal
                    open={openConfirmRemoveModal}
                    setOpen={setOpenConfirmRemoveModal}
                    confirm={callbackExcluir}
                    text="Tem certeza que deseja remover todos os arquivos?"
                />
            )}

            {replaceFile && (
                <ConfirmModal
                    open={openConfirmReplaceModal}
                    setOpen={setOpenConfirmReplaceModal}
                    confirm={() => setOpenUploadModal(true)}
                    text="Tem certeza que deseja substituir o arquivo selecionado?"
                />
            )}
        </React.Fragment>
    );
}
