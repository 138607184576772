import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TabelaPrecoOptionsProps {
    idTabelaPreco: number;
    dsTabelaPreco: string;
    cdTabelaPreco: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function TabelaPrecoAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Tabela de preço"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{cdTabelaPreco} - {dsTabelaPreco}"
            optionStringTemplate="<strong>{cdTabelaPreco}</strong> - {dsTabelaPreco}"
            requestOptions={{
                url: '/gestao/tabelapreco',
                sort: [{ property: 'dsTabelaPreco', direction: 'ASC' }],
                limit: 50,
            }}
            isOptionEqualToValue={(option: TabelaPrecoOptionsProps, value: TabelaPrecoOptionsProps) => value && option && option.idTabelaPreco === value.idTabelaPreco}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
