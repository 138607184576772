import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadIcon from '@mui/icons-material/Download';
import FolderIcon from '@mui/icons-material/Folder';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { Box, IconButton, Typography, Paper, Link, Stack, Breadcrumbs, Skeleton, Button, Grid, useTheme, useMediaQuery } from '@mui/material';

import { EditRemoveBtn } from 'components/buttons';
import { InactiveBox } from 'components/content';
import { Tag, TagGroup } from 'components/text';
import useBlobFile from 'hooks/useBlobFile';
import { MimeTypeIconProp, useMimeTypes } from 'hooks/useMimeTypes';
import { useFormMutation } from 'mutations/useFormMutation';

export interface DownloadBoxProps {
    id?: number;
    label: string;
    description?: string;
    image?: string;
    icon?: MimeTypeIconProp;
    mimetype?: string;
    extensao?: string;
    showEditBtn?: boolean;
    showRemoveBtn?: boolean;
    hidden?: boolean;
    link: string;
    tags?: string[];
    date?: string;
    type?: string | string[];
    refresh?: () => void;
    removeUrl?: string;
    addViewUrl?: string;
    active?: boolean;
    downloads?: number;
}

export function DownloadBoxSkeleton(): JSX.Element {
    return (
        <Box sx={{ display: 'flex', p: 1.5 }}>
            <Skeleton variant="rectangular" width={80} height={60} />
            <Box sx={{ ml: 2, flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="60%" />
            </Box>
            <Box sx={{ ml: 2, width: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="rectangular" width={20} height={20} />
            </Box>
        </Box>
    );
}

/**
 * Bloco em linha para apresentação de download, contendo numero curtidas, numero downloads, thumb, titulo e botão para download
 */
export function DownloadBox({
    image,
    id,
    label,
    description,
    link,
    tags,
    date,
    type,
    icon,
    active,
    showEditBtn = true,
    showRemoveBtn = true,
    refresh,
    removeUrl,
    addViewUrl,
    mimetype,
    extensao,
    downloads,
    hidden = false,
}: DownloadBoxProps): JSX.Element | null {
    const { mutate } = useFormMutation();
    const { getIcon } = useMimeTypes();
    const { urlToFile } = useBlobFile();

    const [qtDownloads, setQtDownloads] = useState<number | undefined>(downloads);

    let icone = icon;

    const handleLinkClick = (): void => {
        const fileExt = extensao || link.split('.').at(-1);

        urlToFile({
            url: link,
            filename: `${label}.${fileExt}`,
        });

        if (addViewUrl) {
            mutate(
                {
                    url: addViewUrl,
                    preventSnack: true,
                },
                {
                    onSuccess: () => setQtDownloads(qtDownloads ? qtDownloads + 1 : 1),
                },
            );
        }
    };

    if (mimetype || extensao) {
        icone = getIcon(mimetype || extensao);
    }

    if (hidden) {
        return null;
    }

    return (
        <InactiveBox active={active}>
            <Paper
                sx={{
                    p: 1.5,
                }}
            >
                <Grid container direction="row" spacing={2} rowSpacing={0.1}>
                    <Grid item lg={1} xs={2} alignItems="center" justifyContent="center" display="flex">
                        {image && (
                            <Box
                                className="box-img-preview"
                                sx={{
                                    width: 80,
                                    height: 60,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundImage: `url("${image}")`,
                                    borderRadius: 1,
                                }}
                            />
                        )}

                        {icone && !image && (
                            <Box
                                className="box-img-preview"
                                sx={{
                                    width: 80,
                                    height: 60,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <FontAwesomeIcon icon={icone} size="2x" />
                            </Box>
                        )}
                    </Grid>

                    <Grid item container lg={9} xs={10} direction="column" justifyContent="space-between">
                        <Typography variant="h6" sx={{ color: 'common.black' }}>
                            {label}
                        </Typography>

                        {description && (
                            <Typography variant="body2" sx={{ color: 'common.grey' }}>
                                {description}
                            </Typography>
                        )}

                        <Grid item container sx={{ gap: { xs: 1, lg: 3 }, pt: { xs: 1, lg: 0 }, alignItems: { xs: 'flex-start', lg: 'center' }, flexDirection: { xs: 'column', lg: 'row' } }}>
                            {date && (
                                <Typography variant="body2" sx={{ color: 'primary.main', whiteSpace: 'nowrap' }}>
                                    {date}
                                </Typography>
                            )}

                            {typeof type === 'string' && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'primary.main',
                                        pt: {
                                            xs: 1.4,
                                            md: 0,
                                        },
                                    }}
                                >
                                    {type.toUpperCase()}
                                </Typography>
                            )}

                            {Array.isArray(type) && type.length > 0 && (
                                <Breadcrumbs
                                    sx={{
                                        '& .MuiBreadcrumbs-separator': {
                                            mx: 0,
                                        },
                                    }}
                                    separator={<NavigateNextOutlinedIcon fontSize="small" />}
                                >
                                    {type.map((item, index) => {
                                        const icon = index === 0 ? <FolderIcon sx={{ mr: 0.5, fontSize: '1.1rem' }} /> : null;

                                        return (
                                            <Typography
                                                key={`bread_download_${item}`}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {icon}
                                                {item}
                                            </Typography>
                                        );
                                    })}
                                </Breadcrumbs>
                            )}

                            {Array.isArray(tags) && tags.length > 0 && (
                                <TagGroup max={1}>
                                    {tags.map((tag) => (
                                        <Tag label={tag} key={Math.random()} />
                                    ))}
                                </TagGroup>
                            )}
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        lg={2}
                        xs={12}
                        sx={{
                            justifyContent: {
                                xs: 'space-between',
                                lg: 'flex-end',
                            },
                            flexDirection: {
                                xs: 'row-reverse',
                                lg: 'column',
                            },
                            gap: 2,
                        }}
                    >
                        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', gap: 1 }}>
                            {typeof qtDownloads === 'number' && (
                                <Typography variant="h6" component="span" sx={{ p: 0 }} style={{ marginLeft: 'auto' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <DownloadIcon sx={{ color: 'info.light', mr: 1 }} />
                                        <Box component="span">{qtDownloads}</Box>
                                    </Box>
                                </Typography>
                            )}

                            {link && (
                                <IconButton onClick={handleLinkClick}>
                                    <CloudDownloadIcon
                                        fontSize="large"
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    />
                                </IconButton>
                            )}
                        </Grid>

                        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {id && (
                                <Box>
                                    <EditRemoveBtn id={id} showEditBtn={showEditBtn} showRemoveBtn={showRemoveBtn} refresh={refresh} removeUrl={removeUrl} sx={{ pr: 1 }} />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </InactiveBox>
    );
}
