import React, { useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { DownloadModeloArquivoBtn, ImportFileBtn } from 'components/buttons';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

interface PedidoLoteCompraImportarModal {
    open: boolean;
    setOpen: (action: boolean) => void;
}

export function DadosEntregaImportarModal({ open, setOpen }: PedidoLoteCompraImportarModal): JSX.Element {
    const [fileImportacao, setFileImportacao] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: () => {
            enqueueSnackbar('Importação de frete e prazos realizada com sucesso', { variant: 'success' });

            setFileImportacao(null);
        },
        onError: (error: any) => showError(error, 'Falha ao importar o arquivo'),
    });

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (fileImportacao) {
            formData.append('configEstado', fileImportacao);
        }

        mutate(
            {
                url: '/gestao/configestado/upload',
                formData,
            },
            {
                onSuccess: () => {
                    setOpen(false);
                    queryClient.invalidateQueries(['getListFretePrazos']);
                },
            },
        );
    }, [fileImportacao]);

    useEffect(() => {
        if (!open) {
            setFileImportacao(null);
        }
    }, [open]);

    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 2, minWidth: { xs: '80vw', sm: 500 } }} closeButton>
            <Box sx={{ p: 1 }}>
                <SubTitle label="Importação de Frete e Prazos" sx={{ width: '100%' }} />

                <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_FRETES_E_PRAZOS" sx={{ mb: 2, minWidth: { xs: '100%', sm: 'auto' } }} />

                <ImportFileBtn setFile={setFileImportacao} file={fileImportacao} accept={['.xlsx', '.csv']} />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={handleSubmit} loading={isLoading} disabled={!fileImportacao || isLoading}>
                        Importar
                    </LoadingButton>
                </Box>
            </Box>
        </ContentModal>
    );
}
