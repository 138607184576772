import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { DownloadModeloArquivoBtn } from 'components/buttons';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    ConfiguracaoPedidoCompraProdutosQuebraModal,
    ConfiguracaoPedidoHeaderActions,
    ConfiguracaoPedidoCompraProdutosQuebrasGrid,
    ConfiguracaoPedidoCompraImportacaoUploadBtn,
    ConfiguracaoPedidoCompraQuebrasModal,
} from 'pages/compra/configuracao-pedido/components';
import { ProdutoQuebra } from 'pages/compra/configuracao-pedido/types';

export function ConfiguracaoPedidoCompraQuebrasDinamicas(): JSX.Element {
    const { setActiveStepSaved, setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { content, setContent } = useFormContext();
    const { produtoQuebraList, initialProdutoQuebraList } = content || { produtoQuebraList: [], initialProdutoQuebraList: [] };
    const { mutate, isLoading } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { id } = useParams();

    const [openQuebrasModal, setOpenQuebrasModal] = useState<boolean | string>(false);
    const [openProdutoQuebraModal, setOpenProdutoQuebraModal] = useState<boolean>(false);
    const [valueFilter, setValueFilter] = useState('');
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const getPostProdutoQuebraList = useCallback((): ProdutoQuebra[] => {
        const newProdutoQuebraList = produtoQuebraList.map((produto: ProdutoQuebra) => produto);

        // Caso exista algum produto que tem na lista inicial mas não está na lista atual, o produto é adicionado com o fgAtivo = false.
        initialProdutoQuebraList.forEach((produto: ProdutoQuebra) => {
            const produtoExist = produtoQuebraList.find((item: ProdutoQuebra) => item.produto.idProduto === produto.produto.idProduto);

            if (!produtoExist) {
                produto.fgAtivo = false;

                newProdutoQuebraList.push(produto);
            }
        });

        return newProdutoQuebraList.map((produto: ProdutoQuebra) => {
            return {
                ...produto,

                configPedido: {
                    idConfigPedido: Number(id),
                },
            };
        });
    }, [id, initialProdutoQuebraList, produtoQuebraList]);

    const validateProdutoIncorreto = useCallback(() => {
        const productIncorrect = produtoQuebraList.find((produtoQuebra: ProdutoQuebra) => !produtoQuebra.condicaoPagamento || !produtoQuebra.peQuebra);

        if (productIncorrect) {
            const { peQuebra, nrQuebra, condicaoPagamento, produto } = productIncorrect;
            const { dsProduto } = produto || {};

            if (!peQuebra) {
                enqueueSnackbar(
                    <Typography>
                        O produto <strong>{dsProduto}</strong> com a quebra de N.º {nrQuebra} não contém porcentagem
                    </Typography>,
                    { variant: 'error' },
                );
            } else if (!condicaoPagamento) {
                enqueueSnackbar(
                    <Typography>
                        O produto <strong>{dsProduto}</strong> com a quebra de N.º {nrQuebra} não contém condição de pagamento
                    </Typography>,
                    { variant: 'error' },
                );
            }

            return false;
        }

        return true;
    }, [enqueueSnackbar, produtoQuebraList]);

    const validateProdutoPeQuebraIncorreto = useCallback(() => {
        const produtosPeQuebraTotalizada: { idProduto: number; dsProduto: string; peQuebra: number }[] = [];

        produtoQuebraList.forEach((produtoQuebra: ProdutoQuebra) => {
            const produtoInPeQuebra = produtosPeQuebraTotalizada.find((produtoPeQuebra) => produtoPeQuebra.idProduto === produtoQuebra.produto?.idProduto);

            if (!produtoInPeQuebra) {
                produtosPeQuebraTotalizada.push({ idProduto: produtoQuebra.produto.idProduto, peQuebra: produtoQuebra.peQuebra || 0, dsProduto: produtoQuebra.produto.dsProduto });
            } else {
                const indexProdutoInPeQuebra = produtosPeQuebraTotalizada.findIndex((produtoPeQuebra) => produtoPeQuebra.idProduto === produtoQuebra.produto?.idProduto);

                produtosPeQuebraTotalizada.splice(indexProdutoInPeQuebra, 1, {
                    ...produtoInPeQuebra,
                    peQuebra: produtoInPeQuebra.peQuebra + (produtoQuebra.peQuebra || 0),
                });
            }
        });

        const produtoPeQuebraIncorreta = produtosPeQuebraTotalizada.find((produtoPeQuebra) => produtoPeQuebra.peQuebra !== 100);

        if (produtoPeQuebraIncorreta) {
            const { dsProduto, peQuebra } = produtoPeQuebraIncorreta;

            enqueueSnackbar(
                <Typography>
                    O produto <strong>{dsProduto}</strong> tem uma porcentagem total de quebra de {peQuebra}%. A soma das porcentagens de quebra deve ser igual a 100%
                </Typography>,
                { variant: 'error' },
            );
            return false;
        }

        return true;
    }, [enqueueSnackbar, produtoQuebraList]);

    const validateProdutoQuebraList = useCallback(() => validateProdutoIncorreto() && validateProdutoPeQuebraIncorreto(), [validateProdutoIncorreto, validateProdutoPeQuebraIncorreto]);

    const handleSave = useCallback((): void => {
        if (validateProdutoQuebraList()) {
            const formData = getPostProdutoQuebraList();

            mutate(
                {
                    url: '/gestao/configpedidoquebra/insertlist',
                    formData,
                    preventSnack: true,
                },
                {
                    onSuccess: ({ data }: any) => {
                        const { message } = data;
                        const newProdutoQuebraList = data?.data?.map((item: ProdutoQuebra) => {
                            item.dtFim = `${item.dtFim}T00:00:00`;
                            item.dtInicio = `${item.dtInicio}T00:00:00`;

                            return item;
                        });

                        setContent({ produtoQuebraList: newProdutoQuebraList, initialProdutoQuebraList: newProdutoQuebraList });

                        enqueueSnackbar('Configuração de quebra salva com sucesso', { variant: 'success' });

                        if (message) {
                            setOpenQuebrasModal(message);
                        }

                        setActiveStepSaved();
                    },
                    onError: (error: any) => showError(error, 'Não foi possível salvar a configuração de quebra'),
                },
            );
        }
    }, [enqueueSnackbar, getPostProdutoQuebraList, mutate, setActiveStepSaved, setContent, showError, validateProdutoQuebraList]);

    const handleExcluir = useCallback(
        (idList: string[]): void => {
            const idListFormatted = idList.map((item) => {
                const [idProduto, nrQuebra] = item.split('_');

                return { idProduto: Number(idProduto), nrQuebra: Number(nrQuebra) };
            });

            const produtoQuebraListFiltered = produtoQuebraList.filter((produtoQuebra: ProdutoQuebra) => {
                return !idListFormatted.some((item) => produtoQuebra.produto.idProduto === item.idProduto && produtoQuebra.nrQuebra === item.nrQuebra);
            });

            const produtoQuebraListFormatted = produtoQuebraListFiltered.map((produtoQuebra: ProdutoQuebra) => {
                idListFormatted.forEach(({ idProduto, nrQuebra }) => {
                    if (produtoQuebra.produto.idProduto === idProduto) {
                        if (produtoQuebra.qtQuebra) {
                            produtoQuebra.qtQuebra = --produtoQuebra.qtQuebra;
                        }

                        if (produtoQuebra.nrQuebra && produtoQuebra.nrQuebra > nrQuebra) {
                            produtoQuebra.nrQuebra = --produtoQuebra.nrQuebra;
                        }
                    }
                });

                return produtoQuebra;
            });

            setContent({ ...content, produtoQuebraList: produtoQuebraListFormatted });
            setActiveStepDirty();
        },
        [content, produtoQuebraList, setActiveStepDirty, setContent],
    );

    return (
        <React.Fragment>
            <FullLoading loading={isLoading} />

            <ConfiguracaoPedidoCompraProdutosQuebraModal open={openProdutoQuebraModal} setOpen={setOpenProdutoQuebraModal} />

            <ConfiguracaoPedidoCompraQuebrasModal open={openQuebrasModal} setOpen={setOpenQuebrasModal} />

            <Block>
                <SubTitle label="Ações" />

                <Grid container sx={{ my: 3, justifyContent: 'space-between', gap: 2 }}>
                    <Grid item>
                        <ConfiguracaoPedidoCompraImportacaoUploadBtn contentListName="produtoQuebraList" url={`gestao/configpedidoquebra/upload/${id}`} onSend={() => setActiveStepDirty()} />
                    </Grid>
                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_QUEBRA_DINAMICA_CONFIG_PEDIDO" />
                    </Grid>
                </Grid>
            </Block>

            <Block>
                <SubTitle label={'Produtos'} />

                <ConfiguracaoPedidoHeaderActions
                    setValueFilter={setValueFilter}
                    selectedRows={selectedRows}
                    searchPlaceholder="Código ou nome do produto..."
                    onClickAdd={() => setOpenProdutoQuebraModal(true)}
                    onClickRemove={() => handleExcluir(selectedRows)}
                />

                <ConfiguracaoPedidoCompraProdutosQuebrasGrid valueFilter={valueFilter} handleExcluir={handleExcluir} setSelectedRows={setSelectedRows} />
            </Block>

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <LoadingButton variant="contained" loading={isLoading} disabled={isLoading} size="large" onClick={() => handleSave()}>
                    Salvar
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}