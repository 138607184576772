import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterTag(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterCodigo, setfilterCodigo] = useState<FilterValuesProps>({
        property: 'idTagComunicado',
        operator: 'eq',
        value: null,
        idProperty: 'idTagComunicado',
    });
    const [nomeTag, setNomeTag] = useState<FilterValuesProps>({
        property: 'dsTagComunicado',
        operator: 'like',
        idProperty: 'dsTagComunicado',
    });

    const filters = [
        { value: filterCodigo, setter: setfilterCodigo },
        {
            value: nomeTag,
            setter: setNomeTag,
        },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField
                    label="Código"
                    value={filterCodigo.value}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => setfilterCodigo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
            <Grid item flex={4}>
                <CissTextField label="Tag" value={nomeTag.value} onChange={(e) => setNomeTag((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>
        </Filter>
    );
}
