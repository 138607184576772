import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestGrupoEmailsProps } from 'pages/complementar/grupo-emails/types';

const getRows = (data: RequestGrupoEmailsProps | undefined):GridRowsProp => {
    if (Array.isArray(data?.data) && data?.data.length) {
        return data?.data.map((item) => ({
            id: item.idGrupoEmail,
            idGrupoEmail: item.idGrupoEmail,
            dsGrupoEmail: item.dsGrupoEmail,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function GrupoEmailsGrid(): JSX.Element {
    const { filter, page, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/grupoemail',
        sort: sort.length ? sort : [{ property: 'dsGrupoEmail', direction: 'DESC' }],
        filter,
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestGrupoEmailsProps> => RequestListagem(requestOptions).then((res) => res.data));
    
    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'dsGrupoEmail', headerName: 'Processos', flex: 1, type: 'string' },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => <EditRemoveBtn id={row.idGrupoEmail} showEditBtn={true} refresh={refetch} />,
            },
        ],
        [refetch],
    );

    return (
        <Block>
            <PanelGrid rows={getRows(data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
        </Block>
    );
}
