import { createTheme, PaletteOptions, Theme, ThemeOptions, Components, alpha } from '@mui/material';
import { grey, common } from '@mui/material/colors';
import { ptBR } from '@mui/material/locale';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { deepmerge } from '@mui/utils';
import { ptBR as ptBRGrid } from '@mui/x-data-grid-pro';

const defaultPalette: PaletteOptions = {
    mode: 'light',
};

const defaultComponents: Components = {
    MuiButton: {
        styleOverrides: {
            sizeLarge: {
                fontSize: '1.1rem',
            },
        },
    },
    MuiLink: {
        defaultProps: {
            underline: 'hover',
        },
    },
    MuiMenu: {
        styleOverrides: {
            list: {
                padding: 0,
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            fullWidth: true,
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                '.Mui-disabled': {
                    color: `${alpha(common.black, 0.6)} !important`,
                    WebkitTextFillColor: `${alpha(common.black, 0.6)} !important`,
                },
                '&.Mui-disabled fieldset': {
                    borderColor: `${alpha(common.black, 0.2)} !important`,
                    backgroundColor: `${alpha(common.black, 0.08)} !important`,
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: '.9rem',
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            standard: {
                'border': '1px solid',
                '& .MuiAlert-message': {
                    lineHeight: '20px',
                },
            },
            standardInfo: {
                borderColor: '#03a9f4',
            },
            standardWarning: {
                borderColor: '#ff9800',
            },
            standardError: {
                borderColor: '#ef5350',
            },
            standardSuccess: {
                borderColor: '#4caf50',
            },
        },
    },
    MuiAutocomplete: {
        defaultProps: {
            autoComplete: true,
            autoHighlight: true,
            filterSelectedOptions: true,
            filterOptions: (options) => options, // desabilita filtro local, sempre retornando as opções que existirem
        },
    },
};

const defaultTypography: TypographyOptions = {
    subtitle1: {
        fontSize: '1.3rem',
        color: grey[600],
    },
    h4: {
        fontWeight: 200,
    },
    h6: {
        fontWeight: 400,
    },
};

/**
 * Responsável pela criação do tema, unindo opções personalizadas (cliente) e padrões (boilerplate)
 * @param options (suporta palette e components)
 * @returns novo tema
 */
export const updateDefaultTheme = (options?: ThemeOptions): Theme => {
    return createTheme(
        {
            palette: deepmerge(defaultPalette, options?.palette),
            components: deepmerge(defaultComponents, options?.components),
            typography: deepmerge(defaultTypography, options?.typography),
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1400, // alteração feita somente no xl
                },
            },
        },
        ptBR,
        ptBRGrid,
    );
};
