import React from 'react';

import { Configuration } from './useHostConfig';

import { useDomainConfigContext } from 'context/DomainConfig';

export function useGetDomainConfig(): Configuration {
    const { configuration } = useDomainConfigContext();

    return configuration;
}
