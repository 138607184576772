import React, { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';

import { CissNumberField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { FullLoading } from 'components/loading';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { CargoRequest, initialValuesFormik } from 'pages/complementar/cargo/types';

export function CargoFormComp(): JSX.Element {
    const { setContent, content } = useFormContext();
    const { mutate, isLoading } = useFormMutation();

    const url = '/gestao/cargo/';

    const handleSubmit = (values: CargoRequest, formik: FormikHelpers<CargoRequest>): void => {
        mutate(
            {
                url,
                idField: 'idCargo',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };
    return (
        <FormPage title={'Cargo e Crédito'} values={initialValuesFormik} onSubmit={handleSubmit} url={url}>
            {(formik: FormikProps<CargoRequest>) => {
                return (
                    <React.Fragment>
                        <FullLoading loading={isLoading} />

                        <Block>
                            <Row>
                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Descrição cargo"
                                        name="dsCargo"
                                        value={formik.values.dsCargo}
                                        error={formik.errors.dsCargo}
                                        onChange={(e) => formik.setFieldValue('dsCargo', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Valor Crédito R$"
                                        name="vlLimiteCredito"
                                        inputType="decimal"
                                        value={formik.values.vlLimiteCredito}
                                        error={formik.errors.vlLimiteCredito}
                                        onChange={(e) => formik.setFieldValue('vlLimiteCredito', Number(e.target.value))}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Código de integração"
                                        type="number"
                                        name="cdCargo"
                                        value={formik.values.cdCargo}
                                        error={formik.errors.cdCargo}
                                        disabled={Boolean(content)}
                                        onChange={(e) => formik.setFieldValue('cdCargo', e.target.value)}
                                    />
                                </Grid>
                            </Row>
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
