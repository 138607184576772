import { enqueueSnackbar } from 'notistack';

import { Revendedor, RevendedorFileResponse } from '../types';

import { useFormContext } from 'context/FormContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { brlList } from 'util/format';

type HandleSubmitFiles = {
    newData: Revendedor;
    newFiles: File[];
};

type UsePostRevendedorFilesReturn = {
    handleSubmitFiles: (props: HandleSubmitFiles) => void;
    isLoadingFiles: boolean;
};

export function usePostRevendedorFiles(): UsePostRevendedorFilesReturn {
    const { showError } = useShowError();
    const { setContent } = useFormContext();
    const { mutate, isLoading: isLoadingFiles } = useUploadFileMutation();

    const handleSubmitFiles = ({ newData, newFiles }: HandleSubmitFiles): void => {
        const formData = new FormData();

        newFiles?.forEach((file) => formData.append('files', file));

        formData.append('documento', String(newData.documento));

        mutate(
            {
                url: '/integracao-revendedor/revendedorfiles/list',
                formData,
            },
            {
                onSuccess: ({ data }) => {
                    const { files, erros } = data as unknown as RevendedorFileResponse;

                    setContent({
                        ...newData,
                        files: [...newData.files, ...files],
                        newFiles: newFiles.filter((file: File) => erros.includes(file.name)),
                    });

                    enqueueSnackbar('Revendedor salvo com sucesso!', { variant: 'success' });

                    if (erros.length) {
                        enqueueSnackbar(`Anexos ${brlList(erros)} não puderam ser importados`, { variant: 'warning' });
                    }
                },
                onError: (error: any) => showError(error.data, 'Falha ao salvar os anexos'),
            },
        );
    };

    return { handleSubmitFiles, isLoadingFiles };
}
