import { useEffect } from 'react';

interface useTitleProps {
    documentTitle: string;
    pageTitle?: string;
}

export function useTitle(props: useTitleProps): void {
    const { documentTitle, pageTitle } = props;

    useEffect(() => {
        document.title = `${documentTitle}${pageTitle && documentTitle !== pageTitle ? ` - ${pageTitle}` : ''}`;
    }, [documentTitle, pageTitle]);
}
