import React from 'react';
import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

interface UnidadeMedidaProps {
    idUnidadeMedidaPeca: number;
    dsUnidadeMedidaPeca: string;
}

type UnidadeMedidaAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'sx' | 'onChange' | 'name'>;

export function UnidadeMedidaAutoComplete({ value, error, multiple = false, sx, onChange, name }: UnidadeMedidaAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Unidade de Medida"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idUnidadeMedidaPeca} - {dsUnidadeMedidaPeca}"
            optionStringTemplate="<strong>{idUnidadeMedidaPeca}</strong> - {dsUnidadeMedidaPeca}"
            requestOptions={{
                url: '/gestao/unidademedidapeca',
                sort: [{ property: 'dsUnidadeMedidaPeca', direction: 'ASC' }],
                columns: 'idUnidadeMedidaPeca,dsUnidadeMedidaPeca',
                limit: 50,
            }}
            isOptionEqualToValue={(option: UnidadeMedidaProps, value: UnidadeMedidaProps) => option.idUnidadeMedidaPeca === value.idUnidadeMedidaPeca}
            onChange={onChange}
            sx={sx}
            name={name}
        />
    );
}
