import React from 'react';

import { PedidoFormComp, PedidoListComp, PedidoGradeComp } from './components';

import { ListagemProvider } from 'context/ListagemContext';
import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function PedidoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['PEDIDO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PedidoListComp />
        </ListagemProvider>
    );
}

export function PedidoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['PEDIDO_CADASTRAR'])) return alertNotPermission();
    
    return (
        <FormProvider>
            <PedidoFormComp />
        </FormProvider>
    );
}

export function PedidoGrade(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['PEDIDO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PedidoGradeComp />
        </ListagemProvider>
    );
}
