import React from 'react';

import { Stack, Typography } from '@mui/material';

import { ProdutosEstoque } from 'pages/compra/carrinho/components/templates/CarrinhoFinalizarModal';

export function CarrinhoFalhaEstoqueText({ produtosEstoque }: { produtosEstoque: ProdutosEstoque[] }): JSX.Element {
    return (
        <React.Fragment>
            <Typography>
                Devido a quantidade de estoque indisponível, os produtos abaixo precisam ser atualizados para essas novas quantidades para serem
                finalizados:
            </Typography>

            <Stack gap={0.5}>
                {produtosEstoque.map((produtoEstoque) => {
                    const { dsProduto, qtEstoqueDisponivel, sgUnidadeMedida, cdProduto } = produtoEstoque;

                    return (
                        <li key={cdProduto}>
                            {dsProduto} -{' '}
                            <strong>
                                {qtEstoqueDisponivel} {sgUnidadeMedida}
                            </strong>
                        </li>
                    );
                })}
            </Stack>
        </React.Fragment>
    );
}
