import React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { CalendarioListComp } from './components';

import { CalendarEventsDisabledProvider } from 'context/CalendarEventsDisabledContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function CalendarioList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['CALENDARIO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                <CalendarEventsDisabledProvider>
                    <CalendarioListComp />
                </CalendarEventsDisabledProvider>
            </LocalizationProvider>
        </ListagemProvider>
    );
}
