import React, { forwardRef, useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { ConfiguracaoPedidoAutoComplete, StoreAutoComplete, SituacaoPedidoLoteAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export const PedidoLoteCompraGridFilter = forwardRef((props, ref) => {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const [lastLengthConfig, setLastLengthConfig] = useState(0);
    const { hasPermission } = usePermissionsContext();
    const hasPermissionInstitucional = hasPermission(['COMPRA_PEDIDO_ACESSA_LOJA_INSTITUCIONAL']);
    const data = new Date();

    const [filterConfigPedido, setFilterConfigPedido] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        operator: 'in',
        value: [],
        idProperty: 'idConfigPedido',
    });

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'in',
        value: [],
        idProperty: 'idLoja',
    });

    const [filterSituacao, setFilterSituacao] = useState<FilterValuesProps>({
        property: 'idStatusProcessamento',
        operator: 'in',
        value: [],
        idProperty: 'idStatusProcessamento',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhInclusao',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterSituacao, setter: setFilterSituacao },
        { value: filterInterval, setter: setFilterInterval },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    // Caso seja removido alguma configuração, limpa as lojas selecionadas.
    useEffect(() => {
        const currentLengthConfig = filterConfigPedido.value.length;

        if (currentLengthConfig < lastLengthConfig) {
            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
        }

        setLastLengthConfig(currentLengthConfig);
    }, [filterConfigPedido]);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={hasPermissionInstitucional ? 2.5 : 3}>
                <ConfiguracaoPedidoAutoComplete
                    multiple={true}
                    value={filterConfigPedido.value}
                    onChange={(e, value) => setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        filter: [
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                            { property: 'fgAtivo', operator: 'eq', value: true },
                            { property: 'dtInicioVigencia', operator: 'lte', value: data },
                            { property: 'dtFimVigencia', operator: 'gte', value: data },
                        ],
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        limit: 9999,
                    }}
                />
            </Grid>

            <Grid item lg={hasPermissionInstitucional ? 4 : 3}>
                <StoreAutoComplete
                    multiple
                    flagInstitucional
                    defaultStore={false}
                    requestOptions={
                        filterConfigPedido.value.length
                            ? {
                                  url: '/gestao/loja/findbyidsconfigpedido',
                                  filter: [
                                      { property: 'idConfigPedido', operator: 'in', value: filterConfigPedido.value.map((config: any) => config.idConfigPedido) },
                                      { property: 'fgAtivo', value: true, operator: 'eq' },
                                  ],
                                  columns: 'idLoja,dsNomeFantasia,cdLoja,idTipoLoja',
                              }
                            : null
                    }
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item lg={hasPermissionInstitucional ? 2.5 : 3}>
                <SituacaoPedidoLoteAutoComplete value={filterSituacao.value} onChange={(e, value) => setFilterSituacao((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item lg={3}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Início', end: 'Data Fim' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>
        </Filter>
    );
});
