import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import {
    ConfiguracaoPedidoAutoComplete,
    StoreAutoComplete,
    SituacaoPedidoTotalizadorAutoComplete,
    ConfiguracaoPedidoTotalizadorAutoComplete,
} from 'components/autocompletes';
import { ConfigPedidoTotalizador } from 'components/autocompletes/ConfiguracaoPedidoTotalizadorAutoComplete';
import { SituacaoPedidoRealizadoAutoComplete } from 'components/autocompletes/SituacaoPedidoRealizadoAutoComplete';
import { Filter } from 'components/page';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface ConfigPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
}

export function PedidoCompraTotalizadorGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { isDownLg: isMobile } = useBreakpoints();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'in',
        value: [],
        idProperty: 'idLoja',
    });

    const [filterStatusQuebra, setFilterStatusQuebra] = useState<FilterValuesProps>({
        property: 'idStatusPedidoTotalizador',
        operator: 'in',
        value: [],
        idProperty: 'idStatusPedidoTotalizador',
    });

    const [filterConfigPedidoTotalizadora, setFilterConfigPedidoTotalizadora] = useState<FilterValuesProps>({
        property: 'idConfigPedidoOrigem',
        operator: 'in',
        value: [],
        idProperty: 'configPedido.idConfigPedido',
        required: 'Configuração de Pedido Totalizador é obrigatório',
    });

    const [filterConfigPedido, setFilterConfigPedido] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        operator: 'in',
        value: [],
        idProperty: 'idConfigPedido',
    });
    const [filterStatusPedido, setFilterStatusPedido] = useState<FilterValuesProps>({
        property: 'idPedidoStatusQuebra',
        operator: 'eq',
        value: null,
        idProperty: 'idPedidoStatus',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterStatusQuebra, setter: setFilterStatusQuebra },
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterConfigPedidoTotalizadora, setter: setFilterConfigPedidoTotalizadora },
        { value: filterStatusPedido, setter: setFilterStatusPedido },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    // Retorna o filtro usado no autocomplete de COnfiguração de Pedido.
    const getFilterConfiguracaoPedido = useCallback(
        (totalizador = false): FilterValuesProps[] => {
            const filterConfiguracaoPedido: FilterValuesProps[] = [{ property: 'fgAtivo', operator: 'eq', value: true }];

            if (totalizador) {
                const [configPedido] = filterConfigPedidoTotalizadora.value;

                filterConfiguracaoPedido.push({ property: 'idTipoPedido', operator: 'eq', value: 4 });

                if (configPedido && !configPedido.fgSincronizado) {
                    filterConfiguracaoPedido.push({ property: 'fgSincronizado', operator: 'eq', value: false });
                }
            } else if (filterConfigPedidoTotalizadora.value.length) {
                filterConfiguracaoPedido.push({
                    property: 'idConfigPedidoOrigem',
                    operator: 'in',
                    value: filterConfigPedidoTotalizadora.value.map(
                        (configTotalizadora: ConfigPedidoTotalizador) => configTotalizadora.configPedido.idConfigPedido,
                    ),
                });
            }

            return filterConfiguracaoPedido;
        },
        [filterConfigPedidoTotalizadora.value],
    );

    const getRequestOptionsLoja = useMemo(() => {
        const requestOptions: RequestOptionsType = {
            url: '/gestao/loja/findbyidsconfigpedido',
            columns: 'idLoja,dsNomeFantasia,cdLoja',
        };

        let value;

        if (filterConfigPedidoTotalizadora.value.length && !filterConfigPedido.value.length) {
            value = filterConfigPedidoTotalizadora.value.map(
                (configTotalizadora: ConfigPedidoTotalizador) => configTotalizadora.configPedido.idConfigPedido,
            );
        } else if (filterConfigPedido.value.length) {
            value = filterConfigPedido.value.map((configPedido: ConfigPedidoProps) => configPedido.idConfigPedido);
        }

        if (value) {
            requestOptions.filter = [
                { property: 'idConfigPedido', operator: 'in', value },
                { property: 'fgAtivo', value: true, operator: 'eq' },
            ];
        }

        return value ? requestOptions : null;
    }, [filterConfigPedido.value, filterConfigPedidoTotalizadora.value]);

    const onChangeConfigPedidoTotalizador = useCallback(
        (e: unknown, value: ConfigPedidoTotalizador[]) => {
            const configPedidoTotalizadorList: ConfigPedidoTotalizador[] = [];

            // Valida se a config já existe na lista
            const isConfigIncluded = (idConfigPedido: number): boolean =>
                configPedidoTotalizadorList.some((item) => item.configPedido.idConfigPedido === idConfigPedido);

            // Caso a config não esteja na lista, adiciona a config passada no parâmetro
            const addConfigToList = (configPedidoTotalizador: ConfigPedidoTotalizador): void => {
                if (!isConfigIncluded(configPedidoTotalizador.configPedido.idConfigPedido)) {
                    configPedidoTotalizadorList.push(configPedidoTotalizador);
                }
            };

            // Tenta inserir a config principal na lista e percorre por suas configs relacionadas e tenta inserir na lista também
            const addAllConfigToList = (configPedidoTotalizador: ConfigPedidoTotalizador): void => {
                const { configPedido, configPedidoRelacionada, fgSincronizado } = configPedidoTotalizador;

                addConfigToList(configPedidoTotalizador);

                configPedidoRelacionada.forEach((configPedidoItem) => {
                    addConfigToList({
                        configPedido: configPedidoItem,
                        configPedidoRelacionada: [configPedido],
                        fgSincronizado,
                    });
                });
            };

            if (value.length < filterConfigPedidoTotalizadora.value.length) {
                setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));

                value.forEach((item) => {
                    const { configPedidoRelacionada } = item;
                    // Valida se todas as configs relacionadas estão também selecionadas
                    const valueHasConfigRelacionada = configPedidoRelacionada.every((configRelacionada) =>
                        value.some((item) => item.configPedido.idConfigPedido === configRelacionada.idConfigPedido),
                    );

                    // Executa caso a config não possua relacionamentos ou caso todas permanecem selecionados em value
                    if ((configPedidoRelacionada.length && valueHasConfigRelacionada) || !configPedidoRelacionada.length) {
                        addAllConfigToList(item);
                    }
                });
            } else {
                value.forEach(addAllConfigToList);
            }

            // Ao selecionar pela primeira vez, mostra o alerta
            if (configPedidoTotalizadorList.length && !filterConfigPedidoTotalizadora.value.length) {
                const [{ fgSincronizado }] = configPedidoTotalizadorList;

                const message = fgSincronizado
                    ? 'Selecionado configuração já processada. Não será possível selecionar outras configurações.'
                    : 'Selecionado configuração não processada. Apenas será possível selecionar configurações não processadas.';

                enqueueSnackbar(message, { variant: 'info' });
            }

            setFilterConfigPedidoTotalizadora((oldState: FilterValuesProps) => ({ ...oldState, value: configPedidoTotalizadorList }));
        },
        [filterConfigPedidoTotalizadora.value.length],
    );

    return (
        <Filter reset={resetFilter} submit={startFilter} fixed={isMobile}>
            <Grid item lg={6}>
                <ConfiguracaoPedidoTotalizadorAutoComplete
                    requestOptions={{
                        url: '/gestao/configpedido/totalizador',
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        columns: 'idConfigPedido,dsConfigPedido,dtInicioVigencia,dtFimVigencia',
                        filter: getFilterConfiguracaoPedido(true),
                        limit: 500,
                    }}
                    getOptionDisabled={() => {
                        if (filterConfigPedidoTotalizadora.value.length) {
                            const [configPedido] = filterConfigPedidoTotalizadora.value;
                            const fgSincronizado = configPedido.fgSincronizado;

                            return Boolean(fgSincronizado);
                        }

                        return false;
                    }}
                    value={filterConfigPedidoTotalizadora.value}
                    onChange={onChangeConfigPedidoTotalizador}
                />
            </Grid>

            <Grid item lg={6}>
                <ConfiguracaoPedidoAutoComplete
                    multiple
                    requestOptions={
                        filterConfigPedidoTotalizadora.value.length
                            ? {
                                  url: '/gestao/configpedido/loja',
                                  sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                                  columns: 'idConfigPedido,dsConfigPedido',
                                  filter: getFilterConfiguracaoPedido(),
                                  limit: 500,
                              }
                            : null
                    }
                    value={filterConfigPedido.value}
                    onChange={(e, value) => {
                        setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value }));

                        if (value.length) {
                            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                        }
                    }}
                />
            </Grid>

            <Grid item lg={4}>
                <SituacaoPedidoRealizadoAutoComplete
                    value={filterStatusPedido.value}
                    onChange={(e, value) => setFilterStatusPedido((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item lg={4}>
                <SituacaoPedidoTotalizadorAutoComplete
                    value={filterStatusQuebra.value}
                    onChange={(e, value) => setFilterStatusQuebra((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item lg={4}>
                <StoreAutoComplete
                    multiple
                    defaultStore={false}
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    requestOptions={getRequestOptionsLoja}
                />
            </Grid>
        </Filter>
    );
}
