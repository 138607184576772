import React from 'react';

import { Typography, Box, BoxProps } from '@mui/material';

interface SubTitleProps extends BoxProps {
    label: string | JSX.Element;
    id?: string;
    fullWidth?: boolean;
}

/**
 * Componente padrão para subtítulo dentro de bloco
 * @param title subtitulo
 */
export function SubTitle({ label, sx, id, fullWidth = false }: SubTitleProps): JSX.Element {
    return (
        <Box
            id={id}
            sx={{
                borderBottom: 2,
                borderColor: 'grey.200',
                pb: 0.4,
                mb: 2,
                width: {
                    lg: fullWidth ? '100%' : '50%',
                },
                ...sx,
            }}
        >
            <Typography variant="subtitle1">{label}</Typography>
        </Box>
    );
}
