import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useTituloContext } from 'context/TituloContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { TituloListBoletoBtn, TituloListStatusColumn } from 'pages/financeiro/titulo/components/';
import { TituloListFppBtn } from 'pages/financeiro/titulo/components/atoms/TituloListActionPrint';
import { RequestTituloStoreProps, TituloModelProps } from 'pages/financeiro/titulo/types';
import { brlDate, brlPrice } from 'util/format';

const getRows = (list: TituloModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(list) && list.length) {
        return list.map((item, index) => ({
            id: index + 1,
            nrFpp: item.nrFpp,
            nrParcela: item.nrParcela,
            nrTitulo: item.nrTitulo,
            nrNotaFiscal: item.nrNotaFiscal,
            dtEmissao: item.dtEmissao && `${item.dtEmissao}T00:00:00`,
            dtPagamento: item.dtPagamento && brlDate(`${item.dtPagamento}T00:00:00`),
            dtVencimento: item.dtVencimento && brlDate(`${item.dtVencimento}T00:00:00`),
            dtCompensacao: item.dtCompensacao && brlDate(`${item.dtCompensacao}T00:00:00`),
            dtVencimentoFormatada: item.dtVencimentoFormatada,
            tpStatus: item.tpStatus,
            tpTitulo: item.origemTitulo.dsOrigemTitulo,
            fgImpresso: item.fgImpresso,
            vlTitulo: item.vlTitulo,
            hasBoleto: Boolean(item.boleto),
        }));
    }

    return [];
};

export function TituloGrid(): JSX.Element {
    const { filter, autoLoad, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();
    const { setHasTitulos } = useTituloContext();

    const requestOptions: RequestOptionsType = {
        sort,
        filter,
        limit,
        nested: true,
        url: '/financeiro/titulo',
        columns: 'nrNotaFiscal,nrTitulo,dtEmissao,dtVencimentoFormatada,vlTitulo,fgImpresso',
    };

    const { isFetching, data, refetch } = useQuery<RequestTituloStoreProps>(
        ['getTitulos', requestOptions],
        () => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: autoLoad,
            onSuccess: (response) => setHasTitulos(Boolean(response.total)),
            onError: () => setHasTitulos(false),
        },
    );

    const columns: GridColDef[] = useMemo(() => {
        const objColumns: GridColDef[] = [
            { field: 'nrNotaFiscal', headerName: 'Nota Fiscal', minWidth: 160 },
            {
                field: 'nrTitulo',
                headerName: 'N° do Título/Parcela',
                minWidth: 180,
                renderCell: ({ row }) => `${row.nrTitulo}/${row.nrParcela}`,
            },
            { field: 'dtEmissao', headerName: 'Emissão', width: 100, minWidth: 90, valueFormatter: ({ value }) => value && brlDate(value) },
            { field: 'dtVencimento', headerName: 'Vencimento', minWidth: 90 },
            // { field: 'dtPagamento', headerName: 'Data Pagamento', minWidth: 90 }, // api está com erro
            { field: 'dtCompensacao', headerName: 'Data Compensação', minWidth: 90 },
            { field: 'tpTitulo', headerName: 'Tipo de Título', minWidth: 140 },
            { field: 'vlTitulo', headerName: 'Valor', type: 'number', flex: 1, minWidth: 90, valueFormatter: ({ value }) => brlPrice(value) },
            {
                field: 'tpStatus',
                headerName: 'Status',
                headerAlign: 'center',
                minWidth: 140,
                renderCell: ({ row }) => <TituloListStatusColumn status={row.tpStatus} />,
            },
        ];

        if (hasPermission(['FINANCEIRO_TITULO_BOLETO_GERAR'])) {
            objColumns.push(
                {
                    field: 'dsBoleto',
                    align: 'center',
                    headerAlign: 'center',
                    headerName: 'Boleto',
                    filterable: false,
                    sortable: false,
                    hideable: false,
                    disableColumnMenu: true,
                    minWidth: 110,
                    renderCell: (params) => <TituloListBoletoBtn params={params} />,
                },
                {
                    field: 'dsImrpimir',
                    align: 'center',
                    headerAlign: 'center',
                    headerName: 'Nota de Débito',
                    filterable: false,
                    sortable: false,
                    hideable: false,
                    disableColumnMenu: true,
                    minWidth: 110,
                    renderCell: (params) => <TituloListFppBtn params={params} />,
                },
            );
        }

        return objColumns;
    }, []);

    return (
        <Block>
            <PanelGrid
                rows={getRows(data?.data)}
                rowsCount={data?.total ?? 0}
                reload={refetch}
                loading={isFetching}
                columns={columns}
                remote={false}
                summary={['vlTitulo']}
            />
        </Block>
    );
}
