import { useEffect, useState } from 'react';

import { ThemeOptions } from '@mui/material';

export type Configuration = {
    tipoPedido: 'padrao' | 'funcionario';
    tipoAuth: 'oicd' | 'cissLiveAuth';
    fgAlteraEmpresa: boolean;
    fgExibeHelpDesk: boolean;
    fgDownloadRelatorioReport: boolean;
};

interface footerLinksProps {
    dsTitle: string;
    dsLink: string;
    dsIcon?: string;
}

interface footerBlocksProps {
    dsTitle: string;
    links: footerLinksProps[];
}

export interface DataHostConfigProps {
    documentTitle: string;
    theme: ThemeOptions;
    footer: footerBlocksProps[];
    favicon: string[];
    logo: string;
    mainGradient: string;
    googleAnalyticsTrackingId: string;
    configuration: Configuration;
}

interface UseHostConfigProps {
    data: DataHostConfigProps;
    defaultDomain: boolean;
}

interface ParamsUseHostConfigProps {
    subdomain: string;
    defaultSubDomain: string;
}

export const useHostConfig = ({ subdomain, defaultSubDomain }: ParamsUseHostConfigProps): UseHostConfigProps => {
    const [data, setData] = useState<DataHostConfigProps>({} as DataHostConfigProps);
    const [defaultDomain, setDefaultDomain] = useState(false);

    useEffect(() => {
        let retry = true;

        const fetchData = async (value: string): Promise<void> => {
            try {
                const response = await fetch(`${process.env.PUBLIC_URL}/config/${value}/config.json`);
                const data = await response.json();

                setData(data);
            } catch (error) {
                if (error instanceof Error) {
                    const invalidJSON = error.message.includes('is not valid JSON');

                    if (invalidJSON && retry && defaultSubDomain) {
                        retry = false;
                        fetchData(defaultSubDomain);
                        setDefaultDomain(true);
                    } else {
                        throw new Error(error.message);
                    }
                } else {
                    throw new Error(error as string);
                }
            }
        };

        fetchData(subdomain);
    }, [defaultSubDomain, subdomain]);

    return { data, defaultDomain };
};
