import React, { useCallback, useMemo } from 'react';

import { Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { LoadingButton } from '@mui/lab';

import { StateNotaDevolucao } from 'pages/financeiro/nota-devolucao/types';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';
import { useListagemContext } from 'context/ListagemContext';

interface NotaDevolucaoListActionPrintProps {
    row: GridRowModel<any>;
    hidden: boolean;
}

export function NotaDevolucaoListActionPrint({ row, hidden }: NotaDevolucaoListActionPrintProps): JSX.Element {
    const { filter } = useListagemContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/fiscal/movimentacaosimulacaodevolucao/espelhomovimentacaosimulacaodevolucao',
            sort: [{ property: 'idMovimentacaoSimulacaoDevolucao', direction: 'DESC' }],
            filter: [
                ...filter,
                {
                    property: 'nrOrdemVenda',
                    operator: 'eq',
                    value: row.nrOrdemVenda,
                    logicalOperator: 'AND',
                },
            ],
        }),
        [filter, row.nrOrdemVenda],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClick = useCallback((): void => {
        downloadFile();
    }, [downloadFile]);

    return (
        <Tooltip title={row.tpStatus !== StateNotaDevolucao.CANCELADA ? 'Imprimir Espelho da Nota' : ''} hidden={hidden}>
            <span>
                <LoadingButton sx={{ p: 0, minWidth: 'auto' }} disabled={row.tpStatus === StateNotaDevolucao.CANCELADA} onClick={handleClick} loading={isLoadingFile}>
                    <PrintOutlinedIcon />
                </LoadingButton>
            </span>
        </Tooltip>
    );
}
