import React from 'react';

import { useGenericAuth } from 'hooks/useGenericAuth';

type PrivaRouteType = {
    component: JSX.Element;
};

/**
 * Método para definir quais rotas são privadas
 * @param component:JSX.Element
 * @returns Retorna o próprio componente ou redireciona para o login
 */
export function PrivateRoute({ component }: PrivaRouteType): JSX.Element | null {
    const { isAuthenticated } = useGenericAuth();

    if (!isAuthenticated) {
        return null;
    }

    return component;
}
