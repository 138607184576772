import { useEffect, useState } from 'react';

interface useImageProps {
    loading: boolean;
    error: any;
    image: any;
}

export const useImage = (file?: string): useImageProps => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | any>(null);
    const [image, setImage] = useState<null | any>(null);

    useEffect(() => {
        const fetchImage = async (url: string): Promise<void> => {
            try {
                const response = await fetch(url);
                const data = await response.blob();
                const validImg = data.type.includes('image/');

                if (validImg) {
                    setImage(URL.createObjectURL(data));
                } else {
                    setError('not image file');
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        if (file) {
            fetchImage(file);
        }
    }, [file]);

    return {
        loading,
        error,
        image,
    };
};
