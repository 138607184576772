import { useNavigate } from 'react-router-dom';

import { FormikHelpers } from 'formik';

import { Promocao } from '../types';

import { useFormMutation } from 'mutations/useFormMutation';

type UsePostPromocaoReturn = {
    handleSubmit: (formData: Promocao, formik: FormikHelpers<Promocao>) => void;
    isLoading: boolean;
};

export function usePostPromocao(): UsePostPromocaoReturn {
    const { mutate, isLoading } = useFormMutation();
    const navigate = useNavigate();

    const handleSubmit = (formData: Promocao, formik: FormikHelpers<Promocao>): void => {
        mutate(
            {
                url: '/integracao-revendedor/promocoes',
                formData,
                formik,
                preventRedirect: true,
            },
            {
                onSuccess: () => {
                    navigate('/revendedor/promocao');
                },
            },
        );
    };

    return { handleSubmit, isLoading };
}
