import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterNota(): JSX.Element {
    const { formattedLoja } = useAplicacaoDataContext();
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'in',
        value: [],
        idProperty: 'cdLoja',
        required: 'Loja é obrigatória',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhMovimentacao',
        operator: 'bt',
        startDate: null,
        endDate: null,
        required: 'Intervalo de movimentação é obrigatório',
    });

    const [filterNumeroNota, setFilterNumeroNota] = useState<FilterValuesProps>({
        property: 'nrNota',
        operator: 'like',
        value: '',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterInterval, setter: setFilterInterval },
        { value: filterNumeroNota, setter: setFilterNumeroNota },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });

        setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: formattedLoja }));
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={2}>
                <StoreAutoComplete value={filterLoja.value} multiple={true} onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={2}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Início', end: 'Data Fim' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="N° da nota" value={filterNumeroNota.value} onChange={(e) => setFilterNumeroNota((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>
        </Filter>
    );
}
