import React, { useState } from 'react';

import { OneColumn } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { ProdutoGridFilter, ProdutoGrid, ProdutoRelatorio, ProdutoImportarModal } from 'pages/compra/produto/components';

export function ProdutoListComp(): JSX.Element {
    const [openImportarModal, setOpenImportarModal] = useState<boolean>(false);
    const { hasPermission } = usePermissionsContext();

    return (
        <React.Fragment>
            <ProdutoImportarModal open={openImportarModal} setOpen={setOpenImportarModal} />

            <OneColumn
                title="Consulta de Produtos"
                goBackButton
                extraButtons={[
                    {
                        title: 'Importar Imagens',
                        onClick: () => setOpenImportarModal(true),
                        visible: hasPermission(['COMPRA_PRODUTO_ALTERAR']),
                    },
                ]}
            >
                <ProdutoGridFilter />

                <ProdutoRelatorio />

                <ProdutoGrid />
            </OneColumn>
        </React.Fragment>
    );
}
