import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { OneColumn } from 'components/page';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { StatusIntegracaoIndicator, StatusIntegracaoGrid } from 'pages/compra/pedido-totalizador/status-integracao/components';
import { RequestConfigPedido } from 'pages/compra/pedido-totalizador/status-integracao/types';

export function StatusIntegracaoListComp(): JSX.Element {
    const [searchParams] = useSearchParams();
    const configPedidoOrigemList = searchParams.get('configPedidoOrigemList');

    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/configpedido',
        filter: [{ property: 'idConfigPedido', operator: 'in', value: configPedidoOrigemList && JSON.parse(configPedidoOrigemList) }],
        sort: [{ property: 'dsConfigPedido', direction: 'ASC' }],
        columns: 'idConfigPedido,dsConfigPedido',
    };
    const { data } = useQuery([requestOptions], (): Promise<RequestConfigPedido> => RequestListagem(requestOptions).then((res) => res.data), { enabled: Boolean(configPedidoOrigemList) });

    return (
        <OneColumn title={`Status da Integração - ${data ? data?.data.map((item) => `${item.idConfigPedido} - ${item.dsConfigPedido}`).join(', ') : ''}`} goBackButton>
            <StatusIntegracaoIndicator />

            <StatusIntegracaoGrid />
        </OneColumn>
    );
}
