import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface MarketingTag {
    idTagPecaMarketing?: number;
    dsTagPecaMarketing: string;
}
export const initialTagValues: MarketingTag = {
    dsTagPecaMarketing: '',
};

export interface RequestMarketingTag extends RequestListagemProps {
    data: MarketingTag[];
}
