import React from 'react';

import { Typography, Divider } from '@mui/material';

import { Block, OneColumn } from 'components/page';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useGetSaldoFuncionario } from 'pages/inicio/hooks';
import { brlPrice } from 'util/format';

export function InicioUsuarioInfo(): JSX.Element {
    const { tokenInfo } = useGenericAuth();
    const { isDownLg, isDownSm } = useBreakpoints();
    const { data } = useGetSaldoFuncionario();

    return (
        <OneColumn title={`Bem vindo, ${tokenInfo?.dsUsuario}`}>
            <Block sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: { xs: 'column', lg: 'row' }, gap: 2 }}>
                <Typography sx={{ fontSize: '1.5rem', span: { color: (theme) => theme.palette.secondary.main } }}>
                    Saldo atual: <span>{brlPrice(data?.vlSaldoAtual || 0)}</span>
                </Typography>

                <Divider orientation={isDownLg ? 'horizontal' : 'vertical'} variant="middle" flexItem />

                <img src="/config/lojinha/logo-crm.png" width={isDownSm ? '100%' : 350} height={isDownSm ? undefined : 126} />
            </Block>
        </OneColumn>
    );
}
