import { AzureUploadFileProps } from 'components/azureUpload/types';
import { UploadFileProps } from 'components/upload/types';
import { RequestListagemProps } from 'hooks/useRequestListagem';

export enum TypeMarketing {
    MARKETING = 1,
    TRADE = 2,
}

export const getTypeMarketing = (isMarketing: boolean): number => (isMarketing ? TypeMarketing.MARKETING : TypeMarketing.TRADE);

export interface MarketingTradePage {
    isMarketing?: boolean;
}

interface PecaMarketingTradeLojaProps {
    idLoja: number;
    dsNomeFantasia: string;
    dsRazaoSocial: string;
}

interface PecaMarketingTradeEstadoProps {
    idEstado: number;
    dsEstado: string;
}
interface PecaMarketingTradeCidadeProps {
    idCidade: number;
    dsCidade: string;
}

interface PecaMarketingTradeTipoLojaProps {
    idLoja: number;
    dsLoja: string;
}
interface PecaMarketingTradeLojaPerfilProps {
    idLoja: number;
    dsLoja: string;
}
export interface Atributo {
    idPecaMarketingArquivoAtributoEdicao: number;
    dsJsonAtributoEdicao: string;
    fgExcluido: boolean;
    nrSequencia: number;
}

export interface TradeTag {
    idTagPecaTrade: number;
    dsTagPecaTrade: string;
}

export interface MarketingTag {
    idTagPecaMarketing: number;
    dsTagPecaMarketing: string;
}

export interface MarketingTradeFilesProps extends UploadFileProps {
    idPecaMarketingArquivo?: number;
}

export interface MarketingTradeEditableFilesProps extends AzureUploadFileProps {
    idPecaMarketingArquivo?: number;
    atributos?: Atributo[];
}

interface MarketingTradeCategoryProps {
    dsCampanhaMarketing: string;
}
interface MarketingTradeFormatoProps {
    dsPecaMarketingFormato: string | null;
    idPecaMarketingFormato: number | null;
}

export interface MarketingTradeProps {
    idPecaMarketing: number;
    dsPecaMarketing: string;
    dsConteudo: string;
    dsPapel: string;
    dsGramatura: string;
    dsFormatoAberto: string;
    dsFormatoFechado: string;
    dsCores: string;
    qtVisualizacao?: number;
    qtCurtida: number;
    fgUsuarioCurtiu: boolean;
    fgFrenteVerso: boolean;
    fgFaca: boolean;
    fgEditavel: boolean;
    fgAtivo: boolean;
    campanhaMarketing: MarketingTradeCategoryProps | null;
    pecaMarketingFormato: MarketingTradeFormatoProps | null;
    pecaTradeTagsPecaTrade?: TradeTag[];
    pecaMarketingTagsPecaMarketing?: MarketingTag[];
    imagensFaca?: MarketingTradeFilesProps[];
    imagensMockup?: MarketingTradeFilesProps[];
    imagensThumb?: MarketingTradeFilesProps[];
    arquivosDownload?: MarketingTradeEditableFilesProps[];
    dtInicioExibicao?: string | null;
    dtFimExibicao?: string | null;
    dsTipoLamina: string;
    dsAcabamento: string;
    nrPagina: number | string;
    dhInclusao: string | null;
    tipoMarketing: {
        idTipoMarketing: number;
    };
    pecaMarketingLojas: PecaMarketingTradeLojaProps[] | null;
    pecaMarketingTiposLojas: PecaMarketingTradeTipoLojaProps[] | null;
    pecaMarketingLojaPerfis: PecaMarketingTradeLojaPerfilProps[] | null;
    pecaMarketingCidades: PecaMarketingTradeCidadeProps[] | null;
    pecaMarketingEstados: PecaMarketingTradeEstadoProps[] | null;
    tipoDisponibilizacao: 'lojas' | 'cidades' | 'estados' | null;
}

export interface RequestCampanhaMarketingStoreProps extends Partial<RequestListagemProps> {
    status: number;
    data: {
        data: MarketingTradeProps;
    };
}

export const initialMarketingValues = (isMarketing: boolean): Partial<MarketingTradeProps> => ({
    idPecaMarketing: -1,
    dsPecaMarketing: '',
    arquivosDownload: [],
    imagensThumb: [],
    imagensMockup: [],
    imagensFaca: [],
    pecaMarketingFormato: null,
    campanhaMarketing: null,
    dsConteudo: '',
    dsTipoLamina: '',
    dsPapel: '',
    dsGramatura: '',
    dsFormatoAberto: '',
    dsFormatoFechado: '',
    dsCores: '',
    dsAcabamento: '',
    nrPagina: '',
    fgFaca: false,
    fgFrenteVerso: false,
    fgEditavel: false,
    fgAtivo: true,
    pecaTradeTagsPecaTrade: [],
    pecaMarketingTagsPecaMarketing: [],
    tipoMarketing: {
        idTipoMarketing: getTypeMarketing(isMarketing),
    },
    pecaMarketingLojas: [],
    pecaMarketingTiposLojas: [],
    pecaMarketingLojaPerfis: [],
    pecaMarketingCidades: [],
    pecaMarketingEstados: [],
    tipoDisponibilizacao: 'lojas',
});

export interface GestaoCampanhaMarketingAninhadaResponse {
    dsCampanhaMarketing: string;
    idCampanhaMarketing: number;
    idsFilhos: number[];
    qtPecas: number;
    subCampanhasMarketing: GestaoCampanhaMarketingAninhadaResponse[];
    campanhaMarketingPai?: { idCampanhaMarketing: number };
}

export interface Font {
    size: number;
    family: string;
    color: string;
    align: string;
    bold: boolean;
    italic: boolean;
}

export interface Field {
    id: number;
    name: string;
    value: string;
    valueLimit: number | null;
    htmlValue: string;
    font: Font;
    x: number;
    y: number;
    rotation: number;
    width: number;
}

export interface KonvaTextProps {
    text: string;
    font: Font;
    width: number;
    x: number;
    y: number;
    rotation: number;
    isSelected: boolean;
    draggable: boolean;
    onDblClick?: () => void;
    onDragEnd?: (event: any) => void;
    onTransformEnd?: (event: any) => void;
}

export interface Arquivo {
    dsLink: string;
}

export interface ArquivoDownload {
    arquivo: Arquivo;
    atributos: Atributo[];
    idPecaMarketingArquivo: number;
}

export interface PainelEdicaoPecasMarketingTradeProps {
    arquivoDownload: ArquivoDownload;
    setArquivosDownload: React.Dispatch<React.SetStateAction<ArquivoDownload[]>>;
    formatoImagem: MarketingTradeFormatoProps;
}

export interface PainelPecasMarketingTradeProps {
    arquivoDownload: MarketingTradeEditableFilesProps;
    formatoImagem: MarketingTradeFormatoProps | null;
}

export interface PecaMarketingTrade {
    pecaMarketingArquivo: {
        idPecaMarketingArquivo: number;
        arquivo: Arquivo;
    };
    atributos: Atributo[];
}

export interface CampanhaModelProps {
    idCampanhaMarketing: number;
    dsCampanhaMarketing: string;
    subCampanhasMarketing?: CampanhaModelProps[];
    fgPermanente: boolean;
    dtInicioExibicao: string | null;
    dtFimExibicao: string | null;
    fgAtivo: boolean;
}

export interface RequestCampanhaListModelProps {
    data: CampanhaModelProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestCampanhaModelProps {
    idCampanhaMarketing: number;
    dsCampanhaMarketing: string;
    campanhaMarketingPai: any;
    dtInicioExibicao: string | null;
    dtFimExibicao: string | null;
    fgPermanente: boolean;
    fgAtivo: boolean;
    tipoMarketing: {
        idTipoMarketing: number;
    };
}

export const initialCampanhaFormValues = (isMarketing: boolean): RequestCampanhaModelProps => ({
    idCampanhaMarketing: -1,
    dsCampanhaMarketing: '',
    campanhaMarketingPai: null,
    dtInicioExibicao: null,
    dtFimExibicao: null,
    fgPermanente: false,
    fgAtivo: true,
    tipoMarketing: {
        idTipoMarketing: getTypeMarketing(isMarketing),
    },
});

export type ExportFileType = 'png' | 'pdf';

export interface ExportTypeModalProps {
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    valueStage?: any;
    valueToNormalize: number;
    formatoImagem: MarketingTradeFormatoProps | null;
}
