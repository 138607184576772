import React, { useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton } from '@mui/material';
import { ArrayHelpers, getIn, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';

import { acceptedFilesExtension, initialSolicitacaoPecaItensValues, ItensProps, ListItemEnderecoProps, SolicitacaoPecaProps } from '../../types';

import { UnidadeMedidaAutoComplete } from 'components/autocompletes';
import { CissNumberField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { ConfirmModal } from 'components/modal';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { Upload } from 'components/upload';
import { brlCep, brlPhone } from 'util/format';
import { AzureUpload } from 'components/azureUpload';

interface SolicitacaoItemFormProps extends ArrayHelpers {
    form: FormikProps<SolicitacaoPecaProps>;
}

export function TradeSolicitacaoItemForm({ push, remove, form: formik }: SolicitacaoItemFormProps): JSX.Element {
    const [listOpenConfirmRemoveModal, setListOpenConfirmRemoveModal] = useState<ListItemEnderecoProps>({});
    const [listItemEndereco, setListItemEndereco] = useState<ListItemEnderecoProps>({});
    const { enqueueSnackbar } = useSnackbar();

    const itens = formik.values.itens;

    const handleAddItem = (): void => {
        if (itens.length < 10) {
            const newId = (itens.length + 1) * -1;

            push({ ...initialSolicitacaoPecaItensValues, idSolicitacaoPecaItem: newId });
        } else {
            enqueueSnackbar('Limite de 10 peças por solicitação atingida.', { variant: 'warning' });
        }
    };

    const handleChangeListarEndereco = (checked: boolean, index: number, id: number): void => {
        const newList = structuredClone(listItemEndereco);
        const lojaValues = formik.values.loja;
        const phone = checked && lojaValues ? brlPhone(lojaValues.dsFone) : '';
        const adress = checked && lojaValues ? [lojaValues.dsEndereco, lojaValues.dsBairro, `${lojaValues.cep.dsCidade} - ${lojaValues.cep.sgEstado}`, `CEP: ${brlCep(lojaValues.cep.nrCep)}`] : [];

        newList[`item_${id}`] = checked;

        formik.setFieldValue(`itens[${index}].dsEnderecoLoja`, adress.join(', '));
        formik.setFieldValue(`itens[${index}].dsTelefoneLoja`, phone);

        setListItemEndereco(newList);
    };

    const handleConfirmRemoverItem = (id: number, status = true): void => {
        const newList = structuredClone(listOpenConfirmRemoveModal);

        newList[`item_${id}`] = status;

        if (itens.length > 1) {
            setListOpenConfirmRemoveModal(newList);
        } else {
            enqueueSnackbar('Não é possivel remover todas as peças', { variant: 'warning' });
        }
    };

    const handleRemoverItem = (index: number): void => {
        if (itens.length > 1) {
            remove(index);
        } else {
            enqueueSnackbar('Não é possivel remover todas as peças', { variant: 'warning' });
        }
    };

    return (
        <React.Fragment>
            {itens.map((item: ItensProps, index: number) => {
                const itensErrors: any = Array.isArray(formik.errors.itens) && formik.errors.itens.at(index);

                return (
                    <React.Fragment key={`item_${index}`}>
                        <Block>
                            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
                                <SubTitle label={`Dados Peça ${`0${index + 1}`.slice(-2)}`} fullWidth sx={{ flex: 1, mb: 0 }} />

                                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        onClick={() => handleConfirmRemoverItem(item.idSolicitacaoPecaItem)}
                                        sx={{
                                            p: 0,
                                            ml: 1,
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Tipo da Peça"
                                        name={`itens[${index}].dsTipoPeca`}
                                        value={formik.values.itens[index].dsTipoPeca}
                                        onChange={(e) => formik.setFieldValue(`itens[${index}].dsTipoPeca`, e.target.value)}
                                        error={itensErrors?.dsTipoPeca || (formik.errors as any)[`itens[${index}].dsTipoPeca`]}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <UnidadeMedidaAutoComplete
                                        name={`itens[${index}].unidadeMedidaPeca`}
                                        value={formik.values.itens[index].unidadeMedidaPeca}
                                        error={itensErrors?.unidadeMedidaPeca || (formik.errors as any)[`itens[${index}].unidadeMedidaPeca`]}
                                        onChange={(e, value) => formik.setFieldValue(`itens[${index}].unidadeMedidaPeca`, value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Largura"
                                        name={`itens[${index}].vlLargura`}
                                        preventEmptyField={false}
                                        value={formik.values.itens[index].vlLargura}
                                        onChange={(e) => formik.setFieldValue(`itens[${index}].vlLargura`, e.target.value)}
                                        error={itensErrors?.vlLargura || (formik.errors as any)[`itens[${index}].vlLargura`]}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Altura"
                                        name={`itens[${index}].vlAltura`}
                                        preventEmptyField={false}
                                        value={formik.values.itens[index].vlAltura}
                                        onChange={(e) => formik.setFieldValue(`itens[${index}].vlAltura`, e.target.value)}
                                        error={itensErrors?.vlAltura || (formik.errors as any)[`itens[${index}].vlAltura`]}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Local de veiculação"
                                        name={`itens[${index}].dsLocalVeiculacao`}
                                        value={formik.values.itens[index].dsLocalVeiculacao}
                                        onChange={(e) => formik.setFieldValue(`itens[${index}].dsLocalVeiculacao`, e.target.value)}
                                        error={itensErrors?.dsLocalVeiculacao || (formik.errors as any)[`itens[${index}].dsLocalVeiculacao`]}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Dados da empresa na peça?"
                                        value={listItemEndereco[`item_${item.idSolicitacaoPecaItem}`] ?? false}
                                        onChange={(e: any) => handleChangeListarEndereco(e.target.checked, index, item.idSolicitacaoPecaItem)}
                                        sx={{
                                            height: '54px',
                                        }}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    {listItemEndereco[`item_${item.idSolicitacaoPecaItem}`] && (
                                        <CissTextField
                                            label="Endereço da Loja"
                                            name={`itens[${index}].dsEnderecoLoja`}
                                            value={formik.values.itens[index].dsEnderecoLoja}
                                            onChange={(e) => formik.setFieldValue(`itens[${index}].dsEnderecoLoja`, e.target.value)}
                                            error={itensErrors?.dsEnderecoLoja || (formik.errors as any)[`itens[${index}].dsEnderecoLoja`]}
                                        />
                                    )}
                                </Grid>

                                <Grid item flex={1}>
                                    {listItemEndereco[`item_${item.idSolicitacaoPecaItem}`] && (
                                        <CissTextField
                                            label="Telefone da Loja"
                                            name={`itens[${index}].dsTelefoneLoja`}
                                            value={formik.values.itens[index].dsTelefoneLoja}
                                            onChange={(e) => formik.setFieldValue(`itens[${index}].dsTelefoneLoja`, e.target.value)}
                                            error={itensErrors?.dsTelefoneLoja || (formik.errors as any)[`itens[${index}].dsTelefoneLoja`]}
                                        />
                                    )}
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Observação"
                                        name={`itens[${index}].dsObservacao`}
                                        multiline
                                        rows={3}
                                        value={formik.values.itens[index].dsObservacao}
                                        onChange={(e) => formik.setFieldValue(`itens[${index}].dsObservacao`, e.target.value)}
                                        error={itensErrors?.dsObservacao || (formik.errors as any)[`itens[${index}].dsObservacao`]}
                                    />
                                </Grid>
                            </Row>

                            <Divider sx={{ my: 2 }} />

                            <AzureUpload
                                id={`itens[${index}].arquivos`}
                                files={formik.values.itens[index].arquivos || []}
                                onChange={(files) => {
                                    formik.setFieldValue(`itens[${index}].arquivos`, files);
                                }}
                            />
                        </Block>

                        <ConfirmModal
                            open={listOpenConfirmRemoveModal[`item_${item.idSolicitacaoPecaItem}`] || false}
                            setOpen={() => handleConfirmRemoverItem(item.idSolicitacaoPecaItem, false)}
                            confirm={() => handleRemoverItem(index)}
                        />
                    </React.Fragment>
                );
            })}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    px: 2,
                }}
            >
                <Button
                    variant="outlined"
                    onClick={handleAddItem}
                    disabled={formik.values.itens.length >= 10}
                    sx={{
                        ml: 'auto',
                    }}
                >
                    Adicionar peça
                </Button>
            </Box>
        </React.Fragment>
    );
}
