import React from 'react';
import { FontesListComp } from 'pages/fontes/pages/FontesList';
import { FontesFormComp } from 'pages/fontes/pages/FontesForm';
import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';

export function FontesList(): JSX.Element {
    return (
        <ListagemProvider>
            <FontesListComp />
        </ListagemProvider>
    );
}

export function FontesForm(): JSX.Element {
    return (
        <FormProvider>
            <FontesFormComp />
        </FormProvider>
    );
}
