interface ErrorMessagesObject {
    [key: string]: string;
}

export const AuthLoginErrorMessages: ErrorMessagesObject = {
    'lg400.credenciais.invalidas': 'LG400 - Usuário e/ou senha inválido(s)',
    'lg401.usuario.nao.autorizado': 'LG401 - Acesso não autorizado',
    'lg402.usuario.nao.autorizado': 'LG402 - Acesso não autorizado',
    'lg403.usuario.nao.autorizado': 'LG403 - Acesso não autorizado',
    'lg404.ambiente.nao.autorizado': 'LG404 - Acesso não autorizado',
    'lg405.credenciais.invalidas': 'LG405 - Usuário e/ou senha inválido(s)',
    'lg500.erro.conexao.ad': 'LG500 - Falha na comunicação',
    'lg503.erro.conexao.auth': 'LG503 - Falha na comunicação',
    'lg504.erro.conexao.auth': 'LG504 - Falha na comunicação',
    'lg505.erro.conexao.auth': 'LG505 - Falha na comunicação',
    'credenciais.invalidas': 'Usuário e/ou senha inválido(s)',
    'usuario.inativo': 'Usuário desabilitado',
};
