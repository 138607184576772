import React, { useState, useRef, ReactNode } from 'react';

import { Box, Menu } from '@mui/material';

import { MenuMainItem } from 'components/menu/main/components/MenuMainItem';
import { MenuItemProps } from 'components/menu/main/types';
import { usePermissionsContext } from 'context/PermissionsContext';

interface MenuMainNested {
    menuItem: MenuItemProps;
    children: ReactNode;
}

export function MenuMainNested({ menuItem, children }: MenuMainNested): JSX.Element | null {
    const { hasAnyPermission } = usePermissionsContext();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const { permissions } = menuItem;
    const menuItemRef = useRef(null);

    if (permissions && permissions.length > 0 && !hasAnyPermission(permissions)) {
        return null;
    }

    return (
        <Box onMouseEnter={() => setIsSubMenuOpen(true)} onMouseLeave={() => setIsSubMenuOpen(false)}>
            <MenuMainItem ref={menuItemRef} menuItem={menuItem} rightIcon />

            <Menu
                style={{ pointerEvents: 'none' }}
                anchorEl={menuItemRef.current}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isSubMenuOpen}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => setIsSubMenuOpen(false)}
            >
                <Box sx={{ pointerEvents: 'auto', py: 1, minWidth: 240 }}>{children}</Box>
            </Menu>
        </Box>
    );
}
