import React, { useId } from 'react';

import { Grid, SxProps, Typography } from '@mui/material';

import { useValidation } from 'hooks/useValidation';

interface TabuledGridListProps {
    label: string | JSX.Element;
    value?: string | number | JSX.Element | HTMLElement;
}

interface TabuledGridProps {
    list: TabuledGridListProps[];
    leftColumnWidth?: number | string;
    showEmpty?: boolean;
    sx?: SxProps;
}

export function TabuledGrid({ list, leftColumnWidth = 200, showEmpty = true, sx }: TabuledGridProps): JSX.Element {
    const id = useId();
    const { validValue } = useValidation();

    return (
        <Grid container spacing={1} direction="column" sx={sx}>
            {list.map(({ label, value }: any) => {
                let content = null;

                if (showEmpty || validValue(value)) {
                    content = (
                        <Grid item key={Math.random() + id} display="flex" sx={{ width: '100%' }}>
                            <Typography minWidth={leftColumnWidth}>{label}</Typography>

                            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</Typography>
                        </Grid>
                    );
                }

                return content;
            })}
        </Grid>
    );
}
