import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { GradeExclusaoComp, GradeListComp } from 'pages/logistica/grade/components';
import { GradeFormComp } from 'pages/logistica/grade/components/pages/GradeForm';
import { GradeImportacaoComp } from 'pages/logistica/grade/components/pages/GradeImportacao';

export function GradeList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['GRADE_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <GradeListComp />
        </ListagemProvider>
    );
}

export function GradeForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['GRADE_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <GradeFormComp />
        </FormProvider>
    );
}

export function GradeImportacao(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['GRADE_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <FormProvider>
                <GradeImportacaoComp />
            </FormProvider>
        </ListagemProvider>
    );
}

export function GradeExclusao(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['GRADE_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <GradeExclusaoComp />
        </ListagemProvider>
    );
}
