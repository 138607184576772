import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { FilterValuesProps, useFilter } from 'hooks/useFilter';

export function PreenchimentoListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const statusOptions = useMemo(
        () => [
            { dreStatus: 1, dsDreStatus: 'A preencher' },
            { dreStatus: 2, dsDreStatus: 'Em preenchimento' },
            { dreStatus: 3, dsDreStatus: 'Finalizado' },
            { dreStatus: 4, dsDreStatus: 'Bloqueado' },
        ],
        [],
    );

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'in',
        value: [],
        idProperty: 'idLoja',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dtInicio',
        operator: 'bt',
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
        required: 'Intervalo de tempo é obrigatório',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'dreStatus',
        operator: 'in',
        value: [statusOptions[0]],
        idProperty: 'dreStatus',
        required: 'Status é obrigatório',
    });

    const filters = useMemo(
        () => [
            { value: filterLoja, setter: setFilterLoja },
            { value: filterInterval, setter: setFilterInterval },
            { value: filterStatus, setter: setFilterStatus },
        ],
        [filterInterval, filterLoja, filterStatus],
    );

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <StoreAutoComplete multiple={true} value={filterLoja.value} onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data inicial', end: 'Data final' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item flex={1}>
                <CissAutoComplete
                    label="Status de preenchimento"
                    options={statusOptions}
                    value={filterStatus.value}
                    multiple={true}
                    selectedStringTemplate="{dsDreStatus}"
                    optionStringTemplate="{dsDreStatus}"
                    isOptionEqualToValue={(option, value) => value && option && option.dreStatus === value.dreStatus}
                    onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>
        </Filter>
    );
}
