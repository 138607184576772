import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { CategoriaComunicadoFormComp } from './components/pages/CategoriaForm';
import { CategoriaComunicadoListComp } from './components/pages/CategoriaList';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function CategoriaComunicadoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['COMUNICADO_CATEGORIA_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <CategoriaComunicadoListComp />
        </ListagemProvider>
    );
}

export function CategoriaComunicadoForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('COMUNICADO_CATEGORIA_GERENCIAR', id)) return alertNotPermission();

    return (
        <FormProvider>
            <CategoriaComunicadoFormComp />
        </FormProvider>
    );
}
