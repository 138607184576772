import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { MensagemPadraoFormComp, MensagemPadraoListComp } from 'pages/marketing-trade/trade/solicitacoes-pecas/mensagem-padrao/components';

export function MensagemPadraoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    
    if (!hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <MensagemPadraoListComp />
        </ListagemProvider>
    );
}

export function MensagemPadraoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['TRADE_SOLICITACAO_PECA_MODERAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <MensagemPadraoFormComp />
        </FormProvider>
    );
}
