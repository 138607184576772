import React, { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { Centered } from 'components/grid';

export function PedidoIframes({ title, src }: { title: string; src: string }): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [iframeHeight, setIframeHeight] = useState(110);

    return (
        <Block>
            <SubTitle label={title} />

            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    minHeight: iframeHeight,
                    overflow: 'hidden',
                    iframe: {
                        border: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    },
                }}
            >
                {loading && (
                    <Centered sx={{ my: 4 }}>
                        <CircularProgress />
                    </Centered>
                )}

                <iframe
                    id="iframeid"
                    title={title}
                    src={src}
                    frameBorder="0"
                    allowFullScreen
                    onLoad={() => {
                        const iframeHeight = document.getElementById('iframeid')?.ownerDocument.body.scrollHeight;

                        setLoading(false);
                        setIframeHeight(iframeHeight || 1500);
                    }}
                />
            </Box>
        </Block>
    );
}
