import React, { ReactNode } from 'react';

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Accordion, AccordionSummary, AccordionDetails, AccordionProps, alpha } from '@mui/material';
import { deepmerge } from '@mui/utils';

interface DefaultAccordionProps extends Omit<AccordionProps, 'children' | 'title'> {
    title: string | JSX.Element;
    children: ReactNode;
}

const defaultSx = {
    '&:first-of-type:before': {
        display: 'none',
    },
};

export function DefaultAccordion({ title, children, sx, ...props }: DefaultAccordionProps): JSX.Element {

    return (
        <Accordion disableGutters sx={{ ...deepmerge(defaultSx, sx) }} {...props}>
            <AccordionSummary
                expandIcon={<ArrowForwardIosOutlinedIcon sx={{ fontSize: '0.9rem' }} />}
                sx={{
                    'backgroundColor': (theme) => alpha(theme.palette.common.black, 0.03),
                    'flexDirection': 'row-reverse',
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                    '& .MuiAccordionSummary-content': {
                        marginLeft: 1,
                    },
                }}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    p: 2,
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
