import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

export type PeriodosHistorico = 'SEMANAL' | 'QUINZENAL' | 'MENSAL';

export interface PeriodoHistorico {
    idPeriodoHistorico: number;
    dsPeriodoHistorico: string;
    vlPeriodoHistorico: PeriodosHistorico;
}

export const PeriodoHistoricoOptions: PeriodoHistorico[] = [
    {
        idPeriodoHistorico: 1,
        dsPeriodoHistorico: 'Semanal',
        vlPeriodoHistorico: 'SEMANAL',
    },
    {
        idPeriodoHistorico: 2,
        dsPeriodoHistorico: 'Quinzenal',
        vlPeriodoHistorico: 'QUINZENAL',
    },
    {
        idPeriodoHistorico: 3,
        dsPeriodoHistorico: 'Mensal',
        vlPeriodoHistorico: 'MENSAL',
    },
];

type PeriodoHistoricoAutoComplete = Pick<CissAutoCompleteProps, 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label' | 'disableClearable'>;

export function PeriodoHistoricoAutoComplete({
    size,
    value,
    error,
    multiple = false,
    disabled = false,
    sx,
    onChange = () => {},
    label = 'Período do Histórico',
    disableClearable,
}: PeriodoHistoricoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            disableClearable={disableClearable}
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            options={PeriodoHistoricoOptions}
            selectedStringTemplate="{dsPeriodoHistorico}"
            optionStringTemplate="{dsPeriodoHistorico}"
            isOptionEqualToValue={(option: PeriodoHistorico, value: PeriodoHistorico) => option.idPeriodoHistorico === value.idPeriodoHistorico}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}
