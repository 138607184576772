import React, { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { OneColumn, Block } from 'components/page';
import { PanelGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useListagemContext } from 'context/ListagemContext';
import { FormFilterCategoriaComunicado } from 'pages/comunicado/categoria/components';

import { RequestCategoriaListModelProps, CategoriaModelProps } from '../../../categoria/types';
import { EditRemoveBtn } from 'components/buttons';

const generateCategoriaComunicadoList = (data: CategoriaModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idCategoriaComunicado: item.idCategoriaComunicado,
            dsCategoriaComunicado: item.dsCategoriaComunicado,
            fgAtivo: item.fgAtivo ? 'Ativo' : 'Inativo',
        }));
    }

    return [];
};

export function CategoriaComunicadoListComp(): JSX.Element {
    const { filter, limit, page, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/gestao/categoriacomunicado',
            filter,
            sort: sort.length ? sort : [{ property: 'idCategoriaComunicado', direction: 'DESC' }],
            columns: 'idCategoriaComunicado,dsCategoriaComunicado,fgAtivo',
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery([requestOptions], () => {
        const request: Promise<RequestCategoriaListModelProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'idCategoriaComunicado', headerName: 'ID' },
            { field: 'dsCategoriaComunicado', headerName: 'Descrição', minWidth: 120, flex: 1 },
            { field: 'fgAtivo', headerName: 'Status', width: 120, minWidth: 70 },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                minWidth: 80,
                renderCell: ({ row }) => (
                    <EditRemoveBtn
                        id={row.idCategoriaComunicado}
                        refresh={refetch}
                        editUrl="/comunicado/categoria/form/"
                        removeUrl={`gestao/categoriacomunicado/${row.idCategoriaComunicado}`}
                        sx={{ ml: 'auto', p: 1.5 }}
                    />
                ),
            },
        ],
        [refetch],
    );

    const rows = useMemo(() => generateCategoriaComunicadoList(data?.data), [data]);

    return (
        <OneColumn title="Listagem Categorias de Comunicado" createButton goBackButton="/Comunicado">
            <FormFilterCategoriaComunicado />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
