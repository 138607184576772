import React from 'react';

import { PedidoCompraListComp } from './components';

import { ListagemProvider } from 'context/ListagemContext';
import { PedidoCompraProvider } from 'context/PedidoCompraContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { PedidoFilterProvider } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoCompraList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PedidoCompraProvider>
                <PedidoFilterProvider>
                    <PedidoCompraListComp />
                </PedidoFilterProvider>
            </PedidoCompraProvider>
        </ListagemProvider>
    );
}
