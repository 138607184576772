import React from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { UsuarioInfo } from 'pages/compra/pedido/types';

export function useGetUsuario(): UseQueryResult<UsuarioInfo> {
    const { configPedido, setUsuarioInfo, tipoEntrega, funcionario } = usePedidoCompraContext();
    const requestListagem = useRequestListagem();
    const idConfigPedido = configPedido?.idConfigPedido;
    const idTipoEntrega = tipoEntrega?.idTipoEntrega || funcionario?.idTipoEntrega;
    const idUsuario = funcionario?.idUsuario;

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/dados/usuario',
        extraParams: [{ idConfigPedido }, { idTipoEntrega }, { idUsuario }],
    };

    return useQuery<UsuarioInfo>(['getUsuarioInfo', requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(idConfigPedido),
        onSuccess: (response) => setUsuarioInfo(response),
    });
}
