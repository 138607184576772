import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface DepositoProps {
    idDeposito: number;
    cdDeposito: number;
    dsDeposito: string;
}

type DepositoAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function DepositoAutoComplete({ value, error, multiple = false, sx, onChange, name }: DepositoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Depósito"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsDeposito}"
            optionStringTemplate="{dsDeposito}"
            requestOptions={{
                url: '/gestao/deposito',
                columns: 'idDeposito,dsDeposito,cdDeposito',
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
            }}
            isOptionEqualToValue={(option: DepositoProps, value: DepositoProps) => option.idDeposito === value.idDeposito}
            onChange={onChange}
            sx={sx}
            name={name}
        />
    );
}
