import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useGetBandaPreco } from 'pages/complementar/banda-preco/hooks';
import { BandaPreco } from 'pages/complementar/banda-preco/types';
import { brlDate, brlPercentual } from 'util/format';

const getRows = (data?: BandaPreco[]): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idDreMercadologicaGrupoExibicaoBandaPreco,
            fgAtivo: item.fgAtivo,
            dsDreMercadologicaGrupoExibicao: item.dsDreMercadologicaGrupoExibicao,
            peBandaPreco: item.peBandaPreco && brlPercentual(item.peBandaPreco),
            dtInicioVigencia: item.dtInicioVigencia && brlDate(`${item.dtInicioVigencia}T00:00:00`),
        }));
    }

    return [];
};

const columns: GridColDef[] = [
    { field: 'dsDreMercadologicaGrupoExibicao', headerName: 'CMV', flex: 1 },
    { field: 'peBandaPreco', headerName: 'Banda de preço', flex: 1 },
    { field: 'dtInicioVigencia', headerName: 'Vigência', flex: 1 },
    { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean' },
    {
        field: 'acoes',
        align: 'center',
        headerAlign: 'center',
        headerName: 'Ações',
        filterable: false,
        sortable: false,
        hideable: false,
        disableColumnMenu: true,
        width: 110,
        renderCell: ({ row }) => <EditRemoveBtn id={row.id} showEditBtn={true} sx={{ p: 1.5 }} />,
    },
];

export function BandaPrecoGrid(): JSX.Element {
    const { data, refetch, isFetching } = useGetBandaPreco();

    return (
        <Block>
            <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={isFetching} columns={columns} />
        </Block>
    );
}
