import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { ExtratoFppListComp } from './components';
import { usePermissionsContext } from 'context/PermissionsContext';

export function ExtratoFppList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_EXTRATO_FPP_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <ExtratoFppListComp />
        </ListagemProvider>
    );
}
