import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Divider, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';
import { Event } from 'react-big-calendar';

import { PromocaoProdutoProps, PromocaoInfoDataProps, PromocaoGridRequestProps } from '../../types';

import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { Centered } from 'components/grid';
import { ContentModal } from 'components/modal';
import { Block } from 'components/page';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CalendarioListEventHeader } from 'pages/calendario/components';
import { getCategoryEventColor } from 'pages/calendario/components/templates/CalendarioListCalendar';
import { CalendarioListEventPromocaoGrid } from 'pages/calendario/components/templates/CalendarioListEventPromocaoGrid';
import { brlPercentual, brlPrice } from 'util/format';

interface CalendarioListCalendarProps {
    promocao: Event | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    store: StoreOptionsProps | null;
}

export function CalendarioListPromocaoModal({ open, setOpen, promocao, store }: CalendarioListCalendarProps): JSX.Element {
    const { title, resource } = promocao || {};
    const { tpCalendarioEvento, dsCor, idPromocao } = resource || {};
    const categoryColor = getCategoryEventColor(tpCalendarioEvento);
    const RequestListagem = useRequestListagem();
    const [promocaoInfo, setPromocaoInfo] = useState<PromocaoInfoDataProps[] | null>(null);
    const [promocaoProdutos, setPromocaoProdutos] = useState<PromocaoProdutoProps[] | null>(null);

    const requestOptions: RequestOptionsType = {
        url: `/gestao/calendarioeventopromocao/promocao/${idPromocao}?cdLoja=${Number(store?.cdLoja)}`,
    };

    const { isLoading, fetchStatus } = useQuery([requestOptions], (): Promise<PromocaoGridRequestProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: open,
        onSuccess: (response) => {
            const { dhInicioPromocao, dhFimPromocao, tipoPromocao, tipoTemplatePromocao, vlDesconto, peDesconto, promocaoProduto } = response.promocao;
            const newPromocaoInfo = [
                { label: 'Data Início', value: dateFns.format(new Date(dhInicioPromocao), 'MM/dd/yyyy') },
                { label: 'Data Fim', value: dateFns.format(new Date(dhFimPromocao), 'MM/dd/yyyy') },
                { label: 'Tipo Promoção', value: tipoPromocao.dsTipoPromocao },
                { label: 'Template Promoção', value: tipoTemplatePromocao ? tipoTemplatePromocao.dsTipoTemplatePromocao : '--' },
                { label: 'Valor Desconto', value: brlPrice(vlDesconto) },
                { label: 'Porcentagem Desconto', value: brlPercentual(peDesconto) },
            ];

            setPromocaoInfo(newPromocaoInfo);
            setPromocaoProdutos(promocaoProduto);
        },
    });

    useEffect(() => {
        if (!open) {
            setPromocaoInfo(null);
            setPromocaoProdutos(null);
        }
    }, [open]);

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            closeButton={{
                'top': -15,
                'right': -15,
                'backgroundColor': 'common.white',
                'borderWidth': 2,
                'borderStyle': 'solid',
                'borderColor': '#264653',
                'p': 0.5,
                '&:hover': {
                    backgroundColor: 'common.white',
                },
            }}
            sx={{
                p: 0,
                width: { sm: '20vw', xs: '80%' },
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: '#264653',
                overflow: 'visible',
            }}
        >
            <React.Fragment>
                {isLoading || fetchStatus === 'fetching' ? (
                    <Centered>
                        <CircularProgress sx={{ my: 2 }} />
                    </Centered>
                ) : (
                    promocaoInfo &&
                    promocaoProdutos && (
                        <React.Fragment>
                            <CalendarioListEventHeader color={dsCor} title={title} />

                            <Divider
                                sx={{
                                    borderColor: categoryColor.hex,
                                }}
                            />

                            <Stack sx={{ p: 2 }} spacing={2}>
                                <Block variant="outlined" elevation={0} sx={{ m: 0 }}>
                                    <Stack spacing={0.5}>
                                        {promocaoInfo.map((item) => (
                                            <Box sx={{ display: 'flex' }} key={item.value}>
                                                <Typography sx={{ flex: 1 }}>{item.label}</Typography>
                                                <Typography sx={{ flex: 1 }}>
                                                    <strong>{item.value}</strong>
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Block>

                                <Box sx={{ maxHeight: { sm: 300, xs: 150 }, overflow: 'auto', overflowX: 'hidden' }}>
                                    <CalendarioListEventPromocaoGrid data={promocaoProdutos} />
                                </Box>
                            </Stack>
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        </ContentModal>
    );
}
