import React, { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import { GridColDef, GridRowsProp, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider, useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoCompraTotalizadorFalhasModal, PedidoCompraTotalizadorSituacaoColumn } from 'pages/compra/pedido-totalizador/components';
import { PedidoCompraTotalizadorQuebraGrid } from 'pages/compra/pedido-totalizador/components/templates/PedidoCompraTotalizadorQuebraGrid';
import { RequestPedidoTotalizadorProps } from 'pages/compra/pedido-totalizador/types';
import { brlPrice } from 'util/format';

export function PedidoCompraTotalizadorGrid(): JSX.Element {
    const { setContent } = useFormContext();
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const [openErrosModal, setOpenErrosModal] = useState<number | boolean>(false);

    const getIdPedidoStatusQuebra = useMemo(() => {
        const filterPedidoStatusQuebra = filter.find((item) => item.property === 'idPedidoStatusQuebra');

        return filterPedidoStatusQuebra ? filterPedidoStatusQuebra.value : null;
    }, [filter]);

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/compra/pedidocompra/totalizador',
            sort: sort.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
            filter,
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(['getListTotalizadores', requestOptions], (): Promise<RequestPedidoTotalizadorProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
        onSuccess: (response) => {
            const totalizadorWithData = Boolean(response?.total);

            setContent({ totalizadorWithData });
        },
    });

    const columns = useMemo(
        (): GridColDef[] => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: ({ value: isExpanded, row }) => (
                    <React.Fragment>
                        {[3, 5].includes(row.idStatusPedidoTotalizador) && (
                            <IconButton size="small" tabIndex={-1}>
                                {isExpanded ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                            </IconButton>
                        )}
                    </React.Fragment>
                ),
            },
            { field: 'dhInclusao', headerName: 'Data', width: 120, minWidth: 90 },
            {
                field: 'dsConfigPedido',
                headerName: 'Configuração de Pedido',
                flex: 1,
                minWidth: 120,
                renderCell: ({ row }) => {
                    const { idConfigPedido, dsConfigPedido } = row;

                    return (
                        <div>
                            <b>{idConfigPedido}</b> - {dsConfigPedido}
                        </div>
                    );
                },
            },
            { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 130 },
            {
                field: 'idStatusPedidoTotalizador',
                headerName: 'Situação da Quebra',
                width: 200,
                minWidth: 150,
                renderCell: ({ row }) => (
                    <PedidoCompraTotalizadorSituacaoColumn
                        idStatus={row.idStatusPedidoTotalizador}
                        title={row.dsStatusPedidoTotalizador}
                        idPedido={row.idPedido}
                        setOpenErrosModal={setOpenErrosModal}
                    />
                ),
            },
            { field: 'nrPedido', headerName: 'Código do Pedido', width: 100, minWidth: 90 },
            { field: 'cdPedido', headerName: 'Código de Integração', width: 100, minWidth: 90 },
            {
                field: 'vlTotal',
                headerName: 'Total Custo',
                type: 'number',
                align: 'right',
                width: 120,
                minWidth: 110,
                valueFormatter: ({ value }) => brlPrice(value),
            },
        ],
        [],
    );

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(data?.data) && data?.data.length) {
            return data?.data.map((item) => ({
                id: item.idPedido,
                idPedido: item.idPedido,
                dhInclusao: new Date(item.dhInclusao).toLocaleDateString('pt-BR'),
                dsNomeFantasia: item.loja.dsNomeFantasia,
                cdPedido: item.cdPedido,
                nrPedido: item.nrPedido,
                idConfigPedido: item.configPedido.idConfigPedido,
                dsConfigPedido: item.configPedido.dsConfigPedido,
                vlTotal: item.vlTotal,
                fgAtivo: item.fgAtivo,
                idStatusPedidoTotalizador: item.statusPedidoTotalizador.idStatusPedidoTotalizador,
                dsStatusPedidoTotalizador: item.statusPedidoTotalizador.dsStatusPedidoTotalizador,
            }));
        }

        return [];
    }, [data?.data]);

    return (
        <Block>
            <PedidoCompraTotalizadorFalhasModal open={openErrosModal} setOpen={setOpenErrosModal} />

            <PanelGrid
                rows={rows}
                reload={refetch}
                loading={fetchStatus === 'fetching'}
                columns={columns}
                rowsCount={data?.total ?? 0}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={({ row }) => (
                    <ListagemProvider>
                        <PedidoCompraTotalizadorQuebraGrid row={row} idPedidoStatusQuebra={getIdPedidoStatusQuebra} />
                    </ListagemProvider>
                )}
                isTreeData
                summaryPrefix={false}
                summary={['vlTotal']}
            />
        </Block>
    );
}
