import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';

import { StoreAutoComplete, TipoGradeAutoComplete } from 'components/autocompletes';
import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

const filterPedidoOptions = [
    {
        idOptionPedido: 1,
        dsNomeExibicao: 'Ambos',
        vlOptionPedido: null,
    },
    {
        idOptionPedido: 2,
        dsNomeExibicao: 'Sim',
        vlOptionPedido: true,
    },
    {
        idOptionPedido: 3,
        dsNomeExibicao: 'Não',
        vlOptionPedido: false,
    },
];

export function FilterGrade(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'eq',
        value: null,
        idProperty: 'cdLoja',
    });

    const [filterGrade, setFilterGrade] = useState<FilterValuesProps>({
        property: 'idTipoGrade',
        operator: 'in',
        value: [],
        idProperty: 'idTipoGrade',
    });

    const [filterPedido, setFilterPedido] = useState<FilterValuesProps>({
        property: 'fgPedido',
        operator: 'eq',
        value: null,
        idProperty: 'vlOptionPedido',
    });

    const [filterDataLimitePedidoInterval, setFilterDataLimitePedidoInterval] = useState<FilterValuesProps>({
        property: 'dtLimitePedido',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const [filterDataEntregaInterval, setFilterDataEntregaInterval] = useState<FilterValuesProps>({
        property: 'dtEntrega',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterPedido, setter: setFilterPedido },
        { value: filterGrade, setter: setFilterGrade },
        { value: filterDataLimitePedidoInterval, setter: setFilterDataLimitePedidoInterval },
        { value: filterDataEntregaInterval, setter: setFilterDataEntregaInterval },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);
  
    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={6}>
                <StoreAutoComplete flagInstitucional value={filterLoja.value} onChange={(e, value) => setFilterLoja({ ...filterLoja, value })} />
            </Grid>

            <Grid item lg={3}>
                <TipoGradeAutoComplete multiple value={filterGrade.value} onChange={(e, value) => setFilterGrade({ ...filterGrade, value })} />
            </Grid>

            <Grid item lg={3}>
                <CissAutoComplete
                    value={filterPedido.value}
                    options={filterPedidoOptions}
                    onChange={(e, value) => {
                        setFilterPedido({ ...filterPedido, value });
                    }}
                    isOptionEqualToValue={(option, value) => option && value && option.idOptionPedido === value.idOptionPedido}
                    multiple={false}
                    optionStringTemplate={'{dsNomeExibicao}'}
                    selectedStringTemplate={'{dsNomeExibicao}'}
                    label={'Possui pedido'}
                />
            </Grid>

            <Grid item lg={6}>
                <DateRangePicker
                    value={[filterDataLimitePedidoInterval.startDate ?? null, filterDataLimitePedidoInterval.endDate ?? null]}
                    onChange={([startDate, endDate]) => {
                        setFilterDataLimitePedidoInterval((oldState: FilterValuesProps) => ({
                            ...oldState,
                            startDate,
                            endDate,
                        }));
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <CissTextField {...startProps} autoComplete="off" label={'Data de limite pedido início'} />
                            <Box sx={{ mx: 2 }}> até </Box>
                            <CissTextField {...endProps} autoComplete="off" label={'Data de limite pedido fim'} />
                        </React.Fragment>
                    )}
                />
            </Grid>

            <Grid item lg={6}>
                <DateRangePicker
                    value={[filterDataEntregaInterval.startDate ?? null, filterDataEntregaInterval.endDate ?? null]}
                    onChange={([startDate, endDate]) => {
                        setFilterDataEntregaInterval((oldState: FilterValuesProps) => ({
                            ...oldState,
                            startDate,
                            endDate,
                        }));
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <CissTextField {...startProps} autoComplete="off" label={'Data de entrega início'} />
                            <Box sx={{ mx: 2 }}> até </Box>
                            <CissTextField {...endProps} autoComplete="off" label={'Data de entrega fim'} />
                        </React.Fragment>
                    )}
                />
            </Grid>
        </Filter>
    );
}
