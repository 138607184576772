import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

interface StatusTituloOptionsProps {
    idStatus: number;
    dsStatus: string;
    tpStatus: string;
}

type TituloStatusAutoComplete = Pick<CissAutoCompleteProps, 'multiple' | 'onChange' | 'size' | 'label'> & {
    value: any;
};

const vencidosStatus: StatusTituloOptionsProps = {
    idStatus: 1,
    dsStatus: 'À Vencer / Em Aberto',
    tpStatus: 'VC',
};

const liquidadosStatus: StatusTituloOptionsProps = {
    idStatus: 2,
    dsStatus: 'Liquidados',
    tpStatus: 'LQ',
};

const data: StatusTituloOptionsProps[] = [vencidosStatus, liquidadosStatus];

const findOptionByValue = (value: StatusTituloOptionsProps): StatusTituloOptionsProps | null => {
    switch (value?.tpStatus) {
        case 'VC':
            return vencidosStatus;
        case 'LQ':
            return liquidadosStatus;
        default:
            return null;
    }
};

export function TituloStatusAutoComplete({ onChange, value, size, multiple = false, label = 'Status' }: TituloStatusAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            size={size}
            label={label}
            options={data}
            multiple={multiple}
            value={findOptionByValue(value)}
            onChange={onChange}
            selectedStringTemplate="{dsStatus}"
            optionStringTemplate="{dsStatus}"
            isOptionEqualToValue={(option, fieldValue) => option.idStatus === fieldValue.idStatus}
        />
    );
}
