import React from 'react';

import { Button } from '@mui/material';
import { SxProps } from '@mui/system';

export interface ExtraButtonListProps {
    title: string;
    onClick: () => void;
    sx?: SxProps;
    featured?: boolean;
    visible?: boolean;
}

interface ExtraButtonProps {
    list: ExtraButtonListProps | ExtraButtonListProps[];
    sx?: SxProps;
}

export function ExtraButtons({ list, sx }: ExtraButtonProps): JSX.Element | null {
    const buttons: (JSX.Element | null)[] = [];

    const generateButton = ({ title, onClick, sx, featured, visible = true }: ExtraButtonListProps): JSX.Element | null => {
        if (visible) {
            return (
                <Button key={`extra_button_${title}`} variant={featured ? 'contained' : 'outlined'} onClick={onClick} sx={{ ...sx, flex: 'auto' }}>
                    {title}
                </Button>
            );
        }

        return null;
    };

    if (Array.isArray(list)) {
        list.forEach((item) => buttons.unshift(generateButton(item)));
    } else if (typeof list === 'object') {
        buttons.push(generateButton({ ...list, sx }));
    }

    return <React.Fragment>{buttons}</React.Fragment>;
}
