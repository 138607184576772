import React from 'react';

import { DreRelatorioComp } from './components';

import { ListagemProvider } from 'context/ListagemContext';

export function DreRelatorio(): JSX.Element {
    return (
        <ListagemProvider>
            <DreRelatorioComp />
        </ListagemProvider>
    );
}
