import React, { useState } from 'react';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { LoadingButton } from '@mui/lab';

import { PedidoRealizadoTrackModal } from 'pages/compra/pedido-realizado/components/templates/PedidoRealizadoTrackModal';

type PedidoRealizadoTrackBtn = {
    dsUrlTrakingPedido: string;
    isDisabledActions: boolean;
};

export function PedidoRealizadoTrackBtn({ isDisabledActions, dsUrlTrakingPedido }: PedidoRealizadoTrackBtn): JSX.Element {
    const [isOpenTrackModal, setIsOpenTrackModal] = useState<boolean>(false);

    return (
        <>
            <PedidoRealizadoTrackModal url={dsUrlTrakingPedido} open={isOpenTrackModal} setOpen={setIsOpenTrackModal} />

            <LoadingButton
                color="inherit"
                startIcon={<LocalShippingOutlinedIcon />}
                disabled={isDisabledActions}
                onClick={() => setIsOpenTrackModal(true)}
            >
                Rastreio
            </LoadingButton>
        </>
    );
}
