import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Card, CardActions, CardContent, CardMedia, Skeleton, Typography } from '@mui/material';

import { ListBoxItemProps } from './ListBoxItem';

import { EditRemoveBtn, LikeBtn, ViewBtn } from 'components/buttons';
import { InactiveBox } from 'components/content';
import { removeHtmlTags } from 'util/format';

export function CardBoxSkeleton(): JSX.Element {
    return (
        <Box>
            <Skeleton
                variant="rectangular"
                width={330}
                height={215}
                sx={{
                    maxWidth: '100%',
                }}
            />
            <Box sx={{ mt: 2 }}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Box>
        </Box>
    );
}

/**
 * Componente de card para uso nas listagens
 */
export function CardBoxItem({
    id,
    active,
    label,
    content,
    type,
    date,
    tags,
    cover,
    likes = 0,
    liked = false,
    views = 0,
    refresh,
    removeUrl,
    cloneUrl,
    showEditBtn,
    showCloneBtn,
    showRemoveBtn,
    showView = true,
    showLike = true,
    DontShowDetail = false,
    idClone,
    extraContent,
}: ListBoxItemProps): JSX.Element {
    const navigate = useNavigate();

    const handleDetail = useCallback((): void => {
        cover && DontShowDetail === false ? navigate(id.toString()) : navigate(`form/${id}`);
    }, [cover, DontShowDetail, navigate, id]);

    return (
        <InactiveBox active={active}>
            <Card
                sx={{
                    height: '100%',
                    position: 'relative',
                    pb: 6.5, // padding para os itens posicionados absolutamente no footer
                }}
            >
                {cover && (
                    <CardMedia
                        onClick={handleDetail}
                        title={label}
                        component="img"
                        height="215"
                        image={cover}
                        alt={label}
                        className="box-img-preview"
                        sx={{
                            mb: 1,
                            cursor: 'pointer',
                            objectFit: 'contain',
                        }}
                    />
                )}

                <CardContent
                    onClick={handleDetail}
                    title={label}
                    sx={{
                        pt: 1,
                        pb: 0,
                        cursor: 'pointer',
                    }}
                >
                    <Typography
                        sx={{
                            mb: 0.5,
                            color: 'common.black',
                        }}
                    >
                        {label.toUpperCase()}
                    </Typography>

                    {type && (
                        <Typography variant="body2" sx={{ color: 'primary.main' }}>
                            {type}
                        </Typography>
                    )}

                    {date && (
                        <Typography variant="body2" sx={{ color: 'primary.main' }}>
                            {date}
                        </Typography>
                    )}

                    {Array.isArray(tags) && (
                        <Typography variant="body2" sx={{ color: 'primary.main' }}>
                            {tags.map((tag) => `#${tag} `)}
                        </Typography>
                    )}

                    {content && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'primary.main',
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                pt: {
                                    xs: 1.4,
                                    md: 0,
                                },
                            }}
                        >
                            {removeHtmlTags(content)}
                        </Typography>
                    )}
                </CardContent>

                <CardActions
                    disableSpacing
                    sx={{
                        px: 2,
                        pb: 2,
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                    }}
                >
                    {showLike && <LikeBtn likes={likes} liked={liked} small sx={{ mr: 2 }} />}
                    {showView && <ViewBtn views={views} small sx={{ mr: 0 }} />}

                    {(showEditBtn || showRemoveBtn || showCloneBtn || extraContent) && (
                        <EditRemoveBtn
                            id={id}
                            refresh={refresh}
                            sx={{ ml: 'auto' }}
                            showEditBtn={showEditBtn}
                            showCloneBtn={showCloneBtn}
                            showRemoveBtn={showRemoveBtn}
                            cloneUrl={cloneUrl}
                            idClone={idClone}
                            removeUrl={removeUrl}
                            extraContent={extraContent}
                        />
                    )}
                </CardActions>
            </Card>
        </InactiveBox>
    );
}
