import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { StoreAutoComplete, SituacaoSugestaoAutoComplete, ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function SugestaoCompraListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const data = new Date();

    const [filterConfigPedido, setFilterConfigPedido] = useState<FilterValuesProps>({
        property: 'idConfigPedido',
        operator: 'in',
        value: [],
        idProperty: 'idConfigPedido',
    });

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'idLoja',
        operator: 'eq',
        value: [],
        idProperty: 'idLoja',
    });

    const [filterSituacao, setFilterSituacao] = useState<FilterValuesProps>({
        property: 'idSugestaoCompraStatus',
        operator: 'in',
        value: [],
        idProperty: 'idSugestaoCompraStatus',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterConfigPedido, setter: setFilterConfigPedido },
        { value: filterSituacao, setter: setFilterSituacao },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={5}>
                <ConfiguracaoPedidoAutoComplete
                    value={filterConfigPedido.value}
                    multiple={true}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        limit: 9999,
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        filter: [
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                            { property: 'fgAtivo', operator: 'eq', value: true },
                            { property: 'dtInicioVigencia', operator: 'lte', value: data },
                            { property: 'dtFimVigencia', operator: 'gte', value: data },
                        ],
                    }}
                    onChange={(e, value) => {
                        if (filterConfigPedido.value.length > value.length) {
                            setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value: [] }));
                        }

                        setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value }));
                    }}
                />
            </Grid>

            <Grid item flex={6}>
                <StoreAutoComplete
                    defaultStore={false}
                    multiple={true}
                    requestOptions={
                        filterConfigPedido.value.length
                            ? {
                                  url: '/gestao/loja/findbyidsconfigpedido',
                                  filter: [{ property: 'idConfigPedido', operator: 'in', value: filterConfigPedido.value.map((config: any) => config.idConfigPedido) }],
                                  columns: 'idLoja,dsNomeFantasia,cdLoja',
                              }
                            : null
                    }
                    value={filterLoja.value}
                    onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item flex={3}>
                <SituacaoSugestaoAutoComplete multiple={true} value={filterSituacao.value} onChange={(e, value) => setFilterSituacao((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>
        </Filter>
    );
}
