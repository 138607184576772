import React from 'react';

import { FullLoading } from 'components/loading';
import { FormPage } from 'components/page';
import { ConfiguracaoGeralGeneralBlock } from 'pages/revendedor/configuracao-geral/components';
import { usePostConfiguracaoGeral } from 'pages/revendedor/configuracao-geral/hooks';

export function ConfiguracaoGeralFormComp(): JSX.Element {
    const { handleSubmit, isLoading } = usePostConfiguracaoGeral();

    return (
        <FormPage
            goBackButton
            queryWithoutId
            keepOriginalTitle
            title="Configurações Gerais"
            url="/integracao-revendedor/descontos/get"
            values={{}}
            onSubmit={handleSubmit}
        >
            {() => (
                <>
                    <FullLoading loading={isLoading} />

                    <ConfiguracaoGeralGeneralBlock />
                </>
            )}
        </FormPage>
    );
}
