import React, { useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { ImportacaoUploadBtn } from 'pages/compra/pedido/components';

interface ConfiguracaoPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
}

interface PedidoLoteCompraImportarModal {
    open: boolean;
    setOpen: (action: boolean) => void;
}

export function PedidoLoteCompraImportarModal({ open, setOpen }: PedidoLoteCompraImportarModal): JSX.Element {
    const { autoLoad, setAutoLoad } = useListagemContext();
    const [configPedido, setConfigPedido] = useState<ConfiguracaoPedidoProps | null>();
    const [fileImportacao, setFileImportacao] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const data = new Date();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: () => {
            if (!autoLoad) {
                setAutoLoad(true);
            }
            
            queryClient.invalidateQueries(['getListPedidosLote']);

            enqueueSnackbar('Arquivo de importação em lote salvo com sucesso', { variant: 'success' });

            setOpen(false);
        },
        onError: (error: any) => showError(error, 'Erro ao salvar arquivo'),
    });

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (fileImportacao) {
            formData.append('file', fileImportacao);
            formData.append('idConfigPedido', String(configPedido?.idConfigPedido));
        }

        mutate({
            url: '/compra/pedidoimportacao/upload',
            formData,
        });
    }, [configPedido, fileImportacao, mutate]);

    useEffect(() => {
        if (!open) {
            setFileImportacao(null);
            setConfigPedido(null);
        }
    }, [open]);

    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 2 }} closeButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Importação em Lote" />

                <ConfiguracaoPedidoAutoComplete
                    value={configPedido}
                    onChange={(e, value) => setConfigPedido(value)}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        filter: [
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                            { property: 'fgAtivo', operator: 'eq', value: true },
                            { property: 'dtInicioVigencia', operator: 'lte', value: data },
                            { property: 'dtFimVigencia', operator: 'gte', value: data },
                        ],
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        limit: 9999,
                    }}
                />

                <Grid container sx={{ my: 3, justifyContent: 'space-between', gap: 2 }}>
                    <Grid item>
                        <ImportacaoUploadBtn setFileImportacao={setFileImportacao} fileImportacao={fileImportacao} />
                    </Grid>
                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_PEDIDO_AUTOMATICO" />
                    </Grid>
                </Grid>

                <Stack spacing={2} justifyContent="flex-end" direction="row">
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={handleSubmit} loading={isLoading} disabled={!configPedido || !fileImportacao || isLoading}>
                        Importar
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
