import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { CardBanner } from 'components/banner';
import { WhatsAppBtn } from 'components/buttons';
import { Fit } from 'components/grid';
import { Header, Footer } from 'components/page';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetWhatsApp } from 'hooks/useGetWhatsApp';

interface PageDefaultProps {
    children?: ReactNode;
}

/**
 * Estrutura padrão do sistema para páginas
 * Contém header + conteudo + footer
 */

export function PageDefault({ children }: PageDefaultProps): JSX.Element {
    const { isUpMd, isDownMd } = useBreakpoints();
    const { data: link } = useGetWhatsApp();
    const showWhatsAppFooter = isUpMd && link;
    const showWhatsAppHeader = isDownMd && link;

    return (
        <>
            <Fit direction="column" sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
                <Header />

                {showWhatsAppHeader && (
                    <Box
                        sx={{
                            position: 'sticky',
                            marginBottom: '-65px',
                            top: 16,
                            mt: 2,
                            mr: 2,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                    >
                        <WhatsAppBtn link={link} size={25} />
                    </Box>
                )}

                <CardBanner />

                {children}

                <Footer />
            </Fit>

            {showWhatsAppFooter && (
                <WhatsAppBtn link={link} sx={{ zIndex: 3, position: 'fixed', bottom: window.innerWidth > 1500 ? 16 : 62, left: 16 }} />
            )}
        </>
    );
}
