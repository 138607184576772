import React, { useMemo, useState } from 'react';

import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, IconButton, Paper, Tooltip, Typography, Stack } from '@mui/material';
import { isNumber } from 'lodash';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import {
    PedidoCompraAdicionarButton,
    PedidoCompraAmmount,
    PedidoCompraProdutoModal,
    PedidoCompraValue,
    PedidoDescontoChip,
} from 'pages/compra/pedido/components';
import { PedidoCompraListagemProductProps } from 'pages/compra/pedido/types';
import { brlDecimal, camelCase } from 'util/format';

export function PedidoCompraGridProduct({ produtoPedido, columnIndex, numColumns, sx }: PedidoCompraListagemProductProps): JSX.Element {
    const { modifyProdListQtd, getProdListQtd, isProdutoDisabled, getProdMinQtd, configPedidoValidateQtdMin, getProdCart } = usePedidoCompraContext();
    const { produto, qtMinimaCompra, peOferta, fgShowPeOferta } = produtoPedido;
    const produtoDisabled = isProdutoDisabled(produtoPedido);
    const produtoCart = getProdCart(produto.idProduto);
    const produtoQtd = produtoCart ? produtoCart.qtProdutoList : 0;
    const prodMinQtd = getProdMinQtd(qtMinimaCompra);
    const [openProdutoModal, setOpenProdutoModal] = useState<boolean>(false);
    const { isDownLg } = useBreakpoints();
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    const getSx = useMemo(() => {
        let width: number = isNumber(Number(sx?.width)) ? Number(sx?.width) : 0;
        let mr: string | number = 0;
        let ml: string | number = 0;

        if (!isDownLg) {
            width -= 16;
        } else {
            if (numColumns === 3) {
                width -= 10;

                if (columnIndex === 0) {
                    mr = '10px';
                } else if (columnIndex === 1) {
                    ml = '5px';
                    mr = '5px';
                } else if (columnIndex === 2) {
                    ml = '10px';
                }
            } else if (numColumns === 2) {
                width -= 8;

                if (columnIndex === 0) {
                    mr = 1;
                } else if (columnIndex === 1) {
                    ml = 1;
                }
            }
        }

        return { ...sx, height: isTipoPedidoFuncionario ? 396 : 456, opacity: produtoDisabled ? 0.6 : 1, p: 2, overflow: 'hidden', width, mr, ml };
    }, [columnIndex, isDownLg, isTipoPedidoFuncionario, numColumns, produtoDisabled, sx]);

    return (
        <React.Fragment>
            <PedidoCompraProdutoModal open={openProdutoModal} setOpen={setOpenProdutoModal} produto={produtoPedido} />

            <Paper variant="elevation" elevation={2} sx={getSx}>
                <Tooltip followCursor={true} title={produtoDisabled ? 'Produto indisponível para compra' : null}>
                    <React.Fragment>
                        {peOferta && fgShowPeOferta && <PedidoDescontoChip peOferta={peOferta} sx={{ top: 8, left: 8 }} />}

                        <Stack justifyContent="space-between" height="100%">
                            <Box
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    onClick={() => (isTipoPedidoFuncionario ? null : setOpenProdutoModal(true))}
                                    sx={{
                                        height: 180,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        backgroundImage: `url("${produto.lkImagem}")`,
                                        mb: 2,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {!produto.lkImagem && (
                                        <InsertPhotoOutlinedIcon sx={{ fontSize: 50, color: (theme) => theme.palette.grey[400] }} />
                                    )}
                                </Box>

                                {isTipoPedidoPadrao && (
                                    <IconButton
                                        onClick={() => setOpenProdutoModal(true)}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                        }}
                                    >
                                        <InfoOutlinedIcon color="primary" />
                                    </IconButton>
                                )}
                            </Box>

                            <Typography
                                onClick={() => (isTipoPedidoFuncionario ? null : setOpenProdutoModal(true))}
                                sx={{
                                    fontSize: '1.1rem',
                                    lineHeight: '1.3em',
                                    overflow: 'hidden',
                                    fontWeight: 500,
                                    height: '2.8em',
                                    cursor: 'pointer',
                                }}
                            >
                                {camelCase(produto.dsProduto, 2)}
                            </Typography>

                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 2fr',
                                    gap: 1,
                                }}
                            >
                                <Box>
                                    <Typography variant="body2">Valor Unitário</Typography>

                                    <PedidoCompraValue value={produtoPedido.vlPrecoCompra} />
                                </Box>

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        gap: 0.5,
                                    }}
                                >
                                    {isTipoPedidoPadrao && (
                                        <React.Fragment>
                                            <Box sx={{ opacity: isNumber(produtoPedido.estoqueLoja?.qtSaldoAtual) ? 1 : 0.5 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '1rem',
                                                            letterSpacing: '-0.03rem',
                                                        }}
                                                    >
                                                        {brlDecimal(produtoPedido.estoqueLoja?.qtSaldoAtual || 0, 0, 3)}{' '}
                                                        {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                                    </Typography>

                                                    <Inventory2OutlinedIcon />
                                                </Box>

                                                <Typography
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    Estoque na Loja
                                                </Typography>
                                            </Box>

                                            <Box sx={{ opacity: isNumber(produtoPedido.qtCoberturaEstoque) ? 1 : 0.5 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '1rem',
                                                            letterSpacing: '-0.03rem',
                                                        }}
                                                    >
                                                        {produtoPedido.qtCoberturaEstoque || 0} dias
                                                    </Typography>

                                                    <DateRangeOutlinedIcon />
                                                </Box>

                                                <Typography
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    Cobertura de Estoque
                                                </Typography>
                                            </Box>

                                            <Box sx={{ opacity: isNumber(produtoPedido.qtSugerida) ? 1 : 0.5 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: '1rem',
                                                            letterSpacing: '-0.03rem',
                                                        }}
                                                    >
                                                        {brlDecimal(produtoPedido.qtSugerida || 0, 0, 3)}{' '}
                                                        {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                                    </Typography>

                                                    <CodeOutlinedIcon />
                                                </Box>

                                                <Typography
                                                    sx={{
                                                        fontSize: '0.8rem',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    Quantidade Sugerida
                                                </Typography>
                                            </Box>
                                        </React.Fragment>
                                    )}

                                    <Box sx={{ opacity: isNumber(prodMinQtd) && configPedidoValidateQtdMin ? 1 : 0.5 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                gap: 0.5,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '1rem',
                                                    letterSpacing: '-0.03rem',
                                                }}
                                            >
                                                {brlDecimal(prodMinQtd || 0, 0, 3)} {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                            </Typography>

                                            <ShoppingCartOutlinedIcon />
                                        </Box>

                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                textAlign: 'right',
                                            }}
                                        >
                                            Quantidade Mínima
                                        </Typography>
                                    </Box>

                                    {isTipoPedidoFuncionario && (
                                        <Box sx={{ opacity: isNumber(produto.produtoEstoque?.qtEstoqueDisponivel) ? 1 : 0.5 }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    gap: 0.5,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        letterSpacing: '-0.03rem',
                                                    }}
                                                >
                                                    {brlDecimal(
                                                        (produto.produtoEstoque?.qtEstoqueDisponivel > 0 &&
                                                            produto.produtoEstoque?.qtEstoqueDisponivel) ||
                                                            0,
                                                        0,
                                                        3,
                                                    )}{' '}
                                                    {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                                </Typography>

                                                <ShoppingCartOutlinedIcon />
                                            </Box>

                                            <Typography
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                Quantidade Disponível
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                }}
                            >
                                <Tooltip
                                    title={
                                        produtoDisabled || !configPedidoValidateQtdMin
                                            ? null
                                            : `Quantidade deve ser múltipla de ${brlDecimal(prodMinQtd || 0, 0, 3)}`
                                    }
                                >
                                    <Box width={110}>
                                        <Typography variant="body2">Quantidade</Typography>

                                        <PedidoCompraAmmount getQtd={getProdListQtd} modifyQtd={modifyProdListQtd} produtoPedido={produtoPedido} />
                                    </Box>
                                </Tooltip>

                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <PedidoCompraAdicionarButton
                                        idProduto={produto.idProduto}
                                        qtMinimaCompra={prodMinQtd}
                                        produtoQtd={produtoQtd}
                                        produtoCart={produtoCart}
                                        produtoDisabled={produtoDisabled}
                                    />
                                </Box>

                                <Box ml="auto" display="flex" flexDirection="column" justifyContent="flex-end">
                                    <Typography variant="body2" align="right">
                                        Valor Total
                                    </Typography>

                                    <PedidoCompraValue value={produtoQtd > 0 ? produtoQtd * produtoPedido.vlPrecoCompra : 0} align="right" />
                                </Box>
                            </Box>
                        </Stack>
                    </React.Fragment>
                </Tooltip>
            </Paper>
        </React.Fragment>
    );
}
