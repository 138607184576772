import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestListagemProdutoCart } from 'pages/compra/pedido/types';

type UseGetConfigPedido = {
    idConfigPedido: number;
    idLoja: number;
};

export function useGetCarrinho({ idConfigPedido, idLoja }: UseGetConfigPedido): UseQueryResult<RequestListagemProdutoCart> {
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/rascunho',
        sort: [{ property: 'idProduto', direction: 'ASC' }],
        filter: [
            { property: 'idLoja', operator: 'eq', value: idLoja },
            { property: 'idConfigPedido', operator: 'eq', value: idConfigPedido },
        ],
    };

    return useQuery<RequestListagemProdutoCart>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: false,
    });
}
