import React from 'react';

import { useParams } from 'react-router-dom';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { PermissoesFormComp, PermissoesFormList } from 'pages/cadastro/permissoes/components';

export function PermissoesForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('GRUPO_PERMISSAO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <PermissoesFormComp />;
        </FormProvider>
    );
}

export function PermissoesList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['GRUPO_PERMISSAO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PermissoesFormList />
        </ListagemProvider>
    );
}
