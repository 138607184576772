import React from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { useFormikContext } from 'formik';

import { TipoPessoaAutoComplete } from 'components/autocompletes';
import { CissMaskField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block } from 'components/page';
import { CepField } from 'pages/cadastro/usuario/components';
import { Revendedor } from 'pages/revendedor/revendedor/types';

type Address = {
    estado: string;
    bairro: string;
    endereco: string;
    municipio: string;
};

export function RevendedorGeneralBlock(): JSX.Element {
    const { id } = useParams();
    const { values, errors, setFieldValue } = useFormikContext<Revendedor>();
    const isPessoaFisica = values.tipo === 'PF';
    const isPessoaJuridica = values.tipo === 'PJ';

    const setAddressData = ({ estado, bairro, endereco, municipio }: Address): void => {
        setFieldValue('estado', estado);
        setFieldValue('bairro', bairro);
        setFieldValue('endereco', endereco);
        setFieldValue('municipio', municipio);
        setFieldValue('complemento', '');
    };

    return (
        <Block>
            <Row>
                <Grid item flex={1}>
                    <CissTextField label="Código" disabled={true} error={errors.idCadastroRevendedor} value={values.idCadastroRevendedor} />
                </Grid>

                <Grid item flex={1}>
                    <TipoPessoaAutoComplete
                        name="tipo"
                        disabled={Boolean(id)}
                        value={values.tipo}
                        error={errors.tipo}
                        onChange={(e, value) => {
                            setFieldValue('tipo', value.tpPessoa);
                            setFieldValue('documento', '');
                        }}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label={isPessoaJuridica ? 'Nome fantasia' : 'Nome completo'}
                        name="nome"
                        error={errors.nome}
                        value={values.nome}
                        onChange={(e) => setFieldValue('nome', e.target.value)}
                    />
                </Grid>
            </Row>

            <Row>
                {isPessoaFisica && (
                    <Grid item flex={1}>
                        <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Data de nascimento"
                                inputFormat="dd/MM/yyyy"
                                value={values.dtNascimento || null}
                                renderInput={(params) => <CissTextField {...params} name="dtNascimento" error={errors.dtNascimento} />}
                                onChange={(value) => setFieldValue('dtNascimento', value)}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}

                {isPessoaJuridica && (
                    <Grid item flex={1}>
                        <CissTextField
                            label="Inscrição estadual"
                            name="ie"
                            error={errors.ie}
                            value={values.ie}
                            onChange={(e) => setFieldValue('ie', e.target.value)}
                        />
                    </Grid>
                )}

                <Grid item flex={1}>
                    <CissMaskField
                        label="Documento"
                        name="documento"
                        format={isPessoaJuridica ? 'cnpj' : 'cpf'}
                        disabled={Boolean(id)}
                        value={values.documento}
                        error={errors.documento}
                        onChange={(e) => setFieldValue('documento', e.target.value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="E-mail"
                        name="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        error={errors.email}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissMaskField
                        label="Telefone"
                        name="telefone"
                        format="phone"
                        value={values.telefone}
                        onChange={(e) => setFieldValue('telefone', e.target.value)}
                        error={errors.telefone}
                    />
                </Grid>
            </Row>

            <Row>
                <Grid item flex={1}>
                    <CepField
                        value={values.cep}
                        error={errors.cep}
                        updateFields={false}
                        onChange={(value) => {
                            setFieldValue('cep', value);

                            setAddressData({ estado: '', bairro: '', municipio: '', endereco: '' });
                        }}
                        onLoad={(data) => {
                            let estado = '',
                                bairro = '',
                                municipio = '',
                                endereco = '';

                            if (Object.keys(data).length) {
                                estado = data.dsEstado;
                                bairro = data.dsBairro;
                                municipio = data.dsCidade;
                                endereco = data.dsEndereco;

                                setFieldValue('cep', data.nrCep);
                            }

                            setAddressData({ estado, bairro, municipio, endereco });
                        }}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="Endereço"
                        name="endereco"
                        value={values.endereco}
                        error={errors.endereco}
                        disabled={!values.municipio}
                        onChange={(e) => setFieldValue('endereco', e.target.value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="Complemento"
                        name="complemento"
                        value={values.complemento}
                        error={errors.complemento}
                        disabled={!values.municipio}
                        onChange={(e) => setFieldValue('complemento', e.target.value)}
                    />
                </Grid>
            </Row>

            <Row>
                <Grid item flex={1}>
                    <CissTextField
                        label="Bairro"
                        name="bairro"
                        value={values.bairro}
                        error={errors.bairro}
                        disabled={!values.municipio}
                        onChange={(e) => setFieldValue('bairro', e.target.value)}
                    />
                </Grid>
                <Grid item flex={1}>
                    <CissTextField disabled label="Estado" name="estado" value={values.estado} error={errors.estado} />
                </Grid>
                <Grid item flex={1}>
                    <CissTextField disabled label="Cidade" name="municipio" value={values.municipio} error={errors.municipio} />
                </Grid>
            </Row>
        </Block>
    );
}
