import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

export const initialStep = 1;

export interface ConfiguracaoPedidoCompraMenuAsideStepProps {
    idConfigPedidoStep: number;
    dsChaveConfigPedidoStep: string;
    dsConfigPedidoStep: string;
    fgSaved: boolean;
    fgDirty: boolean;
    fgDisabled: boolean;
}

export const initialListStep: ConfiguracaoPedidoCompraMenuAsideStepProps[] = [
    {
        idConfigPedidoStep: 1,
        dsConfigPedidoStep: 'Dados Básicos',
        dsChaveConfigPedidoStep: 'dadosBasicos',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: false,
    },
    {
        idConfigPedidoStep: 2,
        dsConfigPedidoStep: 'Condições de Visualização',
        dsChaveConfigPedidoStep: 'condicoesVisualizacao',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: true,
    },
    {
        idConfigPedidoStep: 3,
        dsConfigPedidoStep: 'Regras de Validação',
        dsChaveConfigPedidoStep: 'regrasValidacao',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: true,
    },
    {
        idConfigPedidoStep: 4,
        dsConfigPedidoStep: 'Produtos',
        dsChaveConfigPedidoStep: 'produto',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: true,
    },
    {
        idConfigPedidoStep: 5,
        dsConfigPedidoStep: 'Importar Estoque',
        dsChaveConfigPedidoStep: 'importarEstoque',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: true,
    },
    {
        idConfigPedidoStep: 6,
        dsConfigPedidoStep: 'Quebras Dinâmicas',
        dsChaveConfigPedidoStep: 'quebrasDinamicas',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: true,
    },
    {
        idConfigPedidoStep: 7,
        dsConfigPedidoStep: 'Pedidos Sazonais',
        dsChaveConfigPedidoStep: 'sazonal',
        fgSaved: false,
        fgDirty: false,
        fgDisabled: true
    },
];

export type MenuAsideContextData = {
    activeStep: number;
    setActiveStep: Dispatch<SetStateAction<number>>;
    listStep: ConfiguracaoPedidoCompraMenuAsideStepProps[];
    setListStep: Dispatch<SetStateAction<ConfiguracaoPedidoCompraMenuAsideStepProps[]>>;
    setStepSaved: (id: number) => void;
    setStepDirty: (id: number, dirty: boolean) => void;
    setActiveStepSaved: () => void;
    setActiveStepDirty: (dirty?: boolean) => void;
    isStepSaved: (id: number) => boolean;
    isStepDirty: (id: number) => boolean;
    isActiveStepSaved: () => boolean;
    isActiveStepDirty: () => boolean;
    setStepDisabled: (id: number, disabled: boolean) => void;
};

interface FormProviderProps {
    children?: ReactNode;
}

const FormContext = createContext<MenuAsideContextData>({} as MenuAsideContextData);

export function ConfiguracaoPedidoCompraMenuAsideProvider({ children }: FormProviderProps): JSX.Element {
    const { id } = useParams();
    const [activeStep, setActiveStep] = useState(initialStep);
    const [listStep, setListStep] = useState(initialListStep);

    const getIdsEnabled = useMemo(() => [6, 4, 3, 2, 1], []);

    // Define as steps definidas no método getIdsEnabled como não desabilitadas ao entrar em uma tela salva (com id).
    useEffect(() => {
        if (id) {
            setListStep((list) => list.map((step) => ({ ...step, fgDisabled: !getIdsEnabled.includes(step.idConfigPedidoStep) })));
        }
    }, [id]);

    // Define o valor de fgDisabled da step passada como parâmetro com o valor informado.
    const setStepDisabled = useCallback(
        (id: number, disabled: boolean) => {
            setListStep((list) => list.map((step) => ({ ...step, fgDisabled: id === step.idConfigPedidoStep ? disabled : step.fgDisabled })));
        },
        [],
    );

    // Retorna se a step passada como parâmetro está salva.
    const isStepSaved = useCallback(
        (id: number): boolean => {
            const { fgSaved } = listStep.find((step) => step.idConfigPedidoStep === id) || { fgSaved: false };

            return fgSaved;
        },
        [listStep],
    );

    // Retorna se a step passada como parâmetro está suja.
    const isStepDirty = useCallback(
        (id: number): boolean => {
            const { fgDirty } = listStep.find((step) => step.idConfigPedidoStep === id) || { fgDirty: false };

            return fgDirty;
        },
        [listStep],
    );

    // Retorna se a step atual está salva ou não.
    const isActiveStepSaved = useCallback(() => isStepSaved(activeStep), [activeStep, isStepSaved]);

    // Retorna se a step atual está salva ou não.
    const isActiveStepDirty = useCallback(() => isStepDirty(activeStep), [activeStep, isStepDirty]);

    // Suja a step atual.
    // Preferível utilizar após situações em que os dados da tela são alterados.
    const setStepDirty = useCallback(
        (id: number, dirty: boolean) => {
            setListStep((list) => list.map((step) => ({ ...step, fgDirty: id === step.idConfigPedidoStep ? Boolean(dirty) : step.fgDirty })));
        },
        [],
    );

    // Salva a step com o id passado como parâmetro e a limpa.
    // Preferível utilizar após o save do formulário.
    const setStepSaved = useCallback(
        (id: number) => {
            if (!isStepSaved(id) || isStepDirty(id)) {
                setListStep((list) =>
                    list.map((step) => {
                        return { ...step, fgSaved: id === step.idConfigPedidoStep || step.fgSaved, fgDirty: id === step.idConfigPedidoStep ? false : step.fgDirty };
                    }),
                );
            }
        },
        [isStepDirty, isStepSaved],
    );

    // Define a step atual como suja.
    const setActiveStepDirty = useCallback(
        (dirty?: boolean) => {
            setStepDirty(activeStep, dirty ?? true);
        },
        [activeStep, setStepDirty],
    );

    // Define a step atual como salva.
    const setActiveStepSaved = useCallback(() => setStepSaved(activeStep), [activeStep, setStepSaved]);

    const contextValue = useMemo(
        () => ({
            activeStep,
            setActiveStep,
            listStep,
            setListStep,
            setStepSaved,
            setStepDirty,
            setActiveStepSaved,
            setActiveStepDirty,
            isStepSaved,
            isStepDirty,
            isActiveStepSaved,
            isActiveStepDirty,
            setStepDisabled,
        }),
        [activeStep, isActiveStepDirty, isActiveStepSaved, isStepDirty, isStepSaved, listStep, setActiveStepDirty, setActiveStepSaved, setStepDirty, setStepSaved, setStepDisabled],
    );

    return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
}

export const useConfiguracaoPedidoCompraMenuAsideContext = (): MenuAsideContextData => useContext(FormContext);
