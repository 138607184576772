import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/system';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useDownloadFile } from 'hooks/useDownloadFile';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface PreenchimentoExportarRelatorioBtnProps {
    disabled: boolean;
    filter: FilterValuesProps[];
    label: string;
    sx?: SxProps;
}

export function PreenchimentoExportarRelatorioBtn({ disabled, label, filter, sx }: PreenchimentoExportarRelatorioBtnProps): JSX.Element {
    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/dre/dremercadologica/relatoriopreenchimento/exportar',
            columns: 'idLoja,cdLoja,dsNomeFantasia,dtInicio,dtFim,idDreStatus,dsDreStatus,dsPeriodo',
            filter: filter,
        }),
        [filter],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClickDownload = useCallback((): void => {
        downloadFile({
            newRequestOptions: requestOptions,
            directDownload: true,
        });
    }, [downloadFile, requestOptions]);

    return (
        <LoadingButton loading={isLoadingFile} variant="outlined" onClick={handleClickDownload} disabled={disabled || isLoadingFile} sx={sx} startIcon={<RiFileExcel2Fill />}>
            {label}
        </LoadingButton>
    );
}
