import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ConfiguracaoPedidoAutoComplete } from 'components/autocompletes';
import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { ImportacaoUploadBtn } from 'pages/compra/sugestao/components';

interface ConfiguracaoPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
}

interface SugestaoCompraImportarModal {
    openModalImportar: boolean;
    setOpenModalImportar: Dispatch<SetStateAction<boolean>>;
}

export function SugestaoCompraImportarModal({ openModalImportar, setOpenModalImportar }: SugestaoCompraImportarModal): JSX.Element {
    const { autoLoad, setAutoLoad } = useListagemContext();
    const [configPedidoList, setConfigPedidoList] = useState<ConfiguracaoPedidoProps[]>([]);
    const [fileSugestoes, setFileSugestoes] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const data = new Date();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: () => {
            if (!autoLoad) {
                setAutoLoad(true);
            }

            queryClient.invalidateQueries(['getListSugestoes']);

            enqueueSnackbar('Arquivo de sugestões de compra salvo com sucesso', { variant: 'success' });

            setOpenModalImportar(false);
        },
        onError: (error: any) => showError(error, 'Erro ao salvar arquivo'),
    });

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (fileSugestoes) {
            formData.append('file', fileSugestoes);
            formData.append('ids', configPedidoList.map((config) => config.idConfigPedido).toString());
        }

        mutate({
            url: '/gestao/sugestaocomprawrapper/upload',
            formData,
        });
    }, [configPedidoList, fileSugestoes, mutate]);

    useEffect(() => {
        if (!openModalImportar) {
            setFileSugestoes(null);
            setConfigPedidoList([]);
        }
    }, [openModalImportar]);

    return (
        <ContentModal
            open={openModalImportar}
            setOpen={setOpenModalImportar}
            sx={{
                borderRadius: 2,
                width: {
                    xs: '80%',
                    sm: 'auto',
                },
                maxWidth: '80%',
            }}
            closeButton
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                <SubTitle label="Importar Novo Arquivo de Sugestões" />

                <ConfiguracaoPedidoAutoComplete
                    value={configPedidoList}
                    multiple={true}
                    onChange={(e, value) => setConfigPedidoList(value)}
                    requestOptions={{
                        url: 'gestao/configpedido/loja',
                        limit: 9999,
                        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
                        filter: [
                            { property: 'idConfigPedidoStatus', operator: 'eq', value: 2 },
                            { property: 'dtInicioVigencia', operator: 'lte', value: data },
                            { property: 'dtFimVigencia', operator: 'gte', value: data },
                        ],
                    }}
                />

                <Typography color="error" fontStyle="italic" variant="body2" sx={{ my: 3 }}>
                    <strong>ATENÇÃO!</strong> Ao importar um novo arquivo de sugestão, todas as sugestões existentes na configuração serão substituídas pelas contidas no novo arquivo importado.
                </Typography>

                <Grid container justifyContent="space-between" gap={2}>
                    <Grid item>
                        <ImportacaoUploadBtn setFileSugestoes={setFileSugestoes} fileSugestoes={fileSugestoes} />
                    </Grid>

                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_SUGESTAO_COMPRA" />
                    </Grid>
                </Grid>

                <Stack
                    spacing={2}
                    justifyContent="flex-end"
                    direction="row"
                    sx={{
                        mt: 3,
                    }}
                >
                    <Button variant="outlined" onClick={() => setOpenModalImportar(false)}>
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" onClick={handleSubmit} loading={isLoading} disabled={configPedidoList.length < 1 || !fileSugestoes || isLoading}>
                        Salvar Sugestões
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
