import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface MensagemPadrao {
    idMensagemPadrao: number;
    dsMensagemPadrao: string;
    dsTitulo: string;
}

type MensagemPadraoAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function MensagemPadraoAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => { }, name }: MensagemPadraoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Mensagem Padrão"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsTitulo}"
            optionStringTemplate="{dsTitulo}"
            requestOptions={{
                url: '/gestao/mensagempadrao',
                columns: 'idMensagemPadrao,dsMensagemPadrao,dsTitulo',
            }}
            isOptionEqualToValue={(option: MensagemPadrao, value: MensagemPadrao) => option.idMensagemPadrao === value.idMensagemPadrao}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
