import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Box, Button, Link } from '@mui/material';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

type RequestConfigProps = string;

export function ExtratoRoyaltyInfoBtn(): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/config/configgeral/chave',
            extraParams: [
                {
                    dsChave: 'URL_ARQUIVO_INSTRUCOES_COBRANCA_EXTRATO_ROYALTIES',
                },
            ],
        }),
        [],
    );

    const { data } = useQuery([requestOptions], () => {
        const request: Promise<RequestConfigProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    return (
        <Box hidden={Boolean(!data)}>
            <Button href={data} component={Link} target="_blank">
                Clique aqui e entenda as cobranças
            </Button>
        </Box>
    );
}
