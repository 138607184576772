import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestPercentualNotaFiscalProps } from 'pages/compra/percentual-nota-fiscal/types';
import { brlPercentual } from 'util/format';

export function PercentualNotaFiscalGrid(): JSX.Element {
    const { filter, limit, page, autoLoad, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions = useMemo(
        (): RequestOptionsType => ({
            url: '/gestao/percentualnotalroyaltiessazonalvariacao',
            sort: sort.length ? sort : [{ property: 'idPercentualNotaRoyaltiesSazonalVariacao', direction: 'DESC' }],
            filter,
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(
        ['getPercentualNotaFiscalList', requestOptions],
        (): Promise<RequestPercentualNotaFiscalProps> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: autoLoad,
        },
    );

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(data?.data) && data?.data.length) {
            return data?.data.map((item, index) => ({
                id: index + 1,
                dsTipo: item.dsTipo,
                sgEstado: item.sgEstado,
                peNotaFiscal: item.peNotaFiscal,
                peRoyaltiesPadrao: item.peRoyaltiesPadrao,
                peRoyaltiesMetaAtingida: item.peRoyaltiesMetaAtingida,
            }));
        }

        return [];
    }, [data?.data]);

    const columns = useMemo(
        (): GridColDef[] => [
            {
                field: 'dsTipo',
                headerName: 'Tipo',
                type: 'string',
                minWidth: 130,
                flex: 2,
            },
            {
                field: 'sgEstado',
                headerName: 'UF Destino',
                minWidth: 140,
                flex: 4,
            },
            {
                field: 'peNotaFiscal',
                headerName: 'Desconto Nota Fiscal (%)',
                valueFormatter: ({ value }) => (value ? brlPercentual(value) : ''),
                minWidth: 140,
                flex: 4,
            },
            {
                field: 'peRoyaltiesMetaAtingida',
                headerName: 'Royalties Meta Atingida (%)',
                valueFormatter: ({ value }) => (value ? brlPercentual(value) : ''),
                minWidth: 140,
                flex: 4,
            },
            {
                field: 'peRoyaltiesPadrao',
                headerName: 'Royalties Padrão (%)',
                valueFormatter: ({ value }) => (value ? brlPercentual(value) : ''),
                minWidth: 140,
                flex: 4,
            },
        ],
        [],
    );

    return (
        <Block>
            <PanelGrid rows={rows} rowsCount={data?.total ?? 0} loading={fetchStatus === 'fetching'} columns={columns} reload={refetch} />
        </Block>
    );
}
