import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function UsuarioListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterNome, setFilterNome] = useState<FilterValuesProps>({
        property: 'dsUsuario',
        operator: 'like',
        value: null,
        idProperty: 'dsUsuario',
    });

    const [filterEmail, setFilterEmail] = useState<FilterValuesProps>({
        property: 'dsEmail',
        operator: 'like',
        value: null,
        idProperty: 'dsEmail',
    });

    const [filterLogin, setFilterLogin] = useState<FilterValuesProps>({
        property: 'dsLogin',
        operator: 'like',
        value: null,
        idProperty: 'dsLogin',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterNome, setter: setFilterNome },
        { value: filterEmail, setter: setFilterEmail },
        { value: filterLogin, setter: setFilterLogin },
        { value: filterStatus, setter: setFilterStatus },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField label="Nome" value={filterNome.value} onChange={(e) => setFilterNome((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="E-mail" value={filterEmail.value} onChange={(e) => setFilterEmail((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="Login" value={filterLogin.value} onChange={(e) => setFilterLogin((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>
        </Filter>
    );
}
