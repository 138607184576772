import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { StoreAutoComplete } from 'components/autocompletes';
import { CissTextField, DatePickerMonthYear } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { ClassificacaoAutoComplete } from 'pages/financeiro/detalhamento-creditos/components/atoms/ClassificacaoAutoComplete';

export function DetalhamentoCreditoListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'eq',
        value: null,
        idProperty: 'cdLoja',
        required: 'Loja é obrigatória',
    });

    const [filterClassificacao, setFilterClassificacao] = useState<FilterValuesProps>({
        property: 'idDetalhamentoCreditoDebitoClassificacao',
        operator: 'eq',
        value: null,
        idProperty: 'idDetalhamentoCreditoDebitoClassificacao',
        required: 'Classificação é obrigatória',
    });

    const [filterDsObservacao, setFilterDsObservacao] = useState<FilterValuesProps>({
        property: 'dsObservacao',
        operator: 'like',
        value: '',
    });

    const [filterDtInicioFiltro, setFilterDtInicioFiltro] = useState<FilterValuesProps>({
        property: 'dtInicio',
        operator: 'eq',
        value: null,
        required: 'Selecione o mês/ano para prosseguir.',
    });

    const [filterDtFimFiltro, setFilterDtFimFiltro] = useState<FilterValuesProps>({
        property: 'dtFim',
        operator: 'eq',
        value: null,
        required: 'Selecione o mês/ano para prosseguir.',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterClassificacao, setter: setFilterClassificacao },
        { value: filterDsObservacao, setter: setFilterDsObservacao },
        { value: filterDtInicioFiltro, setter: setFilterDtInicioFiltro },
        { value: filterDtFimFiltro, setter: setFilterDtFimFiltro },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={2}>
                <StoreAutoComplete value={filterLoja.value} onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <ClassificacaoAutoComplete value={filterClassificacao.value} onChange={(e, value) => setFilterClassificacao((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="Descrição" value={filterDsObservacao.value} onChange={(e) => setFilterDsObservacao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <DatePickerMonthYear
                    label="Início competência"
                    minDate={new Date(new Date().getFullYear() - 10, new Date().getMonth(), 1)}
                    maxDate={new Date(new Date().getFullYear(), 11, 1)}
                    value={filterDtInicioFiltro.value}
                    onChange={(value) => setFilterDtInicioFiltro((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            <Grid item flex={1}>
                <DatePickerMonthYear
                    label="Fim competência"
                    minDate={new Date(new Date().getFullYear() - 10, new Date().getMonth(), 1)}
                    maxDate={new Date(new Date().getFullYear(), 11, 1)}
                    value={filterDtFimFiltro.value}
                    onChange={(value) => setFilterDtFimFiltro((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>
        </Filter>
    );
}
