import React from 'react';

import { Typography, Box } from '@mui/material';

interface DreFormBlockTitleProps {
    label: string;
}

export function DreFormBlockTitle({ label }: DreFormBlockTitleProps): JSX.Element {
    return (
        <Box
            id="1"
            sx={{
                display: 'flex',
                py: 1,
                px: 2,
                backgroundColor: 'primary.main',
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                mb: 2,
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{
                    color: 'common.white',
                    textTransform: 'uppercase',
                }}
            >
                {label}
            </Typography>
        </Box>
    );
}
