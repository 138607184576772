import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface SituacaoSugestaoOptionsProps {
    idSugestaoCompraStatus: number;
    dsSugestaoCompraStatus: string;
}

type SituacaoSugestaoAutoCompleteProps = Pick<CissAutoCompleteProps, 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function SituacaoSugestaoAutoComplete({ size, value, error, multiple = false, disabled = false, sx, onChange = () => {} }: SituacaoSugestaoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Situação"
            value={value}
            multiple={multiple}
            error={error}
            requestOptions={{
                url: 'gestao/sugestaocomprastatus',
                sort: [{ property: 'dsSugestaoCompraStatus', direction: 'ASC' }],
            }}
            selectedStringTemplate="{dsSugestaoCompraStatus}"
            optionStringTemplate="{dsSugestaoCompraStatus}"
            isOptionEqualToValue={(option: SituacaoSugestaoOptionsProps, value: SituacaoSugestaoOptionsProps) => option.idSugestaoCompraStatus === value.idSugestaoCompraStatus}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}
