import React from 'react';

import { Chip, SxProps } from '@mui/material';

type PedidoDescontoChip = {
    peOferta: number;
    sx?: SxProps;
};

export function PedidoDescontoChip({ peOferta, sx }: PedidoDescontoChip): JSX.Element {
    return (
        <Chip
            size="small"
            label={`${peOferta}% OFF`}
            sx={{
                position: 'absolute',
                backgroundColor: peOferta >= 70 ? 'error.main' : 'warning.main',
                fontSize: '14px',
                fontWeight: 600,
                color: '#fff',
                top: 4,
                left: 4,
                zIndex: 3,
                ...sx,
            }}
        />
    );
}
