import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useQuery } from '@tanstack/react-query';
import { ptBR } from 'date-fns/locale';

import { CondicaoPagamentoAutoComplete } from 'components/autocompletes/CondicaoPagamentoAutoComplete';
import { CissNumberField, CissTextField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ConfiguracaoPedidoCompraRemoveBtn } from 'pages/compra/configuracao-pedido/components';
import { ProdutoQuebra } from 'pages/compra/configuracao-pedido/types';
import { formatDateHour } from 'util/format';

interface ConfiguracaoPedidoCompraProdutosQuebrasGridProps {
    valueFilter: string;
    handleExcluir: (idList: string[]) => void;
    setSelectedRows: Dispatch<SetStateAction<string[]>>;
}

export function ConfiguracaoPedidoCompraProdutosQuebrasGrid({
    valueFilter,
    handleExcluir,
    setSelectedRows,
}: ConfiguracaoPedidoCompraProdutosQuebrasGridProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { id } = useParams();
    const { content, setContent } = useFormContext();
    const { produtoQuebraList } = content ?? {};
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();

    const requestOptions = useMemo(
        (): RequestOptionsType => ({
            url: `/gestao/configpedidoquebra/configpedido/${id}`,
            sort: [
                { property: 'idConfigPedidoQuebra', direction: 'ASC' },
                { property: 'nrQuebra', direction: 'ASC' },
            ],
        }),
        [id],
    );

    const { fetchStatus } = useQuery([requestOptions], (): Promise<ProdutoQuebra[]> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(id),
        onSuccess: (response) => {
            setContent({ produtoQuebraList: response, initialProdutoQuebraList: response });
        },
    });

    const handleAlterarFields = useCallback(
        (idProduto: number, nrQuebra: number, value: string | number | object, nameField: string): void => {
            const newProdutoQuebraList = produtoQuebraList.map((produtoQuebra: ProdutoQuebra) => {
                if (produtoQuebra.produto?.idProduto === idProduto && produtoQuebra.nrQuebra === nrQuebra) {
                    return { ...produtoQuebra, [nameField]: value };
                }

                return produtoQuebra;
            });

            setContent({ ...content, produtoQuebraList: newProdutoQuebraList });

            setActiveStepDirty();
        },
        [content, produtoQuebraList, setActiveStepDirty, setContent],
    );

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'cdProduto', headerName: 'Código do Produto', minWidth: 120 },
            { field: 'dsProduto', headerName: 'Nome do Produto', width: 150, minWidth: 120 },
            { field: 'qtQuebra', headerName: 'Total de Quebras', minWidth: 80 },
            { field: 'nrQuebra', headerName: 'N.º da Quebra', minWidth: 80 },
            {
                field: 'peQuebra',
                headerName: '%',
                minWidth: 80,
                headerAlign: 'center',
                renderCell: ({ row }) => (
                    <CissNumberField
                        size="small"
                        maxValue={100}
                        forceMaxValue
                        allowNegative={false}
                        value={row.peQuebra}
                        onChange={(e) => handleAlterarFields(row.idProduto, row.nrQuebra, +e.target.value, 'peQuebra')}
                    />
                ),
            },
            {
                field: 'dtInicio',
                headerName: 'Início',
                width: 160,
                minWidth: 140,
                renderCell: ({ row }) => (
                    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <CissTextField {...params} size="small" />}
                            value={row.dtInicio}
                            onChange={(value) => handleAlterarFields(row.idProduto, row.nrQuebra, value, 'dtInicio')}
                        />
                    </LocalizationProvider>
                ),
            },
            {
                field: 'dtFim',
                headerName: 'Fim',
                width: 160,
                minWidth: 140,
                renderCell: ({ row }) => (
                    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat="dd/MM/yyyy"
                            renderInput={(params) => <CissTextField {...params} size="small" />}
                            value={row.dtFim}
                            onChange={(value) => {
                                handleAlterarFields(row.idProduto, row.nrQuebra, value, 'dtFim');
                            }}
                        />
                    </LocalizationProvider>
                ),
            },
            {
                field: 'dsCondicaoPagamento',
                headerName: 'Condição de Pagamento',
                width: 200,
                minWidth: 160,
                renderCell: ({ row }) => (
                    <CondicaoPagamentoAutoComplete
                        label=""
                        size="small"
                        sx={{ width: '100%' }}
                        value={row.condicaoPagamento}
                        onChange={(e, value) => handleAlterarFields(row.idProduto, row.nrQuebra, value, 'condicaoPagamento')}
                    />
                ),
            },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 80,
                renderCell: ({ row }) => <ConfiguracaoPedidoCompraRemoveBtn onClick={() => handleExcluir([`${row.idProduto}_${row.nrQuebra}`])} />,
            },
        ],
        [handleAlterarFields, handleExcluir],
    );

    const filterProdutosQuebra = useCallback(
        () =>
            produtoQuebraList.filter((produtoQuebra: ProdutoQuebra) => {
                const { dsProduto, cdProduto } = produtoQuebra.produto || {};

                return (
                    !valueFilter ||
                    dsProduto?.toLowerCase().includes(valueFilter.trim().toLowerCase()) ||
                    cdProduto?.toString().includes(valueFilter.trim().toLowerCase())
                );
            }),
        [produtoQuebraList, valueFilter],
    );

    const mapProdutosQuebra = useCallback((produtoQuebra: ProdutoQuebra) => {
        return {
            id: `${produtoQuebra.produto?.idProduto}_${produtoQuebra.nrQuebra}_${produtoQuebra.qtQuebra}`,
            idProduto: produtoQuebra.produto?.idProduto,
            cdProduto: produtoQuebra.produto?.cdProduto,
            dsProduto: produtoQuebra.produto?.dsProduto,
            qtQuebra: produtoQuebra.qtQuebra,
            nrQuebra: produtoQuebra.nrQuebra,
            peQuebra: produtoQuebra.peQuebra,
            dtInicio: produtoQuebra.dtInicio && formatDateHour(produtoQuebra.dtInicio),
            dtFim: produtoQuebra.dtFim && formatDateHour(produtoQuebra.dtFim),
            dsCondicaoPagamento: produtoQuebra.condicaoPagamento?.dsCondicaoPagamento,
            idCondicaoPagamento: produtoQuebra.condicaoPagamento?.idCondicaoPagamento,
            fgAtivo: produtoQuebra.fgAtivo ?? true,
            condicaoPagamento: produtoQuebra.condicaoPagamento,
        };
    }, []);

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(produtoQuebraList) && produtoQuebraList.length) {
            return filterProdutosQuebra().map(mapProdutosQuebra);
        }

        return [];
    }, [filterProdutosQuebra, mapProdutosQuebra, produtoQuebraList]);

    return (
        <PanelGrid
            rows={rows}
            rowsCount={rows.length}
            columns={columns}
            loading={fetchStatus === 'fetching'}
            reload={false}
            remote={false}
            checkboxSelection
            onClickRow={(selectionModel) => setSelectedRows(selectionModel as string[])}
        />
    );
}
