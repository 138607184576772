import React from 'react';

import { getIn, useFormikContext } from 'formik';

import { PromocaoAutoComplete } from 'components/autocompletes';
import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { Block } from 'components/page';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { Revendedor } from 'pages/revendedor/revendedor/types';

export function RevendedorAutoCompletesBlock(): JSX.Element {
    const { values, errors, setFieldValue } = useFormikContext<Revendedor>();
    const { tokenInfo } = useGenericAuth();
    const canChangePromocao = tokenInfo?.tpUsuario && ['MASTER', 'SUPORTE_CISS'].includes(tokenInfo?.tpUsuario);

    return (
        <Block sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <CissAutoComplete
                multiple
                label="Lojas"
                name="listLojas"
                objectId="cdLoja"
                optionStringTemplate="{cdLoja} - {dsNomeFantasia}"
                selectedStringTemplate="{cdLoja} - {dsNomeFantasia}"
                value={values.listLojas}
                error={getIn(errors, 'listLojas')}
                onChange={(e, list) => setFieldValue('listLojas', list)}
                isOptionEqualToValue={(option, value) => option.cdLoja === value.cdLoja}
                requestOptions={{
                    url: '/gestao/loja',
                    sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                    columns: 'idLoja,cdLoja,dsNomeFantasia,dsRazaoSocial',
                    limit: 50,
                }}
            />

            {canChangePromocao && (
                <PromocaoAutoComplete
                    multiple
                    name="promocoes"
                    label="Promoções"
                    value={values.promocoes}
                    error={getIn(errors, 'promocoes')}
                    onChange={(e, list) => setFieldValue('promocoes', list)}
                />
            )}
        </Block>
    );
}
