import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { SugestaoCompraListComp } from 'pages/compra/sugestao/components';

export function SugestaoCompraList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_SUGESTAO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <SugestaoCompraListComp />
        </ListagemProvider>
    );
}
