import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { brlPrice } from 'util/format';
import { translateKey } from 'util/i18n';

import { DashedGridTotalizer } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';

export function FppEspelhoNotaProdutoResumoFinanceiro({ nota, titulo }: any): JSX.Element {
    const translateKeyPrefix = 'fpp_espelho_nota_produto_';

    const mercadoria = {
        title: 'Valor de Mercadoria',
        detailItens: [
            {
                label: translateKey(`${translateKeyPrefix}vlTotalBruto`),
                value: brlPrice(nota.vlBruto),
            },
            {
                label: translateKey(`${translateKeyPrefix}vlTotalIcmsSt`),
                value: brlPrice(nota.vlIcmsSt),
            },
            {
                label: translateKey(`${translateKeyPrefix}vlTotalLiquido`),
                value: brlPrice(nota.vlLiquido),
            },
        ],
        docs: [],
    };

    const fpp = {
        title: 'Fundo de Propaganda',
        detailItens: [
            {
                label: translateKey(`${translateKeyPrefix}vlFpp`),
                value: brlPrice(nota.vlFpp),
            },
        ],
        docs: titulo,
    };

    const resumoFinanceiro = [mercadoria, fpp];

    return (
        <Block>
            <SubTitle label="Resumo Financeiro Gerencial do Pedido" />

            <Grid container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                }}
            >
                {resumoFinanceiro.map((resumo) => (
                    <DashedGridTotalizer key={`resumo_${resumo.title}`} list={resumo.detailItens} title={resumo.title} />
                ))}
            </Grid>
        </Block>
    );
}
