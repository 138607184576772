import React from 'react';

import { CentroCustoListComp, CentroCustoFormComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function CentroCustoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_CENTRO_DISTRIBUICAO_GERENCIAR'])) return alertNotPermission();
    
    return (
        <ListagemProvider>
            <CentroCustoListComp />
        </ListagemProvider>
    );
}

export function CentroCustoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_CENTRO_DISTRIBUICAO_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <CentroCustoFormComp />
        </FormProvider>
    );
}
