export interface RequestCentroPedidosProps {
    idCentroPedido: number | null;
    dsCentroPedido: string;
    cdCentroPedido: string;
    fgAtivo: boolean;
}

export const initialCentroPedidosValues: RequestCentroPedidosProps = {
    idCentroPedido: null,
    dsCentroPedido: '',
    cdCentroPedido: '',
    fgAtivo: true,
};
