import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { Divider, Grid, Link, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { brlCep, brlCnpj, brlDate, brlPercentual, brlPrice } from 'util/format';
import { LabeledGrid, TabuledGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { translateKey } from 'util/i18n';
import { Block } from 'components/page';
import { LabeledGridListProps } from 'components/grid/LabeledGrid';
import { SubTitle } from 'components/text';

interface EmpresaCepProps {
    dsCidade: string;
    nrCep: string;
    sgEstado: string;
}

interface EmpresaDataProps {
    dsRazaoSocial?: string;
    dsEndereco: string;
    dsCnpj: string;
    dsBairro?: string;
    dsNomeFantasia?: string;
    cep: EmpresaCepProps;
}

interface EspelhoRoyaltyHeaderProps {
    emitente: EmpresaDataProps;
    list: any;
}

const generateInfo = (key: string, value: string): LabeledGridListProps => {
    return {
        label: translateKey(key),
        value,
    };
};

const generateHeaderLists = (data: EmpresaDataProps | undefined, title: string): LabeledGridListProps[] => {
    const info: LabeledGridListProps[] = [];

    if (data) {
        const { dsNomeFantasia, dsRazaoSocial, dsCnpj, dsEndereco, dsBairro, cep } = data;
        const { sgEstado, nrCep, dsCidade } = cep;
        const name = dsNomeFantasia || dsRazaoSocial;

        info.push(generateInfo(title, name as string));
        info.push(generateInfo('dsCnpj', brlCnpj(dsCnpj)));
        info.push(generateInfo('dsEndereco', dsEndereco + (nrCep ? `, CEP ${brlCep(nrCep)}` : '')));

        if (dsBairro) {
            info.push(generateInfo('dsBairro', dsBairro));
        }

        info.push(generateInfo('dsCidade', dsCidade));
        info.push(generateInfo('sgEstado', sgEstado));
    }

    return info;
};

const getResumoLabel = (key: string): JSX.Element => {
    const tooltips = {
        nrNfse: 'Número da Nota Fiscal de Serviço de Royalties gerado',
        dsRps: 'Número de registro da Nota Fiscal de Serviço de Royalties.',
    };
    const name = translateKey(`espelho_royalty_resumo_${key}`);
    const tooltip = key in tooltips && tooltips[key as keyof typeof tooltips];

    return (
        <React.Fragment>
            {name}
            {tooltip && (
                <Tooltip title={tooltip}>
                    <InfoOutlinedIcon
                        color="primary"
                        sx={{
                            ml: 1,
                            fontSize: '1.25rem',
                            marginTop: '-4px',
                            transform: 'translateY(4px)',
                        }}
                    />
                </Tooltip>
            )}
        </React.Fragment>
    );
};

const generateDetailLists = (data: any | undefined): any => {
    const first: any = [];
    const second: any = [];
    const link: any = [];
    const after: any = [];

    const firstKeys = ['cdLoja', 'nrDocumento', 'nrNfse'];
    const secondKeys = ['dsMesCompetencia', 'dtEmissao', 'dsRps'];
    const linkKeys = ['lkNfse'];
    const afterKeys = ['dsNatureza'];

    const boldKeys = ['nrDocumento', 'dsMesCompetencia'];

    Object.entries(data).forEach(([key, value]) => {
        const info = {
            label: getResumoLabel(key),
            value,
        };

        if (key.startsWith('vl')) {
            info.value = <strong>{brlPrice(info.value as number)}</strong>;
        }

        if (key.startsWith('pe')) {
            info.value = <strong>{brlPercentual(value as string)}</strong>;
        }

        if (key.startsWith('dt')) {
            info.value = <strong>{brlDate(value as string)}</strong>;
        }

        if (boldKeys.includes(key)) {
            info.value = <strong>{info.value as string}</strong>;
        }

        if (key.startsWith('lk')) {
            info.value = (
                <Link href={info.value as string} target="_blank">
                    {info.value as string}
                </Link>
            );
        }

        if (firstKeys.includes(key)) {
            first.push(info);
        } else if (secondKeys.includes(key)) {
            second.push(info);
        } else if (linkKeys.includes(key)) {
            link.push(info);
        } else if (afterKeys.includes(key)) {
            after.push(info);
        }
    });

    return [first, second, link, after];
};

export function EspelhoRoyaltyHeader({ emitente, list }: EspelhoRoyaltyHeaderProps): JSX.Element {
    const [searchParams] = useSearchParams();
    const idLoja = searchParams.get('idLoja');
    const nrDocumento = searchParams.get('nrDocumento');
    const RequestListagem = useRequestListagem();
    const [first, second, link, after] = generateDetailLists(list);

    const requestOptions: RequestOptionsType = {
        url: `/gestao/loja/${idLoja}`,
    };

    const { data: dataDestinatario } = useQuery([requestOptions], () => {
        const request: Promise<any> = RequestListagem(requestOptions).then((res) => res.data.data);
        return request;
    });

    const emitenteData = generateHeaderLists(emitente, 'emitente') ?? [];
    const destinatarioData = generateHeaderLists(dataDestinatario, 'destinatario') ?? [];

    return (
        <Block>
            <SubTitle label={`Documento Contábil de Cobrança Nº ${nrDocumento}`} />

            <LabeledGrid list={emitenteData} />

            <Divider sx={{ my: 2 }} />

            <LabeledGrid list={destinatarioData} />

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={1}>
                <Grid item flex={1}>
                    <TabuledGrid list={first} leftColumnWidth={245} />
                </Grid>

                <Grid item flex={1}>
                    <TabuledGrid list={second} leftColumnWidth={245} />
                </Grid>
            </Grid>

            <Grid item container flex={1} sx={{ gap: 1 }}>
                <TabuledGrid list={link} leftColumnWidth={245} />
                <TabuledGrid list={after} leftColumnWidth={245} />
            </Grid>
        </Block>
    );
}
