import React from 'react';

import { Alert, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { FormFilterBanner } from '..';

import { CardBoxItem, CardBoxSkeleton, ListBoxSkeleton, ListBoxItem, ListBoxItemProps } from 'components/content';
import { OneColumn, Block } from 'components/page/';
import { ListPagination } from 'components/pagination';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useListMode } from 'hooks/useListMode';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestGetBannerModelProps } from 'pages/banner/types';
import { RequestComunicadosStoreProps } from 'pages/comunicado/types';
import { getErrorMessage } from 'util/error';

const generatBannerList = (data: RequestGetBannerModelProps | undefined): ListBoxItemProps[] => {
    const list = data?.data?.data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => {
            return {
                id: item.idBanner,
                label: item.dsBanner,
                active: item.fgAtivo,
                type: item?.categoriaComunicado?.dsCategoriaComunicado,
                date: item.dtInicioVigencia && dateFns.format(new Date(item.dtInicioVigencia), 'dd/MM/yyyy'),
                cover: item.dsLinkArquivo,
            };
        });
    }

    return [];
};

export function BannerListComp(): JSX.Element {
    const { page: currentPage, setPage, filter, limit, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { mode } = useListMode();
    const { hasPermission } = usePermissionsContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const requestOptions: RequestOptionsType = {
        url: '/gestao/banner/lista',
        filter,
        sort: [{ property: 'nrOrdem', direction: 'ASC' }],
        columns: 'idBanner,dsBanner,dsLinkBanner,fgAtivo,fgPermanente,nrOrdem',
        page: currentPage,
        limit,
    };

    const { isLoading, data, fetchStatus, isSuccess, isError, error, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestComunicadosStoreProps> = RequestListagem(requestOptions);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const bannerList = generatBannerList(data);
    const [bannerError] = getErrorMessage(error);

    return (
        <OneColumn title="Banner" createButton={true} listType={!isMobile}>
            <FormFilterBanner />

            {isError && bannerError && <Alert severity="error">{bannerError}</Alert>}

            {!bannerList.length && isSuccess && <Alert severity="warning">Sem registros para exibir.</Alert>}

            <Block filled={false} sx={{ p: 0 }}>
                {(mode === 'grid' || isMobile) && (
                    <Grid container spacing={2}>
                        {isLoading &&
                            fetchStatus === 'fetching' &&
                            Array.from(new Array(limit)).map(() => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={Math.random()}>
                                    <CardBoxSkeleton />
                                </Grid>
                            ))}
                        {Array.isArray(bannerList) &&
                            bannerList.map((banner) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={banner.id}>
                                    <CardBoxItem
                                        DontShowDetail={true}
                                        showLike={false}
                                        showView={false}
                                        refresh={refetch}
                                        removeUrl={`gestao/banner/${banner.id}`}
                                        showEditBtn={hasPermission(['BANNER_ALTERAR'])}
                                        showRemoveBtn={hasPermission(['BANNER_EXCLUIR'])}
                                        {...banner}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                )}

                {mode === 'list' && !isMobile && (
                    <Stack spacing={2}>
                        {isLoading && fetchStatus === 'fetching' && Array.from(new Array(limit)).map(() => <ListBoxSkeleton key={Math.random()} />)}
                        {Array.isArray(bannerList) &&
                            bannerList.map((banner) => (
                                <ListBoxItem
                                    DontShowDetail={true}
                                    showLike={false}
                                    showView={false}
                                    refresh={refetch}
                                    removeUrl={`gestao/banner/${banner.id}`}
                                    key={banner.id}
                                    showEditBtn={hasPermission(['BANNER_ALTERAR'])}
                                    showRemoveBtn={hasPermission(['BANNER_EXCLUIR'])}
                                    {...banner}
                                />
                            ))}
                    </Stack>
                )}
            </Block>

            <ListPagination data={data} list={bannerList} limit={limit} page={currentPage} setPage={setPage} />
        </OneColumn>
    );
}
