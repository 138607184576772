import React from 'react';

import { Centered } from 'components/grid';

interface CustomNoRowsOverlayProps {
    started: boolean;
}

export function CustomNoRowsOverlay({ started }: CustomNoRowsOverlayProps): JSX.Element {
    return <Centered>{started ? 'Sem registros para exibir' : 'Utilize o filtro para visualizar os dados'}</Centered>;
}
