import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface SituacaoSugestaoOptionsProps {
    idConfigPedidoStatus: number;
    dsConfigPedidoStatus: string;
}


const SituacaoSugestaoOptions: SituacaoSugestaoOptionsProps[] = [
    {
        idConfigPedidoStatus: 1,
        dsConfigPedidoStatus: 'Incompleto',
    },
    {
        idConfigPedidoStatus: 2,
        dsConfigPedidoStatus: 'Completo',
    },
];

type SituacaoConfiguracaoPedidoAutoCompleteProps = Pick<CissAutoCompleteProps, 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function SituacaoConfiguracaoPedidoAutoComplete({ size, value, error, multiple = false, disabled = false, sx, onChange = () => {} }: SituacaoConfiguracaoPedidoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Situação"
            value={value}
            multiple={multiple}
            error={error}
            options={SituacaoSugestaoOptions}
            selectedStringTemplate="{dsConfigPedidoStatus}"
            optionStringTemplate="{dsConfigPedidoStatus}"
            isOptionEqualToValue={(option: SituacaoSugestaoOptionsProps, value: SituacaoSugestaoOptionsProps) => option.idConfigPedidoStatus === value.idConfigPedidoStatus}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}