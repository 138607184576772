import React from 'react';

import { Box, Typography } from '@mui/material';

import { PedidoTotalizadorSincronizacaoStatus, getColor, SincronizacaoStatus } from 'pages/compra/pedido-totalizador/types';

interface PedidoCompraTotalizadorBoxIntegracaoStatus {
    statusIntegracao: PedidoTotalizadorSincronizacaoStatus;
}

export function PedidoCompraTotalizadorBoxIntegracaoStatus({ statusIntegracao }: PedidoCompraTotalizadorBoxIntegracaoStatus): JSX.Element {
    return (
        <Box>
            <Typography sx={{ fontSize: '0.8rem', textAlign: 'right' }}>Status da integração</Typography>

            <Typography
                sx={{
                    lineHeight: 1,
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    textAlign: 'right',
                    color: (theme) => theme.palette[getColor[SincronizacaoStatus[statusIntegracao.idPedidoTotalizadorSincronizacaoStatus] as keyof typeof SincronizacaoStatus]].main,
                }}
            >
                {statusIntegracao.dsPedidoTotalizadorSincronizacaoStatus}
            </Typography>
        </Box>
    );
}
