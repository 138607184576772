import React from 'react';

import { Alert, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Responsive } from 'components/content';
import { Centered } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useRequestListagem } from 'hooks/useRequestListagem';

export function RecebiveisComp(): JSX.Element | null {
    const { loja } = useAplicacaoDataContext();
    const requestListagem = useRequestListagem();

    const { data: link, isFetching } = useQuery<string>(
        ['getLinkFinanceiroRecebiveis'],
        () =>
            requestListagem({
                url: '/config/configgeral/chave',
                extraParams: [{ dsChave: 'URL_FINANCEIRO_RECEBIVEIS' }],
            }).then((res) => res.data),
    );

    return (
        <OneColumn title="Recebíveis E-commerce">
            <Block>
                {isFetching && (
                    <Centered>
                        <CircularProgress />
                    </Centered>
                )}

                {!isFetching && link && (
                    <Responsive height={1820}>
                        <iframe src={`${link}${loja?.cdLoja}`} allowFullScreen />
                    </Responsive>
                )}

                {!isFetching && !link && <Alert severity="error">Falha ao exibir os dados</Alert>}
            </Block>
        </OneColumn>
    );
}
