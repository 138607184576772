import React, { useRef, useState } from 'react';

import { OneColumn } from 'components/page';
import { PedidoLoteCompraErrosModal, PedidoLoteCompraGrid, PedidoLoteCompraGridFilter, PedidoLoteCompraImportarModal } from 'pages/compra/pedido-lote/components';

export function PedidoLoteCompraListComp(): JSX.Element {
	const [openModalImportar, setOpenModalImportar] = useState(false);
	const [openModalErros, setOpenModalErros] = useState(false);
	const [idPedidoImportacaoLoja, setIdPedidoImportacaoLoja] = useState<number>();

	return (
        <React.Fragment>
            <PedidoLoteCompraImportarModal open={openModalImportar} setOpen={setOpenModalImportar} />

            <PedidoLoteCompraErrosModal open={openModalErros} setOpen={setOpenModalErros} idPedidoImportacaoLoja={idPedidoImportacaoLoja}/>

            <OneColumn
                title="Pedidos em Lote"
                extraButtons={[
                    {
                        title: 'Importar',
                        onClick: () => setOpenModalImportar(true),
                    },
                ]}
            >
                <PedidoLoteCompraGridFilter />

                <PedidoLoteCompraGrid setOpenModalErros={setOpenModalErros} setIdPedidoImportacaoLoja={setIdPedidoImportacaoLoja}/>
            </OneColumn>
        </React.Fragment>
    );
};