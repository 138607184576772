import React, { useState } from 'react';

import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';

import { LoginForm } from '..';

import { ConfirmLogoutModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useGenericAuth } from 'hooks/useGenericAuth';

export function LoginModal(): JSX.Element {
    const { openLoginModal } = useGenericAuth();
    const [isOpenDialogLogout, setIsOpenDialogLogout] = useState(false);

    return (
        <Modal
            open={openLoginModal}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'common.black',
                },
            }}
        >
            <Grid
                container
                sx={{
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: { xs: 'start', md: 'center' },
                }}
            >
                <Box
                    component={Paper}
                    elevation={6}
                    sx={{
                        maxWidth: 450,
                        px: {
                            xs: 2,
                            md: 5,
                        },
                        pt: {
                            xs: 2,
                            md: 3,
                        },
                        pb: {
                            xs: 2,
                            md: 5,
                        },
                        mt: {
                            xs: 3,
                            md: 0,
                        },
                    }}
                >
                    <SubTitle label="Sessão expirada" />

                    <Typography sx={{ mb: 4 }}>Efetue seu login para continuar</Typography>

                    <LoginForm />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={() => setIsOpenDialogLogout(true)}>Sair</Button>
                    </Box>

                    <ConfirmLogoutModal open={isOpenDialogLogout} setOpen={setIsOpenDialogLogout} />
                </Box>
            </Grid>
        </Modal>
    );
}
