import { useBlocker } from 'hooks/useBlocker';
import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';

export type PageDefaultNavigationType = {
    blockNavigation: boolean;
    setBlockNavigation: Dispatch<SetStateAction<boolean>>;
    showConfirmModal: boolean;
    onCancel: (closeModal: boolean) => void;
    onConfirm: () => void;
};

interface PageDefaultNavigationProps {
    children?: ReactNode;
}

const PageDefault = createContext<PageDefaultNavigationType>({} as PageDefaultNavigationType);

export const usePageDefaultNavigationContext = (): PageDefaultNavigationType => useContext(PageDefault);

export function PageDefaultNavigationProvider({ children }: PageDefaultNavigationProps): JSX.Element {
    const [blockNavigation, setBlockNavigation] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const onConfirm = useBlocker(blockNavigation, setShowConfirmModal);

    const onCancel = useCallback(
        (closeModal: boolean): void => {
            setShowConfirmModal(closeModal);
        },
        [setShowConfirmModal],
    );

    const contextValue = useMemo(
        () => ({
            blockNavigation,
            onCancel,
            onConfirm,
            setBlockNavigation,
            showConfirmModal,
        }),
        [setShowConfirmModal, blockNavigation, setBlockNavigation, showConfirmModal, onCancel, onConfirm],
    );

    return <PageDefault.Provider value={contextValue}>{children}</PageDefault.Provider>;
}
