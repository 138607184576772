import React from 'react';
import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface StatusSolicitacaoPecasOptionsProps {
    idStatus: number;
    dsStatus: string;
}

type StatusSolicitacaoPecaAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'> & { idStatus?: number };

export function StatusSolicitacaoPecaAutoComplete({ value, error, multiple = false, disabled = false, sx, idStatus = 0, onChange = () => {} }: StatusSolicitacaoPecaAutoCompleteProps): JSX.Element {
    let url = '/gestao/solicitacaopecastatus';
    if (idStatus) {
        url = `/gestao/solicitacaopecastatus/statuspermitidos/${idStatus}`;
    }

    return (
        <CissAutoComplete
            label="Próxima ação:"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsStatus}"
            optionStringTemplate="{dsStatus}"
            requestOptions={{
                url,
                sort: [{ property: 'idSolicitacaoPecaStatus', direction: 'ASC' }],
                columns: 'idSolicitacaoPecaStatus,dsStatus',
                limit: 50,
            }}
            isOptionEqualToValue={(option: StatusSolicitacaoPecasOptionsProps, value: StatusSolicitacaoPecasOptionsProps) => option && value && option.dsStatus === value.dsStatus}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
        />
    );
}
