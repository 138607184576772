import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { ContentModal } from 'components/modal';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { useFormMutation } from 'mutations/useFormMutation';
import { CarrinhoCallbackModal } from 'pages/compra/carrinho/components';
import { CallbackInfo, RequestPedidoProps } from 'pages/compra/carrinho/types';

type CarrinhoFinalizarAllModal = {
    open: boolean;
    setOpen: (action: boolean) => void;
};

export function CarrinhoFinalizarAllModal({ open, setOpen }: CarrinhoFinalizarAllModal): JSX.Element {
    const [isOpenCallbackModal, setIsOpenCallbackModal] = useState<boolean>(false);
    const [callbackInfo, setCallbackInfo] = useState<CallbackInfo | null>(null);
    const { fullFilterData } = useListagemContext();
    const { mutateAsync } = useFormMutation();
    const { content, setContent } = useFormContext();
    const queryClient = useQueryClient();
    const { hasPermission } = usePermissionsContext();
    const { usuario } = useAplicacaoDataContext();
    const { tipoPedido } = useGetDomainConfig();

    const handleConfirm = (): void => {
        const { data: pedidoList } = queryClient.getQueriesData(['getListRascunhos'])[0][1] as RequestPedidoProps;
        const filterFuncionario = fullFilterData?.find((filter) => filter.value.property === 'idUsuarioPedido');
        const isUsuarioPedidoLogged = filterFuncionario?.value?.value?.idUsuario === usuario?.idUsuario;
        const isTipoPedidoPadrao = tipoPedido === 'padrao';
        const pedidoListMutate = pedidoList.map((rascunho) =>
            mutateAsync({ url: `/compra/pedidocompra/rascunho/${rascunho.idPedido}/finalizar`, preventSnack: true }),
        );

        setContent({ ...content, finalizarAll: true });

        Promise.allSettled(pedidoListMutate).then((values) => {
            const filterLoja = fullFilterData?.find((filter) => filter.value.property === 'idLoja');
            const loja = filterLoja?.value.value[0] || undefined;
            const hasRejected = values.some((value) => value.status === 'rejected');
            const title = hasRejected ? 'Falha ao finalizar pedidos' : 'Pedidos finalizados com sucesso!';
            const message = hasRejected
                ? 'Alguns pedidos não foram finalizados com sucesso. Verifique o erro clicando em "Falha ao Finalizar" em cada pedido.'
                : '';
            const showConfirm =
                !hasRejected &&
                loja &&
                (isTipoPedidoPadrao || isUsuarioPedidoLogged || (!isUsuarioPedidoLogged && hasPermission(['COMPRA_PEDIDO_ACESSO_GERAL'])));
            const confirm = showConfirm ? 'Acompanhar Pedidos' : '';

            setContent({ ...content, finalizarAll: false });
            setOpen(false);
            setIsOpenCallbackModal(true);
            setCallbackInfo({ title, message, showConfirm, confirm, loja });
        });
    };

    return (
        <React.Fragment>
            <CarrinhoCallbackModal open={isOpenCallbackModal} setOpen={setIsOpenCallbackModal} callbackInfo={callbackInfo} />

            <ContentModal open={open} setOpen={setOpen} closeButton sx={{ borderRadius: 1, minWidth: { xs: 320, md: 600 } }}>
                <Stack gap={2}>
                    <Typography variant="h6">Finalizar todos os pedidos</Typography>

                    <Typography>Tem certeza que deseja finalizar todos os pedidos?</Typography>

                    <Stack spacing={2} justifyContent="flex-end" direction="row">
                        <Button variant="outlined" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>

                        <LoadingButton variant="contained" onClick={handleConfirm} loading={content?.finalizarAll}>
                            Finalizar Pedidos
                        </LoadingButton>
                    </Stack>
                </Stack>
            </ContentModal>
        </React.Fragment>
    );
}
