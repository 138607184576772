import React, { useCallback, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { UploadButton } from 'components/upload';
import { useFormContext } from 'context/FormContext';
import { useShowError } from 'hooks/useShowError';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';

export interface ImportacaoUploadBtnProps {
    url: string;
    contentListName?: string;
    onSend?: () => void;
    onSuccessUploadFile?: (data: any) => void;
}

export function ConfiguracaoPedidoCompraImportacaoUploadBtn({ url, contentListName, onSend = () => {}, onSuccessUploadFile }: ImportacaoUploadBtnProps): JSX.Element {
    const [file, setFile] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { content, setContent } = useFormContext();
    const { id } = useParams();

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: (response: any) => {
            const { data } = response;

            enqueueSnackbar('Arquivo de importação enviado com sucesso.', { variant: 'success' });

            if (typeof onSuccessUploadFile === 'function') {
                onSuccessUploadFile(data);
            } else if (contentListName) {
                setContent({ ...content, [contentListName]: data });
            }

            if (inputFileRef.current) {
                inputFileRef.current.value = '';
                setFile(null);
            }
        },
        onError: (error) => showError(error, 'Erro ao enviar arquivo.'),
    });

    const handleSubmit = useCallback(() => {
        const formData = new FormData();

        if (file) {
            formData.append('file', file);
            formData.append('idConfigPedido', String(id));

            mutate({ formData, url });
        }

        onSend();
    }, [file, id, mutate, onSend, url]);

    const handlerFileChange = (selectedFile: File): void => {
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    return (
        <React.Fragment>
            <Grid container sx={{ gap: 2, alignItems: 'flex-start' }}>
                <Grid item>
                    <UploadButton label="Selecionar Arquivo" disabled={isLoading} onChange={(file) => file && handlerFileChange(file)} accept=".csv" inputRef={inputFileRef} />

                    {file && (
                        <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                            {file.name}
                        </Typography>
                    )}
                </Grid>

                <LoadingButton variant="contained" loading={isLoading} disabled={!file} onClick={handleSubmit} sx={{ maxWidth: 150 }}>
                    Enviar
                </LoadingButton>
            </Grid>

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                *São aceitos somente arquivos em formato .csv e .xlsx
            </Typography>
        </React.Fragment>
    );
}
