import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Grid } from '@mui/material';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { FileTypeField, CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export const FormFilterArquivo = forwardRef((props, ref) => {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsTitulo',
        operator: 'like',
        logicalOperator: 'OR',
        value: '',
    });

    const [filterResumo, setFilterResumo] = useState<FilterValuesProps>({
        property: 'dsResumo',
        operator: 'like',
        logicalOperator: 'OR',
        value: '',
    });

    const [filterTags, setFilterTags] = useState<FilterValuesProps>({
        property: 'idTagCatalogoArquivo',
        operator: 'eq',
        value: null,
        idProperty: 'idTagCatalogoArquivo',
    });

    const [filterCategoria, setFilterCategoria] = useState<FilterValuesProps>({
        property: 'idCategoriaArquivo',
        operator: 'in',
        value: [],
    });

    const [filterFileType, setFilterFileType] = useState<FilterValuesProps>({
        property: 'dsExtensao',
        operator: 'eq',
        value: null,
        idProperty: 'dsTipoArquivoValue',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: true,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterResumo, setter: setFilterResumo },
        { value: filterTags, setter: setFilterTags },
        { value: filterCategoria, setter: setFilterCategoria },
        { value: filterFileType, setter: setFilterFileType },
        { value: filterStatus, setter: setFilterStatus },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useImperativeHandle(ref, () => ({ setFilterCategoria, filterCategoria }), [filterCategoria]);

    useEffect(() => {
        startFilter();
    }, [filterCategoria]);

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={hasPermission(['ARQUIVO_MODERAR']) ? 6 : 3}>
                <CissTextField label="Buscar por nome" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={hasPermission(['ARQUIVO_MODERAR']) ? 6 : 3}>
                <CissTextField label="Buscar por descrição" value={filterResumo.value} onChange={(e) => setFilterResumo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={hasPermission(['ARQUIVO_MODERAR']) ? 4 : 4}>
                <CissAutoComplete
                    label="Tags"
                    value={filterTags.value}
                    onChange={(e, value) => setFilterTags((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    isOptionEqualToValue={(option, value) => option.idTagCatalogoArquivo === value.idTagCatalogoArquivo}
                    selectedStringTemplate="{idTagCatalogoArquivo} - {dsTagCatalogoArquivo}"
                    optionStringTemplate="<strong>{idTagCatalogoArquivo}</strong> - {dsTagCatalogoArquivo}"
                    multiple={false}
                    requestOptions={{
                        url: '/gestao/tagcatalogoarquivo',
                        columns: 'idTagCatalogoArquivo,dsTagCatalogoArquivo',
                    }}
                />
            </Grid>

            <Grid item lg={hasPermission(['ARQUIVO_MODERAR']) ? 4 : 2}>
                <FileTypeField
                    value={filterFileType.value}
                    onChange={(e, value) => setFilterFileType((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>

            {hasPermission(['ARQUIVO_MODERAR']) && (
                <Grid item lg={4}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
});
