import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Grid, Typography, Stack, Alert, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { getTypeMarketing, MarketingTradePage, RequestCampanhaMarketingStoreProps } from '../../types';

import { LikeBtn, ViewBtn } from 'components/buttons';
import { CardCarousel } from 'components/carousel';
import { CardCarouselItemType } from 'components/carousel/CardCarousel';
import { RichContent, DownloadBox } from 'components/content';
import { TableZebra } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { SubTitle, Tag, TagGroup } from 'components/text';
import { useLikesContext } from 'context/LikeContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { useFormMutation } from 'mutations/useFormMutation';
import { MarketingTradePainelPecas } from 'pages/marketing-trade/components';
import { filterEditableFiles } from 'pages/marketing-trade/util/file';
import { getErrorMessage } from 'util/error';

/**
 * Página detalhamento Makerting com 1 coluna (sem aside) e texto
 */
export function MarketingTradeDetailComp({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const [views, setViews] = useState<number>();
    const [validType, setValidType] = useState(true);
    const { validRouterParams } = useValidation();
    const { id } = useParams();
    const hasId = typeof id !== 'undefined';
    const validId = hasId ? validRouterParams({ id }) : true;
    const likesContext = useLikesContext();
    const { mutate } = useFormMutation();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: `/gestao/pecamarketing/${id}`,
    };

    const postView = (content: any): void => {
        const { idPecaMarketing, qtVisualizacao = 0 } = content ?? {};

        if (idPecaMarketing) {
            mutate(
                { url: `/gestao/pecamarketingvisualizacao/add?idPecaMarketing=${idPecaMarketing}`, preventSnack: true },
                {
                    onSuccess: () => setViews(qtVisualizacao + 1),
                },
            );
        }
    };

    const { isLoading, fetchStatus, data, isError, error } = useQuery([requestOptions], (): Promise<RequestCampanhaMarketingStoreProps> => RequestListagem(requestOptions), {
        onSuccess: ({ data }) => {
            if (data) {
                const detail = data.data;

                if (detail.tipoMarketing?.idTipoMarketing !== getTypeMarketing(isMarketing)) {
                    setValidType(false);
                }

                postView(detail);

                likesContext.dispachLike({
                    type: 'start',
                    payload: {
                        likes: detail.qtCurtida,
                        liked: detail.fgUsuarioCurtiu,
                    },
                });
            }
        },
    });

    const status = data?.status;
    const content = data?.data?.data;
    const [requestError] = validId ? getErrorMessage(error) : ['Verifique se a URL informada é valida.'];

    const imagesFromFaca: CardCarouselItemType[] =
        content?.imagensFaca?.filter(filterEditableFiles).map((imagensFaca) => ({
            id: imagensFaca.arquivo.idArquivo,
            url: imagensFaca.arquivo.dsLink,
        })) || [];

    const imagesFromMockup: CardCarouselItemType[] =
        content?.imagensMockup?.filter(filterEditableFiles).map((imagensMockup) => ({
            id: imagensMockup.arquivo.idArquivo,
            url: imagensMockup.arquivo.dsLink,
        })) || [];

    const imagesFromArquivos: CardCarouselItemType[] =
        content?.arquivosDownload?.filter(filterEditableFiles).map((arquivosDownload) => ({
            id: Number(arquivosDownload.arquivo.idArquivo),
            url: arquivosDownload.arquivo.dsLink,
        })) || [];

    const previewPhotos = imagesFromArquivos.concat(imagesFromMockup, imagesFromFaca);

    const qtPreviewPhotos = previewPhotos.length;

    return (
        <OneColumn title={content?.dsPecaMarketing || ''} goBackButton>
            {(isError || !validId) && <Alert severity="error">{requestError}</Alert>}
            {status === 204 && <Alert severity="warning">Nenhum registro encontrado com o id {id}</Alert>}

            {validId && (
                <React.Fragment>
                    {isLoading && fetchStatus === 'fetching' && (
                        <React.Fragment>
                            <Skeleton variant="rectangular" height={80} />
                            <Skeleton variant="rectangular" height={410} sx={{ mt: 3 }} />
                            <Skeleton variant="rectangular" height={90} sx={{ mt: 3 }} />
                        </React.Fragment>
                    )}

                    {!isLoading && content && !validType && <Alert severity="warning">Não é possivel editar/visualizar este item no módulo {isMarketing ? 'MARKETING' : 'TRADE'}.</Alert>}

                    {!isLoading && content && validType && (
                        <React.Fragment>
                            {!content.fgAtivo && (
                                <Alert severity="warning">
                                    Atenção registro <strong>inativo</strong>
                                </Alert>
                            )}

                            <Block>
                                <Grid
                                    container
                                    spacing={{
                                        xs: 0,
                                        md: 2,
                                    }}
                                    rowSpacing={{
                                        xs: 2,
                                        md: 0,
                                    }}
                                    sx={{
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row',
                                        },
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {content.campanhaMarketing && (
                                        <Grid item sx={{ flex: 1, maxWidth: 250 }}>
                                            <Typography sx={{ color: 'primary.main' }}>Campanha</Typography>
                                            <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                <Typography noWrap>{content.campanhaMarketing.dsCampanhaMarketing}</Typography>
                                            </Typography>
                                        </Grid>
                                    )}

                                    {content.pecaMarketingFormato && (
                                        <Grid item sx={{ flex: 1, maxWidth: 160 }}>
                                            <Typography sx={{ color: 'primary.main' }}>Formato</Typography>
                                            <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                <Typography>{content.pecaMarketingFormato.dsPecaMarketingFormato}</Typography>
                                            </Typography>
                                        </Grid>
                                    )}

                                    {content.dhInclusao && (
                                        <Grid item sx={{ flex: 1, maxWidth: 160 }}>
                                            <Typography sx={{ color: 'primary.main' }}>Publicado em</Typography>
                                            <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                {dateFns.format(new Date(content.dhInclusao), 'dd/MM/yyyy')}
                                            </Typography>
                                        </Grid>
                                    )}

                                    {isMarketing
                                        ? Array.isArray(content.pecaMarketingTagsPecaMarketing) &&
                                          Boolean(content.pecaMarketingTagsPecaMarketing.length) && (
                                              <Grid item>
                                                  <Typography sx={{ color: 'primary.main' }}>Hashtags</Typography>
                                                  <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                      <TagGroup max={3}>
                                                          {content.pecaMarketingTagsPecaMarketing.map((tag) => (
                                                              <Tag label={tag.dsTagPecaMarketing} key={tag.idTagPecaMarketing} />
                                                          ))}
                                                      </TagGroup>
                                                  </Typography>
                                              </Grid>
                                          )
                                        : Array.isArray(content.pecaTradeTagsPecaTrade) &&
                                          Boolean(content.pecaTradeTagsPecaTrade.length) && (
                                              <Grid item>
                                                  <Typography sx={{ color: 'primary.main' }}>Hashtags</Typography>
                                                  <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                      <TagGroup max={3}>
                                                          {content.pecaTradeTagsPecaTrade.map((tag) => (
                                                              <Tag label={tag.dsTagPecaTrade} key={tag.idTagPecaTrade} />
                                                          ))}
                                                      </TagGroup>
                                                  </Typography>
                                              </Grid>
                                          )}

                                    <Grid item sx={{ flex: 1, maxWidth: 175 }}>
                                        <Typography sx={{ color: 'primary.main' }}>Curtidas e visualizações</Typography>
                                        <Box sx={{ display: 'flex' }}>
                                            <LikeBtn context={likesContext} likeUrl={`/gestao/pecamarketingcurtida/like?idPecaMarketing=${content.idPecaMarketing}`} />
                                            <ViewBtn views={views} sx={{ mr: 0 }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Block>

                            {(content.dsConteudo || (Boolean(qtPreviewPhotos) && !content.fgEditavel)) && (
                                <Block>
                                    <Grid
                                        container
                                        spacing={{
                                            xs: 0,
                                            md: 2,
                                        }}
                                        rowSpacing={{
                                            xs: 2,
                                            md: 0,
                                        }}
                                        sx={{
                                            flexDirection: {
                                                xs: 'column',
                                                md: 'row',
                                            },
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {Boolean(qtPreviewPhotos) && !content.fgEditavel && (
                                            <Grid item sx={{ flex: 1, minWidth: 300 }}>
                                                <SubTitle label="Pré-visualização" />
                                                <CardCarousel navButtonsAlwaysInvisible={qtPreviewPhotos < 2} indicators={qtPreviewPhotos > 2} cardList={previewPhotos} />
                                            </Grid>
                                        )}

                                        {content.dsConteudo && (
                                            <Grid item sx={{ flex: 1, minWidth: 305 }}>
                                                <SubTitle label="Descrição" />
                                                <Grid item>
                                                    <RichContent dangerouslySetInnerHTML={{ __html: content.dsConteudo }} />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Block>
                            )}

                            {content.fgEditavel && (
                                <React.Fragment>
                                    {content.arquivosDownload?.filter(filterEditableFiles).map((arquivoDownload) => (
                                        <MarketingTradePainelPecas key={arquivoDownload.idPecaMarketingArquivo} arquivoDownload={arquivoDownload} formatoImagem={content.pecaMarketingFormato} />
                                    ))}
                                </React.Fragment>
                            )}

                            {isMarketing ? null : (
                                <React.Fragment>
                                    {Boolean(content.pecaMarketingFormato?.idPecaMarketingFormato !== 2) && (
                                        <Block>
                                            <SubTitle label="Informações adicionais" />
                                            <Box sx={{ display: 'flex', gap: 2 }}>
                                                <TableZebra
                                                    rows={[
                                                        {
                                                            key: 'tipoLamina',
                                                            name: 'Tipo Lâmina',
                                                            value: content.dsTipoLamina || '--',
                                                        },
                                                        {
                                                            key: 'papel',
                                                            name: 'Papel',
                                                            value: content.dsPapel || '--',
                                                        },
                                                        {
                                                            key: 'gramatura',
                                                            name: 'Gramatura',
                                                            value: content.dsGramatura || '--',
                                                        },

                                                        {
                                                            key: 'formatoTrabalhoAberto',
                                                            name: 'Formato de trabalho aberto',
                                                            value: content.dsFormatoAberto || '--',
                                                        },
                                                    ]}
                                                />
                                                <TableZebra
                                                    rows={[
                                                        {
                                                            key: 'formatoTRabalhoFechado',
                                                            name: 'Formato de trabalho fechado',
                                                            value: content.dsFormatoFechado || '--',
                                                        },
                                                        {
                                                            key: 'cores',
                                                            name: 'Cores',
                                                            value: content.dsCores || '--',
                                                        },
                                                        {
                                                            key: 'paginas',
                                                            name: 'Páginas',
                                                            value: content.nrPagina || '--',
                                                        },

                                                        {
                                                            key: 'acabamento',
                                                            name: 'Acabamento',
                                                            value: content.dsAcabamento || '--',
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        </Block>
                                    )}

                                    {Array.isArray(content.imagensFaca) && Boolean(content.imagensFaca.length) && (
                                        <Block>
                                            <SubTitle label="Imagem Faca" />

                                            <Stack spacing={1}>
                                                {content.imagensFaca.map((imagensFaca) => (
                                                    <DownloadBox
                                                        image={imagensFaca.arquivo.dsLinkThumbnail}
                                                        link={imagensFaca.arquivo.dsLink}
                                                        label={imagensFaca.arquivo.dsTitulo}
                                                        key={imagensFaca.idPecaMarketingArquivo}
                                                    />
                                                ))}
                                            </Stack>
                                        </Block>
                                    )}

                                    {Array.isArray(content.imagensMockup) && Boolean(content.imagensMockup.length) && (
                                        <Block>
                                            <SubTitle label="Imagem Mockup (modelo)" />

                                            <Stack spacing={1}>
                                                {content.imagensMockup.map((imagensMockup) => (
                                                    <DownloadBox
                                                        image={imagensMockup.arquivo.dsLinkThumbnail}
                                                        link={imagensMockup.arquivo.dsLink}
                                                        label={imagensMockup.arquivo.dsTitulo}
                                                        key={imagensMockup.idPecaMarketingArquivo}
                                                    />
                                                ))}
                                            </Stack>
                                        </Block>
                                    )}
                                </React.Fragment>
                            )}

                            {Array.isArray(content.arquivosDownload) && Boolean(content.arquivosDownload.length) && (
                                <Block>
                                    <SubTitle label="Arquivos para Download" />

                                    <Stack spacing={1}>
                                        {content.arquivosDownload.map((arquivosDownload) => (
                                            <DownloadBox
                                                image={arquivosDownload.arquivo.dsLinkThumbnail}
                                                link={arquivosDownload.arquivo.dsLink}
                                                label={arquivosDownload.arquivo.dsTitulo}
                                                key={arquivosDownload.idPecaMarketingArquivo}
                                            />
                                        ))}
                                    </Stack>
                                </Block>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </OneColumn>
    );
}
