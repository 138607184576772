import React, { useState } from 'react';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HistoryIcon from '@mui/icons-material/History';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';

import { ConfirmModal } from 'components/modal';
import { PedidoLogModal } from 'components/modal/PedidoLogModal';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useNavigateToPedido } from 'hooks/useNavigateToPedido';
import { CarrinhoFinalizarModal } from 'pages/compra/carrinho/components/templates/CarrinhoFinalizarModal';
import { useDeletePedido } from 'pages/compra/carrinho/hooks';

interface CarrinhoActionColumn {
    row: GridRowModel;
}

export function CarrinhoActionColumn({ row }: CarrinhoActionColumn): JSX.Element {
    const { content } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const { handleDelete } = useDeletePedido({ rowData: row });
    const { navigateToPedido } = useNavigateToPedido();

    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [openFinalizarModal, setOpenFinalizarModal] = useState<boolean>(false);
    const [openLogModal, setOpenLogModal] = useState<boolean>(false);

    const disableButton = row.id === content?.finalizarRow || content?.finalizarAll;

    return (
        <>
            <PedidoLogModal open={openLogModal} setOpen={setOpenLogModal} idPedido={row.idPedido} />

            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={handleDelete} />

            <CarrinhoFinalizarModal open={openFinalizarModal} setOpen={setOpenFinalizarModal} row={row} />

            <Box sx={{ diplay: 'flex', justifyContent: 'center' }}>
                <Tooltip title="Editar">
                    <IconButton disabled={disableButton} onClick={() => navigateToPedido(row)}>
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Remover">
                    <IconButton disabled={disableButton} onClick={() => setOpenConfirmModal(true)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>

                {hasPermission(['COMPRA_PEDIDO_FINALIZAR']) && (
                    <Tooltip title="Finalizar">
                        <IconButton disabled={disableButton} onClick={() => setOpenFinalizarModal(true)}>
                            <CheckCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Logs">
                    <IconButton disabled={disableButton} onClick={() => setOpenLogModal(true)}>
                        <HistoryIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    );
}
