import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { ComunicadoLogsBtn, FormFilterComunicado } from '..';

import { CardBoxItem, CardBoxSkeleton, ListBoxSkeleton, ListBoxItem, ListBoxItemProps } from 'components/content';
import { OneColumn, Block } from 'components/page/';
import { ListPagination } from 'components/pagination';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useListMode } from 'hooks/useListMode';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ComunicadoTagsProps, RequestComunicadosStoreProps } from 'pages/comunicado/types';
import { getErrorMessage } from 'util/error';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { GradeModal } from 'components/modal';

const generateComunicadosList = (data: RequestComunicadosStoreProps | undefined): ListBoxItemProps[] => {
    const list = data?.data?.data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => {
            const comunicadoTagsComunicado = Array.isArray(item.comunicadoTagsComunicado) ? item.comunicadoTagsComunicado : [];

            return {
                id: item.idComunicado,
                label: item.dsComunicado,
                active: item.fgAtivo,
                type: item?.categoriaComunicado?.dsCategoriaComunicado,
                date: item.dhInclusao && dateFns.format(new Date(item.dhInclusao), 'dd/MM/yyyy'),
                tags: comunicadoTagsComunicado.map((comunicadoTag: ComunicadoTagsProps) => comunicadoTag.dsTagComunicado),
                cover: item.dsLinkCapa,
                likes: item.qtCurtida,
                liked: item.fgUsuarioCurtiu,
                views: item.qtVisualizacao,
            };
        });
    }

    return [];
};

/**
 * Página listagem comunicados em grid ou lista
 */
export function ComunicadoListComp(): JSX.Element {
    const { page, setPage, filter, limit, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();
    const { mode } = useListMode();
    const { hasPermission } = usePermissionsContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    const requestOptions: RequestOptionsType = {
        url: '/gestao/comunicado/lista',
        filter,
        sort: [{ property: 'idComunicado', direction: 'DESC' }],
        columns: 'idComunicado,dsComunicado,fgAtivo,dsCategoriaComunicado,dhInclusao,qtCurtida,qtVisualizacao,dsLinkCapa',
        page,
        limit,
    };

    const { isLoading, data, fetchStatus, isSuccess, isError, error, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestComunicadosStoreProps> = RequestListagem(requestOptions);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const comunicadosList = generateComunicadosList(data);
    const [comunicadosError] = getErrorMessage(error);

    return (
        <>
            {isTipoPedidoPadrao && <GradeModal />}

            <OneColumn
                title="Comunicados"
                createButton={hasPermission(['COMUNICADO_CADASTRAR'])}
                listType={!isMobile}
                extraButtons={[
                    {
                        title: 'Gerenciar Categorias',
                        onClick: () => navigate('categoria'),
                        visible: hasPermission(['COMUNICADO_CATEGORIA_GERENCIAR']),
                    },
                ]}
            >
                <FormFilterComunicado />

                {isError && comunicadosError && <Alert severity="error">{comunicadosError}</Alert>}

                {!comunicadosList.length && isSuccess && <Alert severity="warning">Sem registros para exibir.</Alert>}

                <Block filled={false} sx={{ p: 0 }}>
                    {(mode === 'grid' || isMobile) && (
                        <Grid container spacing={2}>
                            {isLoading &&
                                fetchStatus === 'fetching' &&
                                Array.from(new Array(limit)).map(() => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={Math.random()}>
                                        <CardBoxSkeleton />
                                    </Grid>
                                ))}
                            {Array.isArray(comunicadosList) &&
                                comunicadosList.map((comunicado) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={comunicado.id}>
                                        <CardBoxItem
                                            refresh={refetch}
                                            removeUrl={`gestao/comunicado/${comunicado.id}`}
                                            showEditBtn={hasPermission(['COMUNICADO_ALTERAR'])}
                                            showRemoveBtn={hasPermission(['COMUNICADO_EXCLUIR'])}
                                            extraContent={
                                                hasPermission(['COMUNICADO_LOG_VISUALIZAR']) && <ComunicadoLogsBtn idComunicado={comunicado.id} />
                                            }
                                            {...comunicado}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    )}

                    {mode === 'list' && !isMobile && (
                        <Stack spacing={2}>
                            {isLoading &&
                                fetchStatus === 'fetching' &&
                                Array.from(new Array(limit)).map(() => <ListBoxSkeleton key={Math.random()} />)}
                            {Array.isArray(comunicadosList) &&
                                comunicadosList.map((comunicado) => (
                                    <ListBoxItem
                                        refresh={refetch}
                                        removeUrl={`gestao/comunicado/${comunicado.id}`}
                                        key={comunicado.id}
                                        showEditBtn={hasPermission(['COMUNICADO_ALTERAR'])}
                                        showRemoveBtn={hasPermission(['COMUNICADO_EXCLUIR'])}
                                        extraContent={
                                            hasPermission(['COMUNICADO_LOG_VISUALIZAR']) && <ComunicadoLogsBtn idComunicado={comunicado.id} />
                                        }
                                        {...comunicado}
                                    />
                                ))}
                        </Stack>
                    )}
                </Block>

                <ListPagination data={data} list={comunicadosList} limit={limit} page={page} setPage={setPage} />
            </OneColumn>
        </>
    );
}
