import React from 'react';

import { OneColumn } from 'components/page';
import { RevendedorGrid, RevendedorGridFilter, RevendedorReport } from 'pages/revendedor/revendedor/components';

export function RevendedorListComp(): JSX.Element {
    return (
        <OneColumn title="Revendedores" createButton>
            <RevendedorGridFilter />

            <RevendedorReport />

            <RevendedorGrid />
        </OneColumn>
    );
}
