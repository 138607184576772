import React from 'react';

import { TituloListComp } from './components';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { TituloProvider } from 'context/TituloContext';

export function TituloList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_TITULO_VISUALIZAR'])) return alertNotPermission();

    return (
        <TituloProvider>
            <ListagemProvider>
                <TituloListComp />
            </ListagemProvider>
        </TituloProvider>
    );
}
