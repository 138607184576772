import React, { useId } from 'react';

import { GridColDef, GridColumnGroupingModel, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { brlDecimal, brlPrice } from 'util/format';
import { FppNotaProdutoModelProps } from 'pages/financeiro/extrato/fpp-espelho-nota-produto/types';

const configBaseFaturamentoIsFpp = (configVlBaseFaturamento: string): boolean => {
    return configVlBaseFaturamento === 'vlPrecoFpp';
};

const generateNotaProdutoList = (data: FppNotaProdutoModelProps[] | undefined, configVlBaseFaturamento: string): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => {
            return {
                id: useId(),
                cdProduto: item.produto.cdProduto,
                dsProduto: item.produto.dsProduto,
                qtProduto: brlDecimal(item.qtProduto),
                vlConsumidor: brlPrice(item.vlPrecoConsumidor),
                vlPrecoBaseFaturamento: configBaseFaturamentoIsFpp(configVlBaseFaturamento) ? brlPrice(item.vlPrecoFpp) : brlPrice(item.vlPrecoFabrica),
                vlDiferencaTabela: !configBaseFaturamentoIsFpp(configVlBaseFaturamento) ? brlPrice(item.vlPrecoConsumidor - item.vlPrecoFabrica) : brlPrice(item.vlDiferencaTabela),
                vlFpp: item.vlFpp,
            };
        });
    }

    return [];
};

export function FppEspelhoNotaProdutoGrid({ produtos, configVlBaseFaturamento }: { produtos: FppNotaProdutoModelProps[]; configVlBaseFaturamento: string }): JSX.Element {
    const columnGroupingModel: GridColumnGroupingModel = [
        {
            groupId: 'tabelPreco',
            headerName: 'Tabela de Preço',
            children: [{ field: 'vlConsumidor' }, { field: 'vlPrecoBaseFaturamento' }],
        },
        {
            groupId: 'final',
            headerName: 'Cobrança FPP',
            children: [{ field: 'vlDiferencaTabela' }, { field: 'vlFpp' }],
        },
    ];

    const columns: GridColDef[] = [
        { field: 'cdProduto', headerName: 'Cód. Produto', width: 135, minWidth: 100 },
        { field: 'dsProduto', headerName: 'Produto', flex: 1, minWidth: 150 },
        { field: 'qtProduto', headerName: 'Qtd', type: 'number', width: 80, minWidth: 60 },
        { field: 'vlConsumidor', headerName: 'Consumidor', type: 'number', width: 135, minWidth: 100 },
        { field: 'vlPrecoBaseFaturamento', headerName: configBaseFaturamentoIsFpp(configVlBaseFaturamento) ? 'Base de FPP' : 'Base Faturamento', type: 'number', width: 135, minWidth: 100 },
        { field: 'vlDiferencaTabela', headerName: 'Diferença Tabelas', type: 'number', width: 135, minWidth: 130 },
        { field: 'vlFpp', headerName: 'Total', type: 'number', width: 135, minWidth: 70, valueFormatter: ({ value }) => brlPrice(value) },
    ];

    const rows = generateNotaProdutoList(produtos, configVlBaseFaturamento);

    return (
        <Block>
            <PanelGrid rows={rows} rowsCount={rows.length} reload={false} loading={false} columns={columns} remote={false} summary={['vlFpp']} columnGrouping={columnGroupingModel} />
        </Block>
    );
}
