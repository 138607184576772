import React, { useCallback, useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface RelatorioBaseFaturamentoBtnProps {
    disabled: boolean;
}

export function RelatorioBaseFaturamentoBtn({ disabled }: RelatorioBaseFaturamentoBtnProps): JSX.Element {
    const { filter } = useListagemContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/royaltyrelatorio/faturamento/consolidado',
            columns: 'idRelatorioFinanceiroBaseFaturamento',
            filter,
        }),
        [filter],
    );

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClickDownload = useCallback((): void => {
        downloadFile({
            newRequestOptions: requestOptions,
            directDownload: true,
        });
    }, [downloadFile, requestOptions]);

    return (
        <LoadingButton loading={isLoadingFile} variant="outlined" color="info" onClick={handleClickDownload} disabled={disabled || isLoadingFile} startIcon={<RiFileExcel2Fill />}>
            RELATÓRIO BASE FATURAMENTO ROYALTIES
        </LoadingButton>
    );
}
