import React from 'react';

import { Box } from '@mui/system';
import { GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DocumentoDetailsResponse, EstatisticasPanelItemDetailProps } from 'pages/estatisticas/types';

export function EstatisticasPanelItemDetail({ details }: EstatisticasPanelItemDetailProps): JSX.Element {
    const requestListagem = useRequestListagem();
    const { filter } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/documento/item',
        filter: [...filter, { property: 'idDocumento', operator: 'eq', value: details.idDocumento }, { property: 'dsTipoDocumento', operator: 'like', value: details.dsTipoDocumento }],
        sort: [{ property: 'cdLoja', direction: 'DESC' }],
    };

    const { data, fetchStatus, refetch } = useQuery([requestOptions], (): Promise<DocumentoDetailsResponse> => requestListagem(requestOptions).then((res) => res.data));

    const makeRows = (): GridRowsProp => {
        if (!data) return [];

        return data.data.map((documentDetail) => ({
            id: documentDetail.cdLoja,
            dsNomeFantasia: documentDetail.dsNomeFantasia,
            qtCurtida: documentDetail.qtCurtida ?? 0,
            qtDownload: documentDetail.qtDownload ?? 0,
            qtVisualizacao: documentDetail.qtVisualizacao ?? 0,
            dhUltimaVisualizacao: documentDetail.dhUltimaVisualizacao ? format(new Date(documentDetail.dhUltimaVisualizacao), 'dd/MM/yyyy HH:mm:ss') : '',
            dhUltimoDownload: documentDetail.dhUltimoDownload ? format(new Date(documentDetail.dhUltimoDownload), 'dd/MM/yyyy HH:mm:ss') : '',
            dhUltimaCurtida: documentDetail.dhUltimaCurtida ? format(new Date(documentDetail.dhUltimaCurtida), 'dd/MM/yyyy HH:mm:ss') : '',
        }));
    };

    const rows = makeRows();

    return (
        <Box sx={{ m: 2 }}>
            <PanelGrid
                rows={rows}
                rowsCount={data?.total || 0}
                loading={fetchStatus === 'fetching'}
                reload={refetch}
                columns={[
                    { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1 },
                    { field: 'qtCurtida', headerName: 'Curtidas', width: 100, align: 'center', headerAlign: 'center' },
                    { field: 'dhUltimaCurtida', headerName: 'Última Curtida', width: 180, align: 'center', headerAlign: 'center' },
                    { field: 'qtDownload', headerName: 'Downloads', width: 100, align: 'center', headerAlign: 'center' },
                    { field: 'dhUltimoDownload', headerName: 'Último Download', width: 180, align: 'center', headerAlign: 'center' },
                    { field: 'qtVisualizacao', headerName: 'Visualizações', width: 100, align: 'center', headerAlign: 'center' },
                    { field: 'dhUltimaVisualizacao', headerName: 'Última visualização', width: 180, align: 'center', headerAlign: 'center' },
                ]}
            />
        </Box>
    );
}
