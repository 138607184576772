import React, { Dispatch, SetStateAction } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { TipoControlePedidoMinimoAutoComplete } from 'components/autocompletes';
import { TipoControlePedidoMinimo } from 'components/autocompletes/TipoControlePedidoMinimoAutoComplete';
import { CissNumberFieldV2 } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { ConfiguracaoPedidoCompraRemoveBtn } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraRemoveBtn';
import { LojasProps } from 'pages/compra/configuracao-pedido/types';

interface ConfiguracaoPedidoCompraLojasGridProps {
    valueFilter: string;
    removeLojas: (idList: number[]) => void;
    setSelectedRows: Dispatch<SetStateAction<number[]>>;
    loading: boolean;
}

const filterLojas = (lojasList: LojasProps[], valueFilter: string): LojasProps[] =>
    lojasList.filter((loja: LojasProps) => {
        return loja.dsNomeFantasia.toLowerCase().includes(valueFilter.toLowerCase()) || loja.cdLoja.toString().includes(valueFilter);
    });

const getRows = (lojasList: LojasProps[], valueFilter: string): GridRowsProp => {
    if (Array.isArray(lojasList) && lojasList.length) {
        return filterLojas(lojasList, valueFilter).map((item: LojasProps) => ({
            id: item.idLoja,
            idLoja: item.idLoja,
            cdLoja: item.cdLoja,
            dsNomeFantasia: item.dsNomeFantasia,
            dsRazaoSocial: item.dsRazaoSocial,
            fgAtivo: item.fgAtivo,
            peRoyalties: item.peRoyalties,
            vlPedidoMinimo: item.vlPedidoMinimo,
            tipoControlePedidoMinimo: item.tipoControlePedidoMinimo,
        }));
    }

    return [];
};

export function ConfiguracaoPedidoCompraLojasGrid({
    valueFilter,
    removeLojas,
    setSelectedRows,
    loading,
}: ConfiguracaoPedidoCompraLojasGridProps): JSX.Element | null {
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { tipoPedido } = useGetDomainConfig();
    const { content, setContent } = useFormContext();
    const { lojasList } = content ?? {};

    const handleAlterarFields = (idLoja: number, value: number | null | TipoControlePedidoMinimo, nameField: string): void => {
        const newLojasList = lojasList.map((loja: LojasProps) => {
            if (loja.idLoja === idLoja) {
                let { vlPedidoMinimo } = loja;

                // Caso o autocomplete seja limpo, reseta o valor do pedido para 0.
                if (nameField === 'tipoControlePedidoMinimo' && !value) {
                    vlPedidoMinimo = 0;
                }

                return { ...loja, vlPedidoMinimo, [nameField]: value };
            }

            return loja;
        });

        setContent({ ...content, lojasList: newLojasList });

        setActiveStepDirty();
    };

    const columns: GridColDef[] = [
        { field: 'cdLoja', headerName: 'Código', minWidth: 70 },
        { field: 'dsNomeFantasia', headerName: 'Nome da Loja', type: 'string', minWidth: 100, flex: 1 },
        { field: 'dsRazaoSocial', headerName: 'Razão Social', type: 'string', minWidth: 110, flex: 1 },
        {
            field: 'remover',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Remover',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 80,
            renderCell: ({ row }) => <ConfiguracaoPedidoCompraRemoveBtn onClick={() => removeLojas([row.idLoja])} />,
        },
    ];

    if (tipoPedido === 'padrao') {
        columns.splice(
            3,
            0,
            {
                field: 'peRoyalties',
                headerName: 'Coeficiente ND',
                minWidth: 90,
                renderCell: ({ row }) => (
                    <CissNumberFieldV2
                        size="small"
                        inputType="percentual"
                        value={row.peRoyalties}
                        onChange={(value) => handleAlterarFields(row.idLoja, value, 'peRoyalties')}
                    />
                ),
            },
            {
                field: 'tipoControlePedidoMinimo',
                headerName: 'Controle de Mínimo',
                minWidth: 180,
                renderCell: ({ row }) => (
                    <TipoControlePedidoMinimoAutoComplete
                        size="small"
                        sx={{ width: '100%' }}
                        value={row.tipoControlePedidoMinimo}
                        onChange={(e, value) => handleAlterarFields(row.idLoja, value, 'tipoControlePedidoMinimo')}
                    />
                ),
            },
            {
                field: 'vlPedidoMinimo',
                headerName: 'Mínimo',
                minWidth: 80,
                renderCell: ({ row }) => (
                    <CissNumberFieldV2
                        size="small"
                        inputType="decimal"
                        value={row.vlPedidoMinimo}
                        disabled={!row.tipoControlePedidoMinimo}
                        onChange={(value) => handleAlterarFields(row.idLoja, value, 'vlPedidoMinimo')}
                    />
                ),
            },
        );
    }

    const rows = getRows(lojasList, valueFilter);

    return (
        <PanelGrid
            rows={rows}
            rowsCount={rows.length || 0}
            reload={false}
            loading={loading}
            columns={columns}
            remote={false}
            checkboxSelection
            onClickRow={(selectionModel) => setSelectedRows(selectionModel as number[])}
        />
    );
}
