export type ValidationsPassword = {
    minLength: boolean;
    upperLetter: boolean;
    lowerLetter: boolean;
    numbers: boolean;
    specialCharacters: boolean;
};


export const initialValidationsPassword: ValidationsPassword = { minLength: false, upperLetter: false, lowerLetter: false, numbers: false, specialCharacters: false };

export const validatePassword = (inputValue: string): ValidationsPassword => ({
    minLength: inputValue.length >= 10,
    upperLetter: /[A-Z]/.test(inputValue),
    lowerLetter: /[a-z]/.test(inputValue),
    numbers: /[0-9]/.test(inputValue),
    specialCharacters: /[()<>.=:;?!~_@#$%^&"'*+\-|/[\]]/.test(inputValue),
});
