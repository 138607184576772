import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Box, Typography, Alert, CircularProgress, Stack, Grid, useTheme, useMediaQuery } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Centered } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RelatorioFaturamentoFppBtn } from 'pages/financeiro/extrato/fpp/components';
import { ExtratoRoyaltyTableQuinzena, ExtratoRoyaltyListFilter, RelatorioBaseFaturamentoBtn, RelatorioContaCorrenteBtn, ExtratoRoyaltyInfoBtn } from 'pages/financeiro/extrato/royalty/components';
import { RequestRoyaltiesStoreProps } from 'pages/financeiro/extrato/royalty/types';
import { getErrorMessage } from 'util/error';

export interface FilterLojaDataProps {
    idLoja: number;
    cdLoja: string;
    dsNomeFantasia: string;
}

export function ExtratoRoyaltyListComp(): JSX.Element {
    const { filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/royaltyextrato',
        filter,
        nested: true,
        columns: 'royaltyDetailTipoNotas',
    };

    const { isLoading, isError, fetchStatus, error, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestRoyaltiesStoreProps> = RequestListagem(requestOptions).then((result) => result.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const content = data?.data;
    const [errorMsg] = getErrorMessage(error);

    return (
        <OneColumn title="Extrato de Royalties e FPP" extraContent={<ExtratoRoyaltyInfoBtn />}>
            <ExtratoRoyaltyListFilter />

            <Block>
                <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" spacing={2}>
                    <Box sx={{ flex: 1, display: 'inherit', alignItems: 'inherit' }}>
                        <TableViewOutlinedIcon color="primary" />

                        <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                        <ChevronRightOutlinedIcon color="primary" />
                    </Box>

                    <RelatorioFaturamentoFppBtn disabled={!content || (Array.isArray(content) && content.length < 1)} />

                    <RelatorioBaseFaturamentoBtn disabled={!content || (Array.isArray(content) && content.length < 1)} />

                    <RelatorioContaCorrenteBtn disabled={!content || (Array.isArray(content) && content.length < 1)} />
                </Stack>
            </Block>

            <Block>
                {Array.isArray(content) && !content.length && autoLoad && <Alert severity="warning">Nenhum registro encontrado</Alert>}

                {isError && <Alert severity="error">{errorMsg}</Alert>}

                {!autoLoad && (
                    <Centered>
                        <Typography sx={{ my: 2 }}>Utilize os filtros acima para iniciar</Typography>
                    </Centered>
                )}

                {isLoading && fetchStatus === 'fetching' && (
                    <Centered>
                        <CircularProgress />
                    </Centered>
                )}

                {Array.isArray(content) &&
                    content.map(({ dsExtrato, quinzenas, tpExtrato }) => (
                        <Box
                            key={dsExtrato}
                            sx={{
                                '& + .MuiBox-root': {
                                    mt: 3,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: 1,
                                    py: 1,
                                    mb: 2,
                                    backgroundColor: 'primary.main',
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: 'common.white',
                                    }}
                                >
                                    {dsExtrato}
                                </Typography>
                            </Box>

                            <Grid container spacing={2} sx={{ flexDirection: isMobile ? 'column' : 'row' }}>
                                {quinzenas.map((quinzena) => (
                                    <ExtratoRoyaltyTableQuinzena key={quinzena.tipoQuinzena} quinzena={quinzena} tpExtrato={tpExtrato} />
                                ))}
                            </Grid>
                        </Box>
                    ))}
            </Block>
        </OneColumn>
    );
}
