import React, { Dispatch, SetStateAction } from 'react';

import { Alert, Stack } from '@mui/material';

import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { PedigoLog } from 'pages/compra/carrinho/types';

interface CarrinhoFalhasModal {
    open: PedigoLog[] | boolean;
    setOpen: Dispatch<SetStateAction<PedigoLog[] | boolean>>;
}

export function CarrinhoFalhasModal({ open, setOpen }: CarrinhoFalhasModal): JSX.Element {
    const formatMessages = (messages: PedigoLog[]): string[] =>
        messages.flatMap((item) => item.dsMensagem.split('\n'));

    const messages = Array.isArray(open) ? formatMessages(open) : [];

    return (
        <ContentModal open={Boolean(open)} setOpen={setOpen} sx={{ borderRadius: 1, width: { xs: '80%', sm: 'auto' } }} closeButton>
            <Stack>
                <SubTitle label="Falhas" />

                <Stack spacing={2}>
                    {messages.map((item) => (
                        <Alert key={Math.random()} variant="outlined" severity="error">
                            {item}
                        </Alert>
                    ))}
                </Stack>
            </Stack>
        </ContentModal>
    );
}
