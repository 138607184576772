import React, { ReactNode } from 'react';

import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';

interface DreConsultaGridTooltipProps {
    children: ReactNode;
    tooltip: string;
}

export function DreConsultaGridTooltip({ children, tooltip }: DreConsultaGridTooltipProps): JSX.Element {
    return (
        <Tooltip title={tooltip} placement="top">
            <Box
                sx={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25em',
                    fontWeight: 500,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'right',
                }}
            >
                {children}*
            </Box>
        </Tooltip>
    );
}
