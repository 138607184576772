import { SetStateAction } from 'react';

import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import * as dateFns from 'date-fns';
import { useSnackbar } from 'notistack';

import { useListagemContext } from 'context/ListagemContext';
import { useValidation } from 'hooks/useValidation';

export interface FilterValuesProps {
    property: string;
    operator: 'like' | 'eq' | 'bt' | 'in' | 'gte' | 'lte' | 'neq' | 'is null';
    value?: any;
    filterValue?: any;
    idProperty?: string;
    logicalOperator?: 'AND' | 'OR';
    required?: string;
    startDate?: any;
    endDate?: any;
}

export interface ValueSetterFiltersProps {
    value: FilterValuesProps;
    setter?: SetStateAction<any>;
}

interface FullFilterProps {
    filters: ValueSetterFiltersProps[];
}

interface HandleValidateValuesProps {
    filters: FilterValuesProps[];
}

interface HandleValidateProps {
    filters: FilterValuesProps[];
    valid: boolean;
}

interface HandleDefaultFilters {
    filters: ValueSetterFiltersProps[];
    preventQueryParamsEmptyStart?: boolean;
}

interface UseFilterProps {
    handleFilterSubmit: (props: FullFilterProps) => void;
    handleFilterReset: (props: FullFilterProps) => void;
    setDefaultFilters: (props: HandleDefaultFilters) => void;
}

const isValueDate = (value: any): boolean => {
    const dateAsStringOrObject =
        (typeof value === 'string' || typeof value === 'object') &&
        !Array.isArray(value) &&
        isNaN(Number(value)) &&
        dateFns.isValid(dateFns.parseISO(value));
    const dateAsDate = value instanceof Date && dateFns.isValid(value);

    return dateAsDate || dateAsStringOrObject;
};

// Função recursiva para encontrar o valor do objeto mesmo que aninhado.
const getValueProperty = (value: any, property: string): unknown => {
    // Função auxiliar para percorrer o objeto de forma recursiva
    const getValue = (obj: any, propertySplitted: string[]): any => {
        const chaveAtual = propertySplitted.shift(); // Remove e retorna o primeiro elemento do array

        if (obj && chaveAtual && chaveAtual in obj) {
            if (propertySplitted.length === 0) {
                // Se não restarem mais chaves, encontramos o valor desejado
                return obj[chaveAtual];
            } else {
                // Se ainda houver chaves no array, continue percorrendo o objeto
                return getValue(obj[chaveAtual], propertySplitted);
            }
        } else {
            // Se a chave atual não existir no objeto ou o objeto for nulo/undefined, retorna undefined
            return undefined;
        }
    };

    return getValue(value, property.split('.'));
};

export function useFilter(): UseFilterProps {
    const { validValue, validDateRange, notNullValue } = useValidation();
    const listagemContext = useListagemContext();
    const { enqueueSnackbar } = useSnackbar();
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const notEmptyFilterValues = (filter: FilterValuesProps): boolean => {
        const { filterValue } = filter;

        let valid = false;

        if (Array.isArray(filterValue)) {
            filterValue.forEach((val) => {
                valid = validValue(val);

                return valid;
            });
        } else {
            valid = validValue(filterValue);
        }

        return valid;
    };

    const validFilterValue = (filter: FilterValuesProps): boolean => {
        const { required, operator } = filter;
        const empty = !notEmptyFilterValues(filter);

        let valid = true;

        if (required && empty) {
            enqueueSnackbar(required, { variant: 'error' });
            valid = false;
        } else if (operator === 'bt') {
            valid = validDateRange(filter.filterValue);
        }

        return valid;
    };

    const updateFilterValues = (filter: FilterValuesProps): FilterValuesProps => {
        const { required, idProperty, startDate, endDate, value, filterValue, ...filterData } = filter; // removo as chaves que não serão enviadas para o backend no filter
        const { operator, logicalOperator } = filterData;

        const newFilter = { ...filterData, value: filterValue };

        // campo data, formato esperado nos filtros = mm-dd-yyyy
        if (Array.isArray(filterValue) && operator === 'bt') {
            newFilter.value = filterValue.map((val: any) => val && dateFns.format(val, 'MM/dd/yyyy'));
        }

        // operador lógico padrao = AND
        if (!logicalOperator) {
            newFilter.logicalOperator = 'AND';
        }

        return newFilter;
    };

    const setFilterValue = (filter: FilterValuesProps): void => {
        const { idProperty, value, startDate, endDate } = filter;

        // string/boolean
        let newValue: any = value;

        // data
        if (isValueDate(value)) {
            newValue = dateFns.format(value, 'MM/dd/yyyy');
        }
        // array
        else if (Array.isArray(value)) {
            newValue = [...value];
        }
        // objeto
        else if (typeof value === 'object' && value !== null) {
            newValue = { ...value };
        }
        // null
        else if (typeof value === 'object' && value === null) {
            newValue = value;
        }

        if (idProperty) {
            // campo de data com somente 1 coluna (dia, mes ou ano)
            if (isValueDate(value)) {
                let formatter = 'dd';

                if (idProperty === 'year') {
                    formatter = 'yyyy';
                } else if (idProperty === 'month') {
                    formatter = 'MM';
                }

                newValue = dateFns.format(value, formatter);
            }
            // componente: AUTOCOMPLETE com multiselect true ou false
            else if (Array.isArray(value)) {
                newValue = value.map((item: any) => getValueProperty(item, idProperty));
            } else if (typeof value === 'object' && notNullValue(value)) {
                newValue = getValueProperty(value, idProperty);
            }
        }

        // componente: DATE com start e end (operador bt)
        if (typeof startDate !== 'undefined' || typeof endDate !== 'undefined') {
            newValue = [startDate, endDate];
        }

        filter.filterValue = newValue;
    };

    const handleValidateFilter = ({ filters }: HandleValidateValuesProps): HandleValidateProps => {
        let valid = true;

        const newFilters = filters
            .map((filter: FilterValuesProps): null | FilterValuesProps => {
                setFilterValue(filter);

                valid = valid && validFilterValue(filter);

                if (notEmptyFilterValues(filter)) {
                    return filter;
                }

                return null;
            })
            .filter((filter: null | FilterValuesProps) => filter !== null);

        return {
            valid,
            filters: newFilters as [],
        };
    };

    const setUrlFilter = (filters: FilterValuesProps[]): void => {
        const { routeState } = listagemContext;

        const search: any = {};

        filters.forEach(({ property, value, startDate, endDate }): any => {
            let val = value;

            if (typeof startDate !== 'undefined' || typeof endDate !== 'undefined') {
                val = { startDate, endDate };
            }

            search[property] = JSON.stringify(val);
        });

        // se existir parametro de paginação da grid, salva ele e mantem na url
        if (searchParams.get('gridPage')) {
            search['gridPage'] = searchParams.get('gridPage');
        }

        if (Object.keys(search).length || Array.from(searchParams).length) {
            navigate(
                {
                    pathname,
                    search: `?${createSearchParams(search)}`,
                },
                {
                    replace: true,
                    state: routeState,
                },
            );
        }
    };

    const handleFilterSubmit = ({ filters }: FullFilterProps): void => {
        const { setFilter, autoLoad, setAutoLoad, setFullFilterData, setPage } = listagemContext;
        const filtersList = filters.map((filter) => filter.value);
        const urlList = filters.filter((filter) => filter.setter).map((filter) => filter.value.property);
        const { valid, filters: newFilters } = handleValidateFilter({ filters: filtersList });

        setPage(1);

        if (valid) {
            setFilter(newFilters.map(updateFilterValues));

            setFullFilterData(filters);

            setUrlFilter(newFilters.filter((filter) => urlList.includes(filter.property)));

            // se autoload = false, habilita para carregar os dados
            // essa funcionalidade (autoload) só tem serventia se setado enabled: autoload no query
            if (!autoLoad) {
                setAutoLoad(true);
            }
        }
    };

    const handleFilterReset = ({ filters }: FullFilterProps): void => {
        filters.forEach((filter) => {
            const { value: state, setter } = filter;
            const { value, operator, startDate, endDate } = state;
            const newState = { ...state };

            let newValue: any;

            if (typeof setter === 'function') {
                if (typeof value === 'string') {
                    newValue = '';
                } else if (Array.isArray(value)) {
                    if (operator === 'bt') {
                        newValue = [null, null];
                    } else {
                        newValue = [];
                    }
                } else {
                    newValue = null;
                }

                if (typeof startDate !== 'undefined' || typeof endDate !== 'undefined') {
                    newState.startDate = null;
                    newState.endDate = null;
                } else {
                    newState.value = newValue;
                }

                newState.filterValue = newValue;

                setter(newState);
            }
        });

        setUrlFilter([]);
    };

    const setDefaultFilters = ({ filters, preventQueryParamsEmptyStart = false }: HandleDefaultFilters): void => {
        let valid = false;

        filters.forEach((filter) => {
            const { value: state, setter } = filter;
            const { startDate, endDate, property } = state;
            const value = JSON.parse(searchParams.get(property) ?? 'null');

            let startDateValue = (typeof value === 'object' && value !== null && value.startDate) || null;
            let endDateValue = (typeof value === 'object' && value !== null && value.endDate) || null;

            if (startDateValue && typeof startDate !== 'undefined') {
                startDateValue = new Date(value.startDate);
            }

            if (endDateValue && typeof endDate !== 'undefined') {
                endDateValue = new Date(value.endDate);
            }

            if (startDateValue || endDateValue) {
                valid = true;
                setter({ ...state, startDate: startDateValue, endDate: endDateValue });

                // atualizamos o objeto atual para poder aplicar os filtros automaticamente
                state.startDate = startDateValue;
                state.endDate = endDateValue;
            } else if (value) {
                const newValue = isValueDate(value) ? new Date(value) : value;

                valid = true;

                setter({ ...state, value: newValue });

                // atualizamos o objeto atual para poder aplicar os filtros automaticamente
                state.value = newValue;
            }
        });

        if (preventQueryParamsEmptyStart && valid) {
            handleFilterSubmit({ filters });
        } else if (!preventQueryParamsEmptyStart) {
            handleFilterSubmit({ filters });
        }
    };

    return { handleFilterSubmit, handleFilterReset, setDefaultFilters };
}
