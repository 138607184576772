import React from 'react';

import { GradeModal } from 'components/modal';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { BiPageComp } from 'pages/bi/components';
import { ComunicadoModal, InicioUsuarioInfo } from 'pages/inicio/components';

export function InicioDetailComp(): JSX.Element {
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isTipoPedidoPadrao = tipoPedido === 'padrao';

    return (
        <>
            {isTipoPedidoFuncionario && <InicioUsuarioInfo />}

            {isTipoPedidoPadrao && (
                <>
                    <ComunicadoModal />

                    <GradeModal />

                    <BiPageComp />
                </>
            )}
        </>
    );
}
