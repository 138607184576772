import React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GridColDef } from '@mui/x-data-grid-pro';

import { Block } from 'components/page';
import { brlPrice } from 'util/format';
import { SubTitle } from 'components/text';
import { PanelGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { TinyButton } from 'components/buttons';
import { dateToQuinzenaString } from 'util/dateToQuinzenaString';
import { usePermissionsContext } from 'context/PermissionsContext';

const generateNotaFiscalList = (data: any[] | undefined): any => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: `_nota${index}`,
            nrDocumento: item.nrDocumento,
            dtDocumento: `${item.dtDocumento}T00:00:00`,
            vlDocumento: item.vlDocumento,
            vlTotalLiquido: item.vlTotalLiquido,
            vlTotalBaseFaturamento: item.vlTotalBaseFaturamento,
            dsTipoDocumento: item.dsTipoDocumento,
            vlTotal: item.vlTotal,
        }));
    }

    return [];
};

export function EspelhoRoyaltyNotaList(): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const dtFiltro = searchParams.get('dtFiltro');
    const dsTipo = searchParams.get('tpExtrato');
    const nrQuinzena = searchParams.get('nrQuinzena') as string;
    const peDocumento = searchParams.get('peDocumento');

    const [nrMes, diaQuinzena, nrAno] = dtFiltro ? dtFiltro.split('/') : []; // dados recebidos no formato MM/dd/YYYY
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/royaltyextrato/nota',
        columns: 'idNota',
        nested: true,
        filter: [
            { property: 'dtFiltro', value: dtFiltro, operator: 'eq', logicalOperator: 'AND' },
            { property: 'cdLoja', value: cdLoja, operator: 'eq', logicalOperator: 'AND' },
            { property: 'dsTipo', value: dsTipo, operator: 'eq', logicalOperator: 'AND' },
            { property: 'nrQuinzena', value: nrQuinzena, operator: 'eq', logicalOperator: 'AND' },
            { property: 'peDocumento', value: peDocumento, operator: 'eq', logicalOperator: 'AND' },
        ],
    };

    const { data, fetchStatus, refetch } = useQuery([requestOptions], () => {
        const request: Promise<any> = RequestListagem(requestOptions).then((res) => res.data.data);
        return request;
    });

    const handleClickNotaFiscalRow = (row: any): void => {
        const strLoja = cdLoja ? cdLoja.toString() : '';

        navigate({
            pathname: 'nota-produto',
            search: `?${createSearchParams({
                dtFiltro: dateFns.format(new Date(row.dtDocumento), 'MM/dd/yyyy'),
                cdLoja: strLoja,
                nrNota: row.nrDocumento,
            })}`,
        });
    };

    const columns: GridColDef[] = [
        { field: 'nrDocumento', headerName: 'Nota Fiscal', minWidth: 130 },
        { field: 'dtDocumento', headerName: 'Data', minWidth: 140, valueFormatter: ({ value }) => dateFns.format(new Date(value), 'dd/MM/yyyy') },
        { field: 'dsTipoDocumento', headerName: 'Tipo da Nota', minWidth: 130, flex: 1 },
        { field: 'vlDocumento', headerName: 'Total', type: 'number', align: 'right', minWidth: 140, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'vlTotalLiquido', headerName: 'Total Sem Impostos', type: 'number', align: 'right', minWidth: 140, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'vlTotalBaseFaturamento', headerName: 'Total Base Faturamento', type: 'number', align: 'right', minWidth: 150, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'vlTotal', headerName: 'Total Custo', type: 'number', align: 'right', minWidth: 100, valueFormatter: ({ value }) => brlPrice(value) },
    ];

    if (hasPermission(['FINANCEIRO_NOTA_FISCAL_DETALHAR'])) {
        columns.push({
            field: 'detalhes',
            headerName: 'Detalhes',
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            minWidth: 100,
            renderCell: (params) => <TinyButton onClick={() => handleClickNotaFiscalRow(params.row)}>Detalhes</TinyButton>,
        });
    }

    const rows = generateNotaFiscalList(data);
    const [quinzenaInicio, quinzenaFim] = dateToQuinzenaString({ nrQuinzena, nrMes, nrAno });

    return (
        <Block>
            <SubTitle
                label={
                    <React.Fragment>
                        Notas Fiscais Consideradas
                        <Tooltip title={`A geração dos royalties da quinzena (${quinzenaInicio} - ${quinzenaFim}) foi realizada considerando as seguintes notas fiscais`}>
                            <InfoOutlinedIcon
                                color="success"
                                sx={{
                                    ml: 2,
                                    transform: 'translateY(3px)',
                                }}
                            />
                        </Tooltip>
                    </React.Fragment>
                }
            />

            <PanelGrid
                rows={rows}
                rowsCount={rows.length}
                reload={refetch}
                loading={fetchStatus === 'fetching'}
                columns={columns}
                remote={false}
                summaryPrefix={false}
                summary={['vlTotalLiquido', 'vlDocumento', 'vlTotalBaseFaturamento', 'vlTotal']}
            />
        </Block>
    );
}
