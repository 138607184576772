import React from 'react';

import { GridRowModel } from '@mui/x-data-grid-pro';

import { RevendedorEditButton, RevendedorFileButton } from 'pages/revendedor/revendedor/components';

type RevendedorActionColumn = {
    row: GridRowModel;
};

export function RevendedorActionColumn({ row }: RevendedorActionColumn): JSX.Element {
    return (
        <>
            <RevendedorEditButton documento={row.documento} />

            <RevendedorFileButton files={row.files} />
        </>
    );
}
