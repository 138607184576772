import React from 'react';

import { Divider } from '@mui/material';

import { brlCep, brlCnpj } from 'util/format';
import { LabeledGrid } from 'components/grid';
import { translateKey } from 'util/i18n';
import { Block } from 'components/page';
import { LabeledGridListProps } from 'components/grid/LabeledGrid';
import { SubTitle } from 'components/text';

interface EmpresaCepProps {
    dsCidade: string;
    nrCep?: string;
    sgEstado: string;
}

interface EmpresaDataProps {
    dsRazaoSocial?: string;
    dsEndereco: string;
    dsCnpj: string;
    dsBairro?: string;
    dsNomeFantasia?: string;
    cep: EmpresaCepProps;
}

interface EspelhoFppHeaderProps {
    emitente: EmpresaDataProps;
    destinatario: EmpresaDataProps;
}

const generateInfo = (key: string, value: string): LabeledGridListProps => {
    return {
        label: translateKey(key),
        value,
    };
};

const generateHeaderLists = (data: EmpresaDataProps | undefined, title: string): LabeledGridListProps[] => {
    const info: LabeledGridListProps[] = [];

    if (data) {
        const { dsNomeFantasia, dsRazaoSocial, dsCnpj, dsEndereco, dsBairro, cep } = data;
        const { sgEstado, nrCep, dsCidade } = cep;
        const name = dsNomeFantasia || dsRazaoSocial;

        info.push(generateInfo(title, name as string));
        info.push(generateInfo('dsCnpj', brlCnpj(dsCnpj)));
        info.push(generateInfo('dsEndereco', `${dsEndereco}${nrCep ? `, CEP ${brlCep(nrCep)}` : ''}`));

        if (dsBairro) {
            info.push(generateInfo('dsBairro', dsBairro));
        }

        info.push(generateInfo('dsCidade', dsCidade));
        info.push(generateInfo('sgEstado', sgEstado));
    }

    return info;
};

export function EspelhoFppHeader({ emitente, destinatario }: EspelhoFppHeaderProps): JSX.Element {
    const emitenteData = generateHeaderLists(emitente, 'emitente') ?? [];
    const destinatarioData = generateHeaderLists(destinatario, 'destinatario') ?? [];

    return (
        <Block>
            <SubTitle label="Documento Contábil de Cobrança" />

            <LabeledGrid list={emitenteData} />

            <Divider
                sx={{
                    my: 2,
                }}
            />

            <LabeledGrid list={destinatarioData} />
        </Block>
    );
}
