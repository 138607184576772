import React from 'react';

import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { UploadButton } from 'components/upload';
import { RevendedorFilesPaper } from 'pages/revendedor/revendedor/components/atoms/RevendedorFilesPaper';
import { RevendedorNewFilesPaper } from 'pages/revendedor/revendedor/components/atoms/RevendedorNewFilesPaper';
import { Revendedor } from 'pages/revendedor/revendedor/types';
import { brlList } from 'util/format';

export function RevendedorFilesBlock(): JSX.Element {
    const { values, setFieldValue } = useFormikContext<Revendedor>();
    const accept = ['.pdf', '.doc', '.docx'];

    return (
        <Block>
            <SubTitle label="Anexos" />

            <UploadButton
                multiple
                label="Selecionar arquivos"
                accept={accept}
                sizeLimit={5}
                onChangeMultiple={(files) => setFieldValue('newFiles', [...(values.newFiles || []), ...files])}
            />

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                Extensões aceitas: {brlList(accept)}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: 2 }}>
                <RevendedorNewFilesPaper />

                <RevendedorFilesPaper files={values.files} />
            </Box>
        </Block>
    );
}
