import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { QuebrasListComp } from 'pages/logistica/quebras/components';

export function QuebrasList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['QUEBRAS_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <QuebrasListComp />
        </ListagemProvider>
    );
}