import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RequestBandaPreco } from '../types';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useGetBandaPreco(): UseQueryResult<RequestBandaPreco> {
    const { filter, page, sort, limit } = useListagemContext();
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/dre/dremercadologicagrupoexibicaobandapreco',
        sort: sort?.length ? sort : [{ property: 'idDreMercadologicaGrupoExibicaoBandaPreco', direction: 'ASC' }],
        filter,
        page,
        limit,
    };

    return useQuery<RequestBandaPreco>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data));
}
