import { useParams } from 'react-router-dom';

import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';

import { Revendedor } from '../types';

import { usePostRevendedorFiles } from './usePostRevendedorFiles';

import { useFormContext } from 'context/FormContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';

type UsePostRevendedorReturn = {
    handleSubmit: (formData: Revendedor, formik: FormikHelpers<Revendedor>) => void;
    isLoading: boolean;
};

const formatCdSapLojas = (formData: Revendedor): Revendedor => {
    formData.listLojas = formData.listLojas.map((loja) => ({
        ...loja,
        cdSap: loja.cdLoja,
    }));

    return formData;
};

export function usePostRevendedor(): UsePostRevendedorReturn {
    const { handleSubmitFiles, isLoadingFiles } = usePostRevendedorFiles();
    const { mutate, isLoading } = useFormMutation();
    const { setContent } = useFormContext();
    const { showError } = useShowError();
    const { id } = useParams();

    const handleSubmit = (formData: Revendedor, formik: FormikHelpers<Revendedor>): void => {
        formatCdSapLojas(formData);

        mutate(
            {
                url: `/integracao-revendedor/cadastrorevendedor${id ? '/documento' : ''}`,
                idField: 'documento',
                method: id ? 'PUT' : 'POST',
                preventSnack: true,
                formik,
                formData,
            },
            {
                onSuccess: ({ data }: any) => {
                    const newData = data.data;

                    if (formData.newFiles?.length) {
                        handleSubmitFiles({ newData, newFiles: formData.newFiles });
                    } else {
                        setContent(newData);

                        enqueueSnackbar('Revendedor salvo com sucesso!', { variant: 'success' });
                    }
                },
                onError: (error: any) => showError(error, 'Não foi possível salvar o revendedor. Tente novamente'),
            },
        );
    };

    return { handleSubmit, isLoading: isLoading || isLoadingFiles };
}
