import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';
import React from 'react';

export interface StoreOptionsProps {
    idDetalhamentoCreditoDebitoClassificacao: number;
    dsDetalhamentoCreditoDebitoClassificacao: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function ClassificacaoAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {} }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Classificação"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsDetalhamentoCreditoDebitoClassificacao}"
            optionStringTemplate="{dsDetalhamentoCreditoDebitoClassificacao}"
            requestOptions={{
                url: '/financeiro/detalhamentocreditodebitoclassificacao',
                sort: [{ property: 'idDetalhamentoCreditoDebitoClassificacao', direction: 'ASC' }],
                limit: 50,
            }}
            isOptionEqualToValue={(option: StoreOptionsProps, value: StoreOptionsProps) =>
                value && option && option.idDetalhamentoCreditoDebitoClassificacao === value.idDetalhamentoCreditoDebitoClassificacao
            }
            onChange={onChange}
            disabled={disabled}
            sx={sx}
        />
    );
}
