import React, { Dispatch, SetStateAction, useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { ConfirmModalProps, RowProps } from 'pages/compra/pedido-realizado/types';
import { getSuccessMessage, SuccessMessageProps } from 'util/success';

type PedidoRealizadoDeleteBtn = {
    rowProps: RowProps;
    isDisabledActions: boolean;
    setIsDisabledActions: Dispatch<SetStateAction<boolean>>;
    setIsOpenConfirmModal: Dispatch<SetStateAction<boolean>>;
    setDataConfirmModal: Dispatch<SetStateAction<ConfirmModalProps | null>>;
};

export function PedidoRealizadoDeleteBtn({
    isDisabledActions,
    setIsDisabledActions,
    setIsOpenConfirmModal,
    setDataConfirmModal,
    rowProps,
}: PedidoRealizadoDeleteBtn): JSX.Element {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const Api = useServiceApiContext();
    const { idPedido } = rowProps;

    const handleDelete = (): void => {
        setIsDisabledActions(true);
        setIsDeleting(true);

        if (idPedido) {
            Api.delete(`/compra/pedidocompra/${idPedido}`)
                .then((result: SuccessMessageProps) => {
                    const message = getSuccessMessage(result);

                    enqueueSnackbar(message, { variant: 'success' });

                    queryClient.invalidateQueries(['getListPedidosRealizados']).finally(() => setIsDisabledActions(false));

                    setIsDeleting(false);
                })
                .catch((error) => {
                    setIsDeleting(false);
                    setIsDisabledActions(false);

                    showError(error, 'Não foi possível cancelar o pedido. Tente novamente');
                });
        }
    };

    return (
        <LoadingButton
            color="inherit"
            startIcon={<DeleteOutlinedIcon />}
            loading={isDeleting}
            disabled={isDisabledActions}
            onClick={() => {
                setIsOpenConfirmModal(true);
                setDataConfirmModal({ callback: handleDelete });
            }}
        >
            Excluir
        </LoadingButton>
    );
}
