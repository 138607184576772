import React from 'react';

import { useParams } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { alpha, Button, Tooltip } from '@mui/material';

import { Block } from 'components/page';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';

export function ConfiguracaoPedidoCompraMenuAside(): JSX.Element {
    const { activeStep, setActiveStep, listStep } = useConfiguracaoPedidoCompraMenuAsideContext();

    return (
        <Block sx={{ display: 'flex', flexDirection: 'column', position: 'sticky', top: 16, zIndex: 3 }}>
            {listStep.map((item) => {
                const { idConfigPedidoStep, dsConfigPedidoStep, fgSaved, fgDirty, fgDisabled } = item;

                return (
                    <Button
                        key={idConfigPedidoStep}
                        disabled={fgDisabled}
                        onClick={() => setActiveStep(idConfigPedidoStep)}
                        startIcon={
                            fgSaved && !fgDirty ? (
                                <Tooltip title="Salvo">
                                    <CheckCircleIcon />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Não Salvo">
                                    <ErrorIcon />
                                </Tooltip>
                            )
                        }
                        sx={{
                            'justifyContent': 'flex-start',
                            'textAlign': 'start',
                            'textTransform': 'none',
                            'fontWeight': 700,
                            'fontSize': '1rem',
                            'color': 'primary.main',
                            'p': 0.5,
                            'background': (theme) => (activeStep === idConfigPedidoStep ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.background.paper, 0.05)),

                            '&:hover': {
                                backgroundColor: (theme) => (activeStep === idConfigPedidoStep ? alpha(theme.palette.primary.main, 0.15) : alpha(theme.palette.primary.main, 0.05)),
                            },
                        }}
                    >
                        {dsConfigPedidoStep}
                    </Button>
                );
            })}
        </Block>
    );
}
