export interface Cargo {
    idCargo: number;
    dsCargo: string;
    cdCargo: string;
    vlLimiteCredito: number;
}
export interface CargoRequest {
    idCargo: number;
    dsCargo: string;
    cdCargo: string;
    vlLimiteCredito: number;
}

export const initialValuesFormik: CargoRequest = {
    idCargo: 0,
    dsCargo: '',
    cdCargo: '',
    vlLimiteCredito: 0,
};
