import React, { ReactNode } from 'react';

import { Box, alpha, useTheme } from '@mui/material';

interface InactiveBoxProps {
    active?: boolean;
    children: ReactNode;
}

export function InactiveBox({ active = true, children }: InactiveBoxProps): JSX.Element {
    const theme = useTheme();

    let style: any = {
        'height': '100%',
        '& .MuiPaper-root': {
            boxShadow: 'none',
            backgroundColor: alpha(theme.palette.common.white, 0.2),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: alpha(theme.palette.common.black, 0.05),
        },

        '& .box-img-preview': {
            opacity: 0.25,
        },
    };

    if (active) {
        style = {
            height: '100%',
        };
    }

    return <Box sx={style}>{children}</Box>;
}
