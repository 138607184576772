import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { GruposEconomicosProps } from 'pages/cadastro/usuario/types';

interface GrupoEconomicoListGridProps {
    gruposEconomicos: GruposEconomicosProps[];
}

export function GrupoEconomicoListGrid({ gruposEconomicos }: GrupoEconomicoListGridProps): JSX.Element {
    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'idGrupoEconomico', headerName: 'Código', width: 120, minWidth: 80 },
            { field: 'dsGrupoEconomico', headerName: 'Nome Grupo Econômico', flex: 1, minWidth: 150 },
        ],
        [],
    );

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(gruposEconomicos) && gruposEconomicos.length) {
            return gruposEconomicos.map((item) => ({
                id: item.idGrupoEconomico,
                idGrupoEconomico: item.idGrupoEconomico,
                dsGrupoEconomico: item.dsGrupoEconomico,
            }));
        }

        return [];
    }, [gruposEconomicos]);

    return (
        <Box sx={{ mt: 2 }}>
            <PanelGrid rows={rows} rowsCount={rows.length} reload={false} loading={false} columns={columns} remote={false} />
        </Box>
    );
}
