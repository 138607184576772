import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { CissTextField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { ConfirmModal, ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';

interface StoreProps {
    idLoja: number;
    cdLoja: number;
    dsNomeFantasia: string;
}

interface RequestStoreProps {
    data: StoreProps[];
    total: number;
}

interface ChangeEmpresaLogadaModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
}

interface ChangeTokenResponseProps {
    data: {
        access_token: string;
    };
}

export function ChangeEmpresaLogadaModal({ open, setOpen }: ChangeEmpresaLogadaModalProps): JSX.Element {
    const [query, setQuery] = useState('');
    const [isChanging, setIsChanging] = useState(false);
    const [openEmpresaModal, setOpenEmpresaModal] = useState(false);
    const { tokenInfo, accessToken } = useGenericAuth();
    const { mutate } = useFormMutation<ChangeTokenResponseProps, unknown>();
    const { showError } = useShowError();
    const [selected, setSelected] = useState<null | number>(null);
    const { enqueueSnackbar } = useSnackbar();
    const { limit, page } = useListagemContext();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { setToken } = useGenericAuth();
    const { tipoAuth } = useGetDomainConfig();

    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/loja',
        query,
        limit,
        page,
        sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
        columns: 'idLoja,cdLoja,dsNomeFantasia',
    };

    const { isFetching, data, refetch } = useQuery<RequestStoreProps>([requestOptions], () =>
        RequestListagem(requestOptions).then((res) => res.data),
    );

    const columns: GridColDef[] = [
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: '',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 60,
            renderCell: ({ row }) => {
                return (
                    <Checkbox
                        checked={selected === row.id}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelected(row.id);
                            }
                        }}
                    />
                );
            },
        },
        { field: 'cdLoja', headerName: 'Código', type: 'number', width: 80, minWidth: 70 },
        { field: 'dsNomeFantasia', headerName: 'Nome Fantasia', minWidth: 110, flex: 1 },
    ];

    const generateMovimentacaoFiscalList = (data: StoreProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data
                .filter((item) => item.idLoja !== tokenInfo?.idEmpresa)
                .map((item) => ({
                    id: item.idLoja,
                    cdLoja: item.cdLoja,
                    dsNomeFantasia: item.dsNomeFantasia,
                }));
        }

        return [];
    };

    const rows = generateMovimentacaoFiscalList(data?.data);

    const confirmEmpresaChange = (): void => {
        if (tipoAuth !== 'cissLiveAuth') {
            throw new Error('O tipo e contexto do auth são necessários para a aplicação funcionar');
        }

        setIsChanging(true);

        const formData = {
            idEmpresa: selected,
            dsToken: accessToken,
        };

        mutate(
            {
                url: '/auth2/token',
                preventSnack: true,
                formData,
            },
            {
                onSuccess: (response) => {
                    const { data } = response;
                    const { access_token } = data || {};

                    if (access_token) {
                        setToken(access_token);

                        if (pathname === '/compra/pedido') {
                            navigate(pathname, { replace: true });
                        }

                        location.reload();
                    } else {
                        setIsChanging(false);
                        enqueueSnackbar('Ocorreu um erro ao alterar a loja logada.', { variant: 'error' });
                    }
                },
                onError: (error) => {
                    setIsChanging(false);
                    showError(error, 'Ocorreu um erro ao alterar a loja logada.');
                },
            },
        );
    };

    return (
        <>
            <ConfirmModal
                open={openEmpresaModal}
                setOpen={setOpenEmpresaModal}
                text="Ao confirmar a troca de empresa, seu sistema será recarregado e todas alterações não salvas perdidas, gostaria de continuar?"
                confirm={() => confirmEmpresaChange()}
            />

            <ContentModal
                open={open}
                setOpen={setOpen}
                closeButton
                centered={false}
                sx={{
                    maxWidth: 960,
                    width: '90vw',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 1,
                }}
            >
                <>
                    <SubTitle label="Trocar Empresa" />

                    <CissTextField
                        size="small"
                        label="Pesquisar"
                        placeholder="Nome empresa..."
                        deboundTime={500}
                        sx={{ mb: 2, width: '50%' }}
                        onChange={(e) => setQuery(e.target.value)}
                    />

                    <Box sx={{ height: 600 }}>
                        <PanelGrid
                            autoHeight={false}
                            rows={rows}
                            reload={refetch}
                            columns={columns}
                            loading={isFetching}
                            rowsCount={data?.total || 0}
                            onClickRow={([idLoja]) => setSelected(idLoja as number)}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <Button variant="outlined" onClick={() => setOpen(false)}>
                            Cancelar
                        </Button>

                        <LoadingButton variant="contained" onClick={() => setOpenEmpresaModal(true)} loading={isFetching || isChanging}>
                            Confirmar
                        </LoadingButton>
                    </Box>
                </>
            </ContentModal>
        </>
    );
}
