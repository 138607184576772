import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface ArquivoTag {
    idTagCatalogoArquivo?: number;
    dsTagCatalogoArquivo: string;
}

export const initialTagValues: ArquivoTag = {
    dsTagCatalogoArquivo: '',
};

export interface RequestArquivoTag extends RequestListagemProps {
    data: ArquivoTag[];
}
