import React, { useState, useEffect, useCallback, ChangeEvent, FocusEvent, forwardRef } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

interface ResponseCustomUpdateFieldValueProps {
    value: string | number;
    rawValue: string;
}

export type CustomUpdateFieldValueProps = string | ResponseCustomUpdateFieldValueProps;

export type CissTextFieldProps = Omit<TextFieldProps, 'name' | 'error'> & {
    value?: string | number | unknown;
    customUpdateFieldValue?: (value: string | number) => CustomUpdateFieldValueProps;
    deboundTime?: number;
    error?: string | boolean;
    tabIndex?: number;
    name?: string;
};

export const CissTextField = forwardRef(({ customUpdateFieldValue, value: userValue, onChange, onBlur, error, deboundTime = 150, tabIndex, ...props }: CissTextFieldProps, ref) => {
    const [value, setValue] = useState<string | number>('');
    const [rawValue, setRawValue] = useState<string>();

    // atualiza valor do field e dispara evento que tiver listener onChange, onBlur com valores customizados
    const debouncedHandleFieldChange = useDebouncedCallback((value: string, type: 'change' | 'blur') => {
        let eventValue = value;

        if (rawValue === 'string' && rawValue !== value) {
            eventValue = rawValue as string;
        }

        // dispara change
        if (type === 'change' && typeof onChange === 'function') {
            onChange({ target: { value: eventValue } } as ChangeEvent<HTMLInputElement>);
        }

        // dispara blur
        if (type === 'blur' && typeof onBlur === 'function') {
            onBlur({ target: { value: eventValue } } as FocusEvent<HTMLInputElement, Element>);
        }
    }, deboundTime);

    // gera valores atualizos para o field e para o rawValue (eventos de change, blur)
    const updateFieldValue = useCallback(
        (inputValue: string | number): void => {
            const customValue = typeof customUpdateFieldValue === 'function' && customUpdateFieldValue(inputValue);

            let raw = inputValue.toString();
            let val = inputValue;

            if (typeof customValue === 'string') {
                raw = customValue;
                val = customValue;
            } else if (typeof customValue === 'object') {
                raw = customValue.rawValue;
                val = customValue.value;
            }

            setRawValue(raw);
            setValue(val);
        },
        [customUpdateFieldValue],
    );

    // callback para evento de change do valor do input
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            const value = e.target.value;

            updateFieldValue(value);
            debouncedHandleFieldChange(value, 'change');
        },
        [debouncedHandleFieldChange, updateFieldValue],
    );

    // callback para evento de blur do valor do input
    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement, Element>): void => {
            const value = e.target.value;

            debouncedHandleFieldChange.cancel();

            updateFieldValue(value);
            debouncedHandleFieldChange(value, 'blur');
        },
        [debouncedHandleFieldChange, updateFieldValue],
    );

    // manipula valor da tela aplicando no field e tratando preventEmptyField quando necessário
    useEffect(() => {
        const customValue = typeof userValue === 'string' || typeof userValue === 'number' ? userValue : null;
        const newValue = customValue ?? value;

        if (newValue !== value) {
            updateFieldValue(newValue);
        }
    }, [userValue]);

    return (
        <TextField
            value={value}
            onBlur={handleBlur}
            error={Boolean(error)}
            helperText={typeof error === 'string' && error}
            onChange={handleChange}
            inputProps={{ tabIndex: tabIndex }}
            inputRef={ref}
            {...props}
        />
    );
});
