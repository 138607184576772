import React, { useCallback } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import * as dateFns from 'date-fns';
import { Event } from 'react-big-calendar';

import { CalendarioEventoFinanceiroTitulosProps } from 'pages/calendario/types';
import { brlDate, brlPrice } from 'util/format';

interface CalendarioListEventContentProps {
    event: Event;
}

export function CalendarioListAccordionContent({ event }: CalendarioListEventContentProps): JSX.Element {
    const { resource, title, start = new Date() } = event;
    const { tpCalendarioEvento = null, titleEvent } = resource;

    const LabelValueBox = useCallback(
        ({ item }: { item: { label: string; value: string | number } }) => (
            <Box display="flex" justifyContent="space-between">
                {item.label}
                <strong>{item.value}</strong>
            </Box>
        ),
        [],
    );

    switch (tpCalendarioEvento) {
        case 'EVENTO_FINANCEIRO': {
            const { titulos } = resource;

            return (
                <Box sx={{ maxHeight: 200, overflow: 'auto', overflowX: 'hidden', mr: -1, pr: 1 }}>
                    {titulos.map((item: CalendarioEventoFinanceiroTitulosProps, index: number) => {
                        const { nrTitulo, vlTitulo, dtVencimento } = item;
                        const financeiroList = [
                            { label: 'Número do título', value: nrTitulo },
                            { label: 'Valor', value: `${brlPrice(vlTitulo)}` },
                            { label: 'Data de vencimento', value: `${brlDate(dtVencimento)}` },
                        ];

                        return (
                            <React.Fragment key={nrTitulo + Math.random()}>
                                <Divider sx={{ display: index ? 'auto' : 'none', my: 1 }} />

                                {financeiroList.map((item) => (
                                    <LabelValueBox item={item} key={item.label} />
                                ))}
                            </React.Fragment>
                        );
                    })}
                </Box>
            );
        }
        case 'EVENTO_LIMITE_GRADE':
        case 'EVENTO_PREVISAO_ENTREGA': {
            const { dtLimitePedido, dtColeta, dtEntrega } = resource;
            const logisticaList = [
                { label: 'Data limite', value: dateFns.format(dtLimitePedido, 'dd/MM/yyyy') },
                { label: 'Data de coleta', value: dateFns.format(dtColeta, 'dd/MM/yyyy') },
                { label: 'Previsão de entrega', value: dateFns.format(dtEntrega, 'dd/MM/yyyy') },
            ];

            return (
                <React.Fragment key={`${title} + ${Math.random()}`}>
                    {logisticaList.map((item) => (
                        <LabelValueBox item={item} key={item.label} />
                    ))}
                </React.Fragment>
            );
        }
        default: {
            return (
                <React.Fragment key={`${titleEvent} + ${Math.random()}`}>
                    <Typography variant="body1" component="p">
                        {titleEvent} - {dateFns.format(new Date(start), 'dd/MM/yyyy')}
                    </Typography>
                </React.Fragment>
            );
        }
    }
}
