import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface ControleEstoqueOptionsProps {
    idTipoControleEstoquePedido: number;
    dsTipoControleEstoquePedido: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function ControleEstoqueAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Controle de Estoque"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsTipoControleEstoquePedido}"
            optionStringTemplate="{dsTipoControleEstoquePedido}"
            requestOptions={{
                url: '/gestao/tipocontroleestoquepedido',
                sort: [{ property: 'idTipoControleEstoquePedido', direction: 'ASC' }],
                limit: 50,
            }}
            isOptionEqualToValue={(option: ControleEstoqueOptionsProps, value: ControleEstoqueOptionsProps) =>
                value && option && option.idTipoControleEstoquePedido === value.idTipoControleEstoquePedido
            }
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
