import React from 'react';

import { Divider, Grid, Link, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Block } from 'components/page';
import { brlDate, brlPercentual, brlPrice } from 'util/format';
import { translateKey } from 'util/i18n';
import { TabuledGrid } from 'components/grid';

const getResumoLabel = (key: string): JSX.Element => {
    const tooltips = {
        vlBaseRoyalty: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
        peRoyalty: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
        vlRoyalty: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
        vlTotalDebito: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
        vlTotalCredito: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
        vlOutrosDebitos: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
        vlDocumento: 'O cálculo de FPP bruto é feito considerando as variáveis XYZ + XY - X',
    };
    const name = translateKey(key);
    const tooltip = key in tooltips && tooltips[key as keyof typeof tooltips];

    return (
        <React.Fragment>
            {name}
            {tooltip && (
                <Tooltip title={tooltip}>
                    <InfoOutlinedIcon
                        color="primary"
                        sx={{
                            ml: 1,
                            fontSize: '1.25rem',
                            marginTop: '-4px',
                            transform: 'translateY(4px)',
                        }}
                    />
                </Tooltip>
            )}
        </React.Fragment>
    );
};

const generateDetailLists = (data: any | undefined): any => {
    const first: any = [];
    const second: any = [];
    const link: any = [];
    const last: any = [];

    const firstKeys = ['cdLoja', 'nrFpp'];
    const secondKeys = ['dtEmissao'];
    const linkKeys = [''];
    const boldKeys = ['nrFpp', 'dtEmissao', 'vlFpp'];

    Object.entries(data).forEach(([key, value]) => {
        const info = {
            label: getResumoLabel(key),
            value,
        };

        if (key.startsWith('vl')) {
            info.value = <strong>{brlPrice(info.value as number)}</strong>;
        }

        if (key.startsWith('pe')) {
            info.value = <strong>{brlPercentual(value as string)}</strong>;
        }

        if (key.startsWith('dt') && !key.toLowerCase().includes('formatada')) {
            info.value = <strong>{brlDate(value as string)}</strong>;
        }

        if (boldKeys.includes(key)) {
            info.value = <strong>{info.value as string}</strong>;
        }

        if (key.startsWith('lk')) {
            info.value = (
                <Link href={info.value as string} target="_blank">
                    {info.value as string}
                </Link>
            );
        }

        if (firstKeys.includes(key)) {
            first.push(info);
        } else if (secondKeys.includes(key)) {
            second.push(info);
        } else if (linkKeys.includes(key)) {
            link.push(info);
        } else {
            last.push(info);
        }
    });

    return [first, second, link, last];
};

export function EspelhoFppResumoList({ list }: any): JSX.Element {
    const [first, second, link, last] = generateDetailLists(list);

    return (
        <Block>
            <Grid container spacing={1}>
                <Grid item flex={1}>
                    <TabuledGrid list={first} />
                </Grid>

                <Grid item flex={1}>
                    <TabuledGrid list={second} />
                </Grid>

                <Grid item flex={1}>
                    <TabuledGrid list={link} />
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <TabuledGrid list={last} />
        </Block>
    );
}
