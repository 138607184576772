import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Grid } from '@mui/material';

import { TagPecaMarketingAutoComplete, TagPecaTradeAutoComplete } from 'components/autocompletes';
import { StatusField, CissTextField, MarketingFormatoPecaField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { getTypeMarketing, MarketingTradePage } from 'pages/marketing-trade/types';

export const MarketingTradeListFilter = forwardRef(({ isMarketing = false }: MarketingTradePage, ref) => {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsPecaMarketing',
        operator: 'like',
        value: '',
    });

    const [filterFormato, setFilterFormato] = useState<FilterValuesProps>({
        property: 'idPecaMarketingFormato',
        operator: 'eq',
        value: null,
        idProperty: 'idPecaMarketingFormato',
    });

    const [filterTags, setFilterTags] = useState<FilterValuesProps>({
        property: 'idTagsPecaTrade',
        operator: 'in',
        value: [],
        idProperty: 'idTagPecaTrade',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: hasPermission([isMarketing ? 'MARKETING_PECA_MODERAR' : 'TRADE_PECA_MODERAR']) ? null : true,
        idProperty: 'vlStatus',
    });

    const [filterCampanha, setFilterCampanha] = useState<FilterValuesProps>({
        property: 'idCampanhaMarketing',
        operator: 'in',
        value: [],
    });

    const filterTipoMarketing: FilterValuesProps = {
        property: 'idTipoMarketing',
        operator: 'eq',
        value: getTypeMarketing(isMarketing),
    };

    const filters = [
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterFormato, setter: setFilterFormato },
        { value: filterTags, setter: setFilterTags },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterCampanha, setter: setFilterCampanha },
        { value: filterTipoMarketing },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    useImperativeHandle(ref, () => ({ setFilterCampanha, filterCampanha }), [filterCampanha]);

    useEffect(() => {
        startFilter();
    }, [filterCampanha]);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField label="Nome ou título da peça" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <MarketingFormatoPecaField value={filterFormato.value} onChange={(e, value) => setFilterFormato((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                {isMarketing ? (
                    <TagPecaMarketingAutoComplete value={filterTags.value} onChange={(e: any, value: any) => setFilterTags((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                ) : (
                    <TagPecaTradeAutoComplete value={filterTags.value} onChange={(e: any, value: any) => setFilterTags((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                )}
            </Grid>

            {hasPermission([isMarketing ? 'MARKETING_PECA_MODERAR' : 'TRADE_PECA_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
});
