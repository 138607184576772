import React, { useRef, useState, KeyboardEvent, useEffect, useCallback, ChangeEvent, FocusEvent } from 'react';

import { SxProps, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { useValidation } from 'hooks/useValidation';
import { brlCep, brlDecimal, brlPhone, brlPrice } from 'util/format';

type InputTypeProps = 'number' | 'decimal' | 'currency';

interface CissNumberFieldProps {
    minValue?: number;
    maxValue?: number;
    decimalPrecision?: number;
    forceMaxValue?: boolean;
    forceMinValue?: boolean;
    preventEmptyField?: boolean;
    allowNegative?: boolean;
    maxLength?: number;
    forceMaxLength?: boolean;
    maxChar?: number;
    forceMaxChar?: boolean;
    inputType?: InputTypeProps;
    autoComplete?: 'on' | 'off';
    label?: string;
    value?: string | number | null;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    sx?: SxProps;
    size?: 'small' | 'medium';
    tabIndex?: number;
    name?: string;
}

enum Errors {
    MAXVALUE = 'Valor máximo deve ser',
    MINVALUE = 'Valor mínimo deve ser',
    MAXLENGTH = 'Limite de caracteres máximo deve ser',
}

const acceptValueKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '.', ','];
const acceptDeleteKeys = ['Backspace', 'Delete'];
const acceptDirectionKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
const acceptModifiers = ['ArrowUp', 'ArrowDown'];
const acceptComboKeys = ['a', 'c', 'v'];

const limitCharExponencial = 15;

export function CissNumberField(props: CissNumberFieldProps): JSX.Element {
    const { validValue } = useValidation();

    const [value, setValue] = useState<string | number>('');
    const [rawValue, setRawValue] = useState<string>();
    const [error, setError] = useState<string>();

    const inputRef = useRef<HTMLInputElement>();

    const {
        maxLength = 18,
        forceMaxLength = true,
        minValue = 0,
        maxValue = Infinity,
        forceMaxValue,
        forceMinValue = true,
        autoComplete = 'off',
        inputType = 'number',
        value: userValue,
        label,
        onChange,
        onBlur,
        placeholder,
        disabled,
        error: customError,
        onFocus,
        sx,
        size,
        tabIndex,
        name,
        allowNegative,
        preventEmptyField = true,
        maxChar,
        forceMaxChar,
    } = props;

    let { decimalPrecision = 0 } = props;

    // campo decimal default = 2 decimalPrecision
    if (inputType === 'decimal' && !decimalPrecision) {
        decimalPrecision = 2;
    }

    // campo currency não pode ter menos de 2 decimalPrecision
    if (inputType === 'currency' && decimalPrecision < 2) {
        decimalPrecision = 2;
    }

    // retorna somente numeros (remove ponto, virgula, etc...)
    const justNumber = useCallback((value: string): string => {
        if (/,/.test(value)) {
            return value.replace(/\D/g, '');
        } else if (value && value.split('.').length > 1 && value.split('.')[1].length === 1) {
            return parseFloat(value).toFixed(decimalPrecision).replace(/\D/g, '');
        }
        return value.replace(/\D/g, '');
    }, []);

    // normaliza casas decimais na esquerda, baseado no decimalPrecision
    const normalizeDecimals = useCallback(
        (value: string, fill = '0', start = true): string => {
            if (start) {
                return value.padStart(decimalPrecision, fill);
            } else {
                return value.padEnd(decimalPrecision, fill);
            }
        },
        [decimalPrecision],
    );

    // calcula novo valor baseado na tecla pressionada (ArrowUp ou ArrowDown)
    const calculeNewValue = useCallback(
        (value: string, direction: string): string => {
            let newValue = Number(value) - 1;

            if (direction === 'ArrowUp') {
                newValue = Number(value) + 1;
            }

            return normalizeDecimals(newValue.toString());
        },
        [normalizeDecimals],
    );

    // atualiza valor do field e dispara evento que tiver listener onChange, onBlur com valores customizados
    const debouncedHandleFieldChange = useDebouncedCallback((value: string, type: 'change' | 'blur') => {
        let eventValue = value;

        if (typeof rawValue === 'string' || (typeof rawValue === 'number' && rawValue !== value)) {
            eventValue = rawValue as string;
        }

        // dispara change
        if (type === 'change' && typeof onChange === 'function') {
            onChange({ target: { value: eventValue } } as ChangeEvent<HTMLInputElement>);
        }

        // dispara blur
        if (type === 'blur' && typeof onBlur === 'function') {
            onBlur({ target: { value: eventValue } } as FocusEvent<HTMLInputElement, Element>);
        }
    }, 150);

    // workaround para evitar valores exponenciais
    const limitExponencial = useCallback(
        (value: string): string => {
            const numeric = justNumber(value);

            let newValue = value;

            if (numeric.length > limitCharExponencial) {
                newValue = numeric.substring(0, limitCharExponencial);

                console.warn('TODO: Quantidade limite do caracteres sem exponencial atingido, desenvolver solução para tal com BigInt ou BigDecimal');
            }

            return newValue;
        },
        [justNumber],
    );

    // retorna o operador (-) quando o valor original for negativo
    const getOperatorNegative = useCallback(
        (value: string): string => {
            const firstChar = value.charAt(0);
            const lastChar = value.charAt(value.length - 1);

            let negative = false;

            if (allowNegative) {
                if (firstChar === '-') {
                    negative = true;
                } else if (lastChar === '-' && Number(justNumber(value)) === 0) {
                    negative = true;
                }
            }

            return negative ? '-' : '';
        },
        [allowNegative, justNumber],
    );

    // equalize valor inteiro recebido pelo backend para decimal, ex:
    // valor inicial pode ser 50, mas com decimalPrecision de 2 deve ser aplicado 50.00
    const equalizeDecimalBackendNumber = useCallback(
        (value: number): string => {
            const valueString = value.toString();
            const negative = getOperatorNegative(valueString);

            let equalizedValue = valueString;

            // valor inicial pode ser 50, mas com decimal precision deve ser aplicado 50.00
            if (decimalPrecision && value % 1 === 0) {
                equalizedValue = `${negative}${Number(justNumber(valueString)).toFixed(decimalPrecision)}`;
            }

            return equalizedValue;
        },
        [decimalPrecision, getOperatorNegative, justNumber],
    );

    // retorna valor numerico, utilizado como rawValue e retornado nos eventos de onBlur e onChange
    const getNumericValue = useCallback(
        (value: string): string => {
            const negative = getOperatorNegative(value);

            let numericValue = justNumber(value);

            if (decimalPrecision) {
                numericValue = `${negative}${Number(numericValue) / Number('1'.padEnd(decimalPrecision + 1, '0'))}`;
            }

            return numericValue;
        },
        [decimalPrecision, getOperatorNegative, justNumber],
    );

    // limpa o valor (zeros a esquerda) para inputType: number, decimal, currency
    const clearValue = useCallback(
        (value: string): string => {
            const enabled = ['number', 'decimal', 'currency'].includes(inputType);

            if (enabled) {
                return justNumber(value).replace(/^0+/, '');
            }

            return value;
        },
        [inputType, justNumber],
    );

    // captura o valor inteiro do valor recebido baseado no decimalPrecision
    const getIntFromValue = useCallback(
        (value: string): string => {
            let int = value;

            if (decimalPrecision) {
                int = value.substring(0, value.length - decimalPrecision);
            }

            return int || '0';
        },
        [decimalPrecision],
    );

    // captura o valor decimal do valor recebido baseado no decimalPrecision
    const getDecimalFromValue = useCallback(
        (value: string, originalValue?: string): string => {
            const hasDot = Boolean(originalValue?.indexOf('.'));
            const qtdDecimal = hasDot ? originalValue?.toString().split('.')[1]?.length : 0;

            let decimal = '';

            if (decimalPrecision) {
                decimal = value.slice(decimalPrecision * -1);

                if (decimal.length < decimalPrecision) {
                    const indexDecimal = originalValue ? originalValue.indexOf('.') + 1 : 0;

                    if (qtdDecimal && originalValue && originalValue.slice(indexDecimal, originalValue.length) === decimal) {
                        decimal = normalizeDecimals(decimal, '0', false);
                    } else {
                        decimal = normalizeDecimals(decimal);
                    }
                }
            }

            return decimal;
        },
        [decimalPrecision, normalizeDecimals],
    );

    // gera um novo valor juntando o inteiro e decimal e capturando o operador do valor original
    // leva em consideração decimalPrecision para aplicar decimal junto ao valor
    const joinIntDecimal = useCallback(
        (int: string, decimal: string, value: string): string => {
            const newValue = Number(`${int}.${decimal}`).toFixed(decimalPrecision);

            let negative = '';

            if (Number(newValue)) {
                negative = getOperatorNegative(value);
            }

            return `${negative}${newValue}`;
        },
        [decimalPrecision, getOperatorNegative],
    );

    // formatata o valor recebido para apresentação na tela, leva em consideração maxChar, decimalPrecision e inputType para isto
    const formatValue = useCallback(
        (value: string): string => {
            let newValue = justNumber(value);

            if (preventEmptyField || value) {
                const limit = forceMaxChar && typeof maxChar === 'number' ? maxChar : value.toString().length;

                // com o valor limpo de zeros na esquerda (vide regra), cortamos no limite
                // const cleanedValue = workAroundExponencial(clearValue(value).substring(0, limit)) as string;
                const cleanedValue = clearValue(newValue).substring(0, limit);

                // captura valor inteiro (antes do .)
                const int = getIntFromValue(cleanedValue);

                // captura valor decimal (após o .)
                const decimal = getDecimalFromValue(cleanedValue, value);

                // atualizamos o valor do response com o novo valor usando os valores inteiros, decimais e o operador do valor original
                newValue = joinIntDecimal(int, decimal, value);

                // para decimais, ajustamos o valor formatado
                if (inputType === 'decimal') {
                    newValue = brlDecimal(Number(newValue), decimalPrecision, decimalPrecision);
                }
                // para moeda, ajustamos o valor formatado
                else if (inputType === 'currency') {
                    newValue = brlPrice(Number(newValue), decimalPrecision, decimalPrecision);
                }
            }

            return newValue;
        },
        [
            clearValue,
            decimalPrecision,
            forceMaxChar,
            getDecimalFromValue,
            getIntFromValue,
            inputType,
            joinIntDecimal,
            justNumber,
            maxChar,
            preventEmptyField,
        ],
    );

    // corta o valor já formatado (com pontos, virgulas, etc...) baseado na prop maxLength e retorna novo valor para ser formatado
    const cutValueByMaxLength = useCallback(
        (value: string): string => {
            const limite = typeof maxLength === 'number' ? maxLength : 0;
            const excededChars = value.length - limite;
            const numericValue = getNumericValue(value);
            const newValue = numericValue.slice(0, numericValue.length - excededChars);

            return newValue;
        },
        [getNumericValue, maxLength],
    );

    // gera valores atualizos para o field e para o rawValue (eventos de change, blur)
    const updateFieldValue = useCallback(
        (inputValue: string | number): void => {
            const stringValue = inputValue.toString();

            let numericValue = getNumericValue(stringValue);
            let formattedValue = formatValue(stringValue);

            if (rawValue !== numericValue) {
                setError(undefined);

                if (justNumber(numericValue).length > limitCharExponencial) {
                    numericValue = limitExponencial(numericValue);
                    formattedValue = formatValue(numericValue);
                }

                if (typeof minValue === 'number' && Number(numericValue) < minValue) {
                    if (forceMinValue) {
                        numericValue = equalizeDecimalBackendNumber(minValue);
                        formattedValue = formatValue(numericValue);
                    } else {
                        setError(`${Errors.MINVALUE} ${minValue}`);
                    }
                }

                if (typeof maxValue === 'number' && Number(numericValue) > maxValue) {
                    if (forceMaxValue) {
                        numericValue = equalizeDecimalBackendNumber(maxValue);
                        formattedValue = formatValue(numericValue);
                    } else {
                        setError(`${Errors.MAXVALUE} ${maxValue}`);
                    }
                }

                if (typeof maxLength === 'number' && formattedValue.length > maxLength) {
                    if (forceMaxLength) {
                        numericValue = cutValueByMaxLength(numericValue);
                        formattedValue = formatValue(numericValue);
                    } else {
                        setError(`${Errors.MAXLENGTH} ${maxLength}`);
                    }
                }
            }

            setRawValue(numericValue);

            setValue(formattedValue);
        },
        [
            cutValueByMaxLength,
            equalizeDecimalBackendNumber,
            forceMaxLength,
            forceMaxValue,
            forceMinValue,
            formatValue,
            getNumericValue,
            justNumber,
            limitExponencial,
            maxLength,
            maxValue,
            minValue,
            rawValue,
        ],
    );

    // callback de quando presionada teclas de modificadores (seta para cima ou seta para baixo)
    // leva em consideração decimalPrecision para atualizar decimais ou inteiro
    const handleModifier = useCallback(
        (key: string): void => {
            const input = inputRef.current;
            const value = input?.value ?? '0';
            const negative = getOperatorNegative(value);
            const decimals = `${negative}${getDecimalFromValue(value)}`;
            const int = justNumber(getIntFromValue(value));

            let newDecimal = decimalPrecision ? calculeNewValue(decimals, key) : '';
            let newInt = decimalPrecision ? int : calculeNewValue(negative + int, key);
            let newValue = joinIntDecimal(newInt, newDecimal, value);

            if (decimalPrecision) {
                if (Number(newDecimal) > 99) {
                    newDecimal = normalizeDecimals('0');
                    newInt = (Number(int) + 1).toString();
                } else if (Number(newDecimal) < 0) {
                    if (Number(newInt) > 0) {
                        newDecimal = normalizeDecimals('9', '9');
                        newInt = (Number(int) - 1).toString();
                    } else {
                        newDecimal = normalizeDecimals((Number(newDecimal) * -1).toString());
                        newInt = '-0';
                    }
                }
            }

            newValue = joinIntDecimal(newInt, newDecimal, value);

            if (Number(newValue) < 0 && !allowNegative) {
                newValue = '0';
            }

            updateFieldValue(newValue);
            debouncedHandleFieldChange(newValue, 'change');
        },
        [
            allowNegative,
            calculeNewValue,
            debouncedHandleFieldChange,
            decimalPrecision,
            getDecimalFromValue,
            getIntFromValue,
            getOperatorNegative,
            joinIntDecimal,
            justNumber,
            normalizeDecimals,
            updateFieldValue,
        ],
    );

    // callback de teclas pressionadas no field, filtrando teclas aceitas e tratando modificadores por ex
    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLDivElement>): void => {
            const { key, ctrlKey, metaKey } = event;

            let prevent = true;

            if (acceptValueKeys.includes(key)) {
                prevent = false;
            } else if (acceptDeleteKeys.includes(key)) {
                prevent = false;
            } else if ((ctrlKey || metaKey) && acceptComboKeys.includes(key)) {
                prevent = false;
            } else if (acceptDirectionKeys.includes(key)) {
                prevent = false;
            } else if (acceptModifiers.includes(key)) {
                handleModifier(key);
            }

            if (prevent) {
                event.preventDefault();
                event.stopPropagation();
            }
        },
        [handleModifier],
    );

    // callback para evento de change do valor do input
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            const value = e.target.value;

            updateFieldValue(value);
            debouncedHandleFieldChange(value, 'change');
        },
        [debouncedHandleFieldChange, updateFieldValue],
    );

    // callback para evento de blur do valor do input
    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement, Element>): void => {
            const value = e.target.value;

            debouncedHandleFieldChange.cancel();

            updateFieldValue(value);
            debouncedHandleFieldChange(value, 'blur');
        },
        [debouncedHandleFieldChange, updateFieldValue],
    );

    // manipula valor da tela aplicando no field e tratando preventEmptyField quando necessário
    useEffect(() => {
        let newValue = userValue ?? value;

        if (preventEmptyField && !validValue(newValue)) {
            newValue = '0';
        }

        if (typeof newValue === 'number') {
            newValue = equalizeDecimalBackendNumber(newValue);
        }

        if (Number(newValue) !== Number(value.toString().replace(',', '.')) || !Number(newValue)) {
            updateFieldValue(newValue);
        }
    }, [userValue]);

    return (
        <TextField
            autoComplete={autoComplete}
            inputRef={inputRef}
            label={label}
            value={value}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={handleBlur}
            error={Boolean(customError || error)}
            helperText={customError || error}
            onChange={handleChange}
            onFocus={onFocus}
            sx={sx}
            size={size}
            inputProps={{ tabIndex: tabIndex }}
            name={name}
        />
    );
}
