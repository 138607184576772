import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Grid, Container } from '@mui/material';

import { Centered } from 'components/grid';
import { MenuFooter } from 'components/menu';
import { Logo } from 'components/images';

/**
 * Rodapé padrão do sistema, geralmente fixo no final da página
 * Contém logo do cliente + menu com links do rodapé
 */
export function Footer(): JSX.Element {
    return (
        <Box component="footer" sx={{ mt: 'auto', backgroundColor: 'secondary.main' }}>
            {/* xl = extra large = 1400px */}
            <Container maxWidth="xl" disableGutters sx={{ px: 2, py: 4, color: 'common.white' }}>
                <Grid container>
                    <Grid item xs={12} md={9}>
                        <MenuFooter />
                    </Grid>

                    <Grid item xs={false} md={3} justifyContent="flex-end" sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Centered>
                            <Link to="/" title="Página inicial">
                                <Logo />
                            </Link>
                        </Centered>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
