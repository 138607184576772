import React from 'react';

import { StoreAutoComplete } from 'components/autocompletes';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';
import { useSearchParamsFilters } from 'pages/compra/pedido/hooks';
import { LojaProps } from 'pages/compra/pedido/types';

export function PedidoStoreAutoComplete(): JSX.Element {
    const { lojaParam } = useSearchParamsFilters();
    const { loja: lojaAplicacao } = useAplicacaoDataContext();
    const { filterLoja, setFilterLoja, setFilterConfigPedido, setFilterJornada } = usePedidoFilterContext();

    return (
        <StoreAutoComplete
            flagInstitucional
            size="small"
            value={filterLoja.value}
            defaultStore={!lojaParam && lojaAplicacao?.fgAtivo}
            requestOptions={{
                url: '/gestao/loja',
                sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
                columns: 'idLoja,cdLoja,dsNomeFantasia,dsRazaoSocial',
                limit: 50,
            }}
            onChange={(e, value: LojaProps) => {
                setFilterConfigPedido((oldState: FilterValuesProps) => ({ ...oldState, value: null }));
                setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value: null }));
                setFilterLoja((oldState: FilterValuesProps) => ({
                    ...oldState,
                    value: value && { idLoja: value.idLoja, cdLoja: value.cdLoja, dsNomeFantasia: value.dsNomeFantasia },
                }));
            }}
        />
    );
}
