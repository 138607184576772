import React, { Dispatch, SetStateAction } from 'react';

import { Alert } from '@mui/material';
import { Event } from 'react-big-calendar';

import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { ContentModal } from 'components/modal';
import { CalendarioListEvent } from 'pages/calendario/components';
import { getCategoryEventColor } from 'pages/calendario/components/templates/CalendarioListCalendar';

interface CalendarioListCalendarProps {
    eventoSelecionado: Event | boolean;
    setEventoSelecionado: Dispatch<SetStateAction<Event | boolean>>;
    store: StoreOptionsProps | null;
}

export function CalendarioListModalEvent({ eventoSelecionado, setEventoSelecionado, store }: CalendarioListCalendarProps): JSX.Element {
    const eventoData = typeof eventoSelecionado === 'object' && eventoSelecionado;
    const categoryColor = eventoData ? getCategoryEventColor(eventoData.resource.idCategoria) : { hex: '', alpha: '' };

    return (
        <ContentModal
            open={Boolean(eventoSelecionado)}
            setOpen={setEventoSelecionado}
            closeButton={{
                'top': -15,
                'right': -15,
                'backgroundColor': 'common.white',
                'borderWidth': 2,
                'borderStyle': 'solid',
                'borderColor': categoryColor.hex,
                'p': 0.5,
                '&:hover': {
                    backgroundColor: 'common.white',
                },
            }}
            sx={{
                p: 0,
                width: { md: '20vw', xs: '75vw', sm: '50%' },
                borderRadius: 2,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: categoryColor.hex,
                overflow: 'visible',
            }}
        >
            <React.Fragment>
                {eventoData && store ? (
                    <CalendarioListEvent eventoSelecionado={eventoSelecionado} store={store} />
                ) : (
                    <Alert severity="error">Ops... não foi possivel ler os dados da evento selecionado.</Alert>
                )}
            </React.Fragment>
        </ContentModal>
    );
}
