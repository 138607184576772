import React, { CSSProperties, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Box, CircularProgress, alpha } from '@mui/material';
import { View, Event } from 'react-big-calendar';

import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { BaseCalendar } from 'components/calendar';
import { CalendarioListModalEvent } from 'pages/calendario/components';
import { CalendarioListPromocaoModal } from 'pages/calendario/components/templates/CalendarioListPromocaoModal';

interface CalendarioListCalendarProps {
    view: View;
    setView: Dispatch<SetStateAction<View>>;
    date: Date;
    setDate: Dispatch<SetStateAction<Date>>;
    store: StoreOptionsProps | null;
    loading: boolean;
    events: Event[];
}

interface EventStyleGetterReturn {
    className?: string;
    style?: CSSProperties;
}

interface GetCategoryEventColorReturn {
    hex: string;
    alpha: string;
}
export const getCategoryEventColor = (tpCalendarioEvento: string): GetCategoryEventColorReturn => {
    const hex =
        tpCalendarioEvento === 'EVENTO_PERSONALIZADO'
            ? '#FFC800'
            : tpCalendarioEvento === 'EVENTO_NOTA_FISCAL'
            ? '#1E212B'
            : tpCalendarioEvento === 'EVENTO_TITULO'
            ? '#4D8B31'
            : tpCalendarioEvento === 'EVENTO_LIMITE_GRADE'
            ? '#FF8427'
            : tpCalendarioEvento === 'EVENTO_PREVISAO_ENTREGA'
            ? '#e63946'
            : tpCalendarioEvento === 'EVENTO_PROMOCAO'
            ? '#00b050'
            : '#264653';

    return {
        alpha: alpha(hex, 0.2),
        hex,
    };
};

export function CalendarioListCalendar({ view, setView, date, setDate, store, loading, events }: CalendarioListCalendarProps): JSX.Element {
    const [eventoSelecionado, setEventoSelecionado] = useState<Event | boolean>(false);
    const [promocao, setPromocao] = useState<Event | null>(null);
    const [openModalPromocao, setOpenModalPromocao] = useState<boolean>(false);
    const [allEvents, setAllEvents] = useState<Event[]>([]);

    const handleSelectEvent = useCallback((event: Event): void => {
        if (event.resource.tpCalendarioEvento === 'EVENTO_PROMOCAO') {
            setPromocao(event);
            setOpenModalPromocao(true);
        } else {
            setEventoSelecionado(event);
        }
    }, []);

    const getEventStyleByCategory = (event: Event): CSSProperties => {
        const color = '#fff';
        const backgroundColor = event.resource.dsCor || event.resource.calendarioEventoTag.dsCorTag;

        return {
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor,
            color,
            fontSize: '0.8rem',
        };
    };

    const eventStyleGetter = (event: Event): EventStyleGetterReturn => {
        const style = getEventStyleByCategory(event);

        return {
            style: style,
        };
    };

    useEffect(() => {
        setAllEvents(events);
    }, [date, store, view, events]);

    return (
        <Box sx={{ position: 'relative' }}>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 5,
                        background: '#fff',
                    }}
                >
                    <CircularProgress sx={{ my: 3 }} />
                </Box>
            )}

            <BaseCalendar
                view={view}
                onViewChange={(newView) => {
                    setView(newView);
                }}
                date={date}
                onDateChange={(newDate) => {
                    setDate(newDate);
                }}
                eventos={allEvents}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
            />

            <CalendarioListModalEvent eventoSelecionado={eventoSelecionado} setEventoSelecionado={setEventoSelecionado} store={store} />

            <CalendarioListPromocaoModal open={openModalPromocao} setOpen={setOpenModalPromocao} promocao={promocao} store={store} />
        </Box>
    );
}
