import React, { useCallback, useMemo, useState } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { LoadingButton } from '@mui/lab';
import { useMediaQuery, useTheme, Button, Box, Divider, IconButton, Tooltip } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ConfirmModal } from 'components/modal';
import { Block } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { PedidoCompraTotalizadorBoxIntegracaoStatus } from 'pages/compra/pedido-totalizador/components';
import { RequestPedidoIntegracaoStatusProps, SincronizacaoStatus } from 'pages/compra/pedido-totalizador/types';

export function PedidoCompraTotalizadorQuebras(): JSX.Element {
    const { content } = useFormContext();
    const RequestListagem = useRequestListagem();
    const { filter } = useListagemContext();
    const { mutate: mutateSincronizar, isLoading: isLoadingSincronizar } = useFormMutation();
    const { mutate: mutateQuebrar, isLoading: isLoadingQuebrar } = useFormMutation();
    const queryClient = useQueryClient();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [openSincronizarModal, setOpenSincronizarModal] = useState<boolean>(false);
    const [openQuebrarModal, setOpenQuebrarModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const configPedidoOrigemList = useMemo(() => {
        const configPedido = filter && filter.find((item) => item.property === 'idConfigPedidoOrigem');

        return configPedido?.value;
    }, [filter]);

    const refreshGrid = (): void => {
        queryClient.invalidateQueries(['getListQuebras']);
        queryClient.invalidateQueries(['getListTotalizadores']);
    };

    const requestOptions: RequestOptionsType = {
        url: 'compra/pedidototalizadorsincronizacao/configpedidoorigem',
        extraParams: [{ idConfigPedidoOrigem: configPedidoOrigemList && configPedidoOrigemList.join(',') }],
    };
    const { data, isFetching } = useQuery(['getStatusIntegracao', requestOptions], (): Promise<RequestPedidoIntegracaoStatusProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(configPedidoOrigemList),
        refetchInterval: (data) => {
            if (data) {
                const { idPedidoTotalizadorSincronizacaoStatus } = data.pedidoTotalizadorSincronizacaoStatus;

                if (
                    [SincronizacaoStatus.AGUARDANDO, SincronizacaoStatus.PROCESSANDO, SincronizacaoStatus.GERANDO_PACOTES, SincronizacaoStatus.GERANDO_NOVOS_PACOTES].includes(
                        idPedidoTotalizadorSincronizacaoStatus,
                    )
                ) {
                    return 1000 * 10; // 10 segundos
                }
            }

            return false;
        },
        onSuccess: (data) => {
            if (data) {
                const { idPedidoTotalizadorSincronizacaoStatus } = data.pedidoTotalizadorSincronizacaoStatus;

                if ([SincronizacaoStatus.FINALIZADO_COM_SUCESSO, SincronizacaoStatus.FINALIZADO_COM_FALHA, SincronizacaoStatus.PACOTE_COM_FALHA].includes(idPedidoTotalizadorSincronizacaoStatus)) {
                    refreshGrid();
                }
            }
        },
    });

    const onClickSincronizar = useCallback(
        () =>
            mutateSincronizar(
                { url: `/compra/pedidocompra/totalizador/quebra/sincronizar?idConfigPedidoOrigem=${configPedidoOrigemList.join(',')}` },
                {
                    onSuccess: () => queryClient.invalidateQueries(['getStatusIntegracao']),
                },
            ),
        [configPedidoOrigemList],
    );

    const onClickQuebrar = useCallback(
        () =>
            mutateQuebrar(
                { url: `/compra/pedidocompra/totalizador/quebra?idConfigPedidoOrigem=${configPedidoOrigemList.join(',')}` },
                {
                    onSuccess: refreshGrid,
                },
            ),
        [configPedidoOrigemList],
    );

    const isDisabled = (isLoading: boolean): boolean =>
        Boolean(
            isFetching ||
                filter.length !== 1 ||
                isLoading ||
                (data && data.pedidoTotalizadorSincronizacaoStatus.idPedidoTotalizadorSincronizacaoStatus !== SincronizacaoStatus.FINALIZADO_COM_SUCESSO) ||
                !content?.totalizadorWithData,
        );

    return (
        <React.Fragment>
            <ConfirmModal open={openSincronizarModal} setOpen={setOpenSincronizarModal} confirm={onClickSincronizar} text={'Tem certeza que deseja integrar os pedidos?'} />

            <ConfirmModal open={openQuebrarModal} setOpen={setOpenQuebrarModal} confirm={onClickQuebrar} text={'Tem certeza que deseja quebrar os pedidos?'} />

            <Block sx={{ display: 'flex', alignItems: 'center', flexDirection: { sm: 'row', xs: 'column' }, gap: 2, position: isMobile ? 'static' : 'sticky', top: 16, zIndex: 3 }}>
                <LoadingButton
                    sx={{ width: { xs: '100%', lg: 'auto' } }}
                    variant="outlined"
                    color="info"
                    loading={isLoadingQuebrar}
                    onClick={() => setOpenQuebrarModal(true)}
                    disabled={isDisabled(isLoadingSincronizar)}
                >
                    Quebrar Pedidos
                </LoadingButton>

                <LoadingButton
                    variant="outlined"
                    color="success"
                    loading={isLoadingSincronizar}
                    onClick={() => setOpenSincronizarModal(true)}
                    disabled={isDisabled(isLoadingQuebrar)}
                    sx={{ width: { xs: '100%', lg: 'auto' } }}
                >
                    Integrar Pedidos
                </LoadingButton>

                {data ? (
                    <Box sx={{ ml: 'auto', display: 'flex' }}>
                        {data.pedidoTotalizadorSincronizacaoStatus ? <PedidoCompraTotalizadorBoxIntegracaoStatus statusIntegracao={data.pedidoTotalizadorSincronizacaoStatus} /> : null}

                        <Tooltip title="Atualizar status">
                            <IconButton onClick={() => queryClient.invalidateQueries(['getStatusIntegracao'])}>
                                <CachedOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 0.5 }} />

                        <Button
                            variant="contained"
                            disabled={data.idPedidoTotalizadorSincronizacaoStatus === SincronizacaoStatus.GERANDO_PACOTES}
                            color="primary"
                            sx={{ width: { xs: '100%', lg: 'auto' } }}
                            onClick={() =>
                                navigate({
                                    pathname: 'status-integracao',
                                    search: createSearchParams({
                                        configPedidoOrigemList: JSON.stringify(configPedidoOrigemList),
                                    }).toString(),
                                })
                            }
                        >
                            Acompanhar Integração
                        </Button>
                    </Box>
                ) : null}
            </Block>
        </React.Fragment>
    );
}
