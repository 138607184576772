import React, { useCallback, useMemo, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { FormikHelpers, FormikProps, getIn } from 'formik';
import * as Yup from 'yup';

import { CondicaoPagamentoAutoComplete, TipoLojaAutoComplete } from 'components/autocompletes';
import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissMaskField, CissNumberField, CissTextField, Editor } from 'components/fields';
import { Row } from 'components/form';
import { FullLoading } from 'components/loading';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { LojaFormBandaPrecoGrid, LojaFormConfiguracaoPropriedadeGrid } from 'pages/cadastro/loja/components';
import { getTypeChave } from 'pages/cadastro/loja/components/atoms/LojaFormConfiguracaoPropriedadeGrid';
import { LojaConfiguracao, LojaRequest, initialValuesFormik } from 'pages/cadastro/loja/types';
import { CepField } from 'pages/cadastro/usuario/components';

export function LojaFormComp(): JSX.Element {
    const { mutate, isLoading } = useFormMutation();
    const { setContent } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const navigate = useNavigate();
    const url = useRef('/gestao/loja/');

    const formatLojaConfiguracoes = (value: LojaConfiguracao[] = []): LojaConfiguracao[] => {
        return value.filter((item) => item.dsValor);
    };

    const onSubmit = useCallback(
        (values: LojaRequest, formik: FormikHelpers<LojaRequest>) => {
            const formData = structuredClone(values);

            formData.lojaConfiguracoes = formatLojaConfiguracoes(formData.lojaConfiguracoes);

            mutate(
                {
                    url: url.current,
                    idField: 'idLoja',
                    formData,
                    formik,
                    preventRedirect: !hasPermission(['LOJA_ALTERAR']),
                },
                {
                    onSuccess: (response: any) => {
                        setContent(response.data.data);

                        if (!hasPermission(['LOJA_ALTERAR'])) {
                            navigate(-1);
                        }
                    },
                },
            );
        },
        [hasPermission, mutate, navigate, setContent],
    );

    const mapContentToInitialValues = useCallback((contentData: LojaRequest) => {
        if (Array.isArray(contentData.lojaConfiguracoes)) {
            contentData.lojaConfiguracoes.map((config) => {
                if (config.dsValor && ['vl', 'pe'].includes(getTypeChave(config.configuracaoPropriedade?.dsChave))) {
                    config.dsValor = parseFloat(config.dsValor).toFixed(2);
                }
            });
        }

        return contentData;
    }, []);

    const validationSchemaLoja = useMemo(
        () =>
            Yup.object({
                cdLoja: Yup.string().required().nullable(),
                dsRazaoSocial: Yup.string().required().nullable(),
                dsNomeFantasia: Yup.string().required().nullable(),
                dsCnpj: Yup.string().required().nullable().length(14),
                dsEmail: Yup.string().required().nullable(),
                dsEndereco: Yup.string().required().nullable(),
                nrEndereco: Yup.number().required().nullable(),
                dsBairro: Yup.string().required().nullable(),
                dsComplemento: Yup.string().nullable(),
                fgAtivo: Yup.string().required().nullable(),
                tipoLoja: Yup.object().required().nullable(),
                lojaPerfil: Yup.object().required().nullable(),
                grupoEconomicoLoja: Yup.object().required().nullable(),
                lojaCondicaoPagamento: Yup.object().required().nullable(),
                dtInauguracao: Yup.string().required().nullable(),
                cdPontoComercial: Yup.string().max(100),
                cep: Yup.object()
                    .shape({
                        nrCep: Yup.string().length(8, 'O campo deve ter exatamente 8 números.').required().nullable(),
                        estado: Yup.object().required().nullable(),
                        cidade: Yup.object().required().nullable(),
                    })
                    .nullable()
                    .required(),
                lojaConfiguracao: Yup.array()
                    .of(
                        Yup.object().shape({
                            idLojaConfiguracao: Yup.number(),
                            configuracaoPropriedade: Yup.object().shape({
                                idConfiguracaoPropriedade: Yup.number(),
                                dsConfiguracao: Yup.string(),
                            }),
                            idConfiguracaoPropriedade: Yup.number(),
                            dsConfiguracao: Yup.string(),
                            dsChave: Yup.string(),
                            dsValor: Yup.string(),
                        }),
                    )
                    .nullable(),
                bandaPreco: Yup.array()
                    .of(
                        Yup.object().shape({
                            bandaPreco: Yup.object().shape({
                                idBandaPreco: Yup.number(),
                                dsBandaPreco: Yup.string(),
                            }),
                            idBandaPreco: Yup.string(),
                            dsBandaPreco: Yup.string(),
                            dsValor: Yup.string(),
                            vlPercentual: Yup.string(),
                            fgAtivo: Yup.boolean(),
                        }),
                    )
                    .nullable(),
            }),
        [],
    );

    return (
        <FormPage
            title={'Lojas'}
            values={initialValuesFormik}
            onSubmit={onSubmit}
            url={url.current}
            validationSchema={validationSchemaLoja}
            mapContentToInitialValues={mapContentToInitialValues}
            fgAtivo
        >
            {(formik: FormikProps<LojaRequest>) => {
                const getErrorInCep = (field: string): string =>
                    typeof getIn(formik.errors, 'cep') === 'string' ? getIn(formik.errors, 'cep') : getIn(formik.errors, field);

                return (
                    <React.Fragment>
                        <FullLoading loading={isLoading} />

                        <Block>
                            <SubTitle label="Dados Básicos da Loja" />

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Código"
                                        name="cdLoja"
                                        value={formik.values.cdLoja}
                                        error={formik.errors.cdLoja}
                                        onChange={(e) => formik.setFieldValue('cdLoja', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Razão Social"
                                        name="dsRazaoSocial"
                                        value={formik.values.dsRazaoSocial}
                                        error={formik.errors.dsRazaoSocial}
                                        onChange={(e) => formik.setFieldValue('dsRazaoSocial', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={2}>
                                    <CissTextField
                                        label="Nome Fantasia"
                                        name="dsNomeFantasia"
                                        value={formik.values.dsNomeFantasia}
                                        error={formik.errors.dsNomeFantasia}
                                        onChange={(e) => formik.setFieldValue('dsNomeFantasia', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Data de Inauguração"
                                            inputFormat="dd/MM/yyyy"
                                            renderInput={(params) => (
                                                <CissTextField {...params} name="dtInauguracao" error={formik.errors.dtInauguracao} />
                                            )}
                                            value={formik.values.dtInauguracao}
                                            onChange={(value) => formik.setFieldValue('dtInauguracao', value)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Cnpj"
                                        name="dsCnpj"
                                        maxLength={14}
                                        preventEmptyField={false}
                                        value={formik.values.dsCnpj}
                                        error={formik.errors.dsCnpj}
                                        onChange={(e) => formik.setFieldValue('dsCnpj', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Inscrição Estadual"
                                        name="dsInscricaoEstadual"
                                        value={formik.values.dsInscricaoEstadual}
                                        error={formik.errors.dsInscricaoEstadual}
                                        onChange={(e) => formik.setFieldValue('dsInscricaoEstadual', e.target.value)}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissMaskField
                                        label="Telefone da Loja"
                                        format="phone"
                                        name="dsFone"
                                        value={formik.values.dsFone}
                                        onChange={(e) => formik.setFieldValue('dsFone', e.target.value)}
                                        error={formik.errors.dsFone}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Email da Loja"
                                        name="dsEmail"
                                        value={formik.values.dsEmail}
                                        onChange={(e) => formik.setFieldValue('dsEmail', e.target.value)}
                                        error={formik.errors.dsEmail}
                                    />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label="Observação" />

                            <Editor value={formik.values.dsObservacao} onChange={(value) => formik.setFieldValue('dsObservacao', value)} />
                        </Block>

                        <Block>
                            <SubTitle label={'Endereço da Loja'} />

                            <Row>
                                <Grid item flex={1}>
                                    <CepField value={formik.values.cep?.nrCep || ''} error={getErrorInCep('cep.nrCep')} />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Endereço"
                                        name="dsEndereco"
                                        value={formik.values.dsEndereco || ''}
                                        error={formik.errors.dsEndereco}
                                        onChange={(e) => formik.setFieldValue('dsEndereco', e.target.value)}
                                        disabled={!formik.values.cep?.cidade?.dsCidade}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissNumberField
                                        label="Número"
                                        name="nrEndereco"
                                        preventEmptyField={false}
                                        value={formik.values.nrEndereco || ''}
                                        error={formik.errors.nrEndereco}
                                        onChange={(e) => formik.setFieldValue('nrEndereco', e.target.value)}
                                        disabled={!formik.values.cep?.cidade?.dsCidade}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Complemento"
                                        name="dsComplemento"
                                        value={formik.values.dsComplemento || ''}
                                        error={formik.errors.dsComplemento}
                                        onChange={(e) => formik.setFieldValue('dsComplemento', e.target.value)}
                                        disabled={!formik.values.cep?.cidade?.dsCidade}
                                    />
                                </Grid>
                            </Row>

                            <Row>
                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Bairro"
                                        name="dsBairro"
                                        value={formik.values.dsBairro || ''}
                                        error={formik.errors.dsBairro}
                                        onChange={(e) => formik.setFieldValue('dsBairro', e.target.value)}
                                        disabled={!formik.values.cep?.cidade?.dsCidade}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label={'Cidade'}
                                        name="dsCidade"
                                        value={formik.values.cep?.cidade?.dsCidade || ''}
                                        error={getErrorInCep('cep.cidade')}
                                        disabled
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label={'Estado'}
                                        name="dsEstado"
                                        value={formik.values.cep?.estado?.dsEstado || ''}
                                        error={getErrorInCep('cep.estado')}
                                        disabled
                                    />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label={'Configurações da Loja'} />

                            <Row>
                                <Grid item flex={1}>
                                    <CissAutoComplete
                                        label={'Grupo Econômico'}
                                        name="grupoEconomicoLoja"
                                        value={formik.values.grupoEconomicoLoja || null}
                                        onChange={(e, value) => formik.setFieldValue('grupoEconomicoLoja', value)}
                                        multiple={false}
                                        requestOptions={{
                                            url: '/gestao/grupoeconomico/',
                                            columns: 'idGrupoEconomico,dsGrupoEconomico,cdGrupoEconomico,fgAtivo',
                                            sort: [{ property: 'idGrupoEconomico', direction: 'ASC' }],
                                        }}
                                        isOptionEqualToValue={(option, value) => option.idGrupoEconomico === value.idGrupoEconomico}
                                        optionStringTemplate="<strong>{idGrupoEconomico}</strong> - {dsGrupoEconomico}"
                                        selectedStringTemplate="{idGrupoEconomico} - {dsGrupoEconomico}"
                                        error={formik.errors.grupoEconomicoLoja}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissAutoComplete
                                        label={'Faixa Faturamento'}
                                        name="faixaFaturamento"
                                        value={formik.values.faixaFaturamento || null}
                                        onChange={(e, value) => formik.setFieldValue('faixaFaturamento', value)}
                                        multiple={false}
                                        requestOptions={{
                                            url: '/gestao/faixafaturamento/',
                                            columns: 'idFaixaFaturamento,dsFaixaFaturamento',
                                            sort: [{ property: 'idFaixaFaturamento', direction: 'ASC' }],
                                        }}
                                        isOptionEqualToValue={(option, value) => option.idFaixaFaturamento === value.idFaixaFaturamento}
                                        optionStringTemplate="<strong>{idFaixaFaturamento}</strong> - {dsFaixaFaturamento}"
                                        selectedStringTemplate="{idFaixaFaturamento} - {dsFaixaFaturamento}"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <TipoLojaAutoComplete
                                        name="tipoLoja"
                                        value={formik.values.tipoLoja || null}
                                        onChange={(e, value) => formik.setFieldValue('tipoLoja', value)}
                                        error={formik.errors.tipoLoja}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissAutoComplete
                                        label={'Perfil da loja'}
                                        name="lojaPerfil"
                                        value={formik.values.lojaPerfil || null}
                                        onChange={(e, value) => formik.setFieldValue('lojaPerfil', value)}
                                        multiple={false}
                                        requestOptions={{
                                            url: '/gestao/lojaperfil/',
                                            columns: 'idLojaPerfil,dsLojaPerfil',
                                            sort: [{ property: 'idLojaPerfil', direction: 'ASC' }],
                                        }}
                                        isOptionEqualToValue={(option, value) => option.idLojaPerfil === value.idLojaPerfil}
                                        optionStringTemplate="<strong>{idLojaPerfil}</strong> - {dsLojaPerfil}"
                                        selectedStringTemplate="{idLojaPerfil} - {dsLojaPerfil}"
                                        error={formik.errors.lojaPerfil}
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <CondicaoPagamentoAutoComplete
                                        name="lojaCondicaoPagamento"
                                        value={formik.values.lojaCondicaoPagamento || null}
                                        onChange={(e, value) => formik.setFieldValue('lojaCondicaoPagamento', value)}
                                        error={formik.errors.lojaCondicaoPagamento}
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <CissTextField
                                        name="cdPontoComercial"
                                        value={formik.values.cdPontoComercial}
                                        label="Código Ponto Comercial"
                                        onChange={(e) => formik.setFieldValue('cdPontoComercial', e.target.value)}
                                        error={formik.errors.cdPontoComercial}
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid item flex={1}>
                                    <LojaFormConfiguracaoPropriedadeGrid />
                                </Grid>
                                <Grid item flex={1}>
                                    <LojaFormBandaPrecoGrid />
                                </Grid>
                            </Row>
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
