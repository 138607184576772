import React, { Dispatch, SetStateAction, useState } from 'react';

import { Alert, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Centered } from 'components/grid';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoTotalizadorValdiacaoProps } from 'pages/compra/pedido-totalizador/types';

interface PedidoCompraTotalizadorErrosModalProps {
    open: number | boolean;
    setOpen: Dispatch<SetStateAction<number | boolean>>;
}

export function PedidoCompraTotalizadorFalhasModal({ open, setOpen }: PedidoCompraTotalizadorErrosModalProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const [errorList, setErrorList] = useState<string[]>([]);

    const requestOptions: RequestOptionsType = {
        url: `/compra/pedidototalizadorvalidacao/pedido/${open}`,
    };

    const { isFetching } = useQuery<PedidoTotalizadorValdiacaoProps[]>([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(open),
        onSuccess: (response) => {
            const newErrorList: string[] = [];

            response.forEach((item) => {
                const { dsPedidoTotalizadorValidacao } = item;
                const splittedErrors = dsPedidoTotalizadorValidacao.split('\n');

                newErrorList.push(...splittedErrors);
            });

            setErrorList(newErrorList);
        },
    });

    return (
        <ContentModal open={Boolean(open)} setOpen={setOpen} sx={{ borderRadius: 2, width: { xs: '80%', md: 650 } }} closeButton>
            <Stack>
                <SubTitle label="Falhas" />

                {isFetching ? (
                    <Centered>
                        <CircularProgress sx={{ my: 1 }} />
                    </Centered>
                ) : (
                    <Stack spacing={2}>
                        {errorList.map((item, index): JSX.Element => {
                            return (
                                <Alert key={index} variant="outlined" severity="error">
                                    {item}
                                </Alert>
                            );
                        })}
                    </Stack>
                )}
            </Stack>
        </ContentModal>
    );
}
