import React, { useEffect, useRef } from 'react';

import { Typography } from '@mui/material';

import { UploadButton } from 'components/upload';

export function ImportacaoUploadBtn({ setFileSugestoes, fileSugestoes }: any): JSX.Element {
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputFileRef.current && !fileSugestoes) {
            inputFileRef.current.value = '';
        }
    }, [fileSugestoes]);

    return (
        <React.Fragment>
            <UploadButton
                label="Selecionar Arquivo"
                onChange={(file) => {
                    setFileSugestoes(file);
                }}
                accept={['.xlsx', '.csv']}
                inputRef={inputFileRef}
            />

            {fileSugestoes && (
                <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                    {fileSugestoes.name}
                </Typography>
            )}

            <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                *São aceitos somente arquivos em formato .csv e .xlsx
            </Typography>
        </React.Fragment>
    );
}
