import React, { Dispatch, SetStateAction } from 'react';

import { Box, Button } from '@mui/material';

import { PedidoLogGrid } from 'components/grid/PedidoLogGrid';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { ListagemProvider } from 'context/ListagemContext';

type PedidoLogModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    idPedido: number;
};

export function PedidoLogModal({ open, setOpen, idPedido }: PedidoLogModal): JSX.Element {
    return (
        <ContentModal open={open} setOpen={setOpen} sx={{ borderRadius: 1, minWidth: { md: 1000 } }} closeButton>
            <Box>
                <SubTitle label="Logs" />

                <Box sx={{ height: 400 }}>
                    <ListagemProvider>
                        <PedidoLogGrid idPedido={idPedido} />
                    </ListagemProvider>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                </Box>
            </Box>
        </ContentModal>
    );
}
