import React from 'react';

import { Typography, TypographyProps } from '@mui/material';

interface PageTitleProps {
    label?: string;
    sx?: TypographyProps;
}

/**
 * Componente padrão para título dentro de bloco em uma página (geralmente título principal no topo do conteudo)
 * @param title titulo da página
 */
export function PageTitle({ label, sx }: PageTitleProps): JSX.Element {
    return (
        <Typography variant="h4" component="h1" sx={{ ...sx, overflowWrap: 'break-word' }}>
            {label}
        </Typography>
    );
}
