import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Grid, Typography, Stack, Alert, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { LikeBtn, ViewBtn } from 'components/buttons';
import { RichContent, DownloadBox } from 'components/content';
import { OneColumn, Block } from 'components/page';
import { SubTitle, Tag, TagGroup } from 'components/text';
import { useLikesContext } from 'context/LikeContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { useFormMutation } from 'mutations/useFormMutation';
import { RequestComunicadosStoreProps } from 'pages/comunicado/types';
import { getErrorMessage } from 'util/error';

export function ComunicadoDetailComp(): JSX.Element {
    const [views, setViews] = useState<number | undefined>();
    const { validRouterParams } = useValidation();
    const { id } = useParams();
    const validId = validRouterParams({ id });
    const likesContext = useLikesContext();
    const { mutate } = useFormMutation();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: `/gestao/comunicado/${id}`,
    };

    const { isLoading, fetchStatus, data, isError, error } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestComunicadosStoreProps> = RequestListagem(requestOptions);
            return request;
        },
        {
            onSuccess: (response) => {
                const { data: viewData } = response.data;
                const { idComunicado, qtVisualizacao = 0 } = viewData;

                mutate(
                    { url: `/gestao/comunicadovisualizacao/add?idComunicado=${idComunicado}`, preventSnack: true },
                    {
                        onSuccess: () => setViews(qtVisualizacao + 1),
                        onError: () => setViews(qtVisualizacao),
                    },
                );
            },
        },
    );

    const status = data?.status;
    const content = data?.data?.data;
    const [comunicadoError] = validId ? getErrorMessage(error) : ['Verifique se a URL informada é valida.'];

    useEffect(() => {
        if (content) {
            likesContext.dispachLike({
                type: 'start',
                payload: {
                    likes: content.qtCurtida,
                    liked: content.fgUsuarioCurtiu,
                },
            });
        }

        //Filtra somente arquivos que não estejam marcados como capa.
        if (content?.comunicadoArquivos?.length) {
            content.comunicadoArquivos = content.comunicadoArquivos.filter((item: any) => !item.fgCapa);
        }
    }, [content]);

    return (
        <OneColumn title={content?.dsComunicado as string} goBackButton>
            {(isError || !validId) && <Alert severity="error">{comunicadoError}</Alert>}

            {status === 204 && <Alert severity="warning">Nenhum registro encontrado com o id {id}</Alert>}

            {validId && (
                <React.Fragment>
                    {isLoading && fetchStatus === 'fetching' && (
                        <React.Fragment>
                            <Skeleton variant="rectangular" height={80} />
                            <Skeleton variant="rectangular" height={410} sx={{ mt: 3 }} />
                            <Skeleton variant="rectangular" height={90} sx={{ mt: 3 }} />
                        </React.Fragment>
                    )}

                    {!isLoading && content && (
                        <React.Fragment>
                            {!content.fgAtivo && (
                                <Alert severity="warning">
                                    Atenção registro <strong>inativo</strong>
                                </Alert>
                            )}
                            <Block>
                                <Grid
                                    container
                                    spacing={{
                                        xs: 0,
                                        md: 2,
                                    }}
                                    rowSpacing={{
                                        xs: 2,
                                        md: 0,
                                    }}
                                    sx={{
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row',
                                        },
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {content.categoriaComunicado?.dsCategoriaComunicado && (
                                        <Grid item>
                                            <Typography sx={{ color: 'primary.main' }}>Categoria</Typography>
                                            <Typography variant="h6" sx={{ color: 'common.black' }} noWrap>
                                                {content.categoriaComunicado.dsCategoriaComunicado}
                                            </Typography>
                                        </Grid>
                                    )}

                                    {Array.isArray(content.comunicadoTagsComunicado) && Boolean(content.comunicadoTagsComunicado.length) && (
                                        <Grid item>
                                            <Typography sx={{ color: 'primary.main' }}>Hashtags</Typography>
                                            <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                <TagGroup max={3}>
                                                    {content.comunicadoTagsComunicado.map((comunicadoTag) => (
                                                        <Tag label={comunicadoTag.dsTagComunicado} key={comunicadoTag.idTagComunicado} />
                                                    ))}
                                                </TagGroup>
                                            </Typography>
                                        </Grid>
                                    )}

                                    {content.dhInclusao && (
                                        <Grid item sx={{ flex: 1, maxWidth: 115 }}>
                                            <Typography sx={{ color: 'primary.main' }}>Publicado</Typography>
                                            <Typography variant="h6" sx={{ color: 'common.black' }}>
                                                {dateFns.format(new Date(content.dhInclusao), 'dd/MM/yyyy')}
                                            </Typography>
                                        </Grid>
                                    )}

                                    <Grid item sx={{ flex: 1, maxWidth: 175 }}>
                                        <Typography sx={{ color: 'primary.main' }}>Curtidas e visualizações</Typography>
                                        <Box sx={{ display: 'flex' }}>
                                            <LikeBtn context={likesContext} likeUrl={`/gestao/comunicadocurtida/like/?idComunicado=${content.idComunicado}`} />
                                            <ViewBtn views={views} sx={{ mr: 0 }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Block>

                            <Block>
                                <Grid container>
                                    <SubTitle label="Descrição" />
                                    <Grid item xs={12}>
                                        <RichContent dangerouslySetInnerHTML={{ __html: content.dsConteudo as string }} />
                                    </Grid>
                                </Grid>
                            </Block>

                            {Array.isArray(content.comunicadoArquivos) && Boolean(content.comunicadoArquivos.length) && (
                                <Block>
                                    <SubTitle label="Arquivos para download" />

                                    <Stack spacing={1}>
                                        {content.comunicadoArquivos.map((comunicadoArquivo) => (
                                            <DownloadBox
                                                hidden={!comunicadoArquivo.fgAtivo}
                                                image={comunicadoArquivo.arquivo.dsLinkThumbnail}
                                                link={comunicadoArquivo.arquivo.dsLink}
                                                label={comunicadoArquivo.arquivo.dsTitulo}
                                                key={comunicadoArquivo.idComunicadoArquivo}
                                            />
                                        ))}
                                    </Stack>
                                </Block>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </OneColumn>
    );
}
