import React from 'react';

import { Link, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Block } from 'components/page';
import { brlDate, brlPercentual, brlPrice } from 'util/format';
import { translateKey } from 'util/i18n';
import { TabuledGrid } from 'components/grid';
import { SubTitle } from 'components/text';

const getResumoLabel = (key: string): JSX.Element => {
    const tooltips = {
        vlBaseRoyalty: 'É igual ao valor da Tabela Base Faturamento (unit) * a quantidade de produto.',
        peRoyalty: 'Coeficiente considerado para o cálculo de Royalties.',
        vlRoyalty: 'É uma taxa cobrada pelo direito de uso da marca. Seu cálculo é igual ao coeficiente de royalties * o Total da Tabela Base Faturamento.',
        vlTotalDebito: 'Soma dos débitos que venham a ser lançados para a loja.',
        vlTotalCredito: 'Soma de todos os créditos sendo: Créditos diversos / Créditos devolução + o Crédito de Devolução Garantida.',
        vlDocumento: 'É igual ao Royalties Bruto - Total de créditos + Total de Débitos. Observação: O detalhamento e o cálculo constam no relatório "CONTA CORRENTE"',
    };
    const name = translateKey(`espelho_royalty_resumo_${key}`);
    const tooltip = key in tooltips && tooltips[key as keyof typeof tooltips];

    return (
        <React.Fragment>
            {name}
            {tooltip && (
                <Tooltip title={tooltip}>
                    <InfoOutlinedIcon
                        color="primary"
                        sx={{
                            ml: 1,
                            fontSize: '1.25rem',
                            marginTop: '-4px',
                            transform: 'translateY(4px)',
                        }}
                    />
                </Tooltip>
            )}
        </React.Fragment>
    );
};

const generateDetailLists = (data: any | undefined): any => {
    const last: any = [];

    const ignoreKeys = ['cdLoja', 'nrDocumento', 'nrNfse', 'dsMesCompetencia', 'dtEmissao', 'dsRps', 'lkNfse', 'dsNatureza'];

    Object.entries(data).forEach(([key, value]) => {
        if (!ignoreKeys.includes(key)) {
            const info = {
                label: getResumoLabel(key),
                value,
            };

            if (key.startsWith('vl')) {
                info.value = <strong>{brlPrice(info.value as number)}</strong>;
            }

            if (key.startsWith('pe')) {
                info.value = <strong>{brlPercentual(value as string)}</strong>;
            }

            if (key.startsWith('dt')) {
                info.value = <strong>{brlDate(value as string)}</strong>;
            }

            if (key.startsWith('lk')) {
                info.value = (
                    <Link href={info.value as string} target="_blank">
                        {info.value as string}
                    </Link>
                );
            }

            last.push(info);
        }
    });

    return last;
};

export function EspelhoRoyaltyResumoList({ list }: any): JSX.Element {
    const last = generateDetailLists(list);

    return (
        <Block>
            <SubTitle label="Detalhamento de Cobrança de Royalties" />

            <TabuledGrid list={last} leftColumnWidth={245} />
        </Block>
    );
}
