import React, { ChangeEvent } from 'react';

import { Box, Pagination, useMediaQuery, useTheme } from '@mui/material';

import { Centered } from 'components/grid';
import { ListagemContextData } from 'context/ListagemContext';
import { useLocalStorage } from 'hooks/useLocalStorage';

interface ListPaginationProps extends Pick<ListagemContextData, 'setPage' | 'page' | 'limit'> {
    data?: unknown;
    list?: unknown;
    onChange?: () => void;
}

const findTotal = (data: any): number => {
    let total = 1;

    if (data) {
        if ('total' in data) {
            total = data.total;
        } else if ('data' in data && typeof data.data === 'object') {
            total = findTotal(data.data);
        }
    }

    return total;
};

const getPaginationCount = (data: any, limit: number): number => {
    const dataTotal = findTotal(data);

    return Math.ceil(dataTotal / limit);
};

/**
 * Componente padrão para título dentro de bloco em uma página (geralmente título principal no topo do conteudo)
 * @param title titulo da página
 */
export function ListPagination({ data, list, limit, page, setPage, onChange }: ListPaginationProps): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const paginationCount = getPaginationCount(data, limit);
    const showPagination = Array.isArray(list) && Boolean(list.length) && paginationCount > 1;

    const handleChangePagination = (event: ChangeEvent<unknown>, nextPage: number): void => {
        setPage(nextPage);

        if (typeof onChange === 'function') {
            onChange();
        }
    };

    return (
        <React.Fragment>
            {showPagination && (
                <Box>
                    <Centered>
                        <Pagination
                            count={paginationCount}
                            page={page}
                            size={isMobile ? 'large' : 'medium'}
                            variant={isMobile ? 'outlined' : 'text'}
                            shape={isMobile ? 'rounded' : 'circular'}
                            color="primary"
                            onChange={handleChangePagination}
                            sx={{
                                '& li:not(:first-of-type):not(:last-of-type)': {
                                    display: {
                                        xs: 'none',
                                        md: 'inline-flex',
                                    },
                                },
                            }}
                        />
                    </Centered>
                </Box>
            )}
            <span />
        </React.Fragment>
    );
}
