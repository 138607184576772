import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useDebounce } from 'use-debounce';

import { Box } from '@mui/material';

import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CissTextField } from 'components/fields/';

interface StoreTypeOptionsProps {
    idTipoLoja: number;
    dsTipoLoja: string;
}

interface RequestListagemType extends RequestListagemProps {
    data: {
        data: StoreTypeOptionsProps[];
    };
}

interface StoreTypeFieldProps extends AutocompleteProps<StoreTypeOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
}

export function StoreTypeFieldFormik({ onChange, multiple = true, label = 'Tipo de Loja', ...PropsFormik }: StoreTypeFieldProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [query, setQuery] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [queryValue] = useDebounce(query, 500);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/tipoloja',
        query: queryValue,
        sort: [{ property: 'dsTipoLoja', direction: 'ASC' }],
        columns: 'idTipoLoja,dsTipoLoja',
        limit: 50,
    };

    const { isLoading, fetchStatus, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestListagemType> = RequestListagem(requestOptions);
            return request;
        },
        {
            keepPreviousData: false,
            enabled: open,
            onError: () => {
                enqueueSnackbar('Falha ao processar solicitação de tipo de lojas', { variant: 'error' });
            },
        },
    );

    useEffect(() => {
        if (open) {
            setQuery(inputValue);
        }
    }, [inputValue, open]);

    return (
        <Autocomplete
            {...PropsFormik}
            multiple={multiple}
            onChange={onChange}
            loading={isLoading && fetchStatus === 'fetching'}
            options={data?.data?.data || []}
            isOptionEqualToValue={(option: StoreTypeOptionsProps, value: StoreTypeOptionsProps) => option.idTipoLoja === value.idTipoLoja}
            getOptionLabel={(option: StoreTypeOptionsProps | string) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.dsTipoLoja;
            }}
            renderInput={(params) => <CissTextField {...params} label={label} />}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            renderOption={(props, option: StoreTypeOptionsProps) => (
                <Box component="li" {...props} key={option.idTipoLoja}>
                    {option.idTipoLoja} - {option.dsTipoLoja}
                </Box>
            )}
        />
    );
}
