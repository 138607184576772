export interface RequestCentroDistribuicaoProps {
    idCentroDistribuicao: number | null;
    dsCentroDistribuicao: string;
    cdCentroDistribuicao: string;
    fgAtivo: boolean;
}

export const initialCentroDistribuicaoValues: RequestCentroDistribuicaoProps = {
    idCentroDistribuicao: null,
    dsCentroDistribuicao: '',
    cdCentroDistribuicao: '',
    fgAtivo: true,
};
