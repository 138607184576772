import React from 'react';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';

import { RevendedorFile } from 'pages/revendedor/revendedor/types';

type RevendedorFilesPaper = {
    files: RevendedorFile[];
};

export function RevendedorFilesPaper({ files }: { files: RevendedorFile[] }): JSX.Element {
    const filesFormatted = files.map(({ documentFile }) => {
        const match = documentFile.match(/\/private\/([^?#]+)/);

        return {
            name: match && match[1] ? match[1] : documentFile,
            documentFile,
        };
    });

    return (
        <>
            {filesFormatted.reverse().map(({ documentFile, name }) => (
                <Paper key={Math.random()} sx={{ display: 'flex', alignItems: 'center', p: 1.5, gap: 1, height: 54 }}>
                    <ArticleOutlinedIcon />

                    <Typography sx={{ mr: 'auto' }}>{name}</Typography>

                    <Tooltip title="Visualizar">
                        <IconButton sx={{ p: 0.5 }} onClick={() => window.open(documentFile, '_blank')}>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Paper>
            ))}
        </>
    );
}
