import React, { useState } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import { ConfirmModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { brlDecimal, brlPrice } from 'util/format';

interface PedidoCarrinhoStack {
    isFetching: boolean;
}

export function PedidoCarrinhoStack({ isFetching }: PedidoCarrinhoStack): JSX.Element {
    const { carrinhoChanged, initialTotalizador, vlPedido, vlPesoLiquido, totalizador, loja, configPedido, funcionario } = usePedidoCompraContext();
    const navigate = useNavigate();
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const { hasPermission } = usePermissionsContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { usuario } = useAplicacaoDataContext();

    const vlTotalTotalizador = totalizador.vlTotal - initialTotalizador.vlTotal + vlPedido;
    const vlPesoLiquidoTotalTotalizador = totalizador.vlPesoLiquidoTotal - initialTotalizador.vlPesoLiquidoTotal + vlPesoLiquido;

    const navigateRascunho = () => {
        navigate({
            pathname: '/compra/carrinho',
            search: createSearchParams({
                ...(loja && { idLoja: JSON.stringify([loja]) }),
                ...((funcionario || usuario) && hasPermissionFinalizacaoGeral && { idUsuarioPedido: JSON.stringify(funcionario || usuario) }),
            }).toString(),
        });
    };

    return (
        <>
            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                text="Ao visualizar o carrinho, você perderá todas as alterações não salvas no pedido atual. Deseja continuar?"
                confirm={() => navigateRascunho()}
                cancel={() => {}}
            />

            <Stack>
                <SubTitle label="Meu Carrinho" fullWidth />

                <Button
                    variant="outlined"
                    onClick={() => {
                        if (carrinhoChanged) {
                            setOpenConfirmModal(true);
                        } else {
                            navigateRascunho();
                        }
                    }}
                >
                    Meus pedidos pendentes
                </Button>

                {isFetching && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress size={20} />
                    </Box>
                )}

                {!isFetching && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        {typeof vlPesoLiquidoTotalTotalizador === 'number' && configPedido && (
                            <Typography variant="body2" sx={{ mt: 2, textAlign: 'right' }}>
                                Peso total: <strong>{brlDecimal(vlPesoLiquidoTotalTotalizador, 0, 3)} Kg</strong>
                            </Typography>
                        )}

                        {typeof vlTotalTotalizador === 'number' && configPedido && (
                            <Typography variant="body2" sx={{ mt: 2, textAlign: 'right' }}>
                                Valor total: <strong>{brlPrice(vlTotalTotalizador)}</strong>
                            </Typography>
                        )}
                    </Box>
                )}
            </Stack>
        </>
    );
}
