import React, { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as dateFns from 'date-fns';
import { useQuery } from '@tanstack/react-query';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { OneColumn, Block } from 'components/page';
import { PanelGrid } from 'components/grid';
import { NotaDevolucaoListColumnActions, NotaDevolucaoListColumnStatus, NotaDevolucaoListDetailGrid, NotaDevolucaoListFilter } from 'pages/financeiro/nota-devolucao/components';
import { brlPrice } from 'util/format';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ProdutosNotaDevolucaoListProps, RequestProdutosNotaDevolucaoListProps } from 'pages/financeiro/nota-devolucao/types';
import { usePermissionsContext } from 'context/PermissionsContext';

export function NotaDevolucaoListComp(): JSX.Element {
    const navigate = useNavigate();
    const { filter, autoLoad, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const [searchParams] = useSearchParams();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/fiscal/movimentacaosimulacaodevolucao/pedido',
            sort: sort?.length ? sort : [{ property: 'dtMovimentacao', direction: 'DESC' }],
            filter,
            limit,
        }),
        [filter, sort, limit],
    );

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestProdutosNotaDevolucaoListProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const generateRows = useCallback((data: ProdutosNotaDevolucaoListProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item) => ({
                id: item.nrOrdemVenda,
                dtNota: item.dtMovimentacao,
                nrOrigem: item.nrNotaOrigem,
                nrOrdemVenda: item.nrOrdemVenda,
                tpStatus: item.status,
                vlTotal: item.vlTotalProdutos,
                vlTotalNota: item.vlTotalNota,
            }));
        }

        return [];
    }, []);

    const getDsLoja = useCallback((): string => {
        const { dsNomeFantasia, cdLoja } = JSON.parse(searchParams.get('cdCliente') || '{}');

        return `${cdLoja} - ${dsNomeFantasia}`;
    }, [searchParams]);

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'dtNota', headerName: 'Data', width: 100, minWidth: 90, valueFormatter: ({ value }) => dateFns.format(dateFns.parseISO(value), 'dd/MM/yyyy') },
            { field: 'dsLoja', headerName: 'Nome da Loja', minWidth: 120, flex: 1, valueGetter: getDsLoja },
            { field: 'nrOrigem', headerName: 'Nota Origem', width: 150, minWidth: 90 },
            { field: 'nrOrdemVenda', headerName: 'Nota Devolução', width: 150, minWidth: 100 },
            { field: 'tpStatus', headerName: 'Status', width: 175, minWidth: 70, renderCell: (params) => <NotaDevolucaoListColumnStatus value={params.value} /> },
            { field: 'vlTotal', headerName: 'Total dos Produtos', width: 150, minWidth: 120, type: 'number', valueFormatter: ({ value }) => brlPrice(value) },
            { field: 'vlTotalNota', headerName: 'Total da Nota', width: 150, minWidth: 100, type: 'number', valueFormatter: ({ value }) => brlPrice(value) },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                minWidth: 60,
                renderCell: ({ row }) => <NotaDevolucaoListColumnActions showRemoveBtn={hasPermission(['FINANCEIRO_DEVOLUCAO_EXCLUIR'])} row={row} reload={refetch} />,
            },
        ],
        [getDsLoja, hasPermission, refetch],
    );

    const rows = useMemo(() => generateRows(data?.data), [data?.data, generateRows]);

    return (
        <OneColumn
            title="Simulador de Nota Fiscal de Devolução"
            extraButtons={[
                {
                    title: '+ Novo',
                    onClick: () => navigate('form'),
                    visible: hasPermission(['FINANCEIRO_DEVOLUCAO_SIMULAR']),
                    featured: true,
                },
                {
                    title: 'Gerenciar Motivos',
                    onClick: () => navigate('motivo'),
                    visible: hasPermission(['FINANCEIRO_DEVOLUCAO_MOTIVO_GERENCIAR']),
                },
            ]}
        >
            <NotaDevolucaoListFilter />

            <Block>
                <PanelGrid
                    rows={rows}
                    rowsCount={rows.length}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={columns}
                    rowContentHeight={300}
                    rowContent={({ row }) => <NotaDevolucaoListDetailGrid nrOrdemVenda={row.nrOrdemVenda} />}
                />
            </Block>
        </OneColumn>
    );
}
