import React, { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';

import { MenuTreeViewFilterItemProps } from 'components/menu/menutreeviewfilter/types';

export function MenuTreeViewFilterItem<ResponseMenuTreeView>({
    menu,
    setFilter,
    idsPaiFilter,
}: MenuTreeViewFilterItemProps<ResponseMenuTreeView>): JSX.Element {
    const propsTreeItem = useMemo(
        () => ({
            nodeId: menu.nodeId,
            label: menu.rightLabel !== '0' && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', mr: 2 }}>{menu.label}</Typography>

                    {menu.rightLabel && <Typography sx={{ ml: 4 }}>{menu.rightLabel}</Typography>}
                </Box>
            ),
            onClick: () => {
                setFilter?.((oldState) => ({ ...oldState, value: menu.idsFilhos }));
            },
        }),
        [menu, setFilter, idsPaiFilter],
    );

    if (menu.submenus.length && menu.rightLabel !== '0') {
        return (
            <TreeItem {...propsTreeItem}>
                {menu.submenus.map((subMenu) => (
                    <MenuTreeViewFilterItem menu={subMenu} key={subMenu.nodeId} setFilter={setFilter} idsPaiFilter={menu.idsFilhos} />
                ))}
            </TreeItem>
        );
    }

    return <TreeItem {...propsTreeItem} />;
}
