import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { StatusField, CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';

export function FormFilterCategoria(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterCategoria, setFilterCategoria] = useState<FilterValuesProps>({
        property: 'dsCategoriaArquivo',
        operator: 'like',
        value: '',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: hasPermission(['ARQUIVO_CATEGORIA_MODERAR']) ? null : true,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterCategoria, setter: setFilterCategoria },
        { value: filterStatus, setter: setFilterStatus },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField
                    label="Nome da categoria"
                    value={filterCategoria.value}
                    onChange={(e) => setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            {hasPermission(['ARQUIVO_CATEGORIA_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
}
