import React from 'react';

import { Box } from '@mui/material';

import { PasswordValidation } from 'components/text';
import { ValidationsPassword } from 'util/validatePassword';

type PasswordValidationGroupProps = {
    validationsPassword: ValidationsPassword;
};

export function PasswordValidationGroup({ validationsPassword }: PasswordValidationGroupProps): JSX.Element {
    return (
        <Box sx={{ mb: 1, mt: 1.5, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <PasswordValidation text="A senha contém 10 caracteres" validated={validationsPassword.minLength} />
            <PasswordValidation text="A senha contém letras maiúsculas" validated={validationsPassword.upperLetter} />
            <PasswordValidation text="A senha contém letras minúsculas" validated={validationsPassword.lowerLetter} />
            <PasswordValidation text="A senha contém números" validated={validationsPassword.numbers} />
            <PasswordValidation text="A senha contém caracteres especiais" validated={validationsPassword.specialCharacters} />
        </Box>
    );
}
