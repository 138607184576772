import React from 'react';

import { OneColumn } from 'components/page';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { TabelaPrecoListFilter, TabelaPrecoRelatorio, TabelaPrecoGrid } from 'pages/compra/tabela-preco/components';

export function TabelaPrecoListComp(): JSX.Element {
    const { fgDownloadRelatorioReport } = useGetDomainConfig();

    return (
        <OneColumn title="Consulta de Tabelas de Preços" goBackButton createButton={false}>
            <TabelaPrecoListFilter />

            {fgDownloadRelatorioReport && <TabelaPrecoRelatorio />}

            <TabelaPrecoGrid />
        </OneColumn>
    );
}
