import React, { useState } from 'react';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { MenuSystemItem } from 'components/menu/system/components/MenuSystemItem';
import { ConfirmLogoutModal } from 'components/modal';

export function MenuSystemItemLogout(): JSX.Element {
    const [openLogoutConfirm, setOpenLogoutConfirm] = useState(false);

    return (
        <React.Fragment>
            <MenuSystemItem text="Sair" icon={<ExitToAppIcon fontSize="small" />} onClick={() => setOpenLogoutConfirm(true)} />

            <ConfirmLogoutModal open={openLogoutConfirm} setOpen={setOpenLogoutConfirm} />
        </React.Fragment>
    );
}
