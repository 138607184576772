import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { BandaPrecoFormComp, BandaPrecoListComp } from 'pages/complementar/banda-preco/components';

export function BandaPrecoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_BANDA_PRECO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <BandaPrecoListComp />
        </ListagemProvider>
    );
}

export function BandaPrecoForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_BANDA_PRECO_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <BandaPrecoFormComp />
        </FormProvider>
    );
}
