import React, { useState } from 'react';
import { TextField, TextFieldProps } from 'formik-mui';

import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

/**
 * Componente password com possibilida de mostrar/esconder senha enquanto digita
 */
export function Password(props: TextFieldProps): JSX.Element {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            required
            fullWidth
            margin="normal"
            id="password"
            label="Senha"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((oldState) => !oldState)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}
