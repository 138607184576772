import React, { useMemo } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid, StatusPedidoColumn } from 'components/grid';
import { SubTitle } from 'components/text';
import { ListagemProvider, useListagemContext } from 'context/ListagemContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoCompraTotalizadorProdutoGrid } from 'pages/compra/pedido-totalizador/components';
import { RequestPedidoTotalizadorQuebraProps, PedidoTotalizadorQuebraProps } from 'pages/compra/pedido-totalizador/types';
import { brlDate, brlPrice } from 'util/format';

interface PedidoCompraTotalizadorQuebraGridProps {
    row: GridRowModel;
    idPedidoStatusQuebra: number | null;
}

const getRows = (data: PedidoTotalizadorQuebraProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idPedido,
            idPedido: item.idPedido,
            dtInicioQuebraPedido: item.dtInicioQuebraPedido && brlDate(`${item.dtInicioQuebraPedido}T00:00:00`),
            dtEntrega: item.grade.dtEntrega && brlDate(`${item.grade.dtEntrega}T00:00:00`),
            dtColeta: item.grade.dtColeta && brlDate(`${item.grade.dtColeta}T00:00:00`),
            dtLimitePedido: item.grade.dtLimitePedido && brlDate(`${item.grade.dtLimitePedido}T00:00:00`),
            idLoja: item.loja.idLoja,
            cdLoja: item.loja.cdLoja,
            dsNomeFantasia: item.loja.dsNomeFantasia,
            dsRazaoSocial: item.loja.dsRazaoSocial,
            cdPedido: item.cdPedido ?? null,
            nrPedido: item.nrPedido ?? null,
            idConfigPedido: item.configPedido.idConfigPedido,
            dsConfigPedido: item.configPedido.dsConfigPedido,
            vlTotal: item.vlTotal,
            fgAtivo: item.fgAtivo,
            idPedidoStatus: item.pedidoStatus.idPedidoStatus,
            dsPedidoStatus: item.pedidoStatus.dsPedidoStatus,
            dsValidacao: item.dsValidacao,
        }));
    }

    return [];
};

export function PedidoCompraTotalizadorQuebraGrid({ row, idPedidoStatusQuebra }: PedidoCompraTotalizadorQuebraGridProps): JSX.Element {
    const { limit, page, sort, filter } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();

    const requestOptions = useMemo((): RequestOptionsType => {
        const filterRequest: FilterValuesProps[] = [{ property: 'idPedidoOrigem', value: row.idPedido, operator: 'eq' }, { property: 'fgAtivo', value: true, operator: 'eq' }, ...filter];

        if (idPedidoStatusQuebra) {
            filterRequest.push({ property: 'idPedidoStatus', value: idPedidoStatusQuebra, operator: 'eq' });
        }

        return {
            url: '/compra/pedidocompra/quebras',
            sort: sort?.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
            filter: filterRequest,
            limit,
            page,
        };
    }, [filter, idPedidoStatusQuebra, limit, page, row.idPedido, sort]);

    const { fetchStatus, data, refetch } = useQuery(['getListQuebras', requestOptions], (): Promise<RequestPedidoTotalizadorQuebraProps> => RequestListagem(requestOptions).then((res) => res.data));

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'dtInicioQuebraPedido', headerName: 'Data da Quebra', minWidth: 90, width: 90 },
            { field: 'dtLimitePedido', headerName: 'Data Limite do Pedido', minWidth: 90, width: 90 },
            { field: 'dtColeta', headerName: 'Data da Coleta', minWidth: 90, width: 90 },
            { field: 'dtEntrega', headerName: 'Data da Entrega', minWidth: 90, width: 90 },
            { field: 'dsConfigPedido', headerName: 'Configuração de Pedido', flex: 1, minWidth: 120 },
            { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 130 },
            { field: 'nrPedido', headerName: 'Código do Pedido', width: 100, minWidth: 90, filterable: false, sortable: false },
            { field: 'cdPedido', headerName: 'Código de Integração', width: 100, minWidth: 90, filterable: false, sortable: false },
            {
                field: 'vlTotal',
                headerName: 'Total Custo',
                type: 'number',
                align: 'right',
                width: 120,
                minWidth: 110,
                valueFormatter: ({ value }) => brlPrice(value),
            },
            {
                field: 'dsPedidoStatus',
                headerName: 'Situação do Pedido',
                width: 200,
                minWidth: 150,
                renderCell: ({ row }) => <StatusPedidoColumn idStatus={row.idPedidoStatus} dsStatus={row.dsPedidoStatus} dsValidacao={row.dsValidacao} />,
            },
            {
                field: 'grade',
                headerName: 'Grade',
                headerAlign: 'center',
                type: 'number',
                align: 'center',
                filterable: false,
                sortable: false,
                minWidth: 60,
                width: 60,
                disableColumnMenu: true,
                renderCell: ({ row }) => {
                    if (row.idPedidoStatus === 1) {
                        return (
                            <IconButton
                                sx={{ p: 0 }}
                                onClick={() => {
                                    const path = {
                                        pathname: '/logistica/grade/form',
                                        search: createSearchParams({
                                            idLoja: JSON.stringify({ idLoja: row.idLoja, cdLoja: row.cdLoja, dsNomeFantasia: row.dsNomeFantasia }),
                                        }).toString(),
                                    };

                                    navigate(path);
                                }}
                            >
                                <Tooltip title="Adicionar Grade">
                                    <LocalShippingOutlinedIcon />
                                </Tooltip>
                            </IconButton>
                        );
                    }
                },
            },
        ],
        [navigate],
    );

    return (
        <React.Fragment>
            <SubTitle label={'Lista de Quebras'} sx={{ ml: 2, mt: 2, mb: 2 }} />

            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                <PanelGrid
                    rows={getRows(data?.data)}
                    rowsCount={data?.total || 0}
                    loading={fetchStatus === 'fetching'}
                    reload={refetch}
                    columns={columns}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row: rowQuebra }) => (
                        <ListagemProvider>
                            <PedidoCompraTotalizadorProdutoGrid row={rowQuebra} />
                        </ListagemProvider>
                    )}
                    isTreeData
                    summaryPrefix={false}
                    summary={['vlTotal']}
                />
            </Box>
        </React.Fragment>
    );
}
