import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Funcionario } from 'components/autocompletes/FuncionarioAutoComplete';
import { TipoEntrega } from 'components/autocompletes/TipoEntregaAutoComplete';
import { ConfigPedidoProps, LojaProps, ProdutoCart, ProdutoPedido, Totalizador, UsuarioInfo } from 'pages/compra/pedido/types';

export type PedidoCompraContextData = {
    produtos: ProdutoCart[];
    setProdutos: Dispatch<SetStateAction<ProdutoCart[]>>;
    setProdutosInOrder: (produto: ProdutoCart) => void;

    produtoCartList: ProdutoCart[];
    initialProdutosCart: ProdutoCart[];
    setInitialProdutosCart: Dispatch<SetStateAction<ProdutoCart[]>>;

    produtosRequest: ProdutoPedido[];
    setProdutosRequest: Dispatch<SetStateAction<ProdutoPedido[]>>;

    isOpenInfoModal: boolean;
    setIsOpenInfoModal: Dispatch<SetStateAction<boolean>>;

    dataInfoModal: string;
    setDataInfoModal: Dispatch<SetStateAction<string>>;

    initialTotalizador: Totalizador;
    setInitialTotalizador: Dispatch<SetStateAction<Totalizador>>;

    idPedido: number | null;
    setIdPedido: Dispatch<SetStateAction<number | null>>;

    carrinhoChanged: boolean;
    setCarrinhoChanged: Dispatch<SetStateAction<boolean>>;

    loja: LojaProps | null;
    setLoja: Dispatch<SetStateAction<LojaProps | null>>;

    usuarioInfo: UsuarioInfo | null;
    setUsuarioInfo: Dispatch<SetStateAction<UsuarioInfo | null>>;

    configPedido: ConfigPedidoProps | null;
    setConfigPedido: Dispatch<SetStateAction<ConfigPedidoProps | null>>;

    tipoEntrega: TipoEntrega | null;
    setTipoEntrega: Dispatch<SetStateAction<TipoEntrega | null>>;

    funcionario: Funcionario | null;
    setFuncionario: Dispatch<SetStateAction<Funcionario | null>>;

    isSavingCarrinho: boolean;
    setIsSavingCarrinho: Dispatch<SetStateAction<boolean>>;

    isOpenMultiploModal: boolean;
    setIsOpenMultiploModal: Dispatch<SetStateAction<boolean>>;

    totalizador: Totalizador;
    setTotalizador: Dispatch<SetStateAction<Totalizador>>;

    isEmptyCart: boolean;
    vlPedido: number;
    vlPesoLiquido: number;
    getProdListQtd: (idProduto: number) => number;
    getProdCartQtd: (idProduto: number) => number;
    addProdCart: (idProduto: number) => void;
    removeProdCart: (idProduto: number) => void;
    modifyProdCartQtd: (produto: any, type: 'remove' | 'add' | 'fixed', value?: number) => void;
    modifyProdListQtd: (produtoPedido: ProdutoCart | ProdutoPedido, action: 'add' | 'remove' | 'fixed', value?: number) => void;
    focusProdutos: () => void;
    isProdutoInCart: (idProduto: number) => boolean;
    isProdutoDisabled: (produto: ProdutoCart | ProdutoPedido) => boolean;
    getProdEstqQtd: (produto: ProdutoCart | ProdutoPedido) => number;
    getProdMinQtd: (produto: number) => number;
    getProdMaxQtd: (produto: ProdutoCart | ProdutoPedido) => number | undefined;
    getProdCart: (produto: number) => ProdutoCart | undefined;
    configPedidoControlaEstoque: boolean;
    configPedidoValidateQtdMin: boolean;

    getMaxQtdValidated: (value: number, produtoPedido: ProdutoPedido) => number;
};

interface FormProviderProps {
    children?: ReactNode;
}

const FormContext = createContext<PedidoCompraContextData>({} as PedidoCompraContextData);

export function PedidoCompraProvider({ children }: FormProviderProps): JSX.Element {
    const queryClient = useQueryClient();

    const [produtos, setProdutos] = useState<ProdutoCart[]>([]);
    const [produtosRequest, setProdutosRequest] = useState<ProdutoPedido[]>([]);
    const [initialProdutosCart, setInitialProdutosCart] = useState<ProdutoCart[]>([]);
    const [initialTotalizador, setInitialTotalizador] = useState<Totalizador>({ vlPesoLiquidoTotal: 0, vlTotal: 0 });
    const [totalizador, setTotalizador] = useState<Totalizador>({ vlPesoLiquidoTotal: 0, vlTotal: 0 });
    const [idPedido, setIdPedido] = useState<number | null>(null);
    const [carrinhoChanged, setCarrinhoChanged] = useState<boolean>(false);
    const [loja, setLoja] = useState<LojaProps | null>(null);
    const [configPedido, setConfigPedido] = useState<ConfigPedidoProps | null>(null);
    const [usuarioInfo, setUsuarioInfo] = useState<UsuarioInfo | null>(null);
    const [tipoEntrega, setTipoEntrega] = useState<TipoEntrega | null>(null);
    const [funcionario, setFuncionario] = useState<Funcionario | null>(null);
    const [isSavingCarrinho, setIsSavingCarrinho] = useState<boolean>(false);
    const [isOpenMultiploModal, setIsOpenMultiploModal] = useState<boolean>(false);
    const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
    const [dataInfoModal, setDataInfoModal] = useState<string>('');

    const produtoCartList = useMemo(() => produtos.filter((produtoCart) => produtoCart.fgCart), [produtos]);
    const isEmptyCart = produtoCartList.length === 0;
    const vlPedido = useMemo(
        () => produtoCartList.reduce((acc, produtoCart) => acc + produtoCart.vlPrecoCompra * produtoCart.qtProdutoCart, 0),
        [produtoCartList],
    );
    const vlPesoLiquido = useMemo(
        () => produtoCartList.reduce((acc, produtoCart) => acc + (produtoCart.produto.vlPesoLiquido || 0) * produtoCart.qtProdutoCart, 0),
        [produtoCartList],
    );
    const configPedidoControlaEstoque = configPedido?.idTipoControleEstoquePedido !== 1; // 1 = não controla estoque
    const configPedidoValidateQtdMin = Boolean(configPedido?.configPedidoRegraValidacao.fgValidaQuantidadeMinimaMultiploProduto);

    const getProdMinQtd = useCallback((qtMinimaCompra: number) => (configPedidoValidateQtdMin ? qtMinimaCompra : 1), [configPedidoValidateQtdMin]);

    const getProdMaxQtd = useCallback(
        (produto: ProdutoCart | ProdutoPedido) => {
            const { qtMaximaProduto } = produto;
            const prodMinQtd = getProdMinQtd(produto.qtMinimaCompra);

            return qtMaximaProduto && qtMaximaProduto - (qtMaximaProduto % prodMinQtd);
        },
        [getProdMinQtd],
    );

    const getProdEstqQtd = useCallback(
        (produto: ProdutoCart | ProdutoPedido) => {
            const prodMinQtd = getProdMinQtd(produto.qtMinimaCompra);

            return Math.floor((produto.produto?.produtoEstoque?.qtEstoqueDisponivel ?? 0) / prodMinQtd) * prodMinQtd;
        },
        [getProdMinQtd],
    );

    const getProdCartQtd = useCallback(
        (idProduto: number): number => {
            const prod = produtos.find((prod) => prod.produto.idProduto === idProduto);

            return prod ? prod.qtProdutoCart : 0;
        },
        [produtos],
    );

    const getProdListQtd = useCallback(
        (idProduto: number): number => {
            const prod = produtos.find((prod) => prod.produto.idProduto === idProduto);

            return prod ? prod.qtProdutoList : 0;
        },
        [produtos],
    );

    const getProdCart = useCallback(
        (idProduto: number): ProdutoCart | undefined => produtos.find((prod) => prod.produto.idProduto === idProduto),
        [produtos],
    );

    const getMaxQtdValidated = useCallback(
        (prodQtd: number, produtoPedido: ProdutoPedido) => {
            const prodMaxEstqQtd = getProdEstqQtd(produtoPedido);
            const prodMaxQtd = getProdMaxQtd(produtoPedido);

            let isValid = true;
            let newProdQtd = prodQtd;

            // Caso exista estoque máximo e quantidade máxima, validamos qual é o menor dos dois valores e validamos por esse valor.
            // Caso não exista, validamos individualmente pelo estoque ou quantidade máxima.
            if (configPedidoControlaEstoque && prodMaxEstqQtd && prodMaxQtd) {
                const prodMenorQtd = Math.min(prodMaxQtd, prodMaxEstqQtd);

                if (prodQtd > prodMenorQtd) {
                    newProdQtd = prodMenorQtd;

                    isValid = false;
                }
            } else if (configPedidoControlaEstoque && prodMaxEstqQtd && prodQtd > prodMaxEstqQtd) {
                newProdQtd = prodMaxEstqQtd;

                isValid = false;
            } else if (prodMaxQtd && prodQtd > prodMaxQtd) {
                newProdQtd = prodMaxQtd;

                isValid = false;
            }

            if (!isValid) {
                const { dsProduto, unidadeMedida } = produtoPedido.produto;

                setIsOpenInfoModal(true);
                setDataInfoModal(
                    `O produto ${dsProduto} teve sua quantidade alterada para ${newProdQtd} ${unidadeMedida?.sgUnidadeMedida}, o máximo disponível no momento.`,
                );
            }

            return newProdQtd;
        },
        [configPedidoControlaEstoque, getProdEstqQtd, getProdMaxQtd],
    );

    // A fim de manter a ordem dos produtos adicionados, quando ja existir o produto na lista,
    // somente atualizamos ele na mesma posição do array, caso contrário adicionamos ele no final
    const setProdutosInOrder = useCallback(
        (produto: ProdutoCart) =>
            setProdutos((oldState) => {
                let hasProdutoSelectedInList = false;

                const newProductList = oldState.map((produtoState) => {
                    const selectedProduct = produtoState.produto.idProduto === produto.produto.idProduto;

                    if (!hasProdutoSelectedInList) {
                        hasProdutoSelectedInList = selectedProduct;
                    }

                    return selectedProduct ? produto : produtoState;
                });

                if (hasProdutoSelectedInList) {
                    return newProductList;
                }

                return [...newProductList, produto];
            }),
        [],
    );

    const removeProdCart = useCallback(
        (idProduto: number): void => {
            const prod = produtos.find((prod) => prod.produto.idProduto === idProduto);

            if (prod) {
                prod.fgCart = false;
                prod.qtProdutoList = 0;

                setProdutos((oldState) => [...oldState.filter((produtoState) => produtoState.produto.idProduto !== prod.produto.idProduto), prod]);
            }
        },
        [produtos],
    );

    const addProdCart = useCallback(
        (idProduto: number): void => {
            const prod = produtos.find((prod) => prod.produto.idProduto === idProduto);

            if (prod) {
                const newProd = { ...prod, qtProdutoCart: prod.qtProdutoList, fgCart: true };

                return setProdutosInOrder(newProd);
            }
        },
        [produtos, setProdutosInOrder],
    );

    const modifyProdCartQtd = useCallback(
        (produtoCart: ProdutoCart, action: 'add' | 'remove' | 'fixed', value?: number): void => {
            const prodMinQtd = getProdMinQtd(produtoCart.qtMinimaCompra);

            if (action === 'remove') {
                const qtdRemoved = Number((produtoCart.qtProdutoCart - prodMinQtd).toFixed(3));

                let newQtd = qtdRemoved;

                if (qtdRemoved > 0) {
                    newQtd = getMaxQtdValidated(qtdRemoved, produtoCart);
                } else {
                    newQtd = 0;
                }

                produtoCart.qtProdutoList = newQtd;
                produtoCart.qtProdutoCart = newQtd;
            } else if (action === 'add') {
                const newQtd = Number((produtoCart.qtProdutoCart + prodMinQtd).toFixed(3));

                produtoCart.qtProdutoCart = newQtd;
                produtoCart.qtProdutoList = newQtd;
            } else if (action === 'fixed' && value !== undefined) {
                produtoCart.qtProdutoCart = value;
                produtoCart.qtProdutoList = value;
            }

            if (produtoCart.qtProdutoCart <= 0) {
                produtoCart.qtProdutoCart = 0;
                produtoCart.qtProdutoList = 0;
                produtoCart.fgCart = false;
            }

            setProdutosInOrder(produtoCart);
        },
        [getMaxQtdValidated, getProdMinQtd, setProdutosInOrder],
    );

    const modifyProdListQtd = useCallback(
        (produtoPedido: ProdutoCart | ProdutoPedido, action: 'add' | 'remove' | 'fixed', value?: number): void => {
            const prod = produtos.find((prod) => prod.produto.idProduto === produtoPedido.produto.idProduto) || {
                ...produtoPedido,
                fgCart: false,
                qtProdutoList: 0,
                qtProdutoCart: 0,
            };
            const produtoPedidoMinQtd = getProdMinQtd(produtoPedido.qtMinimaCompra);
            const prodMinQtd = getProdMinQtd(prod.qtMinimaCompra);

            if (action === 'remove' && prod.qtProdutoList > 0) {
                const qtdRemoved = Number((prod.qtProdutoList - produtoPedidoMinQtd).toFixed(3));

                let newQtd = qtdRemoved;

                if (qtdRemoved > 0) {
                    newQtd = getMaxQtdValidated(qtdRemoved, produtoPedido);
                } else {
                    newQtd = 0;
                }

                prod.qtProdutoList = newQtd;
            } else if (action === 'add') {
                // para adicionarmos sempre que tiver 0 de quantidade o próximo valor é a quantidade minima
                prod.qtProdutoList = prod.qtProdutoList > 0 ? Number((prod.qtProdutoList + produtoPedidoMinQtd).toFixed(3)) : prodMinQtd;
            } else if (action === 'fixed' && value !== undefined) {
                prod.qtProdutoList = value;
            }

            if (prod.qtProdutoList >= prodMinQtd * 10) {
                setIsOpenMultiploModal(true);
            }

            setProdutosInOrder(prod);
        },
        [getMaxQtdValidated, getProdMinQtd, produtos, setProdutosInOrder],
    );

    const focusProdutos = useCallback(() => {
        const element = document.getElementById('listContainerProdutos');

        if (element) {
            const top = window.scrollY + element.getBoundingClientRect().top - 16;

            window.scrollTo({
                top,
                behavior: 'smooth',
            });
        }
    }, []);

    const isProdutoInCart = useCallback(
        (idProduto: number) => Boolean(produtoCartList.find((produtoCart) => produtoCart.produto.idProduto === idProduto)),
        [produtoCartList],
    );

    const isProdutoDisabled = useCallback(
        (produto: ProdutoCart | ProdutoPedido) => {
            const { qtEstoqueDisponivel } = produto.produto?.produtoEstoque || {};
            const prodMinQtd = getProdMinQtd(produto.qtMinimaCompra);
            const prodMaxQtd = getProdMaxQtd(produto);

            return (
                !produto.fgDisponivelCompra ||
                !prodMinQtd ||
                ((!qtEstoqueDisponivel || qtEstoqueDisponivel < prodMinQtd) && configPedidoControlaEstoque) ||
                prodMaxQtd === 0
            );
        },
        [configPedidoControlaEstoque, getProdMaxQtd, getProdMinQtd],
    );

    // Valida se houve mudanças no rascunho do pedido.

    // TODO alterar comparação para usar apenas o JSON.stringify dos dados iniciais e dos atuais
    useEffect(() => {
        let newChangedRascunho;

        if (initialProdutosCart.length !== produtoCartList.length) {
            newChangedRascunho = true;
        } else {
            newChangedRascunho = initialProdutosCart.some((produto) => {
                const existProduto = produtoCartList.find((produtoCart) => produtoCart.produto.idProduto === produto.produto.idProduto);

                if (!existProduto || (existProduto && existProduto.qtProdutoCart !== produto.qtProdutoCart)) {
                    return true;
                }

                return false;
            });
        }

        setCarrinhoChanged(newChangedRascunho);
    }, [initialProdutosCart, produtoCartList]);

    const contextValue = useMemo(
        () => ({
            produtos,
            setProdutos,
            produtosRequest,
            setProdutosRequest,
            produtoCartList,
            isEmptyCart,
            vlPedido,
            vlPesoLiquido,
            getProdCartQtd,
            removeProdCart,
            modifyProdCartQtd,
            initialProdutosCart,
            setInitialProdutosCart,
            initialTotalizador,
            setInitialTotalizador,
            idPedido,
            setIdPedido,
            carrinhoChanged,
            setCarrinhoChanged,
            totalizador,
            setTotalizador,
            getProdListQtd,
            modifyProdListQtd,
            addProdCart,
            loja,
            setLoja,
            configPedido,
            setConfigPedido,
            focusProdutos,
            isProdutoInCart,
            isProdutoDisabled,
            getProdEstqQtd,
            setProdutosInOrder,
            configPedidoControlaEstoque,
            usuarioInfo,
            setUsuarioInfo,
            tipoEntrega,
            setTipoEntrega,
            configPedidoValidateQtdMin,
            getProdMinQtd,
            getProdCart,
            funcionario,
            setFuncionario,
            isSavingCarrinho,
            setIsSavingCarrinho,
            getProdMaxQtd,
            isOpenInfoModal,
            setIsOpenInfoModal,
            dataInfoModal,
            setDataInfoModal,
            isOpenMultiploModal,
            setIsOpenMultiploModal,
            getMaxQtdValidated,
        }),
        [
            produtos,
            produtosRequest,
            produtoCartList,
            isEmptyCart,
            vlPedido,
            vlPesoLiquido,
            getProdCartQtd,
            removeProdCart,
            modifyProdCartQtd,
            initialProdutosCart,
            initialTotalizador,
            idPedido,
            carrinhoChanged,
            totalizador,
            getProdListQtd,
            modifyProdListQtd,
            addProdCart,
            loja,
            configPedido,
            focusProdutos,
            isProdutoInCart,
            isProdutoDisabled,
            getProdEstqQtd,
            setProdutosInOrder,
            configPedidoControlaEstoque,
            usuarioInfo,
            tipoEntrega,
            configPedidoValidateQtdMin,
            getProdMinQtd,
            getProdCart,
            funcionario,
            isSavingCarrinho,
            getProdMaxQtd,
            isOpenInfoModal,
            dataInfoModal,
            isOpenMultiploModal,
            getMaxQtdValidated,
        ],
    );

    return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
}

export const usePedidoCompraContext = (): PedidoCompraContextData => useContext(FormContext);
