import React from 'react';

import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';

type ReportBtn = {
    requestOptions: RequestOptionsType;
    disabled?: boolean;
};

export function ReportBtn({ requestOptions, disabled }: ReportBtn): JSX.Element {
    const { isLoadingFile, downloadFile } = useDownloadFile({ requestOptions });

    return (
        <LoadingButton
            variant="outlined"
            loading={isLoadingFile}
            disabled={isLoadingFile || disabled}
            startIcon={<RiFileExcel2Fill />}
            sx={{ color: 'green', borderColor: 'green', flex: { xs: 'auto', md: 'none' } }}
            onClick={() => downloadFile({ directDownload: true, newRequestOptions: requestOptions })}
        >
            Relatório em .xlsx
        </LoadingButton>
    );
}
