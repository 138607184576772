import React from 'react';

import { Divider } from '@mui/material';
import { Event } from 'react-big-calendar';

import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { CalendarioListEventHeader, CalendarioListEventContent } from 'pages/calendario/components';
import { getCategoryEventColor } from 'pages/calendario/components/templates/CalendarioListCalendar';

interface CalendarioListCalendarProps {
    eventoSelecionado: Event;
    store: StoreOptionsProps;
}

export function CalendarioListEvent({ eventoSelecionado, store }: CalendarioListCalendarProps): JSX.Element {
    const { resource, start, title } = eventoSelecionado;
    const categoryColor = getCategoryEventColor(resource.tpCalendarioEvento);
    const color = resource.calendarioEventoTag?.dsCorTag || resource?.dsCor;

    return (
        <React.Fragment>
            <CalendarioListEventHeader title={title} titleLarge={store.dsNomeFantasia} color={color} startDate={start} />

            <Divider
                sx={{
                    borderColor: categoryColor.hex,
                }}
            />

            <CalendarioListEventContent event={eventoSelecionado} />
        </React.Fragment>
    );
}
