import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface GrupoEmailsProps {
    idGrupoEmail: number;
    dsGrupoEmail: string;
    fgAtivo: boolean;
}

export interface RequestGrupoEmailsProps extends RequestListagemProps {
    data: GrupoEmailsProps[];
}

export interface RequestGrupoEmailProps extends RequestListagemProps {
    data: GrupoEmailsProps;
}

export interface GrupoEmailsDestinatariosProps {
    idGrupoEmailDestinatario?: number;
    dsNomeDestinatario: string;
    dsEmailDestinatario: string;
    fgAtivo: boolean;
}

export interface RequestGrupoEmailsDestinatariosProps extends RequestListagemProps {
    data: GrupoEmailsDestinatariosProps[];
}

export interface FormikGrupoEmailsDestinatariosProps {
    dsNomeDestinatario: string;
    dsEmailDestinatario: string;
    fgAtivo: boolean;
    grupoEmail: {
        idGrupoEmail: number;
    };
}

export const initialValues = (idGrupoEmail: number): FormikGrupoEmailsDestinatariosProps => ({
    dsNomeDestinatario: '',
    dsEmailDestinatario: '',
    fgAtivo: true,
    grupoEmail: {
        idGrupoEmail,
    },
});