import React from 'react';

import { LoadingButton } from '@mui/lab';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { useDownloadFile } from 'hooks/useDownloadFile';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType } from 'hooks/useRequestListagem';

interface RelatorioCreditoItemDetalhamentoCreditosProps {
    disabled: boolean;
    filter: FilterValuesProps[];
}

export function RelatorioCreditoItemDetalhamentoCreditos({ disabled, filter }: RelatorioCreditoItemDetalhamentoCreditosProps): JSX.Element {
    const requestOptions: RequestOptionsType = {
        url: '/financeiro/detalhamentocreditodebitoprodutorelatorio/item',
        columns: 'idDetalhamentoCreditoDebitoProdutoRelatorio',
        filter,
    };

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions,
    });

    const handleClickDownload = (): void => {
        downloadFile({ directDownload: true });
    };

    return (
        <LoadingButton loading={isLoadingFile} variant="outlined" color="success" onClick={handleClickDownload} disabled={disabled || isLoadingFile} startIcon={<RiFileExcel2Fill />}>
            Detalhamento de Créditos por Item
        </LoadingButton>
    );
}
