import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { EspelhoFppListComp } from './components';

export function EspelhoFppList(): JSX.Element {
    return (
        <ListagemProvider>
            <EspelhoFppListComp />
        </ListagemProvider>
    );
}
