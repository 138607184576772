import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { UsuarioListFilter } from 'pages/cadastro/usuario/components';
import { RequestUsuarioProps, UsuarioProps } from 'pages/cadastro/usuario/types';

const generateList = (data: UsuarioProps | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idUsuario: item.idUsuario,
            dsUsuario: item.dsUsuario,
            dsEmail: item.dsEmail,
            dsLogin: item.dsLogin ?? '-',
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function UsuarioListComp(): JSX.Element {
    const { page, filter, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/usuario',
        filter,
        sort: sort.length ? sort : [{ property: 'idUsuario', direction: 'DESC' }],
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestUsuarioProps> => RequestListagem(requestOptions).then((res) => res.data));

    const columns: GridColDef[] = [
        { field: 'idUsuario', headerName: 'Código do Usuário', minWidth: 100 },
        { field: 'dsUsuario', headerName: 'Nome', flex: 1, minWidth: 100 },
        { field: 'dsEmail', headerName: 'Email', flex: 1, minWidth: 100 },
        { field: 'dsLogin', headerName: 'Login', flex: 1, minWidth: 100 },
        { field: 'fgAtivo', headerName: 'Ativo', type: 'boolean', minWidth: 80 },
    ];

    if (hasPermission(['USUARIO_ALTERAR'])) {
        columns.push({
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 80,
            renderCell: (params) => <EditRemoveBtn showEditBtn={hasPermission(['USUARIO_ALTERAR'])} showRemoveBtn={false} id={params.row.idUsuario} />,
        });
    }

    return (
        <OneColumn title="Usuários" createButton={hasPermission(['USUARIO_CADASTRAR'])}>
            <UsuarioListFilter />

            <Block>
                <PanelGrid rows={generateList(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
