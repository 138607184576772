import React, { useEffect, useState } from 'react';

import { Button, Stack } from '@mui/material';

import { MarcaAutoComplete } from 'components/autocompletes/MarcaAutoComplete';
import { ProdutoAutoComplete } from 'components/autocompletes/ProdutoAutoComplete';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { FilterValuesProps } from 'hooks/useFilter';

interface ConfiguracaoPedidoCompraProdutosModalProps {
    open: boolean;
    setOpen: (action: boolean) => void;
    selectedProducts: any;
    setSelectedProducts: (action: any) => void;
    handleAdicionarProdutos: () => void;
}

export function ConfiguracaoPedidoCompraProdutosModal({ open, setOpen, selectedProducts, setSelectedProducts, handleAdicionarProdutos }: ConfiguracaoPedidoCompraProdutosModalProps): JSX.Element {
    const [selectedMarca, setSelectedMarca] = useState<{ idMarca: number } | null>(null);

    const filter: FilterValuesProps[] = [{ property: 'fgAtivo', operator: 'eq', value: true }];

    if (selectedMarca?.idMarca) {
        filter.push({ property: 'idMarca', value: selectedMarca.idMarca, operator: 'eq' });
    }

    useEffect(() => {
        if (!open) {
            setSelectedProducts([]);
            setSelectedMarca(null);
        }
    }, [open, setSelectedProducts]);

    return (
        <ContentModal closeButton open={open} setOpen={setOpen} sx={{ padding: 2, borderRadius: 1, maxWidth: 500 }}>
            <React.Fragment>
                <SubTitle label="Incluir Produto" />

                <MarcaAutoComplete size="small" label="Marca" value={selectedMarca} sx={{ mb: 2 }} onChange={(e, value) => setSelectedMarca(value)} />

                <ProdutoAutoComplete
                    label="Produtos"
                    size="small"
                    value={selectedProducts}
                    sx={{ mb: 2 }}
                    onChange={(e, value) => setSelectedProducts(value)}
                    requestOptions={{
                        url: '/gestao/produto',
                        sort: [{ property: 'cdProduto', direction: 'ASC' }],
                        filter,
                        columns: 'idProduto,dsProduto,fgAtivo,cdProduto,fgFracionavel,dsMarca,dsGrupo,qtMinimaCompra',
                    }}
                />

                <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>

                    <Button variant="contained" onClick={() => handleAdicionarProdutos()} disabled={!selectedProducts.length}>
                        Incluir
                    </Button>
                </Stack>
            </React.Fragment>
        </ContentModal>
    );
}
