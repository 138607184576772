import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export type TipoControlePedidoMinimo = {
    idTipoControlePedidoMinimo: number;
    dsTipoControlePedidoMinimo: string;
};

type TipoControlePedidoMinimoAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'size'>;

export function TipoControlePedidoMinimoAutoComplete({
    sx,
    value,
    error,
    size,
    disabled = false,
    multiple = false,
    onChange = () => {},
}: TipoControlePedidoMinimoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            size={size}
            error={error}
            value={value}
            onChange={onChange}
            multiple={multiple}
            disabled={disabled}
            objectId="idTipoControlePedidoMinimo"
            optionStringTemplate="{dsTipoControlePedidoMinimo}"
            selectedStringTemplate="{dsTipoControlePedidoMinimo}"
            requestOptions={{
                url: '/gestao/tipocontrolepedidominimo',
                sort: [{ property: 'idTipoControlePedidoMinimo', direction: 'ASC' }],
            }}
            isOptionEqualToValue={(option: TipoControlePedidoMinimo, value: TipoControlePedidoMinimo) =>
                value && option && option.idTipoControlePedidoMinimo === value.idTipoControlePedidoMinimo
            }
        />
    );
}
