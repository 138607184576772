import React from 'react';

import { Box, Typography } from '@mui/material';
import { isNumber } from 'lodash';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { PedidoCompraValue, PedidoProdutoModalInfoTitulo, PedidoProdutoModalInfoDescricao } from 'pages/compra/pedido/components';
import { ProdutoPedido } from 'pages/compra/pedido/types';
import { brlDecimal } from 'util/format';

interface PedidoProdutoModalInfo {
    produto: ProdutoPedido;
}

export function PedidoProdutoModalInfo({ produto }: PedidoProdutoModalInfo): JSX.Element {
    const { isProdutoDisabled, getProdMinQtd, configPedidoValidateQtdMin } = usePedidoCompraContext();
    const prodMinQtd = getProdMinQtd(produto.qtMinimaCompra);

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: { md: 'repeat(4, 1fr)', xs: 'repeat(2, 1fr)' }, gap: 1 }}>
            <Box>
                <PedidoProdutoModalInfoTitulo>
                    Preço
                    <span>Unitário</span>
                </PedidoProdutoModalInfoTitulo>

                <PedidoCompraValue value={produto.vlPrecoCompra} size="small" />
            </Box>

            <Box sx={{ opacity: isNumber(prodMinQtd) && configPedidoValidateQtdMin ? 1 : 0.5 }}>
                <PedidoProdutoModalInfoTitulo>
                    Quantidade
                    <span>Mínima</span>
                </PedidoProdutoModalInfoTitulo>

                <PedidoProdutoModalInfoDescricao>
                    {brlDecimal(prodMinQtd || 0, 0, 3)}
                    <span>
                        {produto.produto.unidadeMedida?.dsUnidadeMedida?.toLowerCase()}
                        {prodMinQtd > 1 && produto.produto.unidadeMedida?.dsUnidadeMedida && 's'}
                    </span>
                </PedidoProdutoModalInfoDescricao>
            </Box>

            <Box sx={{ opacity: isNumber(produto.estoqueLoja?.qtSaldoAtual) ? 1 : 0.5 }}>
                <PedidoProdutoModalInfoTitulo>
                    Estoque na
                    <span>Loja</span>
                </PedidoProdutoModalInfoTitulo>

                <PedidoProdutoModalInfoDescricao>
                    {brlDecimal(produto.estoqueLoja?.qtSaldoAtual || 0, 0, 3)}
                    <span>{produto.produto.unidadeMedida?.dsUnidadeMedida?.toLowerCase()}</span>
                </PedidoProdutoModalInfoDescricao>
            </Box>

            <Box>
                <PedidoProdutoModalInfoTitulo>
                    <br />
                    <span>Disponibilidade</span>
                </PedidoProdutoModalInfoTitulo>

                <Typography
                    sx={{
                        lineHeight: 1,
                        fontSize: '1.6rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                    }}
                >
                    {isProdutoDisabled(produto) ? 'Indísponivel' : 'Disponível'}
                </Typography>
            </Box>
        </Box>
    );
}
