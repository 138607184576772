import React from 'react';

import { ConfiguracaoPedidoCompraMenuAsideProvider } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { ConfiguracaoPedidoCompraFormComp, ConfiguracaoPedidoCompraListComp } from 'pages/compra/configuracao-pedido/components';

export function ConfiguracaoPedidoCompraForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_CONFIGURACAO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <FormProvider>
                <ConfiguracaoPedidoCompraMenuAsideProvider>
                    <ConfiguracaoPedidoCompraFormComp />
                </ConfiguracaoPedidoCompraMenuAsideProvider>
            </FormProvider>
        </ListagemProvider>
    );
}

export function ConfiguracaoPedidoCompraList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_CONFIGURACAO_VISUALIZAR'])) return alertNotPermission();
    
    return (
        <ListagemProvider>
            <ConfiguracaoPedidoCompraListComp />
        </ListagemProvider>
    );
}
