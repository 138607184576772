import React, { Dispatch, SetStateAction, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, CircularProgress, Grid, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { DownloadBox } from 'components/content';
import { DownloadBoxProps, DownloadBoxSkeleton } from 'components/content/DownloadBox';
import { Centered } from 'components/grid';
import { MenuTreeViewFilter } from 'components/menu/menutreeviewfilter/MenuTreeViewFilter';
import { MenuTreeViewItem } from 'components/menu/menutreeviewfilter/types';
import { OneColumn, Block } from 'components/page';
import { ListPagination } from 'components/pagination';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { FilterValuesProps } from 'hooks/useFilter';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { FormFilterArquivo } from 'pages/arquivo/components';
import { categoriaArquivoProps, MenuGestaoCategoriaArquivoResponse, RequestArquivosProps } from 'pages/arquivo/types';
import { getErrorMessage } from 'util/error';
import { camelCase } from 'util/format';

const generateCategoriaArquivoArray = (categorias: categoriaArquivoProps): string[] => {
    const { dsCategoriaArquivo, categoriaArquivoPai } = categorias;

    const categoriaList = [dsCategoriaArquivo];

    if (categoriaArquivoPai) {
        categoriaList.push(...generateCategoriaArquivoArray(categoriaArquivoPai));
    }

    return categoriaList;
};

const generateArquivosList = (data: RequestArquivosProps | undefined): DownloadBoxProps[] => {
    const list = data?.data?.data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => {
            const itemTags = Array.isArray(item.catalogoArquivoTagsCatalogoArquivo) ? item.catalogoArquivoTagsCatalogoArquivo : [];

            return {
                id: item.idCatalogoArquivo,
                label: item.arquivo.dsTitulo,
                description: item.dsResumo,
                link: item.arquivo.dsLink,
                extensao: item.arquivo.dsExtensao,
                tags: itemTags.map((tag) => tag.dsTagCatalogoArquivo),
                type: generateCategoriaArquivoArray(item.categoriaArquivo).reverse(),
                date: camelCase(dateFns.format(new Date(item.dhAlteracao), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })),
                active: item.fgAtivo,
                downloads: item.qtDownload,
            };
        });
    }

    return [];
};

const mapContentToTreeView = (item: MenuGestaoCategoriaArquivoResponse): MenuTreeViewItem<MenuGestaoCategoriaArquivoResponse> => ({
    nodeId: item.idCategoriaArquivo.toString(),
    idsFilhos: item.idsFilhos.map((i) => i.toString()),
    label: item.dsCategoriaArquivo,
    submenus: item.subCategoriasArquivo.map((sub) => mapContentToTreeView(sub)),
});

export function ArquivoListComp(): JSX.Element {
    const filterEl = useRef<null | { filterCategoria: FilterValuesProps; setFilterCategoria: Dispatch<SetStateAction<FilterValuesProps>> }>(null);

    const { limit, page, setPage, filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/catalogoarquivo/lista',
        filter,
        sort: [{ property: 'idCatalogoArquivo', direction: 'DESC' }],
        page,
        limit,
        nested: true,
    };

    const { isLoading, data, fetchStatus, isSuccess, isError, error, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestArquivosProps> = RequestListagem(requestOptions);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const arquivosList = generateArquivosList(data);
    const [requestError] = getErrorMessage(error);

    const { hasPermission } = usePermissionsContext();

    return (
        <OneColumn
            title="Arquivos"
            createButton={hasPermission(['ARQUIVO_CADASTRAR'])}
            extraButtons={[
                {
                    title: 'Gerenciar Categorias',
                    onClick: () => navigate('categoria'),
                    visible: hasPermission(['ARQUIVO_CATEGORIA_GERENCIAR']),
                },
            ]}
        >
            {isLoading && fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress sx={{ my: 2 }} />
                </Centered>
            )}

            <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                    <MenuTreeViewFilter<MenuGestaoCategoriaArquivoResponse>
                        url="/gestao/categoriaarquivo/aninhada"
                        title="Categorias"
                        setFilter={filterEl?.current?.setFilterCategoria}
                        filter={filterEl.current?.filterCategoria}
                        mapContentToTreeView={mapContentToTreeView}
                        extraParams={[{ fgAtivo: true }]}
                    />
                </Grid>

                <Grid item md={9}>
                    <FormFilterArquivo ref={filterEl} />

                    {isError && requestError && <Alert severity="error">{requestError}</Alert>}

                    {!arquivosList.length && isSuccess && <Alert severity="warning">Sem registros para exibir.</Alert>}

                    {!isError && Boolean(arquivosList.length) && (
                        <Block filled={false} sx={{ p: 0 }}>
                            <Stack spacing={2}>
                                {isLoading && fetchStatus === 'fetching' && Array.from(new Array(limit)).map(() => <DownloadBoxSkeleton key={Math.random()} />)}

                                {arquivosList.length &&
                                    arquivosList.map((item) => (
                                        <DownloadBox
                                            key={item.id}
                                            refresh={refetch}
                                            showEditBtn={hasPermission(['ARQUIVO_ALTERAR'])}
                                            showRemoveBtn={hasPermission(['ARQUIVO_EXCLUIR'])}
                                            {...item}
                                            removeUrl={`gestao/catalogoarquivo/${item.id}`}
                                            addViewUrl={`gestao/catalogoarquivo/download/add?idCatalogoArquivo=${item.id}`}
                                        />
                                    ))}
                            </Stack>
                        </Block>
                    )}

                    <ListPagination data={data} list={arquivosList} limit={limit} page={page} setPage={setPage} />
                </Grid>
            </Grid>
        </OneColumn>
    );
}
