import React from 'react';

import { Box, CircularProgress, SxProps, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useValidation } from 'hooks/useValidation';
import { TinyButton } from 'components/buttons';
import { Centered } from 'components/grid';

export interface TableZebraPropsListType {
    key: any;
    name: string | JSX.Element;
    value?: number | string | JSX.Element;
    featured?: boolean;
    onClick?: () => void;
}

export interface TableZebraPropsType {
    rows: TableZebraPropsListType[];
    sx?: SxProps;
    border?: number | string;
    loading?: boolean;
    showDetailsBtn?: boolean;
}

const readBorderConfig = (border: number | string | undefined): any => {
    const borderConfig: any = {
        borderStyle: 'solid',
        borderColor: 'grey.500',
    };

    if (typeof border === 'string') {
        const borderData = border.split(' '); // TOP RIGHT BOTTOM LEFT

        borderConfig.borderTopWidth = Number(borderData[0]);
        borderConfig.borderBottomWidth = Number(borderData[2] || borderData[0]);
        borderConfig.borderRightWidth = Number(borderData[1]);
        borderConfig.borderLeftWidth = Number(borderData[1] || borderData[3]);
    } else {
        borderConfig.borderWidth = typeof border === 'number' ? border : 1;
    }

    return borderConfig;
};

export function TableZebra({ rows, sx, border, loading, showDetailsBtn = true }: TableZebraPropsType): JSX.Element {
    const { validValue } = useValidation();

    return (
        <TableContainer component={Box} sx={sx}>
            {loading ? (
                <Centered>
                    <CircularProgress sx={{ my: 2 }} size="1.5rem" />
                </Centered>
            ) : (
                <Table
                    size="small"
                    sx={{
                        '& .MuiTableBody-root': readBorderConfig(border),
                        '& .MuiTableCell-body': {
                            borderBottomColor: 'grey.500',
                        },
                        '& tr': {
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'grey.200',
                            },
                            '&:last-of-type .MuiTableCell-body': {
                                border: 0,
                            },
                        },
                    }}
                >
                    <TableBody>
                        {rows.map(({ key, name, value, onClick, featured = false }) => {
                            const validVal = validValue(value);

                            let colSpan = 3;

                            if (validVal) {
                                colSpan--;
                            }

                            if (onClick) {
                                colSpan--;
                            }

                            return (
                                <TableRow key={key}>
                                    <TableCell
                                        colSpan={colSpan}
                                        sx={{
                                            fontWeight: featured ? 'bold' : '',
                                            px: featured ? 1 : 2,
                                        }}
                                    >
                                        {name}
                                    </TableCell>
                                    {validVal && <TableCell align="right">{value}</TableCell>}
                                    {onClick && showDetailsBtn && (
                                        <TableCell align="center" sx={{ width: 55 }}>
                                            <TinyButton onClick={onClick}>Detalhes</TinyButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
}
