import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { FormFilterPermissoes } from 'pages/cadastro/permissoes/components';
import { RequestPermissoesProps, PermissoesProps } from 'pages/cadastro/permissoes/types';

const generateList = (data: PermissoesProps | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idGrupoUsuario: item.idGrupoUsuario,
            dsGrupoUsuario: item.dsGrupoUsuario,
            dsObservacao: item.dsObservacao,
            dsGrupoEconomico: item.dsGrupoEconomico,
            fgPadraoFranqueado: item.fgPadraoFranqueado,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function PermissoesFormList(): JSX.Element {
    const { page, filter, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/grupousuario',
        filter,
        limit,
        page,
        sort: sort.length ? sort : [{ property: 'idGrupoUsuario', direction: 'DESC' }],
        columns: 'idGrupoUsuario,dsGrupoUsuario,dsObservacao,fgPadraoFranqueado,dsGrupoEconomico,fgAtivo',
        nested: true,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestPermissoesProps> => RequestListagem(requestOptions).then((res) => res.data));

    const columns: GridColDef[] = [
        { field: 'idGrupoUsuario', headerName: 'Cód. Grupo Usuário', width: 140, minWidth: 130 },
        { field: 'dsGrupoUsuario', headerName: 'Grupo Usuário', flex: 1, minWidth: 110 },
        { field: 'dsObservacao', headerName: 'Observação', flex: 1, width: 100, minWidth: 90 },
        { field: 'dsGrupoEconomico', headerName: 'Grupo Econômico', flex: 1, width: 100, minWidth: 120 },
        { field: 'fgPadraoFranqueado', headerName: 'Padrão', minWidth: 140, type: 'boolean' },
        { field: 'fgAtivo', headerName: 'Grupo Ativo', minWidth: 100, type: 'boolean' },
    ];

    if (hasPermission(['GRUPO_PERMISSAO_ALTERAR'])) {
        columns.push({
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 80,
            renderCell: (params) => <EditRemoveBtn id={params.row.idGrupoUsuario} sx={{ ml: 'auto', p: 1.5 }} />,
        });
    }

    return (
        <OneColumn title="Grupos de Permissões" createButton={hasPermission(['GRUPO_PERMISSAO_CADASTRAR'])}>
            <FormFilterPermissoes />

            <Block>
                <PanelGrid rows={generateList(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
