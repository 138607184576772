import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function MensagemPadraoGridFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterDsTitulo, setFilterDsTitulo] = useState<FilterValuesProps>({
        property: 'dsTitulo',
        operator: 'like',
        value: null,
        idProperty: 'dsTitulo',
    });

    const filters = [{ value: filterDsTitulo, setter: setFilterDsTitulo }];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField
                    label="Descrição"
                    value={filterDsTitulo.value}
                    onChange={(e) => setFilterDsTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
