import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery,  } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestGrupoEmailsDestinatariosProps } from 'pages/complementar/grupo-emails/types';

const getRows = (data: RequestGrupoEmailsDestinatariosProps | undefined): GridRowsProp => {
    if (Array.isArray(data?.data) && data?.data.length) {
        return data?.data.map((item) => ({
            id: item.idGrupoEmailDestinatario,
            idGrupoEmailDestinatario: item.idGrupoEmailDestinatario,
            dsNomeDestinatario: item.dsNomeDestinatario,
            dsEmailDestinatario: item.dsEmailDestinatario,
        }));
    }

    return [];
};

export function GrupoEmailsDestinatariosGrid(): JSX.Element {
    const { filter, page, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { id } = useParams();

    const requestOptions: RequestOptionsType = {
        url: 'gestao/grupoemaildestinatario',
        sort: sort.length ? sort : [{ property: 'dsNomeDestinatario', direction: 'DESC' }],
        filter: [...filter, { property: 'idGrupoEmail', value: id, operator: 'eq' }],
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery(
        ['getListDestinatarios', requestOptions],
        (): Promise<RequestGrupoEmailsDestinatariosProps> => RequestListagem(requestOptions).then((res) => res.data),
        { enabled: Boolean(id) },
    );

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'dsNomeDestinatario', headerName: 'Nome Destinatário', flex: 1, minWidth: 130 },
            { field: 'dsEmailDestinatario', headerName: 'Email Destinatário', flex: 1, minWidth: 130 },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                minWidth: 70,
                renderCell: ({ row }) => (
                    <EditRemoveBtn id={row.idGrupoEmailDestinatario} showEditBtn={false} removeUrl={`gestao/grupoemaildestinatario/${row.idGrupoEmailDestinatario}`} refresh={refetch} />
                ),
            },
        ],
        [],
    );

    return <PanelGrid rows={getRows(data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />;
}
