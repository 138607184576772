import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { Grid, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { TableZebra } from 'components/grid';
import { TableZebraPropsListType } from 'components/grid/TableZebra';
import { translateKey } from 'util/i18n';
import { brlPrice } from 'util/format';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { RequestExtratoFPPListModelProps } from 'pages/financeiro/extrato/fpp/types';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RoyaltiesStoreExtratoQuinzenaType } from 'pages/financeiro/extrato/royalty/types';
import { useListagemContext } from 'context/ListagemContext';
import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { usePermissionsContext } from 'context/PermissionsContext';

interface ExtratoRoyaltyTablesProps {
    quinzena: RoyaltiesStoreExtratoQuinzenaType;
    tpExtrato: string;
}

export function ExtratoRoyaltyTableQuinzena({ quinzena, tpExtrato }: ExtratoRoyaltyTablesProps): JSX.Element {
    const { fullFilterData } = useListagemContext();
    const navigate = useNavigate();
    const nrQuinzena = quinzena.tipoQuinzena === 'PRIMEIRA' ? 1 : 2;
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    let empresa: StoreOptionsProps = {} as StoreOptionsProps;
    let date = '';
    let mes = '';
    let ano = '';

    fullFilterData.forEach(({ value }) => {
        const { property, value: item } = value;

        if (property === 'cdLoja') {
            empresa = item;
        } else if (property === 'dtFiltro') {
            date = dateFns.format(item, 'MM/dd/yyyy');
            mes = date.split('/')[0];
            ano = date.split('/')[2];
        }
    });

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/fpp/extrato',
            columns: 'idExtratoFPP',
            nested: true,
            filter: [
                { property: 'cdLoja', operator: 'eq', value: empresa.cdLoja, logicalOperator: 'AND' },
                { property: 'dtFiltro', operator: 'eq', value: date, logicalOperator: 'AND' },
                { property: 'nrQuinzena', operator: 'eq', value: nrQuinzena, logicalOperator: 'AND' },
                { property: 'dsTipo', operator: 'eq', value: tpExtrato, logicalOperator: 'AND' },
            ],
        }),
        [date, empresa.cdLoja, nrQuinzena, tpExtrato],
    );

    const { fetchStatus, data } = useQuery([requestOptions], () => {
        const request: Promise<RequestExtratoFPPListModelProps> = RequestListagem(requestOptions).then((res: any) => res.data);
        return request;
    });

    const getFaturamentoName = useCallback((key: string): JSX.Element => {
        const tooltips = {
            vlRoyaltiesBruto:
                'É uma taxa cobrada pelo direito de uso da marca. Seu cálculo é igual ao coeficiente de royalties * o Total da Tabela Base Faturamento.',
            vlEstornoDeTaxa: 'Credito referente a acordo comercial (Já é a definição e não existe cálculo).',
            vlDescontoDevolucaoGarantida:
                'É uma condição contratual com margem de devolução fixa, referente a devoluções de produtos de linha. O valor do produto sem impostos + o valor de royalties bruto x 5% (apenas para faturamento de linha).',
            vlCreditoDevolucoesDiversas: 'Créditos referentes as notas fiscais de devolução de produtos que são emitidas pelo franqueado.',
            vlOutrosCreditos: 'Créditos que tem origem das campanhas, reembolsos, promoções e ações de venda da loja.',
            vlOutrosDebitos: 'Os débitos podem ser originados por diversos motivos, cujo seu valor será adicionado a cobrança de royalties líquido.',
            vlCreditosAnteriores:
                'Ocorre quando um credito não é totalmente absorvido em um coeficiente de royalties inicial, sendo assim o credito irá migrar para todos os demais coeficientes de royalties deduzindo o valor bruto royalties podendo ocorrer dentro da mesma quinzena ou podendo migrar para outras quinzenas até ser totalmente absorvido.',
            vlTotalRoyalties: 'É igual ao total de royalties “Bruto” menos todos os créditos, somado aos débitos.',
            vlSaldoResidualCredito: 'É o saldo residual de crédito que é gerado quando a cobrança de royalties é menor do que o total de crédito.',
        };
        const name = translateKey(`extrato_royalty_${key}`);
        const tooltip = key in tooltips && tooltips[key as keyof typeof tooltips];

        return (
            <React.Fragment>
                {name}
                {tooltip && (
                    <Tooltip title={tooltip}>
                        <InfoOutlinedIcon
                            color="primary"
                            sx={{
                                ml: 1,
                                fontSize: '1.25rem',
                                marginTop: '-4px',
                                transform: 'translateY(4px)',
                            }}
                        />
                    </Tooltip>
                )}
            </React.Fragment>
        );
    }, []);

    const faturamentoObjToList = useCallback((): any => {
        const list = Object.entries(quinzena.faturamento);
        const featuredKeys = ['vlTotalFaturamentoSemImpostos', 'vlSaldoResidualCredito', 'vlTotalRoyalties'];
        const rows: any = [
            {
                key: 'header',
                featured: true,
                name: 'Total de Royalties',
            },
        ];

        // o array abaixo determina a ordem das chaves
        const orderedKeys = [
            'header',
            'vlTotalFaturamentoSemImpostos',
            'vlTotalFaturamentoSemImpostos',
            'vlRoyaltiesBruto',
            'vlEstornoDeTaxa',
            'vlDescontoDevolucaoGarantida',
            'vlCreditoDevolucoesDiversas',
            'vlOutrosCreditos',
            'vlOutrosDebitos',
            'vlCreditosAnteriores',
            'vlSaldoResidualCredito',
            'vlTotalRoyalties',
        ];

        list.forEach((item) => {
            const [key, value] = item;
            const featured = featuredKeys.includes(key);

            rows.push({
                key,
                featured,
                name: getFaturamentoName(key),
                value: brlPrice(value as number),
            });
        });

        rows.sort((a: any, b: any) => {
            const indexA = orderedKeys.indexOf(a.key);
            const indexB = orderedKeys.indexOf(b.key);
            const orderA = indexA >= 0 ? indexA : list.length;
            const orderB = indexB >= 0 ? indexB : list.length;

            if (orderA < orderB) {
                return -1;
            }

            if (orderA > orderB) {
                return 1;
            }

            return 0;
        });

        return rows;
    }, [getFaturamentoName, quinzena.faturamento]);

    const documentosObjToList = useCallback((): any => {
        const rowsData: TableZebraPropsListType[] = [];

        if (Array.isArray(quinzena.documentos)) {
            quinzena.documentos.forEach((row: any) => {
                const { nrDocumento, dsDocumento, peDocumento } = row;

                rowsData.push({
                    key: `documento_${nrDocumento}`,
                    name: dsDocumento,
                    value: nrDocumento,
                    onClick: () => {
                        navigate({
                            pathname: 'espelho',
                            search: `?${createSearchParams({
                                ...row,
                                nrQuinzena,
                                tpExtrato,
                                idLoja: empresa.idLoja,
                                peDocumento,
                                cdLoja: empresa.cdLoja,
                                dtFiltro: date,
                                nrMes: mes,
                                nrAno: ano,
                            })}`,
                        });
                    },
                });
            });
        }

        return rowsData;
    }, [ano, date, empresa.cdLoja, empresa.idLoja, mes, navigate, nrQuinzena, quinzena.documentos, tpExtrato]);

    const fppObjToList = useCallback((): any => {
        const content = data?.data;
        const rowsData: TableZebraPropsListType[] = [];

        let total = 0;

        if (Array.isArray(content) && content.length) {
            content.forEach(({ fpp, notas }: any) => {
                total += fpp.vlFpp;

                rowsData.push({
                    key: `fpp_${fpp.nrFpp}`,
                    name: 'Espelho FPP',
                    value: fpp.nrFpp,
                    onClick: () => {
                        navigate({
                            pathname: '/financeiro/extrato/fpp/espelho',
                            search: `?${createSearchParams({
                                cdLoja: notas.at(0).loja.cdLoja,
                                nrFpp: fpp.nrFpp,
                                nrQuinzena: nrQuinzena.toString(),
                                nrMes: mes,
                                nrAno: ano,
                            })}`,
                        });
                    },
                });
            });
        }

        rowsData.unshift({
            key: 'fpp_title',
            name: 'Total FPP',
            value: brlPrice(total),
            featured: true,
        });

        return rowsData;
    }, [ano, data?.data, mes, navigate, nrQuinzena]);

    return (
        <Grid item sx={{ width: { sm: '50%', xs: '100%' } }}>
            <Typography
                variant="subtitle2"
                sx={{
                    'mb': 1,
                    'display': 'flex',
                    'justifyContent': 'center',

                    '& span': {
                        backgroundColor: 'primary.main',
                        color: 'common.white',
                        borderRadius: 1,
                        p: 1,
                    },
                }}
            >
                <span>{quinzena.dsQuinzena}</span>
            </Typography>

            <TableZebra rows={faturamentoObjToList()} />
            <TableZebra rows={documentosObjToList()} border="0 1 1" showDetailsBtn={hasPermission(['FINANCEIRO_ESPELHO_ROYALTIES_VISUALIZAR'])} />
            <TableZebra
                rows={fppObjToList()}
                sx={{ mt: 2 }}
                loading={fetchStatus === 'fetching'}
                showDetailsBtn={hasPermission(['FINANCEIRO_ESPELHO_FPP_VISUALIZAR'])}
            />
        </Grid>
    );
}
