import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { FullLoading } from 'components/loading';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    ConfiguracaoPedidoCompraProdutosGrid,
    ConfiguracaoPedidoCompraImportacaoUploadBtn,
    ConfiguracaoPedidoCompraProdutosModal,
    ConfiguracaoPedidoHeaderActions,
} from 'pages/compra/configuracao-pedido/components';
import { ProdutosListProps, ProdutosProps } from 'pages/compra/configuracao-pedido/types';

export function ConfiguracaoPedidoCompraProdutos(): JSX.Element {
    const { setActiveStepSaved, setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const { mutate, isLoading: mutateLoading } = useFormMutation();
    const { content, setContent } = useFormContext();
    const { produtosList, initialProdutosList } = content ?? {};
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const { id } = useParams();

    const [valueFilter, setValueFilter] = useState<string>('');
    const [selectedProducts, setSelectedProducts] = useState<ProdutosProps[]>([]);
    const [openProdutoModal, setOpenProdutoModal] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    // Cria a lista dos produtos a ser enviada pro backend
    const getPostProdutosList = useCallback((): ProdutosListProps[] => {
        const newProdutosList = produtosList.map((produto: ProdutosListProps) => produto);

        // Caso exista algum produto que tem na lista inicial mas não está na lista atual, o produto é adicionado com o fgAtivo = false.
        initialProdutosList.forEach((produto: ProdutosListProps) => {
            const produtoExist = produtosList.find((item: ProdutosListProps) => item.idProduto === produto.idProduto);

            if (!produtoExist) {
                produto.fgAtivo = false;

                newProdutosList.push(produto);
            }
        });

        // Cria o retorno correto usado no post
        return newProdutosList.map((produto: ProdutosListProps) => {
            return {
                ...produto,

                configPedido: {
                    idConfigPedido: Number(id),
                },
            };
        });
    }, [initialProdutosList, produtosList, id]);

    // Faz o request de post
    const onSubmitForm = useCallback((): void => {
        const values = getPostProdutosList();

        mutate(
            {
                url: 'gestao/configpedidoproduto/insertlist',
                formData: values,
                preventRedirect: true,
                preventSnack: true,
            },
            {
                onSuccess: ({ data }: any) => {
                    const newProdutosList = data ? data.filter((item: ProdutosListProps) => item.fgAtivo) : [];

                    setContent({ produtosList: newProdutosList, initialProdutosList: newProdutosList });

                    setActiveStepSaved();

                    enqueueSnackbar('Produtos salvos com sucesso', { variant: 'success' });
                },
                onError: (error: any) => {
                    showError(error, 'Erro ao salvar Produtos');
                },
            },
        );
    }, [enqueueSnackbar, getPostProdutosList, mutate, setContent, setActiveStepSaved, showError]);

    const handleAdicionarProdutos = useCallback(() => {
        let adicionarProdutos = true;

        const newProducts = selectedProducts
            .filter((produto) => {
                const productInList =
                    produtosList && produtosList.find((produtoList: ProdutosListProps) => produtoList.produto.idProduto === produto.idProduto);

                if (productInList) {
                    if (productInList.fgAtivo) {
                        enqueueSnackbar(`O produto ${productInList.produto.dsProduto} já existe na lista de produtos`, { variant: 'error' });

                        adicionarProdutos = false;

                        return false;
                    }
                }

                return true;
            })
            .map(
                (produto): ProdutosListProps => ({
                    produto,
                    idProduto: produto.idProduto,
                    qtMinimaProduto: produto.qtMinimaCompra || 0,
                    qtMaximaProduto: 0,
                }),
            );

        if (adicionarProdutos) {
            const newProductsList = [...(produtosList ? produtosList : []), ...newProducts];

            setOpenProdutoModal(false);

            setActiveStepDirty();

            setContent({ ...content, produtosList: newProductsList });
        }
    }, [content, enqueueSnackbar, produtosList, selectedProducts, setContent, setActiveStepDirty]);

    const inativaProdutos = (idList: number[]): void => {
        const newProdutosList = produtosList.filter((produto: ProdutosListProps) => !idList.includes(produto.produto.idProduto));

        setActiveStepDirty();

        setContent({ ...content, produtosList: newProdutosList });
    };

    return (
        <React.Fragment>
            <FullLoading loading={mutateLoading} />

            <ConfiguracaoPedidoCompraProdutosModal
                open={openProdutoModal}
                setOpen={setOpenProdutoModal}
                handleAdicionarProdutos={handleAdicionarProdutos}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
            />

            <Block>
                <SubTitle label="Ações" />

                <Grid container sx={{ justifyContent: 'space-between', gap: 2 }}>
                    <Grid item>
                        <ConfiguracaoPedidoCompraImportacaoUploadBtn
                            contentListName="produtosList"
                            url="gestao/configpedidoproduto/upload"
                            onSend={() => setActiveStepDirty()}
                        />
                    </Grid>
                    <Grid item>
                        <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_PRODUTOS_CONFIG_PEDIDO" />
                    </Grid>
                </Grid>
            </Block>

            <Block>
                <SubTitle label="Produtos" />

                <ConfiguracaoPedidoHeaderActions
                    setValueFilter={setValueFilter}
                    selectedRows={selectedRows}
                    searchPlaceholder="Código, nome ou categoria do produto..."
                    onClickAdd={() => setOpenProdutoModal(true)}
                    onClickRemove={() => inativaProdutos(selectedRows)}
                />

                <ListagemProvider>
                    <ConfiguracaoPedidoCompraProdutosGrid
                        valueFilter={valueFilter}
                        setSelectedRows={setSelectedRows}
                        inativaProdutos={inativaProdutos}
                    />
                </ListagemProvider>
            </Block>

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <LoadingButton variant="contained" loading={mutateLoading} disabled={mutateLoading} onClick={onSubmitForm} size="large">
                    Salvar
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
}
