import * as dateFns from 'date-fns';

interface dateToQuinzenaStringProps {
    nrQuinzena: string;
    nrMes: string;
    nrAno: string;
}

export const dateToQuinzenaString = (props: dateToQuinzenaStringProps): string[] => {
    const nrQuinzena = parseInt(props.nrQuinzena);
    const nrMes = parseInt(props.nrMes);
    const nrAno = parseInt(props.nrAno);

    const startQuinzenaDay = Number(nrQuinzena) === 1 ? 1 : 15;
    const quinzenaDate = new Date(nrAno, nrMes, startQuinzenaDay, 0, 0, 0);

    const endQuinzenaDay = Number(nrQuinzena) === 1 ? 15 : dateFns.endOfMonth(quinzenaDate).getDate();
    const startQuinzenaDate = new Date(quinzenaDate.getFullYear(), quinzenaDate.getMonth(), startQuinzenaDay, 0, 0, 0);
    const endQuinzenaDate = new Date(quinzenaDate.getFullYear(), quinzenaDate.getMonth(), endQuinzenaDay, 0, 0, 0);
    const startQuinzena = dateFns.format(startQuinzenaDate, 'dd/MM/yyyy');
    const endQuinzena = dateFns.format(endQuinzenaDate, 'dd/MM/yyyy');

    return [startQuinzena, endQuinzena];
};
