import React from 'react';
import * as dateFns from 'date-fns';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GridColDef } from '@mui/x-data-grid-pro';

import { Block } from 'components/page';
import { brlPrice } from 'util/format';
import { SubTitle } from 'components/text';
import { PanelGrid } from 'components/grid';
import { TinyButton } from 'components/buttons';
import { dateToQuinzenaString } from 'util/dateToQuinzenaString';

const generateNotaFiscalList = (data: any[] | undefined): any => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index,
            nrDocumento: item.nrNotaFiscal,
            dtDocumento: item.dtEmissao,
            vlDocumento: item.vlFpp,
        }));
    }

    return [];
};

export function EspelhoFppNotaList({ notas, cdLoja, nrQuinzena, nrMes, nrAno }: any): JSX.Element {
    const navigate = useNavigate();

    const handleClickNotaFiscalRow = (row: any): void => {
        const strLoja = cdLoja ? cdLoja.toString() : '';
        const strDtFiltro = dateFns.format(new Date(`${nrAno}-${nrMes}-${nrQuinzena === 1 ? 1 : 16}`), 'MM/dd/yyyy');

        navigate({
            pathname: 'nota-produto',
            search: `?${createSearchParams({
                cdLoja: strLoja,
                nrNota: row.nrDocumento,
                dtFiltro: strDtFiltro,
            })}`,
        });
    };

    const columns: GridColDef[] = [
        { field: 'nrDocumento', headerName: 'Nota Fiscal', minWidth: 130 },
        { field: 'dtDocumento', headerName: 'Data', minWidth: 140, flex: 1, valueFormatter: ({ value }) => dateFns.format(new Date(value), 'dd/MM/yyyy') },
        { field: 'vlDocumento', headerName: 'Total', minWidth: 120, type: 'number', valueFormatter: ({ value }) => brlPrice(value) },
        {
            field: 'detalhes',
            align: 'center',
            headerName: 'Detalhes',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            minWidth: 100,
            renderCell: (params) => <TinyButton onClick={() => handleClickNotaFiscalRow(params.row)}>Detalhes</TinyButton>,
        },
    ];

    const rows = generateNotaFiscalList(notas);
    const [quinzenaInicio, quinzenaFim] = dateToQuinzenaString({ nrQuinzena, nrMes, nrAno });

    return (
        <Block>
            <SubTitle
                label={
                    <React.Fragment>
                        Notas Fiscais Consideradas
                        <Tooltip title={`A geração do FPP da quinzena (${quinzenaInicio} - ${quinzenaFim}) foi realizada considerando as seguintes notas fiscais`}>
                            <InfoOutlinedIcon
                                color="success"
                                sx={{
                                    ml: 2,
                                    transform: 'translateY(3px)',
                                }}
                            />
                        </Tooltip>
                    </React.Fragment>
                }
            />

            <PanelGrid rows={rows} rowsCount={rows.length} reload={false} loading={false} columns={columns} remote={false} />
        </Block>
    );
}
