import React, { useCallback, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { LoadingButton } from '@mui/lab';
import { IconButton, Box, Stack, Button, Typography } from '@mui/material';

import { Imagem } from 'components/images';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { UploadButton } from 'components/upload/UploadButton';
import { useMimeTypes } from 'hooks/useMimeTypes';

interface UploadItem {
    file: File | any;
    status: 'pending' | 'uploading' | 'done' | string;
}

interface MultiUploadModalProps {
    setOpen: (open: boolean) => void;
    open: boolean;
    accept: any;
    callback?: any;
}

/**
 * Componente de Upload de Múltiplos Arquivos,
 */

export function MultiUploadModal({ setOpen, open, accept, callback }: MultiUploadModalProps): JSX.Element {
    const [uploadQueue, setUploadQueue] = useState<UploadItem[]>([]);
    const [errorFile, setErrorFile] = useState<JSX.Element | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { getFileNameWithoutExtension } = useMimeTypes();

    const getImagePreview = (selectedFile: any): JSX.Element => {
        const type = selectedFile.type.split('/')[0];

        let previewImg = null;

        if (type === 'image') {
            previewImg = (
                <Box sx={{ mt: 2 }}>
                    <Imagem src={URL.createObjectURL(selectedFile)} style={{ maxHeight: 250, margin: '0 auto' }} />
                </Box>
            );
        } else {
            previewImg = (
                <Box sx={{ mt: 2, backgroundColor: 'grey.200', p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '0.7rem' }}>
                    <InsertDriveFileOutlinedIcon sx={{ display: 'block', mb: 0.5 }} />
                    {selectedFile.name.split('.').at(-1)}
                </Box>
            );
        }

        return previewImg;
    };

    const handleFileChange = (selectedFiles: File[] | FileList | null): void => {
        if (!selectedFiles) {
            setErrorFile(null);
            return;
        }

        const newUploadItems: UploadItem[] = Array.from(selectedFiles).map((file) => ({
            file,
            status: 'pending',
        }));

        setUploadQueue((prevUploadQueue) => [...prevUploadQueue, ...newUploadItems]);
    };

    const resetErrors = (): void => {
        setErrorFile(null);
    };

    const handleUpload = useCallback(async () => {
        resetErrors();

        if (uploadQueue.length > 0) {
            const filesWithTitles = [];

            for (let i = 0; i < uploadQueue.length; i++) {
                const uploadItem = uploadQueue[i];

                filesWithTitles.push({ file: uploadItem.file, dsTitulo: getFileNameWithoutExtension(uploadItem.file.name) });
            }

            setIsLoading(true);

            // Chama o callback e espera a requisição ser concluída.
            await callback(filesWithTitles);
            setIsLoading(false);

            setUploadQueue([]); // Limpa a fila de upload
            setOpen(false);
        }

        if (uploadQueue.length === 0) {
            setErrorFile(
                <Typography color="error" sx={{ fontSize: '0.8rem', ml: 1.5, mt: 0.5 }}>
                    Selecione um arquivo para continuar.
                </Typography>,
            );
        }
    }, [uploadQueue, callback]);

    const handleRemoveFile = (index: number): any => {
        setUploadQueue(uploadQueue.filter((_, idx) => idx !== index));
    };

    return (
        <ContentModal
            open={open}
            setOpen={setOpen}
            onClose={() => {
                setUploadQueue([]);
            }}
            closeButton
            sx={{
                width: 400,
                borderRadius: 1,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <SubTitle label="Adicionar Arquivos" />

                <UploadButton label="Selecionar arquivos" onChangeMultiple={handleFileChange} multiple accept={accept} />
                {errorFile && <Typography color="error">{errorFile}</Typography>}

                {uploadQueue.map((uploadItem, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 1, mt: 2, borderRadius: 1, boxShadow: 1 }}>
                        <Box sx={{ flex: '0 0 80px', justifyContent: 'center', alignItems: 'center', mr: 1.5 }}>{uploadItem.file && getImagePreview(uploadItem.file)}</Box>
                        <Box sx={{ flex: '2', flexDirection: 'column', overflow: 'hidden' }}>
                            <Typography variant="body1" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 80px)' }}>
                                Nome do arquivo: {uploadItem.file.name}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: '0 0 50px', justifyContent: 'center', alignItems: 'center' }}>
                            <IconButton sx={{ width: 60 }} onClick={() => handleRemoveFile(index)} color="primary">
                                <DeleteOutlineIcon fontSize="large" />
                            </IconButton>
                        </Box>
                    </Box>
                ))}

                <Stack spacing={2} justifyContent="flex-end" direction="row" sx={{ mt: 3 }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setOpen(false);
                            setUploadQueue([]);
                        }}
                    >
                        Cancelar
                    </Button>

                    <LoadingButton variant="contained" loading={isLoading} onClick={handleUpload}>
                        Adicionar Arquivo
                    </LoadingButton>
                </Stack>
            </Box>
        </ContentModal>
    );
}
