import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface CentroDistribuicaoProps {
    idCentroDistribuicao: number;
    cdCentroDistribuicao: number;
    dsCentroDistribuicao: string;
}

type CentroDistribuicaoAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function CentroDistribuicaoAutoComplete({ value, error, multiple = false, sx, onChange, name }: CentroDistribuicaoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Centro de Distribuição"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsCentroDistribuicao}"
            optionStringTemplate="{dsCentroDistribuicao}"
            requestOptions={{
                url: '/gestao/centrodistribuicao',
                sort: [{ property: 'dsCentroDistribuicao', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
            }}
            isOptionEqualToValue={(option: CentroDistribuicaoProps, value: CentroDistribuicaoProps) => option.idCentroDistribuicao === value.idCentroDistribuicao}
            onChange={onChange}
            sx={sx}
            name={name}
        />
    );
}
