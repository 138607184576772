import React, { useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { TinyButton } from 'components/buttons';
import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { RequestOptionsType } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';

interface TituloListBoletoBtnProps {
    params: GridRenderCellParams<any, any, any>;
}

const BOLETO_URL = '/financeiro/titulo/boleto';
const BOLETO_COLUMNS = 'idBoleto';

function filterBoleto(nrTitulo: number, nrParcela: number, filter: RequestOptionsType['filter']): RequestOptionsType['filter'] {
    return [
        ...(Array.isArray(filter) ? filter : []),
        {
            property: 'nrTitulo',
            operator: 'eq',
            value: nrTitulo,
            logicalOperator: 'AND',
        },
        {
            property: 'nrParcela',
            operator: 'eq',
            value: nrParcela,
            logicalOperator: 'AND',
        },
    ];
}

export function TituloListBoletoBtn({ params }: TituloListBoletoBtnProps): JSX.Element {
    const { filter, fullFilterData } = useListagemContext();
    const { showError } = useShowError();
    const { mutate } = useFormMutation();
    const [fgImpresso, setFgImpresso] = useState<boolean>(params.row.fgImpresso);
    const filterLoja = fullFilterData?.find((filter) => filter.value.property === 'cdLoja');
    const { idLoja } = filterLoja?.value?.value || {};
    const { hasBoleto, nrTitulo, nrParcela, tpStatus } = params.row;
    const color = fgImpresso ? 'error' : 'success';
    const text = fgImpresso ? 'Impresso' : 'Imprimir';
    const tooltipText = fgImpresso ? 'Este boleto já foi impresso.' : '';

    useEffect(() => setFgImpresso(params.row.fgImpresso), [params.row.fgImpresso]);

    const requestOptions = {
        url: BOLETO_URL,
        columns: BOLETO_COLUMNS,
        nested: true,
        filter: filterBoleto(nrTitulo, nrParcela, filter),
    };

    const { isLoadingFile, downloadFile } = useDownloadFile({ requestOptions });

    const handleClick = (): void => {
        downloadFile({ newRequestOptions: requestOptions });

        mutate(
            {
                url: '/financeiro/tituloimpresso',
                preventSnack: true,
                formData: {
                    nrTitulo,
                    idLoja,
                    nrParcela,
                },
            },
            {
                onSuccess: () => setFgImpresso(true),
                onError: (error: unknown) => showError(error, 'Ocorreu um erro ao computar impressão tente novamente.'),
            },
        );
    };

    return (
        <Tooltip title={tooltipText} placement="top" arrow>
            <TinyButton variant="outlined" color={color} onClick={handleClick} disabled={!hasBoleto || isLoadingFile || tpStatus === 'PAGO'}>
                {text}
            </TinyButton>
        </Tooltip>
    );
}
