import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export type GrupoExibicao = {
    idDreMercadologicaGrupoExibicao: number;
    dsDreMercadologicaGrupoExibicao: string;
};

type GrupoExibicaoAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function GrupoExibicaoAutoComplete({
    sx,
    name,
    value,
    error,
    multiple = false,
    disabled = false,
    onChange = () => {},
}: GrupoExibicaoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            name={name}
            value={value}
            error={error}
            onChange={onChange}
            disabled={disabled}
            multiple={multiple}
            label="CMV"
            optionStringTemplate="{dsDreMercadologicaGrupoExibicao}"
            selectedStringTemplate="{dsDreMercadologicaGrupoExibicao}"
            isOptionEqualToValue={(option: GrupoExibicao, value: GrupoExibicao) =>
                option.idDreMercadologicaGrupoExibicao === value.idDreMercadologicaGrupoExibicao
            }
            requestOptions={{
                url: '/dre/dremercadologicagrupoexibicao/tipogrupoexibicao/2',
                sort: [{ property: 'dsDreMercadologicaGrupoExibicao', direction: 'ASC' }],
            }}
        />
    );
}
