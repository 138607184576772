import React, { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { Field, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { initialCategoriaValues, CategoriaModelProps } from '../../types';

import { CissTextField, CategoriaArquivoFieldFormik, ToggleFgSomenteLojasRevendedor } from 'components/fields/';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { DetalhesArquivoList } from 'pages/arquivo/categoria/components/templates/DetalhesArquivoList';
import { DetalhesSubCategoriaList } from 'pages/arquivo/categoria/components/templates/DetalhesSubCategoriaList';

export function CategoriaFormComp(): JSX.Element {
    const { content, setContent, setLoading } = useFormContext();
    const { hasPermission } = usePermissionsContext();
    const { id } = useParams();
    const hasModeratePermission = hasPermission(['ARQUIVO_CATEGORIA_MODERAR']);

    const url = '/gestao/categoriaarquivo/';

    const { mutate, isLoading } = useFormMutation();

    const handleSubmit = (values: CategoriaModelProps, formik: FormikHelpers<CategoriaModelProps>): void => {
        mutate(
            {
                url,
                idField: 'idCategoriaArquivo',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    setContent(data);
                },
            },
        );
    };

    useEffect(() => {
        setLoading(isLoading);
    }, [content, isLoading, setLoading]);

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsCategoriaArquivo: Yup.string().required(),
                categoriaArquivoPai: Yup.object().nullable(),
            }),
        [],
    );

    return (
        <FormPage
            title="Categoria de Arquivo"
            url={url}
            onSubmit={handleSubmit}
            values={initialCategoriaValues}
            fgAtivo={hasModeratePermission}
            validationSchema={validationSchema}
            extraContent={hasModeratePermission ? <ToggleFgSomenteLojasRevendedor /> : null}
        >
            {(formik: FormikProps<CategoriaModelProps>) => (
                <React.Fragment>
                    <Block>
                        <SubTitle label="Dados Gerais" />

                        <Row>
                            <Grid item flex={1}>
                                <CissTextField
                                    label="Nome da Categoria"
                                    name="dsCategoriaArquivo"
                                    value={formik.values.dsCategoriaArquivo}
                                    onChange={(e) => formik.setFieldValue('dsCategoriaArquivo', e.target.value)}
                                    error={formik.errors.dsCategoriaArquivo}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <Field component={CategoriaArquivoFieldFormik} name="categoriaArquivoPai" limitViewLevelsByCategoria={id || true} />
                            </Grid>
                        </Row>
                    </Block>

                    <ListagemProvider>
                        <DetalhesSubCategoriaList />
                    </ListagemProvider>

                    <ListagemProvider>
                        <DetalhesArquivoList />
                    </ListagemProvider>
                </React.Fragment>
            )}
        </FormPage>
    );
}
