import React, { ReactNode } from 'react';

import Grid, { GridProps } from '@mui/material/Grid';
import { SxProps } from '@mui/system';

interface GridCustomProps extends GridProps {
    children?: ReactNode;
    sx?: SxProps;
    hidden?: boolean;
}

/**
 * Componente responsável por centralizar um conteúdo na horizontal/vertical
 * Obs. por ser um container pode não funcionar corretamente em algumas estruturas
 */
// TODO analisar possibilidade de receber parametros sx também
export function Centered({ children, hidden, sx, ...props }: GridCustomProps): JSX.Element {
    return (
        <Grid
            container
            sx={{
                height: '100%',
                justifyContent: 'center',
                alignItems: { xs: 'start', md: 'center' },
                display: hidden ? 'none' : 'flex',
                ...sx,
            }}
            {...props}
        >
            {children}
        </Grid>
    );
}
