import React from 'react';

import { Box, Typography } from '@mui/material';
import * as dateFns from 'date-fns';

import { Tag, TagGroup } from 'components/text';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Comunicado } from 'pages/inicio/types';

type ComunicadoHeader = {
    comunicado: Comunicado;
};

export function ComunicadoHeader({ comunicado }: ComunicadoHeader): JSX.Element {
    const { isDownMd } = useBreakpoints();

    return (
        <Box
            sx={{
                px: 2,
                py: 1,
                display: { xs: 'grid', md: 'flex' },
                gridTemplateColumns: '1fr 1fr',
                gap: 1,
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                width: '100%',
            }}
        >
            {comunicado.dsComunicado && (
                <Box sx={{ maxWidth: '100%' }}>
                    <Typography variant={isDownMd ? 'body2' : undefined} sx={{ color: 'primary.main' }}>
                        Título
                    </Typography>

                    <Typography variant={isDownMd ? 'body1' : 'h6'} sx={{ color: 'common.black' }}>
                        {comunicado.dsComunicado}
                    </Typography>
                </Box>
            )}

            {comunicado.categoriaComunicado?.dsCategoriaComunicado && (
                <Box>
                    <Typography variant={isDownMd ? 'body2' : undefined} sx={{ color: 'primary.main' }}>
                        Categoria
                    </Typography>

                    <Typography variant={isDownMd ? 'body1' : 'h6'} sx={{ color: 'common.black' }}>
                        {comunicado.categoriaComunicado.dsCategoriaComunicado}
                    </Typography>
                </Box>
            )}

            {Array.isArray(comunicado.comunicadoTagsComunicado) && Boolean(comunicado.comunicadoTagsComunicado.length) && (
                <Box>
                    <Typography variant={isDownMd ? 'body2' : undefined} sx={{ color: 'primary.main' }}>
                        Hashtags
                    </Typography>

                    <Typography variant={isDownMd ? 'body1' : 'h6'} sx={{ color: 'common.black' }}>
                        <TagGroup max={3}>
                            {comunicado.comunicadoTagsComunicado.map((comunicadoTag) => (
                                <Tag label={comunicadoTag.dsTagComunicado} key={comunicadoTag.idTagComunicado} />
                            ))}
                        </TagGroup>
                    </Typography>
                </Box>
            )}

            {comunicado.dhInclusao && (
                <Box>
                    <Typography variant={isDownMd ? 'body2' : undefined} sx={{ color: 'primary.main' }}>
                        Publicado
                    </Typography>

                    <Typography variant={isDownMd ? 'body1' : 'h6'} sx={{ color: 'common.black' }}>
                        {dateFns.format(new Date(comunicado.dhInclusao), 'dd/MM/yyyy')}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
