import React from 'react';

import * as Yup from 'yup';

import { FullLoading } from 'components/loading';
import { FormPage } from 'components/page';
import { LocalFuncionarioGeneralBlock } from 'pages/complementar/local-funcionario/components';
import { usePostLocalFuncionario } from 'pages/complementar/local-funcionario/hooks';

export function LocalFuncionarioFormComp(): JSX.Element {
    const { handleSubmit, isLoading } = usePostLocalFuncionario();

    const validationSchema = Yup.object({
        cdLocalFuncionario: Yup.string().required().nullable(),
        dsLocalFuncionario: Yup.string().required().nullable(),
        dsTransportadora: Yup.string().required().nullable(),
        vlFrete: Yup.number()
            .when(['fgFreteGratis', 'fgRetirada'], {
                is: (fgFreteGratis: boolean, fgRetirada: boolean) => !fgFreteGratis && !fgRetirada,
                then: (schema) => schema.moreThan(0, 'O valor do frete deve ser maior que 0.'),
            })
            .nullable(),
        cep: Yup.object().shape({
            nrCep: Yup.string().length(8, 'O campo deve ter exatamente 8 números.').required().nullable(),
        }),
    });

    return (
        <FormPage
            fgAtivo
            goBackButton
            url="/gestao/localfuncionario/"
            title="Local de Funcionário"
            values={{ fgAtivo: true, vlFrete: 0 }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <FullLoading loading={isLoading} />

            <LocalFuncionarioGeneralBlock />
        </FormPage>
    );
}
