import React, { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Divider } from '@mui/material';

import menuData from '../utils/menuData.json';

import { MenuMainItem } from 'components/menu/main/components/MenuMainItem';
import { MenuMainNested } from 'components/menu/main/components/MenuMainNested';
import { MenuItemProps } from 'components/menu/main/types';
import { usePermissionsContext } from 'context/PermissionsContext';

interface RenderMenuItem {
    onClose: () => void;
}

export function MenuMainRenderer({ onClose }: RenderMenuItem): JSX.Element {
    const navigate = useNavigate();
    const lastGrupo = useRef<string | null>(null);
    const { hasPermission } = usePermissionsContext();

    const renderMenuItem = (menuItem: MenuItemProps): JSX.Element => {
        const handleClick = (): void => {
            navigate(menuItem.route);
            onClose();
        };

        if (menuItem.items) {
            lastGrupo.current = null;

            return (
                <MenuMainNested key={Math.random()} menuItem={menuItem}>
                    {menuItem.items.map(renderMenuItem)}
                </MenuMainNested>
            );
        }

        let showDivider = false;

        // Caso tenha permissão para o item ser exibido
        if (hasPermission(menuItem.permissions)) {
            // Exibe o divider caso não seja o primeiro item e o grupo do item atual é diferente do passado.
            showDivider = Boolean(lastGrupo.current && lastGrupo.current !== menuItem.grupo);

            // Salva o último grupo
            lastGrupo.current = menuItem.grupo ? menuItem.grupo : null;
        }

        return (
            <React.Fragment key={Math.random()}>
                {showDivider && <Divider />}

                <MenuMainItem menuItem={menuItem} callback={handleClick} />
            </React.Fragment>
        );
    };

    return <Box sx={{ py: 1, minWidth: 240 }}>{(menuData as MenuItemProps[]).map(renderMenuItem)}</Box>;
}
