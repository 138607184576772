import React, { SyntheticEvent, useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import { FieldArray, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { initialSolicitacaoPecaValues, RequestSolicitacaoPecaProps, SolicitacaoPecaProps } from '../../types';

import { StoreAutoComplete, CategoriaPecaAutoComplete } from 'components/autocompletes';
import { CategoriaPecaProps } from 'components/autocompletes/CategoriaPecaAutoComplete';
import { StoreOptionsProps } from 'components/autocompletes/StoreAutoComplete';
import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useFormMutation } from 'mutations/useFormMutation';
import { TradeSolicitacaoItemForm } from 'pages/marketing-trade/trade/solicitacoes-pecas/components';

export function TradeSolicitacoesPecaFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const { tokenInfo } = useGenericAuth();
    const { id } = useParams();

    const url = '/gestao/solicitacaopeca/';

    const { mutate } = useFormMutation();

    const handleSubmit = useCallback(
        (values: RequestSolicitacaoPecaProps, formik: FormikHelpers<RequestSolicitacaoPecaProps>): void => {
            mutate(
                {
                    url,
                    idField: 'idSolicitacaoPeca',
                    formData: values,
                    formik,
                },
                {
                    onSuccess: (response: any) => {
                        const { data } = response.data;

                        if (data) {
                            setContent(data);
                        }
                    },
                },
            );
        },
        [mutate, setContent],
    );

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                loja: Yup.object({
                    idLoja: Yup.number(),
                    cdLoja: Yup.string(),
                })
                    .required()
                    .nullable(),
                categoriaPeca: Yup.object({
                    idCategoriaPeca: Yup.number(),
                    dsCategoriaPeca: Yup.string(),
                })
                    .required()
                    .nullable(),
                itens: Yup.array().of(
                    Yup.object().shape({
                        dsTipoPeca: Yup.string().required().max(200),
                        dsLocalVeiculacao: Yup.string().required().max(200),
                        dsObservacao: Yup.string().required().min(2).max(200),
                        vlLargura: Yup.number().required().positive().integer(),
                        vlAltura: Yup.number().required().positive().integer(),
                        unidadeMedidaPeca: Yup.object().required().nullable(),
                    }),
                ),
            }),
        [],
    );

    return (
        <FormPage title="Solicitação de Peças Trade" values={initialSolicitacaoPecaValues} onSubmit={handleSubmit} url={url} validationSchema={validationSchema} fgAtivo={false} goBackButton>
            {(formik: FormikProps<Partial<SolicitacaoPecaProps>>) => (
                <React.Fragment>
                    <Block>
                        <Row>
                            <Grid item flex={1}>
                                <StoreAutoComplete
                                    name="loja"
                                    defaultStore={!id}
                                    value={formik.values.loja}
                                    error={formik.errors.loja}
                                    onChange={(e: SyntheticEvent<Element, Event>, value: StoreOptionsProps) => {
                                        formik.setFieldValue('loja', value);
                                    }}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <CissTextField label="Solicitante" disabled value={tokenInfo?.dsUsuario} />
                            </Grid>

                            <Grid item flex={1}>
                                <CissTextField label="Email Solicitante" disabled value={tokenInfo?.dsEmail} />
                            </Grid>

                            <Grid item flex={1}>
                                <CategoriaPecaAutoComplete
                                    name="categoriaPeca"
                                    value={formik.values.categoriaPeca}
                                    error={formik.errors.categoriaPeca}
                                    onChange={(e: any, value: CategoriaPecaProps) => formik.setFieldValue('categoriaPeca', value)}
                                    multiple={false}
                                />
                            </Grid>
                        </Row>
                    </Block>

                    <FieldArray name="itens">{(arrayHelpers) => <TradeSolicitacaoItemForm {...arrayHelpers} />}</FieldArray>

                    <Box>
                        <Alert severity="info" sx={{ fontSize: '1rem', mt: 3, mb: 1 }}>
                            <AlertTitle sx={{ fontSize: '1.1rem' }}>Atenção</AlertTitle>
                            <Box>
                                O prazo para aprovação da solicitação é de <strong>2 dias úteis</strong>.<br></br>O prazo para enviar a arte finalizada em alta resolução é de até 10 dias úteis.
                            </Box>
                        </Alert>
                    </Box>
                </React.Fragment>
            )}
        </FormPage>
    );
}
