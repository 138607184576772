import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RevendedorRequest } from '../types';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';

export function useGetRevendedor(): UseQueryResult<RevendedorRequest> {
    const requestListagem = useRequestListagem();
    const { page, filter, autoLoad } = useListagemContext();
    const { showError } = useShowError();

    const requestOptions: RequestOptionsType = {
        url: '/integracao-revendedor/cadastrorevendedor',
        sort: [{ property: 'nome', direction: 'DESC' }],
        nested: true,
        limit: 10,
        filter,
        page,
    };


    return useQuery<RevendedorRequest>([requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
        onError: (res) => showError(res),
    });
}
