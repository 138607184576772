import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { FormProvider } from 'context/FormContext';
import { BannerFormComp, BannerListComp } from 'pages/banner/components';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function BannerList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['BANNER_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <BannerListComp />
        </ListagemProvider>
    );
}

export function BannerForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('BANNER', id)) return alertNotPermission();

    return (
        <FormProvider>
            <BannerFormComp />
        </FormProvider>
    );
}
