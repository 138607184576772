import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { PercentualNotaFiscalListComp } from 'pages/compra/percentual-nota-fiscal/components';

export function PercentualNotaFiscalList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PERCENTUAL_NOTA_FISCAL_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PercentualNotaFiscalListComp />
        </ListagemProvider>
    );
}
