import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

import { ImportFileBtn } from 'components/buttons';
import { DownloadModeloArquivoBtn } from 'components/buttons/DownloadModeloArquivoBtn';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { useImportLocalFuncionario } from 'pages/complementar/local-funcionario/hooks';

type TLocalFuncionarioImportModal = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function LocalFuncionarioImportModal({ open, setOpen }: TLocalFuncionarioImportModal): JSX.Element {
    const [fileImportacao, setFileImportacao] = useState<File | null>(null);
    const { handleSubmit, isLoading } = useImportLocalFuncionario({ setOpen });

    useEffect(() => {
        if (!open) {
            setFileImportacao(null);
        }
    }, [open]);

    return (
        <ContentModal
            closeButton
            open={open}
            setOpen={setOpen}
            sx={{
                borderRadius: 1,
                minWidth: {
                    xs: '80%',
                    sm: 500,
                },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 0.5, alignItems: 'flex-start' }}>
                <SubTitle label="Importar Locais de Funcionário" sx={{ width: '75%' }} />

                <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_LOCAL_FUNCIONARIO" sx={{ mb: 2, width: { xs: '100%', md: 'auto' } }} />

                <ImportFileBtn setFile={setFileImportacao} file={fileImportacao} accept={['.xlsx', '.csv']} />

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'flex-end', width: '100%', gap: 2, mt: 1 }}>
                    <Button variant="outlined" onClick={() => setOpen(false)} disabled={isLoading}>
                        Cancelar
                    </Button>

                    <LoadingButton
                        variant="contained"
                        onClick={() => fileImportacao && handleSubmit(fileImportacao)}
                        loading={isLoading}
                        disabled={!fileImportacao || isLoading}
                    >
                        Importar
                    </LoadingButton>
                </Box>
            </Box>
        </ContentModal>
    );
}
