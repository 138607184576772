import React, { DetailedHTMLProps, ImgHTMLAttributes, SyntheticEvent } from 'react';

import { deepmerge } from '@mui/utils';

/**
 * Sucesso no carregamento da imagem
 * Setamos a className para success
 */
const imageOnLoadHandler = (event: SyntheticEvent<HTMLImageElement, Event>): void => {
    if (event.currentTarget.className !== 'error') {
        event.currentTarget.className = 'success';
    }
};

/**
 * Erro durante carregamento da imagem
 * Setamos a className para error e mudamos o src da imagem (TODO ajustar)
 */
const imageOnErrorHandler = (event: SyntheticEvent<HTMLImageElement, Event>): void => {
    event.currentTarget.remove();
    event.currentTarget.className = 'error';
};

/**
 * Componente padrão para imagens, com trativa de erro/sucesso
 */
// TODO analisar possibilidade de durante erro alterar para ícone de erro sem forçar tamanho de imagem
const defaultStyle = {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    display: 'block',
};

export function Imagem({ style, ...props }: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>): JSX.Element | null {
    if (props.src) {
        return <img style={deepmerge(defaultStyle, style)} {...props} onLoad={imageOnLoadHandler} onError={imageOnErrorHandler} />;
    }

    return null;
}
