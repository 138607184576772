import React, { useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { Box, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import { Centered } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { StatusIntegracaoCardIndicator, StatusIntegracaoCardIndicatorStatus } from 'pages/compra/pedido-totalizador/status-integracao/components';
import { PacoteAtual, RequestStatusIntegracao, StatusIntegracaoCardIndicatorProps } from 'pages/compra/pedido-totalizador/status-integracao/types';
import { SincronizacaoStatus } from 'pages/compra/pedido-totalizador/types';

const getCurrentTime = (): string => format(new Date(), 'HH:mm:ss');

const getDadosStatus = (data: RequestStatusIntegracao | PacoteAtual, tempoRestante: string | undefined): StatusIntegracaoCardIndicatorProps[] => [
    { color: 'orange', icon: 'box', label: 'Total de Pedidos', value: data?.qtTotalPedido },
    { color: 'red', icon: 'cancel', label: 'Falha', value: data?.qtTotalPedidoFalha, peValue: data?.pePedidoFalha },
    { color: 'green', icon: 'success', label: 'Sucesso', value: data?.qtTotalPedidoFinalizadoSucesso, peValue: data?.pePedidoFinalizadoSucesso },
    { color: 'pink', icon: 'article', label: 'Pedidos Restantes', value: data?.qtTotalPedidoRestante, peValue: data?.pePedidoRestante },
    { color: 'blue', icon: 'clock', label: 'Tempo restante', value: tempoRestante || 'Calculando...' },
];

export function StatusIntegracaoIndicator(): JSX.Element {
    const [searchParams] = useSearchParams();
    const configPedidoOrigemList = searchParams.get('configPedidoOrigemList');
    const RequestListagem = useRequestListagem();
    const queryClient = useQueryClient();

    const [lastUpdate, setLastUpdate] = useState<string>('');

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidototalizadorsincronizacaomonitoramento/configpedidoorigem',
        extraParams: [{ idConfigPedidoOrigem: configPedidoOrigemList && JSON.parse(configPedidoOrigemList).join(',') }],
    };

    const { data } = useQuery(['getStatusIntegracao', requestOptions], (): Promise<RequestStatusIntegracao> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(configPedidoOrigemList),
        refetchOnWindowFocus: true,
        refetchInterval: (data) => {
            if (data) {
                const { idPedidoTotalizadorSincronizacaoStatus } = data.pedidoTotalizadorSincronizacaoStatus;

                if ([SincronizacaoStatus.AGUARDANDO, SincronizacaoStatus.PROCESSANDO].includes(idPedidoTotalizadorSincronizacaoStatus)) {
                    return 1000 * 10; // 10 segundos
                }
            }

            return false;
        },
        onSuccess: () => {
            setLastUpdate(getCurrentTime());

            queryClient.invalidateQueries(['getPedidos']);
        },
    });

    return (
        <Block>
            <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
                {data ? (
                    <React.Fragment>
                        <Grid item lg={9} width="100%" display="flex" flexDirection="column">
                            <SubTitle
                                label={
                                    <React.Fragment>
                                        Ultima atualização: {lastUpdate}
                                        <Tooltip title="Atualizar status">
                                            <IconButton onClick={() => queryClient.invalidateQueries(['getStatusIntegracao'])}>
                                                <CachedOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            />

                            <Grid container spacing={1} height="100%">
                                {getDadosStatus(data, data.tempoRestante).map((item) => (
                                    <Grid key={Math.random()} item xs={6} md={2.4}>
                                        <StatusIntegracaoCardIndicator color={item.color} label={item.label} value={item.value} peValue={item.peValue} icon={item.icon} />
                                    </Grid>
                                ))}
                            </Grid>

                            <SubTitle sx={{ mt: 1 }} label={`Pacote: ${data?.pacoteAtual?.nrPosicao}/${data?.qtTotalPacote}`} />

                            <Grid container spacing={1} height="100%">
                                {getDadosStatus(data.pacoteAtual, data.tempoRestantePacote).map((item) => (
                                    <Grid key={Math.random()} item xs={6} md={2.4}>
                                        <StatusIntegracaoCardIndicator color={item.color} label={item.label} value={item.value} peValue={item.peValue} icon={item.icon} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item lg={3} display="flex" flexDirection="column" width="100%">
                            <SubTitle label="Status da integração" fullWidth />

                            <StatusIntegracaoCardIndicatorStatus
                                statusIntegracao={data.pedidoTotalizadorSincronizacaoStatus}
                                statusIntegracaoPacote={data.pacoteAtual?.pedidoTotalizadorSincronizacaoStatus}
                                nrPosicao={data.pacoteAtual?.nrPosicao}
                                qtTotalPacote={data.qtTotalPacote}
                                idPedido={data.idPedidoTotalizadorSincronizacao}
                            />
                        </Grid>
                    </React.Fragment>
                ) : (
                    <Box sx={{ width: '100%', height: 200 }}>
                        <Centered sx={{ alignItems: 'center' }}>
                            <CircularProgress />
                        </Centered>
                    </Box>
                )}
            </Grid>
        </Block>
    );
}
