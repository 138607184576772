import React from 'react';

import { OneColumn } from 'components/page';
import { BandaPrecoGrid } from 'pages/complementar/banda-preco/components';

export function BandaPrecoListComp(): JSX.Element {
    return (
        <OneColumn title="Bandas de Preços" createButton>
            <BandaPrecoGrid />
        </OneColumn>
    );
}
