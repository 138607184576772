import { useContext, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(when = true, setModalVisible: Dispatch<SetStateAction<boolean>>): () => void {
    const { navigator: navigationContext } = useContext(NavigationContext);
    const navigator: any = navigationContext;
    const confirmNavigation = useRef<() => void>(() => {
        return;
    });

    useEffect(() => {
        if (!when) return;

        if (typeof navigator?.block === 'function') {
            const unblock = navigator?.block((tx: any) => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };

                confirmNavigation.current = () => {
                    autoUnblockingTx.retry();
                    setModalVisible(false);
                };
                setModalVisible(true);
            });

            return unblock;
        }
    }, [navigator, when]);

    return confirmNavigation.current;
}
