import React from 'react';

import { useNavigate } from 'react-router-dom';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { IconButton, Tooltip } from '@mui/material';

type RevendedorEditButton = {
    documento: string;
};

export function RevendedorEditButton({ documento }: RevendedorEditButton): JSX.Element {
    const navigate = useNavigate();

    return (
        <Tooltip title="Editar">
            <IconButton sx={{ p: 0.5 }} onClick={() => navigate(`/revendedor/form/${documento}`)}>
                <ModeEditOutlineOutlinedIcon />
            </IconButton>
        </Tooltip>
    );
}
