import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function FormFilterCentroDistribuicao(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterIdCentroDistribuicao, setFilterIdCentroDistribuicao] = useState<FilterValuesProps>({
        property: 'idCentroDistribuicao',
        operator: 'like',
        value: null,
        idProperty: 'idCentroDistribuicao',
    });
    const [filterDsCentroDistribuicao, setFilterDsCentroDistribuicao] = useState<FilterValuesProps>({
        property: 'dsCentroDistribuicao',
        operator: 'like',
        value: null,
        idProperty: 'dsCentroDistribuicao',
    });
    const [filterCdCentroDistribuicao, setFilterCdCentroDistribuicao] = useState<FilterValuesProps>({
        property: 'cdCentroDistribuicao',
        operator: 'like',
        value: null,
        idProperty: 'cdCentroDistribuicao',
    });

    const [fgAtivo, setFgAtivo] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'fgAtivo',
    });

    const filters = [
        { value: filterIdCentroDistribuicao, setter: setFilterIdCentroDistribuicao },
        { value: filterDsCentroDistribuicao, setter: setFilterDsCentroDistribuicao },
        { value: filterCdCentroDistribuicao, setter: setFilterCdCentroDistribuicao },
        { value: fgAtivo, setter: setFgAtivo },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <CissTextField
                    label="Código"
                    value={filterIdCentroDistribuicao.value}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => setFilterIdCentroDistribuicao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Nome do centro"
                    value={filterDsCentroDistribuicao.value}
                    onChange={(e) => setFilterDsCentroDistribuicao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>

            <Grid item flex={4}>
                <CissTextField
                    label="Código de Integração"
                    value={filterCdCentroDistribuicao.value}
                    onChange={(e) => setFilterCdCentroDistribuicao((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                />
            </Grid>
        </Filter>
    );
}
