import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { Configuration, DataHostConfigProps, useHostConfig } from 'hooks/useHostConfig';

interface DomainConfigContextData {
    subdomain: string;
    data: DataHostConfigProps;
    configuration: Configuration;
}

interface DomainConfigProviderProps {
    children?: ReactNode;
}

const defaultConfiguration: Configuration = {
    tipoPedido: 'padrao',
    tipoAuth: 'cissLiveAuth',
    fgAlteraEmpresa: true,
    fgExibeHelpDesk: true,
    fgDownloadRelatorioReport: true,
};

const defaultSubDomain = 'localhost';
const DomainConfigContext = createContext<DomainConfigContextData>({} as DomainConfigContextData);

export function DomainConfigProvider({ children }: DomainConfigProviderProps): JSX.Element {
    const subdomain = window.location.hostname.split('.').find((part) => !part.startsWith('www')) as string;
    const { data, defaultDomain } = useHostConfig({ subdomain, defaultSubDomain });
    const ready = Boolean(typeof data === 'object' && data !== null && Object.keys(data).length);

    const contextValue = useMemo(
        () => ({
            subdomain: defaultDomain ? defaultSubDomain : subdomain,
            configuration: { ...defaultConfiguration, ...data?.configuration },
            data,
        }),
        [defaultDomain, subdomain, data],
    );

    return (
        <>
            {ready && <DomainConfigContext.Provider value={contextValue}>{children}</DomainConfigContext.Provider>}

            {!ready && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </>
    );
}

export const useDomainConfigContext = (): DomainConfigContextData => useContext(DomainConfigContext);
