import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Box, Typography } from '@mui/material';

import { ReportBtn } from 'components/buttons';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';

export function RevendedorReport(): JSX.Element {
    const { filter } = useListagemContext();

    return (
        <Block>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alinItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TableViewOutlinedIcon color="primary" />

                    <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIO REFERENTE AOS FILTROS INFORMADOS</Typography>

                    <ChevronRightOutlinedIcon color="primary" />
                </Box>

                <ReportBtn
                    disabled={!filter.length}
                    requestOptions={{
                        url: '/integracao-revendedor/cadastrorevendedor/exportar',
                        sort: [{ property: 'codigo', direction: 'ASC' }],
                        filter,
                    }}
                />
            </Box>
        </Block>
    );
}
