import React, { ChangeEvent, LegacyRef, useState } from 'react';

import { AttachFile } from '@mui/icons-material';
import { Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { useMimeTypes } from 'hooks/useMimeTypes';
import { brlList } from 'util/format';

type UploadButton = {
    label?: string;
    disabled?: boolean;
    onChange?: (file: File | null) => void;
    onChangeMultiple?: (file: File[]) => void;
    accept?: string | string[];
    inputRef?: LegacyRef<HTMLInputElement> | null;
    multiple?: boolean;
    sizeLimit?: number;
};

const validateSizeLimit = (file: File, sizeLimit: number): boolean => {
    const fileSize = Number((file.size / (1024 * 1024)).toFixed(2));

    return fileSize <= sizeLimit;
};

export function UploadButton({
    label,
    disabled,
    onChange,
    onChangeMultiple,
    accept = '*',
    sizeLimit,
    inputRef,
    multiple = false,
}: UploadButton): JSX.Element {
    const [hasSelectedFile, setHasSelectedFile] = useState<boolean>(false);

    const showSizeLimitError = (invalidSizeFiles: string[]): void => {
        let message = '';

        if (invalidSizeFiles.length) {
            if (invalidSizeFiles.length === 1) {
                message = `Arquivo ${invalidSizeFiles[0]} ultrapassa o limite de ${sizeLimit}MB`;
            } else {
                message = `Arquivos ${brlList(invalidSizeFiles)} ultrapassam o limite de ${sizeLimit}MB`;
            }
        }

        if (message) {
            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const handleUpload = (e: ChangeEvent<HTMLInputElement>): void => {
        const files = e?.target?.files;

        if (files && files?.length) {
            if (multiple && typeof onChangeMultiple === 'function') {
                const validFiles: File[] = [],
                    invalidSizeFiles: string[] = [];

                Array.from(files).forEach((file) => {
                    if (!sizeLimit || (sizeLimit && validateSizeLimit(file, sizeLimit))) {
                        validFiles.push(file);
                    } else {
                        invalidSizeFiles.push(file.name);
                    }
                });

                showSizeLimitError(invalidSizeFiles);

                onChangeMultiple(validFiles);
            } else if (typeof onChange === 'function') {
                onChange(files[0]);
            }
        }

        setHasSelectedFile(Boolean(files?.length));
    };

    return (
        <Button variant="contained" component="label" color="inherit" disabled={disabled}>
            <AttachFile sx={{ transform: 'rotate(45deg)', mr: 1 }} />
            <input
                type="file"
                onChange={handleUpload}
                hidden
                multiple={multiple}
                ref={inputRef}
                accept={Array.isArray(accept) ? accept.join(',') : accept}
            />
            {label ?? `${hasSelectedFile ? 'ALTERAR' : 'ANEXAR'} ARQUIVO`}
        </Button>
    );
}
