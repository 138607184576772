export interface RequestOperacaoProps {
    idOperacao: number | null;
    dsOperacao: string;
    cdOperacao: string;
    fgAtivo: boolean;
}

export const initialOperacaoValues: RequestOperacaoProps = {
    idOperacao: null,
    dsOperacao: '',
    cdOperacao: '',
    fgAtivo: true,
};
