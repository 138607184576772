import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface LocalFuncionario {
    idLocalFuncionario: number;
    dsLocalFuncionario: string;
    dsTipoEntrega: string;
}

type LocalFuncionarioAutoComplete = Pick<
    CissAutoCompleteProps,
    | 'value'
    | 'error'
    | 'multiple'
    | 'disabled'
    | 'sx'
    | 'onChange'
    | 'name'
    | 'optionStringTemplate'
    | 'selectedStringTemplate'
    | 'requestOptions'
    | 'label'
    | 'size'
    | 'disableClearable'
>;

export function LocalFuncionarioAutoComplete({
    sx,
    size,
    name,
    value,
    error,
    onChange,
    multiple = false,
    disableClearable = false,
    label = 'Local do Funcionário',
    selectedStringTemplate = '{idLocalFuncionario} - {dsLocalFuncionario}',
    optionStringTemplate = '<strong>{idLocalFuncionario}</strong> - {dsLocalFuncionario}',
    requestOptions = {
        url: '/gestao/localfuncionario/',
        columns: 'idLocalFuncionario,dsLocalFuncionario,cdLocalFuncionario',
        sort: [{ property: 'idLocalFuncionario', direction: 'ASC' }],
    },
}: LocalFuncionarioAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            name={name}
            size={size}
            label={label}
            value={value}
            error={error}
            onChange={onChange}
            multiple={multiple}
            requestOptions={requestOptions}
            disableClearable={disableClearable}
            optionStringTemplate={optionStringTemplate}
            selectedStringTemplate={selectedStringTemplate}
            isOptionEqualToValue={(option: LocalFuncionario, value: LocalFuncionario) => option.idLocalFuncionario === value.idLocalFuncionario}
        />
    );
}
