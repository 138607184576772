import React, { useCallback, useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Checkbox, FormControlLabel, Tooltip, SxProps } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { CissNumberField, CissTextField } from 'components/fields';
import { ConfirmModal } from 'components/modal';

interface DreFormFieldProps {
    fieldLabel?: string;
    fieldType?: 'text' | 'number';
    fieldSx?: SxProps;
    fieldDisabled?: boolean;
    fieldValue?: string | number;
    fieldChange?: (newValue: string) => void;
    sx?: SxProps;
    checkboxLabel?: string;
    checkboxValue?: boolean;
    checkboxChange?: (checked: boolean) => void;
    checkboxDisabled?: boolean;
    tooltip?: string;
    modalTooltip?: {
        titulo: string;
        descricao: string;
    };
    tabIndex?: number;
    onBlur?: () => void;
    name?: string;
    error?: string;
    allowNegative?: boolean;
}

export function DreFormField({
    fieldLabel,
    fieldType = 'number',
    fieldSx,
    fieldDisabled,
    fieldValue,
    fieldChange,
    sx,
    checkboxLabel,
    checkboxValue,
    checkboxChange,
    checkboxDisabled,
    tooltip,
    modalTooltip,
    tabIndex,
    onBlur = () => {},
    name,
    error,
    allowNegative,
}: DreFormFieldProps): JSX.Element {
    const [actualValue, setActualValue] = useState('');
    const [checked, setChecked] = useState(checkboxValue);
    const [modalTooltipVisible, setModalTooltipVisible] = useState<boolean>(false);

    const updateValues = useCallback(
        (newValue: string): void => {
            if (actualValue !== newValue) {
                setActualValue(newValue);

                if (typeof fieldChange === 'function') {
                    fieldChange(newValue);
                }
            }
        },
        [actualValue, fieldChange],
    );

    const handleFieldBlur = (newValue: string): void => {
        updateValues(newValue);
        onBlur();
    };

    const handleCheckboxChange = useCallback(
        (checked: boolean): void => {
            setChecked(checked);

            if (typeof checkboxChange === 'function') {
                checkboxChange(checked);
            }
        },
        [checkboxChange],
    );

    return (
        <Grid
            item
            sx={deepmerge(
                {
                    display: 'flex',
                    flex: 1,
                    maxWidth: { md: '50%', xs: '100%' },
                },
                sx,
            )}
        >
            {fieldType === 'number' && fieldLabel && (
                <CissNumberField
                    name={name}
                    error={error}
                    label={fieldLabel}
                    onFocus={(e) => setActualValue(e.target.value)}
                    onChange={(e) => updateValues(e.target.value)}
                    onBlur={(e) => handleFieldBlur(e.target.value)}
                    sx={fieldSx}
                    disabled={fieldDisabled}
                    value={fieldValue}
                    inputType="decimal"
                    tabIndex={tabIndex}
                    minValue={allowNegative ? -Infinity : 0}
                    allowNegative={allowNegative}
                />
            )}

            {fieldType === 'text' && fieldLabel && (
                <CissTextField
                    name={name}
                    error={error}
                    label={fieldLabel}
                    onFocus={(e) => setActualValue(e.target.value)}
                    onChange={(e) => updateValues(e.target.value)}
                    onBlur={(e) => handleFieldBlur(e.target.value)}
                    sx={fieldSx}
                    disabled={fieldDisabled}
                    value={fieldValue}
                    tabIndex={tabIndex}
                />
            )}

            {tooltip && (
                <Tooltip
                    title={tooltip}
                    onClick={() => {
                        if (modalTooltip) {
                            setModalTooltipVisible(true);
                        }
                    }}
                    style={{ cursor: modalTooltip ? 'pointer' : 'default' }}
                >
                    <InfoOutlinedIcon sx={{ ml: 1, mt: 2 }} />
                </Tooltip>
            )}

            {modalTooltip && (
                <ConfirmModal
                    title={modalTooltip.titulo}
                    text={modalTooltip.descricao}
                    open={modalTooltipVisible}
                    setOpen={setModalTooltipVisible}
                    showCancel={false}
                    confirmText="Entendi"
                    confirm={() => setModalTooltipVisible(false)}
                />
            )}

            {typeof checkboxValue === 'boolean' && (
                <FormControlLabel
                    name={name}
                    control={<Checkbox />}
                    checked={checked}
                    disabled={checkboxDisabled}
                    label={checkboxLabel ?? 'Recorrente'}
                    onChange={(e, checked) => handleCheckboxChange(checked)}
                    sx={{
                        ml: fieldLabel ? 1 : 0,
                        mr: 0,
                    }}
                />
            )}
        </Grid>
    );
}
