import React from 'react';

import { useParams } from 'react-router-dom';

import { TagListComp, TagFormComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function TagListComunicado(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['COMUNICADO_MODERAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <TagListComp />
        </ListagemProvider>
    );
}

export function TagFormComunicado(): JSX.Element {
    const { id } = useParams();
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['COMUNICADO_MODERAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <TagFormComp />
        </FormProvider>
    );
}
