import React, { useCallback } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import * as dateFns from 'date-fns';

import { MensagensProps } from '../../types';

import { AzureUploadFileProps } from 'components/azureUpload/types';
import { DownloadBox } from 'components/content';
import { Block } from 'components/page';
import { useGenericAuth } from 'hooks/useGenericAuth';

interface SolicitacaoPecaMensagensMensagemProps {
    mensagens?: MensagensProps[];
}

export function TradeSolicitacaoPecaMensagensMensagem({ mensagens = [] }: SolicitacaoPecaMensagensMensagemProps): JSX.Element {
    const { tokenInfo } = useGenericAuth();

    const sortAscMensagens = useCallback((a: MensagensProps, b: MensagensProps): number => {
        if (a.idSolicitacaoPecaMensagem < b.idSolicitacaoPecaMensagem) {
            return -1;
        }

        if (a.idSolicitacaoPecaMensagem > b.idSolicitacaoPecaMensagem) {
            return 1;
        }

        return 0;
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: -2,
                mb: 3,
            }}
        >
            {mensagens.sort(sortAscMensagens).map((mensagem) => {
                const myChannelMessage = mensagem.usuarioInclusao?.idUsuario === tokenInfo?.idUsuario; // mensagens enviadas pelo meu lado, seja ele operador ou franqueado devem ficar alinhadas na direita

                return (
                    <Block
                        key={mensagem.idSolicitacaoPecaMensagem}
                        sx={{
                            'width': '60%',
                            'mt': 3,
                            'mb': 0,
                            'py': 1,
                            'ml': myChannelMessage ? 'auto' : '30px',
                            'mr': myChannelMessage ? '30px' : 'auto',
                            'borderTopLeftRadius': myChannelMessage ? 0 : 2,
                            'borderTopRightRadius': myChannelMessage ? 2 : 0,
                            'position': 'relative',
                            '&:before, &:after': {
                                content: '""',
                                width: 0,
                                height: 0,
                                top: 0,
                                left: myChannelMessage ? 'auto' : '-30px',
                                right: myChannelMessage ? '-30px' : 'auto',
                                position: 'absolute',
                                borderWidth: '10px 15px',
                                borderStyle: 'solid',
                            },
                            '&:before': {
                                borderColor: myChannelMessage ? 'white transparent transparent white' : 'white white transparent transparent',
                                borderRadius: 0,
                                zIndex: 2,
                            },
                            '&:after': {
                                borderColor: myChannelMessage ? 'black transparent transparent black' : 'black black transparent transparent',
                                borderRadius: 0,
                                zIndex: 1,
                            },
                        }}
                    >
                        <Typography
                            variant="button"
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Typography
                                component="strong"
                                noWrap
                                sx={{
                                    color: `${myChannelMessage ? 'primary' : 'info'}.main`,
                                }}
                            >
                                {mensagem.usuarioInclusao?.dsUsuario}
                            </Typography>

                            {mensagem.dhInclusao && <Typography component="span">{dateFns.format(new Date(mensagem.dhInclusao), 'dd/MM/yyyy, HH:mm')}</Typography>}
                        </Typography>

                        {mensagem.dsMensagem && (
                            <Typography
                                sx={{
                                    'py': 0.5,
                                    '& > p:first-of-type, > h1:first-of-type, > h2:first-of-type, > h3:first-of-type, > h4:first-of-type, > h5:first-of-type, > h6:first-of-type': {
                                        marginTop: 0,
                                    },
                                    '& > p:last-of-type, > h1:last-of-type, > h2:last-of-type, > h3:last-of-type, > h4:last-of-type, > h5:last-of-type, > h6:last-of-type': {
                                        marginBottom: 0,
                                    },
                                }}
                                dangerouslySetInnerHTML={{ __html: mensagem.dsMensagem }}
                            />
                        )}

                        {Boolean(mensagem.arquivos.length) && (
                            <React.Fragment>
                                <Divider sx={{ my: 2 }} />

                                <Typography sx={{ my: 1 }}>
                                    <strong>Anexos:</strong>
                                </Typography>

                                {mensagem.arquivos.map((upload: AzureUploadFileProps) => (
                                    <DownloadBox
                                        key={`item_mensagem_${upload.arquivo.idArquivo}`}
                                        label={upload.arquivo.dsTitulo}
                                        image={upload.arquivo.dsLinkThumbnail}
                                        extensao={upload.arquivo.dsExtensao}
                                        link={upload.arquivo.dsLink}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </Block>
                );
            })}
        </Box>
    );
}
