import React from 'react';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { PredefinicaoEstiloFormComp } from 'pages/predefinicoes-estilo/components/pages/PredefinicaoEstiloForm';
import { PredefinicoesEstiloListComp } from 'pages/predefinicoes-estilo/components/pages/PredefinicaoEstiloList';

export function PredefinicoesEstiloList(): JSX.Element {
    return (
        <ListagemProvider>
            <PredefinicoesEstiloListComp />
        </ListagemProvider>
    );
}

export function PredefinicaoEstiloForm(): JSX.Element {
    return (
        <FormProvider>
            <PredefinicaoEstiloFormComp />
        </FormProvider>
    );
}
