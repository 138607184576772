import React, { useState } from 'react';

import { OneColumn } from 'components/page';
import { PercentualNotaFiscalGrid, PercentualNotaFiscalImportarModal, PercentualNotaFiscalExcluirModal, PercentualNotaFiscalGridFilter } from 'pages/compra/percentual-nota-fiscal/components';

export function PercentualNotaFiscalListComp(): JSX.Element {
    const [openModalImportar, setOpenModalImportar] = useState(false);
    const [openModalExcluir, setOpenModalExcluir] = useState(false);

    return (
        <React.Fragment>
            <PercentualNotaFiscalImportarModal open={openModalImportar} setOpen={setOpenModalImportar} />

            <PercentualNotaFiscalExcluirModal open={openModalExcluir} setOpen={setOpenModalExcluir} />

            <OneColumn
                title="Percentual de Nota Fiscal"
                extraButtons={[
                    {
                        title: 'Importar',
                        onClick: () => setOpenModalImportar(true),
                    },
                    {
                        title: 'Excluir',
                        onClick: () => setOpenModalExcluir(true),
                    },
                ]}
            >
                <PercentualNotaFiscalGridFilter />

                <PercentualNotaFiscalGrid />
            </OneColumn>
        </React.Fragment>
    );
}
