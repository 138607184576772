import React, { useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';

import { ConfirmModal } from 'components/modal';

interface RemoveBtnProps {
    onClick: () => void;
}

export function ConfiguracaoPedidoCompraRemoveBtn({ onClick = () => {} }: RemoveBtnProps): JSX.Element {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    return (
        <React.Fragment>
            <Box>
                <Tooltip title="Remover">
                    <IconButton sx={{ p: 0, ml: 0 }} onClick={() => setOpenConfirmModal(true)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={() => onClick()} />
        </React.Fragment>
    );
}
