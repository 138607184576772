import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function DadosEntregaListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterEstado, setFilterEstado] = useState<FilterValuesProps>({
        property: 'idEstado',
        operator: 'in',
        value: null,
        idProperty: 'idEstado',
    });

    const filters = [{ value: filterEstado, setter: setFilterEstado }];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={4}>
                <CissAutoComplete
                    label="Estado"
                    value={filterEstado.value || null}
                    multiple={false}
                    requestOptions={{
                        url: '/gestao/estado',
                        sort: [{ property: 'sgEstado', direction: 'ASC' }],
                        columns: 'idEstado,dsEstado,sgEstado',
                    }}
                    isOptionEqualToValue={(option, value) => option.idEstado === value.idEstado}
                    optionStringTemplate="<strong>{idEstado}</strong> - {dsEstado}"
                    selectedStringTemplate="{dsEstado}"
                    onChange={(e, value) => setFilterEstado((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>
        </Filter>
    );
}
