import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useGetPromocao } from 'pages/revendedor/promocao/hooks';
import { Promocao } from 'pages/revendedor/promocao/types';

const getRows = (data: Promocao[] | undefined): GridRowsProp => {
    const list = data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => ({
            id: item.idPromocoes,
            codigo: item.codigo,
            nome: item.nome,
        }));
    }
    return [];
};

export function PromocaoGrid(): JSX.Element {
    const { data, refetch, isFetching } = useGetPromocao();

    const columns: GridColDef[] = [
        { field: 'codigo', headerName: 'Código', flex: 1, sortable: false },
        { field: 'nome', headerName: 'Nome', flex: 1, sortable: false },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            renderCell: ({ row }) => (
                <EditRemoveBtn
                    id={row.idMensagemPadrao}
                    showEditBtn={false}
                    removeUrl={`/integracao-revendedor/promocoes/${row.id}`}
                    refresh={refetch}
                />
            ),
        },
    ];

    return (
        <Block>
            <PanelGrid
                rows={getRows(data?.data)}
                rowsCount={data?.total ?? 0}
                reload={refetch}
                loading={isFetching}
                columns={columns}
                visibleRows={10}
            />
        </Block>
    );
}
