import React from 'react';

import { Divider, Typography } from '@mui/material';

import { Block } from 'components/page';
import { ItensProps } from '../../types';
import { SubTitle } from 'components/text';
import { DownloadBox } from 'components/content';
import { UploadFileProps } from 'components/upload/types';
import { TabuledGrid } from 'components/grid';
import { AzureUploadFileProps } from 'components/azureUpload/types';

interface SolicitacaoPecaItemsMensagemProps {
    itens?: ItensProps[];
}

export function TradeSolicitacaoPecaItemsMensagem({ itens }: SolicitacaoPecaItemsMensagemProps): JSX.Element {
    return (
        <React.Fragment>
            {(itens || []).map((item, index) => {
                return (
                    <Block key={item.idSolicitacaoPecaItem}>
                        <SubTitle label={`Dados Peça ${`0${index + 1}`.slice(-2)}`} />

                        <TabuledGrid
                            showEmpty={false}
                            list={[
                                {
                                    label: <strong>Tipo da Peça:</strong>,
                                    value: item.dsTipoPeca,
                                },
                                {
                                    label: <strong>Local de veiculação:</strong>,
                                    value: item.dsLocalVeiculacao,
                                },
                                {
                                    label: <strong>Unidade de Medida:</strong>,
                                    value: item.unidadeMedidaPeca?.dsUnidadeMedidaPeca,
                                },
                                {
                                    label: <strong>Largura:</strong>,
                                    value: item.vlLargura,
                                },
                                {
                                    label: <strong>Altura:</strong>,
                                    value: item.vlAltura,
                                },
                                {
                                    label: <strong>Endereço da Loja:</strong>,
                                    value: item.dsEnderecoLoja,
                                },
                                {
                                    label: <strong>Telefone da Loja:</strong>,
                                    value: item.dsTelefoneLoja,
                                },
                                {
                                    label: <strong>Observação:</strong>,
                                    value: item.dsObservacao,
                                },
                            ]}
                        />

                        {Boolean(item.arquivos.length) && (
                            <React.Fragment>
                                <Divider sx={{ my: 2 }} />

                                <Typography sx={{ my: 1 }}>
                                    <strong>Anexos:</strong>
                                </Typography>

                                {item.arquivos.map((upload: AzureUploadFileProps) => (
                                    <DownloadBox
                                        key={`item_solicitacao_${upload.arquivo.idArquivo}`}
                                        label={upload.arquivo.dsTitulo}
                                        image={upload.arquivo.dsLinkThumbnail}
                                        extensao={upload.arquivo.dsExtensao}
                                        link={upload.arquivo.dsLink}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </Block>
                );
            })}
        </React.Fragment>
    );
}
