import React from 'react';
import { PreenchimentoListComp } from 'pages/financeiro/dre/preenchimento/components/pages/PreenchimentoList';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function PreenchimentoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DRE_PREENCHIMENTO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <PreenchimentoListComp />
        </ListagemProvider>
    );
}
