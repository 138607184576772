import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { EstatisticasExportarRelatorioBtn } from 'pages/estatisticas/components/atoms/EstatisticasExportarRelatorioBtn';
import { EstatisticasPanelItemDetail } from 'pages/estatisticas/components/templates/EstatisticasPanelItemDetail';
import { FilterEstatisticas } from 'pages/estatisticas/components/templates/FilterEstatisticas';
import { DocumentoResponse } from 'pages/estatisticas/types';

export function EstatisticasListComp(): JSX.Element {
    const { filter, page, limit } = useListagemContext();
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/documento',
        sort: [{ property: 'idDocumento', direction: 'DESC' }],
        filter,
        page,
        limit,
    };

    const { data, fetchStatus, refetch } = useQuery([requestOptions], (): Promise<DocumentoResponse> => requestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(filter.length),
    });

    const makeRows = (): GridRowsProp => {
        if (!data) return [];

        return data.data.map((documento) => ({
            id: documento.idDocumento,
            idDocumento: documento.idDocumento,
            dsTipoDocumento: documento.dsTipoDocumento,
            dsTitulo: documento.dsTitulo,
            qtCurtida: documento.qtCurtida ?? 0,
            qtDownload: documento.qtDownload ?? 0,
            qtVisualizacao: documento.qtVisualizacao ?? 0,
            dhUltimaVisualizacao: documento.dhUltimaVisualizacao ? format(new Date(documento.dhUltimaVisualizacao), 'dd/MM/yyyy HH:mm:ss') : '',
            dhUltimoDownload: documento.dhUltimoDownload ? format(new Date(documento.dhUltimoDownload), 'dd/MM/yyyy HH:mm:ss') : '',
            dhUltimaCurtida: documento.dhUltimaCurtida ? format(new Date(documento.dhUltimaCurtida), 'dd/MM/yyyy HH:mm:ss') : '',
        }));
    };

    const rows = makeRows();

    return (
        <OneColumn title="Estatísticas">
            <FilterEstatisticas />

            <Block>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box sx={{ flex: 1, display: 'inherit', alignItems: 'inherit' }}>
                        <TableViewOutlinedIcon color="primary" />

                        <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIO REFERENTE AO PERÍODO SELECIONADO</Typography>

                        <ChevronRightOutlinedIcon color="primary" />
                    </Box>

                    <EstatisticasExportarRelatorioBtn disabled={!rows.length}  />
                </Stack>
            </Block>

            <Block>
                <PanelGrid
                    rows={rows}
                    rowsCount={data?.total ?? 0}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={[
                        { field: 'dsTipoDocumento', headerName: 'Tipo de documento', width: 160 },
                        { field: 'dsTitulo', headerName: 'Título', flex: 1 },
                        { field: 'qtCurtida', headerName: 'Curtidas', width: 100, align: 'center', headerAlign: 'center' },
                        { field: 'dhUltimaCurtida', headerName: 'Última Curtida', width: 180, align: 'center', headerAlign: 'center' },
                        { field: 'qtDownload', headerName: 'Downloads', width: 100, align: 'center', headerAlign: 'center' },
                        { field: 'dhUltimoDownload', headerName: 'Último Download', width: 180, align: 'center', headerAlign: 'center' },
                        { field: 'qtVisualizacao', headerName: 'Visualizações', width: 100, align: 'center', headerAlign: 'center' },
                        { field: 'dhUltimaVisualizacao', headerName: 'Última visualização', width: 180, align: 'center', headerAlign: 'center' },
                    ]}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => <EstatisticasPanelItemDetail details={row} />}
                    isTreeData
                />
            </Block>
        </OneColumn>
    );
}
