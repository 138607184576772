import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestProdutoPedidoProps } from 'pages/compra/produto/types';
import { brlDate } from 'util/format';

interface ProdutoPedidoGridProps {
    idProduto: number;
    cdDeposito: string;
    cdCentroDistribuicao: string;
}

export function ProdutoPedidoGrid({ idProduto, cdDeposito, cdCentroDistribuicao }: ProdutoPedidoGridProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { page, sort, limit } = useListagemContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: `/compra/pedidocompra/produto/${idProduto}`,
            sort: sort.length ? sort : [{ property: 'nrPedido', direction: 'ASC' }],
            filter: [
                { property: 'cdDeposito', value: cdDeposito, operator: 'eq' },
                { property: 'cdCentroDistribuicao', value: cdCentroDistribuicao, operator: 'eq' },
            ],
            page,
            limit,
        }),
        [cdCentroDistribuicao, cdDeposito, idProduto, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        (): Promise<RequestProdutoPedidoProps> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: Boolean(idProduto),
        },
    );

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'dhInclusao', headerName: 'Data', width: 120, minWidth: 90 },
            { field: 'dsConfigPedido', headerName: 'Configuração de Pedido', flex: 1, minWidth: 120 },
            { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 130 },
            { field: 'dsPedidoStatus', headerName: 'Situação', flex: 1, width: 200, minWidth: 150 },
            { field: 'nrPedido', headerName: 'Código do Pedido', width: 100, minWidth: 90 },
            { field: 'qtProduto', headerName: 'Quantidade', width: 100, minWidth: 90 },
        ],
        [],
    );

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(data?.data) && data?.data.length) {
            return data?.data.map((item) => ({
                id: item.nrPedido,
                dhInclusao: item.dhInclusao && brlDate(item.dhInclusao),
                dsConfigPedido: item.dsConfigPedido,
                dsNomeFantasia: item.dsNomeFantasia,
                dsPedidoStatus: item.dsStatus,
                nrPedido: item.nrPedido,
                qtProduto: item.qtProduto,
            }));
        }

        return [];
    }, [data]);

    return (
        <Box sx={{ p: 2 }}>
            <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
        </Box>
    );
}
