import React, { ReactNode, useCallback, useMemo } from 'react';

import { Box, Tooltip } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DreReportType } from 'pages/financeiro/dre/relatorio/components/atoms/DreExportarRelatorioBtn';
import { DrePainelExportacao } from 'pages/financeiro/dre/relatorio/components/templates/DrePainelExportacao';
import { DreRelatorioGridDataProps } from 'pages/financeiro/dre/relatorio/types';
import { brlPrice, brlPercentual } from 'util/format';

interface DreRelatorioGridAnaliticoProps {
    idLoja: string | string[];
    nrAno: string;
    categoria: string;
}

export function DreRelatorioGridAnalitico({ idLoja, nrAno, categoria }: DreRelatorioGridAnaliticoProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();
    const { isDownLg: isMobile } = useBreakpoints();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/dre/dremercadologica/extrato/analitico',
            columns: 'idDreMercadologica',
            nested: true,
            filter: [
                { property: 'idLoja', operator: Array.isArray(idLoja) ? 'in' : 'eq', value: idLoja },
                { property: 'nrAno', operator: 'eq', value: nrAno },
            ],
        }),
        [idLoja, nrAno],
    );

    const { fetchStatus, refetch, data } = useQuery([requestOptions], (): Promise<DreRelatorioGridDataProps[]> => RequestListagem(requestOptions).then((res) => res.data.data));

    const renderCellValue = (value: number, fgPercentual: boolean): string => {
        if (!value) {
            return '--';
        }

        return fgPercentual ? brlPercentual(value) : brlPrice(value);
    };

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'title', headerName: 'Conta', width: 150, renderCell: ({ row }) => <span>{row.title}</span> },
            { field: 'janeiro', headerName: 'Janeiro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.janeiro, row.fgPercentual) },
            { field: 'fevereiro', headerName: 'Fevereiro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.fevereiro, row.fgPercentual) },
            { field: 'marco', headerName: 'Março', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.marco, row.fgPercentual) },
            { field: 'abril', headerName: 'Abril', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.abril, row.fgPercentual) },
            { field: 'maio', headerName: 'Maio', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.maio, row.fgPercentual) },
            { field: 'junho', headerName: 'Junho', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.junho, row.fgPercentual) },
            { field: 'julho', headerName: 'Julho', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.julho, row.fgPercentual) },
            { field: 'agosto', headerName: 'Agosto', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.agosto, row.fgPercentual) },
            { field: 'setembro', headerName: 'Setembro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.setembro, row.fgPercentual) },
            { field: 'outubro', headerName: 'Outubro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.outubro, row.fgPercentual) },
            { field: 'novembro', headerName: 'Novembro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.novembro, row.fgPercentual) },
            { field: 'dezembro', headerName: 'Dezembro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.dezembro, row.fgPercentual) },
            { field: 'acumulado', headerName: 'Acumulado', flex: 1, type: 'number', minWidth: 120, renderCell: ({ row }) => renderCellValue(row.acumulado, row.fgPercentual) },
        ],
        [],
    );

    const generateTitle = useCallback(({ dsExtrato, nrNivel }: DreRelatorioGridDataProps): ReactNode | string => {
        switch (nrNivel) {
            case 2:
                return (
                    <Tooltip title={dsExtrato}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>● {dsExtrato}</Box>
                    </Tooltip>
                );
            case 3:
                return (
                    <Tooltip title={dsExtrato}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>○ {dsExtrato}</Box>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title={dsExtrato}>
                        <strong>{dsExtrato}</strong>
                    </Tooltip>
                );
        }
    }, []);

    const getItemByCategoria = useCallback(
        (data: DreRelatorioGridDataProps[] | undefined): DreRelatorioGridDataProps[] | undefined => {
            // como são os mesmos itens entre sintetico e analitico (endpoint e tudo)
            // quando for sintetico somente mostramos os niveis 1 e 2
            if (categoria === 'sintetico' && data) {
                return data.filter((item) => item.nrNivel < 3);
            }

            return data;
        },
        [categoria],
    );

    const generateList = useCallback(
        (data: DreRelatorioGridDataProps[] | undefined): GridRowsProp => {
            const items = getItemByCategoria(data);

            if (Array.isArray(items) && items.length) {
                return items.map((item, index) => ({
                    id: index + 1,
                    dsExtrato: item.dsExtrato,
                    title: generateTitle(item),
                    janeiro: item.vlExtrato.janeiro,
                    fevereiro: item.vlExtrato.fevereiro,
                    marco: item.vlExtrato.março,
                    abril: item.vlExtrato.abril,
                    maio: item.vlExtrato.maio,
                    junho: item.vlExtrato.junho,
                    julho: item.vlExtrato.julho,
                    agosto: item.vlExtrato.agosto,
                    setembro: item.vlExtrato.setembro,
                    outubro: item.vlExtrato.outubro,
                    novembro: item.vlExtrato.novembro,
                    dezembro: item.vlExtrato.dezembro,
                    acumulado: item.vlAcumulado,
                    fgPercentual: item.fgPercentual,
                }));
            }

            return [];
        },
        [generateTitle, getItemByCategoria],
    );

    const rows = generateList(data);

    return (
        <Block>
            <SubTitle label="Analítico do Período" />

            {hasPermission(['FINANCEIRO_DRE_RELATORIO_EXPORTAR']) && (
                <DrePainelExportacao
                    reportType={categoria as DreReportType}
                    disableBtn={!rows.length}
                    filter={[
                        { property: 'idLoja', operator: 'eq', value: idLoja },
                        { property: 'nrAno', operator: 'eq', value: nrAno },
                    ]}
                />
            )}

            <PanelGrid
                rows={rows}
                rowsCount={rows.length}
                reload={refetch}
                loading={fetchStatus === 'fetching'}
                columns={columns}
                remote={false}
                visibleRows="all"
                columnBorder
                pinnedColumns={
                    isMobile
                        ? {}
                        : {
                              left: ['title'],
                              right: ['acumulado'],
                          }
                }
            />
        </Block>
    );
}
