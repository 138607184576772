import React, { useCallback, useMemo, useState } from 'react';

import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import { Box, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import { CissTextField } from 'components/fields/';
import { FilterValuesProps } from 'hooks/useFilter';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface MarketingCampanhaOptionsProps {
    idCampanhaMarketing: number;
    dsCampanhaMarketing: string;
    dsCampanhaMarketingPai: string;
    subCampanhasMarketing?: MarketingCampanhaOptionsProps[];
    campanhaMarketingPai?: {
        idCampanhaMarketing: number;
    };
}

interface MarketingCampanhaFieldFormikProps extends AutocompleteProps<MarketingCampanhaOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
    filter?: FilterValuesProps[];
    limitViewLevelsByCampanha: string | boolean;
}

interface RequestMarketingCampanha extends RequestListagemProps {
    data: MarketingCampanhaOptionsProps[];
}

export function MarketingCampanhaFieldFormik({
    onChange,
    multiple = false,
    limitViewLevelsByCampanha = false,
    label = 'Campanha Pai',
    filter = [],
    ...PropsFormik
}: MarketingCampanhaFieldFormikProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [FieldInputProps, { error }] = useField(PropsFormik.field.name);
    const [inputValue, setInputValue] = useState<string>('');
    const [queryValue] = useDebounce(inputValue, 500);
    const { tokenInfo } = useGenericAuth();

    const getFiltersRequestOptions = useMemo(() => {
        const filtersRequestOptions: FilterValuesProps[] = [{ property: 'dsCampanhaMarketing', value: queryValue, operator: 'like' }, ...filter];

        if (tokenInfo?.tpUsuario !== 'SUPORTE_CISS') {
            filtersRequestOptions.push({ property: 'fgAtivo', value: true, operator: 'eq' });
        }

        return filtersRequestOptions;
    }, [filter, tokenInfo?.tpUsuario, queryValue]);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/campanhamarketing/listagem',
        sort: [{ property: 'dsCampanhaMarketing', direction: 'ASC' }],
        filter: getFiltersRequestOptions,
    };

    const { isLoading, fetchStatus, data } = useQuery([requestOptions], (): Promise<RequestMarketingCampanha> => RequestListagem(requestOptions).then((res) => res.data), {
        onError: () => enqueueSnackbar('Falha ao processar solicitação de categorias', { variant: 'error' }),
    });

    const renderOptions = useCallback((props: object, option: MarketingCampanhaOptionsProps) => {
        const { subCampanhasMarketing } = option;
        const categoriasLength = subCampanhasMarketing ? subCampanhasMarketing.length : 0;

        return (
            <Box component="li" {...props} key={option.idCampanhaMarketing}>
                <Stack>
                    {subCampanhasMarketing &&
                        subCampanhasMarketing.map((subCampanha, index) => {
                            return (
                                <Box key={subCampanha.idCampanhaMarketing + subCampanha.dsCampanhaMarketing} sx={{ pl: (index - 1) * 1.5, display: 'flex', alignItems: 'center' }}>
                                    {index > 0 && <SubdirectoryArrowRightOutlinedIcon sx={{ height: 15, width: 15 }} />}

                                    {subCampanha.dsCampanhaMarketing}
                                </Box>
                            );
                        })}

                    <Box sx={{ pl: (categoriasLength - 1) * 1.5 }}>
                        {categoriasLength > 0 && <SubdirectoryArrowRightOutlinedIcon sx={{ height: 15, width: 15 }} />}

                        <strong>{option.dsCampanhaMarketing}</strong>
                    </Box>
                </Stack>
            </Box>
        );
    }, []);

    const getDataFiltered = useCallback(
        (limitePais = 1): MarketingCampanhaOptionsProps[] =>
            data?.data ? data.data.filter((campanha: MarketingCampanhaOptionsProps) => campanha.subCampanhasMarketing && campanha.subCampanhasMarketing.length <= limitePais) : [],
        [data],
    );

    const getOptions = useMemo((): MarketingCampanhaOptionsProps[] => {
        const { data: listCampanhas } = data || {};

        if (!listCampanhas) {
            return [];
        }

        if (!limitViewLevelsByCampanha) {
            return listCampanhas;
        }

        // Caso o valor da campanha for string ou number, faz a validação
        if (typeof limitViewLevelsByCampanha !== 'boolean') {
            // Encontra a campanha que esta sendo editada na lista de campanhas.
            const categoriaForm = listCampanhas.find((categoria: MarketingCampanhaOptionsProps) => categoria.idCampanhaMarketing === Number(limitViewLevelsByCampanha));

            // Se tiver a campanha na lista
            if (categoriaForm) {
                // Encontra categorias filhas da categoria principal
                const listSubCategoriasForm = listCampanhas.filter(
                    (campanha: MarketingCampanhaOptionsProps) => campanha.campanhaMarketingPai?.idCampanhaMarketing === categoriaForm.idCampanhaMarketing,
                );

                // Caso a campanha principal tenha filhos
                if (listSubCategoriasForm.length) {
                    const subCategoriasHasChildren = listSubCategoriasForm.some((subCampanha) =>
                        listCampanhas.some((campanha) => campanha?.campanhaMarketingPai?.idCampanhaMarketing === subCampanha.idCampanhaMarketing),
                    );
                    // Caso as campanhas filhas tenham filhas, não retorna nada. Senão, retorna categorias sem pai.
                    return subCategoriasHasChildren ? [] : getDataFiltered(0);
                }
            }
        }

        return getDataFiltered();
    }, [data, getDataFiltered, limitViewLevelsByCampanha]);

    return (
        <Autocomplete
            {...PropsFormik}
            multiple={multiple}
            onChange={onChange}
            loading={isLoading && fetchStatus === 'fetching'}
            options={getOptions}
            isOptionEqualToValue={(option, value) => value && option && option.idCampanhaMarketing === value.idCampanhaMarketing}
            renderInput={(params) => <CissTextField label={label} error={Boolean(error)} helperText={error} onChange={(e) => setInputValue(e.target.value)} {...params} />}
            getOptionLabel={(option: MarketingCampanhaOptionsProps | string) => (typeof option === 'string' ? option : option.dsCampanhaMarketing)}
            onSelect={() => setInputValue('')}
            renderOption={renderOptions}
        />
    );
}
