import { Dispatch, SetStateAction } from 'react';

import { useNavigateToPedido } from 'hooks/useNavigateToPedido';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { RowProps } from 'pages/compra/pedido-realizado/types';

type UsePostClonePedido = {
    setIsDisabledActions: Dispatch<SetStateAction<boolean>>;
    rowData: RowProps;
};

type ReturnUsePostClonePedido = {
    handleClone: () => void;
    isLoadingClonar: boolean;
};

export function usePostClonePedido({ setIsDisabledActions, rowData }: UsePostClonePedido): ReturnUsePostClonePedido {
    const { idPedido } = rowData;
    const { navigateToPedido } = useNavigateToPedido();
    const { mutate, isLoading: isLoadingClonar } = useFormMutation();
    const { showError } = useShowError();

    const handleClone = (): void => {
        setIsDisabledActions(true);

        mutate(
            { url: `/compra/pedidocompra/${idPedido}/clonar?substituirRascunhoAtual=true`, preventSnack: true },
            {
                onSuccess: () => navigateToPedido(rowData),
                onError: (error: any) => {
                    setIsDisabledActions(false);
                    showError(error, 'Não foi possível clonar o pedido. Tente novamente');
                },
            },
        );
    };

    return { handleClone, isLoadingClonar };
}
