import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TipoUsuarioProps {
    idTipoUsuario: number;
    dsTipoUsuario: string;
    tipoUsuarioEnum: string;
}

type TipoUsuarioAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function TipoUsuarioAutoComplete({
    sx,
    name,
    value,
    error,
    multiple = false,
    disabled = false,
    onChange = () => {},
}: TipoUsuarioAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            name={name}
            error={error}
            value={value}
            onChange={onChange}
            multiple={multiple}
            disabled={disabled}
            disableClearable={true}
            label="Usuários"
            optionStringTemplate="{dsTipoUsuario}"
            selectedStringTemplate="{dsTipoUsuario}"
            isOptionEqualToValue={(option: TipoUsuarioProps, value: TipoUsuarioProps) => option.idTipoUsuario === value.idTipoUsuario}
            requestOptions={{
                url: '/gestao/tipousuario',
                columns: 'idTipoUsuario,dsTipoUsuario',
                nested: true,
            }}
        />
    );
}
