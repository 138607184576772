import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface ConfiguracaoPedidoOptionsProps {
    idConfigPedido: number;
    dsConfigPedido: string;
}

type ConfiguracaoPedidoAutoCompleteProps = Pick<CissAutoCompleteProps, 'requestOptions' | 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label'>;

export function ConfiguracaoPedidoAutoComplete({
    size,
    value,
    error,
    multiple = false,
    disabled = false,
    sx,
    onChange = () => {},
    requestOptions = {
        url: 'gestao/configpedido/loja',
        sort: [{ property: 'idConfigPedido', direction: 'DESC' }],
    },
    label = 'Configuração de Pedido',
}: ConfiguracaoPedidoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idConfigPedido} - {dsConfigPedido}"
            optionStringTemplate="<strong>{idConfigPedido}</strong> - {dsConfigPedido}"
            requestOptions={requestOptions}
            isOptionEqualToValue={(option: ConfiguracaoPedidoOptionsProps, value: ConfiguracaoPedidoOptionsProps) => option.idConfigPedido === value.idConfigPedido}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}
