import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Autocomplete, AutocompleteProps } from 'formik-mui';
import { useDebounce } from 'use-debounce';

import { Box } from '@mui/material';

import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useField } from 'formik';
import { CissTextField } from 'components/fields/';

interface categoryOptionsProps {
    idCategoriaComunicado: number;
    dsCategoriaComunicado: string;
}

interface CategoryFieldProps extends AutocompleteProps<categoryOptionsProps, boolean | undefined, boolean | undefined, boolean | undefined> {
    label: string;
}

interface RequestListagemType extends RequestListagemProps {
    data: {
        data: categoryOptionsProps[];
    };
}

export function CategoryFieldFormik({ onChange, multiple = true, label = 'Categorias', ...PropsFormik }: CategoryFieldProps): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [field, { error }] = useField(PropsFormik.field.name);
    const [inputValue, setInputValue] = useState<string>('');
    const [queryValue] = useDebounce(inputValue, 500);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/categoriacomunicado',
        query: queryValue,
        sort: [{ property: 'idCategoriaComunicado', direction: 'ASC' }],
        filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],
        columns: 'idCategoriaComunicado,dsCategoriaComunicado',
        limit: 50,
    };

    const { isLoading, fetchStatus, data } = useQuery([requestOptions], () => RequestListagem(requestOptions) as Promise<RequestListagemType>, {
        onError: () => enqueueSnackbar('Falha ao processar solicitação de categorias', { variant: 'error' }),
    });

    return (
        <Autocomplete
            {...PropsFormik}
            multiple={multiple}
            onChange={onChange}
            loading={isLoading && fetchStatus === 'fetching'}
            options={data?.data?.data ?? []}
            isOptionEqualToValue={(option, value) => option.idCategoriaComunicado === value.idCategoriaComunicado}
            getOptionLabel={(option: categoryOptionsProps | string) => (typeof option === 'string' ? option : option.dsCategoriaComunicado)}
            renderInput={(params) => (
                <CissTextField {...params} label={label} error={Boolean(error)} onChange={(e) => setInputValue(e.target.value)} helperText={error} />
            )}
            onSelect={() => setInputValue('')}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.idCategoriaComunicado}>
                    {option.dsCategoriaComunicado}
                </Box>
            )}
        />
    );
}
