import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TipoPedidoOptionsProps {
    idTipoPedido: number;
    dsTipoPedido: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name' | 'size'>;

export function TipoPedidoAutoComplete({
    value,
    error,
    multiple = false,
    disabled = false,
    sx,
    onChange = () => {},
    name,
    size,
}: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Tipo de Pedido"
            value={value}
            multiple={multiple}
            error={error}
            size={size}
            selectedStringTemplate="{dsTipoPedido}"
            optionStringTemplate="{dsTipoPedido}"
            requestOptions={{
                url: '/gestao/tipopedido',
                sort: [{ property: 'idTipoPedido', direction: 'ASC' }],
                columns: 'idTipoPedido,dsTipoPedido',
                limit: 50,
            }}
            isOptionEqualToValue={(option: TipoPedidoOptionsProps, value: TipoPedidoOptionsProps) =>
                value && option && option.idTipoPedido === value.idTipoPedido
            }
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
