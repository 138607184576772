import React, { forwardRef } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, SxProps, Typography } from '@mui/material';

import { MenuItemProps } from 'components/menu/main/types';
import { getIcons } from 'components/menu/main/utils/iconUtils';
import { usePermissionsContext } from 'context/PermissionsContext';
interface MenuMainItem {
    menuItem: MenuItemProps;
    callback?: () => void;
    rightIcon?: boolean;
    sx?: SxProps;
}

export const MenuMainItem = forwardRef<HTMLButtonElement, MenuMainItem>(({ menuItem, callback, rightIcon, sx }, ref) => {
    const { hasAnyPermission } = usePermissionsContext();
    const { permissions, text, icon } = menuItem;
    const leftIcon = getIcons(icon);

    if (permissions && permissions.length > 0 && !hasAnyPermission(permissions)) {
        return null;
    }

    return (
        <Button
            ref={ref}
            component={Button}
            onClick={callback}
            sx={{
                textTransform: 'none',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                flex: 1,
                py: 1,
                px: 2,
                gap: 1,
                borderRadius: 0,
                height: 37,
                ...sx,
            }}
        >
            {leftIcon}

            <Typography sx={{ color: 'common.black', flex: 1, textAlign: 'start', lineHeight: '1.1rem', fontSize: '1.1rem' }}>{text}</Typography>

            {rightIcon && <ChevronRightIcon />}
        </Button>
    );
});
