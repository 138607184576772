import { ConfigPedidoProps } from '../types';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';

export function useFrete() {
    const { vlPedido, usuarioInfo, configPedido } = usePedidoCompraContext();
    const { configPedidoRegraValidacao } = configPedido as ConfigPedidoProps;
    const { vlBaseFreteGratis } = configPedidoRegraValidacao;
    const hasVlFrete = typeof usuarioInfo?.vlFrete === 'number';
    const hasVlBaseFreteGratis = typeof vlBaseFreteGratis === 'number';

    let vlParaFreteGratis = 0;
    let vlFrete = 0;

    if (hasVlBaseFreteGratis && vlBaseFreteGratis > vlPedido) {
        vlParaFreteGratis = vlBaseFreteGratis - vlPedido;
    }

    if (hasVlFrete && (!hasVlBaseFreteGratis || vlParaFreteGratis)) {
        vlFrete = usuarioInfo?.vlFrete;
    }

    return {
        vlFrete,
        hasVlFrete,
        vlParaFreteGratis,
        isFreteGratis: vlFrete === 0,
    };
}
