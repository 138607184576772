import React from 'react';

import { useParams } from 'react-router-dom';

import { FormProvider } from 'context/FormContext';
import { LikesProvider } from 'context/LikeContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import {
    MarketingTradeCampanhaListComp,
    MarketingTradeCampanhaForm,
    MarketingTradeDetailComp,
    MarketingTradeEdicaoPecasComp,
    MarketingTradeFormComp,
    MarketingTradeListComp,
} from 'pages/marketing-trade/components';

export function TradeDetail(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['TRADE_PECA_VISUALIZAR'])) return alertNotPermission();

    return (
        <LikesProvider>
            <MarketingTradeDetailComp />
        </LikesProvider>
    );
}

export function TradeList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['TRADE_PECA_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <MarketingTradeListComp />
        </ListagemProvider>
    );
}

export function TradeForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('TRADE_PECA', id)) return alertNotPermission();

    return (
        <FormProvider>
            <MarketingTradeFormComp />
        </FormProvider>
    );
}

export function TradeEdicaoPecas(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['TRADE_PECA_ALTERAR']) && !hasPermission(['TRADE_PECA_CADASTRAR'])) return alertNotPermission();

    return <MarketingTradeEdicaoPecasComp />;
}

export function TradeCampanhaList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['TRADE_CAMPANHA_GERENCIAR'])) alertNotPermission();

    return (
        <ListagemProvider>
            <MarketingTradeCampanhaListComp />
        </ListagemProvider>
    );
}

export function TradeCampanhaForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('TRADE_CAMPANHA', id)) return alertNotPermission();

    return (
        <FormProvider>
            <MarketingTradeCampanhaForm />
        </FormProvider>
    );
}
