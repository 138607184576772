import React from 'react';

import * as Yup from 'yup';

import { FullLoading } from 'components/loading';
import { FormPage } from 'components/page';
import { PromocaoGeneralBlock } from 'pages/revendedor/promocao/components/templates/PromocaoGeneralBlock';
import { usePostPromocao } from 'pages/revendedor/promocao/hooks';

export function PromocaoFormComp(): JSX.Element {
    const { handleSubmit, isLoading } = usePostPromocao();

    const validationSchema = Yup.object({
        nome: Yup.string().required().nullable(),
        codigo: Yup.string().required().nullable(),
    });

    return (
        <FormPage goBackButton title="Promoção" fgAtivo={false} values={{}} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {() => (
                <>
                    <FullLoading loading={isLoading} />

                    <PromocaoGeneralBlock />
                </>
            )}
        </FormPage>
    );
}
