import React from 'react';

import { useParams } from 'react-router-dom';

import { Checkbox } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';

import { CissNumberField, CissTextField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { LojaRequest, LojaConfiguracao } from 'pages/cadastro/loja/types';

export const getTypeChave = (dsChave: string): string => {
    return dsChave.slice(0, 2);
};

const getInitialValue = (dsChave: string): boolean | string | number => {
    const typeChave = getTypeChave(dsChave);

    switch (typeChave) {
        case 'fg':
            return false;
        case 'pe':
        case 'vl':
            return 0;
        case 'cd':
        case 'ds':
        default:
            return '';
    }
};

const getRows = (data: LojaConfiguracao[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index,
            idConfiguracaoPropriedade: item.idConfiguracaoPropriedade,
            dsChave: item.configuracaoPropriedade?.dsChave,
            dsConfiguracao: item.configuracaoPropriedade?.dsConfiguracao,
            dsValor: item.dsValor,
            fgAtivo: getTypeChave(item.configuracaoPropriedade?.dsChave) !== 'fg' || item.dsValor === 'true',
            configuracaoPropriedade: {
                idConfiguracaoPropriedade: item.idConfiguracaoPropriedade,
                dsConfiguracao: item.configuracaoPropriedade?.dsConfiguracao,
                dsChave: item.configuracaoPropriedade?.dsChave,
            },
        }));
    }
    return [];
};

export function LojaFormConfiguracaoPropriedadeGrid(): JSX.Element {
    const { values, setFieldValue } = useFormikContext<LojaRequest>();
    const RequestListagem = useRequestListagem();
    const { id } = useParams();

    const setInitialLojaConfiguracoes = (value: LojaConfiguracao[]): void => {
        const lojaConfiguracoes = value.map((item: LojaConfiguracao) => ({
            ...item,
            dsValor: getInitialValue(item.dsChave),
            configuracaoPropriedade: {
                dsChave: item.dsChave,
                dsConfiguracao: item.dsConfiguracao,
                idConfiguracaoPropriedade: item.idConfiguracaoPropriedade,
            },
        }));

        setFieldValue('lojaConfiguracoes', lojaConfiguracoes);
    };

    const requestConfigPropriedadeOptions: RequestOptionsType = {
        url: '/gestao/configuracaopropriedade',
        sort: [{ property: 'idConfiguracaoPropriedade', direction: 'ASC' }],
        columns: 'idConfiguracaoPropriedade,dsConfiguracao,dsChave',
        limit: 20,
    };

    const { refetch, isFetching } = useQuery(
        ['getLojaConfiguracoes'],
        () => RequestListagem(requestConfigPropriedadeOptions).then((res) => res.data.data),
        {
            enabled: !id,
            onSuccess: setInitialLojaConfiguracoes,
        },
    );

    const setLojaConfiguracoes = (id: number, value: string): void => {
        const lojaConfiguracao = values.lojaConfiguracoes || [];
        const newLojaConfiguracao = lojaConfiguracao.map((item) => {
            if (item.idConfiguracaoPropriedade === id) {
                item.dsValor = value;
            }

            return item;
        });

        setFieldValue('lojaConfiguracoes', newLojaConfiguracao);
    };

    const getCell = ({ row }: GridRenderCellParams): JSX.Element | undefined => {
        const typeChave = getTypeChave(row.dsChave);
        const isPercentual = typeChave === 'pe';
        const { idConfiguracaoPropriedade } = row;

        switch (typeChave) {
            case 'fg':
                return (
                    <Checkbox
                        checked={row.dsValor === 'true'}
                        onChange={(e) => setLojaConfiguracoes(idConfiguracaoPropriedade, String(e.target.checked))}
                    />
                );
            case 'ds':
            case 'cd':
                return (
                    <CissTextField
                        size="small"
                        value={row.dsValor}
                        onChange={(e) => setLojaConfiguracoes(idConfiguracaoPropriedade, e.target.value)}
                    />
                );
            case 'vl':
            case 'pe':
                return (
                    <CissNumberField
                        size="small"
                        inputType="decimal"
                        value={row.dsValor}
                        maxValue={isPercentual ? 100 : undefined}
                        forceMaxValue={isPercentual}
                        onChange={(e) => setLojaConfiguracoes(idConfiguracaoPropriedade, e.target.value)}
                    />
                );
        }
    };

    const columns: GridColDef[] = [
        { field: 'dsConfiguracao', headerName: 'Configuração', minWidth: 120, flex: 1 },
        {
            field: 'Valor',
            align: 'center',
            headerName: 'Valor',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 90,
            renderCell: getCell,
        },
    ];

    const rows = getRows(values.lojaConfiguracoes);

    return <PanelGrid rows={rows} hideFooter={true} rowsCount={rows.length} reload={refetch} loading={isFetching} columns={columns} remote={false} />;
}
