import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { EditBtn } from 'pages/logistica/grade/components/atoms/EditBtn';
import { GradeImportacaoModeloBtn } from 'pages/logistica/grade/components/atoms/GradeImportacaoModeloBtn';
import { GradeImportacaoUploadBtn } from 'pages/logistica/grade/components/atoms/GradeImportacaoUploadBtn';
import { GradeModal } from 'pages/logistica/grade/components/templates/GradeModal';
import { GradeListErros, RequestGradeModelProps } from 'pages/logistica/grade/types';

const getDateStringFormated = (stringDate: string, formatPattern: string): string => {
    const date = new Date(stringDate);
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * -60000);

    return format(newDate, formatPattern);
};

const makeRows = (data: any): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item: RequestGradeModelProps, index) => ({
            id: index + 1,
            idGrade: item.idGrade,
            dsRazaoSocial: item.loja?.dsRazaoSocial,
            cdLoja: item.loja?.cdLoja,
            dtLimitePedido: item.dtLimitePedido ? getDateStringFormated(item.dtLimitePedido, 'dd/MM/yyyy') : '',
            mesFaturamento: item.mesFaturamento,
            dtInicioEntrega: item.dtColeta ? getDateStringFormated(item.dtColeta, 'dd/MM/yyyy') : '',
            dtFimEntrega: item.dtEntrega ? getDateStringFormated(item.dtEntrega, 'dd/MM/yyyy') : '',
            dsTipoGrade: item.tipoGrade?.dsTipoGrade,
            dhInicioEntrega: item.dhInicioEntrega ? format(new Date(item.dhInicioEntrega), 'HH:mm:ss') : '',
            dhFimEntrega: item.dhFimEntrega ? format(new Date(item.dhFimEntrega), 'HH:mm:ss') : '',
            dsObservacao: item.dsObservacao,
        }));
    }

    return [];
};

const makeErrorsRows = (data: any): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item: RequestGradeModelProps, index) => ({
            id: index + 1,
            idGrade: item.idGrade,
            dsRazaoSocial: item.loja?.dsRazaoSocial,
            cdLoja: item.loja?.cdLoja,
            dtLimitePedido: item.dtLimitePedido ? getDateStringFormated(item.dtLimitePedido, 'dd/MM/yyyy') : '',
            mesFaturamento: item.mesFaturamento,
            dtInicioEntrega: item.dtColeta ? getDateStringFormated(item.dtColeta, 'dd/MM/yyyy') : '',
            dtFimEntrega: item.dtEntrega ? getDateStringFormated(item.dtEntrega, 'dd/MM/yyyy') : '',
            dsTipoGrade: item.tipoGrade?.dsTipoGrade,
            dhInicioEntrega: item.dhInicioEntrega ? format(new Date(item.dhInicioEntrega), 'HH:mm:ss') : '',
            dhFimEntrega: item.dhFimEntrega ? format(new Date(item.dhFimEntrega), 'HH:mm:ss') : '',
            dsObservacao: item.dsObservacao,
            dsMensagemErro: item.dsMensagemErro,
            nrLinha: item.nrLinhaArquivo,
        }));
    }

    return [];
};

export function GradeImportacaoComp(): JSX.Element {
    const [gradeList, setGradeList] = useState<RequestGradeModelProps[]>([]);
    const [gradeErrosList, setGradeErrosList] = useState<RequestGradeModelProps[]>([]);
    const [selectedGrade, setSelectedGrade] = useState<RequestGradeModelProps | null>(null);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const urlSave = useRef('/gestao/grade/save');
    const urlValidate = useRef('/gestao/grade/validate');

    const { content, setContent } = useFormContext();
    const { mutate } = useFormMutation();
    const { enqueueSnackbar } = useSnackbar();
    const { gradeListArquivo } = content ?? {};

    const rows = makeRows(gradeList);
    const errorsRows = makeErrorsRows(gradeErrosList);

    const handleEditErro = (idRow: number): void => {
        const grade = gradeErrosList.at(idRow - 1);

        if (grade) {
            setSelectedGrade(grade);
            setModalVisible(true);
        }
    };

    const handleSubmit = (): void => {
        setIsLoading(true);

        mutate(
            {
                url: urlSave.current,
                formData: gradeList,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Grades importadas com sucesso', { variant: 'success' });
                },
                onSettled: () => {
                    setIsLoading(false);
                },
            },
        );
    };

    const submitGradeToValidate = useCallback(
        (values: RequestGradeModelProps, formik: FormikHelpers<RequestGradeModelProps>) => {
            setIsLoading(true);

            mutate(
                {
                    url: urlValidate.current,
                    formData: values,
                    formik,
                },
                {
                    onSuccess: () => {
                        const newGradeListErros = gradeListArquivo.gradeImportacaoErros.filter((gradeErro: GradeListErros) => {
                            if (values?.nrLinhaArquivo === gradeErro.nrLinha) {
                                return false;
                            }
                            return true;
                        });

                        setModalVisible(false);

                        setSelectedGrade(null);

                        setContent({
                            ...content,
                            gradeListArquivo: {
                                gradeImportacaoErros: newGradeListErros,
                                grades: [...gradeListArquivo.grades, values],
                            },
                        });
                    },
                    onSettled: () => {
                        setIsLoading(false);
                    },
                },
            );
        },
        [content],
    );

    const errorPanelColumns: GridColDef[] = [
        { field: 'nrLinha', headerName: 'Linha do Erro', width: 140, minWidth: 100 },
        {
            field: 'dsMensagemErro',
            headerName: 'Menssagem do Erro',
            flex: 1,
            minWidth: 250,
            renderCell: ({ row }) => {
                return <TextField variant="standard" value={row.dsMensagemErro} multiline InputProps={{ disableUnderline: true, style: { padding: 10 } }} />;
            },
        },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 100,
            renderCell: ({ row }) => <EditBtn onClick={() => handleEditErro(row.id)} tooltipText={'Corrigir erros'} />,
        },
    ];

    const panelColumns: GridColDef[] = [
        { field: 'dsRazaoSocial', headerName: 'Loja', width: 140, minWidth: 130 },
        { field: 'cdLoja', headerName: 'Código', width: 80, minWidth: 70 },
        { field: 'dtLimitePedido', headerName: 'Data Limite Pedido', width: 160, minWidth: 130 },
        { field: 'mesFaturamento', headerName: 'Mês Faturamento', width: 130, minWidth: 120 },
        { field: 'dtInicioEntrega', headerName: 'Data de Coleta', flex: 1, width: 100, minWidth: 100 },
        { field: 'dtFimEntrega', headerName: 'Data de Entrega', width: 130, minWidth: 110 },
        { field: 'dsTipoGrade', headerName: 'Tipo de Grade', width: 100, minWidth: 90 },
        { field: 'dhInicioEntrega', headerName: 'Hora Início Entrega', flex: 1, width: 100, minWidth: 130 },
        { field: 'dhFimEntrega', headerName: 'Hora Fim Entrega', flex: 1, width: 100, minWidth: 110 },
        { field: 'dsObservacao', headerName: 'Observação', flex: 1, width: 130, minWidth: 90 },
    ];

    useEffect(() => {
        if (gradeListArquivo) {
            const grades = gradeListArquivo.grades as RequestGradeModelProps[];
            const gradesErros = gradeListArquivo.gradeImportacaoErros as GradeListErros[];

            const mappedGradesErros: RequestGradeModelProps[] = gradesErros.map((grade): RequestGradeModelProps => {
                return {
                    ...(grade.grade || {}),
                    dsMensagemErro: grade.dsMensagensErros?.join('\n'),
                };
            });

            setGradeList(grades);
            setGradeErrosList(mappedGradesErros);
        } else {
            setGradeList([]);
            setGradeErrosList([]);
        }
    }, [gradeListArquivo]);

    return (
        <OneColumn title="Importação de Grades" goBackButton>
            <GradeModal open={modalVisible} setOpen={setModalVisible} grade={selectedGrade} onSubmit={submitGradeToValidate} isLoading={isLoading} />

            <Block>
                <SubTitle label="Importar Arquivo" />

                <Box sx={{ mb: 2 }}>
                    <GradeImportacaoModeloBtn dsChave="URL_ARQUIVO_MODELO_GRADE" />
                </Box>

                <GradeImportacaoUploadBtn url="/gestao/grade/upload" contentListName="gradeListArquivo" />
            </Block>

            {Boolean(errorsRows.length) && (
                <Block>
                    <SubTitle label="Erros de Importação" />

                    <PanelGrid rows={errorsRows} rowsCount={errorsRows.length} columns={errorPanelColumns} loading={false} reload={false} remote={false} rowCellHeight={'auto'} />
                </Block>
            )}

            <Block>
                <PanelGrid rows={rows} rowsCount={errorsRows.length} columns={panelColumns} loading={false} reload={false} remote={false} />
            </Block>

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 5,
                    display: 'flex',
                    p: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <Box flex={1} />
                <LoadingButton variant="contained" loading={isLoading} disabled={!gradeList} size="large" onClick={() => handleSubmit()}>
                    Salvar
                </LoadingButton>
            </Box>
        </OneColumn>
    );
}
