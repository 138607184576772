import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Tooltip, Stack } from '@mui/material';

export function HeaderSituacaoTooltip(): JSX.Element {
    return (
        <Tooltip
            title={
                <Typography>
                    Situação da sugestão de compra dos produtos da configuração para a loja selecionada: <br />
                    <ul>
                        <li>
                            <strong>Completa:</strong> todos os produtos possuem sugestão.
                        </li>
                        <li>
                            <strong>Incompleta:</strong> alguns produtos não possuem sugestão.
                        </li>
                        <li>
                            <strong>Não Importada:</strong> nenhuma sugestão foi importada na configuração para a loja.
                        </li>
                        <li>
                            <strong>Falha na Importação:</strong> houve uma falha durante a importação. A falha pode ser visualizada clicando no botão da situação.
                        </li>
                        <li>
                            <strong>Importando:</strong> o arquivo de sugestões está sendo importado.
                        </li>
                    </ul>
                </Typography>
            }
            placement="top"
        >
            <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                <Typography fontWeight={500} fontSize="0.875rem">
                    Situação
                </Typography>
                <InfoOutlinedIcon fontSize="small" />
            </Stack>
        </Tooltip>
    );
}
