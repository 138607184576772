import { PedidoTotalizadorSincronizacaoStatus } from '../types';

interface ConfigPedidoProps {
    idConfigPedido: number;
    dsConfigPedido: string;
    configPedidoStatus: string;
    idConfigPedidoStatus: number;
}

export interface RequestConfigPedido {
    data: ConfigPedidoProps[];
}

export type Icon = 'clock' | 'success' | 'cancel' | 'article' | 'flag' | 'box';

export type Color = 'orange' | 'red' | 'green' | 'pink' | 'blue';

export const getColor: Record<Color, string> = {
    orange: '#FFCC99',
    green: '#98FF98',
    red: '#FF6665',
    pink: '#FFCBFF',
    blue: '#CCCCFF',
};

export interface StatusIntegracaoCardIndicatorProps {
    color: Color;
    icon: Icon;
    label?: string;
    value?: number | string;
    peValue?: number | string;
}

export interface PacoteAtual {
    idPedidoTotalizadorSincronizacaoPacote: number;
    dtColetaGrade: string;
    nrPosicao: number;
    qtTotalPedido: number;
    qtTotalPedidoFinalizadoSucesso: number;
    qtTotalPedidoFalha: number;
    qtTotalPedidoRestante: number;
    pePedidoFalha: number;
    pePedidoRestante: number;
    pePedidoFinalizadoSucesso: number;
    pedidoTotalizadorSincronizacaoStatus: PedidoTotalizadorSincronizacaoStatus;
}

export interface RequestStatusIntegracao {
    idPedidoTotalizadorSincronizacao: number;
    qtTotalPedido: number;
    qtTotalPedidoFinalizadoSucesso: number;
    qtTotalPedidoFalha: number;
    qtTotalPacote: number;
    qtTotalPedidoRestante: number;
    pePedidoFalha: number;
    pePedidoFinalizadoSucesso: number;
    pePedidoRestante: number;
    pacoteAtual: PacoteAtual;
    pedidoTotalizadorSincronizacaoStatus: PedidoTotalizadorSincronizacaoStatus;
    tempoPercorrido: string;
    tempoMedioPedidoFinalizadoSucesso: string;
    tempoRestante: string;
    tempoRestantePacote: string;
}
