export interface RequestCentroCustoProps {
    idCentroCusto: number | null;
    dsCentroCusto: string;
    cdCentroCusto: string;
    fgAtivo: boolean;
}

export const initialCentroCustoValues: RequestCentroCustoProps = {
    idCentroCusto: null,
    dsCentroCusto: '',
    cdCentroCusto: '',
    fgAtivo: true,
};
