import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface TagPecaTrade {
    idTagPecaTrade: number;
    dsTagPecaTrade: string;
}

type TagPecaTradeAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function TagPecaTradeAutoComplete({ value, error, multiple = true, disabled = false, sx, onChange = () => {}, name }: TagPecaTradeAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Tags"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{idTagPecaTrade} - {dsTagPecaTrade}"
            optionStringTemplate="<strong>{idTagPecaTrade}</strong> - {dsTagPecaTrade}"
            requestOptions={{
                url: '/gestao/tagpecatrade',
                sort: [{ property: 'idTagPecaTrade', direction: 'ASC' }],
                columns: 'idTagPecaTrade,dsTagPecaTrade',
                limit: 50,
            }}
            isOptionEqualToValue={(option: TagPecaTrade, value: TagPecaTrade) => option.idTagPecaTrade === value.idTagPecaTrade}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
