import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { MarketingTagListFilter } from 'pages/marketing-trade/marketing/tag/components';
import { MarketingTag, RequestMarketingTag } from 'pages/marketing-trade/marketing/tag/types';

const generateTagList = (data: MarketingTag[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idTagPecaMarketing: item.idTagPecaMarketing,
            dsTagPecaMarketing: item.dsTagPecaMarketing,
        }));
    }

    return [];
};

export function MarketingTagListComp(): JSX.Element {
    const { filter, page, limit, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/tagpecamarketing',
        filter,
        sort: sort && sort.length > 0 ? sort : [{ property: 'idTagPecaMarketing', direction: 'DESC' }],
        columns: 'idTagPecaMarketing,dsTagPecaMarketing',
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestMarketingTag> => RequestListagem(requestOptions).then((res: any) => res.data));

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'idTagPecaMarketing', headerName: 'Código', alignItems: 'center', flex: 1, type: 'string' },
            { field: 'dsTagPecaMarketing', headerName: 'Descrição', alignItems: 'center', flex: 4, type: 'string' },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                renderCell: ({ row }) => (
                    <EditRemoveBtn
                        id={row.idTagPecaMarketing}
                        showEditBtn={true}
                        showRemoveBtn={true}
                        editUrl="form/"
                        removeUrl={`gestao/tagpecamarketing/${row.idTagPecaMarketing}`}
                        sx={{ ml: 'auto', p: 1.5 }}
                    />
                ),
            },
        ],
        [refetch],
    );

    return (
        <OneColumn title="Tags Marketing" goBackButton createButton>
            <MarketingTagListFilter />
            <Block>
                <PanelGrid rows={generateTagList(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
            </Block>
        </OneColumn>
    );
}
