import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { useFormMutation } from 'mutations/useFormMutation';
import { initialCentroCustoValues, RequestCentroCustoProps } from 'pages/complementar/centro-custo/types';

export function CentroCustoFormComp(): JSX.Element {
    const { setContent } = useFormContext();
    const url = '/gestao/centrocusto/';
    const { id } = useParams();
    const { mutate } = useFormMutation();

    const handleSubmit = (values: RequestCentroCustoProps, formik: FormikHelpers<RequestCentroCustoProps>): void => {
        mutate(
            {
                url,
                idField: 'idCentroCusto',
                formData: values,
                formik,
            },
            {
                onSuccess: (response: any) => {
                    const { data } = response.data;

                    if (data) {
                        setContent(data);
                    }
                },
            },
        );
    };

    const validationSchema = useMemo(
        (): any =>
            Yup.object({
                dsCentroCusto: Yup.string().required(),
                cdCentroCusto: Yup.string().required(),
                fgAtivo: Yup.boolean().required(),
            }),
        [],
    );

    return (
        <FormPage title="Centro de Custos" values={initialCentroCustoValues} fgAtivo={false} onSubmit={handleSubmit} url={url} validationSchema={validationSchema} goBackButton>
            {(formik: FormikProps<RequestCentroCustoProps>) => {
                return (
                    <Block>
                        <Row>
                            {id && (
                                <Grid item flex={1}>
                                    <CissTextField label="Código" value={id} disabled={true} />
                                </Grid>
                            )}

                            <Grid item flex={1}>
                                <CissTextField
                                    label="Nome do centro"
                                    name="dsCentroCusto"
                                    value={formik.values.dsCentroCusto}
                                    error={Boolean(formik.errors.dsCentroCusto)}
                                    helperText={formik.errors.dsCentroCusto && String(formik.errors.dsCentroCusto)}
                                    onChange={(e) => formik.setFieldValue('dsCentroCusto', e.target.value)}
                                />
                            </Grid>

                            <Grid item flex={1}>
                                <CissTextField
                                    disabled={Boolean(id)}
                                    label="Código de Integração"
                                    name="cdCentroCusto"
                                    value={formik.values.cdCentroCusto}
                                    error={Boolean(formik.errors.cdCentroCusto)}
                                    helperText={formik.errors.cdCentroCusto && String(formik.errors.cdCentroCusto)}
                                    onChange={(e) => formik.setFieldValue('cdCentroCusto', e.target.value)}
                                />
                            </Grid>
                        </Row>
                    </Block>
                );
            }}
        </FormPage>
    );
}
