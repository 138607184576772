import React from 'react';

import { IconButton, SxProps, Tooltip } from '@mui/material';
import { IconBaseProps } from 'react-icons';
import { BsWhatsapp } from 'react-icons/bs';

type WhatsAppBtn = {
    sx?: SxProps;
    link?: string;
    size?: IconBaseProps['size'];
};

export function WhatsAppBtn({ sx, size = 20, link }: WhatsAppBtn): JSX.Element {
    const onClick = (): void => {
        window.open(link, '_blank');
    };

    return (
        <Tooltip title="Fale com o Customer" placement="right" arrow>
            <IconButton sx={{ 'padding': 1.5, 'backgroundColor': '#25D366', 'color': '#fff', ':hover': { backgroundColor: '#1cbf58' }, ...sx }} onClick={onClick}>
                <BsWhatsapp size={size} />
            </IconButton>
        </Tooltip>
    );
}
