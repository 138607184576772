import React, { useCallback, useEffect, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ContentModal } from 'components/modal';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ComunicadoHeader, ComunicadoContent, ComunicadoFooter } from 'pages/inicio/components';
import { Comunicado, ComunicadoIndex } from 'pages/inicio/types';

type RequestComunicado = RequestListagemProps & {
    data: Comunicado[];
};

const orderComunicadados = (comunicados: Comunicado[]): Comunicado[] =>
    comunicados.sort((a, b) => {
        if (a.fgSolicitaConfirmacao && !b.fgSolicitaConfirmacao) {
            return -1;
        }

        if (!a.fgSolicitaConfirmacao && b.fgSolicitaConfirmacao) {
            return 1;
        }

        return 0;
    });

export function ComunicadoModal(): JSX.Element {
    const [openModalComunicados, setOpenModalComunicados] = useState(false);
    const [liEstouCiente, setLiEstouCiente] = useState(false);
    const [liEstouCienteDisabled, setLiEstouCienteDisabled] = useState(true);
    const [comunicado, setComunicado] = useState<ComunicadoIndex | null>(null);
    const [listComunicados, setListComunicados] = useState<ComunicadoIndex[]>([]);
    const [readComunicados, setReadComunicados] = useState<number[]>([]);
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/comunicado/popup',
    };

    const { fetchStatus } = useQuery([requestOptions], (): Promise<RequestComunicado> => RequestListagem(requestOptions).then((response) => response.data), {
        onSuccess: ({ data }) => {
            const newListComunicados = orderComunicadados(data);
            const newListComunicadosWithIndex = newListComunicados.map((comunicado, index): ComunicadoIndex => ({ index, ...comunicado }));

            setListComunicados(newListComunicadosWithIndex);

            setOpenModalComunicados(Boolean(data?.length));
        },
    });

    const changeComunicado = useCallback(
        (index: number) => {
            const newComunicado = listComunicados.at(index);

            if (newComunicado) {
                setLiEstouCiente(false);
                setLiEstouCienteDisabled(true);
                setComunicado(newComunicado);
            } else {
                setOpenModalComunicados(false);
            }
        },
        [listComunicados],
    );

    useEffect(() => {
        if (listComunicados.length) {
            changeComunicado(0);
        }
    }, [listComunicados]);

    return (
        <ContentModal
            open={openModalComunicados && fetchStatus === 'idle'}
            setOpen={setOpenModalComunicados}
            disableEscapeKeyDown
            disableBackdropClick
            sx={{
                width: '80vw',
                maxHeight: 'auto',
                height: 'auto',
                p: 0,
                borderRadius: 1,
            }}
        >
            <React.Fragment>
                {comunicado && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <ComunicadoHeader comunicado={comunicado} />

                        <Divider />

                        <ComunicadoContent comunicado={comunicado} liEstouCienteDisabled={liEstouCienteDisabled} setLiEstouCienteDisabled={setLiEstouCienteDisabled} />

                        <Divider />

                        <ComunicadoFooter
                            readComunicados={readComunicados}
                            setReadComunicados={setReadComunicados}
                            comunicado={comunicado}
                            liEstouCiente={liEstouCiente}
                            liEstouCienteDisabled={liEstouCienteDisabled}
                            setLiEstouCiente={setLiEstouCiente}
                            listComunicadosLength={listComunicados?.length}
                            changeComunicado={changeComunicado}
                            setOpenModalComunicados={setOpenModalComunicados}
                        />
                    </Box>
                )}
            </React.Fragment>
        </ContentModal>
    );
}
