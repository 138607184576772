import React, { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';

interface LikePayloadProps {
    liked: boolean;
    likes: number;
}

interface LikeReducerProps {
    type: 'start' | 'like' | 'unlike';
    payload?: LikePayloadProps;
}

export type LikesContextData = {
    stateLike: LikePayloadProps;
    dispachLike: ({ type, payload }: LikeReducerProps) => void;
};

interface LikesProviderProps {
    children?: ReactNode;
}

const reducer = (state: LikePayloadProps, action: LikeReducerProps): LikePayloadProps => {
    const { type, payload } = action;

    switch (type) {
        case 'start':
            return {
                likes: payload?.likes ?? 0,
                liked: payload?.liked ?? false,
            };
        case 'like':
            return {
                likes: state.likes + 1,
                liked: true,
            };
        case 'unlike':
            return {
                likes: state.likes - 1,
                liked: false,
            };
        default:
            return state;
    }
};

const LikesContext = createContext<LikesContextData>({} as LikesContextData);

/**
 * Contexto para uso btn likes
 */
export function LikesProvider({ children }: LikesProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(reducer, { likes: 0, liked: false });

    const contextValue = useMemo(
        () => ({
            stateLike: state,
            dispachLike: dispatch,
        }),
        [state],
    );

    return <LikesContext.Provider value={contextValue}>{children}</LikesContext.Provider>;
}

export const useLikesContext = (): LikesContextData => useContext(LikesContext);
