import { UploadFileProps } from 'components/upload/types';

export interface CategoriaFilesProps extends UploadFileProps {
    idPecaMarketingArquivo?: number;
}

export interface CategoriaModelProps {
    idCategoriaComunicado: number;
    dsCategoriaComunicado: string;
    dsLinkCapa: string;
    fgAtivo: boolean;
}

export interface RequestCategoriaListModelProps {
    data: CategoriaModelProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestCategoriaModelProps {
    idCategoriaComunicado: number;
    dsCategoriaComunicado: string;
    dsLinkCapa: string;
    fgAtivo: boolean;
}

export const initialCategoriaValues: RequestCategoriaModelProps = {
    idCategoriaComunicado: -1,
    dsCategoriaComunicado: '',
    dsLinkCapa: '',
    fgAtivo: true,
};
