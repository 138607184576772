import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { brlDateTime } from 'util/format';

type ComunicadoLogsGrid = {
    idComunicado: number;
    queryEmail: string;
};

type ComunicadoLog = {
    dsEmail: string;
    dhInclusao: string;
};

type ComunicadoLogRequest = RequestListagemProps & {
    data: ComunicadoLog[];
};

const getRows = (data: ComunicadoLog[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            dsEmail: item.dsEmail,
            dhInclusao: item.dhInclusao && brlDateTime(`${item.dhInclusao}`),
        }));
    }

    return [];
};

const columns: GridColDef[] = [
    { field: 'dhInclusao', headerName: 'Data', minWidth: 130 },
    { field: 'dsEmail', headerName: 'Email', flex: 1, minWidth: 90 },
];

export function ComunicadoLogsGrid({ idComunicado, queryEmail }: ComunicadoLogsGrid): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { limit, page, sort } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        limit,
        page,
        url: '/gestao/campanhaemaildestinatario',
        sort: sort?.length ? sort : [{ property: 'idCampanhaEmailDestinatario', direction: 'DESC' }],
        filter: [
            { property: 'idTipoCampanhaEmail', operator: 'eq', value: 1 },
            { property: 'cdTipoCampanhaEmail', operator: 'eq', value: idComunicado },
            { property: 'dsEmail', operator: 'like', value: queryEmail },
        ],
    };

    const { data, isLoading, refetch } = useQuery<ComunicadoLogRequest>([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data));

    return (
        <PanelGrid
            autoHeight={false}
            rows={getRows(data?.data)}
            rowsCount={data?.data?.length || 0}
            columns={columns}
            loading={isLoading}
            reload={refetch}
            remote={false}
        />
    );
}
