import React from 'react';
import { Box, Typography } from '@mui/material';

import { brlDate } from 'util/format';

import { translateKey } from 'util/i18n';
import { Block } from 'components/page';

export function FppEspelhoNotaProdutoDetalhamento({ detalhamento }: any): JSX.Element {
    const translateKeyPrefix = 'fpp_espelho_nota_produto_';

    const firstListFromDetalhamento = [
        {
            label: translateKey(`${translateKeyPrefix}nrPedido`).toUpperCase(),
            value: detalhamento.nrPedido,
        },
        {
            label: translateKey(`${translateKeyPrefix}nrNotaFiscal`).toUpperCase(),
            value: detalhamento.nrNotaFiscal,
        },
        {
            label: translateKey(`${translateKeyPrefix}tipoNota`).toUpperCase(),
            value: detalhamento.tipoNota,
        },
    ];

    const secondaryListFromDetalhamento = [
        {
            label: translateKey(`${translateKeyPrefix}dtPedido`).toUpperCase(),
            value: brlDate(detalhamento.dtPedido),
        },
        {
            label: translateKey(`${translateKeyPrefix}dtEmissao`).toUpperCase(),
            value: brlDate(detalhamento.dtEmissao),
        },
    ];

    return (
        <Block sx={{ display: 'flex' }}>
            <Box sx={{ mr: 8 }}>
                {firstListFromDetalhamento.map((item) => (
                    <Typography key={item.label}>
                        {item.label}: {item.value}
                    </Typography>
                ))}
            </Box>

            <Box>
                {secondaryListFromDetalhamento.map((item) => (
                    <Typography key={item.label}>
                        {item.label}: {item.value}
                    </Typography>
                ))}
            </Box>
        </Block>
    );
}
