import { useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { useServiceApiContext } from 'context/ServiceApiContext';
import { useShowError } from 'hooks/useShowError';
import { getFieldsError } from 'util/error';
import { getSuccessMessage } from 'util/success';

interface MutationOptions {
    url: string;
    formData?: any;
    idField?: string;
    preventSnack?: boolean;
    method?: 'POST' | 'PUT';
    preventRedirect?: boolean;
    formik?: FormikHelpers<any>;
}

export const useFormMutation = <TData = unknown, TError = unknown>(): UseMutationResult<TData, TError, MutationOptions> => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showError } = useShowError();
    const { enqueueSnackbar } = useSnackbar();
    const Api = useServiceApiContext();

    const idProperty = useRef<string | undefined>();
    const noSnack = useRef<boolean | undefined>();
    const noRedirect = useRef<boolean | undefined>();
    const formik = useRef<FormikHelpers<any> | undefined>();

    const mutation = useMutation<TData, TError, MutationOptions>(
        ({ url, formData, idField, preventSnack = false, method, preventRedirect = false, formik: formikHelpers }: MutationOptions) => {
            const id = idField ? formData[idField] : null;

            idProperty.current = idField;
            noSnack.current = preventSnack;
            noRedirect.current = preventRedirect;
            formik.current = formikHelpers;

            if (method !== 'POST' && (typeof id === 'string' || (typeof id === 'number' && id > 0))) {
                return Api.put(`${url.endsWith('/') ? url : `${url}/`}${id}`, formData);
            }

            if (method === 'PUT') {
                return Api.put(url, formData);
            }

            return Api.post(url, formData);
        },
        {
            onSuccess: (result: any) => {
                const message = getSuccessMessage(result);
                const registeredId = idProperty.current && result.data.data[idProperty.current];

                if (formik.current) {
                    formik.current.setErrors({});
                }

                if (!noSnack.current) {
                    enqueueSnackbar(message, { variant: 'success' });
                }

                if (!noRedirect.current && registeredId && String(registeredId) !== String(id)) {
                    // aguardar formik atualizar states
                    setTimeout(() => {
                        navigate(registeredId.toString(), { replace: true });
                    }, 150);
                }
            },
            onError: (error) => {
                const fields = getFieldsError(error);

                if (formik.current) {
                    formik.current.setErrors({});

                    if (fields) {
                        formik.current.setErrors(fields);
                    }
                }

                if (!noSnack.current && !fields) {
                    showError(error);
                }
            },
        },
    );

    return {
        ...mutation,
        mutate: (...args: [MutationOptions]) => {
            if (!mutation.isLoading) {
                mutation.mutate(...args);
            }
        },
    };
};
