import React, { useMemo, useState } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { CarrinhoActionColumn, CarrinhoProdutoGrid, CarrinhoFalhasModal, CarrinhoSituacaoColumn } from 'pages/compra/carrinho/components';
import { useGetCarrinho } from 'pages/compra/carrinho/hooks';
import { PedigoLog, PedidoProps } from 'pages/compra/carrinho/types';
import { brlDate, brlPrice } from 'util/format';

const getRows = (data: PedidoProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idPedido,
            idPedido: item.idPedido,
            dhInclusao: item.dhInclusao ? brlDate(item.dhInclusao) : null,
            dtLimitePedido: item.grade.dtLimitePedido ? brlDate(`${item.grade.dtLimitePedido}T00:00:00`) : null,
            dtEntrega: item.grade.dtEntrega ? brlDate(`${item.grade.dtEntrega}T00:00:00`) : null,
            idLoja: item.loja.idLoja,
            cdLoja: item.loja.cdLoja,
            dsNomeFantasia: item.loja.dsNomeFantasia,
            idConfigPedido: item.configPedido.idConfigPedido,
            dsConfigPedido: item.configPedido.dsConfigPedido,
            idTipoControleEstoquePedido: item.configPedido.idTipoControleEstoquePedido,
            vlTotal: item.vlTotal,
            vlFrete: item.vlFrete ? item.vlFrete : 0,
            vlTotalFrete: item.vlFrete ? item.vlTotal + item.vlFrete : item.vlTotal,
            pedidoTotalizadorValidacoes: item.pedidoLog,
            idStatusRascunho: Array.isArray(item.pedidoLog) && item.pedidoLog.length ? 3 : 1,
            tipoEntrega: item.tipoEntrega,
            dsUsuarioPedido: item.usuarioPedido?.dsUsuario,
            idUsuarioPedido: item.usuarioPedido?.idUsuario,
            usuarioPedido: item.usuarioPedido,
            loja: item.loja,
            configPedido: item.configPedido,
        }));
    }

    return [];
};

export function CarrinhoGrid(): JSX.Element {
    const { hasPermission } = usePermissionsContext();
    const [openFalhasModal, setOpenFalhasModal] = useState<PedigoLog[] | boolean>(false);
    const { content } = useFormContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const { fetchStatus, data, refetch } = useGetCarrinho();
    const isLojasEqualOne = Array.isArray(content?.lojas) && content?.lojas.length === 1;

    const columns = useMemo((): GridColDef[] => {
        const baseColumns: GridColDef[] = [
            { field: 'dhInclusao', headerName: 'Data', width: 120, minWidth: 90 },
            { field: 'dsConfigPedido', headerName: 'Configuração do Pedido', flex: 1, minWidth: 220 },
            {
                field: 'vlTotal',
                headerName: 'Valor do pedido',
                type: 'number',
                align: 'right',
                width: 120,
                minWidth: 110,
                valueFormatter: ({ value }) => (value ? brlPrice(value) : null),
            },
            {
                field: 'idStatusRascunho',
                headerName: 'Status',
                align: 'center',
                headerAlign: 'center',
                minWidth: 170,
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: ({ row }) => (
                    <CarrinhoSituacaoColumn
                        idStatus={content?.finalizarAll || row.id === content?.finalizarRow ? 2 : row.idStatusRascunho}
                        setOpenFalhasModal={setOpenFalhasModal}
                        pedidoTotalizadorValidacoes={row.pedidoTotalizadorValidacoes}
                    />
                ),
            },
            {
                field: 'Ações',
                align: 'center',
                headerAlign: 'center',
                minWidth: 160,
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                renderCell: ({ row }) => <CarrinhoActionColumn row={row} />,
            },
        ];

        if (isTipoPedidoFuncionario) {
            if (hasPermissionFinalizacaoGeral) {
                baseColumns.splice(2, 0, {
                    field: 'dsUsuarioPedido',
                    headerName: 'Funcionário',
                    minWidth: 120,
                });
            }

            baseColumns.splice(
                3,
                0,
                {
                    field: 'vlFrete',
                    headerName: 'Frete',
                    type: 'number',
                    align: 'right',
                    width: 100,
                    minWidth: 90,
                    valueFormatter: ({ value }) => brlPrice(value),
                },
                {
                    field: 'vlTotalFrete',
                    headerName: 'Total do Pedido',
                    type: 'number',
                    align: 'right',
                    width: 120,
                    minWidth: 110,
                    valueFormatter: ({ value }) => brlPrice(value),
                },
            );
        } else {
            const gradeColumns: GridColDef[] = [
                { field: 'dsNomeFantasia', headerName: 'Loja', flex: 1, minWidth: 130 },
                { field: 'dtLimitePedido', headerName: 'Data Limite do Pedido', flex: 1, minWidth: 130 },
                { field: 'dtEntrega', headerName: 'Data de Entrega', flex: 1, minWidth: 130 },
            ];

            baseColumns.unshift(...gradeColumns);
        }

        return baseColumns;
    }, [content?.finalizarAll, content?.finalizarRow, hasPermissionFinalizacaoGeral, isTipoPedidoFuncionario]);

    return (
        <>
            <CarrinhoFalhasModal open={openFalhasModal} setOpen={setOpenFalhasModal} />

            <Block>
                <PanelGrid
                    rows={getRows(data?.data)}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={columns}
                    rowsCount={data?.total || 0}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => (
                        <ListagemProvider>
                            <CarrinhoProdutoGrid row={row} />
                        </ListagemProvider>
                    )}
                    isTreeData
                    visibleRows={isLojasEqualOne || isTipoPedidoFuncionario ? 'all' : undefined}
                />
            </Block>
        </>
    );
}
