import { FormikHelpers } from 'formik';

import { BandaPreco } from '../types';

import { useFormMutation } from 'mutations/useFormMutation';

type ReturnUsePostBandaPreco = {
    handleSubmit(formData: BandaPreco, formik: FormikHelpers<BandaPreco>): void;
    isLoading: boolean;
};

export function usePostBandaPreco(): ReturnUsePostBandaPreco {
    const { mutate, isLoading } = useFormMutation();

    const handleSubmit = (formData: BandaPreco, formik: FormikHelpers<BandaPreco>): void => {
        mutate({
            url: '/dre/dremercadologicagrupoexibicaobandapreco',
            idField: 'idDreMercadologicaGrupoExibicaoBandaPreco',
            formData,
            formik,
        });
    };

    return { handleSubmit, isLoading };
}
