import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';

import { DetalhamentoCreditoListComp, DetalhamentoCreditoDetailComp } from './components';
import { usePermissionsContext } from 'context/PermissionsContext';

export function DetalhamentoCreditoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DETALHAMENTO_CREDITO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <DetalhamentoCreditoListComp />
        </ListagemProvider>
    );
}

export function DetalhamentoCreditoDetail(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['FINANCEIRO_DETALHAMENTO_CREDITO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <DetalhamentoCreditoDetailComp />
        </ListagemProvider>
    );
}
