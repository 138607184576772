import React from 'react';

import { Typography } from '@mui/material';

import { brlDate } from 'util/format';

export function PedidoGradeEntregaText({ date }: { date: string }) {
    return (
        <Typography sx={{ display: 'inline' }}>
            Previsão de entrega:{' '}
            <Typography sx={{ display: 'inline', color: (theme) => theme.palette.secondary.main, fontWeight: 500, textAlign: 'center' }}>
                {brlDate(date)}
            </Typography>
        </Typography>
    );
}
