import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash';

import { PanelGrid } from 'components/grid';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoTotalizadorProdutoProps, RequestPedidoTotalizadorProdutoProps } from 'pages/compra/pedido-totalizador/types';
import { brlPrice } from 'util/format';

interface PedidoCompraTotalizadorProdutoGridProps {
    row: GridRowModel;
}

const getRows = (data: PedidoTotalizadorProdutoProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            vlTotalFaturado: item.vlTotalFaturado,
            vlUnitario: item.vlUnitario,
            qtProduto: item.qtProduto,
            qtProdutoFaturado: item.qtProdutoFaturado,
            lkImagem: item.produto.lkImagem,
            cdProduto: item.produto.cdProduto,
            dsProduto: item.produto.dsProduto,
            qtMinimaCompraPedidoProduto: item.qtMinimaCompraPedidoProduto,
            dsSubgrupo: item.produto.subgrupo.dsSubgrupo,
        }));
    }

    return [];
};

export function PedidoCompraTotalizadorProdutoGrid({ row }: PedidoCompraTotalizadorProdutoGridProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { limit, page, sort } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidoproduto',
        sort: sort?.length ? sort : [{ property: 'dsProduto', direction: 'ASC' }],
        filter: [{ property: 'idPedido', value: row.idPedido, operator: 'eq' }],
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestPedidoTotalizadorProdutoProps> => RequestListagem(requestOptions).then((res) => res.data));

    const columns = useMemo(
        (): GridColDef[] => [
            {
                field: 'imagem',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Imagem',
                filterable: false,
                sortable: false,
                minWidth: 50,
                renderCell: ({ row }) => {
                    if (row.lkImagem) {
                        return (
                            <Box
                                sx={{
                                    minWidth: 50,
                                    minHeight: 50,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundImage: `url("${row.lkImagem}")`,
                                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: 1,
                                }}
                            />
                        );
                    }
                },
            },
            { field: 'cdProduto', headerName: 'Código do Produto', width: 120, minWidth: 90 },
            { field: 'dsProduto', headerName: 'Produto', flex: 1, minWidth: 100 },
            { field: 'dsSubgrupo', headerName: 'Categoria', flex: 1, minWidth: 110 },
            {
                field: 'vlUnitario',
                headerName: 'Preço Unitário',
                type: 'number',
                align: 'right',
                width: 130,
                minWidth: 110,
                valueFormatter: ({ value }) => (isNumber(value) ? brlPrice(value) : ''),
            },
            { field: 'qtMinimaCompraPedidoProduto', headerName: 'Quantidade Mínima', minWidth: 80 },
            { field: 'qtProduto', headerName: 'Quantidade Pedido', minWidth: 80 },
            { field: 'qtProdutoFaturado', headerName: 'Quantidade Faturado', minWidth: 80, sortable: false },
            {
                field: 'vlTotalFaturado',
                headerName: 'Total Faturado',
                type: 'number',
                align: 'right',
                sortable: false,
                valueFormatter: ({ value }) => (isNumber(value) ? brlPrice(value) : ''),
                width: 130,
                minWidth: 110,
            },
        ],
        [],
    );

    return (
        <React.Fragment>
            <SubTitle label={'Lista de Produtos'} sx={{ ml: 2, mt: 2, mb: 2 }} />

            <Box sx={{ ml: 2, mr: 2, mb: 2 }}>
                <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} loading={fetchStatus === 'fetching'} reload={refetch} columns={columns} />
            </Box>
        </React.Fragment>
    );
}
