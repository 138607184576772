import { useLocation } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

type BannerData = {
    idBanner: number;
    dsLinkArquivo: string;
};

export const useBannerData = (): BannerData[] => {
    const RequestListagem = useRequestListagem();
    const { pathname } = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const path = pathname.split('/').filter((p) => p !== '');

    const deviceType = isMobile ? 'MOBILE' : 'DESKTOP';

    const requestOptions: RequestOptionsType = {
        url: '/gestao/banner/modulo',
        sort: [{ property: 'nrOrdem', direction: 'ASC' }],
        extraParams: [{ dsPathModulo: path[0] || 'bi' }, { tipoDispositivo: deviceType }],
    };

    const { data } = useQuery([requestOptions], () => RequestListagem(requestOptions).then((data) => data.data));

    return data;
};
