import React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

export function Row({ sx, ...props }: GridProps): JSX.Element {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                mb: 2,
                flexDirection: {
                    xs: 'column',
                    lg: 'row',
                },
                ...sx,
            }}
            {...props}
        />
    );
}
