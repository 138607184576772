import React from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button } from '@mui/material';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro';

import { CustomFooterSummary, CustomFooterSummaryProps } from 'components/grid/components/CustomFooterSummary';
interface CustomFooterProps extends Partial<CustomFooterSummaryProps> {
    refresh?: () => void;
    hideFooter?: boolean;
}

export function CustomFooter({ refresh, summary = [], summaryPrefix, hideFooter }: CustomFooterProps): JSX.Element | null {
    if (hideFooter) {
        return null;
    }

    return (
        <GridFooterContainer
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            }}
        >
            {Boolean(summary?.length) && <CustomFooterSummary summary={summary} summaryPrefix={summaryPrefix} />}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                {refresh && (
                    <Button onClick={refresh} color="inherit" size="small" sx={{ textTransform: 'none', fontWeight: 'normal', fontSize: '0.875rem' }} startIcon={<RefreshIcon fontSize="small" />}>
                        Recarregar dados
                    </Button>
                )}

                <GridFooter sx={{ border: 'none' }} />
            </Box>
        </GridFooterContainer>
    );
}
