import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

export type TituloContextData = {
    hasTitulos: boolean;
    setHasTitulos: Dispatch<SetStateAction<boolean>>;
};

type TituloProvider = {
    children?: ReactNode;
}

const TituloContext = createContext<TituloContextData>({} as TituloContextData);

export function TituloProvider({ children }: TituloProvider): JSX.Element {
    const [hasTitulos, setHasTitulos] = useState(false);

    const contextValue = useMemo(
        () => ({
            hasTitulos,
            setHasTitulos
        }),
        [hasTitulos],
    );

    return <TituloContext.Provider value={contextValue}>{children}</TituloContext.Provider>;
}

export const useTituloContext = (): TituloContextData => useContext(TituloContext);
