import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useListagemContext } from 'context/ListagemContext';
import { brlDate, brlPrice } from 'util/format';
import { OneColumn, Block } from 'components/page';
import { PanelGrid } from 'components/grid';
import { RequestFinanceiroListModelProps } from 'pages/financeiro/nota/types';
import { FormFilterNota } from '..';
import { Box, Stack, Typography } from '@mui/material';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { ExportarRelatorioBtn } from 'pages/financeiro/nota/components/atoms/ExportarRelatorioBtn';
import { usePermissionsContext } from 'context/PermissionsContext';

const generateMovimentacaoFiscalList = (data: RequestFinanceiroListModelProps | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.nrNota,
            nrNota: `${item.nrNota}/${item.dsSerie}`,
            dhMovimentacao: item.dhMovimentacao,
            loja: `${item.idLoja} - ${item.dsNomeFantasia}`,
            dsOperacao: item.dsOperacao,
            vlTotalLiquido: item.vlTotalLiquido,
            vlTotalBruto: item.vlTotalBruto,
            idMovimentacaoFiscal: item.idMovimentacaoFiscal,
        }));
    }

    return [];
};

export function MovimentacaoListComp(): JSX.Element {
    const { filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = {
        url: '/fiscal/movimentacao',
        columns: 'idMovimentacaoFiscal',
        filter,
    };

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestFinanceiroListModelProps> = RequestListagem(requestOptions).then((res) => res.data.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const columns: GridColDef[] = [
        { field: 'nrNota', headerName: 'N° da Nota / Série', width: 130, minWidth: 120 },
        { field: 'dhMovimentacao', headerName: 'Emissão', width: 100, minWidth: 90, valueFormatter: ({ value }) => brlDate(value) },
        { field: 'loja', headerName: 'Loja', flex: 1, minWidth: 150 },
        { field: 'dsOperacao', headerName: 'Tipo da Nota', width: 100, minWidth: 90 },
        { field: 'vlTotalLiquido', headerName: 'Valor sem Impostos', type: 'number', width: 160, minWidth: 130, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'vlTotalBruto', headerName: 'Valor com Impostos', type: 'number', width: 160, minWidth: 130, valueFormatter: ({ value }) => brlPrice(value) },
    ];

    const rows = generateMovimentacaoFiscalList(data);

    return (
        <OneColumn title="Consulta de Notas Fiscais">
            <FormFilterNota />

            {hasPermission(['FINANCEIRO_NOTA_FISCAL_EXPORTAR']) && (
                <Block>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Box sx={{ flex: 1, display: 'inherit', alignItems: 'inherit' }}>
                            <TableViewOutlinedIcon color="primary" />

                            <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                            <ChevronRightOutlinedIcon color="primary" />
                        </Box>

                        <ExportarRelatorioBtn disabled={!rows.length} extension={'xlsx'} label={'Relatório em .xlsx'} sx={{ color: 'green', borderColor: 'green' }} />
                    </Stack>
                </Block>
            )}
            <Block>
                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} summary={['vlTotalLiquido', 'vlTotalBruto']} />
            </Block>
        </OneColumn>
    );
}
