import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Typography, Box } from '@mui/material';

import { Fit } from 'components/grid';

/**
 * Pagina resposta ao codigo http 404. Quando a rota não leva a lugar nenhum.
 */
export function Page404DetailComp(): JSX.Element {
    const navigate = useNavigate();

    return (
        <Fit direction="column" sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 3,
                }}
            >
                <Typography variant="h1">404</Typography>

                <Typography variant="h4">Página não encontrada.</Typography>

                <Box
                    sx={{
                        display: 'flex',
                        mt: 3,
                    }}
                >
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Página Inicial
                    </Button>

                    <Button
                        variant="contained"
                        sx={{ ml: 3 }}
                        type="button"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        Voltar
                    </Button>
                </Box>
            </Box>
        </Fit>
    );
}
