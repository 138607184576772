import React, { useRef } from 'react';

import { Box, Tooltip } from '@mui/material';
import { GridAlignment, GridValidRowModel, GridValueFormatterParams, useGridApiContext, useGridApiEventHandler } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export interface CustomFooterSummaryProps {
    summary: string[];
    summaryPrefix?: string | boolean;
}

type GetValue = CustomFooterSummaryProps & {
    field: string;
    valueFormatter?: (params: GridValueFormatterParams<any>) => any;
    rows: GridValidRowModel[];
};

type Column = {
    field: string;
    width?: number;
    minWidth?: number;
    flex?: number;
    align?: GridAlignment;
    value: JSX.Element | null;
};

type GetColumns = CustomFooterSummaryProps &
    Pick<GetValue, 'rows'> & {
        gridRef: GridApiPro;
    };

const sumRowsColumn = ({ rows, field }: Pick<GetValue, 'rows' | 'field'>): number =>
    rows.reduce((cc, item) => {
        return cc + item[field];
    }, 0);

const validateColumn = ({ rows, field, summary }: Pick<GetValue, 'rows' | 'field' | 'summary'>): boolean => {
    const found = summary.includes(field);
    const valueType = typeof rows[0][field];
    const validValue = valueType === 'number';

    if (found && !validValue) {
        console.warn(`O tipo do valor (${valueType}) no field ${field} não pode ser usado em um sumário.`);
    }

    return found && validValue;
};

const getValue = ({ field, valueFormatter, summaryPrefix, rows }: GetValue): JSX.Element => {
    const sum = sumRowsColumn({ rows, field });
    const total = typeof valueFormatter === 'function' ? valueFormatter({ field, value: sum, api: null }) : sum;
    const prefix = typeof summaryPrefix === 'boolean' && !summaryPrefix ? '' : `${summaryPrefix} `;

    return (
        <Tooltip title={`${prefix}${total}`}>
            <Box
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {Boolean(prefix) && <strong>{prefix}</strong>}
                {total}
            </Box>
        </Tooltip>
    );
};

const getColumns = ({ gridRef, summaryPrefix, summary, rows }: GetColumns): Column[] => {
    const gridColumns = gridRef.getVisibleColumns();

    return gridColumns.map((column) => {
        const { field, width, minWidth, flex, align, valueFormatter } = column;
        const value = validateColumn({ rows, field, summary }) ? getValue({ field, valueFormatter, summaryPrefix, summary, rows }) : null;

        return {
            field,
            width,
            minWidth,
            flex: flex || undefined,
            align,
            value,
        };
    });
};

export function CustomFooterSummary({ summary, summaryPrefix = 'Total:' }: CustomFooterSummaryProps): JSX.Element | null {
    const apiRef = useGridApiContext();
    const gridRef = apiRef.current;
    const visibleRows = gridRef.getVisibleRowModels();
    const rows: GridValidRowModel[] = [];
    const ref = useRef<HTMLDivElement>(null);

    let columns: Column[] = [];

    // Rola o scroll horizontal da footer de acordo com o scroll da grid
    useGridApiEventHandler(apiRef, 'virtualScrollerWheel', () => ref?.current?.scrollTo({ left: gridRef.windowRef?.current?.scrollLeft }));

    // O getVisibleRowModels retorna um Map, desse modo fizemos um forEach inserindo num outro array, pois não há o .map().
    visibleRows.forEach((value) => rows.push(value));

    if (rows.length) {
        columns = getColumns({ gridRef, summaryPrefix, summary, rows });
    }

    if (!columns.length) {
        return null;
    }

    return (
        <Box ref={ref} sx={{ overflow: 'hidden' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    minHeight: 56,
                    borderBottom: 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: 'grey.300',
                    alignItems: 'center',
                    backgroundColor: 'grey.300',
                    width: gridRef.windowRef?.current?.scrollWidth,
                }}
            >
                {columns.map((column) => {
                    const { field, width, minWidth, flex, align: justifyContent, value } = column;

                    return (
                        <Box key={field} sx={{ px: 1.25, display: 'flex', minWidth, width, flex, justifyContent }}>
                            {value}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}
