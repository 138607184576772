import { format } from 'date-fns';

export const brlList = (value: string | string[]): string => {
    const listFormatter = new Intl.ListFormat('pt-BR', { style: 'long', type: 'conjunction' });

    if (Array.isArray(value)) {
        return listFormatter.format(value);
    }

    return value;
};

export const brlPrice = (value: number, minDecimal?: number, maxDecimal?: number): string => {
    const config: Intl.NumberFormatOptions = { style: 'currency', currency: 'BRL' };

    if (typeof minDecimal === 'number') {
        config.minimumFractionDigits = minDecimal;
    }

    if (typeof maxDecimal === 'number') {
        config.maximumFractionDigits = maxDecimal;
    }

    return new Intl.NumberFormat('pt-BR', config).format(value);
};

export const brlDecimal = (value: number, minDecimal?: number, maxDecimal?: number): string => {
    const config: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    if (typeof minDecimal === 'number') {
        config.minimumFractionDigits = minDecimal;
    }

    if (typeof maxDecimal === 'number') {
        config.maximumFractionDigits = maxDecimal;
    }

    return new Intl.NumberFormat('pt-BR', config).format(value);
};

export const brlDate = (value: Date | string): string => {
    return format(new Date(value), 'dd/MM/yyyy');
};

export const brlDateTime = (value: Date | string): string => {
    return format(new Date(value), 'dd/MM/yyyy HH:mm');
};

export const brlPercentual = (value: number | string): string => {
    return `${Number(value)}%`;
};

export const bytesToMb = (value: number): number => {
    return value / 1024 ** 2;
};

export const urlFriendlyFormat = (value: string): string => {
    return value
        .normalize('NFD')
        .replace(/[^\w\s]/g, '')
        .replaceAll(' ', '-')
        .toLowerCase();
};

export const brlCnpj = (value: string | number): string => {
    const str = typeof value === 'number' ? value.toString() : value;

    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export const brlCpf = (value: string | number): string => {
    const str = typeof value === 'number' ? value.toString() : value;

    return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const brlPhone = (value: string | number): string => {
    const str = typeof value === 'number' ? value.toString() : value;
    const justNumbers = str.replace(/\D/g, '').slice(0, 11);

    return justNumbers.replace(/^\s*(\d{2})[-. ]?(\d{4,5}|\d{4})?[-. ]?(\d{4})?[-. ]?\s*$/, '($1) $2 $3');
};

export const brlCep = (value: string | number): string => {
    const str = typeof value === 'number' ? value.toString() : value;

    return str.replace(/^([\d]{2})\.*([\d]{3})-*([\d]{1,3})/, '$1$2-$3');
};

export const camelCase = (value: string, minChars?: number): string => {
    const breakPoint = minChars ?? 3;
    const positions: string[] = [];

    // tudo para minuscula primeiro
    let newValue = value.toLowerCase();

    // retiro o que for tag html e salvo sua referencia com ponteiro
    newValue = newValue.replace(/<[^>]*>/gi, (txt) => {
        positions.push(txt);

        return `{{${positions.length - 1}}}`;
    });

    // faço a transformação camelCase
    newValue = newValue.replace(/[A-zÀ-ú]*/gi, (txt) => (txt.length > breakPoint ? txt.charAt(0).toUpperCase() + txt.substr(1) : txt));

    // retorno as tags html para suas posições
    newValue = newValue.replace(/{{[\d*]}}/g, (txt) => {
        const code = Number(txt.replace(/\D/g, ''));

        return positions[code] || txt;
    });

    return newValue;
};

export const removeHtmlTags = (value: string): string => {
    return value.replace(/(&nbsp;|<([^>]+)>)/gi, '');
};

export const clearArray = <T>(values: T[]): T[] => {
    return values.filter((item) => item);
};

export const uniqueArray = <T>(values: T[]): T[] => {
    return values.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

// Formata a data recebida para ser exibida corretamente no campo de data da grid
export const formatDateHour = (date: Date | string): string => {
    if (date && typeof date !== 'string') {
        // Caso estiver formatado como um Date
        return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");
    } else if (date && date.length === 10) {
        // Caso não tenha o horário
        return `${date}T00:00:00`;
    }

    return date;
};
