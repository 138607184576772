import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, SxProps } from '@mui/material';

interface NewBtnProps {
    sx?: SxProps;
}

/**
 * Estrutura padrão do sistema para página do tipo 1 coluna (oneColumn) (sem aside)
 * Contém header + titulo + conteudo + footer
 */
export function NewBtn({ sx }: NewBtnProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <Button variant="contained" onClick={() => navigate('form')} sx={{ ...sx, flex: 'auto' }}>
            + Novo
        </Button>
    );
}
