import React, { useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { Alert } from '@mui/material';

import { TabuledGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { useValidation } from 'hooks/useValidation';
import { DreRelatorioGridAnalitico, DreRelatorioGridConsolidado } from 'pages/financeiro/dre/relatorio/components/';

const generateCategoryTitle = (categoria: string): string => {
    const title = categoria === 'sintetico' ? 'Sintético' : 'Analítico';

    return `Relatório de Estimativa de Resultado ${title}`;
};

export function DreRelatorioComp(): JSX.Element {
    const [searchParams] = useSearchParams();
    const { setAutoLoad } = useListagemContext();
    const { categoria } = useParams();
    const nrAno = searchParams.get('nrAno');
    const idLoja = searchParams.getAll('idLoja');
    const dsLoja = searchParams.get('dsLoja');
    const { validRouterParams } = useValidation();
    const validCategorias = ['sintetico', 'analitico'];
    const validSearchParams = nrAno && dsLoja && idLoja;
    const validParams = validSearchParams && categoria && validRouterParams({ categoria }) && validCategorias.includes(categoria);

    // para normalizar mensagem de dados vazios nas grids
    useEffect(() => {
        setAutoLoad(true);
    }, [setAutoLoad]);

    if (validParams) {
        return (
            <OneColumn title={generateCategoryTitle(categoria)} goBackButton>
                <Block>
                    <TabuledGrid
                        leftColumnWidth={140}
                        list={[
                            { label: <strong>Nome</strong>, value: dsLoja },
                            { label: <strong>Ano de Referência</strong>, value: nrAno },
                        ]}
                    />
                </Block>

                <DreRelatorioGridConsolidado idLoja={idLoja} nrAno={nrAno} />

                <DreRelatorioGridAnalitico idLoja={idLoja} nrAno={nrAno} categoria={categoria} />
            </OneColumn>
        );
    }

    return (
        <OneColumn title="Relatório de Estimativa de Resultado">
            <Alert severity="info">A URL informada é inválida, e não contêm todos os parâmetros necessários para gerarmos o relatório de estimativa de resultado.</Alert>
        </OneColumn>
    );
}
