import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Typography } from '@mui/material';

export function DreFormBbarInfo(): JSX.Element {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
            }}
        >
            <SaveOutlinedIcon color="primary" />

            <Typography sx={{ mx: 2 }}>
                SALVE SEU PREENCHIMENTO
                <br />A QUALQUER MOMENTO
            </Typography>

            <ChevronRightOutlinedIcon color="primary" />
        </Box>
    );
}
