import React from 'react';

import { useParams } from 'react-router-dom';

import { Button, Grid } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { ListagemProvider } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { GrupoEmailsDestinatariosGrid } from 'pages/complementar/grupo-emails/components';
import { FormikGrupoEmailsDestinatariosProps, initialValues, RequestGrupoEmailProps } from 'pages/complementar/grupo-emails/types';

export function GrupoEmailsFormComp(): JSX.Element {
    const { mutate } = useFormMutation();
    const queryClient = useQueryClient();
    const { id } = useParams();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = { url: `/gestao/grupoemail/${id}` };
    const { data } = useQuery([requestOptions], (): Promise<RequestGrupoEmailProps> => RequestListagem(requestOptions).then((res) => res.data));

    const handleSubmit = (formData: FormikGrupoEmailsDestinatariosProps, formik: FormikHelpers<FormikGrupoEmailsDestinatariosProps>): void => {
        mutate(
            {
                url: 'gestao/grupoemaildestinatario',
                formik,
                formData,
            },
            {
                onSuccess: () => {
                    formik.resetForm();

                    queryClient.invalidateQueries(['getListDestinatarios']);
                },
            },
        );
    };

    const validationSchema = Yup.object({
        dsNomeDestinatario: Yup.string().required().min(2),
        dsEmailDestinatario: Yup.string().required().min(2),
    });

    return (
        <FormPage
            disableQuery
            title={`Grupo de E-mails - ${data ? data?.data?.dsGrupoEmail : ''}`}
            values={initialValues(Number(id))}
            hideBbar
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            goBackButton
        >
            {({ values, errors, submitForm, setFieldValue }: FormikProps<FormikGrupoEmailsDestinatariosProps>) => {
                return (
                    <React.Fragment>
                        <Block>
                            <Row sx={{ mb: 0 }}>
                                <Grid item flex={5.5}>
                                    <CissTextField
                                        value={values.dsNomeDestinatario}
                                        label="Descrição do destinatário"
                                        name="dsNomeDestinatario"
                                        error={errors.dsNomeDestinatario}
                                        onChange={(e) => setFieldValue('dsNomeDestinatario', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={5.5}>
                                    <CissTextField
                                        value={values.dsEmailDestinatario}
                                        label="E-mail do destinatário"
                                        name="dsEmailDestinatario"
                                        error={errors.dsEmailDestinatario}
                                        onChange={(e) => setFieldValue('dsEmailDestinatario', e.target.value)}
                                    />
                                </Grid>

                                <Grid item flex={1}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submitForm}
                                        sx={{
                                            width: '100%',
                                            mt: {
                                                lg: 1.2,
                                                xs: 0,
                                            },
                                        }}
                                    >
                                        Adicionar
                                    </Button>
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <SubTitle label={'E-mails'} />

                            <ListagemProvider>
                                <GrupoEmailsDestinatariosGrid />
                            </ListagemProvider>
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
