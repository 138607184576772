import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { ConfirmModal } from 'components/modal';
import { ProdutoImportarEdicaoModal } from 'pages/compra/produto/components/templates/ProdutoImportarEdicaoModal';
import { AzureUploadFileModal } from 'pages/compra/produto/types';

type UploadPreviewGrid = {
    files: AzureUploadFileModal[];
    setFiles: Dispatch<SetStateAction<AzureUploadFileModal[]>>;
    loading: boolean;
};

const getRows = (files: AzureUploadFileModal[]): GridRowsProp => {
    if (files.length) {
        return files.map((file) => ({
            id: file.id,
            dsLink: file.arquivo.dsLink,
            dsTitulo: file.arquivo.dsTitulo,
        }));
    }

    return [];
};

export function ProdutoImportarGrid({ files, setFiles, loading }: UploadPreviewGrid): JSX.Element {
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean | number>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean | number>(false);
    const name = useRef<string>('');

    const removeFile = (): void => setFiles((oldFiles) => oldFiles.filter((file) => file.id !== openRemoveModal));

    const changeFileName = (idFile: number, newDsTitulo: string): void => {
        setFiles((oldFiles) =>
            oldFiles.map((file) => {
                if (file.id === idFile) {
                    file.arquivo.dsTitulo = newDsTitulo;
                }

                return file;
            }),
        );
    };

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'dsLink',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Imagem',
                minWidth: 70,
                sortable: false,
                renderCell: ({ row }) => {
                    if (row.dsLink) {
                        return (
                            <Box
                                sx={{
                                    minWidth: 50,
                                    minHeight: 50,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundImage: `url("${row.dsLink}")`,
                                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: 1,
                                }}
                            />
                        );
                    }
                },
            },
            {
                field: 'dsTitulo',
                headerName: 'Nome',
                minWidth: 70,
                flex: 1,
            },
            {
                field: 'acoes',
                headerName: 'Ações',
                headerAlign: 'center',
                align: 'center',
                minWidth: 90,
                width: 90,
                sortable: false,
                renderCell: ({ row }) => (
                    <Box sx={{ display: 'flex' }}>
                        <Tooltip title="Editar nome">
                            <IconButton
                                disabled={loading}
                                onClick={() => {
                                    setOpenEditModal(row.id);

                                    name.current = row.dsTitulo;
                                }}
                            >
                                <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Excluir imagem">
                            <IconButton onClick={() => setOpenRemoveModal(row.id)} disabled={loading}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ),
            },
        ],
        [loading],
    );

    return (
        <React.Fragment>
            <ConfirmModal open={Boolean(openRemoveModal)} setOpen={setOpenRemoveModal} confirm={removeFile} />

            <ProdutoImportarEdicaoModal open={openEditModal} setOpen={setOpenEditModal} name={name.current} changeFileName={changeFileName} />
            <Box sx={{ height: 300 }}>
                <PanelGrid
                    columns={columns}
                    rows={getRows(files)}
                    rowsCount={files.length}
                    loading={loading}
                    remote={false}
                    reload={false}
                    autoHeight={false}
                    customMessageNoRows="Nenhum arquivo informado"
                />
            </Box>
        </React.Fragment>
    );
}
