import React from 'react';

import { Button, ButtonProps } from '@mui/material';

type TinyButtonProps = ButtonProps;

export function TinyButton({ children, sx, ...props }: TinyButtonProps): JSX.Element {
    return (
        <Button variant="contained" size="small" fullWidth={false} sx={{ px: 1, py: 0, minWidth: 22, ...sx }} {...props}>
            {children}
        </Button>
    );
}
