import React, { useState } from 'react';

import { Box, Button, Typography, Grid } from '@mui/material';

import { AzureUploadPreview } from 'components/azureUpload/components/AzureUploadPreview';
import { AzureUploadProps, CallbackUploadModalProps } from 'components/azureUpload/types';
import { ConfirmModal } from 'components/modal';
import { UploadModal } from 'components/upload/components';
import { MultiUploadModal } from 'components/upload/components/MultiUploadModal';
import { useAzureUpload } from 'hooks/useAzureUpload';
import { brlList } from 'util/format';

export function AzureUpload({ files, onChange, accept = '*', removeAll = true, replaceFile = false, error, disabled, multipleUploads = false, id }: AzureUploadProps): JSX.Element {
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false);
    const [openConfirmReplaceModal, setOpenConfirmReplaceModal] = useState(false);
    const { handleMultipleUploadFile, handleUploadFile, isLoading } = useAzureUpload();

    const activeFiles = files.filter((f) => f.fgExcluido === false);

    const handleModalConfirm = ({ file, dsTitulo }: CallbackUploadModalProps): void => {
        handleUploadFile({ file, dsTitulo }).then((newFile) => {
            if (replaceFile) {
                onChange([newFile]);
            } else {
                onChange([...files, newFile]);
            }

            setOpenUploadModal(false);
        });
    };

    const handleMultipleModalConfirm = (newFiles: CallbackUploadModalProps[]): void => {
        handleMultipleUploadFile({ files: newFiles }).then((newFiles) => {
            onChange([...files, ...newFiles]);

            setOpenUploadModal(false);
        });
    };

    return (
        <React.Fragment>
            <Grid container gap={2}>
                <Box>
                    <Button id={id} variant="contained" onClick={() => setOpenUploadModal(true)} disabled={disabled}>
                        <Typography>Selecionar arquivo</Typography>
                    </Button>

                    {error && (
                        <Typography
                            sx={{
                                color: (theme) => theme.palette.error.light,
                                fontSize: 11,
                                mt: 1,
                                ml: 1,
                            }}
                        >
                            {error}
                        </Typography>
                    )}
                </Box>

                {removeAll && Boolean(activeFiles.length) && (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setOpenConfirmRemoveModal(true);
                        }}
                        sx={{ maxHeight: 33 }}
                    >
                        <Typography>Remover todos arquivos</Typography>
                    </Button>
                )}
            </Grid>

            <Typography
                variant="caption"
                sx={{
                    display: 'block',
                    mt: 1,
                }}
            >
                <strong>EXTENSÕES ACEITAS:</strong> {brlList(accept)}
            </Typography>

            {activeFiles.map((file, index) => (
                <AzureUploadPreview
                    key={index}
                    file={file}
                    onDelete={() => {
                        const newFiles = files.map((f) => {
                            if (f.arquivo.dsLink === file.arquivo.dsLink) {
                                return { ...f, fgExcluido: true };
                            } else {
                                return f;
                            }
                        });

                        onChange(newFiles);
                    }}
                    sx={{ mt: 2 }}
                />
            ))}

            {!multipleUploads ? (
                <UploadModal open={openUploadModal} setOpen={setOpenUploadModal} callback={handleModalConfirm} loading={isLoading} accept={accept} />
            ) : (
                <MultiUploadModal open={openUploadModal} setOpen={setOpenUploadModal} callback={handleMultipleModalConfirm} accept={accept} />
            )}

            <ConfirmModal
                open={openConfirmRemoveModal}
                setOpen={setOpenConfirmRemoveModal}
                confirm={() => {
                    const newFiles = files.map((file) => ({ ...file, fgExcluido: true }));
                    onChange(newFiles);
                }}
                text="Tem certeza que deseja remover todos os arquivos?"
            />

            {replaceFile && (
                <ConfirmModal
                    open={openConfirmReplaceModal}
                    setOpen={setOpenConfirmReplaceModal}
                    confirm={() => setOpenUploadModal(true)}
                    text="Tem certeza que deseja substituir o arquivo selecionado?"
                />
            )}
        </React.Fragment>
    );
}
