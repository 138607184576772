import React from 'react';

import { Link } from '@mui/material';
import * as dateFns from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { TabuledGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { camelCase } from 'util/format';
import { translateKey } from 'util/i18n';

const generateArquivoInfoList = (content: any): any => {
    const rows: any = [];
    const prefix = 'arquivo_info_';

    const keys = ['dsAuthor', 'dtCadastro', 'lkArquivo', 'qtDownload'];

    Object.entries(content).forEach(([key, value]: any) => {
        if (keys.includes(key)) {
            const info: { label: string; value: JSX.Element | string } = {
                label: translateKey(prefix + key),
                value: <strong>{value}</strong>,
            };

            if (key.startsWith('lk') && value) {
                info.value = (
                    <Link href={value as string} target="_blank">
                        {value as string}
                    </Link>
                );
            }

            if (key.startsWith('dt') && value) {
                info.value = camelCase(dateFns.format(new Date(value), "dd 'de' MMMM 'de' yyyy, HH:mm'hrs'", { locale: ptBR }));
            }

            rows.push(info);
        }
    });

    return rows;
};

export function ArquivoFormInfo({ content }: any): JSX.Element {
    const rows = generateArquivoInfoList(content);

    return (
        <Block>
            <SubTitle label="Detalhes do Arquivo" />

            <TabuledGrid leftColumnWidth={175} list={rows} />
        </Block>
    );
}
