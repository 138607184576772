import React from 'react';

import { Imagem } from 'components/images';
import { useImage } from 'hooks/useImage';

import { useDomainConfigContext } from 'context/DomainConfig';

export const Logo = (): JSX.Element => {
    const { data, subdomain } = useDomainConfigContext();
    const { loading, image } = useImage(`/config/${subdomain}/${data.logo}`);

    return <React.Fragment>{!loading && <Imagem src={image} alt={process.env.REACT_APP_WEBSITE_NAME} />}</React.Fragment>;
};
