import React, { useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, IconButton, SxProps, Tooltip } from '@mui/material';

interface EditBtnProps {
    onClick: () => void;
    disabled?: boolean;
    tooltipText?: string;
    sx?: SxProps;
}

export function EditBtn({ onClick = () => {}, disabled = false, tooltipText, sx }: EditBtnProps): JSX.Element {
    return (
        <Tooltip title={tooltipText || 'Editar'}>
            <Box sx={sx}>
                <IconButton sx={{ p: 0, ml: 0 }} onClick={() => onClick()} disabled={disabled}>
                    <ModeEditOutlineOutlinedIcon />
                </IconButton>
            </Box>
        </Tooltip>
    );
}
