import React, { useCallback, useState } from 'react';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

import { AzureUploadFileArquivoProps } from 'components/azureUpload/types';
import { UploadModal } from 'components/upload/components';
import { CallbackUploadModalProps } from 'components/upload/types';
import { useAzureBlob } from 'hooks/useAzureBlob';
import { ArquivoDownload } from 'pages/marketing-trade/types';
import { editableFormats } from 'pages/marketing-trade/util/file';

type MarketingTradeAlterarImagemBtn = {
    changeArquivoOnState: () => void;
    arquivoDownload: ArquivoDownload;
};

export function MarketingTradeAlterarImagemBtn({ changeArquivoOnState, arquivoDownload }: MarketingTradeAlterarImagemBtn): JSX.Element {
    const { uploadFile } = useAzureBlob();
    const { enqueueSnackbar } = useSnackbar();

    const [openModalImagem, setOpenModalImagem] = useState<boolean>(false);
    const [isLoadingImagem, setIsLoadingImagem] = useState<boolean>(false);

    const handleModalImagem = useCallback(
        ({ dsTitulo, file }: CallbackUploadModalProps): undefined => {
            if (!file) return;

            setIsLoadingImagem(true);

            uploadFile(file)
                .then((value) => {
                    if (value) {
                        const { file, uploadUrl } = value;

                        const link = uploadUrl;
                        const hash = uploadUrl.split('/').pop() || '';
                        const extensao = file.name.split('.').pop() || '';

                        const arquivo: AzureUploadFileArquivoProps = {
                            dsExtensao: extensao,
                            dsArquivo: file.name,
                            dsLink: link,
                            dsLinkThumbnail: editableFormats.includes(extensao) ? link : undefined,
                            dsHash: hash,
                            dsTitulo: dsTitulo,
                            dsMimeType: file.type,
                        };

                        arquivoDownload.arquivo = arquivo;

                        changeArquivoOnState();
                    }
                })
                .catch(() => enqueueSnackbar('Falha no upload da imagem, tente novamente.', { variant: 'error' }))
                .finally(() => {
                    setIsLoadingImagem(false);
                    setOpenModalImagem(false);
                });
        },
        [arquivoDownload],
    );

    return (
        <React.Fragment>
            <UploadModal open={openModalImagem} setOpen={setOpenModalImagem} callback={handleModalImagem} loading={isLoadingImagem} accept={editableFormats} />

            <Tooltip title="Alterar imagem">
                <IconButton onClick={() => setOpenModalImagem(true)}>
                    <AddPhotoAlternateOutlinedIcon fontSize="large" sx={{ color: 'primary.main' }} />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}
