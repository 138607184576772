import React from 'react';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { CissTextField } from 'components/fields/';

interface DatePickerYearProps extends Omit<DatePickerProps<Date, Date>, 'views' | 'label' | 'renderInput'> {
    label?: string;
}

/**
 * Componente data Ano com props pré-definidas
 */
export function DatePickerYear({ label = 'Ano', ...props }: DatePickerYearProps): JSX.Element {
    return <DatePicker views={['year']} label={label} inputFormat="yyyy" renderInput={(params) => <CissTextField {...params} autoComplete="off" />} {...props} />;
}
