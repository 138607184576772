import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconButton, Tooltip } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useFormMutation } from 'mutations/useFormMutation';
import { StateNotaDevolucao } from 'pages/financeiro/nota-devolucao/types';
import { ConfirmModal } from 'components/modal';
import { useShowError } from 'hooks/useShowError';

interface NotaDevolucaoListActionDeleteProps {
    row: GridRowModel<any>;
    reload: () => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
    hidden: boolean;
}

export function NotaDevolucaoListActionDelete({ row, reload, setLoading, hidden }: NotaDevolucaoListActionDeleteProps): JSX.Element {
    const { mutate } = useFormMutation();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [searchParams] = useSearchParams();
    const { showError } = useShowError();

    const handleClick = useCallback((): void => {
        setOpenConfirmModal(true);
    }, []);

    const mutateCancelNF = useCallback((): void => {
        const { cdLoja } = JSON.parse(searchParams.get('cdCliente') || '{}');

        setLoading(true);

        mutate(
            {
                url: '/fiscal/movimentacaosimulacaodevolucao/cancela',
                method: 'PUT',
                formData: {
                    nrOrdemVenda: row.nrOrdemVenda,
                    cdLoja,
                },
            },
            {
                onSuccess: () => reload(),
                onSettled: () => setLoading(false),
                onError: (error: unknown) => showError(error, 'Ocorreu um erro ao cancelar sua solicitação de devolução, tente novamente.'),
            },
        );
    }, [mutate, reload, row.nrOrdemVenda, searchParams, setLoading, showError]);

    return (
        <React.Fragment>
            <Tooltip title={row.tpStatus !== StateNotaDevolucao.CANCELADA ? 'Cancelar Solicitação de Nota Devolução' : ''} hidden={hidden}>
                <span>
                    <IconButton sx={{ p: 0, minWidth: 'auto', ml: 2 }} disabled={row.tpStatus === StateNotaDevolucao.CANCELADA} onClick={handleClick} color="error">
                        <DeleteOutlineIcon />
                    </IconButton>
                </span>
            </Tooltip>

            <ConfirmModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                confirm={mutateCancelNF}
                text={`Tem certeza que deseja CANCELAR a solicitação de cancelamento da NF ${row.nrOrdemVenda}?`}
            />
        </React.Fragment>
    );
}
