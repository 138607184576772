import React from 'react';

import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import { CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { Block } from 'components/page';
import { Promocao } from 'pages/revendedor/promocao/types';

export function PromocaoGeneralBlock(): JSX.Element {
    const { values, errors, setFieldValue } = useFormikContext<Promocao>();

    return (
        <Block>
            <Row>
                <Grid item flex={1}>
                    <CissTextField
                        label="Descrição"
                        name="nome"
                        error={errors.nome}
                        value={values.nome}
                        onChange={(e) => setFieldValue('nome', e.target.value)}
                    />
                </Grid>

                <Grid item flex={1}>
                    <CissTextField
                        label="Código da promoção"
                        name="codigo"
                        error={errors.codigo}
                        value={values.codigo}
                        onChange={(e) => setFieldValue('codigo', e.target.value)}
                    />
                </Grid>
            </Row>
        </Block>
    );
}
