import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography,Tooltip,Stack } from '@mui/material';

export function HeaderProdutosTooltip(): JSX.Element {
    return (
        <Tooltip
            title={
                <Typography>
                    Produtos da configuração com <br />
                    <strong>sugestão importada</strong> e o <strong>total de produtos</strong>
                </Typography>
            }
            placement="top"
        >
            <Stack direction="row" display="flex" alignItems="center" spacing={0.5}>
                <Typography fontWeight={500} fontSize="0.875rem">
                    Produtos
                </Typography>
                <InfoOutlinedIcon fontSize="small" />
            </Stack>
        </Tooltip>
    );
}