import React, { useRef } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IconButton, InputAdornment, SxProps } from '@mui/material';

import { CissTextField } from 'components/fields';

interface PesquisarBtnProps {
    label?: string;
    placeholder?: string;
    setValueFilter: (value: string) => void;
    sx?: SxProps;
}

export function ConfiguracaoPedidoCompraPesquisaField({ setValueFilter, label = 'Pesquisar', placeholder, sx }: PesquisarBtnProps): JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <CissTextField
            inputRef={inputRef}
            label={label}
            placeholder={placeholder}
            size="small"
            sx={{ width: { xs: '100%', md: '50%' }, ...sx }}
            deboundTime={500}
            onChange={(e) => setValueFilter(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();

                    if (inputRef.current) {
                        setValueFilter(inputRef.current.value);
                    }
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={() => {
                                if (inputRef.current) {
                                    setValueFilter(inputRef.current.value);
                                }
                            }}
                        >
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
