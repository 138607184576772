import React, { useCallback, useEffect, useMemo } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoLoteCompraSituacaoColumn } from 'pages/compra/pedido-lote/components';
import { PedidoLoteCompraProps, RequestPedidoLoteCompraProps } from 'pages/compra/pedido-lote/types';
import { brlDateTime } from 'util/format';

interface PedidoLoteCompraGridProps {
    setOpenModalErros: (action: boolean) => void;
    setIdPedidoImportacaoLoja: (id: number) => void;
}

const getRows = (data: PedidoLoteCompraProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            dsNomeFantasia: item.loja.dsNomeFantasia,
            idLoja: item.loja.idLoja,
            cdLoja: item.loja.cdLoja,
            dsStatusProcessamento: item.statusProcessamento.dsStatusProcessamento,
            idStatusProcessamento: item.statusProcessamento.idStatusProcessamento,
            dsConfigPedido: item.pedidoImportacao.configPedido.dsConfigPedido,
            idConfigPedido: item.pedidoImportacao.configPedido.idConfigPedido,
            dhInclusao: item.dhInclusao,
            idPedido: item.pedido?.idPedido,
            idPedidoImportacaoLoja: item.idPedidoImportacaoLoja,
        }));
    }

    return [];
};

export function PedidoLoteCompraGrid({ setOpenModalErros, setIdPedidoImportacaoLoja }: PedidoLoteCompraGridProps): JSX.Element {
    const { filter, limit, page, autoLoad, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/compra/pedidoimportacaoloja',
            columns: 'dsNomeFantasia,idLoja,cdLoja,dsStatusProcessamento,dhInclusao,idStatusProcessamento,dsConfigPedido,idConfigPedido,idPedido,idPedidoImportacaoLoja',
            sort: sort.length ? sort : [{ property: 'dhInclusao', direction: 'DESC' }],
            nested: true,
            filter,
            limit,
            page,
        }),
        [filter, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(['getListPedidosLote', requestOptions], (): Promise<RequestPedidoLoteCompraProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: autoLoad,
        cacheTime: 0,
    });

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'dhInclusao',
                headerName: 'Data da Importação',
                type: 'string',
                align: 'center',
                headerAlign: 'center',
                valueFormatter: ({ value }) => (value ? brlDateTime(`${value}`) : ''),
                minWidth: 130,
                flex: 2,
            },
            {
                field: 'idConfigPedido',
                align: 'left',
                headerAlign: 'left',
                headerName: 'Configuração',
                minWidth: 140,
                flex: 4,
                renderCell: ({ row }) => (
                    <Typography>
                        <strong>{row.idConfigPedido}</strong> - {row.dsConfigPedido}
                    </Typography>
                ),
            },
            {
                field: 'idLoja',
                align: 'left',
                headerAlign: 'left',
                headerName: 'Loja',
                minWidth: 140,
                flex: 3,
                renderCell: ({ row }) => (
                    <Typography>
                        <strong>{row.cdLoja}</strong> - {row.dsNomeFantasia}
                    </Typography>
                ),
            },
            {
                field: 'dsStatusProcessamento',
                headerName: 'Situação',
                headerAlign: 'center',
                minWidth: 170,
                flex: 2,
                renderCell: ({ row }) => (
                    <PedidoLoteCompraSituacaoColumn
                        idStatus={row.idStatusProcessamento}
                        title={row.dsStatusProcessamento}
                        setOpenModalErros={setOpenModalErros}
                        setIdPedidoImportacaoLoja={setIdPedidoImportacaoLoja}
                        idPedidoImportacaoLoja={row.idPedidoImportacaoLoja}
                    />
                ),
            },
            {
                field: 'pedido',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Pedido',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 80,
                flex: 1,
                renderCell: ({ row }) => {
                    const { idLoja, cdLoja, dsNomeFantasia, idConfigPedido, dsConfigPedido, idPedido } = row;

                    return (
                        <Tooltip title="Visualizar pedido">
                            <IconButton
                                onClick={() =>
                                    navigate({
                                        pathname: '/compra/pedido-realizado',
                                        search: createSearchParams({
                                            idPedido: JSON.stringify(idPedido),
                                            idLoja: JSON.stringify({ idLoja, cdLoja, dsNomeFantasia }),
                                            idConfigPedido: JSON.stringify({ idConfigPedido, dsConfigPedido }),
                                        }).toString(),
                                    })
                                }
                                disabled={row.idStatusProcessamento !== 3}
                            >
                                <ReceiptLongOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    );
                },
            },
        ],
        [navigate],
    );

    return (
        <Block>
            <PanelGrid rows={getRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
        </Block>
    );
}
