import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useRequestListagem } from 'hooks/useRequestListagem';
import { Grade } from 'pages/inicio/types';

export function useGetGrade(): UseQueryResult<Grade> {
    const requestListagem = useRequestListagem();
    const { value: lastToken, setValue: setValueLastToken } = useLocalStorage('lastToken');
    const { accessToken } = useGenericAuth();
    const { loja } = useAplicacaoDataContext();
    const { idLoja } = loja || {};
    const [lastTokenIdLoja, lastTokenAccessToken] = lastToken?.split(/_(.+)/) || [];
    const hasChangedToken = accessToken && idLoja && (Number(lastTokenIdLoja) !== idLoja || lastTokenAccessToken !== accessToken);

    const query = useQuery<Grade>(
        ['getGrade'],
        () =>
            requestListagem({
                url: '/gestao/grade/popup',
            }).then((res) => res?.data?.data),
        {
            enabled: false,
        },
    );

    if (hasChangedToken) {
        setValueLastToken(`${idLoja}_${accessToken}`);

        query.refetch();
    }

    return query;
}
