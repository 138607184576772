import React, { useMemo } from 'react';

import { Stack } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { FormFilterCentroCusto } from '..';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

const generateCentroCustoList = (data: any[] | undefined): GridRowsProp => {
    const list = data;

    if (Array.isArray(list) && list.length) {
        return list.map((item, index) => ({
            id: index + 1,
            idCentroCusto: item.idCentroCusto,
            dsCentroCusto: item.dsCentroCusto,
            cdCentroCusto: item.cdCentroCusto,
        }));
    }
    return [];
};

export function CentroCustoListComp(): JSX.Element {
    const { filter, page, sort, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/centrocusto',
        sort: sort && sort.length > 0 ? sort : [{ property: 'idCentroCusto', direction: 'DESC' }],
        columns: 'idCentroCusto,dsCentroCusto,cdCentroCusto,fgAtivo',
        nested: true,
        filter: [...filter, { property: 'fgAtivo', value: true, operator: 'eq' }],
        page,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], () => {
        const request: Promise<any> = RequestListagem(requestOptions).then((res: any) => res.data);
        return request;
    });

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'idCentroCusto', headerName: 'Código', alignItems: 'center', flex: 1, type: 'string' },
            { field: 'dsCentroCusto', headerName: 'Descrição', alignItems: 'center', flex: 1, type: 'string' },
            { field: 'cdCentroCusto', headerName: 'Código de Integração', alignItems: 'center', flex: 1, type: 'string' },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                renderCell: ({ row }) => (
                    <EditRemoveBtn id={row.idCentroCusto} showEditBtn={true} showRemoveBtn={true} removeUrl={`gestao/centrocusto/${row.idCentroCusto}`} sx={{ ml: 'auto', p: 1.5 }} refresh={refetch} />
                ),
            },
        ],
        [refetch],
    );

    const rows = generateCentroCustoList(data?.data);

    return (
        <OneColumn title="Centros de Custos" createButton>
            <FormFilterCentroCusto />

            <Block>
                <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
            </Block>
        </OneColumn>
    );
}
