import React, { useCallback } from 'react';

import { CissTextField } from 'components/fields';
import { CissTextFieldProps, CustomUpdateFieldValueProps } from 'components/fields/CissTextField';

export function CissPrimaryKeyField(props: CissTextFieldProps): JSX.Element {
    // custom update field value para primary key field somente mostra valores numericos e positivos
    const updateFieldValue = useCallback((value: string | number): CustomUpdateFieldValueProps => {
        let fieldValue = value.toString();

        if (isNaN(Number(fieldValue)) || Number(fieldValue) < 1) {
            fieldValue = '';
        }

        return {
            value: fieldValue,
            rawValue: value.toString(),
        };
    }, []);

    return <CissTextField label="Código" disabled customUpdateFieldValue={updateFieldValue} {...props} />;
}
