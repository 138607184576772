import React, { useEffect, useState } from 'react';

import { FormControlLabel, Switch } from '@mui/material';
import { useFormikContext } from 'formik';

import { Revendedor } from 'pages/revendedor/revendedor/types';

export function RevendedorAtivoToggle(): JSX.Element {
    const { values, setFieldValue } = useFormikContext<Revendedor>();
    const [switchChecked, setSwitchChecked] = useState<boolean>(true);

    const handleChangeStatus = (status: boolean): void => {
        setSwitchChecked(status);
        setFieldValue('status', status);
    };

    useEffect(() => {
        if (values.status !== switchChecked) {
            handleChangeStatus(Boolean(values.status));
        }
    }, [values.status]);

    return <FormControlLabel control={<Switch checked={switchChecked} onChange={(e, checked) => handleChangeStatus(checked)} />} label="Ativo" />;
}
