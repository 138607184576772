import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { StatusIntegracaoListComp } from 'pages/compra/pedido-totalizador/status-integracao/components';

export function StatusIntegracaoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPRA_PEDIDO_TOTALIZADOR_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <StatusIntegracaoListComp />
        </ListagemProvider>
    );
}
