import React from 'react';

import { Box, Typography, TypographyProps, BoxProps, CircularProgress } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface ViewBtnProps extends TypographyProps {
    views?: number;
    small?: boolean;
    labelPrefix?: string;
}

const getLabelDefeaultProps = (small: boolean): BoxProps => {
    let labelProps = {};

    if (small) {
        labelProps = {
            sx: {
                fontSize: '1rem',
                color: 'grey.500',
            },
        };
    }

    return labelProps;
};

export function ViewBtn({ sx, views, small = false, labelPrefix = '' }: ViewBtnProps): JSX.Element {
    const labelProps = getLabelDefeaultProps(small);

    return (
        <Typography variant="h6" component="span" sx={{ mr: 3, p: 0, ...sx }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <VisibilityIcon sx={{ color: 'info.light', mr: 1 }} />
                <Box {...labelProps} component="span">
                    {typeof views === 'number' ? `${views} ${labelPrefix}` : <CircularProgress color="info" size={16} />}
                </Box>
            </Box>
        </Typography>
    );
}
