import React, { Children, ReactNode } from 'react';

import { Box, Tooltip } from '@mui/material';

import { Tag } from './Tag';

interface TagGroupProps {
    children?: ReactNode;
    max?: number;
}

/**
 * Componente responsável por limitar as Tags, adicionado indicador de tags excedentes
 * @param children tags
 * @param max quantidade máxima a ser apresentada
 * @returns <Box> contendo childrens + indicador de excedentes
 */
export function TagGroup({ children, max }: TagGroupProps): JSX.Element {
    const qtd = Children.count(children);
    const surplusChildrens: string[] = [];

    let newChildrens = [children];
    let excedente = 0;

    // se tiver um valor máximo (diferente de 0, diferente de null, difererente de undefined)
    if (max) {
        newChildrens = []; // reseta childrens

        excedente = qtd - max;

        Children.toArray(children).forEach((child: any, i) => {
            const visible = i < max;

            if (visible) {
                newChildrens.push(child);
            } else {
                surplusChildrens.push(child.props.label);
            }
        });
    }

    return (
        <Box>
            {newChildrens}
            {excedente > 0 && (
                <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: surplusChildrens.join('<br>') as string }} />}>
                    <Box sx={{ display: 'inline-flex' }}>
                        <Tag label={`+ ${excedente}`} />
                    </Box>
                </Tooltip>
            )}
        </Box>
    );
}
