import React from 'react';

import { useSearchParams } from 'react-router-dom';

import { Alert, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';


import { EspelhoRoyaltyNotaList, EspelhoRoyaltyTitulosList, EspelhoRoyaltyHeader, EspelhoRoyaltyDetalhamentoList, EspelhoRoyaltyDemonstrativoList, EspelhoRoyaltyResumoList } from '..';

import { Centered } from 'components/grid';
import { OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function EspelhoRoyaltyListComp(): JSX.Element {
    const { setAutoLoad } = useListagemContext();
    const [searchParams] = useSearchParams();
    const cdLoja = searchParams.get('cdLoja');
    const dtFiltro = searchParams.get('dtFiltro');
    const tpNotaDebito = searchParams.get('tpExtrato');
    const nrNotaDebito = searchParams.get('nrDocumento');
    const nrQuinzena = searchParams.get('nrQuinzena');
    const idLoja = searchParams.get('idLoja'); // capturado aqui pois é exigido como um filtro na request do header então é "obrigatório aqui"
    const peDocumento = searchParams.get('peDocumento'); // capturado aqui pois é exigido como um filtro na request de notas então é "obrigatório aqui"
    const validParams = Boolean(cdLoja && dtFiltro && tpNotaDebito && nrNotaDebito && nrQuinzena && idLoja && peDocumento);
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/royaltyextrato/resumodebitocredito',
        columns: 'idResumoDebitoCredito',
        nested: true,
        filter: [
            { property: 'dtFiltro', value: dtFiltro, operator: 'eq' },
            { property: 'cdLoja', value: cdLoja, operator: 'eq' },
            { property: 'tpNotaDebito', value: tpNotaDebito, operator: 'eq' },
            { property: 'nrNotaDebito', value: nrNotaDebito, operator: 'eq' },
            { property: 'nrQuinzena', value: nrQuinzena, operator: 'eq' },
            { property: 'peDocumento', value: peDocumento, operator: 'eq' },
        ],
    };

    const { data, fetchStatus, isLoading } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<any> = RequestListagem(requestOptions).then((res: any) => res.data.data);
            return request;
        },
        {
            enabled: validParams,
            onSettled: () => setAutoLoad(true), // utilizado para indicar para as grids que não existem dados
        },
    );

    const content = Array.isArray(data) && data.at(0);

    return (
        <OneColumn title="Espelho de Royalties" goBackButton>
            {isLoading && fetchStatus === 'fetching' && (
                <Centered>
                    <CircularProgress sx={{ my: 2 }} />
                </Centered>
            )}

            {!validParams && <Alert severity="error">Verifique se a URL informada é valida.</Alert>}

            {!isLoading && validParams && !content && <Alert severity="error">Falha na recuperação dos dados do Espelho de Royalties.</Alert>}

            {!isLoading && validParams && content && (
                <React.Fragment>
                    <EspelhoRoyaltyHeader emitente={content.emitente} list={content.resumo} />

                    <EspelhoRoyaltyResumoList list={content.resumo} />

                    <EspelhoRoyaltyTitulosList />

                    <EspelhoRoyaltyNotaList />

                    <EspelhoRoyaltyDemonstrativoList list={content.creditoDebitoDiversos} />

                    <EspelhoRoyaltyDetalhamentoList list={content.notas} />
                </React.Fragment>
            )}
        </OneColumn>
    );
}
