import { BlockBlobClient } from '@azure/storage-blob';

import { useServiceApiContext } from 'context/ServiceApiContext';

export interface UploadFileReturn {
    file: File;
    uploadUrl: string;
}

interface AzureBlobReturn {
    uploadFile: (file: File, signal?: AbortSignal) => Promise<UploadFileReturn | null>;
}

export const useAzureBlob = (): AzureBlobReturn => {
    const Api = useServiceApiContext();

    const uploadFile = async (file: File, signal?: AbortSignal): Promise<UploadFileReturn | null> => {
        const { data } = await Api.get('/storage-azure-sas', { params: { fileName: file.name }, signal: signal });
        const { uploadUrlBlob, sasToken } = data;

        const options = {
            blobHTTPHeaders: { blobContentType: file.type },
        };

        const blobServiceClient = new BlockBlobClient(`${uploadUrlBlob}?${sasToken}`);
        await blobServiceClient.uploadData(file, options);

        return {
            file,
            uploadUrl: uploadUrlBlob,
        };
    };

    return { uploadFile };
};
