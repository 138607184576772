import React from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useShowError } from 'hooks/useShowError';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function useGetAdimplencia(): UseQueryResult<boolean> {
    const { filterConfigPedido, filterLoja } = usePedidoFilterContext();
    const requestListagem = useRequestListagem();
    const { showError } = useShowError();
    const idLoja = filterLoja.value?.idLoja;
    const idConfigPedido = filterConfigPedido.value?.idConfigPedido;

    const requestOptionsAdimplencia: RequestOptionsType = {
        url: 'gestao/limitecredito/adimplente',
        extraParams: [{ idLoja }, { idConfigPedido }],
    };

    return useQuery<boolean>([requestOptionsAdimplencia], () => requestListagem(requestOptionsAdimplencia).then((res) => res.data), {
        enabled: Boolean(idLoja && idConfigPedido),
        onSuccess: (response) => {
            if (!response) {
                showError(null, 'Loja selecionada está inadimplente');
            }
        },
        onError: () => showError(null, 'Falha ao validar a adimplência da loja'),
    });
}
