import React from 'react';

import { FuncionarioAutoComplete } from 'components/autocompletes';
import { Funcionario } from 'components/autocompletes/FuncionarioAutoComplete';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoFuncionarioAutoComplete(): JSX.Element {
    const { filterFuncionario, setFilterTipoEntrega, setFilterFuncionario } = usePedidoFilterContext();

    return (
        <FuncionarioAutoComplete
            size="small"
            value={filterFuncionario.value}
            onChange={(e, value: Funcionario) => {
                setFilterTipoEntrega((oldState: FilterValuesProps) => ({ ...oldState, value: null }));

                setFilterFuncionario((oldState: FilterValuesProps) => ({
                    ...oldState,
                    value: value && {
                        idUsuario: value.idUsuario,
                        dsUsuario: value.dsUsuario,
                        idTipoEntrega: value.idTipoEntrega,
                    },
                }));
            }}
        />
    );
}
