import React, { useState } from 'react';

import { Chip, Tooltip } from '@mui/material';

import { SugestaoCompraFalhaImportacaoModal } from 'pages/compra/sugestao/components';

interface SugestaoCompraSituacaoColumn {
    sugestaoCompraStatus: string;
    dsValidacao: string;
}

const getInfos: Record<string, { color: 'error' | 'info' | 'success' | 'warning'; label: string }> = {
    NAO_IMPORTADA: { color: 'error', label: 'Não Importada' },
    FALHA_IMPORTACAO: { color: 'error', label: 'Falha na Importação' },
    COMPLETA: { color: 'success', label: 'Sucesso' },
    IMPORTANDO: { color: 'info', label: 'Importando' },
    INCOMPLETA: { color: 'warning', label: 'Incompleta' },
};

export function SugestaoCompraSituacaoColumn({ sugestaoCompraStatus, dsValidacao }: SugestaoCompraSituacaoColumn): JSX.Element {
    const [openFalhaModal, setOpenFalhaModal] = useState<boolean | string>(false);
    const { color, label } = getInfos[sugestaoCompraStatus];

    return (
        <React.Fragment>
            <SugestaoCompraFalhaImportacaoModal setOpen={setOpenFalhaModal} open={openFalhaModal} />

            <Tooltip title={dsValidacao ? 'Visualizar erro' : ''}>
                <Chip
                    label={label}
                    variant="outlined"
                    size="small"
                    color={color}
                    sx={{ width: '100%' }}
                    onClick={
                        dsValidacao
                            ? () => {
                                  setOpenFalhaModal(dsValidacao);
                              }
                            : undefined
                    }
                />
            </Tooltip>
        </React.Fragment>
    );
}
