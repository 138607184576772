import React from 'react';

import { alpha, Chip } from '@mui/material';
import { SolicitacaoPecasProps, SolicitacaoStatusColor } from 'pages/marketing-trade/trade/solicitacoes-pecas/types';

interface PanelGridCategoriaProps {
    rowData: SolicitacaoPecasProps;
}

export function TradeSolicitacaoPecasListStatusIndicatorTitle({ rowData }: PanelGridCategoriaProps): JSX.Element {
    const keyEnum = `STATUS_${rowData.idSolicitacaoPecaStatus}` as keyof typeof SolicitacaoStatusColor;
    const color = SolicitacaoStatusColor[keyEnum];

    return (
        <Chip
            label={rowData.dsStatus}
            variant="outlined"
            size="small"
            sx={{
                backgroundColor: alpha(color, 0.35),
                borderColor: color,
            }}
        />
    );
}
