import React, { ReactNode, useCallback } from 'react';

import { IconButton, IconButtonProps, Badge } from '@mui/material';

interface IconButtonCustomProps extends IconButtonProps {
    icon?: ReactNode;
    notifications?: number;
    onClick?: () => void;
}

/**
 * Botão padrão para taskbar
 * @param IconButtonCustomProps
 * @returns IconButton
 */
export function MenutaskBarButton({ title, onClick, notifications, icon }: IconButtonCustomProps): JSX.Element {
    const handleOnClick = useCallback(() => {
        if (typeof onClick === 'function') {
            onClick();
        }
    }, [onClick]);

    return (
        <IconButton title={title} onClick={handleOnClick} size="large" color="inherit">
            <Badge badgeContent={notifications} color="error">
                {icon}
            </Badge>
        </IconButton>
    );
}
