import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

type MesFaturamentoAutoCompleteAutoCompleteProps = Pick<CissAutoCompleteProps, 'size' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function MesFaturamentoAutoComplete({ size, value, error, multiple = false, disabled = false, sx, onChange = () => {} }: MesFaturamentoAutoCompleteAutoCompleteProps): JSX.Element {

    return (
        <CissAutoComplete
            label="Mês de faturamento"
            value={value}
            multiple={multiple}
            error={error}
            options={['JANEIRO', 'FEVEREIRO', 'MARCO', 'ABRIL', 'MAIO', 'JUNHO', 'JULHO', 'AGOSTO', 'SETEMBRO', 'OUTUBRO', 'NOVEMBRO', 'DEZEMBRO']}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}