import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface CargoFuncionario {
    idCargo: number;
    dsCargo: string;
    cdCargo: number;
}

type CargoFuncionarioAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name' | 'label'>;

const getStringTemplate = (option: CargoFuncionario, strong = false): string => {
    const { cdCargo, dsCargo } = option;

    if (cdCargo) {
        if (strong) {
            return `<strong>${cdCargo}</strong> - ${dsCargo}`;
        }

        return `${cdCargo} - ${dsCargo}`;
    }

    return dsCargo;
};

export function CargoFuncionarioAutoComplete({
    value,
    error,
    multiple = false,
    sx,
    onChange,
    name,
    label = 'Cargo',
}: CargoFuncionarioAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            isOptionEqualToValue={(option: CargoFuncionario, value: CargoFuncionario) => option.idCargo === value.idCargo}
            onChange={onChange}
            sx={sx}
            name={name}
            objectId="idCargo"
            requestOptions={{
                url: '/gestao/cargo',
                columns: 'idCargo,dsCargo,cdCargo',
                sort: [{ property: 'dsCargo', direction: 'ASC' }],
            }}
            disableClearable={true}
            getSelectedString={getStringTemplate}
            getOptionString={(option) => getStringTemplate(option, true)}
        />
    );
}
