import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { FilterValuesProps, useFilter, ValueSetterFiltersProps } from 'hooks/useFilter';
import { EstadoAutoComplete, GrupoEconomicoAutoComplete, PerfilLojaAutoComplete, TipoLojaAutoComplete } from 'components/autocompletes';

export function LojaListFilter(): JSX.Element {
    const { handleFilterReset, handleFilterSubmit, setDefaultFilters } = useFilter();

    const [filterCod, setFilterCod] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'eq',
        value: null,
    });

    const [filterRazao, setFilterRazao] = useState<FilterValuesProps>({
        property: 'dsRazaoSocial',
        operator: 'like',
        value: '',
    });

    const [filterNome, setFilterNome] = useState<FilterValuesProps>({
        property: 'dsNomeFantasia',
        operator: 'like',
        value: '',
    });

    const [filterCnpj, setFilterCnpj] = useState<FilterValuesProps>({
        property: 'dsCnpj',
        operator: 'eq',
        value: null,
    });

    const [filterCidade, setFilterCidade] = useState<FilterValuesProps>({
        property: 'dsCidade',
        operator: 'like',
        value: null,
    });

    const [filterEstado, setFilterEstado] = useState<FilterValuesProps>({
        property: 'idEstado',
        operator: 'in',
        value: [],
        idProperty: 'idEstado',
    });

    const [filterGrupoEconomico, setFilterGrupoEconomico] = useState<FilterValuesProps>({
        property: 'idGrupoEconomico',
        operator: 'in',
        value: [],
        idProperty: 'idGrupoEconomico',
    });

    const [filterTipoLoja, setFilterTipoLoja] = useState<FilterValuesProps>({
        property: 'idTipoLoja',
        operator: 'in',
        value: [],
        idProperty: 'idTipoLoja',
    });

    const [filterPerfilLoja, setFilterPerfilLoja] = useState<FilterValuesProps>({
        property: 'idLojaPerfil',
        operator: 'in',
        value: [],
        idProperty: 'idLojaPerfil',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'vlStatus',
    });

    const filters: ValueSetterFiltersProps[] = useMemo(() => {
        return [
            { value: filterCod, setter: setFilterCod },
            { value: filterRazao, setter: setFilterRazao },
            { value: filterNome, setter: setFilterNome },
            { value: filterCnpj, setter: setFilterCnpj },
            { value: filterCidade, setter: setFilterCidade },
            { value: filterEstado, setter: setFilterEstado },
            { value: filterGrupoEconomico, setter: setFilterGrupoEconomico },
            { value: filterTipoLoja, setter: setFilterTipoLoja },
            { value: filterPerfilLoja, setter: setFilterPerfilLoja },
            { value: filterStatus, setter: setFilterStatus },
        ];
    }, [filterCnpj, filterCod, filterNome, filterRazao, filterEstado, filterCidade, filterGrupoEconomico, filterTipoLoja, filterPerfilLoja, filterStatus]);

    const resetFilter = useCallback(() => {
        handleFilterReset({ filters });
    }, [filters, handleFilterReset]);

    const startFilter = useCallback(() => {
        handleFilterSubmit({ filters });
    }, [filters, handleFilterSubmit]);

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item lg={2.4}>
                <CissTextField label="Código" value={filterCod.value} onChange={(e) => setFilterCod((oldState) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <CissTextField label="Razão social" value={filterRazao.value} onChange={(e) => setFilterRazao((oldState) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <CissTextField label="Nome fantasia" value={filterNome.value} onChange={(e) => setFilterNome((oldState) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <CissTextField label="CNPJ" value={filterCnpj.value} onChange={(e) => setFilterCnpj((oldState) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <CissTextField label="Cidade" value={filterCidade.value} onChange={(e) => setFilterCidade((oldState) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <EstadoAutoComplete multiple value={filterEstado.value} onChange={(e, value) => setFilterEstado((oldState) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <GrupoEconomicoAutoComplete multiple value={filterGrupoEconomico.value} onChange={(e, value) => setFilterGrupoEconomico((oldState) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <TipoLojaAutoComplete multiple value={filterTipoLoja.value} onChange={(e, value) => setFilterTipoLoja((oldState) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item lg={2.4}>
                <PerfilLojaAutoComplete multiple value={filterPerfilLoja.value} onChange={(e, value) => setFilterPerfilLoja((oldState) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={2.4}>
                <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>
        </Filter>
    );
}
