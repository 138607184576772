export interface StateOptionsProps {
    idEstado: number;
    sgEstado: string;
    dsEstado: string;
}

export interface ConfigEstadoRequestProps {
    idConfigEstado: number;
    estado: StateOptionsProps | null;
    vlFrete: number;
    nrDiasPrevisaoEntrega: number;
    dsTransportadora?: string;
}

export const initialValuesFormik: ConfigEstadoRequestProps = {
    idConfigEstado: 0,
    estado: null,
    vlFrete: 0,
    nrDiasPrevisaoEntrega: 0,
};
