import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';

interface UseListModeProps {
    mode: string | null;
    updateMode: (value: string) => void;
}

const defaultValue = 'grid';

export const useListMode = (): UseListModeProps => {
    const { value, setValue } = useLocalStorage('listMode');

    const updateMode = useCallback((value: string): void => {
        const eventListMode = new CustomEvent('listMode', {
            detail: {
                listMode: value,
            },
        });

        document.dispatchEvent(eventListMode);
    }, []);

    useEffect(() => {
        const handlerEventListMode: EventListenerOrEventListenerObject = (e: any) => {
            setValue(e.detail.listMode);
        };
        document.addEventListener('listMode', handlerEventListMode);

        return () => document.removeEventListener('listMode', handlerEventListMode);
    }, []);

    return { mode: value ?? defaultValue, updateMode };
};
