import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { StoreAutoComplete } from 'components/autocompletes';
import { DatePickerMonthYear } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';

export function ExtratoRoyaltyListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'eq',
        value: null,
        idProperty: 'cdLoja',
        required: 'Loja é obrigatória',
    });

    const [filterDtFiltro, setFilterDtFiltro] = useState<FilterValuesProps>({
        property: 'dtFiltro',
        operator: 'eq',
        value: null,
        required: 'Selecione o mês/ano para prosseguir.',
    });

    const filters = [
        { value: filterLoja, setter: setFilterLoja },
        { value: filterDtFiltro, setter: setFilterDtFiltro },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters, preventQueryParamsEmptyStart: true });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <StoreAutoComplete value={filterLoja.value} onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <DatePickerMonthYear
                    minDate={new Date('2010-01-01')}
                    maxDate={new Date()}
                    value={filterDtFiltro.value}
                    onChange={(value) => setFilterDtFiltro((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                />
            </Grid>
        </Filter>
    );
}
