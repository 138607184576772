import React, { useEffect, useRef } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useShowError } from 'hooks/useShowError';
import { useFormMutation } from 'mutations/useFormMutation';
import { FinalizarFormData } from 'pages/compra/pedido/types';

type UsePostPedido = {
    handleSubmit: () => void;
    isLoading: boolean;
};

export function usePostPedido(): UsePostPedido {
    const { showError } = useShowError();
    const navigate = useNavigate();
    const { mutate, isLoading } = useFormMutation();
    const { setIsSavingCarrinho, configPedido, loja, idPedido, tipoEntrega, produtoCartList, funcionario } = usePedidoCompraContext();
    const { hasPermission } = usePermissionsContext();
    const { usuario } = useAplicacaoDataContext();
    const timesClicked = useRef(0);
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);

    useEffect(() => setIsSavingCarrinho(isLoading), [isLoading]);

    const getFinalizarFormData = (): FinalizarFormData | undefined => {
        if (!configPedido || !loja) {
            return;
        }

        return {
            idPedido,
            produtos: produtoCartList.map((produto) => {
                return { qtProduto: produto.qtProdutoCart, idProduto: produto.produto.idProduto };
            }),
            configPedido: {
                idConfigPedido: configPedido.idConfigPedido,
            },
            loja: {
                idLoja: loja?.idLoja,
            },
            ...(tipoEntrega && { tipoEntrega: { idTipoEntrega: tipoEntrega?.idTipoEntrega } }),
            ...(funcionario && { usuarioPedido: { idUsuario: funcionario?.idUsuario } }),
        };
    };

    const navigateToCarrinho = (): void =>
        navigate({
            pathname: '/compra/carrinho',
            search: createSearchParams({
                idLoja: JSON.stringify([loja]),
                ...((funcionario || usuario) && hasPermissionFinalizacaoGeral && { idUsuarioPedido: JSON.stringify(funcionario || usuario) }),
            }).toString(),
        });

    const sendInfoPedidoDuplicado = (error: any): void => {
        mutate({
            url: '/gestao/pedidologerro/gravar',
            preventSnack: true,
            formData: {
                body: error?.config?.data,
                agent: window.navigator.userAgent,
                timestamp: error?.data?.timestamp,
                timesClicked: timesClicked.current,
            },
        });
    };

    const handleSubmit = (): void => {
        if (!configPedido || !loja) {
            return;
        }

        timesClicked.current += 1;

        mutate(
            {
                url: '/compra/pedidocompra/rascunho',
                formData: getFinalizarFormData(),
                preventSnack: true,
                preventRedirect: true,
                idField: 'idPedido',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Pedido salvo com sucesso!', { variant: 'success' });

                    navigateToCarrinho();
                },
                onError: (error: any) => {
                    // 409 = Pedido duplicado
                    if (error?.status && error?.status === 409) {
                        sendInfoPedidoDuplicado(error);
                    } else {
                        showError(error, 'Ocorreu uma falha ao salvar o pedido, tente novamente');
                    }
                },
            },
        );
    };

    return { handleSubmit, isLoading };
}
