import React, { useEffect, useState } from 'react';

import { Grid, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { CategoryField } from 'pages/comunicado/components';

export function FormFilterComunicado(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsComunicado',
        operator: 'like',
        value: '',
    });

    const [filterCategoria, setFilterCategoria] = useState<FilterValuesProps>({
        property: 'idCategoriaComunicado',
        operator: 'in',
        value: [],
        idProperty: 'idCategoriaComunicado',
    });

    const [filterTags, setFilterTags] = useState<FilterValuesProps>({
        property: 'idTagsComunicado',
        operator: 'in',
        value: [],
        idProperty: 'idTagComunicado',
    });

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhInclusao',
        operator: 'bt',
        startDate: null,
        endDate: null,
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: null,
        idProperty: 'vlStatus',
    });

    const filters = [
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterCategoria, setter: setFilterCategoria },
        { value: filterTags, setter: setFilterTags },
        { value: filterInterval, setter: setFilterInterval },
        { value: filterStatus, setter: setFilterStatus },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissTextField label="Título" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            <Grid item flex={1}>
                <CategoryField value={filterCategoria.value} onChange={(e, value) => setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <CissAutoComplete
                    label="Tags"
                    value={filterTags.value}
                    onChange={(e, value) => setFilterTags((oldState: FilterValuesProps) => ({ ...oldState, value }))}
                    isOptionEqualToValue={(option, value) => option.idTagComunicado === value.idTagComunicado}
                    selectedStringTemplate="{idTagComunicado} - {dsTagComunicado}"
                    optionStringTemplate="<strong>{idTagComunicado}</strong> - {dsTagComunicado}"
                    requestOptions={{
                        url: '/gestao/tagcomunicado',
                        columns: 'idTagComunicado,dsTagComunicado',
                    }}
                />
            </Grid>

            <Grid item flex={2}>
                <LocalizationProvider autoComplete="no" adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Inicial', end: 'Data Final' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            {hasPermission(['COMUNICADO_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
}
