import React, { useState } from 'react';

import { Chip, Tooltip } from '@mui/material';

import { ErroPedidoModal } from 'components/grid';

interface StatusPedidoColumnProps {
    idStatus: number;
    dsStatus: string;
    dsValidacao: string;
}

const statusPedido: Record<string, number> = {
    PENDENTE_GRADE: 1,
    PENDENTE_SINCRONIZACAO: 2,
    PENDENTE_APROVACAO: 3,
    RASCUNHO: 4,
    TOTALIZADOR: 5,
    AGUARDANDO_FATURAMENTO: 6,
    CANCELADO_SAP: 7,
    CANCELADO_EXTERNO: 8,
    FATURADO_PARCIALMENTE: 9,
    FATURADO_TOTAL: 10,
    PEDIDO_MANUAL: 11,
    EM_SINCRONIZACAO: 12,
    ERRO_SINCRONIZACAO: 13,
};

export function StatusPedidoColumn({ idStatus, dsStatus, dsValidacao }: StatusPedidoColumnProps): JSX.Element {
    const [openErroModal, setOpenErroModal] = useState<string | boolean>(false);
    const showError = dsValidacao && ![statusPedido.AGUARDANDO_FATURAMENTO, statusPedido.FATURADO_PARCIALMENTE, statusPedido.FATURADO_TOTAL].includes(idStatus);

    let color: 'error' | 'info' | 'success' | 'warning' = 'warning';

    if (showError || [statusPedido.CANCELADO_SAP, statusPedido.ERRO_SINCRONIZACAO].includes(idStatus)) {
        color = 'error';
    } else if (
        [statusPedido.PENDENTE_SINCRONIZACAO, statusPedido.PENDENTE_APROVACAO, statusPedido.RASCUNHO, statusPedido.TOTALIZADOR, statusPedido.PEDIDO_MANUAL, statusPedido.EM_SINCRONIZACAO].includes(
            idStatus,
        )
    ) {
        color = 'info';
    } else if ([statusPedido.AGUARDANDO_FATURAMENTO, statusPedido.CANCELADO_EXTERNO, statusPedido.FATURADO_TOTAL].includes(idStatus)) {
        color = 'success';
    } else if ([statusPedido.PENDENTE_GRADE, statusPedido.FATURADO_PARCIALMENTE].includes(idStatus)) {
        color = 'warning';
    }

    return (
        <React.Fragment>
            <ErroPedidoModal setOpen={setOpenErroModal} open={openErroModal} />

            <Tooltip title={showError ? 'Visualizar erro' : ''}>
                <Chip
                    label={dsStatus}
                    variant="outlined"
                    size="small"
                    color={color}
                    sx={{ width: '100%' }}
                    onClick={
                        showError
                            ? () => {
                                  setOpenErroModal(dsValidacao);
                              }
                            : undefined
                    }
                />
            </Tooltip>
        </React.Fragment>
    );
}
