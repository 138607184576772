import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';
export interface StoreOptionsProps {
    idLoja: number;
    cdLoja: string;
    dsNomeFantasia: string;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function TradeSolicitaoPecasStoreAutoComplete({ value, error, multiple = false, disabled = false, sx, onChange = () => {} }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Loja"
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{cdLoja} - {dsNomeFantasia}"
            optionStringTemplate="<strong>{cdLoja}</strong> - {dsNomeFantasia}"
            requestOptions={{
                url: '/gestao/solicitacaopeca/loja',
                sort: [{ property: 'dsNomeFantasia', direction: 'ASC' }],
                columns: 'idLoja,cdLoja,dsNomeFantasia',
                limit: 50,
            }}
            isOptionEqualToValue={(option: StoreOptionsProps, value: StoreOptionsProps) => value && option && option.idLoja === value.idLoja}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
        />
    );
}
