import React from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LoadingButton } from '@mui/lab';

import { RowProps } from 'pages/compra/pedido-realizado/types';

type PedidoRealizadoGradeBtn = {
    rowProps: RowProps;
    isDisabledActions: boolean;
};

export function PedidoRealizadoGradeBtn({ isDisabledActions, rowProps }: PedidoRealizadoGradeBtn): JSX.Element {
    const navigate = useNavigate();

    const onClickGrade = (): void => {
        const { dsNomeFantasia, cdLoja, idLoja, tipoGrade } = rowProps;

        const path = {
            pathname: '/logistica/grade/form',
            search: createSearchParams({
                idLoja: JSON.stringify({ idLoja, cdLoja, dsNomeFantasia }),
                idTipoGrade: JSON.stringify(tipoGrade),
            }).toString(),
        };

        navigate(path);
    };

    return (
        <LoadingButton color="inherit" disabled={isDisabledActions} onClick={onClickGrade} startIcon={<CalendarMonthIcon />}>
            Atribuir Grade
        </LoadingButton>
    );
}
