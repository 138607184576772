import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useFormContext } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { CarrinhoFinalizarAllModal } from 'pages/compra/carrinho/components';
import { useGetTotalizador } from 'pages/compra/carrinho/hooks';
import { brlDecimal, brlPrice } from 'util/format';

export interface LojaProps {
    idLoja: number;
    cdLoja: string;
    dsNomeFantasia: string;
}

export function CarrinhoBbar(): JSX.Element {
    const [isOpenFinalizarModal, setIsOpenFinalizarModal] = useState<boolean>(false);
    const { content } = useFormContext();
    const { tipoPedido } = useGetDomainConfig();
    const { hasPermission } = usePermissionsContext();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const { data: totalizador, isFetching } = useGetTotalizador();
    const isLojasEqualOne = Array.isArray(content?.lojas) && content?.lojas.length === 1;
    const disableFinalizarAll = !totalizador?.vlTotal || content?.finalizarRow || (!isLojasEqualOne && isTipoPedidoPadrao);

    return (
        <>
            <CarrinhoFinalizarAllModal open={isOpenFinalizarModal} setOpen={setIsOpenFinalizarModal} />

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    p: 2,
                    mx: -2,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    gap: 2,
                }}
            >
                {isFetching && <CircularProgress size={20} />}

                {!isFetching && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        {typeof totalizador?.vlPesoLiquidoTotal === 'number' && (
                            <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                Peso total: <strong>{brlDecimal(totalizador.vlPesoLiquidoTotal, 0, 3)} Kg</strong>
                            </Typography>
                        )}

                        {typeof totalizador?.vlTotal === 'number' && (
                            <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                Valor total: <strong>{brlPrice(totalizador?.vlTotal)}</strong>
                            </Typography>
                        )}
                    </Box>
                )}

                {hasPermission(['COMPRA_PEDIDO_FINALIZAR']) && (
                    <LoadingButton
                        variant="contained"
                        loading={content?.finalizarAll}
                        disabled={disableFinalizarAll}
                        onClick={() => setIsOpenFinalizarModal(true)}
                        size="large"
                    >
                        Finalizar Todos os Pedidos
                    </LoadingButton>
                )}
            </Box>
        </>
    );
}
