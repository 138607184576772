import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { CissNumberField, CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';

export function FormFilterCategoriaComunicado(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterCodigo, setFilterCodigo] = useState<FilterValuesProps>({
        property: 'idCategoriaComunicado',
        operator: 'eq',
        value: '',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: hasPermission(['COMUNICADO_CATEGORIA_MODERAR']) ? null : true,
        idProperty: 'vlStatus',
    });

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsCategoriaComunicado',
        operator: 'like',
        value: '',
    });

    const filters = useMemo(
        () => [
            { value: filterCodigo, setter: setFilterCodigo },
            { value: filterTitulo, setter: setFilterTitulo },
            { value: filterStatus, setter: setFilterStatus },
        ],
        [filterStatus, filterCodigo, filterTitulo],
    );

    const startFilter = useCallback((): void => {
        handleFilterSubmit({ filters });
    }, [filters, handleFilterSubmit]);

    const resetFilter = useCallback((): void => {
        handleFilterReset({ filters });
    }, [filters, handleFilterReset]);

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissNumberField
                    label="Código"
                    value={filterCodigo.value}
                    onChange={(e) => setFilterCodigo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                    preventEmptyField={false}
                />
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="Buscar por" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            {hasPermission(['ARQUIVO_CATEGORIA_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
}
