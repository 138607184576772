import React, { useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { useListagemContext } from 'context/ListagemContext';
import { FilterValuesProps } from 'hooks/useFilter';

interface RelatorioDreAnaliticoBtnProps {
    disabled: boolean;
    filterLoja?: FilterValuesProps;
}

export function RelatorioDreAnaliticoBtn({ disabled, filterLoja }: RelatorioDreAnaliticoBtnProps): JSX.Element {
    const navigate = useNavigate();
    const { filter } = useListagemContext();

    const findValueFilter = useCallback((filtros: FilterValuesProps[], column: string): string => {
        const filtro = filtros.find((filtro) => filtro.property === column);

        return filtro?.value;
    }, []);

    const getDsLoja = useCallback((filterLoja?: FilterValuesProps): string => {
        const { value } = filterLoja || {};

        if (Array.isArray(value)) {
            return value.map((item) => `${item.cdLoja} - ${item.dsNomeFantasia}`).join(', ');
        }

        return `${value.cdLoja} - ${value.dsNomeFantasia}`;
    }, []);

    const handleClickDownload = useCallback((): void => {
        const idLoja = findValueFilter(filter, 'idLoja');
        const nrAno = findValueFilter(filter, 'nrAno');
        const dsLoja = getDsLoja(filterLoja);

        navigate({
            pathname: 'relatorio/analitico',
            search: `?${createSearchParams({
                idLoja,
                dsLoja,
                nrAno,
            })}`,
        });
    }, [filter, filterLoja, findValueFilter, getDsLoja, navigate]);

    return (
        <Button variant="outlined" color="success" onClick={handleClickDownload} disabled={disabled}>
            Estimativa de Resultado Analítico
        </Button>
    );
}
