import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Chip, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { Block } from 'components/page';
import { PanelGrid } from 'components/grid';
import { useListagemContext } from 'context/ListagemContext';
import { SubTitle } from 'components/text';
import { brlDateTime } from 'util/format';
import { ArquivoProps, RequestArquivosProps } from 'pages/financeiro/detalhamento-creditos/importar/types';
import { DetalhamentoCreditosImportacaFilter } from 'pages/financeiro/detalhamento-creditos/importar/components/';
import { ContentModal } from 'components/modal';
import { EditRemoveBtn } from 'components/buttons';
import { usePermissionsContext } from 'context/PermissionsContext';

const generateImportacaoArquivoList = (data: ArquivoProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idDetalhamentoCreditoDebitoImportacao: item.idDetalhamentoCreditoDebitoImportacao,
            dsArquivo: item.dsArquivo,
            idStatusProcessamento: item.statusProcessamento.idStatusProcessamento,
            dsStatusProcessamento: item.statusProcessamento.dsStatusProcessamento,
            validacoes: item.validacoes,
            dhInclusao: item.dhInclusao,
        }));
    }
    return [];
};

export const DetalhamentoCreditosImportacaoGrid = (): JSX.Element => {
    const { limit, page, filter, sort } = useListagemContext();
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState<string[]>([]);
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/detalhamentocreditodebitoimportacao/lista',
            limit,
            page,
            sort: sort.length ? sort : [{ property: 'dhInclusao', direction: 'DESC' }],
            filter,
        }),
        [filter, limit, page, sort],
    );

    const { refetch, data, fetchStatus } = useQuery(['importacao-file-list', requestOptions], () => {
        const request: Promise<RequestArquivosProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'idDetalhamentoCreditoDebitoImportacao', headerName: 'ID Arquivo', type: 'number', minWidth: 80 },
            { field: 'dsArquivo', headerName: 'Arquivo', minWidth: 200, flex: 1 },
            {
                field: 'dsStatusProcessamento',
                headerName: 'Status',
                width: 220,
                minWidth: 200,
                sortable: false,
                renderCell: (params) => {
                    let color: 'default' | 'error' | 'info' | 'success' | 'warning' = 'default';

                    switch (params.row.idStatusProcessamento) {
                        case 3: // SUCESSO = 3,
                            color = 'success';
                            break;
                        case 4: // ERRO = 4,
                        case 5: // FINALIZADO COM ALERTA = 5,
                            color = 'error';
                            break;
                        case 1: // AGUARDANDO = 1, PROCESSANDO = 2,
                        default:
                            color = 'info';
                            break;
                    }

                    return (
                        <React.Fragment>
                            <Chip label={params.value} variant="outlined" size="small" color={color} />

                            {[4, 5].includes(params.row.idStatusProcessamento) && params.row.validacoes.length && (
                                <IconButton
                                    onClick={() => {
                                        const validacoesErros: string[] = params.row.validacoes.map((val: any) => val.dsDetalhamentoCreditoDebitoImportacaoValidacao);

                                        setModalContent(validacoesErros);
                                        setOpenModal(true);
                                    }}
                                    sx={{ ml: 1 }}
                                >
                                    <InfoOutlinedIcon color="primary" sx={{ fontSize: '1.25rem' }} />
                                </IconButton>
                            )}
                        </React.Fragment>
                    );
                },
            },
            { field: 'dhInclusao', headerName: 'Data envio', width: 150, minWidth: 140, valueFormatter: ({ value }) => value && brlDateTime(value) },
        ],
        [],
    );

    if (hasPermission(['FINANCEIRO_DETALHAMENTO_CREDITO_EXCLUIR'])) {
        columns.push({
            field: 'acoes',
            headerName: 'Cancelar',
            headerAlign: 'center',
            align: 'center',
            minWidth: 90,
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => {
                const { idDetalhamentoCreditoDebitoImportacao, idStatusProcessamento } = row;
                const url = [3, 4, 5].includes(idStatusProcessamento) ? `financeiro/detalhamentocreditodebitoimportacao/${idDetalhamentoCreditoDebitoImportacao}` : undefined;

                return <EditRemoveBtn id={idDetalhamentoCreditoDebitoImportacao} refresh={refetch} showEditBtn={false} removeUrl={url} sx={{ p: 1.5 }} />;
            },
        });
    }

    const rows = useMemo(() => generateImportacaoArquivoList(data?.data), [data?.data]);

    return (
        <Block id="files-import-grid">
            <SubTitle label="Arquivos de Importação" />

            <DetalhamentoCreditosImportacaFilter />

            <PanelGrid rows={rows} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />

            <ContentModal open={openModal} setOpen={setOpenModal} closeButton sx={{ borderRadius: 1, minWidth: 300 }}>
                <React.Fragment>
                    <SubTitle label="Validações do Processamento" fullWidth />

                    <List dense={true} sx={{ p: 0 }}>
                        {modalContent.map((modalCont, index) => (
                            <ListItem key={`${modalCont}_${index}`} sx={{ p: 0 }}>
                                <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                    <ChevronRightOutlinedIcon />
                                </ListItemIcon>

                                <ListItemText primary={modalCont} />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            </ContentModal>
        </Block>
    );
};
