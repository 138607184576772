import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

export interface ProdutoProps {
    idProduto: number;
    dsProduto: string;
    cdProduto: string;
}

type ProdutoAutoCompleteProps = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'requestOptions' | 'size'>;

export function ProdutoAutoComplete({
    value,
    error,
    size,
    label = 'Produtos',
    multiple = true,
    disabled = false,
    sx,
    onChange = () => {},
    requestOptions = {
        url: '/gestao/produto',
        sort: [{ property: 'cdProduto', direction: 'ASC' }],
        columns: 'idProduto,dsProduto,fgAtivo,cdProduto,fgFracionavel,dsMarca,dsGrupo,qtMinimaCompra',
    },
}: ProdutoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{cdProduto} - {dsProduto}"
            optionStringTemplate="<strong>{cdProduto}</strong> - {dsProduto}"
            requestOptions={requestOptions}
            isOptionEqualToValue={(option: ProdutoProps, value: ProdutoProps) => option.idProduto === value.idProduto}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            size={size}
        />
    );
}
