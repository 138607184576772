import React, { useCallback, useMemo, useState } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import { Box, Grid, Stack, Typography, alpha, Paper, Tooltip, Alert, CircularProgress } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { DefaultAccordion } from 'components/accordion';
import { TinyButton } from 'components/buttons';
import { Centered, PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DetalhamentoCreditoListFilter, RelatorioCreditosItemDetalhamentoCreditos, RelatorioCreditosRoyaltiesDetalhamentoCreditos } from 'pages/financeiro/detalhamento-creditos/components';
import { DetalhamentoProps, RequestDetalhamentoCreditoProps } from 'pages/financeiro/detalhamento-creditos/types';
import { getErrorMessage } from 'util/error';
import { brlPrice } from 'util/format';

const generateDetalhamentoRows = (data: DetalhamentoProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => {
            return {
                id: `${item.nrDocumento}_${item.dsObservacao}`,
                nrMes: item.nrMes,
                nrAno: item.nrAno,
                nrQuinzena: item.nrQuinzena,
                cdLoja: parseInt(item.loja.cdLoja),
                dsPromocao: item.dsObservacao,
                tipoNaturezaLancamento: item.tipoNaturezaLancamento.dsTipoNaturezaLancamento,
                vlLancamento: item.vlLancamento,
                dsMarca: item.marca.dsMarca,
            };
        });
    }

    return [];
};

export function DetalhamentoCreditoListComp(): JSX.Element {
    const navigate = useNavigate();
    const { filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const [accordionExpanded, setAccordionExpanded] = useState<string | false>(false);
    const { hasPermission } = usePermissionsContext();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/financeiro/detalhamentocreditodebitowrapper',
            columns: 'idDetalhamentoCreditoDebitoWrapper',
            nested: true,
            filter,
            requestConfig: {
                timeout: 2 * 60 * 10000,
            },
        }),
        [filter],
    );

    const { isLoading, isError, error, fetchStatus, refetch, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestDetalhamentoCreditoProps> = RequestListagem(requestOptions).then((res) => res.data);
            return request;
        },
        {
            enabled: autoLoad,
        },
    );

    const handleClickButtonDetalhes = useCallback(
        ({ cdLoja, nrAno, nrMes, nrQuinzena, dsPromocao }: any): void => {
            navigate({
                pathname: 'detalhes',
                search: `?${createSearchParams({
                    cdLoja,
                    nrAno,
                    nrMes,
                    nrQuinzena,
                    dsPromocao,
                })}`,
            });
        },
        [navigate],
    );

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'nrMes', headerName: 'Mês', type: 'number', width: 80, minWidth: 60 },
            { field: 'nrAno', headerName: 'Ano', type: 'string', width: 80, minWidth: 60 },
            { field: 'nrQuinzena', headerName: 'Quinzena', type: 'number', minWidth: 80 },
            { field: 'dsMarca', headerName: 'Marca', width: 80, minWidth: 70 },
            { field: 'cdLoja', headerName: 'Cliente', type: 'string', width: 120, minWidth: 80 },
            {
                field: 'dsPromocao',
                headerName: 'Descrição',
                flex: 1,
                minWidth: 100,
                renderCell: (params) => (
                    <Tooltip title={params.value}>
                        <span
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {params.value}
                        </span>
                    </Tooltip>
                ),
            },
            { field: 'tipoNaturezaLancamento', headerName: 'Tipo de Lançamento', width: 150, minWidth: 140 },
            { field: 'vlLancamento', headerName: 'Valor do Lançamento', type: 'number', minWidth: 150, valueFormatter: ({ value }) => brlPrice(value) },
            {
                field: 'dsEspelho',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Detalhes',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 110,
                renderCell: ({ row }) => <TinyButton onClick={() => handleClickButtonDetalhes(row)}>Detalhes</TinyButton>,
            },
        ],
        [handleClickButtonDetalhes],
    );

    const content = Array.isArray(data?.data) ? data?.data.at(0) : {};
    const [errorContent] = getErrorMessage(error);

    const rowsCredito = generateDetalhamentoRows(content?.detalhamentoCreditos);
    const vlTotalCredito = rowsCredito.reduce((prev, curr) => prev + curr.vlLancamento, 0);

    const rowsDebito = generateDetalhamentoRows(content?.detalhamentoDebitos);
    const vlTotalDebito = rowsDebito.reduce((prev, curr) => prev + curr.vlLancamento, 0);

    const qtRows = rowsCredito.length + rowsDebito.length;
    const vlTotal = vlTotalCredito - vlTotalDebito;

    const handleAccordionChange = useCallback(
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setAccordionExpanded(isExpanded ? panel : false);
        },
        [],
    );

    return (
        <OneColumn
            title="Detalhamento de Créditos"
            extraButtons={[
                {
                    title: 'Importar',
                    onClick: () => navigate('importar'),
                    visible: hasPermission(['FINANCEIRO_DETALHAMENTO_CREDITO_IMPORTAR']),
                },
            ]}
        >
            <DetalhamentoCreditoListFilter />

            <Block>
                {isLoading && fetchStatus === 'fetching' && (
                    <Centered>
                        <CircularProgress sx={{ my: 2 }} />
                    </Centered>
                )}

                {!autoLoad && (
                    <Centered>
                        <Typography sx={{ my: 2 }}>Utilize os filtros acima para iniciar</Typography>
                    </Centered>
                )}

                {autoLoad && isError && <Alert severity="error">{errorContent}</Alert>}

                {!isLoading && autoLoad && !isError && (
                    <React.Fragment>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                            <RelatorioCreditosRoyaltiesDetalhamentoCreditos disabled={qtRows <= 0} />

                            <RelatorioCreditosItemDetalhamentoCreditos disabled={qtRows <= 0} />
                        </Stack>

                        <DefaultAccordion
                            expanded={accordionExpanded === 'credito'}
                            onChange={handleAccordionChange('credito')}
                            title={
                                <Grid container>
                                    <Grid item sm={6} xs={12}>
                                        <Typography>Créditos</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        sm={6}
                                        xs={12}
                                        direction="row"
                                        sx={{
                                            gap: 2,
                                            justifyContent: {
                                                xs: 'flex-start',
                                                sm: 'flex-end',
                                            },
                                        }}
                                    >
                                        <Typography>Valor Total Créditos:</Typography>

                                        <Typography sx={{ minWidth: '100px' }}>
                                            <strong>{brlPrice(vlTotalCredito)}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        >
                            <PanelGrid
                                rows={rowsCredito}
                                rowsCount={rowsCredito.length}
                                reload={refetch}
                                loading={fetchStatus === 'fetching'}
                                columns={columns}
                                remote={false}
                                customMessageNoRows="Não há detalhamento de crédito a ser exibido"
                            />
                        </DefaultAccordion>

                        <DefaultAccordion
                            expanded={accordionExpanded === 'debito'}
                            onChange={handleAccordionChange('debito')}
                            title={
                                <Grid container>
                                    <Grid item sm={6} xs={12}>
                                        <Typography>Débitos</Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        sm={6}
                                        xs={12}
                                        direction="row"
                                        sx={{
                                            gap: 2,
                                            justifyContent: {
                                                xs: 'flex-start',
                                                sm: 'flex-end',
                                            },
                                        }}
                                    >
                                        <Typography>Valor Total Débitos:</Typography>
                                        <Typography sx={{ minWidth: '100px' }}>
                                            <strong>{brlPrice(vlTotalDebito)}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                        >
                            <PanelGrid
                                rows={rowsDebito}
                                rowsCount={rowsDebito.length}
                                reload={refetch}
                                loading={fetchStatus === 'fetching'}
                                columns={columns}
                                remote={false}
                                customMessageNoRows="Não há detalhamento de crédito a ser exibido"
                            />
                        </DefaultAccordion>

                        <Paper
                            sx={{
                                backgroundColor: (theme) => alpha(theme.palette.common.black, 0.03),
                                py: 2,
                                px: 2,
                            }}
                        >
                            <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                                <Typography sx={{ ml: { sm: 'auto', xs: 2.5 } }}>Valor Total:</Typography>
                                <Typography
                                    sx={{
                                        minWidth: '100px',
                                        color: (theme) => {
                                            const negativeColor = theme.palette.error.main;
                                            const defaultColor = theme.palette.success.main;

                                            return vlTotal < 0 ? negativeColor : defaultColor;
                                        },
                                    }}
                                >
                                    <strong>{brlPrice(vlTotal)}</strong>
                                </Typography>
                            </Box>
                        </Paper>
                    </React.Fragment>
                )}
            </Block>
        </OneColumn>
    );
}
