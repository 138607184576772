import { UploadFileProps } from 'components/upload/types';
import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface ComunicadoTagsProps {
    idTagComunicado: number;
    dsTagComunicado: string;
}

export interface ComunicadoFilesProps extends UploadFileProps {
    idComunicadoArquivo?: number;
}

interface ComunicadoCategoryProps {
    dsCategoriaComunicado: string;
}

interface ComunicadoEstadosProps {
    idComunicadoEstado?: string;
    idEstado: number;
    dsEstado: string;
}
interface ComunicadoCidadesProps {
    idComunicadoCidade?: string;
    idCidade: number;
    dsCidade: string;
}

interface ComunicadoTipoLojasProps {
    idComunicadoTipoLojas?: string;
    idTipoLoja: number;
    dsTipoLoja: string;
}

interface ComunicadoLojasProps {
    idComunicadoLojas?: string;
    idLoja: number;
    dsLoja: string;
}

interface ComunicadoLojaPerfis {
    idLojaPerfil: number;
    dsLojaPerfil: string;
}
export interface RequestComunicadosModelProps {
    idComunicado: number;
    dsComunicado: string;
    dsConteudo: string;
    dhInclusao?: string;
    qtCurtida: number;
    qtVisualizacao?: number;
    fgAtivo: boolean;
    fgUsuarioCurtiu: boolean;
    categoriaComunicado: ComunicadoCategoryProps | null;
    comunicadoTagsComunicado?: ComunicadoTagsProps[];
    comunicadoArquivos?: ComunicadoFilesProps[];
    fgExibePopup?: boolean;
    fgSolicitaConfirmacao?: boolean;
    fgEnviaEmail?: boolean;
    comunicadoEstados?: ComunicadoEstadosProps[];
    comunicadoTipoLojas?: ComunicadoTipoLojasProps[];
    comunicadoLojaPerfis?: ComunicadoLojaPerfis[];
    comunicadoLojas?: ComunicadoLojasProps[];
    comunicadoCidades?: ComunicadoCidadesProps[];
    dhInicioPopup?: string | null;
    dhFinalPopup?: string | null;
}

export interface RequestComunicadosStoreProps extends Partial<RequestListagemProps> {
    status: number;
    data: {
        data: RequestComunicadosModelProps;
    };
}

export const initialComunicadosValues: RequestComunicadosModelProps = {
    idComunicado: -1,
    dsComunicado: '',
    dsConteudo: '',
    dhInclusao: undefined,
    qtCurtida: 0,
    qtVisualizacao: 0,
    fgAtivo: false,
    fgUsuarioCurtiu: false,
    comunicadoTagsComunicado: [],
    comunicadoArquivos: [],
    fgExibePopup: false,
    fgSolicitaConfirmacao: false,
    fgEnviaEmail: false,
    comunicadoEstados: [],
    comunicadoCidades: [],
    dhInicioPopup: null,
    dhFinalPopup: null,
    comunicadoTipoLojas: [
        { idTipoLoja: 2, dsTipoLoja: 'Franquia' },
        { idTipoLoja: 1, dsTipoLoja: 'Própria' },
    ],
    comunicadoLojaPerfis: [],
    comunicadoLojas: [],
    categoriaComunicado: null,
};
