import React from 'react';

import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

import { useListagemContext } from 'context/ListagemContext';
import { useDownloadFile } from 'hooks/useDownloadFile';

interface TituloListActionPrintProps {
    params: GridRenderCellParams<any, any, any>;
}

export function TituloListFppBtn({ params }: TituloListActionPrintProps): JSX.Element | null {
    const { fullFilterData } = useListagemContext();
    const filterLoja = fullFilterData?.find((filter) => filter.value.property === 'cdLoja');
    const { cdLoja } = filterLoja?.value?.value || {};
    const { dtEmissao, nrTitulo, tpTitulo } = params.row;

    const { isLoadingFile, downloadFile } = useDownloadFile({
        requestOptions: {
            url: '/financeiro/fpp/extrato/pdf',
            sort: [{ property: 'nrFpp', direction: 'DESC' }],
            filter: [
                {
                    property: 'cdLoja',
                    operator: 'eq',
                    value: cdLoja,
                    logicalOperator: 'AND',
                },
                {
                    property: 'dtFiltro',
                    operator: 'eq',
                    value: format(new Date(dtEmissao), 'MM/dd/yyyy'),
                    logicalOperator: 'AND',
                },
                {
                    property: 'nrFpp',
                    operator: 'eq',
                    value: nrTitulo,
                    logicalOperator: 'AND',
                },
            ],
        },
    });

    if (tpTitulo !== 'Nota de débito FPP') {
        return null;
    }

    return (
        <Tooltip title={'Nota Débito'}>
            <LoadingButton onClick={() => downloadFile()} loading={isLoadingFile} disabled={isLoadingFile}>
                <PrintOutlinedIcon />
            </LoadingButton>
        </Tooltip>
    );
}
