import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { CissNumberField, CissTextField, StatusField } from 'components/fields';
import { Filter } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { getTypeMarketing, MarketingTradePage } from 'pages/marketing-trade/types';

export function MarketingTradeCampanhaListFilter({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();
    const { hasPermission } = usePermissionsContext();

    const [filterCodigo, setFilterCodigo] = useState<FilterValuesProps>({
        property: 'idCampanhaMarketing',
        operator: 'eq',
        value: '',
    });

    const [filterTitulo, setFilterTitulo] = useState<FilterValuesProps>({
        property: 'dsCampanhaMarketing',
        operator: 'like',
        value: '',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'fgAtivo',
        operator: 'eq',
        value: hasPermission([isMarketing ? 'MARKETING_CAMPANHA_MODERAR' : 'TRADE_CAMPANHA_MODERAR']) ? null : true,
        idProperty: 'vlStatus',
    });

    const filterTipoMarketing: FilterValuesProps = {
        property: 'idTipoMarketing',
        operator: 'eq',
        value: getTypeMarketing(isMarketing),
    };

    const filters = [
        { value: filterCodigo, setter: setFilterCodigo },
        { value: filterTitulo, setter: setFilterTitulo },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterTipoMarketing },
    ];

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={1}>
                <CissNumberField
                    label="Código"
                    value={filterCodigo.value}
                    onChange={(e) => setFilterCodigo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))}
                    preventEmptyField={false}
                />
            </Grid>

            <Grid item flex={1}>
                <CissTextField label="Título" value={filterTitulo.value} onChange={(e) => setFilterTitulo((oldState: FilterValuesProps) => ({ ...oldState, value: e.target.value }))} />
            </Grid>

            {hasPermission([isMarketing ? 'MARKETING_CAMPANHA_MODERAR' : 'TRADE_CAMPANHA_MODERAR']) && (
                <Grid item flex={1}>
                    <StatusField value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
                </Grid>
            )}
        </Filter>
    );
}
