import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { SubTitle } from 'components/text';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ProdutoPedidoProps } from 'pages/compra/carrinho/types';
import { brlPrice } from 'util/format';

interface CarrinhoProdutoGrid {
    row: GridRowModel;
}

export function CarrinhoProdutoGrid({ row }: CarrinhoProdutoGrid): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { limit, page, sort, filter } = useListagemContext();

    const requestOptions: RequestOptionsType = {
        url: `/compra/pedidocompra/rascunho/itens?idPedido=${row.idPedido}`,
        sort: sort.length ? sort : [[{ property: 'dsSubgrupo', direction: 'ASC' }]],
        page,
        filter,
        limit,
    };

    const { fetchStatus, data, refetch } = useQuery(
        [requestOptions],
        () => RequestListagem(requestOptions).then((res) => res.data) as Promise<ProdutoPedidoProps>,
    );

    const columns: GridColDef[] = [
        {
            field: 'imagem',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Imagem',
            filterable: false,
            sortable: false,
            minWidth: 50,
            renderCell: ({ row }) =>
                row.lkImagem ? (
                    <Box
                        sx={{
                            minWidth: 50,
                            minHeight: 50,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundImage: `url("${row.lkImagem}")`,
                            borderRadius: 1,
                            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                        }}
                    />
                ) : null,
        },
        { field: 'cdProduto', headerName: 'Código de Integração', width: 120, minWidth: 90 },
        { field: 'dsProduto', headerName: 'Produto', flex: 1, minWidth: 100 },
        { field: 'dsSubgrupo', headerName: 'Categoria', flex: 1, minWidth: 110 },
        {
            field: 'vlUnitario',
            headerName: 'Preço Unitário',
            type: 'number',
            align: 'right',
            width: 130,
            minWidth: 110,
            valueFormatter: ({ value }) => (value ? brlPrice(value) : null),
        },
        { field: 'qtMinimaCompra', headerName: 'Quantidade Mínima', minWidth: 100 },
        { field: 'qtPedido', headerName: 'Quantidade Pedido', minWidth: 100 },
        {
            field: 'vlTotal',
            headerName: 'Total Faturado',
            type: 'number',
            align: 'right',
            width: 130,
            minWidth: 110,
            valueFormatter: ({ value }) => (value ? brlPrice(value) : null),
        },
        { field: 'fgDisponivelCompra', headerName: 'Disponível para Compra', type: 'boolean', minWidth: 100 },
    ];

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: index + 1,
                vlTotal: item.vlTotal,
                qtPedido: item.qtPedido,
                vlUnitario: item.vlPreco,
                fgDisponivelCompra: item.fgDisponivelCompra,
                lkImagem: item.produto.lkImagem,
                cdProduto: item.produto.cdProduto,
                dsProduto: item.produto.dsProduto,
                qtMinimaCompra: item.produto.qtMinimaCompra,
                dsSubgrupo: item.produto.subgrupo.dsSubgrupo,
            }));
        }

        return [];
    }, [data]);

    return (
        <React.Fragment>
            <SubTitle label={'Lista de Produtos'} sx={{ ml: 2, mt: 2 }} />

            <Box sx={{ mx: 2, mb: 2 }}>
                <PanelGrid
                    rows={rows}
                    rowsCount={rows.length}
                    loading={fetchStatus === 'fetching'}
                    reload={refetch}
                    columns={columns}
                    remote={false}
                />
            </Box>
        </React.Fragment>
    );
}
