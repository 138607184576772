import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { MetaAutoComplete, TipoLogisticaLojaAutoComplete } from 'components/autocompletes';
import { PanelGrid } from 'components/grid';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';
import { useFormContext } from 'context/FormContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ConfiguracaoPedidoCompraRemoveBtn } from 'pages/compra/configuracao-pedido/components/atoms/ConfiguracaoPedidoCompraRemoveBtn';
import { LojaMetaAtingidaProps, LojasProps, MetaProps, LojasMetasRequestProps, TipoLogisticaLojaProps } from 'pages/compra/configuracao-pedido/types';

interface ConfiguracaoPedidoCompraImportacaoMetasGridProps {
    valueFilterLojas: LojasProps[];
    valueFilterLogisticaLoja: TipoLogisticaLojaProps[];
    valueFilterMetas: MetaProps | null;
    removeLojas: (idList: number[]) => void;
    setSelectedRows: Dispatch<SetStateAction<number[]>>;
}

export function ConfiguracaoPedidoCompraLojasMetasGrid({
    valueFilterLojas,
    valueFilterMetas,
    valueFilterLogisticaLoja,
    removeLojas,
    setSelectedRows,
}: ConfiguracaoPedidoCompraImportacaoMetasGridProps): JSX.Element | null {
    const { content, setContent } = useFormContext();
    const { metasList } = content ?? {};
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();
    const RequestListagem = useRequestListagem();
    const { id } = useParams();

    const handleAlterarFields = useCallback(
        (idLoja: number, value: string | number | object | boolean, nameField: string): void => {
            const newMetasList = metasList.map((metaLoja: LojaMetaAtingidaProps) => {
                if (metaLoja.loja?.idLoja === idLoja) {
                    return { ...metaLoja, [nameField]: value };
                }

                return metaLoja;
            });

            setContent({ ...content, metasList: newMetasList });

            setActiveStepDirty();
        },
        [content, metasList, setActiveStepDirty, setContent],
    );

    const filterLojas = useCallback(() => {
        const listIdLojasFilter = valueFilterLojas.map((loja: LojasProps) => loja.idLoja);
        const listIdLogisticaLojasFilter = valueFilterLogisticaLoja.map((logisticaLoja: TipoLogisticaLojaProps) => logisticaLoja.idTipoLogisticaLoja);

        return metasList.filter((metaLoja: LojaMetaAtingidaProps) => {
            const hasLogisticaLoja = !listIdLogisticaLojasFilter.length || (metaLoja.tipoLogisticaLoja && listIdLogisticaLojasFilter.includes(metaLoja.tipoLogisticaLoja.idTipoLogisticaLoja));
            const hasLoja = !listIdLojasFilter.length || listIdLojasFilter.includes(metaLoja.loja.idLoja);
            const hasMeta = !valueFilterMetas || valueFilterMetas.vlMeta === metaLoja.fgMetaAtingida;

            return hasLoja && hasMeta && hasLogisticaLoja;
        });
    }, [metasList, valueFilterLogisticaLoja, valueFilterLojas, valueFilterMetas]);

    const getMetaAtingida = useCallback((fgMetaAtingida: boolean) => {
        if (fgMetaAtingida) {
            return {
                idMeta: 1,
                vlMeta: true,
                dsMeta: 'Sim',
            };
        }

        return {
            idMeta: 2,
            vlMeta: false,
            dsMeta: 'Não',
        };
    }, []);

    const mapLojas = useCallback(
        (item: LojaMetaAtingidaProps) => ({
            id: item.loja.idLoja,
            idLoja: item.loja.idLoja,
            cdLoja: item.loja.cdLoja,
            dsNomeFantasia: item.loja.dsNomeFantasia,
            fgMetaAtingida: item.fgMetaAtingida,
            metaAtingida: getMetaAtingida(item.fgMetaAtingida),
            tipoLogisticaLoja: item.tipoLogisticaLoja,
        }),
        [getMetaAtingida],
    );

    const generateLojasList = useMemo((): GridRowsProp => {
        if (Array.isArray(metasList) && metasList.length) {
            return filterLojas().map(mapLojas);
        }

        return [];
    }, [filterLojas, mapLojas, metasList]);

    const requestOptions: RequestOptionsType = {
        url: `/gestao/lojametaatingida/configpedido/${id}`,
    };

    const { fetchStatus } = useQuery([requestOptions], (): Promise<LojasMetasRequestProps> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(id),
        onSuccess: (response) => setContent({ metasList: response.lojasMetaAtingida, initialMetasList: response.lojasMetaAtingida, tipoPercentual: response.percentualNotaRoyaltiesSazonal }),
    });

    const columns: GridColDef[] = [
        { field: 'cdLoja', headerName: 'Código', type: 'number', minWidth: 80, align: 'center', headerAlign: 'center' },
        { field: 'dsNomeFantasia', headerName: 'Nome da Loja', type: 'string', minWidth: 100, flex: 1 },
        {
            field: 'fgMetaAtingida',
            headerName: 'Meta',
            type: 'string',
            minWidth: 110,
            flex: 1,
            renderCell: ({ row }) => (
                <MetaAutoComplete sx={{ flex: 1 }} label="" size="small" value={row.metaAtingida} onChange={(e, newMeta) => handleAlterarFields(row.idLoja, newMeta.vlMeta, 'fgMetaAtingida')} />
            ),
        },
        {
            field: 'tipoLogisticaLoja',
            headerName: 'Tipo de Loja',
            type: 'string',
            minWidth: 110,
            flex: 1,
            renderCell: ({ row }) => (
                <TipoLogisticaLojaAutoComplete
                    sx={{ flex: 1 }}
                    size="small"
                    value={row.tipoLogisticaLoja}
                    onChange={(e, newTipoLogisticaLoja) => handleAlterarFields(row.idLoja, newTipoLogisticaLoja, 'tipoLogisticaLoja')}
                />
            ),
        },
        {
            field: 'remover',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Remover',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 110,
            renderCell: ({ row }) => <ConfiguracaoPedidoCompraRemoveBtn onClick={() => removeLojas([row.idLoja])} />,
        },
    ];

    return (
        <PanelGrid
            rows={generateLojasList}
            rowsCount={generateLojasList.length || 0}
            reload={false}
            loading={fetchStatus === 'fetching'}
            columns={columns}
            remote={false}
            checkboxSelection
            onClickRow={(selectionModel) => setSelectedRows(selectionModel as number[])}
        />
    );
}
