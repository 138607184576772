import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { EstatisticasListComp } from 'pages/estatisticas/components/pages/EstatisticasList';

export function EstatisticasList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['ESTATISTICA_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <EstatisticasListComp />
        </ListagemProvider>
    );
}
