import React, { useMemo } from 'react';

import { Button, SxProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { RiFileExcel2Fill } from 'react-icons/ri';

import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

interface DownloadModeloArquivoBtn {
    dsChave: string;
    sx?: SxProps;
}

export function DownloadModeloArquivoBtn({ dsChave, sx }: DownloadModeloArquivoBtn): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/config/configgeral/chave',
            extraParams: [{ dsChave }],
        }),
        [dsChave],
    );

    const { data } = useQuery([requestOptions], (): Promise<string> => RequestListagem(requestOptions).then((res) => res.data));

    return (
        <Button href={data} variant="outlined" color="success" hidden={Boolean(!data)} startIcon={<RiFileExcel2Fill />} sx={sx}>
            Download do modelo do arquivo
        </Button>
    );
}
