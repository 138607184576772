import React from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoLog } from 'pages/compra/pedido-realizado/types';
import { brlDateTime } from 'util/format';

type PedidoLogGrid = {
    idPedido: number;
};

const getRows = (data: PedidoLog[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idPedidoLog,
            dhInclusao: item.dhInclusao && brlDateTime(`${item.dhInclusao}`),
            dsTipoLogPedido: `${item.tipoLogPedido.dsTipoLogPedido} ${item.dsMensagem || ''}`,
            dsUsuario: item.usuarioInclusao?.dsUsuario,
        }));
    }

    return [];
};

const columns: GridColDef[] = [
    { field: 'dhInclusao', headerName: 'Data', minWidth: 130 },
    { field: 'dsTipoLogPedido', headerName: 'Descrição', flex: 1, minWidth: 90 },
    { field: 'dsUsuario', headerName: 'Usuário', flex: 1, minWidth: 90 },
];

export function PedidoLogGrid({ idPedido }: PedidoLogGrid): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: `/compra/pedidolog/pedido/${idPedido}`,
    };

    const { data, isLoading, refetch } = useQuery<PedidoLog[]>([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data));

    return (
        <PanelGrid
            autoHeight={false}
            rows={getRows(data)}
            rowsCount={data?.length || 0}
            columns={columns}
            loading={isLoading}
            reload={refetch}
            remote={false}
        />
    );
}
