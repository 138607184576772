import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DreExportarRelatorioBtn, DreReportType } from 'pages/financeiro/dre/relatorio/components/atoms/DreExportarRelatorioBtn';

import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { FilterValuesProps } from 'hooks/useFilter';

interface DrePainelExportacaoProps {
    reportType: DreReportType;
    disableBtn: boolean;
    filter: FilterValuesProps[];
}

export function DrePainelExportacao({ disableBtn, reportType, filter }: DrePainelExportacaoProps): JSX.Element {
    return (
        <Stack direction="row" alignItems="center" sx={{ my: 2, gap: 2 }} flexWrap="wrap">
            <Box sx={{ flex: 1, display: 'inherit', alignItems: 'inherit' }}>
                <TableViewOutlinedIcon color="primary" />

                <Typography sx={{ ml: 2, mr: 1 }}>RELATÓRIOS REFERENTES AO PERÍODO SELECIONADO</Typography>

                <ChevronRightOutlinedIcon color="primary" />
            </Box>

            <DreExportarRelatorioBtn disabled={disableBtn} reportType={reportType} label={'Relatório em .xlsx'} sx={{ color: 'green', borderColor: 'green' }} filter={filter} />
        </Stack>
    );
}
