import React, { Dispatch, SetStateAction } from 'react';

import { Chip, Tooltip } from '@mui/material';

import { PedigoLog } from 'pages/compra/carrinho/types';

interface PedidoCompraTotalizadorSituacaoColumnProps {
    idStatus: number;
    pedidoTotalizadorValidacoes: PedigoLog[];
    setOpenFalhasModal: Dispatch<SetStateAction<PedigoLog[] | boolean>>;
}

export function CarrinhoSituacaoColumn({
    idStatus,
    pedidoTotalizadorValidacoes,
    setOpenFalhasModal,
}: PedidoCompraTotalizadorSituacaoColumnProps): JSX.Element {
    let color: 'error' | 'info' | 'success' | 'warning' = 'warning';
    let msgTooltip = null;
    let title = null;

    switch (idStatus) {
        case 1:
            color = 'info';
            title = 'Aguardando Finalizar';
            break;
        case 2:
            title = 'Processando';
            break;
        case 3:
            color = 'error';
            title = 'Falha ao Finalizar';
            msgTooltip = 'Visualizar Falhas';
            break;
    }

    return (
        <Tooltip title={msgTooltip || ''}>
            <Chip
                label={title}
                variant="outlined"
                size="small"
                color={color}
                sx={{ width: '100%' }}
                onClick={
                    idStatus === 3
                        ? () => {
                              setOpenFalhasModal(pedidoTotalizadorValidacoes);
                          }
                        : undefined
                }
            />
        </Tooltip>
    );
}
