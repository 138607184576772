import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material/';

import { MenuMainItem } from 'components/menu/main/components/MenuMainItem';
import { MenuItemProps } from 'components/menu/main/types';
import { usePermissionsContext } from 'context/PermissionsContext';

interface NestedAcordionProps {
    item: MenuItemProps;
    open: boolean;
    setOpen: (action: boolean) => void;
}

/**
 * Manipula abertura/fechamento dos accordions, levando em consideração seu pai (parent)
 * @param idPanel{number} Id do painel a ser aberto/fechado
 * @param idPanel{boolean} Novo estado do painel (fechado/aberto)
 */

export function MenuMainMobileNestedAcordion({ item, setOpen, open }: NestedAcordionProps): JSX.Element | null {
    const { items, route, permissions } = item;
    const { hasAnyPermission } = usePermissionsContext();
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    if (permissions && permissions.length > 0 && !hasAnyPermission(permissions)) {
        return null;
    }

    return (
        <Accordion
            disableGutters
            elevation={0}
            TransitionProps={{ unmountOnExit: true }}
            expanded={isExpanded}
            onChange={() => (items && items.length > 0 ? setIsExpanded(!isExpanded) : null)}
            sx={{
                '&.Mui-expanded': {
                    backgroundColor: 'grey.200',
                },
            }}
        >
            <AccordionSummary expandIcon={items && items.length > 0 ? <ExpandMoreIcon /> : null}>
                <MenuMainItem
                    menuItem={item}
                    sx={{ borderRadius: 1 }}
                    callback={() => {
                        if (route) {
                            navigate(route);
                            setOpen(false);
                        }
                    }}
                />
            </AccordionSummary>

            {items && items.length > 0 && (
                <AccordionDetails>
                    {items.map((itemNested) => (
                        <MenuMainMobileNestedAcordion key={Math.random()} item={itemNested} setOpen={setOpen} open={open} />
                    ))}
                </AccordionDetails>
            )}
        </Accordion>
    );
}
