import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';

import { CategoriaPecaAutoComplete, StatusSolicitacaoPecaAutoComplete } from 'components/autocompletes';
import { CissTextField } from 'components/fields';
import { Filter } from 'components/page';
import { useFilter, FilterValuesProps } from 'hooks/useFilter';
import { TradeSolicitaoPecasStoreAutoComplete } from 'pages/marketing-trade/trade/solicitacoes-pecas/components';
import { TypeMarketing } from 'pages/marketing-trade/types';

export function TradeSolicitacaoPecasListFilter(): JSX.Element {
    const { handleFilterSubmit, handleFilterReset, setDefaultFilters } = useFilter();

    const today = new Date();

    const [filterInterval, setFilterInterval] = useState<FilterValuesProps>({
        property: 'dhInclusao',
        operator: 'bt',
        startDate: new Date(today.setDate(today.getDate() - 30)),
        endDate: new Date(),
        required: 'Intervalo de inclusão é obrigatório',
    });

    const [filterLoja, setFilterLoja] = useState<FilterValuesProps>({
        property: 'cdLoja',
        operator: 'eq',
        value: null,
        idProperty: 'cdLoja',
    });

    const [filterCategoria, setFilterCategoria] = useState<FilterValuesProps>({
        property: 'idCategoriaPeca',
        operator: 'eq',
        value: null,
        idProperty: 'idCategoriaPeca',
    });

    const [filterStatus, setFilterStatus] = useState<FilterValuesProps>({
        property: 'idSolicitacaoPecaStatus',
        operator: 'eq',
        value: null,
        idProperty: 'idSolicitacaoPecaStatus',
    });

    const filterTipoMarketing: FilterValuesProps = {
        property: 'idTipoMarketing',
        operator: 'eq',
        value: TypeMarketing.TRADE,
    };

    const filters = [
        { value: filterInterval, setter: setFilterInterval },
        { value: filterLoja, setter: setFilterLoja },
        { value: filterCategoria, setter: setFilterCategoria },
        { value: filterStatus, setter: setFilterStatus },
        { value: filterTipoMarketing },
    ];

    const startFilter = (): void => {
        handleFilterSubmit({ filters });
    };

    const resetFilter = (): void => {
        handleFilterReset({ filters });
    };

    useEffect(() => {
        setDefaultFilters({ filters });
    }, []);

    return (
        <Filter reset={resetFilter} submit={startFilter}>
            <Grid item flex={2}>
                <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns} localeText={{ start: 'Data Início', end: 'Data Fim' }}>
                    <DateRangePicker
                        value={[filterInterval.startDate ?? null, filterInterval.endDate ?? null]}
                        onChange={([startDate, endDate]) => {
                            setFilterInterval((oldState: FilterValuesProps) => ({
                                ...oldState,
                                startDate,
                                endDate,
                            }));
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <CissTextField {...startProps} autoComplete="off" />
                                <Box sx={{ mx: 2 }}> até </Box>
                                <CissTextField {...endProps} autoComplete="off" />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item flex={1}>
                <TradeSolicitaoPecasStoreAutoComplete value={filterLoja.value} onChange={(e, value) => setFilterLoja((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <CategoriaPecaAutoComplete value={filterCategoria.value} multiple={false} onChange={(e, value) => setFilterCategoria((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>

            <Grid item flex={1}>
                <StatusSolicitacaoPecaAutoComplete value={filterStatus.value} onChange={(e, value) => setFilterStatus((oldState: FilterValuesProps) => ({ ...oldState, value }))} />
            </Grid>
        </Filter>
    );
}
