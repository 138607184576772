import React, { useState } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CargoImportarModal } from 'pages/complementar/cargo/components/templates/CargoImportarModal';
import { CargoListFilter } from 'pages/complementar/cargo/components/templates/CargoListFilter';
import { Cargo } from 'pages/complementar/cargo/types';
import { brlPrice } from 'util/format';

interface ResponseData {
    data: Cargo[];
    hasNext: boolean;
    total: number;
}

export function CargoListComp(): JSX.Element {
    const { sort, page, filter, limit } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const [openImportarModal, setOpenImportarModal] = useState<boolean>(false);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/cargo',
        columns: 'idCargo,dsCargo,vlLimiteCredito,cdCargo',
        sort: sort?.length ? sort : [{ property: 'idCargo', direction: 'DESC' }],
        page,
        filter,
        limit,
    };

    const { data, refetch, fetchStatus } = useQuery(['getListCargo', requestOptions], (): Promise<ResponseData> => RequestListagem(requestOptions).then((res) => res.data));

    const columns: GridColDef[] = [
        { field: 'dsCargo', headerName: 'Descrição Cargo', flex: 1, minWidth: 100 },
        { field: 'vlLimiteCredito', headerName: 'Limite de Crédito', flex: 1, width: 100, minWidth: 110, valueFormatter: ({ value }) => brlPrice(value) },
        { field: 'cdCargo', headerName: 'Código de integração', flex: 1, minWidth: 100 },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            width: 80,
            minWidth: 60,
            renderCell: (params) => (
                <EditRemoveBtn id={params.row.idCargo} sx={{ ml: 'auto', p: 1.5 }} showEditBtn={true} showRemoveBtn={true} removeUrl={`/gestao/cargo/${params.row.idCargo}`} refresh={refetch} />
            ),
        },
    ];

    const gridRows = (rows: Cargo[] | undefined): GridRowsProp => {
        if (!rows) return [];
        return rows.map((row, index) => {
            return { ...row, id: index + 1 };
        });
    };

    return (
        <React.Fragment>
            <CargoImportarModal open={openImportarModal} setOpen={setOpenImportarModal} />

            <OneColumn title="Cargos e Créditos" createButton extraButtons={[{ title: 'Importar', onClick: () => setOpenImportarModal(true) }]}>
                <CargoListFilter />

                <Block>
                    <PanelGrid rows={gridRows(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} visibleRows={10} />
                </Block>
            </OneColumn>
        </React.Fragment>
    );
}
