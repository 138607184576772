import React, { Dispatch, forwardRef, SetStateAction, useEffect, useRef } from 'react';

import { Box, Divider } from '@mui/material';

import { RichContent } from 'components/content';
import { Imagem } from 'components/images';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { ComunicadoIndex } from 'pages/inicio/types';

type ComunicadoContent = {
    comunicado: ComunicadoIndex;
    liEstouCienteDisabled: boolean;
    setLiEstouCienteDisabled: Dispatch<SetStateAction<boolean>>;
};

type ImagemContent = {
    comunicado: ComunicadoIndex;
    isDownMd: boolean;
};

const ImagemContent = ({ comunicado }: ImagemContent): JSX.Element => (
    <React.Fragment>
        {comunicado.dsLinkCapa && (
            <Box sx={{ flex: 4, height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', my: { xs: 1, md: 0 } }}>
                <Imagem src={comunicado.dsLinkCapa} style={{ maxHeight: '100%', borderRadius: 4 }} />
            </Box>
        )}
    </React.Fragment>
);

export function ComunicadoContent({ comunicado, liEstouCienteDisabled, setLiEstouCienteDisabled }: ComunicadoContent): JSX.Element {
    const conteudoRef = useRef<HTMLDivElement>(null);
    const { isDownMd } = useBreakpoints();

    useEffect(() => {
        if (conteudoRef && conteudoRef.current) {
            conteudoRef.current.scrollTop = 0;

            const hasScroll = conteudoRef.current.scrollHeight > conteudoRef.current.clientHeight;

            if (!hasScroll) {
                setLiEstouCienteDisabled(false);
            }
        }
    }, [comunicado]);

    return (
        <Box
            sx={{
                pl: { xs: 0, md: 2 },
                gap: { xs: 0.5, md: 2 },
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                height: { xs: '40vh', md: 400 },
            }}
        >
            {!isDownMd && <ImagemContent comunicado={comunicado} isDownMd={isDownMd} />}

            {(comunicado.dsConteudo || comunicado.fgSolicitaConfirmacao) && (
                <Box
                    ref={conteudoRef}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        pr: 2,
                        pl: { xs: 2, md: 0 },
                        flex: 8,
                    }}
                    onScroll={(e) => {
                        const isOnBottom = e.currentTarget.scrollHeight - Math.ceil(e.currentTarget.scrollTop) <= e.currentTarget.clientHeight + 64;

                        if (isOnBottom && liEstouCienteDisabled) {
                            setLiEstouCienteDisabled(false);
                        }
                    }}
                >
                    {isDownMd && <ImagemContent comunicado={comunicado} isDownMd={isDownMd} />}

                    <Box sx={{ py: 1 }}>
                        <RichContent dangerouslySetInnerHTML={{ __html: comunicado.dsConteudo }} />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
