import React, { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Checkbox } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useFormikContext } from 'formik';

import { CissNumberField } from 'components/fields';
import { PanelGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { LojaRequest, LojaBandaPreco } from 'pages/cadastro/loja/types';

const getRows = (data: LojaBandaPreco[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index,
            idBandaPreco: item.idBandaPreco,
            dsBandaPreco: item.bandaPreco.dsBandaPreco,
            vlPercentual: item.vlPercentual,
            fgAtivo: item.fgAtivo,
            bandaPreco: {
                dsBandaPreco: item.bandaPreco.dsBandaPreco,
                idBandaPreco: item.idBandaPreco,
            },
        }));
    }
    return [];
};

export function LojaFormBandaPrecoGrid(): JSX.Element {
    const { values, setFieldValue } = useFormikContext<LojaRequest>();
    const RequestListagem = useRequestListagem();
    const { id } = useParams();

    const setInitialLojaBandaPrecos = (value: LojaBandaPreco[]): void => {
        const lojaConfiguracoes = value.map((item: LojaBandaPreco) => ({
            ...item,
            fgAtivo: false,
            vlPercentual: 0,
            bandaPreco: { idBandaPreco: item.idBandaPreco, dsBandaPreco: item.dsBandaPreco },
        }));

        setFieldValue('lojaBandaPrecos', lojaConfiguracoes);
    };

    const requestConfigBandaPrecoQueryOptions: RequestOptionsType = {
        url: '/gestao/bandapreco',
        sort: [{ property: 'idBandaPreco', direction: 'ASC' }],
        columns: 'idBandaPreco,dsBandaPreco,fgAtivo',
        limit: 20,
    };

    const { refetch, fetchStatus } = useQuery(
        ['getLojaBandaPrecos'],
        () => RequestListagem(requestConfigBandaPrecoQueryOptions).then((res) => res.data.data),
        {
            enabled: !id,
            onSuccess: setInitialLojaBandaPrecos,
        },
    );

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'dsBandaPreco', headerName: 'Banda de Preço', minWidth: 120, flex: 1 },
            {
                field: 'fgAtivo',
                headerName: 'Ativo',
                filterable: false,
                sortable: false,
                hideable: false,
                maxWidth: 50,
                disableColumnMenu: false,
                renderCell: ({ row }) => {
                    return (
                        <Checkbox
                            checked={row.fgAtivo}
                            onChange={(e) => setFieldValue(`lojaBandaPrecos[${row.id}]`, { ...row, fgAtivo: e.target.checked })}
                        />
                    );
                },
            },
            {
                field: 'Valor',
                align: 'center',
                headerName: 'Valor',
                headerAlign: 'center',
                sortable: false,
                hideable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 140,
                renderCell: ({ row }) => {
                    return (
                        <CissNumberField
                            size="small"
                            inputType="decimal"
                            value={row.vlPercentual}
                            onChange={(e) => setFieldValue(`lojaBandaPrecos[${row.id}]`, { ...row, vlPercentual: e.target.value })}
                        />
                    );
                },
            },
        ],
        [setFieldValue],
    );

    const rows = getRows(values.lojaBandaPrecos);

    return (
        <PanelGrid
            hideFooter={true}
            rows={rows}
            rowsCount={rows.length}
            reload={refetch}
            loading={fetchStatus === 'fetching'}
            columns={columns}
            remote={false}
        />
    );
}
