import React from 'react';

import { brlPercentual, brlPrice } from 'util/format';

import { Block } from 'components/page';
import { RoyaltyNotaProdutoModelProps } from 'pages/financeiro/extrato/royalty-espelho-nota-produto/types';
import { GridColDef, GridColumnGroupingModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { PanelGrid } from 'components/grid';
import { NotaProdutoGridTooltip } from 'pages/financeiro/extrato/royalty-espelho-nota-produto/components';
import { Tooltip } from '@mui/material';

// TODO unificar com a mesma função em src/pages/financeiro/extrato/fpp-espelho-nota-produto/components/templates/FppEspelhoNotaProdutoGrid.tsx
const configBaseFaturamentoIsFpp = (configVlBaseFaturamento: string): boolean => {
    return configVlBaseFaturamento === 'vlPrecoFpp';
};

const generateNotaProdutoList = (data: RoyaltyNotaProdutoModelProps[] | undefined, configVlBaseFaturamento: string): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index,
            cdProduto: item.cdProduto,
            dsProduto: item.dsProduto,
            qtProduto: item.qtProduto,
            vlNotaFiscal: item.vlNotaFiscal,
            nrTaxa: item.peNotaDebito,
            vlRoyaltyBruto: item.vlRoyaltyBruto,
            vlTotalUnitario: item.vlTotalUnitario,
            vlTotal: item.vlTotal,
            vlConsumidor: item.vlConsumidor,
            vlFabrica: item.vlFabrica,
            fppVlDiferencaTabela: !configBaseFaturamentoIsFpp(configVlBaseFaturamento)
                ? item.vlConsumidor - item.vlFabrica
                : item.royaltyProdutoFpp.vlDiferencaTabela,
            fppVlTotal: item.royaltyProdutoFpp.vlTotal,
            vlTotalProduto: item.vlTotalProduto,
            vlNotaFiscalComIpi: item.vlNotaFiscalComIpi,
            vlTotalProdutoBaseFaturamento: item.vlTotalProdutoBaseFaturamento,
        }));
    }

    return [];
};

export function NotaProdutoGrid({
    royaltyNotaProdutos,
    configVlBaseFaturamento,
}: {
    royaltyNotaProdutos: RoyaltyNotaProdutoModelProps[];
    configVlBaseFaturamento: string;
}): JSX.Element {
    const columnGroupingModel: GridColumnGroupingModel = [
        {
            groupId: 'tabelPreco',
            headerName: 'Cobrança Produtos',
            children: [
                { field: 'vlNotaFiscalComIpi' },
                { field: 'vlNotaFiscal' },
                { field: 'nrTaxa' },
                { field: 'vlRoyaltyBruto' },
                { field: 'vlTotalUnitario' },
                { field: 'vlTotal' },
            ],
        },
        {
            groupId: 'final',
            headerName: 'Tabela de Preço',
            children: [{ field: 'vlConsumidor' }, { field: 'vlFabrica' }],
        },
    ];

    const columns: GridColDef[] = [
        {
            field: 'cdProduto',
            headerName: 'Cód. Produto',
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'dsProduto',
            headerName: 'Produto',
            flex: 1,
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <Tooltip title={row.dsProduto}>
                    <span>{row.dsProduto}</span>
                </Tooltip>
            ),
        },

        {
            field: 'qtProduto',
            headerName: 'Qtd',
            minWidth: 50,
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'vlTotalProduto',
            type: 'number',
            width: 100,
            minWidth: 80,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => <NotaProdutoGridTooltip tooltip="Custo total por produto">Total</NotaProdutoGridTooltip>,
        },
        {
            field: 'vlNotaFiscalComIpi',
            type: 'number',
            minWidth: 80,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Total de produtos da nota fiscal com IPI.">
                    Total NF <br />
                    Com IPI
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'vlNotaFiscal',
            headerName: 'Total NF S/ Imposto',
            type: 'number',
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Total de produtos da nota fiscal sem impostos.">
                    Total NF <br />
                    Sem Imposto
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'nrTaxa',
            headerName: 'Percentual de Royalties',
            type: 'number',
            minWidth: 110,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPercentual(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Percentual de Royalties cobrado, conforme pedido realizado.">
                    Percentual <br />
                    de Royalties
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'vlRoyaltyBruto',
            headerName: 'Royalties Bruto',
            type: 'number',
            width: 100,
            minWidth: 90,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Quantidade x Preço fábrica x Percentual de Royalties = Royalties Bruto.">
                    Royalties <br />
                    Bruto
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'vlTotalUnitario',
            type: 'number',
            minWidth: 120,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Custo Total Unitário Produto: Valor Total de Custo Produto N.F + Royalties Bruto (dividido), pela quantidade.">
                    Custo Total <br />
                    Unit. Produto
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'vlTotal',
            width: 125,
            minWidth: 100,
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => <NotaProdutoGridTooltip tooltip="Soma Total de Produtos N.F + Royalties Bruto.">Custo Total</NotaProdutoGridTooltip>,
            valueFormatter: ({ value }) => brlPrice(value),
        },
        {
            field: 'vlConsumidor',
            headerName: 'Consumidor',
            minWidth: 100,
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
        },
        {
            field: 'vlFabrica',
            minWidth: 100,
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Total Base Faturamento">
                    Faturamento
                    <br />
                    Unit.
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'vlTotalProdutoBaseFaturamento',
            minWidth: 100,
            type: 'number',
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Total Base Faturamento">
                    Total base
                    <br />
                    Faturamento
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'fppVlDiferencaTabela',
            type: 'number',
            minWidth: 110,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Diferença Tabelas Valor Unitário: Diferença entre Tabela de Preço Consumidor - Tabela de Preço Fábrica">
                    Vlr. FPP Unit.
                </NotaProdutoGridTooltip>
            ),
        },
        {
            field: 'fppVlTotal',
            type: 'number',
            minWidth: 110,
            sortable: false,
            disableColumnMenu: true,
            valueFormatter: ({ value }) => brlPrice(value),
            renderHeader: () => (
                <NotaProdutoGridTooltip tooltip="Total Diferenças Tabelas: Total Unit. Diferença entre as tabelas x quantidade = Valor Total de FPP">
                    Vlr. FPP Total
                </NotaProdutoGridTooltip>
            ),
        },
    ];

    const rows = generateNotaProdutoList(royaltyNotaProdutos, configVlBaseFaturamento);

    return (
        <Block>
            <PanelGrid
                rows={rows}
                rowsCount={rows.length}
                reload={false}
                loading={false}
                columns={columns}
                remote={false}
                summaryPrefix={false}
                summary={['vlTotalProduto', 'vlTotal', 'fppVlTotal', 'vlNotaFiscal', 'vlRoyaltyBruto', 'vlTotalProdutoBaseFaturamento', 'vlFabrica']}
                columnGrouping={columnGroupingModel}
            />
        </Block>
    );
}
