export interface GrupoEconomicoModelProps {
    idGrupoEconomico: number;
    dsGrupoEconomico: string;
    cdGrupoEconomico: string;
    fgAtivo: boolean;
}

export interface RequestGrupoEconomicoListModelProps {
    data: GrupoEconomicoModelProps[];
    total: number;
    hasNext: boolean;
}

export interface RequestGrupoEconomicoModelProps {
    idGrupoEconomico: number;
    dsGrupoEconomico: string;
    cdGrupoEconomico: string;
    fgAtivo: boolean;
}

export const initialGrupoEconomicoFormValues: RequestGrupoEconomicoModelProps = {
    idGrupoEconomico: -1,
    dsGrupoEconomico: '',
    cdGrupoEconomico: '',
    fgAtivo: true,
};
