import React from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

type Grade = {
    dtEntrega: string;
    dtLimitePedido: string;
};

export function useGetDataEntrega(): UseQueryResult<Grade> {
    const requestListagem = useRequestListagem();
    const { configPedido, loja } = usePedidoCompraContext();
    const idLoja = loja?.idLoja;
    const idConfigPedido = configPedido?.idConfigPedido;

    const requestOptions: RequestOptionsType = {
        url: '/gestao/grade/dataentrega',
        columns: 'idGrade,dtEntrega',
        filter: [
            { property: 'idLoja', operator: 'eq', value: idLoja },
            { property: 'idConfigPedido', operator: 'eq', value: idConfigPedido },
        ],
    };

    return useQuery<Grade>([requestOptions], () => requestListagem(requestOptions).then((res) => res?.data?.data), {
        enabled: Boolean(idConfigPedido && idLoja),
    });
}
