import * as dateFns from 'date-fns';
import { useSnackbar } from 'notistack';
import { bytesToMb } from 'util/format';

interface validParamsProps {
    [key: string]: unknown;
}

interface useValidationProps {
    notNullValue: (value: any) => boolean;
    validValue: (valye: any) => boolean;
    validDateRange: (dates: Date[]) => boolean;
    validRouterParams: (props: validParamsProps) => boolean;
    validFileSize: (file: any, limit: number) => boolean;
    validObject: (value: any) => boolean;
}

export function useValidation(): useValidationProps {
    const { enqueueSnackbar } = useSnackbar();

    const notNullValue = (value: any): boolean => {
        return Boolean(typeof value !== 'undefined' && value !== null);
    };

    const validValue = (value: any): boolean => {
        return Boolean(notNullValue(value) && value !== '');
    };

    const validObject = (value: any): boolean => {
        return typeof value === 'object' && value !== null;
    };

    const validDateRange = (dates: Date[]): boolean => {
        const [startDate, endDate] = Array.isArray(dates) ? dates : [];
        const validStart = validValue(startDate);
        const validEnd = validValue(endDate);

        let valid = true;
        let msg;

        if (validStart && !validEnd) {
            msg = 'Informe a data de fim para continuar';
        } else if (!validStart && validEnd) {
            msg = 'Informe a data de inicio para continuar';
        } else if (!dateFns.isEqual(startDate, endDate) && dateFns.isBefore(endDate, startDate)) {
            msg = 'A data de inicio deve ser anterior a data de fim';
        }

        if (msg) {
            valid = false;
            enqueueSnackbar(msg, { variant: 'error' });
        }

        return valid;
    };

    const validRouterParams = ({ id, categoria }: validParamsProps): boolean => {
        let valid = false;

        if (typeof id === 'string' && Number.isFinite(Number(id))) {
            valid = true;
        } else if (typeof categoria === 'string' && validValue(categoria)) {
            valid = true;
        }

        return valid;
    };

    const validFileSize = (file: any, limit: number): boolean => {
        const size = bytesToMb(file.size);
        const valid = size <= limit;

        if (!valid) {
            enqueueSnackbar(`Tamanho do arquivo maior que o permitido (permitido: ${limit}MB)`, { variant: 'error' });
        }

        return valid;
    };

    return { notNullValue, validValue, validDateRange, validRouterParams, validFileSize, validObject };
}
