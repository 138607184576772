import React, { useState, useCallback, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, Switch, Tooltip } from '@mui/material';
import { GridColDef, GridRowsProp, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider, useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { ProdutoAlterarImagemModal, ProdutoImagemModal, ProdutoPedidoGrid } from 'pages/compra/produto/components';
import { ProdutoAlterarImagemProps, RequestProdutoList } from 'pages/compra/produto/types';
import { brlDecimal } from 'util/format';

export function ProdutoGrid(): JSX.Element {
    const { hasPermission } = usePermissionsContext();
    const { filter, limit, page, autoLoad, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();
    const { mutate } = useFormMutation();
    const { setContent } = useFormContext();

    const [openAlterarImagemModal, setOpenAlterarImagemModal] = useState<boolean | ProdutoAlterarImagemProps>(false);
    const [openImagemModal, setOpenImagemModal] = useState<boolean | string>(false);

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: hasPermission(['COMPRA_PRODUTO_ALTERAR']) ? '/compra/produto/estoque' : '/gestao/produto',
            sort: sort.length ? sort : [{ property: 'idProduto', direction: 'ASC' }],
            filter,
            limit,
            page,
        }),
        [filter, hasPermission, limit, page, sort],
    );

    const { fetchStatus, data, refetch } = useQuery(
        ['listProdutos', requestOptions],
        (): Promise<RequestProdutoList> => RequestListagem(requestOptions).then((res) => res.data),
        {
            enabled: autoLoad,
            onSuccess: (res) => {
                if (res?.data?.length) {
                    setContent(true);
                } else {
                    setContent(false);
                }
            },
            onError: () => setContent(false),
        },
    );

    const handleAtivarProduto = useCallback(
        (fgAtivo: boolean, idProduto: number): void => {
            mutate({
                url: `/gestao/produto/${idProduto}/ativo`,
                method: 'PUT',
                formData: {
                    fgAtivo,
                },
            });
        },
        [mutate],
    );

    const columns = useMemo(
        (): GridColDef[] => [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: ({ value: isExpanded, row }) => (
                    <React.Fragment>
                        {row.qtEstoqueReservado > 0 && (
                            <IconButton size="small" tabIndex={-1}>
                                {isExpanded ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        )}
                    </React.Fragment>
                ),
            },
            {
                field: 'lkImagem',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Imagem',
                minWidth: 70,
                renderCell: ({ row }) => {
                    if (row.lkImagem) {
                        return (
                            <Tooltip title="Visualizar imagem">
                                <Box
                                    onClick={() => (row.lkImagem ? setOpenImagemModal(row.lkImagem) : null)}
                                    sx={{
                                        minWidth: 50,
                                        minHeight: 50,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        backgroundImage: `url("${row.lkImagem}")`,
                                        cursor: row.lkImagem ? 'pointer' : null,
                                        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: 1,
                                    }}
                                />
                            </Tooltip>
                        );
                    }
                },
            },
            { field: 'cdProduto', headerName: 'Código', align: 'left', headerAlign: 'left', type: 'number', minWidth: 100, flex: 1 },
            { field: 'dsProduto', headerName: 'Produto', type: 'number', align: 'left', headerAlign: 'left', minWidth: 100, flex: 2 },
            { field: 'dsSecao', headerName: 'Segmento', type: 'string', minWidth: 120, flex: 1 },
            { field: 'dsGrupo', headerName: 'Jornada', type: 'string', minWidth: 120, flex: 1 },
            { field: 'dsSubgrupo', headerName: 'Categoria', type: 'string', minWidth: 120, flex: 1 },
            {
                field: 'vlPesoLiquido',
                headerName: 'Peso',
                type: 'string',
                minWidth: 70,
                flex: 1,
                valueFormatter: ({ value }) => value && `${brlDecimal(value, 0, 3)} Kg`,
            },
        ],
        [setOpenImagemModal],
    );

    const permissionedColumns = useMemo(
        (): GridColDef[] => [
            {
                field: 'qtEstoque',
                headerName: 'Estoque',
                type: 'string',
                minWidth: 80,
                flex: 1,
                sortable: false,
                renderCell: ({ row }) => {
                    const { qtEstoque, sgUnidadeMedida } = row;

                    return isNumber(qtEstoque) ? `${qtEstoque} ${sgUnidadeMedida || ''}` : '';
                },
            },
            {
                field: 'qtEstoqueDisponivel',
                headerName: 'Estoque Portal',
                type: 'string',
                minWidth: 80,
                flex: 1,
                sortable: false,
                renderCell: ({ row }) => {
                    const { qtEstoqueDisponivel, sgUnidadeMedida } = row;

                    return isNumber(qtEstoqueDisponivel) ? `${qtEstoqueDisponivel} ${sgUnidadeMedida || ''}` : '';
                },
            },
            { field: 'cdDeposito', headerName: 'Depósito', type: 'string', minWidth: 90, flex: 1, sortable: false },
            { field: 'cdCentroDistribuicao', headerName: 'Centro de Distribuição', type: 'string', minWidth: 90, flex: 1, sortable: false },
            {
                field: 'editar-imagem',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Alterar Imagem',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                minWidth: 90,
                renderCell: ({ row }) => (
                    <IconButton sx={{ p: 0, ml: 0 }} onClick={() => setOpenAlterarImagemModal({ idProduto: row.idProduto, lkImagem: row.lkImagem })}>
                        <AddPhotoAlternateOutlinedIcon />
                    </IconButton>
                ),
            },
            {
                field: 'fgAtivo',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Status',
                hideable: false,
                disableColumnMenu: true,
                minWidth: 70,
                renderCell: ({ row }) => (
                    <Switch
                        checked={row.fgAtivo}
                        onChange={(e) => {
                            row.fgAtivo = e.target.checked;
                            handleAtivarProduto(e.target.checked, row.idProduto);
                        }}
                    />
                ),
            },
        ],
        [handleAtivarProduto, setOpenAlterarImagemModal],
    );

    const notPermissionedColumns = useMemo(
        (): GridColDef[] => [{ field: 'fgAtivo', headerName: 'Ativo', type: 'boolean', minWidth: 120, flex: 1 }],
        [],
    );

    if (hasPermission(['COMPRA_PRODUTO_ALTERAR'])) {
        columns.push(...permissionedColumns);
    } else {
        columns.push(...notPermissionedColumns);
    }

    const rows = useMemo((): GridRowsProp => {
        if (Array.isArray(data?.data) && data?.data.length) {
            return data?.data.map((item, index) => ({
                id: index + 1,
                idProduto: item.idProduto,
                cdProduto: item.cdProduto,
                dsProduto: item.dsProduto,
                dsGrupo: item.dsGrupo,
                dsSecao: item.dsSecao,
                dsSubgrupo: item.dsSubgrupo,
                fgAtivo: item.fgAtivo,
                lkImagem: item.lkImagem,
                qtEstoque: item.produtoEstoque?.qtEstoque,
                cdDeposito: item.produtoEstoque?.cdDeposito,
                cdCentroDistribuicao: item.produtoEstoque?.cdCentroDistribuicao,
                qtEstoqueDisponivel: item.produtoEstoque?.qtEstoqueDisponivel,
                qtEstoqueReservado: item.produtoEstoque?.qtEstoqueReservado,
                sgUnidadeMedida: item.sgUnidadeMedida,
                vlPesoLiquido: item.vlPesoLiquido,
            }));
        }

        return [];
    }, [data]);

    return (
        <React.Fragment>
            <ProdutoImagemModal open={openImagemModal} setOpen={setOpenImagemModal} />

            <ProdutoAlterarImagemModal open={openAlterarImagemModal} setOpen={setOpenAlterarImagemModal} />

            <Block>
                <PanelGrid
                    rows={rows}
                    rowsCount={data?.total ?? 0}
                    reload={refetch}
                    loading={fetchStatus === 'fetching'}
                    columns={columns}
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => (
                        <ListagemProvider>
                            <ProdutoPedidoGrid
                                idProduto={row.idProduto}
                                cdDeposito={row.cdDeposito}
                                cdCentroDistribuicao={row.cdCentroDistribuicao}
                            />
                        </ListagemProvider>
                    )}
                    isTreeData={hasPermission(['COMPRA_PRODUTO_ALTERAR'])}
                />
            </Block>
        </React.Fragment>
    );
}
