import React, { useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';

import { PanelGrid } from 'components/grid';
import { Block, OneColumn } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PreenchimentoListFilter } from 'pages/financeiro/dre/preenchimento/components/templates/PreenchimentoListFilter';
import { PreenchimentoPainelExportacao } from 'pages/financeiro/dre/preenchimento/components/templates/PreenchimentoPainelExportacao';
import { PreenchimentoColumns, PreenchimentoDetail } from 'pages/financeiro/dre/preenchimento/types';

export function PreenchimentoListComp(): JSX.Element {
    const { filter, page, limit, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/dre/dremercadologica/relatoriopreenchimento',
            columns: 'idLoja,cdLoja,dsNomeFantasia,dtInicio,dtFim,idDreStatus,dsDreStatus,dsPeriodo,dhAlteracao',
            sort: [{ property: 'dtInicio', direction: 'ASC' }],
            filter,
            page,
            limit,
        }),
        [filter, limit, page],
    );

    const { data, refetch, fetchStatus } = useQuery([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data), { enabled: autoLoad });

    const columns: GridColDef[] = [
        { field: 'cdLoja', headerName: 'Código da Loja', flex: 1, minWidth: 100 },
        { field: 'dsNomeFantasia', headerName: 'Nome da Loja', flex: 1, minWidth: 90 },
        { field: 'mes', headerName: 'Mês', flex: 1, minWidth: 70 },
        { field: 'ano', headerName: 'Ano', flex: 1, minWidth: 50 },
        { field: 'status', headerName: 'Situação', flex: 1, minWidth: 90 },
        { field: 'dhAlteracao', headerName: 'Última Alteração', flex: 1, minWidth: 110 },
    ];

    const generateRows = (preenchimentoList: PreenchimentoDetail[]): PreenchimentoColumns[] => {
        return preenchimentoList.map((preenchimento, index) => {
            const ano = new Date(`${preenchimento.dtInicio}T00:00:00`).getFullYear().toString();
            const mes = preenchimento.dsMesExtenso.toLowerCase();

            const dhAlteracao = format(new Date(preenchimento.dhAlteracao), 'dd/MM/yyyy');

            return {
                id: index,
                cdLoja: preenchimento.loja.cdLoja,
                dsNomeFantasia: preenchimento.loja.dsNomeFantasia,
                status: preenchimento.dreStatus.dsDreStatus,
                ano,
                mes,
                dhAlteracao,
            };
        });
    };

    const rows = generateRows(data?.data?.data || []);

    return (
        <OneColumn title={'Preenchimento de Estimativa de Resultado'} goBackButton>
            <PreenchimentoListFilter />

            <Block>
                <PreenchimentoPainelExportacao disableBtn={!rows.length} filter={filter} />
            </Block>

            <Block>
                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={true} />
            </Block>
        </OneColumn>
    );
}
