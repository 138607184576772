import React, { useCallback, useMemo } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { DrePainelExportacao } from 'pages/financeiro/dre/relatorio/components/templates/DrePainelExportacao';
import { DreRelatorioGridDataProps } from 'pages/financeiro/dre/relatorio/types';
import { brlPrice } from 'util/format';

interface DreRelatorioGridConsolidadoProps {
    idLoja: string | string[];
    nrAno: string;
}

export function DreRelatorioGridConsolidado({ idLoja, nrAno }: DreRelatorioGridConsolidadoProps): JSX.Element {
    const RequestListagem = useRequestListagem();
    const { hasPermission } = usePermissionsContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/dre/dremercadologica/extrato/consolidado',
            columns: 'idDreMercadologica',
            nested: true,
            filter: [
                { property: 'idLoja', operator: Array.isArray(idLoja) ? 'in' : 'eq', value: idLoja },
                { property: 'nrAno', operator: 'eq', value: nrAno },
            ],
        }),
        [idLoja, nrAno],
    );

    const { fetchStatus, data, refetch } = useQuery([requestOptions], () => {
        const request: Promise<DreRelatorioGridDataProps[]> = RequestListagem(requestOptions).then((res) => res.data.data);
        return request;
    });

    const renderCellValue = (value?: number): string => {
        return value ? brlPrice(value) : '--';
    };

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'title', headerName: 'Valores de', width: 110, renderCell: ({ row }) => <strong>{row.title}</strong> },
            { field: 'janeiro', headerName: 'Janeiro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.janeiro) },
            { field: 'fevereiro', headerName: 'Fevereiro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.fevereiro) },
            { field: 'marco', headerName: 'Março', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.marco) },
            { field: 'abril', headerName: 'Abril', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.abril) },
            { field: 'maio', headerName: 'Maio', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.maio) },
            { field: 'junho', headerName: 'Junho', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.junho) },
            { field: 'julho', headerName: 'Julho', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.julho) },
            { field: 'agosto', headerName: 'Agosto', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.agosto) },
            { field: 'setembro', headerName: 'Setembro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.setembro) },
            { field: 'outubro', headerName: 'Outubro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.outubro) },
            { field: 'novembro', headerName: 'Novembro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.novembro) },
            { field: 'dezembro', headerName: 'Dezembro', flex: 1, type: 'number', minWidth: 100, renderCell: ({ row }) => renderCellValue(row.dezembro) },
            { field: 'acumulado', headerName: 'Acumulado', flex: 1, type: 'number', minWidth: 110, renderCell: ({ row }) => renderCellValue(row.acumulado) },
        ],
        [],
    );

    const generateList = useCallback((data: DreRelatorioGridDataProps[] | undefined): GridRowsProp => {
        if (Array.isArray(data) && data.length) {
            return data.map((item, index) => ({
                id: index + 1,
                title: item.dsExtrato,
                janeiro: item.vlExtrato.janeiro,
                fevereiro: item.vlExtrato.fevereiro,
                marco: item.vlExtrato.março,
                abril: item.vlExtrato.abril,
                maio: item.vlExtrato.maio,
                junho: item.vlExtrato.junho,
                julho: item.vlExtrato.julho,
                agosto: item.vlExtrato.agosto,
                setembro: item.vlExtrato.setembro,
                outubro: item.vlExtrato.outubro,
                novembro: item.vlExtrato.novembro,
                dezembro: item.vlExtrato.dezembro,
                acumulado: item.vlAcumulado,
            }));
        }

        return [];
    }, []);

    const rows = generateList(data);

    return (
        <Block>
            <SubTitle label="Analítico do Período" />

            {hasPermission(['FINANCEIRO_DRE_RELATORIO_EXPORTAR']) && (
                <DrePainelExportacao
                    reportType={'consolidado'}
                    disableBtn={!rows.length}
                    filter={[
                        { property: 'idLoja', operator: 'eq', value: idLoja },
                        { property: 'nrAno', operator: 'eq', value: nrAno },
                    ]}
                />
            )}

            <PanelGrid
                rows={rows}
                rowsCount={rows.length}
                reload={refetch}
                loading={fetchStatus === 'fetching'}
                columns={columns}
                remote={false}
                visibleRows="all"
                columnBorder
                pinnedColumns={
                    isMobile
                        ? {}
                        : {
                              left: ['title'],
                              right: ['acumulado'],
                          }
                }
            />
        </Block>
    );
}
