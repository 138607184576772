import React from 'react';

import { DadosEntregaListComp, DadosEntregaFormComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function DadosEntregaList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_FRETE_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <DadosEntregaListComp />
        </ListagemProvider>
    );
}

export function DadosEntregaForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_FRETE_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <DadosEntregaFormComp />
        </FormProvider>
    );
}
