import { UploadFileArquivoProps } from 'components/upload/types';
import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface ArquivoProps {
    arquivo: UploadFileArquivoProps;
}

export interface TipoDispositivoProps {
    idTipoDispositivo: number;
}

export interface ModuloProps {
    idModulo: number;
}

export interface BannerModuloProps {
    modulo: ModuloProps;
}

export interface RequestBannerModelProps {
    dsBanner?: string;
    nrOrdem?: number;
    dsLinkBanner?: string;
    dtInicioVigencia?: string | null;
    dtFimVigencia?: string | null;
    fgAtivo?: boolean;
    fgPermanente?: boolean;
    arquivo?: ArquivoProps[];
    bannerArquivos?: ArquivoProps[];
    tipoDispositivo?: TipoDispositivoProps | null;
    bannerModulos?: BannerModuloProps[];
}

export interface RequestGetBannerModelProps extends Partial<RequestListagemProps> {
    status: number;
    data: {
        data: RequestBannerModelProps;
    };
}

export const initialBannerValues: RequestBannerModelProps = {
    dsBanner: '',
    dsLinkBanner: '',
    dtInicioVigencia: null,
    dtFimVigencia: null,
    fgAtivo: true,
    fgPermanente: false,
    bannerArquivos: [],
    arquivo: [],
    tipoDispositivo: null,
    bannerModulos: [],
};
