import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { PeriodosHistorico } from 'components/autocompletes/PeriodoHistoricoAutoComplete';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { PedidoProdutoModalHistoricoGrupoPeriodo } from 'pages/compra/pedido/components';
import { HistoricoPedidos, ProdutoPedido } from 'pages/compra/pedido/types';

interface PedidoCompraProdutoHistorico {
    produto: ProdutoPedido;
    periodoHistorico: PeriodosHistorico;
    open: boolean;
}

interface RequestHistoricoPedidos {
    historicoPedidosCompra: HistoricoPedidos[];
    historicoPedidosVenda: HistoricoPedidos[];
}

export function PedidoProdutoModalHistorico({ produto, periodoHistorico, open }: PedidoCompraProdutoHistorico): JSX.Element {
    const { loja } = usePedidoCompraContext();
    const RequestListagem = useRequestListagem();

    const [historicoPedidosCompra, setHistoricosPedidoCompra] = useState<HistoricoPedidos[]>([]);
    const [historicoPedidosVenda, setHistoricosPedidoVenda] = useState<HistoricoPedidos[]>([]);

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/catalogoproduto/informacoes-adicionais?',
        extraParams: [{ idProduto: produto.produto.idProduto }, { idLoja: loja?.idLoja }, { tipo: periodoHistorico }],
    };

    const { isFetching } = useQuery([requestOptions], (): Promise<RequestHistoricoPedidos> => RequestListagem(requestOptions).then((res) => res.data), {
        enabled: Boolean(produto && periodoHistorico),
        onSuccess: (response) => {
            setHistoricosPedidoCompra(response.historicoPedidosCompra);
            setHistoricosPedidoVenda(response.historicoPedidosVenda);
        },
    });

    useEffect(() => {
        if (!open) {
            setHistoricosPedidoCompra([]);
            setHistoricosPedidoVenda([]);
        }
    }, [open]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <PedidoProdutoModalHistoricoGrupoPeriodo
                titulo={
                    <React.Fragment>
                        Histórico de
                        <span>Pedidos</span>
                    </React.Fragment>
                }
                historicoPedidos={historicoPedidosCompra}
                isFetching={isFetching}
                produto={produto}
            />

            <PedidoProdutoModalHistoricoGrupoPeriodo
                titulo={
                    <React.Fragment>
                        Histórico de
                        <span>Vendas</span>
                    </React.Fragment>
                }
                historicoPedidos={historicoPedidosVenda}
                isFetching={isFetching}
                produto={produto}
            />
        </Box>
    );
}
