import React, { useCallback, useMemo, useRef, useState } from 'react';

import { DndContext, MouseSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button, Grid, IconButton, TextField, Container, CircularProgress, Checkbox, Typography, Box, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { Image, Layer, Stage } from 'react-konva';
import tinymce from 'tinymce';
import useImage from 'use-image';

import { CissAutoComplete } from 'components/autocompletes/CissAutoComplete';
import { CissNumberField } from 'components/fields';
import { BaseEditor } from 'components/fields/BaseEditor';
import { Row } from 'components/form';
import { Centered } from 'components/grid';
import { ContentModal } from 'components/modal';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import {
    MarketingTradeAlterarImagemBtn,
    MarketingTradeAtributoCard,
    MarketingTradeExportTypeModal,
    MarketingTradeKonvaText,
} from 'pages/marketing-trade/components';
import { Atributo, Field, Font, PainelEdicaoPecasMarketingTradeProps } from 'pages/marketing-trade/types';

interface Fonte {
    idFonteTexto: number;
    dsFonteTextoEstilo: string;
    dsFonteTextoFamilia: string;
    dsFonteTextoFormato: string;
    lkFonteTexto: string;
}

interface EstiloFonte {
    idEstiloFonte?: number;
    dsEstiloFonte: string;
    dsFonte: string;
    dsCor: string;
    nrTamanho: number;
}

interface CreateFieldValues {
    dsNomeCampo: string;
    estiloFonte?: EstiloFonte;
    fgLimiteCaractere: boolean;
    nrLimiteCaractere: number;
}

const getStylesNode = (node: any): any => {
    return {
        align: tinymce.DOM.getStyle(node, 'text-align', true) || 'center',
        color: tinymce.DOM.getStyle(node, 'color', true) || '#fff',
        family: tinymce.DOM.getStyle(node, 'font-family', true) || 'sans-serif',
        size: parseInt(tinymce.DOM.getStyle(node, 'font-size', true) || '24'),
    };
};

const getStyles = (editor: any): Font => {
    const node = editor.selection.getNode();
    const content = editor.getContent();
    const { align, color, family, size } = getStylesNode(node);

    return {
        align,
        color,
        family,
        size,
        bold: content.includes('<strong>'),
        italic: content.includes('<em>'),
    };
};

export function MarketingTradePainelEdicaoPecas({
    arquivoDownload,
    setArquivosDownload,
    formatoImagem,
}: PainelEdicaoPecasMarketingTradeProps): JSX.Element {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalEstiloVisible, setModalEstiloVisible] = useState<boolean>(false);
    const [modalExportVisible, setModalExportVisible] = useState<boolean>(false);
    const [modalEditAtributoVisible, setModalEditAtributoVisible] = useState<boolean>(false);

    const [selectedField, setSelectedField] = useState<Field | null>(null);
    const [fontFamilies, setFontFamilies] = useState('');
    const [estiloFonte, setEstiloFonte] = useState<EstiloFonte | null>(null);

    const [image, status] = useImage(arquivoDownload.arquivo.dsLink, 'anonymous');
    const { mutate } = useFormMutation();
    const RequestListagem = useRequestListagem();
    const { enqueueSnackbar } = useSnackbar();

    const stageRef = useRef<any>();
    const editorRef = useRef<any>();
    const canvasWidth = 800;

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 20 } }));

    const requestOptions: RequestOptionsType = {
        url: '/gestao/fontetexto',
        columns: 'idFonteTexto,dsFonteTextoFamilia,dsFonteTextoEstilo,dsFonteTextoFormato,lkFonteTexto',
        sort: [{ property: 'dsFonteTextoFamilia', direction: 'ASC' }],
    };

    useQuery([requestOptions], () => RequestListagem(requestOptions).then((data) => data.data), {
        onSuccess: (data) => {
            const fontes: Fonte[] = data.data;
            let fontFamiliesString = '';

            fontes.forEach((fonte) => {
                fontFamiliesString += `${fonte.dsFonteTextoFamilia}=${fonte.dsFonteTextoFamilia.replaceAll(' ', '_')};`;
            });

            setFontFamilies(fontFamiliesString);
        },
        enabled: !fontFamilies,
    });

    const atributosToDisplay = arquivoDownload.atributos
        .filter((atributo) => !atributo.fgExcluido)
        .sort((atrA, atrB) => {
            if (atrA.nrSequencia > atrB.nrSequencia) {
                return 1;
            } else {
                return -1;
            }
        });

    const fields: Field[] = atributosToDisplay.map((atributo) => {
        return JSON.parse(atributo.dsJsonAtributoEdicao);
    });

    const fieldsIdsToSort = fields.map((field) => field.id);

    const valueToNormalize = useMemo(() => {
        let value = 1;
        if (status === 'loaded') {
            if ((image?.width || 0) > canvasWidth) {
                value = Math.ceil((image?.width || 0) / canvasWidth);
            }
        }
        return value;
    }, [image?.width, status]);

    const getNewPositions = useCallback(
        (event: any, field: Field): { x: number; y: number } => {
            const difX = (image?.width || 0) / 2;
            const difY = ((field.font.size || 0) * valueToNormalize) / 2;

            const newX = event.target.x() * valueToNormalize - difX;
            const newY = event.target.y() * valueToNormalize - difY;
            return { x: newX, y: newY };
        },
        [image?.width, valueToNormalize],
    );

    const normalize = useCallback((value: number | string) => Number(value) / valueToNormalize, [valueToNormalize]);

    const changeArquivoOnState = (): void => {
        setArquivosDownload((oldState) => {
            return oldState.map((arquivo) => {
                if (arquivo.idPecaMarketingArquivo === arquivoDownload.idPecaMarketingArquivo) return arquivoDownload;
                return arquivo;
            });
        });
    };

    const createField = (values: CreateFieldValues): Field => {
        let htmlValue = '<p></p>';
        if (values.estiloFonte) {
            const { dsFonte, nrTamanho, dsCor, dsEstiloFonte } = values.estiloFonte;
            htmlValue = `<p><span style="font-family: ${dsFonte}; color: ${dsCor}; font-size: ${nrTamanho}px;">${dsEstiloFonte}</span></p>`;
        }

        return {
            id: Math.ceil(Math.random() * new Date().getTime()),
            name: values.dsNomeCampo,
            htmlValue,
            value: '',
            valueLimit: values.fgLimiteCaractere ? values.nrLimiteCaractere : null,
            x: 0,
            y: 0,
            rotation: 0,
            width: canvasWidth,
            font: {
                italic: false,
                bold: false,
                align: 'left',
                size: 14,
                color: '#000',
                family: 'sans-serif',
            },
        };
    };

    const onEditorChange = (editor: any): void => {
        const font = getStyles(editor);
        const htmlValue = editor.getContent();
        const text = editor.getContent({ format: 'text' });

        arquivoDownload.atributos = arquivoDownload.atributos.map((atributo) => {
            const field: Field = JSON.parse(atributo.dsJsonAtributoEdicao);

            if (field.id === selectedField?.id) {
                field.value = text;
                field.htmlValue = htmlValue;
                field.font = font;
            }

            atributo.dsJsonAtributoEdicao = JSON.stringify(field);
            return atributo;
        });
        changeArquivoOnState();
    };

    const onDeleteField = (fieldId: number): void => {
        const atributoArquivo: Atributo | undefined = arquivoDownload.atributos.find((atributo) => {
            const field: Field = JSON.parse(atributo.dsJsonAtributoEdicao);
            return field.id === fieldId ? true : false;
        });

        if (atributoArquivo && atributoArquivo.idPecaMarketingArquivoAtributoEdicao > 0) {
            arquivoDownload.atributos = arquivoDownload.atributos.map((atributo) => {
                const field: Field = JSON.parse(atributo.dsJsonAtributoEdicao);
                if (field.id === fieldId) {
                    atributo.fgExcluido = true;
                }
                return atributo;
            });
        } else {
            arquivoDownload.atributos = arquivoDownload.atributos.filter((atributo) => {
                const field: Field = JSON.parse(atributo.dsJsonAtributoEdicao);
                return field.id === fieldId ? false : true;
            });
        }

        changeArquivoOnState();
    };

    const saveFieldChanges = (field: Field): void => {
        arquivoDownload.atributos.map((atributo) => {
            const atributoField: Field = JSON.parse(atributo.dsJsonAtributoEdicao);
            if (atributoField.id === field.id) {
                atributo.dsJsonAtributoEdicao = JSON.stringify(field);
            }
            return atributo;
        });
        changeArquivoOnState();
    };

    const handleCreateField = (values: CreateFieldValues): void => {
        const newField = createField(values);

        let nrSequencia = 0;
        if (arquivoDownload.atributos.length >= 1) {
            nrSequencia = arquivoDownload.atributos[arquivoDownload.atributos.length - 1].nrSequencia + 1;
        }

        arquivoDownload.atributos.push({
            idPecaMarketingArquivoAtributoEdicao: -1,
            fgExcluido: false,
            dsJsonAtributoEdicao: JSON.stringify(newField),
            nrSequencia,
        });

        changeArquivoOnState();
        setModalVisible(false);
    };

    const handleEditFiled = (values: Field & CreateFieldValues): void => {
        const html = new DOMParser().parseFromString(values.htmlValue, 'text/html');
        let lastChildren = html.body.children[0];

        while (lastChildren.children[0]) {
            const children = lastChildren.children[0];
            lastChildren = children;
        }

        let htmlValue = values.htmlValue;
        if (values.estiloFonte) {
            htmlValue = `<p><span style="font-family: ${values.estiloFonte.dsFonte}; color: ${values.estiloFonte.dsCor}; font-size: ${values.estiloFonte.nrTamanho}px;">${lastChildren.textContent}</span></p>`;
        }

        const field: Field = {
            id: values.id,
            font: {
                ...values.font,
                family: values.estiloFonte?.dsFonte || values.font.family,
                color: values.estiloFonte?.dsCor || values.font.color,
                size: values.estiloFonte?.nrTamanho || values.font.size,
            },
            htmlValue: htmlValue,
            name: values.dsNomeCampo || values.name,
            rotation: values.rotation,
            value: values.value,
            valueLimit: values.fgLimiteCaractere ? values.nrLimiteCaractere || values.valueLimit : null,
            width: values.width,
            x: values.x,
            y: values.y,
        };

        saveFieldChanges(field);
        setSelectedField(null);
        setModalEditAtributoVisible(false);
    };

    const handleSavePreset = (): void => {
        mutate(
            {
                url: '/gestao/estilofonte',
                preventSnack: true,
                formData: estiloFonte,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Predefinição de estilo salva com sucesso', { variant: 'success' });
                },
                onError: () => {
                    enqueueSnackbar('Erro ao salvar predefinição de estilo', { variant: 'error' });
                },
                onSettled: () => {
                    setModalEstiloVisible(false);
                    setEstiloFonte(null);
                },
            },
        );
    };

    const getRichTextInitialValue = (): string => {
        let htmlValue = '';

        arquivoDownload.atributos.forEach((atributo) => {
            const field: Field = JSON.parse(atributo.dsJsonAtributoEdicao);
            if (field.id === selectedField?.id) {
                htmlValue = field.htmlValue;
            }
        });

        return htmlValue;
    };

    const moveTextWithKeyboard = (e: any): void => {
        e.preventDefault();
        const DELTA = valueToNormalize;
        const field = fields.find((f) => f.id === selectedField?.id);

        if (field) {
            if (e.key === 'ArrowLeft') {
                field.x -= DELTA;
            } else if (e.key === 'ArrowUp') {
                field.y -= DELTA;
            } else if (e.key === 'ArrowRight') {
                field.x += DELTA;
            } else if (e.key === 'ArrowDown') {
                field.y += DELTA;
            } else {
                return;
            }
            saveFieldChanges(field);
        }
    };

    const handleDragAtributeEnd = (event: any): void => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const atributoAtivoIndex = arquivoDownload.atributos.findIndex((atr) => {
                const field: Field = JSON.parse(atr.dsJsonAtributoEdicao);
                return field.id === active.id;
            });

            const atributoSobreescritoIndex = arquivoDownload.atributos.findIndex((atr) => {
                const field: Field = JSON.parse(atr.dsJsonAtributoEdicao);
                return field.id === over.id;
            });

            const newAtributesSequence = arrayMove(arquivoDownload.atributos, atributoAtivoIndex, atributoSobreescritoIndex).map((atr, index) => ({
                ...atr,
                nrSequencia: index + 1,
            }));

            arquivoDownload.atributos = newAtributesSequence;
            changeArquivoOnState();
        }
    };

    return (
        <Block>
            <MarketingTradeExportTypeModal
                isModalVisible={modalExportVisible}
                setIsModalVisible={setModalExportVisible}
                valueStage={stageRef?.current}
                valueToNormalize={valueToNormalize}
                formatoImagem={formatoImagem}
            />

            <ContentModal open={modalVisible} setOpen={setModalVisible}>
                <Formik
                    initialValues={{
                        dsNomeCampo: '',
                        fgLimiteCaractere: false,
                        nrLimiteCaractere: 0,
                    }}
                    onSubmit={(values) => handleCreateField(values)}
                >
                    {(formik: FormikProps<CreateFieldValues>) => (
                        <Container sx={{ display: 'flex', flexDirection: 'column', mt: 3, mb: 3, p: 0 }}>
                            <TextField
                                label={'Nome do atributo'}
                                value={formik.values.dsNomeCampo}
                                variant="outlined"
                                onChange={(e: any) => {
                                    formik.setFieldValue('dsNomeCampo', e.target.value);
                                }}
                            />
                            <CissAutoComplete
                                label="Predefinição de estilo"
                                value={formik.values.estiloFonte || null}
                                multiple={false}
                                isOptionEqualToValue={(option, value) => option.idEstiloFonte === value.idEstiloFonte}
                                optionStringTemplate="{dsEstiloFonte}"
                                selectedStringTemplate="{dsEstiloFonte}"
                                onChange={(e, value) => formik.setFieldValue('estiloFonte', value)}
                                requestOptions={{
                                    url: '/gestao/estilofonte',
                                    sort: [{ property: 'dsEstiloFonte', direction: 'ASC' }],
                                }}
                                sx={{ mt: 2 }}
                            />

                            <Row sx={{ alignItems: 'center' }}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        checked={formik.values.fgLimiteCaractere}
                                        onChange={(e, checked) => formik.setFieldValue('fgLimiteCaractere', checked)}
                                        sx={{ mt: 2, p: 0 }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Limitar caracteres?
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <CissNumberField
                                        label="Limite de caracteres"
                                        value={formik.values.nrLimiteCaractere}
                                        onChange={(e) => formik.setFieldValue('nrLimiteCaractere', e.target.value)}
                                        sx={{ mt: 2 }}
                                        disabled={!formik.values.fgLimiteCaractere}
                                    />
                                </Grid>
                            </Row>

                            <Button disabled={!formik.values.dsNomeCampo} variant="contained" size="large" onClick={() => formik.submitForm()}>
                                Adicionar atributo
                            </Button>
                        </Container>
                    )}
                </Formik>
            </ContentModal>

            <ContentModal open={modalEditAtributoVisible} setOpen={setModalEditAtributoVisible}>
                <Formik
                    initialValues={{
                        ...selectedField!,
                        dsNomeCampo: selectedField?.name || '',
                        fgLimiteCaractere: Boolean(selectedField?.valueLimit || false),
                        nrLimiteCaractere: selectedField?.valueLimit || 0,
                    }}
                    onSubmit={(values) => handleEditFiled(values)}
                >
                    {(formik: FormikProps<Field & CreateFieldValues>) => (
                        <Container sx={{ display: 'flex', flexDirection: 'column', mt: 3, mb: 3, p: 0 }}>
                            <TextField
                                label={'Nome do atributo'}
                                value={formik.values.dsNomeCampo}
                                variant="outlined"
                                onChange={(e: any) => {
                                    formik.setFieldValue('dsNomeCampo', e.target.value);
                                }}
                            />
                            <CissAutoComplete
                                label="Predefinição de estilo"
                                value={formik.values.estiloFonte || null}
                                multiple={false}
                                isOptionEqualToValue={(option, value) => option.idEstiloFonte === value.idEstiloFonte}
                                optionStringTemplate="{dsEstiloFonte}"
                                selectedStringTemplate="{dsEstiloFonte}"
                                onChange={(e, value) => formik.setFieldValue('estiloFonte', value)}
                                requestOptions={{
                                    url: '/gestao/estilofonte',
                                    sort: [{ property: 'dsEstiloFonte', direction: 'ASC' }],
                                }}
                                sx={{ mt: 2 }}
                            />

                            <Row sx={{ alignItems: 'center' }}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        checked={formik.values.fgLimiteCaractere}
                                        onChange={(e, checked) => formik.setFieldValue('fgLimiteCaractere', checked)}
                                        sx={{ mt: 2, p: 0 }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Limitar caracteres?
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <CissNumberField
                                        label="Limite de caracteres"
                                        value={formik.values.nrLimiteCaractere}
                                        onChange={(e) => formik.setFieldValue('nrLimiteCaractere', e.target.value)}
                                        sx={{ mt: 2 }}
                                        disabled={!formik.values.fgLimiteCaractere}
                                    />
                                </Grid>
                            </Row>

                            <Button disabled={!formik.values.dsNomeCampo} variant="contained" size="large" onClick={() => formik.submitForm()}>
                                Salvar alterações
                            </Button>
                        </Container>
                    )}
                </Formik>
            </ContentModal>

            <ContentModal open={modalEstiloVisible} setOpen={setModalEstiloVisible}>
                <Container sx={{ display: 'flex', flexDirection: 'column', mt: 3, mb: 3, p: 0 }}>
                    <TextField
                        label={'Nome da predefinição de estilo'}
                        value={estiloFonte?.dsEstiloFonte || ''}
                        variant="outlined"
                        onChange={(e: any) => {
                            if (estiloFonte) {
                                setEstiloFonte({
                                    ...estiloFonte,
                                    dsEstiloFonte: e.target.value,
                                });
                            }
                        }}
                    />
                    <Button disabled={!estiloFonte?.dsEstiloFonte} variant="contained" size="large" sx={{ mt: 2 }} onClick={handleSavePreset}>
                        Salvar estilo
                    </Button>
                </Container>
            </ContentModal>

            <Grid
                container
                spacing={{
                    xs: 0,
                    md: 2,
                }}
                rowSpacing={{
                    xs: 2,
                    md: 0,
                }}
                sx={{
                    flexDirection: {
                        xs: 'column',
                        md: 'row',
                    },
                    justifyContent: 'space-between',
                }}
            >
                <Grid item sx={{ flex: 1 }}>
                    <SubTitle label="Pré-visualização" />
                    {status === 'loaded' ? (
                        <React.Fragment>
                            <div tabIndex={1} onKeyDown={moveTextWithKeyboard}>
                                <Stage
                                    width={normalize(image?.width || 0)}
                                    height={normalize(image?.height || 0)}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    ref={stageRef}
                                >
                                    <Layer>
                                        <Image image={image} scale={{ x: normalize(1), y: normalize(1) }} />
                                        {fields.map((field: Field) => (
                                            <MarketingTradeKonvaText
                                                key={field.id}
                                                text={field.value}
                                                font={field.font}
                                                width={normalize(image?.width || 0)}
                                                x={normalize(field.x)}
                                                y={normalize(field.y)}
                                                rotation={field.rotation}
                                                draggable={true}
                                                isSelected={selectedField?.id === field.id}
                                                onDblClick={() => {
                                                    setSelectedField(field);
                                                }}
                                                onDragEnd={(event: any) => {
                                                    const { x, y } = getNewPositions(event, field);
                                                    field.x = x;
                                                    field.y = y;
                                                    saveFieldChanges(field);
                                                }}
                                                onTransformEnd={(event: any) => {
                                                    field.rotation = event.target.rotation();
                                                    saveFieldChanges(field);
                                                }}
                                            />
                                        ))}
                                    </Layer>
                                </Stage>
                            </div>
                            <Box sx={{ display: 'flex', gap: 1, mt: 'auto' }}>
                                <Tooltip title="Fazer download">
                                    <IconButton onClick={() => setModalExportVisible(true)}>
                                        <CloudDownloadIcon
                                            fontSize="large"
                                            sx={{
                                                color: 'primary.main',
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>

                                <MarketingTradeAlterarImagemBtn arquivoDownload={arquivoDownload} changeArquivoOnState={changeArquivoOnState} />
                            </Box>
                        </React.Fragment>
                    ) : (
                        <Centered>
                            <CircularProgress sx={{ my: 2 }} size="1.5rem" />
                        </Centered>
                    )}
                </Grid>

                <Grid item sx={{ flex: 1, minWidth: 305 }}>
                    <SubTitle label="Atributos" />
                    <Grid item direction={'column'} display={'flex'} container={true}>
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={(event) => handleDragAtributeEnd(event)}>
                            <SortableContext items={fieldsIdsToSort} strategy={verticalListSortingStrategy}>
                                {atributosToDisplay.map((atributo) => {
                                    const field: Field = JSON.parse(atributo.dsJsonAtributoEdicao);

                                    return (
                                        <MarketingTradeAtributoCard
                                            key={field.id}
                                            field={field}
                                            checked={field.id === selectedField?.id}
                                            onDelete={() => onDeleteField(field.id)}
                                            onCheck={(checked) => {
                                                if (checked) {
                                                    setSelectedField(field);
                                                } else {
                                                    setSelectedField(null);
                                                }
                                            }}
                                            onEdit={() => {
                                                setSelectedField(field);
                                                setModalEditAtributoVisible(true);
                                            }}
                                            onSaveStyle={(styles) => {
                                                setEstiloFonte({
                                                    dsEstiloFonte: '',
                                                    dsCor: styles.dsCor,
                                                    dsFonte: styles.dsFonte,
                                                    nrTamanho: styles.nrTamanho,
                                                });

                                                setModalEstiloVisible(true);
                                            }}
                                        />
                                    );
                                })}
                            </SortableContext>
                        </DndContext>

                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 5, mb: 5 }}>
                            <Button sx={{ width: 200 }} variant="contained" onClick={() => setModalVisible(true)}>
                                Adicionar atributo
                            </Button>
                        </Container>

                        {fontFamilies && (
                            <BaseEditor
                                ref={editorRef}
                                initialValue={getRichTextInitialValue()}
                                onChange={(_: any, editor: any) => {
                                    onEditorChange(editor);
                                }}
                                disabled={false}
                                init={{
                                    branding: false,
                                    draggable_modal: true,
                                    max_height: 200,
                                    min_height: 100,
                                    placeholder: 'Selecione um campo para personalizar...',
                                    resize: false,
                                    statusbar: false,
                                    style_formats_autohide: true,
                                    toolbar_sticky: true,
                                    content_css: 'writer',
                                    allow_unsafe_link_target: false,
                                    custom_undo_redo_levels: 10,
                                    paste_as_text: true,
                                    language: 'pt_BR',
                                    language_url: '/tinymce/langs/pt_BR.js',
                                    entity_encoding: 'raw',
                                    menubar: false,
                                    toolbar: ' fontfamily fontsize | bold italic forecolor textShadow | alignleft aligncenter alignright',
                                    font_size_formats:
                                        '2px 3px 4px 5px 6px 7px 8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px ' +
                                        '28px 30px 32px 34px 36px 45px 60px 85px 100px 110px 135px 150px 160px 185px 210px',
                                    preview_styles: 'text-shadow',
                                    plugins: ['table', 'image', 'link'],
                                    font_family_formats: fontFamilies,
                                    font_css: `/proxy/fonts.css?hash=${Math.ceil(Math.random() * 100000)}`,
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Block>
    );
}
