import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TipoLogisticaLojaProps {
    idTipoLogisticaLoja: number;
    dsTipoLogisticaLoja: string;
}

type TipoLogisticaLojaAutoCompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label' | 'size'>;

export function TipoLogisticaLojaAutoComplete({ value, error, multiple = false, sx, onChange, label = 'Tipo de Loja', size }: TipoLogisticaLojaAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsTipoLogisticaLoja}"
            optionStringTemplate="{dsTipoLogisticaLoja}"
            requestOptions={{
                url: '/gestao/tipologisticaloja',
                sort: [{ property: 'idTipoLogisticaLoja', direction: 'ASC' }],
            }}
            isOptionEqualToValue={(option: TipoLogisticaLojaProps, value: TipoLogisticaLojaProps) => option.idTipoLogisticaLoja === value.idTipoLogisticaLoja}
            onChange={onChange}
            sx={sx}
            size={size}
        />
    );
}
