import React from 'react';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import * as dateFns from 'date-fns';


import { HistoricoStatusProps } from '../../types';

import { SubTitle } from 'components/text';

interface SolicitacaoPecaHistoricoMensagemProps {
    historicoStatus?: HistoricoStatusProps[];
}

export function TradeSolicitacaoPecaHistoricoMensagem({ historicoStatus }: SolicitacaoPecaHistoricoMensagemProps): JSX.Element {
    const qtdHistoricoStatus = historicoStatus && historicoStatus.length;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Box sx={{ width: '100%', mb: -2 }}>
                <SubTitle label={'Histórico'} />
            </Box>

            {(historicoStatus || []).map((historico, index) => {
                const last = Boolean(qtdHistoricoStatus === index + 1);

                let color;

                switch (historico.solicitacaoPecaStatus.idSolicitacaoPecaStatus) {
                    case 2: // Aprovado pelo marketing
                    case 5: // Finalizado
                        color = 'success';
                        break;
                    case 3: // Reprovado
                        color = 'error';
                        break;
                    case 4: // Em andamento
                        color = 'info';
                        break;
                    case 1: // Em aprovação pelo marketing
                    default:
                        color = '';
                        break;
                }

                return (
                    <Box
                        key={historico.idSolicitacaoPecaSolicitacaoPecaStatus}
                        sx={{
                            width: 260,
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                        }}
                    >
                        <Box
                            component={Paper}
                            sx={{
                                p: 1,
                                width: 'calc(100% - 20px)',
                            }}
                        >
                            <Tooltip title={`Responsável: ${historico.usuarioInclusao.dsUsuario}`} arrow>
                                <Box>
                                    <Typography
                                        component="span"
                                        color={`${color}.main`}
                                        sx={{
                                            ml: 0.5,
                                            fontWeight: 700,
                                        }}
                                    >
                                        <InfoOutlinedIcon
                                            color="info"
                                            fontSize="small"
                                            sx={{
                                                mt: '-3px',
                                                mr: 0.5,
                                                transform: 'translateY(4px)',
                                            }}
                                        />
                                        {historico.solicitacaoPecaStatus.dsStatus}
                                    </Typography>

                                    <Typography variant="body2">Data: {dateFns.format(new Date(historico.dhInclusao), 'dd/MM/yyyy, HH:mm')}hrs.</Typography>
                                </Box>
                            </Tooltip>
                        </Box>

                        {!last && <ChevronRightOutlinedIcon />}
                    </Box>
                );
            })}
        </Box>
    );
}
