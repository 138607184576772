import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface PerfilLoja {
    idLojaPerfil: number;
    dsLojaPerfil: string;
}

type PerfilLojaAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function PerfilLojaAutoComplete({ value, error, multiple = true, disabled = false, sx, onChange = () => {}, name }: PerfilLojaAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Perfil da Loja"
            optionStringTemplate="{dsLojaPerfil}"
            selectedStringTemplate="{dsLojaPerfil}"
            sx={sx}
            name={name}
            value={value}
            error={error}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            isOptionEqualToValue={(option: PerfilLoja, value: PerfilLoja) => option.idLojaPerfil === value.idLojaPerfil}
            requestOptions={{
                url: '/gestao/lojaperfil',
                sort: [{ property: 'idLojaPerfil', direction: 'ASC' }],
                columns: 'idLojaPerfil,dsLojaPerfil',
                limit: 50,
            }}
        />
    );
}
