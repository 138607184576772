import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { RequestPedidoProps } from '../types';

import { useAplicacaoDataContext } from 'context/AplicacaoDataContext';
import { useFormContext } from 'context/FormContext';
import { useListagemContext } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function useGetCarrinho(): UseQueryResult<RequestPedidoProps> {
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const requestListagem = useRequestListagem();
    const { formattedLoja } = useAplicacaoDataContext();
    const { hasPermission } = usePermissionsContext();
    const { content } = useFormContext();
    const hasPermissionFinalizacaoGeral = hasPermission(['COMPRA_PEDIDO_FINALIZACAO_GERAL']);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';
    const isLojasEqualOne = Array.isArray(content?.lojas) && content?.lojas.length === 1;

    const requestOptions: RequestOptionsType = {
        url: '/compra/pedidocompra/rascunho/loja',
        sort: sort.length ? sort : [{ property: 'idPedido', direction: 'DESC' }],
        filter: [
            { property: 'fgAtivo', operator: 'eq', value: true, logicalOperator: 'AND' },
            { property: 'vlTotal', operator: 'neq', value: 0 },
            ...filter,
        ],
        limit: isLojasEqualOne || isTipoPedidoFuncionario ? 100 : limit,
        page,
    };

    if (isTipoPedidoFuncionario && requestOptions.filter && formattedLoja) {
        requestOptions.filter.push({ property: 'idLoja', operator: 'eq', value: formattedLoja?.idLoja });
    }

    return useQuery<RequestPedidoProps>(['getListRascunhos', requestOptions], () => requestListagem(requestOptions).then((res) => res.data), {
        enabled: (isTipoPedidoFuncionario && !hasPermissionFinalizacaoGeral) || autoLoad,
        cacheTime: 0,
    });
}
