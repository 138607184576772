import { RequestListagemProps } from 'hooks/useRequestListagem';

export interface ComunicadoTag {
    idTagComunicado?: number;
    dsTagComunicado: string;
}

export const initialTagValues: ComunicadoTag = {
    dsTagComunicado: '',
};

export interface RequestComunicadoTag extends RequestListagemProps {
    data: ComunicadoTag[];
}
