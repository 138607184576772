import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface EstadoProps {
    idEstado: number;
    dsEstado: string;
    sgEstado: string;
}

type EstadoAutoCompleteProps = Pick<CissAutoCompleteProps, 'label' | 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange'>;

export function EstadoAutoComplete({ value, error, label = 'Estado', multiple = false, sx, onChange }: EstadoAutoCompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            value={value}
            onChange={onChange}
            sx={sx}
            label={label}
            error={error}
            multiple={multiple}
            selectedStringTemplate="{sgEstado} - {dsEstado}"
            optionStringTemplate="{sgEstado} - {dsEstado}"
            isOptionEqualToValue={(option: EstadoProps, value: EstadoProps) => option.idEstado === value.idEstado}
            requestOptions={{
                url: '/gestao/estado',
                sort: [{ property: 'sgEstado', direction: 'ASC' }],
                columns: 'idEstado,dsEstado,sgEstado',
            }}
        />
    );
}
