import React, { useState, useMemo } from 'react';

import { Alert, Box, CircularProgress } from '@mui/material';

import { CissTextField } from 'components/fields';
import { Centered } from 'components/grid';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { PedidoCompraCartProduct } from 'pages/compra/pedido/components';

interface PedidoCompraCartAsideProps {
    isFetching: boolean;
}

export function PedidoCompraCartAside({ isFetching }: PedidoCompraCartAsideProps): JSX.Element {
    const { produtoCartList, isEmptyCart } = usePedidoCompraContext();
    const [search, setSearch] = useState<string>('');

    const produtoCartListFiltered = useMemo(() => {
        const filteredList = produtoCartList.filter((produtoCart) => {
            const { dsProduto } = produtoCart.produto;
            const includeSearch = dsProduto.toLowerCase().includes(search.toLowerCase());
            return includeSearch;
        });

        return filteredList.map((produtoCart) => <PedidoCompraCartProduct key={produtoCart.produto.idProduto} produtoCart={produtoCart} />);
    }, [produtoCartList, search]);

    const erroSemProdutos = useMemo(() => (!produtoCartListFiltered.length ? <Alert severity="info">Sem produtos correspondentes</Alert> : null), [produtoCartListFiltered.length]);

    return (
        <Block
            sx={{
                p: 0,
                mt: 0,
                mb: 0,
                pb: 2,
                width: '100%',
            }}
        >
            <SubTitle label="Detalhes do Pedido" sx={{ width: 'auto', mx: 2, pt: 2 }} />

            {isEmptyCart && !isFetching && (
                <Alert severity="info" sx={{ mx: 2 }}>
                    Sem produtos no pedido atual
                </Alert>
            )}

            {isFetching && (
                <Centered>
                    <CircularProgress sx={{ mt: 2, mb: 4 }} />
                </Centered>
            )}

            {!isEmptyCart && !isFetching && (
                <React.Fragment>
                    <Box display="flex">
                        <CissTextField label="Pesquisar" placeholder="Nome do produto" onChange={(e) => setSearch(e.target.value)} size="small" sx={{ mx: 2, mb: 2 }} />
                    </Box>

                    <Box
                        sx={{
                            '> hr:last-of-type': {
                                display: 'none',
                            },
                            'overflowY': 'auto',
                            'overflowX': 'hidden',
                            // 68 = bbar
                            // 184 = rascunho + padding + margin
                            // 138 = titulo + pesquisa + padding + margin
                            'maxHeight': window.innerHeight - 68 - 184 - 138,
                            'px': 2,
                        }}
                    >
                        {produtoCartListFiltered}
                        {erroSemProdutos}
                    </Box>
                </React.Fragment>
            )}
        </Block>
    );
}
