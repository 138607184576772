import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';

export type FormContextData = {
    isLoading?: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    content?: any;
    setContent: Dispatch<SetStateAction<any>>;
};

interface FormProviderProps {
    children?: ReactNode;
}

const FormContext = createContext<FormContextData>({} as FormContextData);

export function FormProvider({ children }: FormProviderProps): JSX.Element {
    const [isLoading, setLoading] = useState(false);
    const [content, setContent] = useState();

    const contextValue = useMemo(
        () => ({
            isLoading,
            setLoading,
            content,
            setContent,
        }),
        [content, isLoading],
    );

    return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
}

export const useFormContext = (): FormContextData => useContext(FormContext);
