import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { usePostPedido } from 'pages/compra/pedido/hooks';

export function PedidoSalvarBtn(): JSX.Element {
    const { handleSubmit, isLoading } = usePostPedido();
    const { isEmptyCart, carrinhoChanged } = usePedidoCompraContext();

    return (
        <Tooltip title="Continuar para o carrinho">
            <span>
                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    sx={{ width: 150, height: 40 }}
                    onClick={handleSubmit}
                    disabled={isEmptyCart && !carrinhoChanged}
                >
                    Salvar Pedido
                </LoadingButton>
            </span>
        </Tooltip>
    );
}
