import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { ArquivoDownload, getTypeMarketing, MarketingTradePage, PecaMarketingTrade } from '../../types';

import { Bbar } from 'components/docked';
import { Centered } from 'components/grid';
import { OneColumn } from 'components/page';
import { useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { MarketingTradePainelEdicaoPecas } from 'pages/marketing-trade/components/templates/MarketingTradePainelEdicaoPecas';
import { filterEditableFiles } from 'pages/marketing-trade/util/file';

export function MarketingTradeEdicaoPecasComp({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const [arquivosDownload, setArquivosDownload] = useState<ArquivoDownload[]>([]);

    const RequestListagem = useRequestListagem();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { mutate } = useFormMutation();
    const { id: imageId } = useParams();

    const imageUrl = `/gestao/pecamarketing/${imageId}`;

    const { isLoading, fetchStatus, data } = useQuery([imageUrl], () => RequestListagem({ url: imageUrl }).then((res) => res.data), {
        onSuccess: ({ data }) => {
            const arquivosEditaveis = data.arquivosDownload.filter(filterEditableFiles);

            setArquivosDownload(arquivosEditaveis);
        },
    });

    const handleSave = (): void => {
        const requestBody: PecaMarketingTrade[] = arquivosDownload.map(
            (arquivo): PecaMarketingTrade => ({
                pecaMarketingArquivo: {
                    idPecaMarketingArquivo: arquivo.idPecaMarketingArquivo,
                    arquivo: arquivo.arquivo,
                },
                atributos: arquivo.atributos,
            }),
        );

        if (data.data.tipoMarketing.idTipoMarketing !== getTypeMarketing(isMarketing)) {
            enqueueSnackbar(`Não é possivel editar/visualizar este item no módulo ${isMarketing ? 'MARKETING' : 'TRADE'}`, { variant: 'error' });
        } else {
            mutate(
                {
                    url: '/gestao/pecamarketingarquivoatributoedicao/atributos',
                    preventSnack: true,
                    formData: requestBody,
                    method: 'PUT',
                    preventRedirect: true,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar('Edição de peça(s) concluída com sucesso', { variant: 'success' });
                        navigate(`/${isMarketing ? 'marketing' : 'trade'}`);
                    },
                    onError: () => {
                        enqueueSnackbar('Falha ao salvar as edições', { variant: 'error' });
                    },
                },
            );
        }
    };

    if (isLoading || fetchStatus !== 'idle') {
        return (
            <Centered>
                <CircularProgress sx={{ my: 2 }} size="1.5rem" />
            </Centered>
        );
    }

    return (
        <OneColumn
            goBackButton
            title={`Editar Peça de ${isMarketing ? 'Marketing' : 'Trade'}`}
            extraButtons={[
                {
                    title: 'Gerenciar Fontes',
                    onClick: () => navigate('/fontes'),
                },
                {
                    title: 'Gerenciar Predefinições de Estilo',
                    onClick: () => navigate('/predefinicoes-estilo'),
                },
            ]}
        >
            {arquivosDownload.map((arquivo) => (
                <MarketingTradePainelEdicaoPecas
                    arquivoDownload={arquivo}
                    setArquivosDownload={setArquivosDownload}
                    key={arquivo.idPecaMarketingArquivo}
                    formatoImagem={data.data.pecaMarketingFormato}
                />
            ))}

            <Bbar primaryBtnText="Salvar" primaryBtnHandler={handleSave} />
        </OneColumn>
    );
}
