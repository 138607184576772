import React, { useCallback, useMemo, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Button, Box, Typography } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import { DownloadModeloArquivoBtn, ImportFileBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { ConfirmModal } from 'components/modal';
import { Block, OneColumn } from 'components/page';
import { SubTitle } from 'components/text';
import { useUploadFileMutation } from 'mutations/useUploadFileMutation';
import { useShowError } from 'hooks/useShowError';

interface GradeErro {
    nrLinha: number;
    dsMensagensErros: string[];
}

interface RequestGradeErro {
    gradeImportacaoErros: GradeErro[];
}

const getRows = (data: GradeErro[]): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            nrLinha: item.nrLinha,
            dsMensagensErros: item.dsMensagensErros,
        }));
    }

    return [];
};

const getTextError = (messages: string[]): JSX.Element => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1, py: 1, minHeight: 50 }}>
            {messages.map((message, index) => (
                <Typography key={index}>{message}</Typography>
            ))}
        </Box>
    );
};

export function GradeExclusaoComp(): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const { showError } = useShowError();
    const [file, setFile] = useState<File | null>(null);
    const [gradeErroList, setGradeErroList] = useState<GradeErro[] | null>(null);
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

    const { mutate, isLoading } = useUploadFileMutation({
        onSuccess: ({ data }) => {
            const { gradeImportacaoErros } = data as unknown as RequestGradeErro;

            if (!gradeImportacaoErros.length) {
                enqueueSnackbar('Exclusão de quebras realizada com sucesso', { variant: 'success' });
            } else {
                enqueueSnackbar('Exclusão de quebras realizada com falha', { variant: 'warning' });
            }

            setFile(null);
            setGradeErroList(gradeImportacaoErros);
        },
        onError: (error) => showError(error, 'Falha ao excluir as quebras'),
    });

    const callbackSubmitFile = useCallback(() => {
        const formData = new FormData();

        if (file) {
            formData.append('file', file);
        }

        mutate({ url: '/gestao/grade/upload/exclusao', formData });
    }, [file, mutate]);

    const columns = useMemo(
        (): GridColDef[] => [
            { field: 'nrLinha', headerName: 'Linha do Erro', width: 140, minWidth: 100 },
            {
                field: 'dsMensagensErros',
                headerName: 'Menssagem do Erro',
                flex: 1,
                minWidth: 250,
                renderCell: ({ row }) => getTextError(row.dsMensagensErros),
            },
        ],
        [],
    );

    return (
        <React.Fragment>
            <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} confirm={callbackSubmitFile} text="Tem certeza que deseja excluir as grades importadas?" />

            <OneColumn title="Exclusão de Grades" goBackButton>
                <Block sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'stretch', sm: 'flex-start' }, gap: 1 }}>
                    <SubTitle label="Excluir Grades" sx={{ mb: 1 }} />

                    <DownloadModeloArquivoBtn dsChave="URL_ARQUIVO_MODELO_GRADE" />

                    <ImportFileBtn file={file} setFile={setFile} accept={['.xlsx', '.csv']} />

                    <LoadingButton color="error" variant="contained" disabled={!file} loading={isLoading} startIcon={<DeleteOutlineIcon />} onClick={() => setOpenConfirmModal(true)}>
                        Excluir grades
                    </LoadingButton>
                </Block>

                {Array.isArray(gradeErroList) && Boolean(gradeErroList.length) && (
                    <Block>
                        <SubTitle label="Falhas na Exclusão" />

                        <PanelGrid rows={getRows(gradeErroList)} rowsCount={gradeErroList.length} columns={columns} loading={false} reload={false} remote={false} rowCellHeight={'auto'} />
                    </Block>
                )}
            </OneColumn>
        </React.Fragment>
    );
}
