import React from 'react';

import { OperacoesFormComp, OperacoesListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function OperacoesList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_OPERACAO_GERENCIAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <OperacoesListComp />
        </ListagemProvider>
    );
}

export function OperacoesForm(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['COMPLEMENTAR_OPERACAO_GERENCIAR'])) return alertNotPermission();

    return (
        <FormProvider>
            <OperacoesFormComp />
        </FormProvider>
    );
}
