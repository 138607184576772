import { FormikErrors } from 'formik';
import { clearArray } from './format';

const getErrorHttp = (statusCode: number): string => {
    switch (statusCode) {
        case 500:
            return 'Houve um erro no servidor.';
        case 404:
        case 400:
            return 'O Recurso que você está tentando acessar não está disponível';
        default:
            return 'Houve um erro no servidor.';
    }
};

export const getErrorMessage = (error: unknown): string[] => {
    const { data, status } = Object.create(error as Record<string, unknown>);
    const { errors, message } = data ?? {};

    const messages: string[] = [message, null]; // array de mensagem de erros (todas)

    if (Array.isArray(errors) && errors.length) {
        errors.forEach((error) => messages.push(error.message.charAt(0).toUpperCase() + error.message.slice(1)));
    }

    if (!clearArray(messages).length && status) {
        messages.push(getErrorHttp(status));
    }

    // removemos itens "vazios"
    return clearArray<string>(messages);
};

export const getFieldsError = (error: unknown): FormikErrors<any> | null => {
    const { data } = Object.create(error as Record<string, unknown>);
    const { errors } = data ?? {};
    const object: FormikErrors<any> = {};

    let fields = [];

    if (Array.isArray(errors) && errors.length) {
        fields = errors.filter((error) => 'field' in error && 'message' in error);
    }

    fields.forEach((item) => {
        const { field, message } = item;

        object[field] = message.charAt(0).toUpperCase() + message.slice(1);
    });

    return fields.length ? object : null;
};
