import React from 'react';

import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function TituloAlert(): JSX.Element | null {
    const { filter } = useListagemContext();
    const requestListagem = useRequestListagem();

    const cdLoja = filter.find((f) => f.property === 'cdLoja')?.value;

    const requestOptions: RequestOptionsType = {
        url: '/financeiro/titulo/vencidos',
        extraParams: [{ cdLoja }],
    };

    const { data: qtdTitulos, fetchStatus } = useQuery([requestOptions], () => requestListagem(requestOptions).then((res) => res.data), { enabled: Boolean(cdLoja) });

    if (fetchStatus === 'fetching' || !qtdTitulos) {
        return null;
    }

    return <Alert severity="warning">Atenção, existe {qtdTitulos} títulos vencidos para sua loja! Realize a consulta e verifique.</Alert>;
}
