import React from 'react';

import { MarcaAutoComplete, MarcaOptionsProps } from 'components/autocompletes/MarcaAutoComplete';
import { FilterValuesProps } from 'hooks/useFilter';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoMarcaGrupoAutoComplete(): JSX.Element {
    const { filterMarca, filterConfigPedido, setFilterMarca } = usePedidoFilterContext();
    const idConfigPedido = filterConfigPedido.value?.idConfigPedido;

    return (
        <MarcaAutoComplete
            size="small"
            value={filterMarca.value}
            disabled={!idConfigPedido}
            requestOptions={
                idConfigPedido
                    ? {
                          url: '/gestao/marca/pedidocompra',
                          sort: [{ property: 'dsMarca', direction: 'ASC' }],
                          filter: [{ property: 'idConfigPedido', operator: 'eq', value: idConfigPedido }],
                      }
                    : null
            }
            onChange={(e, value: MarcaOptionsProps) => {
                setFilterMarca((oldState: FilterValuesProps) => ({
                    ...oldState,
                    value,
                }));
            }}
        />
    );
}
