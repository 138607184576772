import React, { useEffect, useMemo, useState } from 'react';

import { Add, Remove } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { TreeView } from '@mui/x-tree-view';
import { useQuery } from '@tanstack/react-query';

import { MenuTreeViewFilterItem } from 'components/menu/menutreeviewfilter/MenuTreeViewFilterItem';
import { MenuTreeViewFilterProps } from 'components/menu/menutreeviewfilter/types';
import { Block } from 'components/page';
import { SubTitle } from 'components/text';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';

export function MenuTreeViewFilter<ResponseMenuTreeView>({
    setFilter,
    filter,
    url,
    title,
    mapContentToTreeView,
    extraParams = [],
    sort,
    sx = [],
}: MenuTreeViewFilterProps<ResponseMenuTreeView>): JSX.Element {
    const RequestListagem = useRequestListagem();
    const [expanded, setExpanded] = useState<string[]>([]);

    const requestOptions: RequestOptionsType = {
        url,
        extraParams,
        sort,
    };

    const { data, isLoading } = useQuery([requestOptions.url, requestOptions], () => {
        const request: Promise<ResponseMenuTreeView[]> = RequestListagem(requestOptions).then((response) => response?.data?.data);
        return request;
    });

    useEffect(() => {
        if (!filter?.value?.length) {
            setExpanded([]);
        }
    }, [filter?.value?.length]);

    const dataMapped = useMemo(() => (data ? data.map((item) => mapContentToTreeView(item)) : []), [data, mapContentToTreeView]);

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Block
            sx={{
                position: 'sticky',
                top: 0,
                ...sx,
            }}
        >
            {title && <SubTitle label={title} />}

            <TreeView
                defaultCollapseIcon={<Remove />}
                defaultExpandIcon={<Add />}
                sx={{ flexGrow: 1 }}
                onNodeToggle={(event: object, nodeIds: any) => setExpanded(nodeIds)}
                expanded={expanded}
            >
                {dataMapped.map((menu) => (
                    <MenuTreeViewFilterItem<ResponseMenuTreeView> menu={menu} key={menu.nodeId} setFilter={setFilter} idsPaiFilter={[menu.nodeId]} />
                ))}
            </TreeView>

            {Boolean(filter?.value?.length) && (
                <Button
                    sx={{ mt: 2 }}
                    onClick={() => {
                        setFilter?.((oldState) => ({ ...oldState, value: [] }));
                        setExpanded([]);
                    }}
                >
                    Listar tudo
                </Button>
            )}
        </Block>
    );
}
