import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export type TipoEntrega = {
    idTipoEntrega: number;
    dsTipoEntrega: string;
};

type TipoEntregaAutoComplete = Pick<
    CissAutoCompleteProps,
    'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name' | 'label' | 'size' | 'requestOptions' | 'disableClearable' | 'error'
>;

export function TipoEntregaAutoComplete({
    sx,
    value,
    name,
    size,
    error,
    onChange,
    multiple = false,
    disableClearable = false,
    requestOptions = {
        url: '/gestao/tipoentrega',
        sort: [{ property: 'dsTipoEntrega', direction: 'ASC' }],
    },
}: TipoEntregaAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            sx={sx}
            name={name}
            size={size}
            error={error}
            value={value}
            multiple={multiple}
            onChange={onChange}
            requestOptions={requestOptions}
            disableClearable={disableClearable}
            label="Tipo de entrega"
            selectedStringTemplate="{dsTipoEntrega}"
            optionStringTemplate="{dsTipoEntrega}"
            isOptionEqualToValue={(option: TipoEntrega, value: TipoEntrega) => option.idTipoEntrega === value.idTipoEntrega}
        />
    );
}
