import React from 'react';

import { Grid, Typography } from '@mui/material';

import { Block } from 'components/page';
import { brlPrice } from 'util/format';
import { SubTitle } from 'components/text';
import { DashedGrid } from 'components/grid';
import { translateKey } from 'util/i18n';

const featuredKeys = ['vlTotal'];

const loopObject = (obj: any): any => {
    const info: any = [];

    Object.entries(obj).forEach(([key, value]) => {
        const data = {
            label: translateKey(key),
            value: brlPrice(value as number),
            featured: false,
        };

        if (featuredKeys.includes(key)) {
            data.featured = true;
        }

        info.push(data);
    });

    return info;
};

const generateResumoLists = (data: any | undefined): any => {
    const credito: any = [];
    const debito: any = [];

    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            const info = loopObject(value);

            if (key === 'creditoDiversos') {
                credito.push(...info);
            } else {
                debito.push(...info);
            }
        }
    });

    return [credito, debito];
};

export function EspelhoRoyaltyDemonstrativoList({ list }: any): JSX.Element {
    const [credito, debito] = generateResumoLists(list);

    return (
        <Block>
            <SubTitle label="Demonstrativo de Créditos e Débitos" />

            <Grid container spacing={2}>
                <Grid
                    item
                    flex={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography>Créditos Diversos (-)</Typography>
                    <DashedGrid list={credito} />
                </Grid>

                <Grid
                    item
                    flex={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography>Débitos Diversos (+)</Typography>
                    <DashedGrid list={debito} />
                </Grid>
            </Grid>
        </Block>
    );
}
