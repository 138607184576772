import React from 'react';

import { ArquivoFormComp, ArquivoListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function ArquivoForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('ARQUIVO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <ArquivoFormComp />
        </FormProvider>
    );
}

export function ArquivoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['ARQUIVO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <ArquivoListComp />
        </ListagemProvider>
    );
}
