import React, { useState } from 'react';

import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { isNumber } from 'lodash';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import {
    PedidoCompraAmmount,
    PedidoCompraProdutoModal,
    PedidoCompraValue,
    PedidoCompraAdicionarButton,
    PedidoDescontoChip,
} from 'pages/compra/pedido/components';
import { PedidoCompraListagemProductProps } from 'pages/compra/pedido/types';
import { camelCase, brlDecimal } from 'util/format';

export function PedidoCompraListProduct({ produtoPedido, sx }: PedidoCompraListagemProductProps): JSX.Element {
    const { modifyProdListQtd, getProdListQtd, isProdutoDisabled, getProdMinQtd, configPedidoValidateQtdMin, getProdCart } = usePedidoCompraContext();
    const { produto, qtMinimaCompra, peOferta, fgShowPeOferta } = produtoPedido;
    const produtoDisabled = isProdutoDisabled(produtoPedido);
    const produtoCart = getProdCart(produto.idProduto);
    const produtoQtd = produtoCart ? produtoCart.qtProdutoList : 0;
    const prodMinQtd = getProdMinQtd(qtMinimaCompra);
    const [openProdutoModal, setOpenProdutoModal] = useState<boolean>(false);
    const { tipoPedido } = useGetDomainConfig();
    const isTipoPedidoPadrao = tipoPedido === 'padrao';
    const isTipoPedidoFuncionario = tipoPedido === 'funcionario';

    return (
        <React.Fragment>
            <PedidoCompraProdutoModal open={openProdutoModal} setOpen={setOpenProdutoModal} produto={produtoPedido} />

            <Paper
                variant="elevation"
                elevation={2}
                sx={{
                    ...sx,
                    p: 2,
                    display: 'flex',
                    overflow: 'hidden',
                    opacity: produtoDisabled ? 0.6 : 1,
                    height: 100,
                }}
            >
                <Tooltip followCursor={true} title={produtoDisabled ? 'Produto indisponível para compra' : null}>
                    <React.Fragment>
                        {peOferta && fgShowPeOferta && <PedidoDescontoChip peOferta={peOferta} />}

                        <Grid container spacing={1.5} display="flex" alignItems="center">
                            <Grid item xs={3} md={1}>
                                <Box
                                    onClick={() => (isTipoPedidoFuncionario ? null : setOpenProdutoModal(true))}
                                    sx={{
                                        height: 68,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        backgroundImage: `url("${produto.lkImagem}")`,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {!produto.lkImagem && (
                                        <InsertPhotoOutlinedIcon fontSize="large" sx={{ color: (theme) => theme.palette.grey[400] }} />
                                    )}
                                </Box>
                            </Grid>

                            <Grid item container direction="column" xs={9} md={6.05}>
                                <Grid item zeroMinWidth>
                                    <Typography
                                        onClick={() => (isTipoPedidoFuncionario ? null : setOpenProdutoModal(true))}
                                        sx={{
                                            fontSize: '1.4rem',
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                        }}
                                        noWrap
                                    >
                                        {camelCase(produto.dsProduto, 2)}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={1}>
                                    {isTipoPedidoPadrao && (
                                        <React.Fragment>
                                            <Grid item sx={{ opacity: isNumber(produtoPedido.estoqueLoja?.qtSaldoAtual) ? 1 : 0.5 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <Inventory2OutlinedIcon />

                                                    <Typography>
                                                        {brlDecimal(produtoPedido.estoqueLoja?.qtSaldoAtual || 0, 0, 3)}{' '}
                                                        {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                                    </Typography>
                                                </Box>

                                                <Typography variant="caption">Estoque na Loja</Typography>
                                            </Grid>

                                            <Grid item sx={{ opacity: isNumber(produtoPedido.qtCoberturaEstoque) ? 1 : 0.5 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <DateRangeOutlinedIcon />

                                                    <Typography>{produtoPedido.qtCoberturaEstoque || 0} dias</Typography>
                                                </Box>

                                                <Typography variant="caption">Cobertura de Estoque</Typography>
                                            </Grid>
                                            <Grid item sx={{ opacity: isNumber(produtoPedido.qtSugerida) ? 1 : 0.5 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    <CodeOutlinedIcon />

                                                    <Typography>
                                                        {brlDecimal(produtoPedido.qtSugerida || 0, 0, 3)}{' '}
                                                        {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                                    </Typography>
                                                </Box>

                                                <Typography variant="caption">Quantidade Sugerida</Typography>
                                            </Grid>
                                        </React.Fragment>
                                    )}

                                    <Grid item sx={{ opacity: isNumber(prodMinQtd) && configPedidoValidateQtdMin ? 1 : 0.5 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5,
                                            }}
                                        >
                                            <ShoppingCartOutlinedIcon />

                                            <Typography>
                                                {brlDecimal(prodMinQtd || 0, 0, 3)} {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                            </Typography>
                                        </Box>

                                        <Typography variant="caption">Quantidade Mínima</Typography>
                                    </Grid>

                                    {isTipoPedidoFuncionario && (
                                        <Grid item sx={{ opacity: isNumber(produto.produtoEstoque?.qtEstoqueDisponivel) ? 1 : 0.5 }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 0.5,
                                                }}
                                            >
                                                <ShoppingCartOutlinedIcon />

                                                <Typography>
                                                    {brlDecimal(
                                                        (produto.produtoEstoque?.qtEstoqueDisponivel > 0 &&
                                                            produto.produtoEstoque?.qtEstoqueDisponivel) ||
                                                            0,
                                                        0,
                                                        3,
                                                    )}{' '}
                                                    {produto.unidadeMedida?.sgUnidadeMedida?.toLowerCase()}
                                                </Typography>
                                            </Box>

                                            <Typography variant="caption">Quantidade Disponível</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={3} md={1.25}>
                                <Typography variant="body2" textAlign="right">
                                    Valor Unitário
                                </Typography>

                                <PedidoCompraValue value={produtoPedido.vlPrecoCompra} align="right" size="small" />
                            </Grid>

                            <Grid item xs={4} md={1.7} display="flex" alignItems="flex-end">
                                <Tooltip
                                    title={
                                        produtoDisabled || !configPedidoValidateQtdMin
                                            ? null
                                            : `Quantidade deve ser múltipla de ${brlDecimal(prodMinQtd || 0, 0, 3)}`
                                    }
                                >
                                    <Box>
                                        <Typography variant="body2">Quantidade</Typography>

                                        <PedidoCompraAmmount getQtd={getProdListQtd} modifyQtd={modifyProdListQtd} produtoPedido={produtoPedido} />
                                    </Box>
                                </Tooltip>
                            </Grid>

                            <Grid item xs={2} md={0.5} display="flex" alignItems="flex-end">
                                <PedidoCompraAdicionarButton
                                    sx={{ mb: -2 }}
                                    idProduto={produto.idProduto}
                                    qtMinimaCompra={prodMinQtd}
                                    produtoQtd={produtoQtd}
                                    produtoCart={produtoCart}
                                    produtoDisabled={produtoDisabled}
                                />
                            </Grid>

                            <Grid item xs={3} md={1.5}>
                                <Typography variant="body2" textAlign="right">
                                    Valor Total
                                </Typography>

                                <PedidoCompraValue value={produtoQtd > 0 ? produtoQtd * produtoPedido.vlPrecoCompra : 0} align="right" size="small" />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </Tooltip>
            </Paper>
        </React.Fragment>
    );
}
