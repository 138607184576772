import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Checkbox, FormControlLabel, Grid, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { Field, FormikHelpers, FormikProps } from 'formik';
import { usePopupState } from 'material-ui-popup-state/hooks';
import * as Yup from 'yup';

import { CissPrimaryKeyField, CissTextField } from 'components/fields';
import { Row } from 'components/form';
import { FullLoading } from 'components/loading';
import { Block, FormPage } from 'components/page';
import { SubTitle } from 'components/text';
import { useFormContext } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useFormMutation } from 'mutations/useFormMutation';
import { ModulosPermissionCheckBox, UsuariosListGrid, UsuariosListGridModal } from 'pages/cadastro/permissoes/components';
import { PermissoesProps, ModuleProps, PermissionProps, UsuariosProps } from 'pages/cadastro/permissoes/types';

export function PermissoesFormComp(): JSX.Element {
    const { content } = useFormContext();
    const { id } = useParams();

    const { mutate, isLoading } = useFormMutation();
    const RequestListagem = useRequestListagem();

    const [openUsuarioModal, setOpenUsuarioModal] = useState(false);
    const popupState = usePopupState({ variant: 'popover', popupId: 'menuSystem' });

    const [usuarios, setUsuarios] = useState([] as UsuariosProps[]);

    const { hasPermission } = usePermissionsContext();

    const handleAdicionarUsuarioClick = (): void => {
        popupState.close();
        setOpenUsuarioModal(true);
    };

    const RequestOptions: RequestOptionsType = {
        url: '/security/grupousuariopermissao?application=portal',
    };

    const { data: permissionsModules } = useQuery([RequestOptions], () =>
        RequestListagem(RequestOptions).then(({ data }) => {
            const modules: ModuleProps[] = data.data;
            return modules.map((module) => {
                return {
                    ...module,
                    permissoes: module.permissoes.map((permission) => {
                        return {
                            ...permission,
                            fgPermiteAcesso: false,
                        };
                    }),
                };
            });
        }),
    );

    const url = useMemo(() => '/gestao/grupousuario/', []);

    const handleSubmit = useCallback(
        (values: PermissoesProps, formik: FormikHelpers<PermissoesProps>): void => {
            values.dsObservacao === '' ? (values.dsObservacao = null) : values.dsObservacao;
            values.usuarios = usuarios;

            mutate({
                url,
                idField: 'idGrupoUsuario',
                formData: values,
                formik,
            });
        },
        [mutate, url, usuarios],
    );

    const handleSelectPermission = (event: React.ChangeEvent<HTMLInputElement>, idPermissao: number, currentModules: ModuleProps[]): ModuleProps[] => {
        return currentModules.map((module) => {
            return {
                ...module,
                permissoes: module.permissoes.map((p: PermissionProps) => {
                    return p.idPermissao === idPermissao ? { ...p, fgPermiteAcesso: event.target.checked } : p;
                }),
            };
        });
    };

    const validationSchema = useMemo(
        () =>
            Yup.object({
                idGrupoUsuario: Yup.number(),
                dsGrupoUsuario: Yup.string().required(),
                fgAtivo: Yup.boolean(),
                fgPadraoFranqueado: Yup.boolean().optional(),
                dsDescricao: Yup.string().optional(),
            }),
        [],
    );

    const getInitialValues = useMemo(
        () => ({
            idGrupoUsuario: 0,
            dsGrupoUsuario: '',
            dsObservacao: '',
            fgPadraoFranqueado: false,
            fgAtivo: true,
            grupoUsuarioRecursoPermissoes: id ? [] : permissionsModules || [],
            usuarios: [],
        }),
        [id, permissionsModules],
    );

    useEffect(() => {
        if (content && content.usuarios) {
            setUsuarios(content.usuarios);
        }
    }, [content]);

    const addUsuario = (users: UsuariosProps[]): void => {
        setUsuarios(users);
    };

    return (
        <FormPage title="Grupo de Permissões" values={getInitialValues} onSubmit={handleSubmit} validationSchema={validationSchema} url={url} fgAtivo={hasPermission(['GRUPO_PERMISSAO_MODERAR'])}>
            {(formik: FormikProps<Partial<PermissoesProps>>) => {
                return (
                    <React.Fragment>
                        <FullLoading loading={isLoading} />

                        <Block>
                            <Row>
                                <Grid item>
                                    <CissPrimaryKeyField value={formik.values.idGrupoUsuario} name="idGrupoUsuario" />
                                </Grid>

                                <Grid item flex={1}>
                                    <CissTextField
                                        label="Nome do grupo de permissões"
                                        name="dsGrupoUsuario"
                                        value={formik.values.dsGrupoUsuario}
                                        onChange={(e) => formik.setFieldValue('dsGrupoUsuario', e.target.value)}
                                        error={formik.errors.dsGrupoUsuario}
                                    />
                                </Grid>

                                <Grid item sx={{ alignItems: 'center', display: 'flex', height: 70 }}>
                                    <Field as={FormControlLabel} type="checkbox" name="fgPadraoFranqueado" control={<Checkbox />} label="Padrão" />
                                </Grid>
                            </Row>
                        </Block>

                        <Block>
                            <Row>
                                <Grid item flex={1}>
                                    <SubTitle label="Descrição e/ou Observação" />

                                    <CissTextField
                                        label="Observação"
                                        name="dsObservacao"
                                        multiline
                                        rows={5}
                                        value={formik.values.dsObservacao}
                                        error={formik.errors.dsObservacao}
                                        onChange={(e) => formik.setFieldValue('dsObservacao', e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                </Grid>
                            </Row>
                        </Block>
                        <Block>
                            <Grid container direction="column" sx={{ gap: 2 }}>
                                <SubTitle label="Módulos" />

                                {formik.values.grupoUsuarioRecursoPermissoes && (
                                    <ModulosPermissionCheckBox
                                        modules={formik?.values?.grupoUsuarioRecursoPermissoes}
                                        onChange={(event, idPermissao) => {
                                            const mappedModules = handleSelectPermission(event, idPermissao, formik.values.grupoUsuarioRecursoPermissoes || []);
                                            formik.setFieldValue('grupoUsuarioRecursoPermissoes', mappedModules);
                                        }}
                                    />
                                )}
                            </Grid>
                        </Block>
                        <Block>
                            <SubTitle label="Usuários" />

                            <Box sx={{ display: 'flex', mb: 2, justifyContent: 'flex-end' }}>
                                <Button variant="contained" onClick={handleAdicionarUsuarioClick}>
                                    Gerenciar Usuários
                                </Button>
                            </Box>

                            <ListagemProvider>
                                <UsuariosListGrid usuarios={usuarios} />
                            </ListagemProvider>

                            <ListagemProvider>
                                <UsuariosListGridModal open={openUsuarioModal} setOpen={setOpenUsuarioModal} addUsuario={addUsuario} currentUsers={usuarios} />
                            </ListagemProvider>
                        </Block>
                    </React.Fragment>
                );
            }}
        </FormPage>
    );
}
