import React, { useState } from 'react';

import CenterFocusStrongOutlinedIcon from '@mui/icons-material/CenterFocusStrongOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Button, Grid, Tooltip } from '@mui/material';

import { ListPagination } from 'components/pagination';
import { useListagemContext } from 'context/ListagemContext';
import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { PedidoCompraCartModal, PedidoSalvarBtn, PedidoInfoBbar } from 'pages/compra/pedido/components';
import { RequestListagemProdutoPedidos } from 'pages/compra/pedido/types';

interface PedidoCompraListBbarProps {
    isFetching: boolean;
    data?: RequestListagemProdutoPedidos;
}

export function PedidoCompraListBbar({ isFetching, data }: PedidoCompraListBbarProps): JSX.Element {
    const { focusProdutos } = usePedidoCompraContext();
    const { page, setPage } = useListagemContext();
    const { isDownLg: isMobile } = useBreakpoints();

    const [openCartModal, setOpenCartModal] = useState<boolean>(false);

    return (
        <>
            <PedidoCompraCartModal open={openCartModal} setOpen={setOpenCartModal} isFetching={isFetching} />

            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    py: 1.5,
                    mr: -1,
                    mt: 1,
                    pr: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}
            >
                <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    sx={{
                        'justifyContent': 'flex-end',
                        '& .MuiGrid-item': {
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                        },
                    }}
                >
                    {data && (
                        <Grid
                            item
                            xs={6}
                            md="auto"
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', mr: data.total > 200 ? 'none' : 'auto' }}
                        >
                            <div>
                                <strong>{data.data.length}</strong> de <strong>{data.total}</strong> produtos listados
                            </div>
                        </Grid>
                    )}

                    {data && data.total > 200 && (
                        <Grid item xs={6} md sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ListPagination data={data} list={data?.data} limit={200} page={page} setPage={setPage} onChange={focusProdutos} />
                        </Grid>
                    )}

                    <PedidoInfoBbar />

                    <Grid item xs={6} md="auto" gap={2}>
                        <Tooltip title="Focar">
                            <Button
                                variant="outlined"
                                sx={{
                                    height: 40,
                                    minWidth: 40,
                                    px: 0,
                                }}
                                onClick={focusProdutos}
                            >
                                <CenterFocusStrongOutlinedIcon sx={{ p: 0, m: 0 }} />
                            </Button>
                        </Tooltip>

                        {isMobile && (
                            <Tooltip title="Carrinho">
                                <Button
                                    variant="contained"
                                    color="success"
                                    sx={{
                                        height: 40,
                                        minWidth: 40,
                                        px: 0,
                                    }}
                                    onClick={() => setOpenCartModal(true)}
                                >
                                    <ShoppingCartOutlinedIcon />
                                </Button>
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid item xs={6} md="auto">
                        <PedidoSalvarBtn />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
