import React from 'react';

import { GrupoAutoComplete } from 'components/autocompletes';
import { FilterValuesProps } from 'hooks/useFilter';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';
import { usePedidoFilterContext } from 'pages/compra/pedido/contexts/PedidoFilterContext';

export function PedidoGrupoAutoComplete(): JSX.Element {
    const { tipoPedido } = useGetDomainConfig();
    const { filterConfigPedido, filterJornada, setFilterJornada } = usePedidoFilterContext();
    const idConfigPedido = filterConfigPedido.value?.idConfigPedido;

    return (
        <GrupoAutoComplete
            label={tipoPedido === 'funcionario' ? 'Grupo de produtos' : 'Jornada'}
            size="small"
            optionStringTemplate="<strong>{dsGrupo}</strong> <br/> Segmento {secao.dsSecao}"
            value={filterJornada.value}
            disabled={!filterConfigPedido.value?.idConfigPedido}
            onChange={(e, value) => setFilterJornada((oldState: FilterValuesProps) => ({ ...oldState, value }))}
            requestOptions={
                idConfigPedido
                    ? {
                          url: '/gestao/grupo/pedidocompra',
                          filter: [{ property: 'idConfigPedido', operator: 'eq', value: idConfigPedido }],
                          columns: 'idGrupo,dsGrupo,cdGrupo,dsSecao',
                          nested: true,
                      }
                    : null
            }
        />
    );
}
