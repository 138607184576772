import React, { useCallback, useState } from 'react';

import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Container } from '@mui/system';
import jsPDF from 'jspdf';

import { ContentModal } from 'components/modal';
import { ExportFileType, ExportTypeModalProps } from 'pages/marketing-trade/types';

export function MarketingTradeExportTypeModal({ isModalVisible, setIsModalVisible, valueStage, valueToNormalize, formatoImagem}: ExportTypeModalProps): JSX.Element {
    const exportTypes: ExportFileType[] = ['png', 'pdf'];

    const [selectedExportType, setSelectedExportType] = useState<ExportFileType>('png');

    const downloadPngImage = useCallback(() => {
        const imageUrl = valueStage?.toDataURL({ pixelRatio: valueToNormalize });

        const link = document.createElement('a');
        
        link.download = 'peca.png';
        link.href = imageUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [valueStage, valueToNormalize]);

    const downloadPdfImage = useCallback(() => {
        const image = valueStage?.toDataURL({ pixelRatio: valueToNormalize });
        const density = formatoImagem?.idPecaMarketingFormato === 1 ? 5.55 : 2;
        const width = (valueStage?.width() * valueToNormalize) / density;
        const height = (valueStage?.height() * valueToNormalize) / density;

        const pageFormat = width > height ? 'l' : 'p';

        const pdf = new jsPDF(pageFormat, 'px', [width, height]);
        pdf.addImage(image, 0, 0, width, height);

        pdf.save('peca.pdf');
    }, [formatoImagem?.idPecaMarketingFormato, valueStage, valueToNormalize]);

    const handleDownload = useCallback(() => {
        if (selectedExportType === 'png') {
            downloadPngImage();
        } else if (selectedExportType === 'pdf') {
            downloadPdfImage();
        }
        
        setIsModalVisible(false);
    }, [downloadPdfImage, downloadPngImage, selectedExportType, setIsModalVisible]);

    return (
        <ContentModal
            open={isModalVisible}
            setOpen={setIsModalVisible}
            sx={{
                borderRadius: 1,
                minWidth: {
                    sm: 300,
                },
            }}
        >
            <Container sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', mt: 3, mb: 3, p: 0, gap: 2 }}>
                <RadioGroup value={selectedExportType} onChange={(e, value) => setSelectedExportType(value as ExportFileType)}>
                    {exportTypes.map((type, index) => (
                        <FormControlLabel key={index} value={type} label={type.toUpperCase()} control={<Radio />} />
                    ))}
                </RadioGroup>

                <Button variant="contained" size="large" sx={{ width: '100%' }} onClick={handleDownload}>
                    Baixar
                </Button>
            </Container>
        </ContentModal>
    );
}
