import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useConfiguracaoPedidoCompraMenuAsideContext } from 'context/ConfiguracaoPedidoCompraMenuAsideContext';

export function ValueChangeListener(): null {
    const { dirty } = useFormikContext();
    const { setActiveStepDirty } = useConfiguracaoPedidoCompraMenuAsideContext();

    useEffect(() => {
        if (dirty) {
            setActiveStepDirty();
        }
    }, [dirty]);

    return null;
}
