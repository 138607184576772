import React from 'react';

import { Chip, alpha } from '@mui/material';

interface TagProps {
    label: string;
}

/**
 * Chip do MUI personalizado baseado na cor principal do tema
 */
export function Tag({ label }: TagProps): JSX.Element {
    return (
        <Chip
            size="small"
            label={label}
            sx={{
                color: 'secondary.main',
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
                mr: 1,
            }}
        />
    );
}
