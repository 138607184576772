import { AzureUploadFileProps } from 'components/azureUpload/types';
import { RequestListagemProps } from 'hooks/useRequestListagem';
import { TypeMarketing } from 'pages/marketing-trade/types';

export const acceptedFilesExtension = ['.png', '.jpg', '.gif', '.bmp', '.ai', '.pdf', '.eps', '.cdr', '.psd', '.tiff', '.ppt', '.pptx', '.mp4', '.mpeg', '.zip', '.rar'];

interface EstadoProps {
    dsEstado: string;
}

interface CepProps {
    idCep: number;
    dsCidade: string;
    sgEstado: string;
    nrCep: string;
    estado: EstadoProps;
}

interface LojaProps {
    idLoja: number;
    dsNomeFantasia: string;
    dsRazaoSocial: string;
    cdLoja: string;
    dsCnpj: string;
    dsFone: string;
    dsEndereco: string;
    dsBairro: string;
    cep: CepProps;
}

interface CategoriaPecaProps {
    idCategoriaPeca: number;
    dsCategoriaPeca: string;
}

interface UnidadeMedidaPecaProps {
    idUnidadeMedidaPeca: number;
    dsUnidadeMedidaPeca: string;
}

export interface ItensProps {
    idSolicitacaoPecaItem: number;
    unidadeMedidaPeca: UnidadeMedidaPecaProps | null;
    dsTipoPeca: string;
    vlLargura: string;
    vlAltura: string;
    dsLocalVeiculacao: string;
    dsObservacao: string;
    dsEnderecoLoja: string;
    dsTelefoneLoja: string;
    fgExcluido: boolean;
    arquivos: AzureUploadFileProps[];
}

interface StatusPecaProps {
    idSolicitacaoPecaStatus: number;
    dsStatus: string;
}

interface UsuarioProps {
    dsUsuario: string;
    idUsuario: number;
}

export interface HistoricoStatusProps {
    idSolicitacaoPecaSolicitacaoPecaStatus: number;
    solicitacaoPecaStatus: StatusPecaProps;
    dhInclusao: string;
    usuarioInclusao: UsuarioProps;
}

export interface MensagensProps {
    idSolicitacaoPecaMensagem: number;
    dsMensagem?: string;
    arquivos: AzureUploadFileProps[];
    dhInclusao?: string;
    usuarioInclusao?: UsuarioProps;
}

export interface SolicitacaoPecaProps {
    idSolicitacaoPeca: number;
    loja: LojaProps | null;
    categoriaPeca: CategoriaPecaProps | null;
    dhInclusao?: string;
    fgAtivo: boolean;
    itens: ItensProps[];
    historicoStatus: HistoricoStatusProps[];
    mensagens: MensagensProps[];
    solicitacaoPecaStatus: StatusPecaProps | null;
    usuarioSolicitante: UsuarioProps & {
        dsEmail: string;
    };
    tipoMarketing: {
        idTipoMarketing: number;
    };
}

export interface RequestSolicitacaoPecaProps extends Partial<RequestListagemProps> {
    data: {
        data: SolicitacaoPecaProps;
    };
}

export const initialSolicitacaoPecaItensValues: ItensProps = {
    idSolicitacaoPecaItem: -1,
    unidadeMedidaPeca: null,
    dsTipoPeca: '',
    vlLargura: '',
    vlAltura: '',
    dsLocalVeiculacao: '',
    dsObservacao: '',
    dsEnderecoLoja: '',
    dsTelefoneLoja: '',
    fgExcluido: false,
    arquivos: [],
};

export const initialSolicitacaoPecaValues: Partial<SolicitacaoPecaProps> = {
    idSolicitacaoPeca: -1,
    loja: null,
    categoriaPeca: null,
    itens: [initialSolicitacaoPecaItensValues],
    tipoMarketing: {
        idTipoMarketing: TypeMarketing.TRADE,
    },
};

export interface SolicitacaoMensagemPecaProps {
    id: number;
    idSolicitacaoPeca: number | null;
    mensagem: MensagensProps;
    solicitacaoPecaStatus: StatusPecaProps | null;
    fgContestado: boolean;
    fgEnviaParaEmAndamento: boolean;
}

export const initialSolicitacaoMensagemValues: MensagensProps = {
    idSolicitacaoPecaMensagem: -1,
    dsMensagem: '',
    arquivos: [],
};

export const initialSolicitacaoMensagemPecaValues: SolicitacaoMensagemPecaProps = {
    id: -1,
    idSolicitacaoPeca: null,
    mensagem: initialSolicitacaoMensagemValues,
    solicitacaoPecaStatus: null,
    fgContestado: false,
    fgEnviaParaEmAndamento: false,
};

export interface ListItemEnderecoProps {
    [key: string]: boolean;
}

interface UsuarioPecasProps {
    dsUsuario: string;
}

interface LojaPecasProps {
    dsRazaoSocial: string;
}

interface StatusPecasProps {
    dsStatus: string;
    idSolicitacaoPecaStatus: number;
}

export interface PecasItensProps {
    idSolicitacaoPecaItem: number;
    dsTipoPeca: string;
}

export interface SolicitacaoPecasProps {
    idSolicitacaoPeca: number;
    loja: LojaPecasProps;
    usuarioSolicitante: UsuarioPecasProps;
    solicitacaoPecaStatus: StatusPecasProps;
    dhInclusao: string | null;
    fgAtivo: boolean;
    idSolicitacaoPecaStatus: number;
    itens: PecasItensProps[];
    dsStatus: string;
}

export enum SolicitacaoStatusColor {
    STATUS_1 = '#FFCC99', // 1- Aguardando aprovação
    STATUS_2 = '#98FF98', // 2- Aprovado pelo Marketing
    STATUS_3 = '#FF6665', // 3- Reprovadas
    STATUS_4 = '#99FEFF', // 4- Em andamento
    STATUS_5 = '#98FF98', // 5- Finalizadas
    STATUS_6 = '#FFCC99', // 6- Aguardando Franqueado
    STATUS_100 = '#CCCCFF', // 100- Solicitacoes
    STATUS_101 = '#FFCBFF', // 101 - Dias tempo médio
}

export interface RequestSolicitacaoPecasModelProps {
    data: SolicitacaoPecasProps[];
    total: number;
    hasNext: boolean;
}

export interface CardIndicadorProps {
    dsIndicador: string;
    qtIndicador: number;
    qtAnterior: string;
    peIndicador: number;
    idIndicador: number;
    tpIndicador: string;
    fgAtivo?: boolean;
}

export interface RequestCardIndicadorProps {
    data: CardIndicadorProps[];
    total: number;
    hasNext: boolean;
}

export interface MensagemPadrao {
    idMensagemPadrao?: number;
    dsMensagemPadrao: string;
    dsTitulo: string;
}