import React from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { TRequestLocalFuncionarioList } from 'pages/complementar/local-funcionario/types';

export function useGetLocalFuncionario(): UseQueryResult<TRequestLocalFuncionarioList> {
    const { filter, page, sort, limit } = useListagemContext();
    const requestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: '/gestao/localfuncionario',
        sort: sort.length ? sort : [{ property: 'dsLocalFuncionario', direction: 'ASC' }],
        filter,
        page,
        limit,
    };

    return useQuery<TRequestLocalFuncionarioList>(['getLocalFuncionario', requestOptions], () =>
        requestListagem(requestOptions).then((res) => res.data),
    );
}
