import 'react-big-calendar/lib/css/react-big-calendar.css';

import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import * as dateFns from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Calendar, dateFnsLocalizer, Event, EventPropGetter, View } from 'react-big-calendar';

interface BaseCalendarProps {
    view?: View;
    onViewChange?: (view: View) => void;
    date?: Date;
    onDateChange?: (date: Date) => void;
    eventos: Event[];
    onSelectEvent?: (event: Event, e: SyntheticEvent<HTMLElement, globalThis.Event>) => void;
    eventPropGetter?: EventPropGetter<Event>;
}

export function BaseCalendar({
    view: userView,
    onViewChange: userViewChangeCallback,
    date: userDate,
    onDateChange: userDateChangeCallback,
    eventos,
    onSelectEvent,
    eventPropGetter,
}: BaseCalendarProps): JSX.Element {
    const [view, setView] = useState<View>('month');
    const [date, setDate] = useState(new Date());

    // tradução calendário
    const localizer = useMemo(
        () =>
            dateFnsLocalizer({
                format: dateFns.format,
                parse: dateFns.parse,
                startOfWeek: dateFns.startOfWeek,
                getDay: dateFns.getDay,
                locales: {
                    'pt-BR': ptBR,
                },
            }),
        [],
    );

    // na mudança de view disparamos o callback para o usuário
    const onViewChange = (newView: View): void => {
        setView(newView);

        if (typeof userViewChangeCallback === 'function') {
            userViewChangeCallback(newView);
        }
    };

    // na mudança de data disparamos o callback para o usuário
    const onDateChange = (newDate: Date): void => {
        setDate(newDate);

        if (typeof userDateChangeCallback === 'function') {
            userDateChangeCallback(newDate);
        }
    };

    // por padrão o big calendar não inclui a data final (hora/min) de um evento como parte do evento, portanto, por exemplo um evento que termine
    // à meia noite do dia 20, na verdade termina às 23:59 do dia 19... para isso, sempre que uma data final terminar em 0 (meia noite) jogamos um
    // minuto a mais na data final para que o evento siga até no dia final no calendário
    const getEndDate = (event: Event): Date => {
        const { end } = event;

        if (end && end.getHours() === 0 && end.getMinutes() === 0) {
            end.setMinutes(1);
        }

        return end as Date;
    };

    // atualizamos a view com base na prop view que se torna userView recebida pelo usuário
    useEffect(() => {
        if (userView && userView !== view) {
            setView(userView);
        }
    }, [userView]);

    // atualizamos o range de data com base na prop date que se torna userDate recebida pelo usuário
    useEffect(() => {
        if (userDate && userDate !== date) {
            setDate(userDate);
        }
    }, [userDate]);

    const transformedEvents = eventos.map((evento) => {
        const title = evento.resource?.titleEvent || evento.title;

        return {
            ...evento,
            title,
        };
    });

    return (
        <Box sx={{ height: 750 }}>
            <Calendar
                date={date}
                onNavigate={onDateChange}
                view={view}
                onView={onViewChange}
                culture="pt-BR"
                localizer={localizer}
                events={transformedEvents}
                eventPropGetter={eventPropGetter}
                endAccessor={getEndDate}
                onSelectEvent={onSelectEvent}
                toolbar={false}
                popup
                messages={{
                    date: 'Data',
                    time: 'Tempo',
                    event: 'Evento',
                    allDay: 'Dia todo',
                    week: 'Semana',
                    work_week: 'Dias úteis',
                    day: 'Dia',
                    month: 'Mês',
                    previous: 'Voltar',
                    next: 'Avançar',
                    yesterday: 'Ontem',
                    tomorrow: 'Amanhã',
                    today: 'Hoje',
                    agenda: 'Agenda',
                    noEventsInRange: 'Sem eventos neste intervalo.',
                    showMore: (total) => `+ ${total} eventos`,
                }}
            />
        </Box>
    );
}
