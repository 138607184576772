import React from 'react';

import { FetchEventSourceInit, fetchEventSource } from '@microsoft/fetch-event-source';

import { baseURL } from 'constants/baseURL';

type EventStream = {
    url: string;
    body: unknown;
    events: Pick<FetchEventSourceInit, 'onerror' | 'onmessage' | 'onclose' | 'onopen'>;
};

type UseNewEventStream = {
    createEventStream: (params: EventStream) => Promise<void>;
};

export function useEventStream(): UseNewEventStream {
    const createEventStream = ({ url, body, events }: EventStream): Promise<void> =>
        fetchEventSource(`${baseURL}${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'text/event-stream',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('AuthToken')}`,
            },
            ...events,
        });

    return { createEventStream };
}
