import React, { useContext } from 'react';

import { decodeToken } from 'react-jwt';
import { AuthContext as KeycloakContext } from 'react-oidc-context';

import { AuthContext as CissLiveContext } from 'context/CissLiveAuthContext';
import { GenericAuth, TokenInfo } from 'context/GenericAuthContext';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';

type Token = {
    info: {
        aplicacao: TokenInfo;
    };
};

export function useGenericAuth(): GenericAuth {
    const { tipoAuth } = useGetDomainConfig();
    const cissLiveAuth = useContext(CissLiveContext);
    const keycloakAuth = useContext(KeycloakContext);

    if (tipoAuth === 'cissLiveAuth' && cissLiveAuth) {
        return cissLiveAuth;
    } else if (tipoAuth === 'oicd' && keycloakAuth) {
        const keycloakDecoded = decodeToken(keycloakAuth?.user?.access_token || '') as unknown as Token;

        if (!keycloakAuth?.isAuthenticated && !keycloakAuth?.isLoading) {
            keycloakAuth.signinRedirect();
        }

        const logout = (): Promise<void> => keycloakAuth.signoutRedirect();

        const resetSession = (): Promise<void> => keycloakAuth.signinRedirect();

        const updatePassword = (): void => {
            const { authority, client_id, redirect_uri } = keycloakAuth.settings;

            location.replace(
                `${authority}protocol/openid-connect/auth?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&kc_action=UPDATE_PASSWORD`,
            );
        };

        const tokenInfo = {
            ...keycloakDecoded?.info?.aplicacao,
        };

        tokenInfo.dsUsuario = keycloakAuth?.user?.profile.name ?? 'User';

        const accessToken = keycloakAuth.user?.access_token || null;

        return {
            isAuthenticated: keycloakAuth.isAuthenticated && !keycloakAuth.isLoading,
            logout,
            resetSession,
            tokenInfo,
            accessToken,
            updatePassword,
            isFirstTime: false,
            openLoginModal: false,
            isUpdatablePassword: true,
            setToken: () => {
                throw new Error('O método setToken não é suportado neste tipo de auth');
            },
            setOpenLoginModal: () => {
                throw new Error('O método setOpenLoginModal não é suportado neste tipo de auth');
            },
            setIsFirstTime: () => {
                throw new Error('O método setIsFirstTime não é suportado neste tipo de auth');
            },
        };
    }

    throw new Error('O tipo e contexto do auth são necessários para a aplicação funcionar');
}
