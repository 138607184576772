import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export function useLocalStorage<StorageType extends string>(key: string): { value: StorageType | null; setValue: Dispatch<SetStateAction<StorageType | null>>; removeValue: () => void } {
    const [value, setValue] = useState<StorageType | null>(localStorage.getItem(key) as StorageType);

    const handleValue = useCallback(
        (newValue: any) => {
            localStorage.setItem(key, newValue);
            setValue(newValue);
        },
        [key],
    );

    const removeValue = useCallback(() => {
        localStorage.removeItem(key);
        setValue(null);
    }, [key]);

    const eventListenerStorage = useCallback(
        (event: any) => {
            const newValue = event.storageArea[key];
            if (newValue && newValue !== value) {
                handleValue(newValue);
            }
        },
        [key, value, handleValue],
    );

    useEffect(() => {
        window.addEventListener('storage', eventListenerStorage);

        return () => window.removeEventListener('storage', eventListenerStorage);
    }, [eventListenerStorage]);

    return { value, setValue: handleValue, removeValue };
}
