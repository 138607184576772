import React, { useCallback } from 'react';

import EastIcon from '@mui/icons-material/East';
import { Box } from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import * as dateFns from 'date-fns';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { OneColumn, Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { MarketingTradeCampanhaListFilter } from 'pages/marketing-trade/components';
import { CampanhaModelProps, MarketingTradePage, RequestCampanhaListModelProps } from 'pages/marketing-trade/types';

const generateCampanhasList = (data: CampanhaModelProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.idCampanhaMarketing,
            idCampanhaMarketing: item.idCampanhaMarketing,
            dsCampanhaMarketing: item.dsCampanhaMarketing,
            fgPermantente: item.fgPermanente,
            subCampanhasMarketing: item.subCampanhasMarketing,
            dtInicioExibicao: item.dtInicioExibicao ? `${item.dtInicioExibicao}T00:00:00` : null,
            dtFimExibicao: item.dtFimExibicao ? `${item.dtFimExibicao}T00:00:00` : null,
            fgAtivo: item.fgAtivo,
        }));
    }

    return [];
};

export function MarketingTradeCampanhaListComp({ isMarketing = false }: MarketingTradePage): JSX.Element {
    const { limit, page, sort, filter, autoLoad } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const getCategoriaTitle = useCallback(({ row }: GridRowModel): JSX.Element => {
        const { subCampanhasMarketing, dsCampanhaMarketing } = row;

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {subCampanhasMarketing &&
                    subCampanhasMarketing.map((subCampanha: CampanhaModelProps, index: number) => {
                        return (
                            <React.Fragment key={subCampanha.idCampanhaMarketing + subCampanha.dsCampanhaMarketing}>
                                {index > 0 && <EastIcon sx={{ height: 15, width: 15, mx: 0.5 }} />}

                                {subCampanha.dsCampanhaMarketing}
                            </React.Fragment>
                        );
                    })}

                {subCampanhasMarketing && subCampanhasMarketing.length > 0 && <EastIcon sx={{ height: 15, width: 15, mx: 0.5 }} />}

                <strong>{dsCampanhaMarketing}</strong>
            </Box>
        );
    }, []);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/campanhamarketing/listagem',
        sort: sort?.length ? sort : [{ property: 'dsCampanhaMarketing', direction: 'ASC' }],
        filter,
        limit,
        page,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], () => RequestListagem(requestOptions).then((res) => res.data) as Promise<RequestCampanhaListModelProps>, {
        enabled: autoLoad,
    });

    const checkDateFgPermanente = (params: any): string => {
        const { row, value } = params;
        const { fgPermantente } = row;

        let val = '--';

        if (!fgPermantente && value) {
            val = dateFns.format(new Date(value), 'dd/MM/yyyy');
        }

        return val;
    };

    const columns: GridColDef[] = [
        { field: 'idCampanhaMarketing', headerName: 'Código', minWidth: 90 },
        { field: 'dsCampanhaMarketing', headerName: 'Título', flex: 1, minWidth: 80, renderCell: getCategoriaTitle },
        { field: 'dtInicioExibicao', headerName: 'Data Início', minWidth: 90, valueGetter: checkDateFgPermanente },
        { field: 'dtFimExibicao', headerName: 'Data Fim', minWidth: 90, valueGetter: checkDateFgPermanente },
        { field: 'fgPermantente', headerName: 'Permanente', type: 'boolean', sortable: false, minWidth: 90 },
        { field: 'fgAtivo', headerName: 'Ativo', minWidth: 90, type: 'boolean' },
        {
            field: 'acoes',
            align: 'center',
            headerAlign: 'center',
            headerName: 'Ações',
            filterable: false,
            sortable: false,
            hideable: false,
            disableColumnMenu: true,
            minWidth: 90,
            renderCell: ({ row }) => <EditRemoveBtn id={row.idCampanhaMarketing} refresh={refetch} removeUrl={`/gestao/campanhamarketing/${row.idCampanhaMarketing}`} sx={{ p: 1.5 }} />,
        },
    ];

    const rows = generateCampanhasList(data?.data);

    return (
        <OneColumn title={`Campanhas de ${isMarketing ? 'Marketing' : 'Trade'}`} goBackButton createButton>
            <MarketingTradeCampanhaListFilter isMarketing={isMarketing} />

            <Block>
                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />
            </Block>
        </OneColumn>
    );
}
