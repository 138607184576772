import React from 'react';

import { useParams } from 'react-router-dom';

import { ComunicadoListComp, ComunicadoDetailComp, ComunicadoFormComp } from './components';

import { FormProvider } from 'context/FormContext';
import { LikesProvider } from 'context/LikeContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function ComunicadoDetail(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['COMUNICADO_VISUALIZAR'])) return alertNotPermission();

    return (
        <LikesProvider>
            <ComunicadoDetailComp />
        </LikesProvider>
    );
}

export function ComunicadoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['COMUNICADO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <ComunicadoListComp />
        </ListagemProvider>
    );
}

export function ComunicadoForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('COMUNICADO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <ComunicadoFormComp />
        </FormProvider>
    );
}
