import React, { useMemo } from 'react';

import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { EditRemoveBtn } from 'components/buttons';
import { PanelGrid } from 'components/grid';
import { Block } from 'components/page';
import { useListagemContext } from 'context/ListagemContext';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { RequestMensagemPadrao } from 'pages/marketing-trade/trade/solicitacoes-pecas/mensagem-padrao/types';
import { MensagemPadrao } from 'pages/marketing-trade/trade/solicitacoes-pecas/types';

const generateDepositosList = (data: MensagemPadrao[] | undefined): GridRowsProp => {
    const list = data;

    if (Array.isArray(list) && list.length) {
        return list.map((item) => ({
            id: item.idMensagemPadrao,
            idMensagemPadrao: item.idMensagemPadrao,
            dsMensagemPadrao: item.dsMensagemPadrao,
            dsTitulo: item.dsTitulo,
        }));
    }
    return [];
};

export function MensagemPadraoGrid(): JSX.Element {
    const { filter, page, sort } = useListagemContext();
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = {
        url: 'gestao/mensagempadrao',
        sort: sort.length > 0 ? sort : [{ property: 'idMensagemPadrao', direction: 'DESC' }],
        filter,
        page,
    };

    const { fetchStatus, data, refetch } = useQuery([requestOptions], (): Promise<RequestMensagemPadrao> => RequestListagem(requestOptions).then((res: any) => res.data));

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'dsTitulo', headerName: 'Título', minWidth: 100, flex: 1, type: 'string' },
            {
                field: 'acoes',
                align: 'center',
                headerAlign: 'center',
                headerName: 'Ações',
                filterable: false,
                sortable: false,
                hideable: false,
                disableColumnMenu: true,
                width: 110,
                renderCell: ({ row }) => <EditRemoveBtn id={row.idMensagemPadrao} showEditBtn={true} showRemoveBtn={true} removeUrl={`gestao/mensagempadrao/${row.idMensagemPadrao}`} refresh={refetch} />,
            },
        ],
        [refetch],
    );

    return (
        <Block>
            <PanelGrid rows={generateDepositosList(data?.data)} rowsCount={data?.total ?? 0} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} />
        </Block>
    );
}