import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useRequestListagem } from 'hooks/useRequestListagem';
import { UsuarioInfo } from 'pages/inicio/types';

export function useGetSaldoFuncionario(): UseQueryResult<UsuarioInfo> {
    const requestListagem = useRequestListagem();

    return useQuery<UsuarioInfo>(
        ['getSaldoFuncionario'],
        () =>
            requestListagem({
                url: 'gestao/creditoFuncionario/saldo',
            }).then((res) => res.data),
        {
            enabled: true,
        },
    );
}
