import React from 'react';
import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface PessoaProps {
    cdPessoa: string;
    dsPessoa: string;
    idPessoa: number;
    nrCpfCnpj: string;
    nrTelefone: string;
}

export interface FornecedorOptionsProps {
    dsInscricaoEstadual: string;
    fgAtivo: boolean;
    idPessoaFornecedor: number;
    pessoa: PessoaProps;
}

type FornecedorAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'onChange' | 'name'>;

export function FornecedorAutoComplete({ value, error, onChange = () => {}, name }: FornecedorAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label="Filial"
            value={value}
            multiple={false}
            error={error}
            requestOptions={{
                url: '/gestao/pessoafornecedor',
                sort: [{ property: 'dsNome', direction: 'ASC' }],
                limit: 50,
                nested: true,
            }}
            isOptionEqualToValue={(option: FornecedorOptionsProps, value: FornecedorOptionsProps) => option.pessoa.cdPessoa === value.pessoa.cdPessoa}
            selectedStringTemplate="{pessoa.dsPessoa}"
            optionStringTemplate="{pessoa.cdPessoa} - {pessoa.dsPessoa}"
            onChange={onChange}
            name={name}
        />
    );
}
