import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Alert, AlertTitle } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { PanelGrid } from 'components/grid';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { useValidation } from 'hooks/useValidation';
import { RequestUsuariosListProps, UsuariosProps } from 'pages/cadastro/permissoes/types';

const generateUsuariosList = (data: UsuariosProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item, index) => ({
            id: index + 1,
            idUsuario: item.idUsuario,
            dsUsuario: item.dsUsuario,
        }));
    }

    return [];
};

export function UsuariosListGrid({ usuarios }: any): JSX.Element | null {
    const RequestListagem = useRequestListagem();

    const { id } = useParams();
    const { validRouterParams } = useValidation();
    const validId = validRouterParams({ id });

    const requestOptions: RequestOptionsType = {
        url: '/gestao/usuario',
        sort: [{ property: 'dsUsuario', direction: 'ASC' }],
        columns: 'idUsuario,dsUsuario',
        limit: 50,
    };

    const { data, fetchStatus, refetch } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestUsuariosListProps> = RequestListagem(requestOptions).then((result) => result.data);
            return request;
        },
        { enabled: validId },
    );

    const columns: GridColDef[] = [
        { field: 'idUsuario', headerName: 'Código', width: 120, minWidth: 70 },
        { field: 'dsUsuario', headerName: 'Nome Usuário', flex: 1, minWidth: 100 },
    ];

    const rows = generateUsuariosList(usuarios ? usuarios : null);

    if (rows.length) {
        return <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} />;
    } else {
        return (
            <Box>
                <Alert severity="info" sx={{ fontSize: '1rem', mt: 3, mb: 1 }}>
                    <AlertTitle sx={{ fontSize: '1.1rem' }}>Atenção</AlertTitle>
                    <Box>
                        Para gerenciar a listagem de usuários clique em <strong>Gerenciar Usuários</strong>.
                    </Box>
                </Alert>
            </Box>
        );
    }
}
