import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useGenericAuth } from 'hooks/useGenericAuth';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { LojaProps } from 'pages/compra/pedido/types';

type FormattedLoja = Pick<LojaProps, 'idLoja' | 'dsNomeFantasia' | 'cdLoja'> | undefined;

type Usuario = {
    idUsuario: number;
    dsUsuario: string;
    cdUsuario: string;
    idTipoEntrega: number;
    idLocalFuncionario: number;
    cep?: {
        idCep: number;
    };
};

export type AplicacaoDataContext = {
    loja?: LojaProps;
    formattedLoja: FormattedLoja;
    usuario?: Usuario;
};

type AplicacaoDataProvider = {
    children?: ReactNode;
};

const AplicacaoDataContext = createContext<AplicacaoDataContext>({} as AplicacaoDataContext);

export function AplicacaoDataProvider({ children }: AplicacaoDataProvider): JSX.Element {
    const requestListagem = useRequestListagem();
    const { tokenInfo } = useGenericAuth();

    const [formattedLoja, setFormattedLoja] = useState<FormattedLoja | undefined>();
    const [loja, setLoja] = useState<LojaProps | undefined>();
    const [usuario, setUsuario] = useState<Usuario | undefined>();

    const requestOptionsLoja: RequestOptionsType = {
        url: `gestao/loja/${tokenInfo?.idEmpresa}`,
    };

    useQuery<AxiosResponse<LojaProps>>([requestOptionsLoja], () => requestListagem(requestOptionsLoja).then((res) => res.data), {
        enabled: Boolean(tokenInfo?.idEmpresa),
        onSuccess: (response) => {
            const { data } = response;

            if (data) {
                setLoja(data);
                setFormattedLoja({ idLoja: data.idLoja, cdLoja: data.cdLoja, dsNomeFantasia: data.dsNomeFantasia });
            }
        },
    });

    const requestOptionsUsuario: RequestOptionsType = {
        url: `gestao/usuario/${tokenInfo?.idUsuario}`,
    };

    useQuery<AxiosResponse<Usuario>>([requestOptionsUsuario], () => requestListagem(requestOptionsUsuario).then((res) => res.data), {
        enabled: Boolean(tokenInfo?.idUsuario),
        onSuccess: ({ data }) => {
            if (data) {
                setUsuario(data);
            }
        },
    });

    const contextValue = useMemo(
        () => ({
            loja,
            formattedLoja,
            usuario,
        }),
        [formattedLoja, loja, usuario],
    );

    return <AplicacaoDataContext.Provider value={contextValue}>{children}</AplicacaoDataContext.Provider>;
}

export const useAplicacaoDataContext = (): AplicacaoDataContext => useContext(AplicacaoDataContext);
