import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from 'components/autocompletes/CissAutoComplete';

export interface TipoOperacaoOptionsProps {
    dsOperacao: string | null;
    cdOperacao: string | null;
    fgAtivo: boolean | null;
    idConfigPedidoOperacao: number | null;
    tipoLoja: {
        idTipoLoja: number;
        dsTipoLoja: string;
    };
    operacao: {
        idOperacao: number;
        dsOperacao: string;
    };
    idOperacao: number | null;
    idConfigPedido: number | null;
    idTipoLoja: number | null;
}

type StoreAutocompleteProps = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'label' | 'name'>;

export function TipoOperacaoAutoComplete({ label = 'Tipo de Operacao', value, error, multiple = false, disabled = false, sx, onChange = () => {}, name }: StoreAutocompleteProps): JSX.Element {
    return (
        <CissAutoComplete
            label={label}
            value={value}
            multiple={multiple}
            error={error}
            selectedStringTemplate="{dsOperacao}"
            optionStringTemplate="{dsOperacao}"
            requestOptions={{
                url: '/gestao/operacao',
                sort: [{ property: 'idOperacao', direction: 'ASC' }],
                filter: [{ property: 'fgAtivo', value: true, operator: 'eq' }],

                limit: 50,
            }}
            isOptionEqualToValue={(option: TipoOperacaoOptionsProps, value: TipoOperacaoOptionsProps) => value && option && value.idOperacao === option.idConfigPedidoOperacao}
            onChange={onChange}
            disabled={disabled}
            sx={sx}
            name={name}
        />
    );
}
