import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Box } from '@mui/material';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';

import { PanelGrid } from 'components/grid';
import { brlPercentual, brlPrice } from 'util/format';
import { RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { ProdutosNotaDevolucaoDetailListProps, RequestProdutosNotaDevolucaoDetailListProps } from 'pages/financeiro/nota-devolucao/types';

const generateRows = (data: ProdutosNotaDevolucaoDetailListProps[] | undefined): GridRowsProp => {
    if (Array.isArray(data) && data.length) {
        return data.map((item) => ({
            id: item.produto.cdProduto,
            nrCodigo: item.produto.cdProduto,
            dsProduto: item.produto.dsProduto,
            qtProduto: item.qtNotaProduto,
            vlProduto: item.vlProduto,
            vlTotal: item.vlTotalProduto,
            peIcms: item.peIcms,
            vlSt: item.vlIcmsSt,
        }));
    }

    return [];
};

interface NotaDevolucaoListDetailGridProps {
    nrOrdemVenda: number;
}

export function NotaDevolucaoListDetailGrid({ nrOrdemVenda }: NotaDevolucaoListDetailGridProps): JSX.Element {
    const RequestListagem = useRequestListagem();

    const requestOptions: RequestOptionsType = useMemo(
        () => ({
            url: '/fiscal/movimentacaosimulacaodevolucao/itens',
            sort: [{ property: 'nrOrdemVenda', direction: 'DESC' }],
            filter: [{ property: 'nrOrdemVenda', value: nrOrdemVenda, operator: 'eq' }],
        }),
        [nrOrdemVenda],
    );

    const { fetchStatus, data, refetch } = useQuery([requestOptions], () => {
        const request: Promise<RequestProdutosNotaDevolucaoDetailListProps> = RequestListagem(requestOptions).then((res) => res.data);
        return request;
    });

    const columns: GridColDef[] = useMemo(
        () => [
            { field: 'nrCodigo', headerName: 'Código', width: 120 },
            { field: 'dsProduto', headerName: 'Produto', minWidth: 120, flex: 1 },
            { field: 'qtProduto', headerName: 'Qtde.', width: 150, type: 'number' },
            { field: 'vlProduto', headerName: 'Valor Unit.', width: 150, type: 'number', valueFormatter: ({ value }) => brlPrice(value) },
            { field: 'vlTotal', headerName: 'Valor Total', width: 150, type: 'number', valueFormatter: ({ value }) => brlPrice(value) },
            { field: 'peIcms', headerName: 'Aliq. ICMS', width: 150, type: 'number', valueFormatter: ({ value }) => brlPercentual(value) },
            { field: 'vlSt', headerName: 'Vl. SR (R$)', width: 130, type: 'number', valueFormatter: ({ value }) => brlPrice(value) },
        ],
        [],
    );

    const rows = useMemo(() => generateRows(data?.data), [data]);

    return (
        <Box
            sx={{
                py: 2.5,
                pl: 2.5,
                backgroundColor: 'grey.200',
                overflow: 'hidden',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    pr: 2.5,
                    overflow: 'scroll',
                    height: '100%',
                }}
            >
                <PanelGrid rows={rows} rowsCount={rows.length} reload={refetch} loading={fetchStatus === 'fetching'} columns={columns} remote={false} visibleRows="all" />
            </Box>
        </Box>
    );
}
