import React from 'react';

import { Grid, Typography } from '@mui/material';

import { usePedidoCompraContext } from 'context/PedidoCompraContext';
import { useFrete, usePedidoMinimo } from 'pages/compra/pedido/hooks';
import { brlPrice } from 'util/format';

export function PedidoInfoBbar(): JSX.Element {
    const { hasVlFrete, isFreteGratis, vlFrete, vlParaFreteGratis } = useFrete();
    const { dsPedidoMinimo } = usePedidoMinimo();
    const { vlPedido } = usePedidoCompraContext();

    const hasContent = hasVlFrete || dsPedidoMinimo || vlPedido;

    return (
        <Grid
            item
            xs={hasContent ? 6 : 0}
            md="auto"
            sx={{
                alignItems: 'flex-start',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: 0, md: 2 },
            }}
        >
            {hasVlFrete && vlParaFreteGratis > 0 && (
                <Typography
                    sx={{
                        fontSize: '.9rem',
                        span: {
                            display: 'block',
                            strong: {
                                color: 'success.main',
                            },
                        },
                    }}
                >
                    Faltam <strong>{brlPrice(vlParaFreteGratis)}</strong>
                    <span>
                        para frete <strong>GRÁTIS</strong>
                    </span>
                </Typography>
            )}

            {hasVlFrete && (
                <Typography
                    sx={{
                        strong: {
                            ml: { xs: 0.5, md: 0 },
                            color: isFreteGratis ? 'success.main' : 'black',
                        },
                        span: {
                            fontSize: '.8rem',
                            display: { xs: 'inline', md: 'block' },
                        },
                    }}
                >
                    <span>Frete:</span>

                    <strong>{isFreteGratis ? 'GRÁTIS' : brlPrice(vlFrete)}</strong>
                </Typography>
            )}

            {dsPedidoMinimo && (
                <Typography
                    sx={{
                        strong: {
                            ml: { xs: 0.5, md: 0 },
                        },
                        span: {
                            fontSize: '.8rem',
                            display: { xs: 'inline', md: 'block' },
                        },
                    }}
                >
                    <span>Faltam:</span>
                    <strong>{dsPedidoMinimo}</strong>
                </Typography>
            )}

            <Typography
                sx={{
                    strong: {
                        ml: { xs: 0.5, md: 0 },
                    },
                    span: {
                        fontSize: '.8rem',
                        display: { xs: 'inline', md: 'block' },
                    },
                }}
            >
                <span>Total do pedido:</span>

                <strong>{brlPrice(vlPedido + vlFrete)}</strong>
            </Typography>
        </Grid>
    );
}
