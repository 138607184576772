import React, { useState } from 'react';

import { OneColumn } from 'components/page';
import { usePermissionsContext } from 'context/PermissionsContext';
import { QuebrasErrosModal, QuebrasGrid, QuebrasGridFilter, QuebrasImportarModal } from 'pages/logistica/quebras/components';

export function QuebrasListComp(): JSX.Element {
    const [openModalImportar, setOpenModalImportar] = useState<boolean>(false);
    const [openModalErros, setOpenModalErros] = useState<boolean | number>(false);
    const { hasPermission } = usePermissionsContext();
    
    return (
        <OneColumn
            title="Quebras"
            goBackButton
            createButton={false}
            extraButtons={
                hasPermission(['QUEBRAS_IMPORTAR'])
                    ? [
                          {
                              title: 'Importar',
                              onClick: () => setOpenModalImportar(true),
                          },
                      ]
                    : []
            }
        >
            <QuebrasImportarModal open={openModalImportar} setOpen={setOpenModalImportar} />

            <QuebrasErrosModal open={openModalErros} setOpen={setOpenModalErros} />

            <QuebrasGridFilter />

            <QuebrasGrid setOpenModalErros={setOpenModalErros} />
        </OneColumn>
    );
}
