export interface RequestCondicaoPagamentoProps {
    idCondicaoPagamento: number | null;
    dsCondicaoPagamento: string;
    cdCondicaoPagamento: string;
    fgAtivo: boolean;
}

export const initialCondicaoPagamentoValues: RequestCondicaoPagamentoProps = {
    idCondicaoPagamento: null,
    dsCondicaoPagamento: '',
    cdCondicaoPagamento: '',
    fgAtivo: true,
};
