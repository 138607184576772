import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { NotasRowProps } from 'pages/financeiro/extrato/fpp/types';
import { TinyButton } from 'components/buttons';

interface PanelGridEspelhoFPPProps {
    rowData: NotasRowProps;
}

export function PanelGridEspelhoFPP({ rowData }: PanelGridEspelhoFPPProps): JSX.Element {
    const navigate = useNavigate();
    const { featured, cdLoja, nrFpp, nrQuinzena, nrMes, nrAno } = rowData;

    const handleClickButtonPdf = (): void => {
        navigate({
            pathname: 'espelho',
            search: `?${createSearchParams({
                cdLoja,
                nrFpp: nrFpp.toString(),
                nrQuinzena: nrQuinzena.toString(),
                nrMes: nrMes.toString(),
                nrAno: nrAno.toString(),
            })}`,
        });
    };

    return <React.Fragment>{featured && <TinyButton onClick={handleClickButtonPdf}>Detalhes</TinyButton>}</React.Fragment>;
}
