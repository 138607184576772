import React from 'react';

import { ListagemProvider } from 'context/ListagemContext';
import { UsuarioFormComp, UsuarioListComp } from 'pages/cadastro/usuario/components';
import { FormProvider } from 'context/FormContext';
import { usePermissionsContext } from 'context/PermissionsContext';
import { useParams } from 'react-router-dom';

export function UsuarioForm(): JSX.Element {
    const { hasFormPermission, alertNotPermission } = usePermissionsContext();
    const { id } = useParams();

    if (!hasFormPermission('USUARIO', id)) return alertNotPermission();

    return (
        <FormProvider>
            <UsuarioFormComp />
        </FormProvider>
    );
}

export function UsuarioList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();

    if (!hasPermission(['USUARIO_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <UsuarioListComp />
        </ListagemProvider>
    );
}
