import React from 'react';

import { PedidoRealizadoListComp } from './components';

import { FormProvider } from 'context/FormContext';
import { ListagemProvider } from 'context/ListagemContext';
import { usePermissionsContext } from 'context/PermissionsContext';

export function PedidoRealizadoList(): JSX.Element {
    const { hasPermission, alertNotPermission } = usePermissionsContext();
    if (!hasPermission(['COMPRA_PEDIDO_LISTAGEM_VISUALIZAR'])) return alertNotPermission();

    return (
        <ListagemProvider>
            <FormProvider>
                <PedidoRealizadoListComp />
            </FormProvider>
        </ListagemProvider>
    );
}
