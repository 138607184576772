import React, { Dispatch, SetStateAction, useState } from 'react';

import { Box, Button } from '@mui/material';

import { CissTextField } from 'components/fields';
import { ContentModal } from 'components/modal';
import { SubTitle } from 'components/text';
import { ComunicadoLogsGrid } from 'pages/comunicado/components/templates/ComunicadoLogsGrid';

type ComunicadoLogsModal = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    idComunicado: number;
};

export function ComunicadoLogsModal({ isOpen, setIsOpen, idComunicado }: ComunicadoLogsModal): JSX.Element {
    const [queryEmail, setQueryEmail] = useState<string>('');
    return (
        <ContentModal open={isOpen} setOpen={setIsOpen} sx={{ borderRadius: 1, minWidth: { md: 800 } }} closeButton>
            <Box>
                <SubTitle label="Logs de Envio de Emails" />

                <Box sx={{ display: 'flex' }}>
                    <CissTextField
                        label="Buscar Email"
                        size="small"
                        sx={{ width: { xs: '100%', md: '50%' } }}
                        deboundTime={500}
                        onChange={(e) => setQueryEmail(e.target.value)}
                    />
                </Box>

                <Box sx={{ height: 400, my: 2 }}>
                    <ComunicadoLogsGrid idComunicado={idComunicado} queryEmail={queryEmail} />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>
                        Fechar
                    </Button>
                </Box>
            </Box>
        </ContentModal>
    );
}
