import React from 'react';

import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useFormMutation } from 'mutations/useFormMutation';
import { StatusIntegracaoTextStatus } from 'pages/compra/pedido-totalizador/status-integracao/components';
import { PedidoTotalizadorSincronizacaoStatus, SincronizacaoStatus } from 'pages/compra/pedido-totalizador/types';

export interface StatusIntegracaoCardIndicatorStatus {
    statusIntegracao: PedidoTotalizadorSincronizacaoStatus;
    statusIntegracaoPacote?: PedidoTotalizadorSincronizacaoStatus;
    nrPosicao: number;
    qtTotalPacote: number;
    idPedido: number;
}

export function StatusIntegracaoCardIndicatorStatus({ statusIntegracao, statusIntegracaoPacote, nrPosicao, qtTotalPacote, idPedido }: StatusIntegracaoCardIndicatorStatus): JSX.Element {
    const { mutate, isLoading } = useFormMutation();
    const queryClient = useQueryClient();

    const onClickReprocessar = (): void => {
        mutate(
            { url: `compra/pedidototalizadorsincronizacao/reprocessa/${idPedido}` },
            {
                onSettled: () => queryClient.invalidateQueries(['getStatusIntegracao']),
            },
        );
    };

    return (
        <Paper variant="outlined" sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <Box
                sx={{
                    p: 1,
                    backgroundColor: '#96d7eb',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <FlagOutlinedIcon />
            </Box>

            <Stack sx={{ 'p': 2, 'width': '100%', 'alignItems': 'center', 'justifyContent': 'center', 'textAlign': 'center', 'gap': 1, '>  .MuiTypography-root': { fontSize: '1.2rem' } }}>
                <Typography>Situação: {statusIntegracao ? <StatusIntegracaoTextStatus statusIntegracao={statusIntegracao} /> : null}</Typography>

                <Typography>
                    Pacote: {nrPosicao}/{qtTotalPacote}
                </Typography>

                <Typography>Situação pacote: {statusIntegracaoPacote ? <StatusIntegracaoTextStatus statusIntegracao={statusIntegracaoPacote} /> : null}</Typography>

                <LoadingButton
                    loading={isLoading}
                    onClick={onClickReprocessar}
                    disabled={![SincronizacaoStatus.FINALIZADO_COM_FALHA, SincronizacaoStatus.PACOTE_COM_FALHA].includes(statusIntegracao.idPedidoTotalizadorSincronizacaoStatus)}
                    variant="contained"
                    color="inherit"
                >
                    Reprocessar
                </LoadingButton>
            </Stack>
        </Paper>
    );
}
