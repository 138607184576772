import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { FormControlLabel, Switch } from '@mui/material';

interface ToggleFgAtivoProps {
    disabled?: boolean;
}

export function ToggleFgAtivo({ disabled }: ToggleFgAtivoProps): JSX.Element {
    const { values, setFieldValue } = useFormikContext();
    const { fgAtivo } = values as any;
    const [switchChecked, setSwitchChecked] = useState(true);

    const handleFgAtivoChange = useCallback(
        (status: boolean): void => {
            setSwitchChecked(status);
            setFieldValue('fgAtivo', status);
        },
        [setSwitchChecked, setFieldValue],
    );

    useEffect(() => {
        if (fgAtivo !== switchChecked) {
            handleFgAtivoChange(fgAtivo);
        }
    }, [fgAtivo, handleFgAtivoChange, setSwitchChecked, switchChecked]);

    return <FormControlLabel control={<Switch checked={switchChecked} onChange={(e) => handleFgAtivoChange(e.target.checked)} />} label="Ativo" disabled={disabled} />;
}
