import React from 'react';
import { Outlet } from 'react-router-dom';

import { PageDefault } from 'components/page';
import { PageDefaultNavigationProvider } from 'context/PageDefaultNavigationProvider';

export function PortalDetailComp(): JSX.Element {
    return (
        <PageDefaultNavigationProvider>
            <PageDefault>
                <Outlet />
            </PageDefault>
        </PageDefaultNavigationProvider>
    );
}
