import React from 'react';

import { CissAutoComplete, CissAutoCompleteProps } from './CissAutoComplete';

interface GrupoEconomico {
    idGrupoEconomico: number;
    dsGrupoEconomico: string;
}

type GrupoEconomicoAutoComplete = Pick<CissAutoCompleteProps, 'value' | 'error' | 'multiple' | 'disabled' | 'sx' | 'onChange' | 'name'>;

export function GrupoEconomicoAutoComplete({ value, error, multiple = true, disabled = false, sx, onChange = () => {}, name }: GrupoEconomicoAutoComplete): JSX.Element {
    return (
        <CissAutoComplete
            label="Grupo Econômico"
            optionStringTemplate="{dsGrupoEconomico}"
            selectedStringTemplate="{dsGrupoEconomico}"
            sx={sx}
            name={name}
            value={value}
            error={error}
            multiple={multiple}
            onChange={onChange}
            disabled={disabled}
            isOptionEqualToValue={(option: GrupoEconomico, value: GrupoEconomico) => option.idGrupoEconomico === value.idGrupoEconomico}
            requestOptions={{
                url: '/gestao/grupoeconomico',
                sort: [{ property: 'idGrupoEconomico', direction: 'ASC' }],
                columns: 'idGrupoEconomico,dsGrupoEconomico',
                nested: true,
                limit: 50,
            }}
        />
    );
}
