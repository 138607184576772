import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useDebounce } from 'use-debounce';

import { Autocomplete, Box } from '@mui/material';

import { RequestListagemProps, RequestOptionsType, useRequestListagem } from 'hooks/useRequestListagem';
import { CissTextField } from 'components/fields/';

interface categoryOptionsProps {
    idCategoriaComunicado: number;
    dsCategoriaComunicado: string;
}

interface CategoryFieldProps {
    onChange: (e: any, value: any) => void;
    multiple?: boolean;
    label?: string;
    value?: any;
}

interface RequestListagemType extends RequestListagemProps {
    data: {
        data: categoryOptionsProps[];
    };
}

export function CategoryField({ onChange, multiple = true, label = 'Categorias', value }: CategoryFieldProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [query, setQuery] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const RequestListagem = useRequestListagem();
    const [queryValue] = useDebounce(query, 500);

    const requestOptions: RequestOptionsType = {
        url: '/gestao/categoriacomunicado',
        query: queryValue,
        sort: [{ property: 'idCategoriaComunicado', direction: 'ASC' }],
        columns: 'idCategoriaComunicado,dsCategoriaComunicado',
        limit: 50,
    };

    const { isLoading, fetchStatus, data } = useQuery(
        [requestOptions],
        () => {
            const request: Promise<RequestListagemType> = RequestListagem(requestOptions);
            return request;
        },
        {
            keepPreviousData: false,
            enabled: open,
            onError: () => {
                enqueueSnackbar('Falha ao processar solicitação de categorias', { variant: 'error' });
            },
        },
    );

    useEffect(() => {
        if (open) {
            setQuery(inputValue);
        }
    }, [inputValue, open]);

    return (
        <Autocomplete
            value={value}
            multiple={multiple}
            onChange={onChange}
            loading={isLoading && fetchStatus === 'fetching'}
            options={data?.data?.data ?? []}
            isOptionEqualToValue={(option, fieldValue) => option.idCategoriaComunicado === fieldValue.idCategoriaComunicado}
            getOptionLabel={(option: categoryOptionsProps) => option.dsCategoriaComunicado}
            renderInput={(params) => <CissTextField {...params} label={label} />}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.idCategoriaComunicado}>
                    {option.idCategoriaComunicado} - {option.dsCategoriaComunicado}
                </Box>
            )}
        />
    );
}
