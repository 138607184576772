import React, { useState, MouseEvent } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Divider, Menu, Stack } from '@mui/material';

import { MenuSystemItemChangePassword, MenuSystemItemChangeStore, MenuSystemItemLogout } from 'components/menu/system';
import { useGenericAuth } from 'hooks/useGenericAuth';
import { useGetDomainConfig } from 'hooks/useGetDomainConfig';

export type TMenuSystemItem = {
    text: string;
    icon: JSX.Element;
    hidden?: boolean;
    onClick: () => void;
};

/**
 * Menu de sistema completo
 * Contém botão para abrir menu de sistema (ícone) + menu com opções disponiveis
 */
export function MenuSystem(): JSX.Element {
    const { isUpdatablePassword } = useGenericAuth();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const open = Boolean(anchorEl);
    const { fgAlteraEmpresa } = useGetDomainConfig();

    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => setAnchorEl(e.currentTarget as Element);
    const handleClose = (): void => setAnchorEl(null);

    return (
        <React.Fragment>
            <IconButton onClick={handleClick} sx={{ color: 'common.white' }}>
                <MoreVertIcon fontSize="large" />
            </IconButton>

            <Menu anchorEl={anchorEl as Element} open={open} onClose={handleClose}>
                <Stack divider={<Divider sx={{ p: 0 }} />} sx={{ width: 200, py: 1 }}>
                    {fgAlteraEmpresa && <MenuSystemItemChangeStore />}

                    {isUpdatablePassword && <MenuSystemItemChangePassword />}

                    <MenuSystemItemLogout />
                </Stack>
            </Menu>
        </React.Fragment>
    );
}
