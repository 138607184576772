import React from 'react';

import { UploadFileProps, UploadProps } from '../types';
import { UploadPreview } from 'components/upload/components';

type UpdateFgCapaProps = boolean | string;

/**
 * Componente de Upload de Imagens,
 */
export function UploadPreviewCover({ listFiles, setListFiles, disabled }: UploadProps): JSX.Element {
    // atualiza capa da lista
    // pode receber uma string (hash) para setar em um item especifico
    // pode receber um boolean (true) para setar no primeiro item da lista
    const updateFgCapa = (data: UpdateFgCapaProps): UploadFileProps[] => {
        let hash: UpdateFgCapaProps = typeof data === 'string' && data;
        let list = [] as UploadFileProps[];

        if (listFiles && listFiles.length) {
            if (typeof data === 'boolean') {
                hash = listFiles[0].arquivo.dsHash;
            }

            list = listFiles.map((item) => {
                item.fgCapa = item.arquivo.dsHash === hash;

                return item;
            });
        }

        return list;
    };

    const handleRemoveFile = (dsHash: string): void => {
        let cover = false;

        let list = listFiles.map((item) => {
            if (item.arquivo.dsHash === dsHash) {
                item.fgAtivo = false;
                item.fgExcluido = true;

                cover = item.fgCapa;
            }

            return item;
        });

        if (cover) {
            list = updateFgCapa(true);
        }

        setListFiles(list);
    };

    const handleUpdateCover = (dsHash: string): void => {
        setListFiles(updateFgCapa(dsHash));
    };

    return <UploadPreview listFiles={listFiles} updateCover={handleUpdateCover} removeFile={handleRemoveFile} disabled={disabled} />;
}
