import React from 'react';

import Grid, { GridProps } from '@mui/material/Grid';

/**
 * Componente responsável por criar uma "div" de tamanho: 100% largura + 100vh de altura
 */
// TODO analisar possibilidade de receber parametros sx também e nesse caso atualizar style para sx no componente
export function Fit(props: GridProps): JSX.Element {
    return <Grid container component="div" style={{ minHeight: '100dvh', width: '100%' }} {...props} />;
}
