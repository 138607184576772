import React from 'react';

import { Checkbox, FormControlLabel, Grid, Typography, FormGroup } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Block } from 'components/page';
import { ModuleProps, PermissionProps } from 'pages/cadastro/permissoes/types';

interface ModuloPermissionCheckboxProps {
    modules: ModuleProps[];
    onChange: (event: React.ChangeEvent<HTMLInputElement>, idPermissao: number) => void;
}

export function ModulosPermissionCheckBox({ modules = [], onChange = () => {} }: ModuloPermissionCheckboxProps): JSX.Element | null {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, { idPermissao }: PermissionProps): void => {
        onChange(event, idPermissao);
    };

    return (
        <Grid item container spacing={2}>
            {modules.map((module: ModuleProps) => (
                <Grid item xs={12} sm={6} md={4} key={module.idRecurso}>
                    <Block
                        variant="outlined"
                        elevation={0}
                        key={module.idRecurso}
                        sx={{
                            height: '100%',
                            m: 0,
                        }}
                    >
                        <Box
                            sx={{
                                p: 1,
                            }}
                        >
                            <React.Fragment key={module.idRecurso}>
                                <Typography fontSize="1.25rem">
                                    <strong> {module.text}</strong>
                                </Typography>

                                <FormGroup>
                                    {module.permissoes.map((permissao: PermissionProps) => (
                                        <FormControlLabel
                                            control={<Checkbox defaultChecked={!!permissao.fgPermiteAcesso} onChange={(e: any) => handleChange(e, permissao)} />}
                                            label={
                                                <Typography
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {permissao.dsPermissao}
                                                </Typography>
                                            }
                                            key={permissao.idPermissao}
                                            sx={{ width: '100%' }}
                                        />
                                    ))}
                                </FormGroup>
                            </React.Fragment>
                        </Box>
                    </Block>
                </Grid>
            ))}
        </Grid>
    );
}
